import { InteractionRequiredAuthError } from '@azure/msal-browser'

import { msalInstance, tokenRequest } from '@/authConfig'

const getAccessToken = async () => {
    try {
        const tokenResponse = await msalInstance.acquireTokenSilent(tokenRequest)
        const token = tokenResponse?.accessToken ?? null
        const tokenExpireOn = tokenResponse?.expiresOn ?? null

        return {
            token,
            tokenExpireOn,
        }
    } catch (error) {
        console.error('Error acquiring token:', error.message)
        if (error instanceof InteractionRequiredAuthError) {
            try {
                const tokenResponse = await msalInstance.acquireTokenPopup(tokenRequest)
                const token = tokenResponse?.accessToken ?? null
                const tokenExpireOn = tokenResponse?.expiresOn ?? null

                return {
                    token,
                    tokenExpireOn,
                }
            } catch (innerError) {
                console.error('Failed to acquire token via popup:', innerError.message)
                return {
                    token: null,
                    tokenExpireOn: null,
                }
            }
        } else {
            console.error('Failed to acquire token:', error.message)
            return {
                token: null,
                tokenExpireOn: null,
            }
        }
    }
}

export { getAccessToken }
