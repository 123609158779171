import { useState } from 'react'
import { useTranslation } from 'react-i18next'
import { MenuItem, Select } from '@mui/material'
import PropTypes from 'prop-types'

const LayerType = ({ handleUpdateLayerType, isDisabled, layerType }) => {
    const { t } = useTranslation()

    const [itemLayerType, setItemLayerType] = useState(layerType)

    const handleTypeChange = async (event) => {
        const value = event?.target?.value
        setItemLayerType(value)
        handleUpdateLayerType(value)
    }

    return (
        <Select
            data-testid="nqp-quote-item-consumption-mode"
            disabled={isDisabled}
            id="select-itemConsumptionMode"
            inputProps={{
                'data-testid': 'drawingdoctordrawer-layer-layertype-input',
                sx: { padding: 1 },
            }}
            labelId="select-itemConsumptionMode-label"
            sx={{ width: '100%' }}
            value={itemLayerType}
            onChange={handleTypeChange}
        >
            <MenuItem value="Cutting">{t('Cutting')}</MenuItem>
            <MenuItem value="Etching">{t('Etching')}</MenuItem>
            <MenuItem value="Information">{t('Information')}</MenuItem>
            <MenuItem value="Folding">{t('Folding')}</MenuItem>
        </Select>
    )
}

LayerType.propTypes = {
    handleUpdateLayerType: PropTypes.func,
    isDisabled: PropTypes.bool,
    layerType: PropTypes.string,
}

export default LayerType
