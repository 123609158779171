import { Toolbar as MuiToolbar } from '@mui/material'
import PropTypes from 'prop-types'

const Toolbar = ({ children, disableGutters = false, sx }) => {
    return (
        <MuiToolbar
            disableGutters={disableGutters}
            sx={[...(Array.isArray(sx) ? sx : [sx])]}
        >
            {children}
        </MuiToolbar>
    )
}

Toolbar.propTypes = {
    children: PropTypes.node,
    disableGutters: PropTypes.bool,
    sx: PropTypes.oneOfType([
        PropTypes.arrayOf(PropTypes.oneOfType([PropTypes.func, PropTypes.object, PropTypes.bool])),
        PropTypes.func,
        PropTypes.object,
    ]),
}

export default Toolbar
