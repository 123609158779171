import { useEffect, useState } from 'react'
import { useSelector } from 'react-redux'
import { useParams } from 'react-router-dom'
import { TransformComponent, TransformWrapper } from 'react-zoom-pan-pinch'
import { ClassNames } from '@emotion/react'
import { Box, useTheme } from '@mui/material'
import PropTypes from 'prop-types'

import { useGetQuoteItemsQuery } from '@/app/services/quoteItems'
import { selectOrganisationId } from '@/app/slices/organisationSlice'
import PathTypeLegend from '@/common/components/PathTypeLegend/PathTypeLegend'
import ShortcutsLegend from '@/common/components/ShortcutsLegend/ShortcutsLegend'

import DrawingRenderer from './Renderers/DrawingRenderer'
import ZoomControls from './ZoomControls'

const classes = {
    drawingContainer: {
        flex: '1 1 auto',
        boxSizing: 'border-box',
        position: 'relative',
    },

    selectArea: (theme) => ({
        borderWidth: '1px',
        borderStyle: 'dashed',
        borderColor: theme.palette.primary.main,
        backgroundColor: theme.palette.action.focus,
        zIndex: 1400,
    }),
}

const DrawingDoctor = ({
    drawing,
    onRedoClick,
    onSelectPath,
    onUndoClick,
    originalCadSvg,
    quoteItemId,
    selectedPaths,
    showCadView = false,
    showLegend = false,
    showShortcuts = false,
}) => {
    const params = useParams()
    const { quoteId } = params
    const theme = useTheme()

    const organisationId = useSelector(selectOrganisationId)
    const { quoteItem } = useGetQuoteItemsQuery(
        {
            organisationId,
            quoteId,
        },
        {
            selectFromResult: ({ data }) => ({
                quoteItem: data?.find((item) => item.id === quoteItemId),
            }),
        }
    )

    const [showOpenPathCheckbox, setShowOpenPathCheckbox] = useState(false)

    const [viewBox, setViewBox] = useState({
        minX: 0,
        minY: 0,
        width: 0,
        height: 0,
    })

    const [cadViewBox, setCadViewBox] = useState({
        minX: 0,
        minY: 0,
        width: 0,
        height: 0,
    })

    const platform = window.navigator.platform.toLowerCase()

    const isMacOS = platform.indexOf('mac') !== -1

    const keyModifierEvent = isMacOS ? 'metaKey' : 'ctrlKey'

    const handlePathsSelected = (pathIds, added) => {
        if (added) {
            const result = pathIds.reduce(function (res, item) {
                if (!res[item]) {
                    res[item] = drawing.paths[item]
                } else {
                    // if we are adding paths, if the path already exists it gets deselected
                    delete res[item]
                }

                return res
            }, selectedPaths)
            onSelectPath((prev) => ({ ...prev, ...result }))
        } else {
            onSelectPath(Object.fromEntries(Object.entries(drawing.paths).filter(([k, _]) => pathIds.includes(k))))
        }
    }

    // Keyboard shortcuts
    // useKeyboardShortcut(['control', 'z'], onUndoClick)
    // useKeyboardShortcut(['meta', 'z'], onUndoClick)
    // useKeyboardShortcut(['control', 'y'], onRedoClick)
    // useKeyboardShortcut(['meta', 'shift', 'z'], onRedoClick)

    useEffect(() => {
        if (!drawing) {
            return
        }

        const newViewBox = {
            minX: drawing.viewBoxMinX !== 0 ? (drawing.viewBoxMinX ?? drawing.minX) : drawing.minX,
            minY: drawing.viewBoxMinY !== 0 ? (drawing.viewBoxMinY ?? drawing.minY) : drawing.minY,
            width: drawing.viewBoxWidth !== 0 ? (drawing.viewBoxWidth ?? drawing.width) : drawing.width,
            height: drawing.viewBoxHeight !== 0 ? (drawing.viewBoxHeight ?? drawing.height) : drawing.height,
        }

        // Apply a margin
        const viewBoxMargin = 0.02
        newViewBox.minX -= viewBoxMargin * newViewBox.width
        newViewBox.minY -= viewBoxMargin * newViewBox.height
        newViewBox.width *= 1 + 2 * viewBoxMargin
        newViewBox.height *= 1 + 2 * viewBoxMargin

        const drawingHasOpenPath = !!drawing.issues?.find(
            (issue) => issue.issueType === 'OpenInner' || issue.issueType === 'OpenOuter'
        )

        setViewBox(newViewBox)
        setShowOpenPathCheckbox(drawingHasOpenPath)
    }, [drawing])

    useEffect(() => {
        if (!originalCadSvg) {
            return
        }

        const cad = new DOMParser().parseFromString(originalCadSvg, 'image/svg+xml')

        const newViewBox = {
            minX: cad.documentElement.viewBox.baseVal.x,
            minY: cad.documentElement.viewBox.baseVal.y,
            width: cad.documentElement.viewBox.baseVal.width,
            height: cad.documentElement.viewBox.baseVal.height,
        }

        // Apply a margin
        const viewBoxMargin = 0.02
        newViewBox.minX -= viewBoxMargin * newViewBox.width
        newViewBox.minY -= viewBoxMargin * newViewBox.height
        newViewBox.width *= 1 + 2 * viewBoxMargin
        newViewBox.height *= 1 + 2 * viewBoxMargin

        setCadViewBox(newViewBox)
    }, [originalCadSvg])

    useEffect(() => {
        const keyDownHandler = (event) => {
            if (event.key === 'Escape') {
                event.preventDefault()
                event.stopPropagation()
                handlePathsSelected([], false)
            }

            if (event.key === 'a' && event[keyModifierEvent]) {
                event.preventDefault()
                event.stopPropagation()
                handlePathsSelected(Object.keys(drawing.paths), true)
            }
        }

        document.addEventListener('keydown', keyDownHandler)

        return () => {
            document.removeEventListener('keydown', keyDownHandler)
        }
    }, [handlePathsSelected])

    useEffect(() => {
        const handleContextMenu = (e) => {
            e.preventDefault()
        }

        const rootElement = document.querySelector('#drawing-doctor')

        rootElement.addEventListener('contextmenu', handleContextMenu)

        return () => {
            rootElement.removeEventListener('contextmenu', handleContextMenu)
        }
    }, [])

    return (
        <TransformWrapper
            centerZoomedOut={false}
            maxScale={16}
            minScale={0.4}
            panning={{
                allowLeftClickPan: false,
                allowMiddleClickPan: true,
                allowRightClickPan: false,
            }}
            wheel={{ smoothStep: 0.008 }}
            onPanningStart={() => {
                document.querySelector('.react-transform-wrapper').style.cursor = 'grabbing'
            }}
            onPanningStop={() => {
                document.querySelector('.react-transform-wrapper').style.cursor = 'crosshair'
            }}
        >
            {({ resetTransform, zoomIn, zoomOut }) => (
                <Box
                    id="drawing-doctor"
                    sx={classes.drawingContainer}
                    onAuxClick={(e) => {
                        if (e.button === 1 && e.detail === 2) {
                            resetTransform()
                        }
                    }}
                >
                    {showLegend ? <PathTypeLegend /> : null}

                    {showShortcuts ? <ShortcutsLegend /> : null}

                    <ZoomControls
                        onZoomIn={zoomIn}
                        onZoomOut={zoomOut}
                        onZoomReset={resetTransform}
                    />
                    {showCadView && quoteItem?.modelViewUri ? (
                        <TransformComponent
                            contentStyle={{
                                width: '100%',
                                height: '100%',
                            }}
                            wrapperStyle={{
                                width: '100%',
                                height: 'calc(100vh - 72px)',
                                cursor: 'crosshair',
                            }}
                        >
                            <Box
                                component="img"
                                src={quoteItem?.modelViewUri}
                                style={{
                                    width: '100%',
                                }}
                            />
                        </TransformComponent>
                    ) : (
                        <TransformComponent
                            contentStyle={{
                                width: '100%',
                                height: '100%',
                            }}
                            wrapperStyle={{
                                width: '100%',
                                height: 'calc(100vh - 72px)',
                                cursor: 'crosshair',
                            }}
                        >
                            <ClassNames>
                                {({ css }) => (
                                    <DrawingRenderer
                                        cadViewBox={cadViewBox}
                                        drawing={drawing}
                                        originalCadSvg={originalCadSvg}
                                        selectAreaClassName={css(classes.selectArea(theme))}
                                        selectedPaths={selectedPaths}
                                        showCad={showCadView}
                                        showOpenPathHighlight={showOpenPathCheckbox}
                                        viewBox={viewBox}
                                        onPathsSelected={handlePathsSelected}
                                    />
                                )}
                            </ClassNames>
                        </TransformComponent>
                    )}
                </Box>
            )}
        </TransformWrapper>
    )
}

DrawingDoctor.propTypes = {
    disabled: PropTypes.bool,
    drawing: PropTypes.object,
    originalCadSvg: PropTypes.oneOfType([PropTypes.string, PropTypes.object]),
    quoteItemId: PropTypes.string,
    selectedPaths: PropTypes.object,
    showCadView: PropTypes.bool,
    showLegend: PropTypes.bool,
    showShortcuts: PropTypes.bool,
    onRedoClick: PropTypes.func,
    onSelectPath: PropTypes.func,
    onUndoClick: PropTypes.func,
}

export default DrawingDoctor
