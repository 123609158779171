import { useCallback, useMemo, useRef, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useSelector } from 'react-redux'
import {
    closestCenter,
    DndContext,
    DragOverlay,
    KeyboardSensor,
    PointerSensor,
    useSensor,
    useSensors,
} from '@dnd-kit/core'
import { restrictToVerticalAxis } from '@dnd-kit/modifiers'
import { arrayMove, SortableContext, sortableKeyboardCoordinates, verticalListSortingStrategy } from '@dnd-kit/sortable'
import {
    Box,
    Button,
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableHead,
    TablePagination,
    TableRow,
    Typography,
} from '@mui/material'
import Grid from '@mui/material/Unstable_Grid2'
import { useSnackbar } from 'notistack'
import useLocalStorage from 'use-local-storage'

import {
    useArchiveDeliveryZoneMutation,
    useGetDeliveryZonesQuery,
    useUnarchiveDeliveryZoneMutation,
    useUpdateDeliveryZonesMutation,
} from '@/app/services/deliveryZones'
import { selectOrganisationId } from '@/app/slices/organisationSlice'
import { AlertDialog, TbxShowToggle } from '@/common/components'
import { AddIcon } from '@/common/icons'
import { ZONES_PER_PAGE_DEFAULT_VALUE, ZONES_PER_PAGE_OPTIONS, ZONES_PER_PAGE_VARIABLE } from '@/common/utils'

import AddDeliveryZoneDialog from './AddDeliveryZoneDialog'
import DeliveryZoneOverlay from './DeliveryZoneOverlay'
import DeliveryZonesRow from './DeliveryZonesRow'

const classes = {
    tableContainer: {
        background: (theme) => theme.palette.background.paper,
        border: (theme) => `1px solid ${theme.palette.grey[400]}`,
        boxSizing: 'border-box',
        borderRadius: 2,
    },
    table: {
        tableLayout: 'fixed',
        boxSizing: 'border-box',
        borderCollapse: 'separate',
    },
    headerTableCell: {
        paddingBlock: 2,
        paddingInline: 2,
        verticalAlign: 'bottom',
    },
    tableRow: {
        '&:hover': {
            background: (theme) => theme.palette.action.hover,
        },
    },
    tableCell: {
        paddingBlock: 2,
        paddingInline: 2,
    },
    tableCellWithEllipsis: {
        overflow: 'hidden',
        whiteSpace: 'nowrap',
        textOverflow: 'ellipsis',
    },
    tableFooter: {
        display: 'flex',
        alignItems: 'center',
        flexDirection: 'row-reverse',
    },
    iconColor: (active = true) => ({
        color: active ? 'secondary.main' : 'text.disabled',
    }),
    activeRate: (active = true) => ({
        color: active ? 'text.primary' : 'text.disabled',
    }),
}

const DeliveryZones = () => {
    const { t } = useTranslation()
    const { enqueueSnackbar } = useSnackbar()

    const organisationId = useSelector(selectOrganisationId)

    const [activeZoneId, setActiveZoneId] = useState(null)
    const [activeZoneName, setActiveZoneName] = useState(null)
    const [showArchivedZones, setShowArchivedZones] = useState(false)
    const [showAddDeliveryZoneDialog, setShowAddDeliveryZoneDialog] = useState(false)
    const [showArchiveDeliveryZoneDialog, setShowArchiveDeliveryZoneDialog] = useState(false)
    const [showUnarchiveDeliveryZoneDialog, setShowUnarchiveDeliveryZoneDialog] = useState(false)
    const [currentPage, setCurrentPage] = useState(0)

    const zoneRef = useRef(null)

    const [currentRowsPerPage, setCurrentRowsPerPage] = useLocalStorage(
        ZONES_PER_PAGE_VARIABLE,
        ZONES_PER_PAGE_DEFAULT_VALUE
    )

    const {
        data: deliveryZones,
        isLoading: isLoadingZones,
        refetch,
    } = useGetDeliveryZonesQuery({
        organisationId,
        params: { includeDeleted: showArchivedZones },
    })
    const [archiveDeliveryZone] = useArchiveDeliveryZoneMutation()
    const [unarchiveDeliveryZone] = useUnarchiveDeliveryZoneMutation()
    const [updateDeliveryZones] = useUpdateDeliveryZonesMutation()

    const deliveryZonesIds = useMemo(() => {
        if (isLoadingZones) return []
        return deliveryZones?.map((zone) => zone?.zoneId) ?? []
    }, [deliveryZones, isLoadingZones])

    const sensors = useSensors(
        useSensor(PointerSensor),
        useSensor(KeyboardSensor, {
            coordinateGetter: sortableKeyboardCoordinates,
        })
    )

    const maxIndex = Math.max(-1, ...(deliveryZones?.filter((z) => !z.isDeleted)?.map((z) => z.index ?? -1) ?? []))

    const handleChangePage = (_, newPage) => {
        setCurrentPage(newPage)
    }

    const handleChangeRowsPerPage = (event) => {
        const newRowsPerPage = parseInt(event.target.value, 10)
        setCurrentRowsPerPage(newRowsPerPage)
        setCurrentPage(0)
    }

    const handleShowArchivedZonesToggle = () => {
        setShowArchivedZones((prev) => !prev)
        refetch()
    }

    const handleAddDeliveryZoneButtonClick = () => {
        setShowAddDeliveryZoneDialog(true)
    }

    const handleEditDeliveryZoneButtonClick = (zone) => {
        zoneRef.current = zone
        setShowAddDeliveryZoneDialog(true)
    }

    const handleCloseAddDeliveryZoneDialog = () => {
        zoneRef.current = null
        setShowAddDeliveryZoneDialog(false)
    }

    const handleArchiveDeliveryZoneButtonClick = (zone) => {
        zoneRef.current = zone
        setShowArchiveDeliveryZoneDialog(true)
    }

    const handleUnarchiveDeliveryZoneButtonClick = (zone) => {
        zoneRef.current = zone
        setShowUnarchiveDeliveryZoneDialog(true)
    }

    const handleCancelArchive = () => {
        zoneRef.current = null
        setShowArchiveDeliveryZoneDialog(false)
        setShowUnarchiveDeliveryZoneDialog(false)
    }

    const handleArchiveConfirmation = async () => {
        try {
            await archiveDeliveryZone({ organisationId, zoneId: zoneRef.current.zoneId }).unwrap()
            enqueueSnackbar(t('Delivery zone archived successfully'), { variant: 'success' })
        } catch (error) {
            console.error(error)
            enqueueSnackbar(t('Failed to archive delivery zone'), { variant: 'error' })
        } finally {
            zoneRef.current = null
            setShowArchiveDeliveryZoneDialog(false)
        }
    }

    const handleUnarchiveConfirmation = async () => {
        try {
            await unarchiveDeliveryZone({
                organisationId,
                zoneId: zoneRef.current.zoneId,
            }).unwrap()
            enqueueSnackbar(t('Delivery zone unarchive successfully'), { variant: 'success' })
        } catch (error) {
            console.error(error)
            enqueueSnackbar(t('Failed to unarchive delivery zone'), { variant: 'error' })
        } finally {
            zoneRef.current = null
            setShowUnarchiveDeliveryZoneDialog(false)
        }
    }

    const handleUpdateDeliveryZones = async (sortedZones) => {
        try {
            await updateDeliveryZones({ organisationId, showArchived: showArchivedZones, zones: sortedZones }).unwrap()
            enqueueSnackbar(t('Delivery zones updated successfully'), { variant: 'success' })
        } catch (error) {
            console.error(error)
            enqueueSnackbar(t('Failed to update delivery zones'), { variant: 'error' })
        }
    }

    const onDragStart = (event) => {
        const id = event.active.id
        setActiveZoneId(id)

        const activeItem = deliveryZones.find((zone) => zone.zoneId === id)
        setActiveZoneName(activeItem.name)
    }

    const onDragEnd = useCallback(
        (event) => {
            const { active, over } = event

            if (!active || !over) {
                return
            }

            if (active.id === over.id) {
                return
            }

            const oldIndex = active?.data?.current?.sortable?.index

            const newIndex = over.data.current.sortable.index

            const zones = structuredClone(arrayMove(deliveryZones, oldIndex, newIndex))

            zones?.forEach((zone, index) => {
                zone.index = index
            })

            handleUpdateDeliveryZones(zones)
        },
        [deliveryZones]
    )

    return (
        <>
            <Grid xs={12}>
                <Typography
                    data-testid="delivery-zones"
                    mb={1}
                    variant="strong1"
                >
                    {t('Delivery zones')}
                </Typography>
                <Typography
                    color="text.secondary"
                    mb={3}
                    variant="body2"
                >
                    {t(
                        "Each delivery zone has its own set of delivery prices. Create a zone for each location group you want to set prices for. If a customer's delivery address falls within multiple zones, the zone highest on the list is selected."
                    )}
                </Typography>

                <Box
                    alignItems="center"
                    display="flex"
                    justifyContent="end"
                >
                    <Box
                        mr={3}
                        width="fit-content"
                    >
                        <TbxShowToggle
                            checked={showArchivedZones}
                            data-testid="show-archived-rates-toggle"
                            label={t('Show archived zones')}
                            onChange={handleShowArchivedZonesToggle}
                        />
                    </Box>

                    <Button
                        color="secondary"
                        data-testid="add-delivery-zone-button"
                        size="small"
                        startIcon={
                            <AddIcon
                                size={18}
                                type="sharp"
                                variant="solid"
                            />
                        }
                        variant="outlined"
                        onClick={handleAddDeliveryZoneButtonClick}
                    >
                        {t('Add new zone')}
                    </Button>
                </Box>
            </Grid>
            {!isLoadingZones ? (
                <Grid xs={12}>
                    <TableContainer sx={classes.tableContainer}>
                        <Table sx={classes.table}>
                            <colgroup>
                                <col style={{ width: '180px' }} />
                                <col style={{ width: '220px' }} />
                                <col style={{ width: '80px' }} />
                            </colgroup>

                            <TableHead>
                                <TableRow>
                                    <TableCell sx={classes.headerTableCell}>{t('Zone')}</TableCell>
                                    <TableCell sx={classes.headerTableCell}>{t('Region(s)')}</TableCell>
                                    <TableCell />
                                </TableRow>
                            </TableHead>
                            <TableBody>
                                <DndContext
                                    collisionDetection={closestCenter}
                                    modifiers={[restrictToVerticalAxis]}
                                    sensors={sensors}
                                    onDragEnd={onDragEnd}
                                    onDragStart={onDragStart}
                                >
                                    {deliveryZones?.length ? (
                                        <>
                                            <SortableContext
                                                items={deliveryZonesIds}
                                                strategy={verticalListSortingStrategy}
                                            >
                                                {deliveryZones
                                                    ?.slice(
                                                        currentPage * currentRowsPerPage,
                                                        currentPage * currentRowsPerPage + currentRowsPerPage
                                                    )
                                                    ?.map((zone) => {
                                                        return (
                                                            <DeliveryZonesRow
                                                                handleArchiveButtonClick={
                                                                    handleArchiveDeliveryZoneButtonClick
                                                                }
                                                                handleEditButtonClick={
                                                                    handleEditDeliveryZoneButtonClick
                                                                }
                                                                handleUnarchiveButtonClick={
                                                                    handleUnarchiveDeliveryZoneButtonClick
                                                                }
                                                                id={zone.zoneId}
                                                                index={zone.index}
                                                                key={zone.zoneId}
                                                                zone={zone}
                                                            />
                                                        )
                                                    })}
                                            </SortableContext>
                                            <DragOverlay wrapperElement="tr">
                                                {activeZoneId ? (
                                                    <DeliveryZoneOverlay
                                                        id={activeZoneId}
                                                        name={activeZoneName}
                                                    />
                                                ) : null}
                                            </DragOverlay>
                                        </>
                                    ) : (
                                        <TableRow>
                                            <TableCell
                                                align="center"
                                                colSpan={3}
                                                sx={classes.tableCell}
                                            >
                                                {t('No delivery zones found')}
                                            </TableCell>
                                        </TableRow>
                                    )}
                                </DndContext>
                            </TableBody>
                        </Table>

                        <TablePagination
                            component="div"
                            count={deliveryZones?.length ?? 0}
                            labelDisplayedRows={({ count, from, to }) => `${from}-${to} of ${count}`}
                            labelRowsPerPage={t('Zones per page')}
                            page={currentPage}
                            rowsPerPage={currentRowsPerPage}
                            rowsPerPageOptions={ZONES_PER_PAGE_OPTIONS}
                            sx={classes.tableFooter}
                            onPageChange={handleChangePage}
                            onRowsPerPageChange={handleChangeRowsPerPage}
                        />
                    </TableContainer>
                </Grid>
            ) : null}

            {showAddDeliveryZoneDialog ? (
                <AddDeliveryZoneDialog
                    deliveryZone={zoneRef.current}
                    nextIndex={maxIndex + 1}
                    open={showAddDeliveryZoneDialog}
                    onClose={handleCloseAddDeliveryZoneDialog}
                />
            ) : null}

            {showArchiveDeliveryZoneDialog ? (
                <AlertDialog
                    content={t('Are you sure want to archive {{zoneName}} zone from your organisation?', {
                        zoneName: zoneRef.current?.name,
                    })}
                    okButtonText={t('Yes, Archive')}
                    open={showArchiveDeliveryZoneDialog}
                    title={t('Archive delivery zone')}
                    onCancelClose={handleCancelArchive}
                    onOkClose={handleArchiveConfirmation}
                />
            ) : null}

            {showUnarchiveDeliveryZoneDialog ? (
                <AlertDialog
                    content={t('Are you sure want to unarchive {{zoneName}} zone from your organisation?', {
                        zoneName: zoneRef.current?.name,
                    })}
                    okButtonText={t('Yes, Unarchive')}
                    open={showUnarchiveDeliveryZoneDialog}
                    title={t('Unarchive delivery zone')}
                    onCancelClose={handleCancelArchive}
                    onOkClose={handleUnarchiveConfirmation}
                />
            ) : null}
        </>
    )
}

DeliveryZones.propTypes = {}

export default DeliveryZones
