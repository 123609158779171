import { Trans, useTranslation } from 'react-i18next'
import { useSelector } from 'react-redux'
import { Box, Button, Link } from '@mui/material'
import PropTypes from 'prop-types'

import { useGetSecondaryProcessesQuery } from '@/app/services/secondaryProcesses'
import { selectIsOnFreePlan, selectOrganisationId, selectReduceTrialAccess } from '@/app/slices/organisationSlice'
import { TbxTooltip } from '@/common/components'
import TbxToolbar from '@/common/components/TbxToolbar/TbxToolbar'
import TbxToolbarActions from '@/common/components/TbxToolbar/TbxToolbarActions'
import TbxToolbarTitle from '@/common/components/TbxToolbar/TbxToolbarTitle'
import { useToolBoxTreatments } from '@/common/hooks'

import TbxToolbarMessages from '../../TbxToolbar/TbxToolbarMessages'
import UpgradePlanLink from '../../UpgradePlanLink/UpgradePlanLink'

const classes = {
    actions: {
        display: 'flex',
        gap: 2,
    },
}

const ProcessesToolbar = ({
    canAddCuttingTech,
    handleAddCuttingTechClick,
    handleAddSecondaryProcessClick,
    isLoading,
}) => {
    const { t } = useTranslation()

    const { showCuttingTechManagement } = useToolBoxTreatments()

    const reduceTrialAccess = useSelector(selectReduceTrialAccess)
    const isOnFreePlan = useSelector(selectIsOnFreePlan)
    const organisationId = useSelector(selectOrganisationId)

    const { data: secondaryProcesses } = useGetSecondaryProcessesQuery({
        organisationId,
        requestParams: { includeDeleted: false },
    })

    const secondaryProcessCount = secondaryProcesses?.length ?? 0

    return (
        <TbxToolbar>
            <TbxToolbarTitle>{t('Processes')}</TbxToolbarTitle>

            <TbxToolbarMessages />

            <TbxToolbarActions>
                {showCuttingTechManagement ? (
                    <Box sx={classes.actions}>
                        <TbxTooltip
                            title={
                                isOnFreePlan ? (
                                    secondaryProcessCount > 0 && (
                                        <Trans t={t}>
                                            You are on a Free Plan which only allows 1 custom secondary process. Please
                                            archive your existing process in order to create a new one.
                                        </Trans>
                                    )
                                ) : reduceTrialAccess ? (
                                    <Trans t={t}>
                                        Not available in trial mode. <Link href="/activate">Subscribe here.</Link>
                                    </Trans>
                                ) : (
                                    ''
                                )
                            }
                            arrow
                        >
                            <span>
                                <Button
                                    color="secondary"
                                    data-testid="cancel-processes-update"
                                    disabled={isOnFreePlan ? secondaryProcessCount > 0 : reduceTrialAccess}
                                    sx={classes.cancelButton}
                                    variant="outlined"
                                    onClick={handleAddSecondaryProcessClick}
                                >
                                    {t('Add secondary process')}
                                </Button>
                            </span>
                        </TbxTooltip>

                        <TbxTooltip
                            title={
                                isOnFreePlan && secondaryProcessCount > 0 ? (
                                    <Trans t={t}>
                                        Not available in free mode. <Link href="/activate">Subscribe here.</Link>
                                    </Trans>
                                ) : reduceTrialAccess ? (
                                    <Trans t={t}>
                                        Not available in trial mode. <Link href="/activate">Subscribe here.</Link>
                                    </Trans>
                                ) : !canAddCuttingTech ? (
                                    <UpgradePlanLink />
                                ) : (
                                    ''
                                )
                            }
                            arrow
                        >
                            <span>
                                <Button
                                    color="primary"
                                    data-testid="update-processes"
                                    disabled={!canAddCuttingTech || reduceTrialAccess}
                                    variant="contained"
                                    onClick={isLoading ? null : handleAddCuttingTechClick}
                                >
                                    {t('Add Cutting Technology')}
                                </Button>
                            </span>
                        </TbxTooltip>
                    </Box>
                ) : null}
            </TbxToolbarActions>
        </TbxToolbar>
    )
}

ProcessesToolbar.propTypes = {
    canAddCuttingTech: PropTypes.bool,
    handleAddCuttingTechClick: PropTypes.func,
    handleAddSecondaryProcessClick: PropTypes.func,
    handleCancelUpdateClick: PropTypes.func,
    handleUpdateProcessesClick: PropTypes.func,
    isLoading: PropTypes.bool,
    isUpdateButtonDisabled: PropTypes.bool,
}

export default ProcessesToolbar
