import { useTranslation } from 'react-i18next'
import Chip from '@mui/material/Chip'
import PropTypes from 'prop-types'

import { GET_STATUS_LABEL, QUOTE_STATUS } from '@/common/utils'

const statusChipColor = (status) => {
    switch (status) {
        case (status = QUOTE_STATUS.NotCalculated):
            return 'text.secondary'
        case (status = QUOTE_STATUS.Calculated):
            return 'text.secondary'
        case (status = QUOTE_STATUS.Draft):
            return 'warning.main'
        case (status = QUOTE_STATUS.PendingOrderConfirmation):
            return 'info.dark'
        case (status = QUOTE_STATUS.Issued):
            return 'info.main'
        case (status = QUOTE_STATUS.Ordered):
            return 'success.light'
        case (status = QUOTE_STATUS.Invoiced):
            return 'success.main'
        case (status = QUOTE_STATUS.Dispatched):
            return 'success.dark'
        case (status = QUOTE_STATUS.Lost):
            return 'error.light'
        case (status = QUOTE_STATUS.Cancelled):
            return 'error.main'
        case (status = QUOTE_STATUS.Rejected):
            return 'error.dark'
        case (status = QUOTE_STATUS.Voided):
            return 'error.dark'
        default:
            return 'secondary'
    }
}
const QuoteStatusChip = ({ quoteStatus }) => {
    const { t } = useTranslation()

    return (
        <Chip
            label={t(GET_STATUS_LABEL(quoteStatus))}
            size="small"
            sx={{
                textTransform: 'uppercase',
                fontSize: '11px',
                backgroundColor: statusChipColor(quoteStatus),
                color: 'white',
                userSelect: 'none',
            }}
            title={t(GET_STATUS_LABEL(quoteStatus))}
        />
    )
}

QuoteStatusChip.propTypes = {
    quoteStatus: PropTypes.string,
}

export default QuoteStatusChip
