import { useTranslation } from 'react-i18next'
import { Save, Undo } from '@mui/icons-material'
import { Button, CircularProgress } from '@mui/material'
import PropTypes from 'prop-types'

import TbxToolbar from '@/common/components/TbxToolbar/TbxToolbar'
import TbxToolbarActions from '@/common/components/TbxToolbar/TbxToolbarActions'
import TbxToolbarDivider from '@/common/components/TbxToolbar/TbxToolbarDivider'
import TbxToolbarTitle from '@/common/components/TbxToolbar/TbxToolbarTitle'

import TbxToolbarMessages from '../../TbxToolbar/TbxToolbarMessages'

const SettingsToolbar = ({
    enableCancelButton = false,
    enableSaveButton = false,
    handleCancelClick,
    handleSaveClick,
    isLoading = false,
    title = 'Settings',
}) => {
    const { t } = useTranslation()

    return (
        <TbxToolbar>
            <TbxToolbarTitle>{t(title)}</TbxToolbarTitle>

            <TbxToolbarMessages />

            <TbxToolbarActions>
                <Button
                    color="secondary"
                    data-testid="reset-settings"
                    disabled={!enableCancelButton || isLoading}
                    startIcon={<Undo />}
                    variant="outlined"
                    onClick={handleCancelClick}
                >
                    {t('Cancel')}
                </Button>
                <TbxToolbarDivider />
                <Button
                    color="primary"
                    data-testid="save-settings"
                    disabled={!enableSaveButton || isLoading}
                    startIcon={
                        isLoading ? (
                            <CircularProgress
                                color="primary"
                                size={20}
                            />
                        ) : (
                            <Save />
                        )
                    }
                    variant="contained"
                    onClick={handleSaveClick}
                >
                    {isLoading ? t('Saving') : t('Save changes')}
                </Button>
            </TbxToolbarActions>
        </TbxToolbar>
    )
}

SettingsToolbar.propTypes = {
    enableCancelButton: PropTypes.bool,
    enableSaveButton: PropTypes.bool,
    handleCancelClick: PropTypes.func,
    handleSaveClick: PropTypes.func,
    isLoading: PropTypes.bool,
    title: PropTypes.string,
}

export default SettingsToolbar
