import { useMemo, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { Tune } from '@mui/icons-material'
import Button from '@mui/material/Button'
import PropTypes from 'prop-types'

import { QUOTE_STATUS } from '@/common/utils/Constants/Constants'

import PriceSettingsDialog from '../PriceSettingsDialog/PriceSettingsDialog'

const QuotePriceAdjustment = ({ quote, quoteItems }) => {
    const { t } = useTranslation()
    const [showPriceSettingsDialog, setShowPriceSettingsDialog] = useState(false)

    const handlePriceAdjustmentsClicked = () => {
        setShowPriceSettingsDialog(true)
    }

    const handlePriceSettingsDialogClose = () => {
        setShowPriceSettingsDialog(false)
    }

    const disabledPriceAdjustmenButton = useMemo(() => {
        return ![QUOTE_STATUS.NotCalculated, QUOTE_STATUS.Calculated, QUOTE_STATUS.Draft].includes(quote?.status)
    }, [quote?.status])

    return (
        <>
            <Button
                color="primary"
                data-testid="price-adjustments-button"
                disabled={disabledPriceAdjustmenButton}
                size="small"
                startIcon={<Tune />}
                variant="outlined"
                onClick={handlePriceAdjustmentsClicked}
            >
                {t('Price Adjustments')}
            </Button>

            {showPriceSettingsDialog ? (
                <PriceSettingsDialog
                    open={showPriceSettingsDialog}
                    quote={quote}
                    quoteItems={quoteItems}
                    onClose={handlePriceSettingsDialogClose}
                />
            ) : null}
        </>
    )
}

QuotePriceAdjustment.propTypes = {
    quote: PropTypes.object,
    quoteItems: PropTypes.array,
}

export default QuotePriceAdjustment
