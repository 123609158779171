import { EventType, LogLevel, PublicClientApplication } from '@azure/msal-browser'

import { Paths } from './common/utils'

// Browser check variables
// If you support IE, our recommendation is that you sign-in using Redirect APIs
// If you as a developer are testing using Edge InPrivate mode, please add "isEdge" to the if check
const ua = window.navigator.userAgent
const msie = ua.indexOf('MSIE ')
const msie11 = ua.indexOf('Trident/')
const msedge = ua.indexOf('Edge/')
const firefox = ua.indexOf('Firefox')
const isIE = msie > 0 || msie11 > 0
const isEdge = msedge > 0
const isFirefox = firefox > 0 // Only needed if you need to support the redirect flow in Firefox incognito

const tenantSubdomain = import.meta.env.VITE_AUTH_TENANT_SUBDOMAIN
const signInPolicy = import.meta.env.VITE_AUTH_SIGN_IN_POLICY
const signUpPolicy = import.meta.env.VITE_AUTH_SIGN_UP_POLICY
const xeroPolicy = import.meta.env.VITE_AUTH_XERO_POLICY
const resetPasswordPolicy = import.meta.env.VITE_AUTH_RESET_PASSWORD_POLICY
const editProfilePolicy = import.meta.env.VITE_AUTH_EDIT_PROFILE_POLICY
const clientId = import.meta.env.VITE_AUTH_CLIENT_ID
const cacheLocation = import.meta.env.VITE_AUTH_CACHE_LOCATION

// With the changes below, we are allowing redirects to whatever subdomain has loaded the webstore app as well as
// allowing loading the app from the main domain

const redirectUri = `https://${window.location.host}` // we are no longer using import.meta.env.VITE_AUTH_REDIRECT_URI here
const registerRedirectUri = `${redirectUri}/register` // not using import.meta.env.VITE_AUTH_REGISTER_REDIRECT_URI anymore
const postLogoutRedirectUri = `${redirectUri}/signout` // not using import.meta.env.VITE_POSTLOGOUT_REDIRECT_URI either

const tenant = `${tenantSubdomain}.onmicrosoft.com`
const b2cInstance = `https://${tenantSubdomain}.b2clogin.com/tfp/`
const scopes = [`https://${tenant}/api/quotemate.api.read`, `https://${tenant}/api/quotemate.api.write`]
const signInAuthority = `${b2cInstance}${tenant}/${signInPolicy}`
const signUpAuthority = `${b2cInstance}${tenant}/${signUpPolicy}`
const xeroAuthority = `${b2cInstance}${tenant}/${xeroPolicy}`
const resetPasswordAuthority = `${b2cInstance}${tenant}/${resetPasswordPolicy}`
const editProfileAuthority = `${b2cInstance}${tenant}/${editProfilePolicy}`
const logLevel = import.meta.env.VITE_ENV === 'development' ? LogLevel.Warning : LogLevel.Error

const params = new URLSearchParams(window.location.search)
const hasXero = params.has('xero')
const authority = hasXero ? xeroAuthority : signInAuthority

export const msalConfig = {
    auth: {
        clientId: clientId,
        authority: authority,
        knownAuthorities: [signInAuthority, xeroAuthority],
        validateAuthority: false,
        redirectUri: redirectUri,
        postLogoutRedirectUri: postLogoutRedirectUri,
        scopes: scopes,
    },
    cache: {
        cacheLocation,
        storeAuthStateInCookie: isIE || isEdge || isFirefox,
    },
    system: {
        loggerOptions: {
            logLevel: logLevel,
            loggerCallback: (level, message, containsPii) => {
                if (containsPii) {
                    return
                }
                switch (level) {
                    case LogLevel.Error:
                        console.error(message)
                        return
                    case LogLevel.Info:
                        console.info(message)
                        return
                    case LogLevel.Verbose:
                        console.debug(message)
                        return
                    case LogLevel.Warning:
                        console.warn(message)
                        return
                    default:
                        return
                }
            },
        },
    },
}

// Add here scopes for id token to be used at MS Identity Platform endpoints.
export const loginRequest = {
    scopes,
    authority: authority,
}

export const resetPasswordRequest = {
    scopes,
    authority: resetPasswordAuthority,
}

export const editProfileRequest = {
    scopes,
    authority: editProfileAuthority,
}

export const signUpRequest = {
    scopes,
    authority: signUpAuthority,
    redirectUri: registerRedirectUri,
}

export const tokenRequest = {
    scopes,
}

export const msalInstance = new PublicClientApplication(msalConfig)

msalInstance.enableAccountStorageEvents()

// Account selection logic is app dependent. Adjust as needed for different use cases.
const accounts = msalInstance.getAllAccounts()
if (accounts && accounts.length > 0) {
    msalInstance.setActiveAccount(accounts[0])
}

msalInstance.addEventCallback((message) => {
    if (message?.eventType === EventType.LOGIN_SUCCESS && message?.payload?.account) {
        const account = message.payload.account
        msalInstance.setActiveAccount(account)
    } else if (message?.eventType === EventType.ACCOUNT_REMOVED) {
        msalInstance.setActiveAccount(null)
        window.location.href = Paths.SIGNOUT_PATHNAME
    } else if (message.eventType === EventType.ACTIVE_ACCOUNT_CHANGED) {
        const activeAccount = msalInstance.getActiveAccount()
        if (activeAccount) {
            msalInstance.setActiveAccount(activeAccount)
        }
        window.location.href = Paths.DASHBOARD_PATHNAME
    }
})
