import { useTranslation } from 'react-i18next'
import { Check } from '@mui/icons-material'
import { Box, List, ListItem, ListItemIcon, ListItemText, ListSubheader } from '@mui/material'

const classes = {
    listItemIconRoot: {
        minWidth: 'initial',
        paddingRight: 1.5,
    },
}

const FileRequirementsList = () => {
    const { t } = useTranslation()

    return (
        <Box
            alignItems="center"
            display="flex"
            flexDirection="column"
            justifyContent="center"
        >
            <List
                subheader={
                    <ListSubheader
                        color="primary"
                        disableSticky
                    >
                        {t('File Requirements')}
                    </ListSubheader>
                }
                disablePadding
            >
                <ListItem dense>
                    <ListItemIcon sx={classes.listItemIconRoot}>
                        <Check color="primary" />
                    </ListItemIcon>
                    <ListItemText
                        id="cutting"
                        primary={t('2D parts only, as DXF or DWG')}
                    />
                </ListItem>

                <ListItem dense>
                    <ListItemIcon sx={classes.listItemIconRoot}>
                        <Check color="primary" />
                    </ListItemIcon>
                    <ListItemText
                        id="cutting"
                        primary={t(
                            'Files should only contain the cut path of your parts (no borders, side views, fold lines, etc)'
                        )}
                    />
                </ListItem>

                <ListItem dense>
                    <ListItemIcon sx={classes.listItemIconRoot}>
                        <Check color="primary" />
                    </ListItemIcon>
                    <ListItemText
                        id="cutting"
                        primary={t('Parts should be scaled to their true size, 1:1')}
                    />
                </ListItem>

                <ListItem dense>
                    <ListItemIcon sx={classes.listItemIconRoot}>
                        <Check color="primary" />
                    </ListItemIcon>
                    <ListItemText
                        id="cutting"
                        primary={t('Files should not contain any Z-coordinates')}
                    />
                </ListItem>

                <ListItem dense>
                    <ListItemIcon sx={classes.listItemIconRoot}>
                        <Check color="primary" />
                    </ListItemIcon>
                    <ListItemText
                        id="cutting"
                        primary={t('Parts should be enclosed without open outers')}
                    />
                </ListItem>

                <ListItem dense>
                    <ListItemIcon sx={classes.listItemIconRoot}>
                        <Check color="primary" />
                    </ListItemIcon>
                    <ListItemText
                        id="cutting"
                        primary={t('Convert all text to shapes/outlines')}
                    />
                </ListItem>

                <ListItem dense>
                    <ListItemIcon sx={classes.listItemIconRoot}>
                        <Check color="primary" />
                    </ListItemIcon>
                    <ListItemText
                        id="cutting"
                        primary={t('Shapes and text should be connected with bridges')}
                    />
                </ListItem>
            </List>
        </Box>
    )
}

export default FileRequirementsList
