import { useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useDispatch, useSelector } from 'react-redux'
import { ListItem, ListItemText, Switch } from '@mui/material'
import { useSnackbar } from 'notistack'

import { useUpdateUserMutation } from '@/app/services/user'
import { selectCurrentUser, setCurrentUser } from '@/app/slices/appSlice'
import { showTooltipsDisplay } from '@/common/utils'

export const UserflowTooltipsToggle = () => {
    const { t } = useTranslation()
    const { enqueueSnackbar } = useSnackbar()

    const currentUser = useSelector(selectCurrentUser)

    const [isEnabled, setIsEnabled] = useState(currentUser?.showUserflowTooltips)
    const dispatch = useDispatch()

    const [updateUser] = useUpdateUserMutation()

    const handleChange = async (event) => {
        const { checked } = event.target
        const user = {
            firstName: currentUser.firstName,
            lastName: currentUser.lastName,
            language: currentUser.language,
            emailAddress: currentUser.emailAddress,
            showUserflowTooltips: checked,
        }
        try {
            setIsEnabled(checked)
            showTooltipsDisplay(currentUser.userId, checked)
            const updatedUser = await updateUser({ userId: currentUser.userId, user }).unwrap()
            dispatch(setCurrentUser(updatedUser))
            enqueueSnackbar(t('Setting updated successfully.'), { variant: 'success' })
        } catch (error) {
            enqueueSnackbar(t('Could not update this setting.'), { variant: 'error' })
            setIsEnabled(!checked)
        }
    }

    return (
        <ListItem>
            <ListItemText
                id="switch-help-tooltips-label"
                primary={t('Show help tooltips')}
            />
            <Switch
                checked={isEnabled}
                edge="end"
                inputProps={{ 'aria-labelledby': 'switch-help-tooltips-label' }}
                size="small"
                onChange={handleChange}
            />
        </ListItem>
    )
}
