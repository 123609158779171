import { useCallback, useEffect, useMemo, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useDispatch, useSelector } from 'react-redux'
import { useNavigate } from 'react-router-dom'
import { Add } from '@mui/icons-material'
import { Box, Button, Typography } from '@mui/material'
import { useSnackbar } from 'notistack'
import { v4 as uuidv4 } from 'uuid'

import { useGetCuttingTechnologiesQuery } from '@/app/services/cuttingTechnologies'
import { useCreateMaterialMutation, useGetMaterialsQuery, useLazyGetMaterialQuery } from '@/app/services/materials'
import { useGetRateTablesQuery } from '@/app/services/rateTables'
import { useUpdateSheetMutation } from '@/app/services/sheets'
import { setShowDrawerMenu, setShowNestedDrawerMenu } from '@/app/slices/appSlice'
import {
    selectSelectedMaterialId,
    selectShowAddMaterialForm,
    setSelectedMaterialId,
    setShowAddMaterialForm,
} from '@/app/slices/materialsSlice'
import { selectOrganisationId } from '@/app/slices/organisationSlice'
import { TbxDrawer, TbxShowToggle } from '@/common/components'
import { MaterialsToolbar } from '@/common/components/MainAppBar'
import { Paths } from '@/common/utils'

import AddMaterialForm from '../components/AddMaterialForm'
import AddSheet from '../components/AddSheet'
import DuplicateMaterialDialog from '../components/DuplicateMaterialDialog'
import MaterialDetails from '../components/MaterialDetails'
import MaterialHeader from '../components/MaterialHeader'
import SheetsTable from '../components/SheetsTable'

const classes = {
    root: {
        display: 'flex',
        flexDirection: 'column',
        minHeight: 'calc(100vh - 64px)',
        padding: '24px 30px',
        backgroundColor: '#FAFAFA',
    },
    header: {
        display: 'flex',
        flexDirection: 'row',
        justifyContent: 'space-between',
        marginBottom: '12px',
    },

    details: {
        display: 'flex',
        flexDirection: 'row',
        alignItems: 'stretch',
        margin: '12px 0',
    },

    actionBar: {
        display: 'flex',
        justifyContent: 'space-between',
        margin: '12px 0',
    },

    noMaterialText: {
        fontSize: '34px',
        lineHeight: '123.5%',
        color: 'rgba(52, 73, 94, 0.38)',
    },
    noMaterialContainer: {
        display: 'flex',
        maxWidth: '450px',
        height: 'calc(100vh - 64px)',
        justifyContent: 'center',
        alignItems: 'center',
        textAlign: 'center',
        background: 'url(toolbox-box.png)',
        backgroundRepeat: 'no-repeat',
        backgroundPosition: 'center',
    },
}

const Materials = () => {
    const { t } = useTranslation()
    const { enqueueSnackbar } = useSnackbar()
    const dispatch = useDispatch()
    const navigate = useNavigate()

    const organisationId = useSelector(selectOrganisationId)

    useGetCuttingTechnologiesQuery({ organisationId })
    useGetRateTablesQuery({ organisationId })
    const [getMaterial] = useLazyGetMaterialQuery()
    const { data: materials, isLoading } = useGetMaterialsQuery({ organisationId })

    const selectedMaterialId = useSelector(selectSelectedMaterialId)
    const showAddMaterialForm = useSelector(selectShowAddMaterialForm)

    const selectedMaterial = useMemo(() => {
        return materials?.[selectedMaterialId]
    }, [selectedMaterialId, materials])

    const [createMaterial] = useCreateMaterialMutation()

    const [updateSheet] = useUpdateSheetMutation()

    const [showAddSheetDrawer, setShowAddSheetDrawer] = useState(false)
    const [showArchivedSheets, setShowArchivedSheets] = useState(false)
    const [showDuplicateMaterialDialog, setShowDuplicateMaterialDialog] = useState(false)

    const handleShowArchivedSheetsChange = () => {
        setShowArchivedSheets(!showArchivedSheets)
    }

    const handleDuplicateMaterialClick = () => {
        setShowDuplicateMaterialDialog(true)
    }

    const handleConfirmCreateDuplicateMaterial = async (materialName) => {
        const newMaterialId = uuidv4()
        try {
            await createMaterial({
                organisationId,
                material: {
                    ...selectedMaterial,
                    materialId: newMaterialId,
                    materialName: materialName,
                    sheets: [],
                },
            }).unwrap()
            if (selectedMaterial?.sheetIds?.length > 0) {
                const material = await getMaterial({ organisationId, materialId: selectedMaterialId }).unwrap()
                material?.sheets.forEach(
                    async (sheet) =>
                        await updateSheet({
                            organisationId,
                            sheet: {
                                ...sheet,
                                materialId: newMaterialId,
                                sheetId: uuidv4(),
                            },
                        }).unwrap()
                )
            }

            dispatch(setSelectedMaterialId(newMaterialId))
            setShowDuplicateMaterialDialog(false)
        } catch (error) {
            enqueueSnackbar(t(error.response?.data || 'An error occurred'), {
                variant: 'error',
            })
        }
    }

    const handleAddSheetClick = () => {
        setShowAddSheetDrawer(!showAddSheetDrawer)
    }
    const handleAddSheetClose = () => {
        setShowAddSheetDrawer(false)
    }

    const handleAddMaterialClick = () => {
        dispatch(setShowAddMaterialForm(true))
    }

    const handleAddMaterialFormClose = (newMaterialId) => {
        dispatch(setShowAddMaterialForm(false))
        dispatch(setSelectedMaterialId(newMaterialId))
    }

    const handleAddMaterialDrawerClose = () => {
        dispatch(setShowAddMaterialForm(false))
    }

    const handleRateTablesClick = () => {
        navigate(Paths.RATE_TABLES)
    }

    const materialIs = useCallback(
        (materialType) => {
            return selectedMaterial?.type === materialType
        },
        [selectedMaterial]
    )

    useEffect(() => {
        if (!selectedMaterialId) {
            dispatch(setShowDrawerMenu(true))
            dispatch(setShowNestedDrawerMenu('materials'))
        }
        return () => {
            dispatch(setSelectedMaterialId(null))
        }
    }, [])

    return !isLoading ? (
        <>
            <MaterialsToolbar
                handleAddMaterialClick={handleAddMaterialClick}
                handleDuplicateMaterialClick={handleDuplicateMaterialClick}
                handleRateTablesClick={handleRateTablesClick}
            />

            {(selectedMaterialId && (
                <Box sx={classes.root}>
                    <Box sx={classes.header}>
                        <MaterialHeader />
                    </Box>
                    <Box sx={classes.details}>
                        <MaterialDetails />
                    </Box>
                    <Box sx={classes.actionBar}>
                        <Button
                            color="primary"
                            data-testid="add-sheet-button"
                            disabled={selectedMaterial?.isDeleted}
                            size="small"
                            startIcon={<Add />}
                            variant="outlined"
                            onClick={handleAddSheetClick}
                        >
                            {materialIs('Rotary') ? t('Add record') : t('Add sheet')}
                        </Button>
                        <TbxShowToggle
                            checked={showArchivedSheets}
                            label={materialIs('Rotary') ? t('Show archived records') : t('Show archived sheets')}
                            onChange={handleShowArchivedSheetsChange}
                        />
                    </Box>
                    <SheetsTable
                        selectedMaterialId={selectedMaterialId}
                        showArchivedSheets={showArchivedSheets}
                    />
                </Box>
            )) || (
                <Box sx={classes.noMaterialContainer}>
                    <Typography sx={classes.noMaterialText}>
                        {t('Select a material from the list to view its details.')}
                    </Typography>
                </Box>
            )}

            <TbxDrawer
                showDrawer={showAddMaterialForm}
                onClose={handleAddMaterialDrawerClose}
            >
                <AddMaterialForm
                    onCancel={handleAddMaterialDrawerClose}
                    onSave={handleAddMaterialFormClose}
                />
            </TbxDrawer>
            <TbxDrawer
                showDrawer={showAddSheetDrawer}
                onClose={handleAddSheetClose}
            >
                <AddSheet
                    selectedMaterialId={selectedMaterialId}
                    onCancel={handleAddSheetClose}
                    onClose={handleAddSheetClose}
                />
            </TbxDrawer>

            <DuplicateMaterialDialog
                placeholderName={t('Copy of') + ' ' + selectedMaterial?.materialName}
                showDialog={showDuplicateMaterialDialog}
                onClose={() => setShowDuplicateMaterialDialog(false)}
                onSave={handleConfirmCreateDuplicateMaterial}
            />
        </>
    ) : null
}

export default Materials
