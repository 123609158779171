import { Navigate, useParams } from 'react-router-dom'

const RedirectToQuote = () => {
    const { quoteId } = useParams()
    return (
        <Navigate
            to={`/quotes/${quoteId}`}
            replace
        />
    )
}

export default RedirectToQuote
