import { useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useIsAuthenticated } from '@azure/msal-react'
import { Box, Container } from '@mui/material'

import { selectAppBarState, setAppBarState } from '@/app/slices/appSlice'
import { MainAppBar } from '@/common/components/MainAppBar'

const classes = {
    layoutContainer: {
        display: 'flex',
        flexDirection: 'column',
        minWidth: 'fit-content',
    },
    contentContainer: {
        flexGrow: 1,
        backgroundColor: 'background.default',
        display: 'flex',
        alignItems: 'flex-start',
        justifyContent: 'center',
        '@media print': {
            alignItems: 'start',
        },
    },
}

const Layout = ({ children }) => {
    const userIsAuthenticated = useIsAuthenticated()
    const dispatch = useDispatch()

    const appBarState = useSelector(selectAppBarState)

    useEffect(() => {
        if (!userIsAuthenticated) {
            dispatch(
                setAppBarState({
                    menuHidden: true,
                    hidden: true,
                    myAccountHidden: true,
                    showUpdatePaymentBar: false,
                    showSubscriptionBar: false,
                    showTrialWithSubscriptionBar: false,
                })
            )
        }
    }, [userIsAuthenticated, setAppBarState])

    return (
        <Box sx={classes.layoutContainer}>
            {!appBarState?.hidden ? <MainAppBar /> : null}
            <Container
                disableGutters={true}
                maxWidth={false}
                sx={!appBarState?.hidden ? classes.contentContainer : null}
            >
                {children}
            </Container>
        </Box>
    )
}

export default Layout
