import { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useSelector } from 'react-redux'
import { useParams } from 'react-router-dom'
import { Box, MenuItem, Select, TextField, Typography } from '@mui/material'
import { useSnackbar } from 'notistack'
import PropTypes from 'prop-types'
import { useDebouncedCallback } from 'use-debounce'

import {
    useGetQuoteItemsQuery,
    useUpdateQuoteItemDrawingMutation,
    useUpdateQuoteItemMutation,
} from '@/app/services/quoteItems'
import { selectOrganisationId } from '@/app/slices/organisationSlice'
import TbxTooltip from '@/common/components/TbxTooltip/TbxTooltip'

const PartDetails = ({ drawing, quoteItemId }) => {
    const { t } = useTranslation()
    const { enqueueSnackbar } = useSnackbar()
    const { quoteId } = useParams()

    const organisationId = useSelector(selectOrganisationId)

    const { quoteItem } = useGetQuoteItemsQuery(
        {
            organisationId,
            quoteId,
        },
        {
            selectFromResult: ({ data }) => ({
                quoteItem: data?.find((item) => item.id === quoteItemId),
            }),
        }
    )

    const [updateQuoteItem] = useUpdateQuoteItemMutation()
    const [updateQuoteItemDrawing] = useUpdateQuoteItemDrawingMutation({ fixedCacheKey: 'updateQuoteItemDrawing' })

    const isPartFromLibrary = Boolean(quoteItem?.partLibraryEntryId)

    const [itemUnit, setItemUnit] = useState(quoteItem?.isImperial ? 'in' : 'mm')
    const [itemName, setItemName] = useState(quoteItem?.name || '')
    const [itemNotes, setItemNotes] = useState(quoteItem?.vendorNotes || '')

    const handleUpdateQuoteItem = async (quoteItem) => {
        try {
            await updateQuoteItem({
                organisationId,
                quoteId,
                quoteItemId: quoteItem?.id,
                quoteItem,
            }).unwrap()
        } catch (error) {
            enqueueSnackbar(t('Failed to update the $t(quote) item'), {
                variant: 'error',
            })
        }
    }

    const debounceHandleUpdateQuoteItem = useDebouncedCallback(handleUpdateQuoteItem, 1000)

    const handleNameChange = async (event) => {
        const value = event?.target?.value
        setItemName(value)
        if (Boolean(value) && itemName !== value) {
            await debounceHandleUpdateQuoteItem({ ...quoteItem, name: value })
        }
    }

    const handleNotesChange = async (event) => {
        const value = event?.target?.value
        setItemNotes(value)
        if (Boolean(value) && itemNotes !== value) {
            await debounceHandleUpdateQuoteItem({ ...quoteItem, vendorNotes: value })
        }
    }

    const handleUnitChange = async (event) => {
        const value = event?.target?.value
        if (Boolean(value) && itemUnit !== value) {
            setItemUnit(value)
            const isImperial = value === 'in'
            await handleUpdateQuoteItem({ ...quoteItem, isImperial: isImperial })
            await updateQuoteItemDrawing({ ...drawing, isImperial: isImperial })
        }
    }

    useEffect(() => {
        setItemUnit(quoteItem?.isImperial ? 'in' : 'mm')
        setItemName(quoteItem?.name || '')
        setItemNotes(quoteItem?.vendorNotes || '')
    }, [quoteItem])

    return (
        <Box>
            {isPartFromLibrary ? (
                <Box
                    alignItems={'center'}
                    display={'flex'}
                    flexDirection={'row'}
                    gap={1}
                    justifyContent={'space-between'}
                    margin={1}
                >
                    <Box flex={1}>
                        <Typography>{t('Part ID')}</Typography>
                    </Box>
                    <Box flex={2}>
                        <Typography>{quoteItem?.partId}</Typography>
                    </Box>
                </Box>
            ) : null}
            <Box
                alignItems={'center'}
                display={'flex'}
                flexDirection={'row'}
                gap={1}
                justifyContent={'space-between'}
                margin={1}
            >
                <Box flex={1}>
                    <Typography>{t('Part Name')}</Typography>
                </Box>
                <TbxTooltip
                    title={isPartFromLibrary ? t('Cannot edit parts from library') : ''}
                    arrow
                >
                    <Box flex={2}>
                        <TextField
                            disabled={isPartFromLibrary}
                            inputProps={{
                                'data-testid': 'drawingdoctordrawer-partdetails-partname-input',
                                sx: { color: 'white' },
                            }}
                            size="small"
                            sx={{ width: '100%' }}
                            value={itemName}
                            variant="outlined"
                            onBlur={handleNameChange}
                            onChange={handleNameChange}
                        />
                    </Box>
                </TbxTooltip>
            </Box>
            <Box
                alignItems={'center'}
                display={'flex'}
                flexDirection={'row'}
                gap={1}
                justifyContent={'space-between'}
                margin={1}
            >
                <Box flex={1}>
                    <Typography>{t('Units')}</Typography>
                </Box>
                <TbxTooltip
                    title={isPartFromLibrary ? t('Cannot edit parts from library') : ''}
                    arrow
                >
                    <Box flex={2}>
                        <Select
                            color="primary"
                            disabled={isPartFromLibrary}
                            id="select-itemUnit"
                            inputProps={{
                                'data-testid': 'drawingdoctordrawer-partdetails-units-input',
                            }}
                            labelId="select-itemUnit-label"
                            size="small"
                            sx={{ width: '100%' }}
                            value={itemUnit}
                            variant="outlined"
                            onBlur={handleUnitChange}
                            onChange={handleUnitChange}
                        >
                            <MenuItem value="mm">{t('Millimeters')}</MenuItem>
                            <MenuItem value="in">{t('Inches')}</MenuItem>
                        </Select>
                    </Box>
                </TbxTooltip>
            </Box>
            <Box
                alignItems={'top'}
                display={'flex'}
                flexDirection={'row'}
                gap={1}
                justifyContent={'space-between'}
                margin={1}
            >
                <Box flex={1}>
                    <Typography>{t('Notes')}</Typography>
                </Box>
                <Box flex={2}>
                    <TextField
                        color="primary"
                        inputProps={{
                            'data-testid': 'drawingdoctordrawer-partdetails-notes-input',
                        }}
                        maxRows={4}
                        minRows={4}
                        sx={{ width: '100%' }}
                        value={itemNotes}
                        variant="outlined"
                        multiline
                        onBlur={handleNotesChange}
                        onChange={handleNotesChange}
                    />
                </Box>
            </Box>
        </Box>
    )
}

PartDetails.propTypes = {
    drawing: PropTypes.object,
    quoteItemId: PropTypes.string,
}

export default PartDetails
