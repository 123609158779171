import { useCallback } from 'react'
import { useDropzone } from 'react-dropzone'
import { useTranslation } from 'react-i18next'
import { Box, Button, Typography } from '@mui/material'
import PropTypes from 'prop-types'

import DropZoneIcon from './dropZoneIcon'

const classes = {
    root: {
        gap: 2,
        background: '#FFFFFF',
        border: (theme) => `1px dashed ${theme.palette.primary.main}`,
        borderRadius: 1,
        flex: '1 0 auto',
        cursor: 'pointer',
    },
    dropTextContainer: {
        gap: 1,
    },
    smallText: {
        fontSize: '0.75rem',
    },
}

const QuoteItemDropzone = ({ isUploading = false, onFilesAccepted, showHeading = false }) => {
    const { t } = useTranslation()

    const onDrop = useCallback((acceptedFiles) => {
        onFilesAccepted(acceptedFiles)
    }, [])

    const { getInputProps, getRootProps, isDragActive } = useDropzone({
        onDrop,
        accept: ['.dxf', '.dwg'],
        disabled: isUploading,
    })

    return (
        <Box
            alignItems="center"
            display="flex"
            flexDirection="column"
            justifyContent="space-around"
            p={3}
            sx={classes.root}
            {...getRootProps()}
        >
            <input {...getInputProps()} />
            {showHeading ? (
                <Typography variant="body1">{t('Start your $t(quote) by uploading a part.')}</Typography>
            ) : null}
            <DropZoneIcon
                color="primary"
                style={{ fontSize: 54 }}
            />
            <Box
                alignItems="center"
                display="flex"
                flexDirection="column"
                sx={classes.dropTextContainer}
            >
                <Typography variant="body1">
                    {t(isDragActive ? 'Drop here...' : 'Drop your files here to add them')}
                </Typography>
                <Typography
                    sx={classes.smallText}
                    variant="body2"
                >
                    {t('or')}
                </Typography>
                <Button
                    color="primary"
                    variant="contained"
                >
                    {t('Browse files')}
                </Button>
            </Box>
            <Typography
                sx={classes.smallText}
                variant="body2"
            >
                {t('We accept DXF and DWG files for instant $t(quote)')}
            </Typography>
        </Box>
    )
}

QuoteItemDropzone.propTypes = {
    isUploading: PropTypes.bool,
    showHeading: PropTypes.bool,
    onFilesAccepted: PropTypes.func,
}

export default QuoteItemDropzone
