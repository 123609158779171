import { AppBar as MuiAppBar, Toolbar as MuiToolbar } from '@mui/material'
import PropTypes from 'prop-types'

const AppBar = ({ children, className, classes, color = 'default', position = 'fixed' }) => {
    return (
        <MuiAppBar
            classes={classes}
            color={color}
            elevation={4}
            position={position}
            sx={className}
        >
            <MuiToolbar>{children}</MuiToolbar>
        </MuiAppBar>
    )
}

AppBar.propTypes = {
    children: PropTypes.node,
    classes: PropTypes.object,
    className: PropTypes.object,
    color: PropTypes.oneOf(['default', 'inherit', 'primary', 'secondary', 'transparent']),
    position: PropTypes.oneOf(['absolute', 'fixed', 'relative', 'static', 'sticky']),
}

export default AppBar
