import { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { NumericFormat } from 'react-number-format'
import { useSelector } from 'react-redux'
import { useParams } from 'react-router-dom'
import {
    Box,
    Button,
    CircularProgress,
    Dialog,
    DialogActions,
    DialogContent,
    DialogTitle,
    TextField,
    Typography,
} from '@mui/material'
import { useSnackbar } from 'notistack'
import PropTypes from 'prop-types'

import { useScaleQuoteItemDrawingMutation } from '@/app/services/quoteItems'
import { selectLocale } from '@/app/slices/appSlice'
import { selectOrganisationId } from '@/app/slices/organisationSlice'
import { selectQuoteItemDrawingId } from '@/app/slices/quoteItemsSlice'
import { formatNumber } from '@/common/helpers/formatUtilities'
import { getNumberFormat } from '@/common/utils'

const ScaleDrawingModal = ({ drawingDimensions, handleClose, open }) => {
    const { t } = useTranslation()
    const { enqueueSnackbar } = useSnackbar()
    const params = useParams()
    const { quoteId } = params

    const organisationId = useSelector(selectOrganisationId)
    const quoteItemId = useSelector(selectQuoteItemDrawingId)
    const locale = useSelector(selectLocale)

    const [scaleDrawing] = useScaleQuoteItemDrawingMutation()

    const [isScalingDrawing, setIsScalingDrawing] = useState(false)
    const [scaleFactor, setScaleFactor] = useState(1)
    const [scaledDrawingDimensions, setScaledDrawingDimensions] = useState({
        width: drawingDimensions.width,
        height: drawingDimensions.height,
    })

    const handleScaleFactorChange = (values) => {
        const newScaleFactor = values.floatValue
        setScaleFactor(newScaleFactor)
    }

    const handleScaleDrawing = async () => {
        setIsScalingDrawing(true)
        try {
            // Call the API to scale the drawing
            await scaleDrawing({
                organisationId,
                quoteId,
                quoteItemId,
                scaleFactor,
            }).unwrap()

            enqueueSnackbar(t('Drawing scaled successfully.'), {
                variant: 'success',
            })
        } catch (error) {
            enqueueSnackbar(t('Failed to scale drawing.'), {
                variant: 'error',
            })
        } finally {
            setIsScalingDrawing(false)
            handleCancel()
        }
    }

    const handleCancel = () => {
        setScaleFactor(1)
        setScaledDrawingDimensions({
            width: drawingDimensions.width,
            height: drawingDimensions.height,
        })
        handleClose()
    }

    const numberFormat = getNumberFormat(locale)
    const decimalPlaces = numberFormat['decimalPlaces']
    const groupSeparator = numberFormat['group']
    const decimalSeparator = groupSeparator === '.' ? ',' : '.'

    useEffect(() => {
        setScaledDrawingDimensions({
            width: drawingDimensions.width * scaleFactor || drawingDimensions.width,
            height: drawingDimensions.height * scaleFactor || drawingDimensions.height,
        })
    }, [scaleFactor, drawingDimensions.width, drawingDimensions.height])

    return (
        <Dialog
            maxWidth="sm"
            open={open}
            PaperProps={{
                sx: {
                    padding: 1,
                },
            }}
            onClose={handleCancel}
        >
            <DialogTitle>{t('Scale Drawing')}</DialogTitle>
            <DialogContent sx={{ gap: 3, display: 'flex', flexDirection: 'column' }}>
                <Box>
                    <NumericFormat
                        allowNegative={false}
                        customInput={TextField}
                        decimalScale={decimalPlaces}
                        decimalSeparator={decimalSeparator}
                        disabled={isScalingDrawing}
                        error={!scaleFactor}
                        helperText={
                            !scaleFactor
                                ? t('Enter a scale factor greater than 0.')
                                : t('Enter a scale factor to resize the drawing.')
                        }
                        InputLabelProps={{
                            'data-testid': 'scale-drawing-input-label',
                        }}
                        inputProps={{
                            'data-testid': 'scale-drawing-input',
                        }}
                        label={t('Scale factor')}
                        min={0.1}
                        sx={{
                            '& .MuiInputLabel-root.Mui-error, & .MuiFormHelperText-root.Mui-error, & .Mui-error::before':
                                {
                                    color: '#6a0c0c',
                                    borderBottomColor: '#6a0c0c',
                                },
                        }}
                        thousandSeparator={groupSeparator}
                        value={scaleFactor}
                        fullWidth
                        onValueChange={handleScaleFactorChange}
                    />
                </Box>
                <Box
                    display="flex"
                    flexDirection="column"
                    gap={1}
                >
                    <Typography variant="h6">{t('Selection bounds')}</Typography>

                    <Box
                        display="flex"
                        gap={2}
                        justifyContent="space-between"
                    >
                        <Typography>{t('Current dimensions')}:</Typography>
                        <Typography>
                            {formatNumber(drawingDimensions.width, locale)} x{' '}
                            {formatNumber(drawingDimensions.height, locale)}
                        </Typography>
                    </Box>

                    <Box
                        display="flex"
                        gap={2}
                        justifyContent="space-between"
                    >
                        <Typography variant="strong1">{t('Scaled dimensions')}:</Typography>
                        <Typography variant="strong1">
                            {formatNumber(scaledDrawingDimensions.width, locale)} x{' '}
                            {formatNumber(scaledDrawingDimensions.height, locale)}
                        </Typography>
                    </Box>
                </Box>
            </DialogContent>
            <DialogActions sx={{ gap: 1, padding: 2 }}>
                <Button
                    color="secondary"
                    disabled={isScalingDrawing}
                    variant="contained"
                    onClick={handleCancel}
                >
                    {t('No, go back')}
                </Button>
                <Button
                    color="primary"
                    disabled={isScalingDrawing || !scaleFactor || scaleFactor === 1}
                    variant="contained"
                    onClick={handleScaleDrawing}
                >
                    {isScalingDrawing ? t('Scaling drawing...') : t('Yes, scale drawing')}
                    {isScalingDrawing ? (
                        <CircularProgress
                            size={20}
                            sx={{ ml: 2 }}
                        />
                    ) : null}
                </Button>
            </DialogActions>
        </Dialog>
    )
}

ScaleDrawingModal.propTypes = {
    drawingDimensions: PropTypes.object.isRequired,
    handleClose: PropTypes.func.isRequired,
    open: PropTypes.bool.isRequired,
}

export default ScaleDrawingModal
