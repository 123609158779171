import { useEffect, useRef } from 'react'
import { Controller, useForm } from 'react-hook-form'
import { useTranslation } from 'react-i18next'
import { useDispatch, useSelector } from 'react-redux'
import { Button, TextField } from '@mui/material'
import { useSnackbar } from 'notistack'
import PropTypes from 'prop-types'

import { useDuplicateRateTableMutation, useValidateRateTableNameMutation } from '@/app/services/rateTables'
import { selectOrganisationId } from '@/app/slices/organisationSlice'
import { setSelectedRateTableId } from '@/app/slices/rateTablesSlice'
import { Dialog } from '@/common/components'

const classes = {
    dialog: {
        width: 680,
    },
    textInput: {
        fontSize: '20px',
        lineHeight: '32px',
        color: 'text.primary',
    },
    cancelButton: {
        marginRight: 1,
    },
}

const DuplicateRateTableDialog = ({ onClose, rateTable, showDialog = false }) => {
    const { t } = useTranslation()
    const dispatch = useDispatch()
    const { enqueueSnackbar } = useSnackbar()

    const organisationId = useSelector(selectOrganisationId)
    const [duplicateRateTable] = useDuplicateRateTableMutation()
    const [validateRateTableName] = useValidateRateTableNameMutation()

    const isMountedRef = useRef(true)

    useEffect(() => {
        return () => {
            isMountedRef.current = false
        }
    }, [])

    const {
        control,
        formState: { isValid },
        getValues,
        reset,
        trigger,
    } = useForm({
        mode: 'onChange',
        defaultValues: {
            nameValue: '',
        },
    })

    const validateName = async (name) => {
        const requestBody = {
            cuttingTechnologyId: rateTable.cuttingTechnologyId,
            name: name,
        }
        const result = await validateRateTableName({ organisationId, requestBody })
        return result || t('This name is not available to be used with this cutting technology.')
    }

    useEffect(() => {
        setTimeout(() => {
            if (isMountedRef.current && rateTable) {
                reset({ nameValue: `${t('Copy of')} ${rateTable.name}` })
                trigger()
            }
        })
    }, [rateTable, trigger, reset, t])

    const handleDuplicateButtonClick = async () => {
        if (isValid) {
            try {
                const newRateTable = await duplicateRateTable({
                    organisationId,
                    rateTableId: rateTable.rateTableId,
                    newRateTableName: getValues('nameValue'),
                }).unwrap()

                dispatch(setSelectedRateTableId(newRateTable.rateTableId))

                enqueueSnackbar(t('Rate table duplicated successfully'), {
                    variant: 'success',
                })
            } catch (error) {
                enqueueSnackbar(t('An error ocurred while duplicating the rate table'), {
                    variant: 'error',
                })
            } finally {
                onClose()
            }
        }
    }

    return (
        <Dialog
            actions={[
                <Button
                    color="secondary"
                    key="cancel"
                    sx={classes.cancelButton}
                    variant="outlined"
                    onClick={onClose}
                >
                    {t('Cancel')}
                </Button>,
                <Button
                    color="primary"
                    disabled={!isValid}
                    key="add"
                    variant="contained"
                    onClick={handleDuplicateButtonClick}
                >
                    {t('Duplicate')}
                </Button>,
            ]}
            className={classes.dialog}
            open={showDialog}
            title={`${t('Duplicating rate table')}: ${rateTable?.name} (${t(rateTable?.cuttingTechnology?.name)})`}
            onClose={onClose}
        >
            {rateTable ? (
                <Controller
                    control={control}
                    name="nameValue"
                    render={({ field: { onBlur, onChange, value }, fieldState }) => (
                        <TextField
                            error={fieldState.invalid}
                            helperText={fieldState.error?.message || t('Please choose a name for the new rate table')}
                            InputProps={{
                                sx: classes.textInput,
                            }}
                            label={t('New table rate name')}
                            value={value}
                            fullWidth
                            onBlur={onBlur}
                            onChange={onChange}
                        />
                    )}
                    rules={{
                        required: true,
                        validate: validateName,
                    }}
                />
            ) : null}
        </Dialog>
    )
}

DuplicateRateTableDialog.propTypes = {
    rateTable: PropTypes.object,
    showDialog: PropTypes.bool,
    onClose: PropTypes.func,
}

export default DuplicateRateTableDialog
