import { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useSelector } from 'react-redux'
import { Close } from '@mui/icons-material'
import { Box, Dialog, DialogContent, IconButton, MenuItem, TextField } from '@mui/material'
import MuiDialogTitle from '@mui/material/DialogTitle'
import { DesktopDatePicker } from '@mui/x-date-pickers/DesktopDatePicker'
import dayjs from 'dayjs'
import { useSnackbar } from 'notistack'
import PropTypes from 'prop-types'

import { useLazyGetPartLibraryEntryQuotesQuery } from '@/app/services/partLibrary'
import { selectOrganisationId } from '@/app/slices/organisationSlice'
import TbxLocalizationProvider from '@/common/components/TbxLocalizationProvider/TbxLocalizationProvider'

import PartLibraryQuoteDetailsTable from './PartLibraryQuoteDetailsTable'

const classes = {
    root: {
        margin: 0,
        padding: 2,
    },
    quoteHistotyDialog: {
        display: 'flex',
        width: '100%',
        gap: 3,
        mb: 3,
    },
    textFieldProjectStaus: {
        width: '17%',
    },
    dialogPaper: {
        minWidth: '90%',
        maxWidth: '90%',
        minHeight: '90%',
        maxHeight: '90%',
    },
    textFieldDate: {
        width: '170px',
        height: '32px',
        marginTop: 0,
    },
    closeButton: {
        position: 'absolute',
        right: 1,
        top: 1,
        color: (theme) => theme.palette.grey[500],
    },
}

const DialogTitle = ({ children, onClose, ...other }) => {
    return (
        <MuiDialogTitle
            sx={classes.root}
            {...other}
        >
            {children}
            {onClose ? (
                <IconButton
                    aria-label="close"
                    size="large"
                    sx={classes.closeButton}
                    onClick={onClose}
                >
                    <Close />
                </IconButton>
            ) : null}
        </MuiDialogTitle>
    )
}

DialogTitle.propTypes = {
    onClose: PropTypes.func.isRequired,
    children: PropTypes.node,
}

const PartQuoteHistoryDialog = ({ handleClose, open, partLibrary }) => {
    const { t } = useTranslation()
    const { enqueueSnackbar } = useSnackbar()

    const organisationId = useSelector(selectOrganisationId)

    const [getPartLibraryEntryQuotes] = useLazyGetPartLibraryEntryQuotesQuery()

    const [title, setTitle] = useState('')
    const [partQuoteHistory, setPartQuoteHistory] = useState([])
    const [quoteStatuses, setQuoteStatuses] = useState([])
    const [selectedStatusFilter, setSelectedStatusFilter] = useState('All')
    const [selectedPeriodFromFilter, setSelectedPeriodFromFilter] = useState(null)
    const [selectedPeriodToFilter, setSelectedPeriodToFilter] = useState(null)

    const userTimeZone = dayjs.tz.guess()

    const handleProjectMoreDetailsClick = async () => {
        try {
            const response = await getPartLibraryEntryQuotes({
                organisationId,
                partLibraryId: partLibrary.partLibraryId,
            }).unwrap()

            setTitle(partLibrary.partId + ' - ' + partLibrary.name)
            const uniqueProjectStatus = [...new Set(response.map((item) => item.quote.status))]
            uniqueProjectStatus.unshift('All')
            setQuoteStatuses(uniqueProjectStatus)
            setPartQuoteHistory(response)
        } catch (error) {
            const errorMessage = t('$t(An error occurred) while loading part library entry $t(quotes)')
            enqueueSnackbar(errorMessage, { variant: 'error' })
        }
    }

    const handleChangeFilterByProjectStatus = (event) => {
        setSelectedStatusFilter(event.target.value)
    }

    const handleChangeFilterByProjectPeriodFrom = (date) => {
        setSelectedPeriodFromFilter(date?.utc()?.format() || null)
    }
    const handleChangeFilterByProjectPeriodTo = (date) => {
        setSelectedPeriodToFilter(date?.utc()?.format() || null)
    }

    const checkProjectStatusValueExist = (partQuoteHistotyItem) => {
        if (
            typeof partQuoteHistotyItem.quote.name !== 'undefined' &&
            partQuoteHistotyItem.quote.status === selectedStatusFilter
        )
            return partQuoteHistotyItem
        else return {}
    }

    const checkProjectLastQuotePeriodValueRangeExist = (partQuoteHistotyItem) => {
        if (typeof partQuoteHistotyItem.quote.lastModifiedDateUtc !== 'undefined') {
            const min = dayjs(selectedPeriodFromFilter)
            const max = dayjs(selectedPeriodToFilter)
            const lastModifiedDate = dayjs(partQuoteHistotyItem.quote.lastModifiedDateUtc)

            if (lastModifiedDate.isBetween(min, max, 'day', '[]')) return partQuoteHistotyItem
            else return {}
        } else return {}
    }

    const filteredPartQuotes = partQuoteHistory.filter((partQuoteHistotyItem) => {
        if (Object.keys(partQuoteHistotyItem).length > 0 && selectedStatusFilter !== 'All') {
            partQuoteHistotyItem = checkProjectStatusValueExist(partQuoteHistotyItem)
        }
        if (
            Object.keys(partQuoteHistotyItem).length > 0 &&
            selectedPeriodFromFilter != null &&
            selectedPeriodToFilter != null
        ) {
            partQuoteHistotyItem = checkProjectLastQuotePeriodValueRangeExist(partQuoteHistotyItem)
        }
        if (Object.keys(partQuoteHistotyItem).length > 0) return partQuoteHistotyItem
    })

    // Part Quote Details Histoy
    const partQuoteHistoryColumns = [
        {
            id: 'quoteName',
            numeric: false,
            disablePadding: false,
            label: t('$t(Quote) name'),
        },
        {
            id: 'status',
            numeric: false,
            disablePadding: false,
            label: t('Status'),
        },
        {
            id: 'quoteLastModified',
            numeric: false,
            disablePadding: false,
            label: t('$t(Quote) last modified'),
        },
        {
            id: 'cuttingTechnology',
            numeric: false,
            disablePadding: false,
            label: t('Cutting technology'),
        },
        {
            id: 'cuttingTime',
            numeric: true,
            disablePadding: false,
            label: t('Cutting time'),
        },
        {
            id: 'cuttingPrice',
            numeric: true,
            disablePadding: false,
            label: t('Cutting price'),
        },
        {
            id: 'materialMarkup',
            numeric: true,
            disablePadding: false,
            label: t('Material markup'),
        },
        {
            id: 'materialPrice',
            numeric: true,
            disablePadding: false,
            label: t('Material price'),
        },
        {
            id: 'secondaryProcess',
            numeric: true,
            disablePadding: false,
            label: t('Secondary processes'),
        },
        {
            id: 'otherCharges',
            numeric: true,
            disablePadding: false,
            label: t('Other charges'),
        },
        {
            id: 'unitPrice',
            numeric: true,
            disablePadding: false,
            label: t('Unit price'),
        },
        {
            id: 'quantity',
            numeric: true,
            disablePadding: false,
            label: t('Qty'),
        },
    ]

    useEffect(() => {
        if (open) {
            handleProjectMoreDetailsClick()
        }

        return () => {
            setTitle('')
            setQuoteStatuses([])
            setPartQuoteHistory([])
            setSelectedStatusFilter('All')
            setSelectedPeriodFromFilter(null)
            setSelectedPeriodToFilter(null)
        }
    }, [open])

    return (
        <Dialog
            aria-labelledby="part-quote-history-dialog"
            open={open}
            PaperProps={{ sx: classes.dialogPaper }}
            onClose={handleClose}
        >
            <DialogTitle
                id="quote-history-dialog-title"
                style={{ padding: '24px' }}
                onClose={handleClose}
            >
                {t('Part $t(quote) history: {{title}}', { title: title })}
            </DialogTitle>
            <DialogContent
                style={{ padding: '12px 24px', display: 'flex', flexDirection: 'column', overflow: 'hidden' }}
            >
                <Box sx={classes.quoteHistotyDialog}>
                    <TextField
                        id="outlined-filter-project-status"
                        label={t('$t(Quote) status')}
                        name=""
                        size="small"
                        sx={classes.textFieldProjectStaus}
                        value={selectedStatusFilter}
                        variant="outlined"
                        select
                        onChange={handleChangeFilterByProjectStatus}
                    >
                        {quoteStatuses.map((option) => (
                            <MenuItem
                                key={option}
                                value={option}
                            >
                                {t(option)}
                            </MenuItem>
                        ))}
                    </TextField>

                    <TbxLocalizationProvider>
                        <DesktopDatePicker
                            format="DD-MMM-YYYY"
                            label={t('From')}
                            maxDate={dayjs()}
                            slotProps={{
                                textField: {
                                    placeholder: 'From date',
                                    variant: 'outlined',
                                    size: 'small',
                                    InputLabelProps: {
                                        shrink: true,
                                    },
                                },
                                field: {
                                    clearable: true,
                                    onClear: () => {
                                        setSelectedPeriodFromFilter(null)
                                        setSelectedPeriodToFilter(null)
                                    },
                                },
                            }}
                            sx={[classes.textFieldDate, { mr: 2 }]}
                            timezone={userTimeZone}
                            value={selectedPeriodFromFilter ? dayjs(selectedPeriodFromFilter) : null}
                            onChange={handleChangeFilterByProjectPeriodFrom}
                        />

                        <DesktopDatePicker
                            disabled={selectedPeriodFromFilter ? false : true}
                            format="DD-MMM-YYYY"
                            label={t('To')}
                            maxDate={dayjs()}
                            minDate={selectedPeriodFromFilter ? dayjs(selectedPeriodFromFilter) : null}
                            slotProps={{
                                textField: {
                                    placeholder: 'To date',
                                    variant: 'outlined',
                                    size: 'small',
                                    InputLabelProps: {
                                        shrink: true,
                                    },
                                },
                                field: {
                                    clearable: true,
                                    onClear: () => {
                                        setSelectedPeriodFromFilter(null)
                                        setSelectedPeriodToFilter(null)
                                    },
                                },
                            }}
                            sx={classes.textFieldDate}
                            timezone={userTimeZone}
                            value={selectedPeriodToFilter ? dayjs(selectedPeriodToFilter) : null}
                            onChange={handleChangeFilterByProjectPeriodTo}
                        />
                    </TbxLocalizationProvider>
                </Box>
                <PartLibraryQuoteDetailsTable
                    columns={partQuoteHistoryColumns}
                    rows={filteredPartQuotes}
                />
            </DialogContent>
        </Dialog>
    )
}
PartQuoteHistoryDialog.propTypes = {
    handleClose: PropTypes.func,
    open: PropTypes.bool,
    partLibrary: PropTypes.object,
}

export default PartQuoteHistoryDialog
