import { useTranslation } from 'react-i18next'
import { Button, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle } from '@mui/material'
import PropTypes from 'prop-types'

const AlertDialog = ({
    buttonVariant = 'text',
    cancelButtonText = 'Cancel',
    content,
    hideCancelButton = false,
    hideOkButton = false,
    okButtonText = 'Ok',
    onCancelClose,
    onOkClose,
    open,
    title,
    ...rest
}) => {
    const { t } = useTranslation()

    return (
        <Dialog
            aria-describedby="alert-dialog-description"
            aria-labelledby="alert-dialog-title"
            open={open}
            onClose={onCancelClose}
            {...rest}
        >
            <DialogTitle id="alert-dialog-title">{title}</DialogTitle>
            <DialogContent>
                <DialogContentText id="alert-dialog-description">{content}</DialogContentText>
            </DialogContent>
            <DialogActions sx={{ gap: 1, padding: 2 }}>
                {!hideCancelButton ? (
                    <Button
                        color="secondary"
                        variant={buttonVariant}
                        autoFocus
                        onClick={onCancelClose}
                    >
                        {cancelButtonText}
                    </Button>
                ) : null}
                {!hideOkButton ? (
                    <Button
                        color="error"
                        variant={buttonVariant}
                        onClick={onOkClose}
                    >
                        {okButtonText}
                    </Button>
                ) : null}
            </DialogActions>
        </Dialog>
    )
}

AlertDialog.propTypes = {
    buttonVariant: PropTypes.oneOf(['text', 'contained', 'outlined']),
    cancelButtonText: PropTypes.string,
    content: PropTypes.oneOfType([PropTypes.object, PropTypes.string]),
    hideCancelButton: PropTypes.bool,
    hideOkButton: PropTypes.bool,
    okButtonText: PropTypes.string,
    open: PropTypes.bool,
    title: PropTypes.string,
    onCancelClose: PropTypes.func,
    onOkClose: PropTypes.func,
}

export default AlertDialog
