import { Minimize } from '@mui/icons-material'
import { Accordion, AccordionDetails, AccordionSummary, Box, Typography } from '@mui/material'
import PropTypes from 'prop-types'

const classes = {
    AccordionRoot: {
        borderTop: () => '1px solid hsl(0, 0%, 35%)',
        backgroundColor: 'background.default',
        '&:before': {
            display: 'none',
        },
        '&:last-of-type': {
            borderBottom: () => '1px solid hsl(0, 0%, 35%)',
        },
    },
    AccordionSummary: {
        backgroundColor: 'hsl(0, 0%, 15%)',
        '& .MuiAccordionSummary-content': {
            justifyContent: 'space-between',
            margin: '12px 0',
            '&.Mui-expanded': {
                margin: '12px 0',
            },
        },
    },

    AccordionDetails: {
        backgroundColor: 'hsl(0, 0%, 15%)',
        padding: '0 16px 16px',
    },
}
const DrawerItem = ({ children, drawerOpen, editButton, handleOpenDrawer, icon, title }) => {
    return (
        <Accordion
            elevation={0}
            sx={classes.AccordionRoot}
            disableGutters
            square
            onChange={handleOpenDrawer}
        >
            <AccordionSummary
                aria-controls={title + '-content'}
                expandIcon={drawerOpen ? <Minimize sx={{ color: 'action.active' }} /> : null}
                id={title + '-accordion'}
                sx={classes.AccordionSummary}
            >
                <Box
                    alignItems={'center'}
                    display={'flex'}
                    flexDirection={'row'}
                >
                    {icon}
                    <Box>
                        {drawerOpen ? (
                            <Box
                                alignItems={'center'}
                                display={'flex'}
                                flexDirection={'row'}
                                gap={1}
                                margin={1}
                            >
                                <Box>
                                    <Typography>{title}</Typography>
                                </Box>
                                <Box>{editButton}</Box>
                            </Box>
                        ) : null}
                    </Box>
                </Box>
            </AccordionSummary>
            {drawerOpen ? (
                <AccordionDetails sx={classes.AccordionDetails}>
                    <Box
                        display={'flex'}
                        flexDirection={'column'}
                    >
                        {children}
                    </Box>
                </AccordionDetails>
            ) : null}
        </Accordion>
    )
}

DrawerItem.propTypes = {
    children: PropTypes.element,
    drawerOpen: PropTypes.bool,
    editButton: PropTypes.any,
    handleOpenDrawer: PropTypes.func,
    icon: PropTypes.element,
    title: PropTypes.string,
}

export default DrawerItem
