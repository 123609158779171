import userflow from 'userflow.js'

export const initUserFlow = ({ language, localeToUse, organisation, planMetadata, reseller, user }) => {
    if (user) {
        userflow.init(import.meta.env.VITE_USERFLOW_TOKEN)
        userflow.identify(user.userId, {
            name: user.firstName + (user.lastName ? ' ' : '') + user.lastName,
            email: user.emailAddress,
            locale_code: language, // UserFlow docs state that locale_code must set to the user's preferred language
            locality: localeToUse, // Send locality, which has a special meaning in our app
            language: language,
            organisation_id: organisation ? organisation.organisationId : undefined,
            organisation_units: organisation ? organisation.defaultDrawingUnits : undefined,
            reseller,
            plan_metadata: planMetadata ? JSON.stringify(planMetadata) : undefined,
            is_legacy_materials: organisation ? organisation.isLegacyMaterials : undefined,
            promotional_codes: user.promotionalCodes ? JSON.stringify(user.promotionalCodes) : undefined,
            device_width: window.screen.width,
            device_height: window.screen.height,
            user_agent: window.navigator.userAgent,
            stripe_id: organisation ? organisation.paymentGatewayCustomerId : undefined,
            help_tips_toggle: user.showUserflowTooltips,
            laser_k_w: organisation ? organisation.cuttingTechnologyPower : undefined,
            quoting_experience: 2023,
            help_widget: user.showHelpWidget ? 'show' : 'hide'
        })
    }
}

export const showTooltipsDisplay = (userId, showUserflowTooltips) => {
    if (userId) {
        userflow.init(import.meta.env.VITE_USERFLOW_TOKEN)
        userflow.identify(userId,
            { help_tips_toggle: showUserflowTooltips }
        )
    }
}

export const showHelpWidget = (userId, showHelpWidget) => {
    if (userId) {
        userflow.init(import.meta.env.VITE_USERFLOW_TOKEN)
        userflow.identify(userId,
            { help_widget: showHelpWidget ? 'show' : 'hide' }
        )
    }
}

export const showResourceCentre = (showResourceCentre) => {
    userflow.setResourceCenterLauncherHidden(!showResourceCentre)
}
