import { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useDispatch, useSelector } from 'react-redux'
import { useParams } from 'react-router-dom'
import { Box } from '@mui/material'
import FormControl from '@mui/material/FormControl'
import InputLabel from '@mui/material/InputLabel'
import MenuItem from '@mui/material/MenuItem'
import Select from '@mui/material/Select'
import TextField from '@mui/material/TextField'
import { useSnackbar } from 'notistack'

import { useCalculateQuoteMutation } from '@/app/services/web-store/webStoreQuote'
import {
    useDeleteQuoteItemsMutation,
    useUpdateQuoteItemsAttributesMutation,
} from '@/app/services/web-store/webStoreQuoteItems'
import { clearSelectedItems, selectSelectedItems } from '@/app/slices/web-store/webStoreQuoteItemsSlice'

import TbxBulkEditModal from '../../components/shared/TbxBulkEditModal'
import TbxDialog from '../../components/shared/TbxDialog'
import { useQuoteItemPropertyOptions } from '../../hooks/useQuoteItemPropertyOptions'

const classes = {
    formContainer: {
        display: 'flex',
        gap: '24px',
    },
    formControl_size: {
        width: '100px',
    },
    formControl_material: {
        width: '224px',
    },
    formControl_thickness: {
        width: '140px',
    },
    formControl_quantity: {
        width: '120px',
    },
}

const QuoteItemsBulkEdit = () => {
    const { t } = useTranslation()
    const dispatch = useDispatch()
    const { enqueueSnackbar } = useSnackbar()

    const { organisationId, quoteId } = useParams()

    const [itemUnit, setItemUnit] = useState('')
    const [itemMaterial, setItemMaterial] = useState('')
    const [itemThickness, setItemThickness] = useState('')
    const [itemQuantity, setItemQuantity] = useState('')
    const [isDeleteDialogOpen, setIsDeleteDialogOpen] = useState(false)
    const [isSavingData, setIsSavingData] = useState(false)

    const { materialOptions, thicknessOptions } = useQuoteItemPropertyOptions({
        organisationId,
        materialId: itemMaterial,
        mode: 'flat',
    })

    const selectedItemsArray = useSelector(selectSelectedItems)

    const [updateQuoteItems, { isLoading: isUpdating }] = useUpdateQuoteItemsAttributesMutation()
    const [calculateQuote, { isLoading: isCalculating }] = useCalculateQuoteMutation({
        fixedCacheKey: 'shared-calculate-webstore-quote',
    })
    const [deleteQuoteItems, { isLoading: isDeleting }] = useDeleteQuoteItemsMutation()

    const resetForm = () => {
        setItemUnit('')
        setItemMaterial('')
        setItemThickness('')
        setItemQuantity('')
    }

    const handleUnitChange = (event) => {
        setItemUnit(event.target.value)
    }

    const handleMaterialChange = (event) => {
        setItemMaterial(event.target.value)
        setItemThickness('')
    }

    const handleThicknessChange = (event) => {
        setItemThickness(event.target.value)
    }

    const handleQuantityChange = (event) => {
        setItemQuantity(event.target.value)
    }

    const handleCloseClick = () => {
        resetForm()
        dispatch(clearSelectedItems())
    }

    const canSave =
        [itemUnit, itemMaterial, itemThickness, itemQuantity].some(Boolean) &&
        (!isUpdating || !isCalculating || !isDeleting)

    const handleUpdateQuoteItem = async () => {
        setIsSavingData(true)
        const quoteItems = {
            quoteItemIds: selectedItemsArray,
            attributes: {
                ...(Boolean(itemMaterial) && { materialId: itemMaterial }),
                ...(Boolean(itemThickness) && { thickness: itemThickness }),
                ...(Boolean(itemQuantity) && { quantity: itemQuantity }),
                ...(Boolean(itemUnit) && { isImperial: itemUnit === 'in' }),
            },
        }
        try {
            await updateQuoteItems({ organisationId, quoteId, quoteItems }).unwrap()
            enqueueSnackbar(t('{{length}} parts updated', { length: selectedItemsArray.length }), {
                variant: 'success',
            })
        } catch (error) {
            enqueueSnackbar(
                t('Failed to update {{length}} parts from $t(quote)', {
                    length: selectedItemsArray.length,
                }),
                { variant: 'error' }
            )
        } finally {
            setIsSavingData(false)
        }
    }

    const handleDeleteSelectedItems = async () => {
        try {
            await deleteQuoteItems({
                organisationId,
                quoteId,
                quoteItemsIds: selectedItemsArray,
            }).unwrap()
            enqueueSnackbar(
                t('{{length}} parts deleted from $t(quote)', {
                    length: selectedItemsArray.length,
                }),
                { variant: 'success' }
            )
            setIsDeleteDialogOpen(false)
            handleCloseClick()
            await calculateQuote({ organisationId, quoteId })
        } catch (error) {
            enqueueSnackbar(
                t('Failed to delete {{length}} parts from $t(quote)', {
                    length: selectedItemsArray.length,
                }),
                { variant: 'error' }
            )
            setIsDeleteDialogOpen(false)
            handleCloseClick()
        }
    }

    const handleSaveClick = async () => {
        await handleUpdateQuoteItem()
        handleCloseClick()
        try {
            await calculateQuote({ organisationId, quoteId }).unwrap()
            enqueueSnackbar(t('$t(Quote) calculated'), { variant: 'success' })
        } catch (error) {
            enqueueSnackbar(t('Failed to calculate $t(quote)'), { variant: 'error' })
        }
    }

    const handleDeleteDialogOpen = () => {
        setIsDeleteDialogOpen(true)
    }

    const handleDeleteDialogClose = () => {
        setIsDeleteDialogOpen(false)
    }

    useEffect(() => {
        if (!selectedItemsArray?.length) {
            resetForm()
        }
    }, [selectedItemsArray])

    return (
        <>
            <TbxBulkEditModal
                disableSave={!canSave || isSavingData}
                handleClose={handleCloseClick}
                handleDelete={handleDeleteDialogOpen}
                handleSave={handleSaveClick}
                items={selectedItemsArray}
                open={selectedItemsArray?.length > 1}
            >
                <Box sx={classes.formContainer}>
                    <FormControl
                        color="primary"
                        disabled={isUpdating}
                        margin="none"
                        size="small"
                        sx={classes.formControl_size}
                        variant="outlined"
                    >
                        <InputLabel
                            htmlFor="select-itemsUnit"
                            id="select-itemsUnit-label"
                        >
                            {t('Unit')}
                        </InputLabel>
                        <Select
                            id="select-itemsUnit"
                            label={t('Unit')}
                            labelId="select-itemsUnit-label"
                            value={itemUnit}
                            onBlur={handleUnitChange}
                            onChange={handleUnitChange}
                        >
                            <MenuItem value="mm">mm</MenuItem>
                            <MenuItem value="in">in</MenuItem>
                        </Select>
                    </FormControl>

                    <FormControl
                        color="primary"
                        disabled={isUpdating}
                        margin="none"
                        size="small"
                        sx={classes.formControl_material}
                        variant="outlined"
                    >
                        <InputLabel
                            htmlFor="select-itemsMaterial"
                            id="select-itemsMaterial-label"
                        >
                            {t('Material')}
                        </InputLabel>
                        <Select
                            id="select-itemsMaterial"
                            label={t('Material')}
                            labelId="select-itemsMaterial-label"
                            value={itemMaterial}
                            onBlur={handleMaterialChange}
                            onChange={handleMaterialChange}
                        >
                            {materialOptions.map((material) => (
                                <MenuItem
                                    key={material.materialId}
                                    value={material.materialId}
                                >
                                    {material.materialName}
                                </MenuItem>
                            ))}
                        </Select>
                    </FormControl>

                    <FormControl
                        color="primary"
                        disabled={!thicknessOptions.length || isUpdating}
                        margin="none"
                        size="small"
                        sx={classes.formControl_thickness}
                        variant="outlined"
                    >
                        <InputLabel
                            htmlFor="select-itemsThickness"
                            id="select-itemsThickness-label"
                        >
                            {t('Thickness')}
                        </InputLabel>
                        <Select
                            id="select-itemsThickness"
                            label={t('Thickness')}
                            labelId="select-itemsThickness-label"
                            value={itemThickness}
                            onBlur={handleThicknessChange}
                            onChange={handleThicknessChange}
                        >
                            {thicknessOptions.map((thickness) => (
                                <MenuItem
                                    key={thickness}
                                    value={thickness}
                                >
                                    {thickness}
                                </MenuItem>
                            ))}
                        </Select>
                    </FormControl>

                    <TextField
                        color="primary"
                        inputProps={{ style: { textAlign: 'right' } }}
                        label={t('Quantity')}
                        size="small"
                        sx={classes.formControl_quantity}
                        type="number"
                        value={itemQuantity}
                        variant="outlined"
                        fullWidth
                        onBlur={handleQuantityChange}
                        onChange={handleQuantityChange}
                    />
                </Box>
            </TbxBulkEditModal>

            <TbxDialog
                closeButtonText={t('cancel')}
                confirmButtonText={isDeleting ? t('Deleting...') : t('Yes, delete parts')}
                content={t('Are you sure you want to delete {{length}} parts from $t(quote)', {
                    length: selectedItemsArray.length,
                })}
                handleClose={handleDeleteDialogClose}
                handleConfirmClose={handleDeleteSelectedItems}
                isLoading={isDeleting}
                isOpen={isDeleteDialogOpen}
                key={quoteId}
                title={t('Delete {{length}} parts', {
                    length: selectedItemsArray.length,
                })}
            />
        </>
    )
}

export default QuoteItemsBulkEdit
