import { createSlice } from '@reduxjs/toolkit'

const initialState = {
    selectedItemsIds: [],
    expandedItemsIds: [],
}

export const webStoreQuoteItemsSlice = createSlice({
    name: 'webStoreQuoteItems',
    initialState,
    reducers: {
        setSelectedItemsIds: (state, action) => {
            state.selectedItemsIds = action.payload
        },
        addSelectedItem: (state, action) => {
            state.selectedItemsIds.push(action.payload)
        },
        removeSelectedItem: (state, action) => {
            state.selectedItemsIds = state.selectedItemsIds.filter((item) => item !== action.payload)
        },
        clearSelectedItems: (state) => {
            state.selectedItemsIds = []
        },
        setExpandedItemsIds: (state, action) => {
            state.expandedItemsIds = action.payload
        },
        addExpandedItem: (state, action) => {
            state.expandedItemsIds.push(action.payload)
        },
        removeExpandedItem: (state, action) => {
            state.expandedItemsIds = state.expandedItemsIds.filter((item) => item !== action.payload)
        },
        clearExpandedItems: (state) => {
            state.expandedItemsIds = []
        },
    },
})

export const selectSelectedItems = (state) => state.webStoreQuoteItems.selectedItemsIds
export const selectExpandedItems = (state) => state.webStoreQuoteItems.expandedItemsIds

export const {
    addExpandedItem,
    addSelectedItem,
    clearExpandedItems,
    clearSelectedItems,
    removeExpandedItem,
    removeSelectedItem,
    setExpandedItemsIds,
    setSelectedItemsIds,
} = webStoreQuoteItemsSlice.actions

export default webStoreQuoteItemsSlice.reducer
