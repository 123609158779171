import { useEffect, useMemo, useState } from 'react'
import { Controller, useForm, useWatch } from 'react-hook-form'
import { useTranslation } from 'react-i18next'
import { useDispatch, useSelector } from 'react-redux'
import { Box, InputAdornment, TextField } from '@mui/material'
import { DesktopDatePicker } from '@mui/x-date-pickers/DesktopDatePicker'
import PropTypes from 'prop-types'

import { useSheetsBulkUpdateMutation } from '@/app/services/sheets'
import {
    selectCurrencySymbol,
    selectOrganisationId,
    selectPaidFeatures,
    selectUseImperialUnits,
} from '@/app/slices/organisationSlice'
import { clearSelectedSheets, selectSelectedSheets } from '@/app/slices/sheetsSlice'
import { TbxBulkEditModal, TbxTooltip } from '@/common/components'
import TbxLocalizationProvider from '@/common/components/TbxLocalizationProvider/TbxLocalizationProvider'
import UpgradePlanLink from '@/common/components/UpgradePlanLink/UpgradePlanLink'
import { calculateCostPrice, percentageToFraction } from '@/common/utils'

import ArchiveSheetDialog from './ArchiveSheetDialog'

const classes = {
    formContainer: {
        display: 'flex',
        gap: '24px',
    },
    field: {
        width: '130px',
        margin: 0,
    },
    noWrap: {
        whiteSpace: 'nowrap',
    },
}

const SheetsBulkEdit = ({ selectedMaterial }) => {
    const { t } = useTranslation()
    const dispatch = useDispatch()

    const organisationId = useSelector(selectOrganisationId)
    const currencySymbol = useSelector(selectCurrencySymbol)
    const useImperialUnits = useSelector(selectUseImperialUnits)
    const paidFeatures = useSelector(selectPaidFeatures)
    const selectedSheets = useSelector(selectSelectedSheets)

    const [sheetsBulkUpdate] = useSheetsBulkUpdateMutation()

    const isRotary = useMemo(() => {
        return selectedMaterial?.type?.toLowerCase() === 'rotary'
    }, [selectedMaterial])

    const { control, formState, handleSubmit, reset } = useForm({
        mode: 'all',
        defaultValues: {
            expiryDate: '',
            sheetRatePrice: '',
            sheetCostChange: '',
        },
    })

    const [isSaving, setIsSaving] = useState(false)
    const [showDialog, setShowDialog] = useState(false)
    const [calendarIsOpen, setCalendarIsOpen] = useState(false)

    const saveSheets = async (data) => {
        setIsSaving(true)

        const { expiryDate, sheetCostChange, sheetRatePrice } = data

        const updatedSheets = selectedSheets.map((sheet) => {
            const changeFactor = percentageToFraction(parseFloat(sheetCostChange || 0, 10) + 100)
            const newSheetCost = sheetCostChange != '' ? changeFactor * sheet.sheetCost : null
            const newSheetRatePrice =
                sheetCostChange != ''
                    ? changeFactor * sheet.sheetRatePrice
                    : parseFloat(sheetRatePrice || sheet.sheetRatePrice)

            // Calculate cost from rate
            const newSheetCostPrice = calculateCostPrice(newSheetRatePrice, sheet.profile, {
                density: selectedMaterial.density,
                thickness: sheet.thickness,
                width: sheet.sheetWidth,
                height: sheet.sheetHeight,
                diameter: sheet.diameter,
                materialLength: sheet.materialLength,
                useImperialUnits,
            })

            const sheetCost = newSheetCost || parseFloat(newSheetCostPrice).toFixed(2)

            return {
                ...sheet,
                sheetCost: !isNaN(sheetCost) && sheetCost !== '' ? sheetCost : sheet.sheetCost,
                sheetRatePrice: newSheetRatePrice,
                expiryDate: expiryDate || sheet.expiryDate,
            }
        })

        await sheetsBulkUpdate({ organisationId, updatedSheets }).unwrap()
        setIsSaving(false)
    }

    useEffect(() => {
        if (!selectedSheets?.length) {
            resetForm()
        }
    }, [selectedSheets])

    const archiveSheets = async () => {
        setIsSaving(true)

        const updatedSheets = selectedSheets.map((r) => ({
            ...r,
            isDeleted: true,
        }))

        await sheetsBulkUpdate({ organisationId, updatedSheets }).unwrap()
        setIsSaving(false)
    }

    const handleCloseClick = () => {
        resetForm()
        dispatch(clearSelectedSheets())
    }

    const handleSaveClick = async (data, _) => {
        await saveSheets(data)
        resetForm()
        dispatch(clearSelectedSheets())
    }

    const confirmArchive = async () => {
        await archiveSheets()
        dispatch(clearSelectedSheets())
        setShowDialog(false)
    }

    const hideArchiveDialog = () => {
        setShowDialog(false)
    }

    const showArchiveDialog = () => {
        setShowDialog(true)
    }

    const resetForm = () => {
        reset({
            expiryDate: null,
            sheetRatePrice: '',
            sheetCostChange: '',
        })
    }

    const openCalendar = () => paidFeatures.hasAdvancedMaterials && setCalendarIsOpen(true)
    const closeCalendar = () => setCalendarIsOpen(false)

    const sheetRatePrice = useWatch({ control, name: 'sheetRatePrice' })
    const sheetCostChange = useWatch({ control, name: 'sheetCostChange' })
    const lockSheetRatePrice = useMemo(() => !!sheetCostChange, [sheetCostChange])
    const lockSheetCostChange = useMemo(() => !!sheetRatePrice, [sheetRatePrice])

    return (
        <TbxBulkEditModal
            disableSave={!formState.isValid || isSaving}
            handleArchive={showArchiveDialog}
            handleClose={handleCloseClick}
            handleSave={handleSubmit(handleSaveClick)}
            items={selectedSheets}
            open={selectedSheets?.length > 0}
        >
            <Box sx={classes.formContainer}>
                <TbxTooltip
                    leaveDelay={500}
                    title={paidFeatures.hasAdvancedMaterials ? '' : <UpgradePlanLink />}
                    arrow
                    disableFocusListener
                    disableTouchListener
                >
                    <Box>
                        <Controller
                            control={control}
                            name="expiryDate"
                            render={({ field: { name, onChange, ref, value }, fieldState }) => (
                                <TbxLocalizationProvider>
                                    <DesktopDatePicker
                                        disabled={!paidFeatures.hasAdvancedMaterials}
                                        format="DD-MMM-YYYY"
                                        inputRef={ref}
                                        label={t('Expiry')}
                                        open={calendarIsOpen}
                                        slotProps={{
                                            textField: {
                                                name,
                                                onClick: openCalendar,
                                                helperText: fieldState.error?.message || '',
                                                error: fieldState.invalid,
                                                sx: classes.field,
                                                variant: 'standard',
                                                InputLabelProps: { shrink: true },
                                                inputProps: {
                                                    id: 'sheet-date-picker',
                                                },
                                            },
                                        }}
                                        value={value}
                                        disablePast
                                        onChange={onChange}
                                        onClose={closeCalendar}
                                        onOpen={openCalendar}
                                    />
                                </TbxLocalizationProvider>
                            )}
                        />
                    </Box>
                </TbxTooltip>
                <Controller
                    control={control}
                    name="sheetRatePrice"
                    render={({ field: { onBlur, onChange, value }, fieldState }) => (
                        <TextField
                            disabled={lockSheetRatePrice}
                            error={fieldState.invalid}
                            helperText={fieldState.error?.message || ''}
                            InputLabelProps={{ shrink: true }}
                            InputProps={{
                                endAdornment: (
                                    <InputAdornment position="end">
                                        {`${currencySymbol}/${useImperialUnits ? 'lb' : 'kg'}`}
                                    </InputAdornment>
                                ),
                            }}
                            label={t(`${isRotary ? 'Unit' : 'Sheet'} rate`)}
                            sx={classes.field}
                            value={value}
                            autoFocus
                            onBlur={onBlur}
                            onChange={onChange}
                        />
                    )}
                />
                <Controller
                    control={control}
                    name="sheetCostChange"
                    render={({ field: { onBlur, onChange, value }, fieldState }) => (
                        <TextField
                            disabled={lockSheetCostChange}
                            error={fieldState.invalid}
                            helperText={fieldState.error?.message || ''}
                            InputLabelProps={{ shrink: true, sx: classes.noWrap }}
                            InputProps={{
                                endAdornment: <InputAdornment position="end">%</InputAdornment>,
                            }}
                            label={t(`${isRotary ? 'Unit' : 'Sheet'} cost change`)}
                            sx={classes.field}
                            type="number"
                            value={value}
                            onBlur={onBlur}
                            onChange={onChange}
                        />
                    )}
                />
            </Box>

            {showDialog ? <ArchiveSheetDialog
                    name=""
                    onCancelClose={hideArchiveDialog}
                    onConfirmClose={confirmArchive}
                          /> : null}
        </TbxBulkEditModal>
    )
}

SheetsBulkEdit.propTypes = {
    selectedMaterial: PropTypes.object,
}

export default SheetsBulkEdit
