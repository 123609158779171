import { Trans, useTranslation } from 'react-i18next'
import { useSelector } from 'react-redux'
import { Add, ArrowForward, FileCopy } from '@mui/icons-material'
import { Button, Link } from '@mui/material'
import PropTypes from 'prop-types'

import { useGetMaterialsQuery } from '@/app/services/materials'
import { selectSelectedMaterialId } from '@/app/slices/materialsSlice'
import { selectIsOnFreePlan, selectOrganisationId, selectReduceTrialAccess } from '@/app/slices/organisationSlice'
import { TbxTooltip } from '@/common/components'
import TbxToolbar from '@/common/components/TbxToolbar/TbxToolbar'
import TbxToolbarActions from '@/common/components/TbxToolbar/TbxToolbarActions'
import TbxToolbarDivider from '@/common/components/TbxToolbar/TbxToolbarDivider'
import TbxToolbarTitle from '@/common/components/TbxToolbar/TbxToolbarTitle'

import TbxToolbarMessages from '../../TbxToolbar/TbxToolbarMessages'

const MaterialsToolbar = ({ handleAddMaterialClick, handleDuplicateMaterialClick, handleRateTablesClick }) => {
    const { t } = useTranslation()

    const organisationId = useSelector(selectOrganisationId)

    const { data: materials } = useGetMaterialsQuery({ organisationId })

    const selectedMaterialId = useSelector(selectSelectedMaterialId)
    const selectedMaterial = materials[selectedMaterialId]

    const availableMaterialCount = Object.values(materials).filter((mat) => !mat.isDeleted).length

    const reduceTrialAccess = useSelector(selectReduceTrialAccess)
    const isOnFreePlan = useSelector(selectIsOnFreePlan)

    return (
        <TbxToolbar>
            <TbxToolbarTitle>{t('Materials')}</TbxToolbarTitle>

            <TbxToolbarMessages />

            <TbxToolbarActions>
                <TbxTooltip
                    title={
                        isOnFreePlan && availableMaterialCount > 2 ? (
                            <Trans t={t}>
                                Maximum of 3 materials available on your Free plan. You must first archive a material
                                before adding another active material.
                            </Trans>
                        ) : reduceTrialAccess ? (
                            <Trans t={t}>
                                Not available in trial mode. <Link href="/activate">Subscribe here.</Link>
                            </Trans>
                        ) : (
                            ''
                        )
                    }
                    arrow
                >
                    <span>
                        {isOnFreePlan ? (
                            <Button
                                color="secondary"
                                data-testid="duplicate-material-button"
                                disabled={availableMaterialCount > 2}
                                startIcon={<FileCopy />}
                                variant="outlined"
                                onClick={handleDuplicateMaterialClick}
                            >
                                {t('Duplicate')}
                            </Button>
                        ) : (
                            <Button
                                color="secondary"
                                data-testid="duplicate-material-button"
                                disabled={reduceTrialAccess || !selectedMaterial}
                                startIcon={<FileCopy />}
                                variant="outlined"
                                onClick={handleDuplicateMaterialClick}
                            >
                                {t('Duplicate')}
                            </Button>
                        )}
                    </span>
                </TbxTooltip>

                <Button
                    color="secondary"
                    data-testid="go-to-rate-tables-button"
                    endIcon={<ArrowForward />}
                    variant="outlined"
                    onClick={handleRateTablesClick}
                >
                    {t('Rate tables')}
                </Button>

                <TbxToolbarDivider />

                <TbxTooltip
                    title={
                        isOnFreePlan && availableMaterialCount > 2 ? (
                            <Trans t={t}>
                                Maximum of 3 materials available on your Free plan. You must first archive a material
                                before adding another active material.
                            </Trans>
                        ) : reduceTrialAccess && !isOnFreePlan ? (
                            <Trans t={t}>
                                Not available in trial mode. <Link href="/activate">Subscribe here.</Link>
                            </Trans>
                        ) : (
                            ''
                        )
                    }
                    arrow
                >
                    <span>
                        {isOnFreePlan ? (
                            <Button
                                color="primary"
                                data-testid="add-material"
                                disabled={availableMaterialCount > 2}
                                startIcon={<Add />}
                                variant="contained"
                                onClick={handleAddMaterialClick}
                            >
                                {t('Add material')}
                            </Button>
                        ) : (
                            <Button
                                color="primary"
                                data-testid="add-material"
                                disabled={reduceTrialAccess}
                                startIcon={<Add />}
                                variant="contained"
                                onClick={handleAddMaterialClick}
                            >
                                {t('Add material')}
                            </Button>
                        )}
                    </span>
                </TbxTooltip>
            </TbxToolbarActions>
        </TbxToolbar>
    )
}

MaterialsToolbar.propTypes = {
    handleAddMaterialClick: PropTypes.func,
    handleDuplicateMaterialClick: PropTypes.func,
    handleRateTablesClick: PropTypes.func,
}

export default MaterialsToolbar
