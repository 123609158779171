import { useTranslation } from 'react-i18next'
import { Typography } from '@mui/material'

import AppBarPortal from '../AppBarPortal'

const classes = {
    title: {
        fontWeight: 500,
        fontSize: '18px',
        lineHeight: '21px',
        textTransform: 'uppercase',
        color: 'text.primary',
    },
}

const SubscriptionToolbar = () => {
    const { t } = useTranslation()

    return (
        <AppBarPortal>
            <Typography sx={classes.title}>{t('Settings / Subscription')}</Typography>
        </AppBarPortal>
    )
}

export default SubscriptionToolbar
