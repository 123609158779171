import { useEffect, useMemo, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useDispatch, useSelector } from 'react-redux'
import { Add } from '@mui/icons-material'
import { Box, Button, Typography } from '@mui/material'
import PropTypes from 'prop-types'

import { useGetCustomersQuery } from '@/app/services/customers'
import { selectSelectedCustomerId, setSelectedCustomerId, setShowAddCustomerForm } from '@/app/slices/customersSlice'
import { selectOrganisationId } from '@/app/slices/organisationSlice'
import { TbxShowToggle } from '@/common/components'
import TbxToolbarSearch from '@/common/components/TbxToolbar/TbxToolbarSearch'

import CustomersListItem from './CustomersListItem'

const classes = {
    searchInput: {
        width: '100%',
        '& input::placeholder': {
            fontSize: '16px',
            lineHeight: '24px',
            color: 'black',
        },
        mt: '72px',
        mb: 2,
    },
    toggleContainer: {
        mb: 2,
    },
}

const CustomersList = ({ onClose }) => {
    const { t } = useTranslation()
    const dispatch = useDispatch()
    const organisationId = useSelector(selectOrganisationId)

    const { data: customers, isLoading } = useGetCustomersQuery({
        organisationId,
        params: {
            includeDeleted: true,
            getLastActivity: true,
            getQuoteCount: true,
            includeWebStore: true,
        },
    })

    const selectedCustomerId = useSelector(selectSelectedCustomerId)
    const selectedCustomer = customers?.find((customer) => customer.customerId === selectedCustomerId)

    const [showArchivedCustomers, setShowArchivedCustomers] = useState(false)
    const [customersSearchValue, setCustomersSearchValue] = useState('')

    const handleAddCustomerClick = () => {
        dispatch(setShowAddCustomerForm(true))
    }

    const filteredCustomers = useMemo(() => {
        if (!customers) return []

        const searchedCustomers = customersSearchValue
            ? customers.filter((customer) =>
                  customer.companyName.toLocaleLowerCase().includes(customersSearchValue.toLocaleLowerCase())
              )
            : customers

        //searchedCustomers = searchedCustomers?.slice()?.sort(compareMaterials)

        return showArchivedCustomers ? searchedCustomers : searchedCustomers.filter((customer) => !customer.isDeleted)
    }, [customersSearchValue, customers, showArchivedCustomers])

    useEffect(() => {
        if (selectedCustomer) {
            const selectedIsFiltered = filteredCustomers.find((c) => c.customerId === selectedCustomer.customerId)
            if (selectedIsFiltered === undefined) dispatch(setSelectedCustomerId(null))
        }
    }, [filteredCustomers, selectedCustomer, dispatch])

    const handleShowArchivedMaterialsChange = () => {
        setShowArchivedCustomers(!showArchivedCustomers)
    }

    const handleSearchValueChange = (value) => {
        setCustomersSearchValue(value)
    }

    return (
        <>
            <TbxToolbarSearch
                dataTestId="customers-toolbar-search"
                handleChange={handleSearchValueChange}
                placeholder="Search customers..."
                searchValue={customersSearchValue}
                sx={classes.searchInput}
            />

            <TbxShowToggle
                checked={showArchivedCustomers}
                label={t('Show archived customers')}
                sx={classes.toggleContainer}
                onChange={handleShowArchivedMaterialsChange}
            />

            <Button
                color="secondary"
                data-testid="add-customer-button"
                startIcon={<Add />}
                variant="contained"
                onClick={handleAddCustomerClick}
            >
                {t('Add new customer')}
            </Button>

            {isLoading ? (
                <Typography
                    my={3}
                    textAlign="center"
                    variant="body2"
                >
                    {t('Loading customers...')}
                </Typography>
            ) : filteredCustomers.length > 0 ? (
                <Box my={2}>
                    {filteredCustomers?.map((customer) => (
                        <CustomersListItem
                            customer={customer}
                            key={customer.customerId}
                            selectedCustomer={selectedCustomer}
                            onCustomerSelected={onClose}
                        />
                    ))}
                </Box>
            ) : (
                <Typography
                        my={3}
                        textAlign="center"
                >
                        {t('No customers found')}
                    </Typography>
            )}
        </>
    )
}

CustomersList.propTypes = {
    onClose: PropTypes.func,
}

export default CustomersList
