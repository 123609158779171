import PropTypes from 'prop-types'

import { getFormattedPrice } from '@/common/utils/CurrencyUtils/CurrencyUtils'

const Price = ({ amount, currencyCode, locale }) => {
    return <>{getFormattedPrice(amount, currencyCode, locale)}</>
}

Price.propTypes = {
    amount: PropTypes.number,
    currencyCode: PropTypes.string,
    locale: PropTypes.string,
}

export default Price
