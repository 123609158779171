import { useRef, useState } from 'react'
import { ArrowDropDown as ArrowDropDownIcon } from '@mui/icons-material'
import {
    Box,
    Button,
    ButtonGroup as MuiButtonGroup,
    ClickAwayListener,
    Grow,
    MenuItem,
    MenuList,
    Paper,
    Popper,
} from '@mui/material'
import PropTypes from 'prop-types'

const classes = {
    popper: {
        zIndex: 1001,
        position: 'absolute',
        top: 0,
        left: 0,
        transform: 'translate3d(140px, 50px, 0px)',
        willChange: 'transform',
    },
    dropdownArrow: {
        marginRight: 0.5,
    },
}
const ButtonGroupOLD = ({
    buttonLabels,
    buttonOnClickHandlers,
    className,
    color,
    defaultSelectedIndex,
    disabled,
    projectCustomer,
    splitButton,
    variant,
}) => {
    const quoteButtonGroupAnchorRef = useRef()

    const [selectedIndex, setSelectedIndex] = useState(defaultSelectedIndex ? defaultSelectedIndex : 0)

    const [menuOpen, setMenuOpen] = useState(false)

    const handleToggleMenu = () => {
        setMenuOpen((prevOpen) => !prevOpen)
    }

    const handleButtonEvent = async (index) => {
        if (!splitButton & (index === 0)) {
            setMenuOpen(true)
        }

        if (buttonOnClickHandlers[index]) {
            buttonOnClickHandlers[index]()
        }
    }

    const handleButtonClick = () => {
        handleButtonEvent(selectedIndex)
    }

    const handleMenuItemClick = (event, index) => {
        if (splitButton) {
            setSelectedIndex(index)
        }
        setMenuOpen(false)

        handleButtonEvent(index)
    }

    const handleCloseButton = (event) => {
        if (quoteButtonGroupAnchorRef.current && quoteButtonGroupAnchorRef.current.contains(event.target)) {
            return
        }
        setMenuOpen(false)
    }

    return buttonLabels.length > 1 ? (
        <div>
            <MuiButtonGroup
                aria-label="split primary button group"
                color={color}
                data-testid="sharedAddPartsButton"
                disabled={disabled}
                ref={quoteButtonGroupAnchorRef}
                sx={className}
                variant={variant}
            >
                <Button
                    disabled={disabled}
                    onClick={handleButtonClick}
                >
                    <Box sx={!splitButton ? classes.dropdownArrow : undefined}>{buttonLabels[selectedIndex]}</Box>
                    {!splitButton ? <ArrowDropDownIcon /> : null}
                </Button>
                {splitButton ? (
                    <Button
                        aria-controls={menuOpen ? 'split-button-menu' : undefined}
                        aria-expanded={menuOpen ? 'true' : undefined}
                        aria-haspopup="menu"
                        aria-label="select merge strategy"
                        disabled={disabled}
                        size="small"
                        onClick={handleToggleMenu}
                    >
                        <ArrowDropDownIcon />
                    </Button>
                ) : null}
            </MuiButtonGroup>
            <Popper
                anchorEl={quoteButtonGroupAnchorRef.current}
                data-testid="sharedQuotePopper"
                open={menuOpen}
                role={undefined}
                sx={classes.popper}
                disablePortal
                transition
            >
                {({ TransitionProps, placement }) => (
                    <Grow
                        {...TransitionProps}
                        style={{
                            transformOrigin: placement === 'bottom' ? 'center top' : 'center bottom',
                        }}
                    >
                        <Paper>
                            <ClickAwayListener onClickAway={handleCloseButton}>
                                <MenuList id="split-button-menu">
                                    {
                                        splitButton
                                            ? buttonLabels.map((option, index) => (
                                                  <MenuItem
                                                      key={option}
                                                      selected={index === selectedIndex}
                                                      onClick={(event) => handleMenuItemClick(event, index)}
                                                  >
                                                      {option}
                                                  </MenuItem>
                                              ))
                                            : //                   if (
                                              //                       option === t('Add from Part Library') &&
                                              // !projectCustomer
                                              //                   ) {
                                              //                       return (
                                              //                           <Tooltip
                                              //                               title={t(
                                              //                                   'Select a customer for this quote to send these parts to their part library',
                                              //                               )}
                                              //                           >
                                              //                               <div style={{ display: 'inline-block' }}>
                                              //                                   <MenuItem
                                              //                                       key={option}
                                              //                                       disabled={true}
                                              //                                       component="div"
                                              //                                   >
                                              //                                       {option}
                                              //                               </div>
                                              //                           </Tooltip>
                                              //                       )
                                              //                   } else {
                                              //                       return (
                                              //                           <MenuItem
                                              //                               key={option}
                                              //                               selected={index === selectedIndex}
                                              //                               onClick={(event) =>
                                              //                                   handleMenuItemClick(event, index)
                                              //                               }
                                              //                           >
                                              //                               {option}
                                              //                       )
                                              //                   }
                                              buttonLabels.slice(1).map((option, index) => (
                                                  <MenuItem
                                                      key={option}
                                                      selected={index === selectedIndex}
                                                      onClick={(event) => handleMenuItemClick(event, index + 1)}
                                                  >
                                                      {option}
                                                  </MenuItem>
                                              ))
                                        //                   if (
                                        //                       option === t('Add from Part Library') &&
                                        // !projectCustomer
                                        //                   ) {
                                        //                       return (
                                        //                           <Tooltip
                                        //                               title={t(
                                        //                                   'Select a customer for this quote to send these parts to their part library',
                                        //                               )}
                                        //                           >
                                        //                               <div style={{ display: 'inline-block' }}>
                                        //                                   <MenuItem
                                        //                                       key={option}
                                        //                                       disabled={true}
                                        //                                       component="div"
                                        //                                   >
                                        //                                       {option}
                                        //                               </div>
                                        //                           </Tooltip>
                                        //                       )
                                        //                   } else {
                                        //                       return (
                                        //                           <MenuItem
                                        //                               key={option}
                                        //                               selected={index === selectedIndex}
                                        //                               onClick={(event) =>
                                        //                                   handleMenuItemClick(event, index + 1)
                                        //                               }
                                        //                           >
                                        //                               {option}
                                        //                       )
                                        //                   }
                                    }
                                </MenuList>
                            </ClickAwayListener>
                        </Paper>
                    </Grow>
                )}
            </Popper>
        </div>
    ) : (
        <Button onClick={handleButtonClick}>{buttonLabels[0]}</Button>
    )
}

ButtonGroupOLD.propTypes = {
    buttonLabels: PropTypes.array,
    buttonOnClickHandlers: PropTypes.array,
    className: PropTypes.string,
    color: PropTypes.string,
    defaultSelectedIndex: PropTypes.number,
    disabled: PropTypes.bool,
    projectCustomer: PropTypes.object,
    splitButton: PropTypes.bool,
    variant: PropTypes.string,
}

export default ButtonGroupOLD
