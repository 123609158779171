// check if any quote.quoteitems has issues warning or is-error
export const quoteHasIssues = (quoteItems) => {
    if (!quoteItems?.length) return false
    return quoteItems.some((item) =>
        Object.keys(item?.issues).some((key) => {
            if (key !== '$type') {
                return item?.issues[key]?.length
            }
        })
    )
}

// count how many quote.quoteitems has warnings or error
export const quoteIssuesCount = (quoteItems) => {
    if (!quoteItems?.length) return false
    return quoteItems.reduce(
        (acc, item) => {
            if (item?.issues?.warnings?.length) acc.warnings += 1
            if (item?.issues?.errors?.length) acc.errors += 1
            if (!item?.issues?.warnings?.length && !item?.issues?.errors?.length) acc.success += 1
            return acc
        },
        { warnings: 0, errors: 0, success: 0 }
    )
}

// select quoteitem by id and check if has warnings or error
export const quoteItemHasIssues = (quoteItem) => {
    if (!quoteItem?.issues) return false
    return Object.keys(quoteItem?.issues).some((key) => {
        if (key !== '$type') {
            return quoteItem?.issues[key]?.length
        }
    })
}

// select quoteitem by id and return warnings
export const quoteItemWarnings = (quoteItem) => {
    return quoteItem?.issues?.warnings?.length ? quoteItem.issues.warnings : false
}

// select quoteitem by id and return errors
export const quoteItemErrors = (quoteItem) => {
    return quoteItem?.issues?.errors?.length ? quoteItem.issues.errors : false
}
