import MuiMenuItem from '@mui/material/MenuItem'
import PropTypes from 'prop-types'

const MenuItem = ({ children, disabled = false, onClick }) => {
    return (
        <MuiMenuItem
            disabled={disabled}
            onClick={onClick}
        >
            {children}
        </MuiMenuItem>
    )
}

MenuItem.propTypes = {
    children: PropTypes.node,
    disabled: PropTypes.bool,
    onClick: PropTypes.func,
}

export default MenuItem
