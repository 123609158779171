import { api } from './api'

export const deliveryContainersApi = api
    .enhanceEndpoints({
        addTagTypes: ['Containers'],
    })
    .injectEndpoints({
        endpoints: (builder) => ({
            getDeliveryContainers: builder.query({
                query: ({ organisationId, params }) => ({
                    url: `/shipping/${organisationId}/containers`,
                    params,
                }),
                providesTags: (result, _error, _arg) =>
                    result
                        ? [
                              { type: 'Containers', id: 'LIST' },
                              ...result.map(({ containerId }) => ({
                                  type: 'Containers',
                                  id: containerId,
                              })),
                          ]
                        : [{ type: 'Containers', id: 'LIST' }],
                transformResponse: (response) => {
                    return response?.sort((a, b) => {
                        if (!a?.maximumWeight) return 1
                        if (!b?.maximumWeight) return -1
                        return a.maximumWeight - b.maximumWeight
                    })
                },
            }),

            getDeliveryContainer: builder.query({
                query: ({ containerId, organisationId }) => ({
                    url: `/shipping/${organisationId}/container/${containerId}`,
                }),
                providesTags: (result, _error, arg) => [{ type: 'Containers', id: arg.containerId }],
            }),

            createDeliveryContainer: builder.mutation({
                query: ({ container, organisationId }) => ({
                    url: `/shipping/${organisationId}/container`,
                    method: 'POST',
                    body: { ...container, organisationId },
                }),
                invalidatesTags: [{ type: 'Containers', id: 'LIST' }],
            }),

            updateDeliveryContainer: builder.mutation({
                query: ({ container, organisationId }) => ({
                    url: `/shipping/${organisationId}/container/update`,
                    method: 'PUT',
                    body: { ...container, organisationId },
                }),
                invalidatesTags: (_result, _error, arg) => [{ type: 'Containers', id: arg.container.containerId }],
            }),

            archiveDeliveryContainer: builder.mutation({
                query: ({ containerId, organisationId }) => ({
                    url: `/shipping/${organisationId}/container/${containerId}`,
                    method: 'DELETE',
                }),
                invalidatesTags: (_result, _error, _arg) => [{ type: 'Containers', id: 'LIST' }],
            }),

            unarchiveDeliveryContainer: builder.mutation({
                query: ({ containerId, organisationId }) => ({
                    url: `/shipping/${organisationId}/container/${containerId}/unarchive`,
                    method: 'PUT',
                }),
                invalidatesTags: (_result, _error, _arg) => [{ type: 'Containers', id: 'LIST' }],
            }),
        }),
    })

export const {
    useArchiveDeliveryContainerMutation,
    useCreateDeliveryContainerMutation,
    useGetDeliveryContainerQuery,
    useGetDeliveryContainersQuery,
    useUnarchiveDeliveryContainerMutation,
    useUpdateDeliveryContainerMutation,
} = deliveryContainersApi
