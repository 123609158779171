import { Controller } from 'react-hook-form'
import { useTranslation } from 'react-i18next'
import { Checkbox, FormControlLabel } from '@mui/material'
import PropTypes from 'prop-types'

const FormInputCheckbox = ({ control, disabled = false, label, name, rules }) => {
    const { t } = useTranslation()
    return (
        <FormControlLabel
            control={
                <Controller
                    control={control}
                    name={name}
                    render={({ field: { onChange, value } }) => (
                        <Checkbox
                            checked={value || false}
                            color="primary"
                            disabled={disabled}
                            onChange={onChange}
                        />
                    )}
                    rules={rules}
                />
            }
            label={t(label)}
        />
    )
}

FormInputCheckbox.propTypes = {
    control: PropTypes.object.isRequired,
    label: PropTypes.string.isRequired,
    name: PropTypes.string.isRequired,
    disabled: PropTypes.bool,
    rules: PropTypes.object,
}

export default FormInputCheckbox
