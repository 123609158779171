import { useEffect } from 'react'
import { Controller, useForm } from 'react-hook-form'
import { Edit, Save } from '@mui/icons-material'
import { Card, CardContent, CardHeader, TextField, Typography } from '@mui/material'
import PropTypes from 'prop-types'

const classes = {
    card: {
        border: '1px solid #E0E0E0',
        boxSizing: 'border-box',
        borderRadius: '8px',
        marginRight: '24px',
        width: '400px',
        flexGrow: 1,
    },
    cardAction: {
        marginTop: 0,
    },
    cardContent: {
        padding: '0 24px',
    },
    cardHeader: {
        display: 'flex',
        flexDirection: 'row',
        justifyContent: 'space-between',
        alignItems: 'center',
        padding: '12px 24px 0 24px',
    },
    cardTitle: {
        fontWeight: 700,
        fontSize: '16px',
        lineHeight: '150%',
        letterSpacing: '0.15px',
        color: 'text.primary',
    },
    editableTitle: {
        fontWeight: 500,
        fontSize: '20px',
        lineHeight: '32px',
    },
    textInput: {
        fontSize: '20px',
        lineHeight: '32px',
        color: 'text.primary',
    },
    editIcon: {
        cursor: 'pointer',
    },
}

const TbxCardWithAction = ({
    canEditTitle = false,
    children,
    disabled = false,
    isEditing = false,
    onActionButtonClick,
    onTitleValidation,
    title = '',
}) => {
    const {
        control,
        formState: { isValid },
        getValues,
        reset,
    } = useForm({
        mode: 'all',
        defaultValues: {
            titleValue: title,
        },
    })

    const handleSaveEditClick = () => {
        isValid && onActionButtonClick(getValues('titleValue'))
    }

    useEffect(() => {
        reset({
            titleValue: title,
        })
    }, [title, reset])

    const cardTitleStyle = Object.assign({}, classes.cardTitle, canEditTitle ? classes.editableTitle : {})

    return (
        <Card
            sx={classes.card}
            variant="outlined"
        >
            <CardHeader
                action={
                    typeof onActionButtonClick === 'function' && !disabled ? (
                        !isEditing ? (
                            <Edit
                                fontSize="small"
                                sx={classes.editIcon}
                                onClick={handleSaveEditClick}
                            />
                        ) : (
                            <Save
                                fontSize="small"
                                sx={classes.editIcon}
                                onClick={handleSaveEditClick}
                            />
                        )
                    ) : null
                }
                classes={{ action: classes.cardAction }}
                sx={classes.cardHeader}
                title={
                    isEditing && canEditTitle ? (
                        <Controller
                            control={control}
                            name="titleValue"
                            render={({ field: { onBlur, onChange, value }, fieldState }) => (
                                <TextField
                                    disabled={disabled}
                                    error={fieldState.invalid}
                                    helperText={fieldState.error?.message || ''}
                                    InputProps={{
                                        sx: classes.textInput,
                                    }}
                                    value={value}
                                    onBlur={onBlur}
                                    onChange={onChange}
                                />
                            )}
                            rules={{
                                required: 'Required',
                                validate: onTitleValidation,
                            }}
                        />
                    ) : (
                        <Typography sx={cardTitleStyle}>{title}</Typography>
                    )
                }
            />
            <CardContent sx={classes.cardContent}>{children}</CardContent>
        </Card>
    )
}

TbxCardWithAction.propTypes = {
    canEditTitle: PropTypes.bool,
    children: PropTypes.node,
    disabled: PropTypes.bool,
    isEditing: PropTypes.bool,
    title: PropTypes.string,
    onActionButtonClick: PropTypes.func,
    onTitleValidation: PropTypes.func,
}

export default TbxCardWithAction
