import { api } from './api'

const apiVersion = 'v2'

export const integrationsApi = api.injectEndpoints({
    endpoints: (builder) => ({
        startXeroAuthorization: builder.mutation({
            query: ({ redirectUri }) => ({
                url: `/${apiVersion}/integrations/xero`,
                method: 'POST',
                body: { redirectUri },
                responseHandler: (response) => response.text(),
            }),
        }),

        exchangeXeroCode: builder.mutation({
            query: ({ code, redirectUri }) => ({
                url: `/${apiVersion}/integrations/xero/exchange-code`,
                method: 'POST',
                body: { code, redirectUri },
            }),
        }),

        getXeroProfile: builder.mutation({
            query: ({ accessToken }) => ({
                url: `/${apiVersion}/integrations/xero/profile`,
                method: 'POST',
                body: { accessToken },
            }),
        }),

        createStripeAccount: builder.mutation({
            query: ({ organisationId }) => ({
                url: `/organisations/${organisationId}/create-stripe-account`,
                method: 'POST',
                responseHandler: (response) => response.text(),
            }),
            invalidatesTags: ['Organisation'],
        }),

        createCustomerPortalSession: builder.mutation({
            query: ({ organisationId, requestModel }) => ({
                url: `/organisations/${organisationId}/create-customer-portal-session`,
                method: 'POST',
                body: requestModel,
            }),
        }),

        getStripeOnboardingLink: builder.query({
            query: ({ organisationId, params }) => ({
                url: `/organisations/${organisationId}/get-stripe-onboarding-link`,
                params,
                responseHandler: (response) => response.text(),
            }),
        }),

        createStripeCheckoutSession: builder.mutation({
            query: ({ cancelUrl, organisationId, quoteId, successUrl }) => ({
                url: `/${apiVersion}/integrations/stripe/${organisationId}/checkout/${quoteId}`,
                method: 'POST',
                body: { successUrl, cancelUrl },
                responseHandler: (response) => response.text(),
            }),
        }),

        setIntegrationFlags: builder.mutation({
            query: ({ organisationDto }) => {
                const { $type, ...requestModel } = organisationDto
                return {
                    url: `/organisations/${organisationDto.organisationId}/integration-flags`,
                    method: 'PUT',
                    body: requestModel,
                }
            },
            invalidatesTags: ['Organisation'],
        }),
    }),
})

export const {
    useCreateCustomerPortalSessionMutation,
    useCreateStripeAccountMutation,
    useCreateStripeCheckoutSessionMutation,
    useExchangeXeroCodeMutation,
    useGetStripeOnboardingLinkQuery,
    useGetXeroProfileMutation,
    useLazyGetStripeOnboardingLinkQuery,
    useSetIntegrationFlagsMutation,
    useStartXeroAuthorizationMutation,
} = integrationsApi
