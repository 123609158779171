import { useCallback, useEffect, useState } from 'react'
import { Search } from '@mui/icons-material'
import { Box, Input } from '@mui/material'
import { debounce } from 'lodash'
import PropTypes from 'prop-types'

const classes = (theme) => ({
    search: {
        position: 'relative',
        marginLeft: 0,
        width: '100%',
        [theme.breakpoints.up('sm')]: {
            marginLeft: 0,
            width: 'auto',
        },
    },
    searchIcon: {
        width: 30,
        height: '100%',
        position: 'absolute',
        pointerEvents: 'none',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        '& svg': {
            color: 'text.primary',
        },
    },
    underline: {
        '&:before': {
            borderBottom: `1px solid ${theme.palette.text.primary}`,
        },
        '&:hover:not(.Mui-disabled):before': {
            borderBottom: `2px solid ${theme.palette.text.primary}`,
        },
        '&:after': {
            borderBottom: `2px solid ${theme.palette.text.primary}`,
        },
    },
    inputRoot: {
        height: '30px',
    },
    inputInput: {
        paddingTop: 1,
        paddingRight: 1,
        paddingBottom: 1,
        paddingLeft: 4,
        transition: theme.transitions.create('width'),
        color: 'text.primary',
        borderBottomColor: 'text.primary',
    },
    searchInput: {
        display: 'block',
        width: '100%',
    },
    label: {
        marginBottom: '4px',
    },
})
const SearchInput = ({ debounceDelay = 800, debounceInput = true, disabled = false, onChange, placeholder, value }) => {
    const [searchTerm, setSearchTerm] = useState(value)

    useEffect(() => {
        setSearchTerm(value)
    }, [value])

    const searchTermChangedHandler = useCallback(
        debounce(
            (newValue) => {
                if (typeof onChange === 'function') {
                    onChange(newValue)
                }
            },
            !debounceInput ? 0 : debounceDelay
        ),
        []
    )

    const handleSearchTermChange = (event) => {
        setSearchTerm(event.target.value)
        searchTermChangedHandler(event.target.value)
    }

    return (
        <Box sx={classes.searchInput}>
            <Box sx={classes.search}>
                <Box sx={classes.searchIcon}>
                    <Search />
                </Box>
                <Input
                    classes={{
                        root: classes.inputRoot,
                        input: classes.inputInput,
                        underline: classes.underline,
                    }}
                    disabled={disabled}
                    placeholder={placeholder}
                    value={searchTerm}
                    onChange={handleSearchTermChange}
                />
            </Box>
        </Box>
    )
}

SearchInput.propTypes = {
    debounceDelay: PropTypes.number,
    debounceInput: PropTypes.bool,
    disabled: PropTypes.bool,
    placeholder: PropTypes.string,
    value: PropTypes.string,
    onChange: PropTypes.func,
}

export default SearchInput
