import { useEffect, useState } from 'react'
import { useSelector } from 'react-redux'
import { Box } from '@mui/material'
import Drawer from '@mui/material/Drawer'
import { alpha } from '@mui/material/styles'
import PropTypes from 'prop-types'

import { selectIsOnTrial, selectOrganisation } from '@/app/slices/organisationSlice'

const classes = {
    drawerBackdrop: {
        backgroundColor: (theme) => alpha(theme.palette.text.primary, 0.5),
    },
}

const TbxDrawer = ({
    anchor = 'right',
    children,
    disableAutoFocus = false,
    disableEnforceFocus = false,
    hideBackdrop = false,
    onClose,
    padding = 48,
    showDrawer = false,
    showToolbar = false,
    variant = 'temporary',
    width = 470,
}) => {
    const organisation = useSelector(selectOrganisation)
    const isOnTrial = useSelector(selectIsOnTrial)
    const [hasActiveTrial, setHasActiveTrial] = useState(false)
    const [toolbarHeight, setToolbarHeight] = useState(0)

    useEffect(() => {
        setHasActiveTrial(isOnTrial && !organisation.hasActiveSubscription)
    }, [organisation, isOnTrial])

    useEffect(() => {
        let toolbarHeight = 0
        if (showToolbar) toolbarHeight += 65
        if (hasActiveTrial) toolbarHeight += 65
        setToolbarHeight(toolbarHeight)
    }, [showToolbar, hasActiveTrial])

    return (
        <Drawer
            anchor={anchor}
            disableAutoFocus={disableAutoFocus}
            disableEnforceFocus={disableEnforceFocus}
            ModalProps={{
                hideBackdrop: hideBackdrop,
            }}
            open={showDrawer}
            slotProps={{
                backdrop: {
                    sx: classes.drawerBackdrop,
                },
            }}
            sx={{
                width: width,
                flexShrink: 0,
                top: toolbarHeight ? `${toolbarHeight}px !important` : 0,
                zIndex: showToolbar ? '1000 !important' : 1300,
                '& .MuiDrawer-paper': {
                    width: width,
                    marginTop: toolbarHeight ? `${toolbarHeight}px` : 0,
                    height: toolbarHeight ? `calc(100% - ${toolbarHeight}px)` : '100%',
                },
            }}
            variant={variant}
            onClose={onClose}
        >
            <Box
                sx={{
                    overflow: 'auto',
                    flex: '1 1 auto',
                    padding: `${padding}px`,
                }}
            >
                {children}
            </Box>
        </Drawer>
    )
}

TbxDrawer.propTypes = {
    anchor: PropTypes.oneOf(['left', 'right', 'top', 'bottom']),
    children: PropTypes.node,
    disableAutoFocus: PropTypes.bool,
    disableEnforceFocus: PropTypes.bool,
    hideBackdrop: PropTypes.bool,
    padding: PropTypes.number,
    showDrawer: PropTypes.bool,
    showToolbar: PropTypes.bool,
    variant: PropTypes.oneOf(['permanent', 'persistent', 'temporary']),
    width: PropTypes.number,
    onClose: PropTypes.func,
}

export default TbxDrawer
