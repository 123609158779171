import { useTranslation } from 'react-i18next'
import { Edit } from '@mui/icons-material'
import { Box, Card, CardContent, IconButton, Typography } from '@mui/material'
import PropTypes from 'prop-types'

const classes = {
    root: {
        marginRight: 3,
    },
    cardContent: {
        padding: 3,
    },
    disabledCard: {
        opacity: 0.5,
        PointerEvent: 'none',
    },
    cardHeader: {
        display: 'flex',
        flexDirection: 'row',
        marginBottom: 2,
        justifyContent: 'space-between',
        alignItems: 'center',
    },
}

const AddressCard = ({ address, customer, isEditable = true, onEditButtonClick, title }) => {
    const { t } = useTranslation()

    return (
        <Card
            sx={[classes.root, !isEditable && classes.disabledCard]}
            variant="outlined"
        >
            <CardContent sx={classes.cardContent}>
                <Box sx={classes.cardHeader}>
                    <Typography variant="strong1">{t(title)}</Typography>
                    {!customer?.isDeleted ? (
                        <IconButton
                            color="secondary"
                            disabled={!isEditable}
                            size="small"
                            onClick={onEditButtonClick}
                        >
                            <Edit fontSize="small" />
                        </IconButton>
                    ) : null}
                </Box>

                <Box
                    display="flex"
                    flexDirection="row"
                    gap={4}
                >
                    <Box width={200}>
                        <Typography variant="small">{t('Address Line 1')}</Typography>
                        <Typography
                            mb={2}
                            variant="strong1"
                        >
                            {address?.line1}
                        </Typography>
                        <Typography variant="small">{t('Address Line 2')}</Typography>
                        <Typography
                            mb={2}
                            variant="strong1"
                        >
                            {address?.line2}
                        </Typography>
                        <Typography variant="small">{t('City')}</Typography>
                        <Typography
                            mb={2}
                            variant="strong1"
                        >
                            {address?.city}
                        </Typography>
                    </Box>

                    <Box width={200}>
                        <Typography variant="small">{t('State')}</Typography>
                        <Typography
                            mb={2}
                            variant="strong1"
                        >
                            {address?.state}
                        </Typography>
                        <Typography variant="small">{t('Post Code')}</Typography>
                        <Typography
                            mb={2}
                            variant="strong1"
                        >
                            {address?.postcode}
                        </Typography>
                        <Typography variant="small">{t('Country')}</Typography>
                        <Typography
                            mb={2}
                            variant="strong1"
                        >
                            {address?.country}
                        </Typography>
                    </Box>
                </Box>
            </CardContent>
        </Card>
    )
}

AddressCard.propTypes = {
    address: PropTypes.object,
    customer: PropTypes.object,
    isEditable: PropTypes.bool,
    title: PropTypes.string,
    onEditButtonClick: PropTypes.func,
}

export default AddressCard
