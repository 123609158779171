import SvgIcon from '@mui/material/SvgIcon'

const classes = {
    colour: {
        color: '#5E7387',
    },
}

const CustomerSuppliedMaterialIcon = (props) => {
    return (
        <SvgIcon
            {...props}
            viewBox="-3.5 -3.5 24 24"
        >
            <path
                d="M9 10.06L6 13L9 16H0V14C0 11.34 5.33 10 8 10C8.32 10 8.61 10.02 9 10.06ZM12 4C12 6.21 10.21 8 8 8C5.79 8 4 6.21 4 4C4 1.79 5.79 0 8 0C10.21 0 12 1.79 12 4ZM9 13L12.47 16.5L19 9.91L17.6 8.5L12.47 13.67L10.4 11.59L9 13Z"
                fill={classes.colour.color}
            />
        </SvgIcon>
    )
}

export default CustomerSuppliedMaterialIcon
