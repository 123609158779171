import { useLayoutEffect, useRef, useState } from 'react'
import { Motion, spring } from 'react-motion'
import { SvgProxy } from 'react-svgmt'
import PropTypes from 'prop-types'

const AnimatedDimension = ({ maxStrokeWidth, minStrokeWidth, selectedDimensionId }) => {
    const [reverse, setReverse] = useState(false)
    const animateRef = useRef()

    const minStroke = minStrokeWidth ? minStrokeWidth : 0.25
    const maxStroke = maxStrokeWidth ? maxStrokeWidth : 0.5

    useLayoutEffect(() => {
        return () => {
            cancelAnimationFrame(animateRef.current)
        }
    }, [])

    const handleRest = () => {
        animateRef.current = requestAnimationFrame(() => setReverse(!reverse))
    }

    return (
        selectedDimensionId && (
            <Motion
                defaultStyle={{ 'stroke-width': reverse ? maxStroke : minStroke }}
                style={{
                    'stroke-width': reverse ? spring(minStroke) : spring(maxStroke),
                }}
                onRest={handleRest}
            >
                {(valObj) => {
                    const attributes = { ...valObj }
                    Object.keys(attributes).forEach((key) => {
                        attributes[key] = attributes[key].toString() + '%'
                    })
                    return (
                        <SvgProxy
                            {...attributes}
                            class="Dim selectedDim"
                            selector={'g#' + selectedDimensionId}
                        />
                    )
                }}
            </Motion>
        )
    )
}

AnimatedDimension.propTypes = {
    maxStrokeWidth: PropTypes.number,
    minStrokeWidth: PropTypes.number,
    selectedDimensionId: PropTypes.string,
}

export default AnimatedDimension
