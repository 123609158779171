import { useCallback, useEffect, useMemo, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useSelector } from 'react-redux'
import { Link } from 'react-router-dom'
import { useMsal } from '@azure/msal-react'
import { DeleteRounded, EditRounded, PersonAddRounded, SwapHorizRounded } from '@mui/icons-material'
import {
    Box,
    Button,
    CircularProgress,
    IconButton,
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableHead,
    TablePagination,
    TableRow,
    Typography,
} from '@mui/material'
import Grid from '@mui/material/Unstable_Grid2'
import useLocalStorage from 'use-local-storage'

import {
    useLazyGetOrganisationUserQuery,
    useLazyGetOrganisationUsersQuery,
    useRemoveOrganisationUserMutation,
} from '@/app/services/organisationUsers'
import { selectCurrentUserId, selectIsCurrentUserAdmin, selectIsCurrentUserOwner } from '@/app/slices/appSlice'
import { selectMaxUsers, selectOrganisation, selectOrganisationId } from '@/app/slices/organisationSlice'
import { AlertDialog } from '@/common/components'
import { useToolBoxTreatments } from '@/common/hooks'
import {
    AuthorisationUtils,
    LIBELLULA_PRICING_REDIRECT_URL,
    TOOLBOX_PRICING_REDIRECT_URL,
    USERS_PER_PAGE_DEFAULT_VALUE,
    USERS_PER_PAGE_OPTIONS,
    USERS_PER_PAGE_VARIABLE,
} from '@/common/utils'

import AddUserDialog from './AddUserDialog'
import ChangeUserRole from './ChangeUserRole'
import TransferOwnershipDialog from './TransferOwnershipDialog'

const classes = {
    actionPanel: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'space-between',
        zIndex: 500,
        width: '100%',
    },
    addButton: {
        width: 40,
        height: 40,
    },

    remainingUsersLabel: {
        padding: '1.5rem',
        fontWeight: 500,
        flex: 1,
    },

    tableContainer: {
        background: (theme) => theme.palette.background.paper,
        border: (theme) => `1px solid ${theme.palette.grey[400]}`,
        boxSizing: 'border-box',
        borderRadius: 2,
    },
    table: {
        tableLayout: 'fixed',
        boxSizing: 'border-box',
        borderCollapse: 'separate',
    },
    headerTableCell: {
        paddingBlock: 2,
        paddingInline: 2,
        verticalAlign: 'bottom',
    },
    tableRow: {
        '&:hover': {
            background: (theme) => theme.palette.action.hover,
        },
    },
    tableCell: {
        paddingBlock: 2,
        paddingInline: 2,
    },
    tableCellWithEllipsis: {
        overflow: 'hidden',
        whiteSpace: 'nowrap',
        textOverflow: 'ellipsis',
    },
    tableFooter: {
        display: 'flex',
        alignItems: 'center',
        flexDirection: 'row-reverse',
    },
    iconColor: {
        color: 'secondary.main',
    },
    disabledIcon: (isDeleted) => ({
        color: isDeleted ? 'text.disabled' : 'text.primary',
    }),
}

const UserManagement = () => {
    const { t } = useTranslation()

    const { showLibellulaWhitelabelling } = useToolBoxTreatments()

    const currentUserId = useSelector(selectCurrentUserId)
    const organisation = useSelector(selectOrganisation)
    const organisationId = useSelector(selectOrganisationId)
    const isCurrentUserAdmin = useSelector(selectIsCurrentUserAdmin)
    const isCurrentUserOwner = useSelector(selectIsCurrentUserOwner)
    const maxUsersBySubscription = useSelector(selectMaxUsers)

    const [getOrganisationUsersRequest, { data: organisationUsers, isLoading }] = useLazyGetOrganisationUsersQuery()

    const [getOrganisationUserRequest] = useLazyGetOrganisationUserQuery()

    const [removeOrganisationUserRequest] = useRemoveOrganisationUserMutation()

    const [errorLoadingUsers, setErrorLoadingUsers] = useState(false)
    const [showAddUserDialog, setShowAddUserDialog] = useState(false)
    const [showTransferOwnerButton, setShowTransferOwnerButton] = useState(false)
    const [showTransferOwnerDialog, setShowTransferOwnerDialog] = useState(false)
    const [showChangeRoleDialog, setShowChangeRoleDialog] = useState(false)
    const [showDeleteUserDialog, setShowDeleteUserDialog] = useState(false)
    const [otherAdministrators, setOtherAdministrators] = useState([])
    const [selectedUser, setSelectedUser] = useState(null)

    const [remainingUsersBySubscription, setRemainingUsersBySubscription] = useState(null)
    const [currentPage, setCurrentPage] = useState(0)
    const [currentRowsPerPage, setCurrentRowsPerPage] = useLocalStorage(
        USERS_PER_PAGE_VARIABLE,
        USERS_PER_PAGE_DEFAULT_VALUE
    )
    const { instance: msalInstance } = useMsal()

    const isSiteAdminOrSupport = useMemo(
        () => AuthorisationUtils.isSiteAdminOrSupport(msalInstance.getActiveAccount()),
        [msalInstance]
    )

    const currentUserCanEditUser = useMemo(
        () => isSiteAdminOrSupport || isCurrentUserAdmin || isCurrentUserOwner,
        [isSiteAdminOrSupport, isCurrentUserAdmin, isCurrentUserOwner]
    )

    const getOrganisationUsers = useCallback(async () => {
        try {
            await getOrganisationUsersRequest({ organisationId }).unwrap()
        } catch (error) {
            setErrorLoadingUsers(true)
        }
    }, [organisation])

    const getOrganisationUser = useCallback(
        async (organisationId, userId) => {
            try {
                const response = await getOrganisationUserRequest({ organisationId, userId }).unwrap()
                return response
            } catch (error) {
                console.error(error)
            }
        },
        [organisationId]
    )

    const handleUpgradeButtonClicked = () => {
        window.open(
            showLibellulaWhitelabelling
                ? LIBELLULA_PRICING_REDIRECT_URL
                : `${TOOLBOX_PRICING_REDIRECT_URL}${organisation?.paymentGatewayCustomerId}`
        )
    }

    const handleAddButtonClick = () => {
        setShowAddUserDialog(true)
    }

    const handleNewUserAdded = () => {
        setShowAddUserDialog(false)
    }

    const handleChangeRoleButtonClick = (user) => {
        setSelectedUser(user)
        setShowChangeRoleDialog(true)
    }

    const handleChangeRoleDialogClose = () => {
        setShowChangeRoleDialog(false)
        setSelectedUser(null)
    }

    const handleChangeRoleConfirmation = () => {
        setShowChangeRoleDialog(false)
        setSelectedUser(null)
    }

    const handleDeleteButtonClick = (user) => {
        setSelectedUser(user)
        setShowDeleteUserDialog(true)
    }

    const handleDeleteCancel = () => {
        setShowDeleteUserDialog(false)
        setSelectedUser(null)
    }

    const handleDeleteConfirmation = async () => {
        try {
            const response = await removeOrganisationUserRequest({ organisationId, userId: selectedUser.userId })

            setShowDeleteUserDialog(false)
            setSelectedUser(null)

            return response
        } catch (error) {
            console.error(error)
        }
    }

    const handleOwnershipTransferred = () => {
        getOrganisationUsers()
        setShowTransferOwnerDialog(false)
    }

    const handleChangePage = (_, newPage) => {
        setCurrentPage(newPage)
    }

    const handleChangeRowsPerPage = (event) => {
        const newRowsPerPage = parseInt(event.target.value, 10)
        setCurrentRowsPerPage(newRowsPerPage)
        setCurrentPage(0)
    }

    useEffect(() => {
        getOrganisationUsers()
    }, [getOrganisationUsers])

    useEffect(() => {
        if (isSiteAdminOrSupport) {
            setShowTransferOwnerButton(true)
        } else {
            getOrganisationUser(organisationId, currentUserId).then((response) => {
                setShowTransferOwnerButton(response?.role?.toLowerCase() === 'owner')
            })
        }
    }, [organisationId, currentUserId, isSiteAdminOrSupport])

    useEffect(() => {
        const admins = []

        organisationUsers?.forEach((orgUser) => {
            if (orgUser.userId !== currentUserId && orgUser.role.toLowerCase() === 'administrator') {
                admins.push(orgUser)
            }
        })
        setOtherAdministrators(admins)
    }, [currentUserId, organisationUsers])

    const whiteListedDomains = ['@ipcompute.com', '@tempustools.com']

    const paidUsers = organisationUsers?.filter(
        (u) => u.emailAddress && !whiteListedDomains.some((d) => u.emailAddress.endsWith(d))
    )

    useEffect(() => {
        if (organisationUsers?.length && maxUsersBySubscription !== null) {
            setRemainingUsersBySubscription(maxUsersBySubscription - paidUsers?.length)
        }
    }, [paidUsers, organisationUsers, maxUsersBySubscription])

    return (
        <>
            <Grid
                px={2}
                spacing={3}
                container
            >
                <Grid xs={12}>
                    <Typography
                        data-testid="organisation-pricing-setting"
                        id="user-management"
                        sx={classes.title}
                        variant="h6"
                    >
                        {t('User management')}
                    </Typography>
                </Grid>

                {isLoading ? (
                    <Grid xs={12}>
                        <Box
                            display="flex"
                            gap={1}
                            justifyContent="center"
                            marginBlock={2}
                        >
                            <CircularProgress
                                color="secondary"
                                size={21}
                            />
                            <Typography variant="body2">{t('Loading users...')}</Typography>
                        </Box>
                    </Grid>
                ) : errorLoadingUsers ? (
                    <Grid xs={12}>
                        <Typography
                            color="error.main"
                            variant="body1"
                        >
                            {t('$t(An error occurred) loading your organisation users')}
                        </Typography>
                    </Grid>
                ) : (
                    <>
                        <Grid xs={6}>
                            {!organisation.hasActiveSubscription ? (
                                <Typography variant="body2">
                                    <Link to="/activate">{t('Activate now')}</Link> {t('to register more users.')}
                                </Typography>
                            ) : null}

                            {organisation.hasActiveSubscription && remainingUsersBySubscription > 0 ? (
                                <>
                                    <Typography variant="body2">
                                        {t('{{maxUsers}} paid users included in your plan.', {
                                            remainingUsersCount: remainingUsersBySubscription,
                                            maxUsers: maxUsersBySubscription,
                                        })}
                                    </Typography>
                                    <Typography variant="body2">
                                        {t('{{remainingUsersCount}} of {{maxUsers}} paid users remaining.', {
                                            remainingUsersCount: remainingUsersBySubscription,
                                            maxUsers: maxUsersBySubscription,
                                        })}
                                    </Typography>
                                </>
                            ) : null}

                            {organisation.hasActiveSubscription && remainingUsersBySubscription <= 0 ? (
                                <>
                                    <Typography variant="body2">
                                        {t('{{paidUsersCount}} of {{maxUsers}} paid users registered.', {
                                            paidUsersCount: paidUsers?.length,
                                            maxUsers: maxUsersBySubscription,
                                        })}
                                    </Typography>
                                    <Typography variant="body2">
                                        {t('You have reached the maximum number of paid users for your plan.', {
                                            paidUsersCount: paidUsers?.length,
                                            maxUsers: maxUsersBySubscription,
                                        })}{' '}
                                        <Link onClick={handleUpgradeButtonClicked}>
                                            {t('Upgrade to a higher plan')}
                                        </Link>{' '}
                                        {t('to register more users.')}
                                    </Typography>
                                </>
                            ) : null}
                        </Grid>
                        <Grid
                            alignItems={'center'}
                            display="flex"
                            gap={2}
                            justifyContent="flex-end"
                            xs={6}
                        >
                            {showTransferOwnerButton && Boolean(otherAdministrators.length) ? (
                                <Button
                                    color="secondary"
                                    size="medium"
                                    startIcon={<SwapHorizRounded />}
                                    variant="contained"
                                    onClick={() => setShowTransferOwnerDialog(true)}
                                >
                                    {t('Transfer')}
                                </Button>
                            ) : null}
                            <Button
                                color="secondary"
                                disabled={
                                    !isSiteAdminOrSupport
                                        ? remainingUsersBySubscription <= 0 || !organisation.hasActiveSubscription
                                        : null
                                }
                                size="medium"
                                startIcon={<PersonAddRounded />}
                                variant="outlined"
                                onClick={handleAddButtonClick}
                            >
                                {t('Add user')}
                            </Button>
                        </Grid>

                        <Grid xs={12}>
                            <TableContainer sx={classes.tableContainer}>
                                <Table sx={classes.table}>
                                    <colgroup>
                                        <col style={{ width: '180px' }} />
                                        <col style={{ width: '200px' }} />
                                        <col style={{ width: '100px' }} />
                                        <col style={{ width: '100px' }} />
                                    </colgroup>

                                    <TableHead>
                                        <TableRow>
                                            <TableCell sx={classes.headerTableCell}>{t('Name')}</TableCell>
                                            <TableCell sx={classes.headerTableCell}>{t('Email address')}</TableCell>
                                            <TableCell sx={classes.headerTableCell}>{t('Role')}</TableCell>
                                            <TableCell />
                                        </TableRow>
                                    </TableHead>
                                    <TableBody>
                                        {organisationUsers?.length
                                            ? organisationUsers
                                                  .slice(
                                                      currentPage * currentRowsPerPage,
                                                      currentPage * currentRowsPerPage + currentRowsPerPage
                                                  )
                                                  .map((user) => {
                                                      return (
                                                          <TableRow
                                                              key={user.userId}
                                                              sx={classes.tableRow}
                                                          >
                                                              <TableCell
                                                                  sx={[
                                                                      classes.tableCell,
                                                                      classes.tableCellWithEllipsis,
                                                                  ]}
                                                              >{`${user.firstName} ${user.lastName}`}</TableCell>

                                                              <TableCell
                                                                  sx={[
                                                                      classes.tableCell,
                                                                      classes.tableCellWithEllipsis,
                                                                  ]}
                                                                  title={user.emailAddress}
                                                              >
                                                                  {user.emailAddress}
                                                              </TableCell>

                                                              <TableCell sx={classes.tableCell}>
                                                                  {['owner', 'administrator', 'user'].includes(
                                                                      user.role.toLowerCase()
                                                                  )
                                                                      ? t(user.role)
                                                                      : null}
                                                              </TableCell>

                                                              <TableCell
                                                                  align="right"
                                                                  sx={classes.tableCell}
                                                              >
                                                                  {currentUserCanEditUser &&
                                                                  user.role.toLowerCase() !== 'owner' ? (
                                                                      <Box
                                                                          display="flex"
                                                                          justifyContent="flex-end"
                                                                      >
                                                                          <IconButton
                                                                              size="small"
                                                                              onClick={() => {
                                                                                  handleChangeRoleButtonClick(user)
                                                                              }}
                                                                          >
                                                                              <EditRounded
                                                                                  fontSize="small"
                                                                                  sx={classes.iconColor}
                                                                              />
                                                                          </IconButton>
                                                                          <IconButton
                                                                              size="small"
                                                                              sx={classes.iconColor}
                                                                              onClick={() => {
                                                                                  handleDeleteButtonClick(user)
                                                                              }}
                                                                          >
                                                                              <DeleteRounded fontSize="small" />
                                                                          </IconButton>
                                                                      </Box>
                                                                  ) : null}
                                                              </TableCell>
                                                          </TableRow>
                                                      )
                                                  })
                                            : null}
                                    </TableBody>
                                </Table>

                                <TablePagination
                                    component="div"
                                    count={organisationUsers?.length ?? 0}
                                    labelDisplayedRows={({ count, from, to }) => `${from}-${to} of ${count}`}
                                    labelRowsPerPage={t('Users per page')}
                                    page={currentPage}
                                    rowsPerPage={currentRowsPerPage}
                                    rowsPerPageOptions={USERS_PER_PAGE_OPTIONS}
                                    sx={classes.tableFooter}
                                    onPageChange={handleChangePage}
                                    onRowsPerPageChange={handleChangeRowsPerPage}
                                />
                            </TableContainer>
                        </Grid>
                    </>
                )}
            </Grid>

            <AddUserDialog
                open={showAddUserDialog}
                onAddUser={handleNewUserAdded}
                onClose={() => setShowAddUserDialog(false)}
            />

            <ChangeUserRole
                open={showChangeRoleDialog}
                user={selectedUser}
                onClose={handleChangeRoleDialogClose}
                onUpdateUserRole={handleChangeRoleConfirmation}
            />

            <TransferOwnershipDialog
                open={showTransferOwnerDialog}
                users={otherAdministrators}
                onCancelClose={() => setShowTransferOwnerDialog(false)}
                onTransferClose={handleOwnershipTransferred}
            />

            <AlertDialog
                content={t('Are you sure want to remove {{userFullname}} from your organisation?', {
                    userFullname: selectedUser && `${selectedUser.firstName} ${selectedUser.lastName}`,
                })}
                okButtonText={t('Yes, Delete')}
                open={showDeleteUserDialog}
                title={t('Delete user')}
                onCancelClose={handleDeleteCancel}
                onOkClose={handleDeleteConfirmation}
            />
        </>
    )
}

export default UserManagement
