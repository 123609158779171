import { createSlice } from '@reduxjs/toolkit'

const initialState = {
    selectedSheet: null,
    selectedSheets: [],
}

export const sheetsSlice = createSlice({
    name: 'sheetsSlice',
    initialState,
    reducers: {
        setSelectedSheet: (state, action) => {
            state.selectedSheet = action.payload
        },

        setSelectedSheets: (state, action) => {
            state.selectedSheets = action.payload
        },

        addToSelectedSheets: (state, action) => {
            state.selectedSheets.push(action.payload)
        },

        removeFromSelectedSheets: (state, action) => {
            state.selectedSheets = state.selectedSheets.filter((sheet) => sheet.sheetId !== action.payload.sheetId)
        },

        clearSelectedSheets: (state) => {
            state.selectedSheets = []
        },
    },
})

//export const selectSheets = (state) => state.sheetsSlice.sheets
export const selectSelectedSheet = (state) => state.sheetsSlice.selectedSheet
export const selectSelectedSheets = (state) => state.sheetsSlice.selectedSheets

export const {
    addToSelectedSheets,
    clearSelectedSheets,
    removeFromSelectedSheets,
    setSelectedSheet,
    setSelectedSheets,
} = sheetsSlice.actions

export default sheetsSlice.reducer
