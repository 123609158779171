import { memo } from 'react'
import { useTranslation } from 'react-i18next'
import { CheckCircleTwoTone, CircleTwoTone, ErrorTwoTone, HighlightOffTwoTone } from '@mui/icons-material'
import { Box, Typography } from '@mui/material'
import PropTypes from 'prop-types'

import TbxTooltip from '../shared/TbxTooltip'

const classes = {
    success: {
        color: 'success.main',
    },
    warning: {
        color: 'warning.main',
    },
    error: {
        color: 'error.main',
    },
    overlay: {
        color: 'primary.contrastText',
    },
}

const QuoteItemStatus = ({ count = 0, messages = [], status = 'success' }) => {
    const { t } = useTranslation()

    //return a span for each item in message array
    const title =
        messages.length &&
        messages.map((message, index) => {
            return (
                <Box
                    component="span"
                    display="block"
                    key={index}
                    py={0.5}
                >
                    {t(message)}
                </Box>
            )
        })

    switch (status) {
        case 'success':
            return (
                <TbxTooltip
                    title=""
                    arrow
                >
                    <Box
                        alignItems="center"
                        display="flex"
                        ml={1}
                    >
                        <CheckCircleTwoTone
                            aria-label="Success"
                            fontSize="small"
                            sx={classes.success}
                        />
                        {count ? (
                            <Typography
                                style={{ paddingLeft: 6 }}
                                sx={classes.success}
                            >
                                {count}
                            </Typography>
                        ) : null}
                    </Box>
                </TbxTooltip>
            )
        case 'warning':
            return (
                <TbxTooltip
                    title={title || ''}
                    arrow
                >
                    <Box
                        alignItems="center"
                        display="flex"
                        ml={1}
                    >
                        <ErrorTwoTone
                            aria-label="Warning"
                            fontSize="small"
                            sx={classes.warning}
                        />
                        {count ? (
                            <Typography
                                style={{ paddingLeft: 6 }}
                                sx={classes.warning}
                            >
                                {count}
                            </Typography>
                        ) : null}
                    </Box>
                </TbxTooltip>
            )
        case 'error':
            return (
                <TbxTooltip
                    title={title || ''}
                    arrow
                >
                    <Box
                        alignItems="center"
                        display="flex"
                        ml={1}
                    >
                        <HighlightOffTwoTone
                            aria-label="Error"
                            fontSize="small"
                            sx={classes.error}
                        />
                        {count ? (
                            <Typography
                                style={{ paddingLeft: 6 }}
                                sx={classes.error}
                            >
                                {count}
                            </Typography>
                        ) : null}
                    </Box>
                </TbxTooltip>
            )

        case 'overlay':
            return (
                <Box
                    alignItems="center"
                    display="flex"
                    ml={1}
                >
                    <CircleTwoTone
                        aria-label="Error"
                        fontSize="small"
                        sx={classes.overlay}
                    />
                </Box>
            )
    }
}

QuoteItemStatus.propTypes = {
    count: PropTypes.number,
    messages: PropTypes.array,
    status: PropTypes.oneOf(['success', 'warning', 'error', 'overlay']),
}

export default memo(QuoteItemStatus)
