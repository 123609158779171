import { useTranslation } from 'react-i18next'
import { useSelector } from 'react-redux'
import { Create } from '@mui/icons-material'
import { Box, Card, CardContent, CardHeader, IconButton, Typography } from '@mui/material'
import PropTypes from 'prop-types'

import { selectLocale } from '@/app/slices/appSlice'
import { selectCurrencySymbol, selectOrganisation, selectUseImperialUnits } from '@/app/slices/organisationSlice'
import { useNumberFormatter, useToolBoxTreatments } from '@/common/hooks'
import { fractionToPercentage } from '@/common/utils'

const classes = {
    root: {
        gap: '24px',
        width: '400px',
        background: 'background.paper',
        border: '1px solid',
        borderColor: (theme) => theme.palette.grey[300],
        borderRadius: '8px',
        boxShadow: 'none',
    },
    archived: {
        color: 'text.disabled',
    },
    cardHeader: {
        paddingBottom: '0px',
    },
    cardHeaderIcon: {
        padding: '6px',
        color: 'text.secondary',
    },
    cardContent: {
        display: 'flex',
        flexDirection: 'column',
        gap: '24px',
    },
    infoGroup: {
        display: 'flex',
        flexDirection: 'column',
        gap: '12px',
    },
    infoRow: {
        display: 'flex',
        flexDirection: 'row',
        justifyContent: 'space-between',
    },
    infoStrong: {
        fontWeight: 700,
        fontSize: '14px',
        lineHeight: '143%',
        letterSpacing: '0.17px',
    },
}

const setupModeOptions = {
    None: { name: 'None', value: 'None' },
    ByLine: { name: 'Line Item', value: 'ByLine' },
    ByGroup: { name: 'Material', value: 'ByGroup' },
}

const TbxFoldingCard = ({ onEdit }) => {
    const { t } = useTranslation()

    const organisation = useSelector(selectOrganisation)
    const currencySymbol = useSelector(selectCurrencySymbol)
    const useImperialUnits = useSelector(selectUseImperialUnits)

    const { showFoldingProductionWarnings } = useToolBoxTreatments()

    const { n } = useNumberFormatter()

    return (
        <Card
            data-testid="folding-card"
            key={organisation?.foldingSettings.foldingSettingsId}
            sx={classes.root}
        >
            <CardHeader
                action={
                    <IconButton
                        aria-label="edit"
                        disabled={organisation?.foldingSettings.isDeleted}
                        size="large"
                        sx={classes.cardHeaderIcon}
                        onClick={onEdit}
                    >
                        <Create />
                    </IconButton>
                }
                sx={classes.cardHeader}
            />
            <CardContent sx={classes.cardContent}>
                <Box sx={classes.infoGroup}>
                    {showFoldingProductionWarnings ? (
                        <>
                            <Box sx={classes.infoRow}>
                                <Typography
                                    data-testid="maximum-press-tonnage-label"
                                    variant="body2"
                                >
                                    {t('Maximum press tonnage (tonnes)')}
                                </Typography>
                                <Typography
                                    sx={classes.infoStrong}
                                    variant="body2"
                                >
                                    {n(organisation?.foldingSettings.maximumPressTonnage)}
                                </Typography>
                            </Box>
                            <Box sx={classes.infoRow}>
                                <Typography
                                    data-testid="maximum-press-width-label"
                                    variant="body2"
                                >
                                    {t('Maximum press width')} {useImperialUnits ? '(in)' : '(mm)'}
                                </Typography>
                                <Typography
                                    sx={classes.infoStrong}
                                    variant="body2"
                                >
                                    {n(organisation?.foldingSettings.maximumPressWidth)}
                                </Typography>
                            </Box>
                        </>
                    ) : null}
                    <Box sx={classes.infoRow}>
                        <Typography
                            data-testid="base-hourly-rate-label"
                            variant="body2"
                        >
                            {t('Base hourly rate ({{currencySymbol}})', { currencySymbol })}
                        </Typography>
                        <Typography
                            sx={classes.infoStrong}
                            variant="body2"
                        >
                            {n(organisation?.foldingSettings.baseHourlyRate)}
                        </Typography>
                    </Box>
                    <Box sx={classes.infoRow}>
                        <Typography
                            data-testid="added-labour-label"
                            variant="body2"
                        >
                            {t('Additional labour hourly rate ({{currencySymbol}})', {
                                currencySymbol,
                            })}
                        </Typography>
                        <Typography
                            sx={classes.infoStrong}
                            variant="body2"
                        >
                            {n(organisation?.foldingSettings.addedLabourHourlyRate)}
                        </Typography>
                    </Box>
                    <Box sx={classes.infoRow}>
                        <Typography
                            data-testid="folding-setup-mode-label"
                            variant="body2"
                        >
                            {t('Setup mode')}
                        </Typography>
                        <Typography
                            sx={classes.infoStrong}
                            variant="body2"
                        >
                            {setupModeOptions[organisation?.foldingSettings.setupMode]?.name
                                ? t(setupModeOptions[organisation?.foldingSettings.setupMode]?.name)
                                : null}
                        </Typography>
                    </Box>
                    <Box sx={classes.infoRow}>
                        <Typography
                            data-testid="setup-override-label"
                            variant="body2"
                        >
                            {t('Setup override (%)')}
                        </Typography>
                        <Typography
                            sx={classes.infoStrong}
                            variant="body2"
                        >
                            {n(fractionToPercentage(organisation?.foldingSettings.setupOverride))}
                        </Typography>
                    </Box>
                    <Box sx={classes.infoRow}>
                        <Typography
                            data-testid="production-override-label"
                            variant="body2"
                        >
                            {t('Production override (%)')}
                        </Typography>
                        <Typography
                            sx={classes.infoStrong}
                            variant="body2"
                        >
                            {n(fractionToPercentage(organisation?.foldingSettings.productionOverride))}
                        </Typography>
                    </Box>
                </Box>
            </CardContent>
        </Card>
    )
}

TbxFoldingCard.propTypes = {
    onEdit: PropTypes.func,
}

export default TbxFoldingCard
