import { useTranslation } from 'react-i18next'
import { useSelector } from 'react-redux'
import { useParams } from 'react-router-dom'
import { Dialog, DialogContent } from '@mui/material'
import { useSnackbar } from 'notistack'
import PropTypes from 'prop-types'

import { useAddQuoteItemsFrom3DMutation } from '@/app/services/quoteItems'
import { useCalculateQuoteMutation } from '@/app/services/quotes'
import { selectOrganisationId } from '@/app/slices/organisationSlice'
import Unfold from '@/features/quotes/components/Unfold/Unfold'

const classes = {
    dialogRoot: {
        borderRadius: 1,
    },
    dialogContent: {
        display: 'flex',
        flexDirection: 'row',
        p: 0,
    },
}

const Add3DPartsDialog = ({ is3DTube = false, onClose, open = false }) => {
    const { t } = useTranslation()
    const { enqueueSnackbar } = useSnackbar()

    const { quoteId } = useParams()
    const organisationId = useSelector((state) => selectOrganisationId(state))

    const [addQuoteItemsFrom3d] = useAddQuoteItemsFrom3DMutation()
    const [calculateQuote] = useCalculateQuoteMutation({
        fixedCacheKey: 'shared-calculate-quote',
    })

    const handleClose = () => {
        onClose()
    }
    const handleAdd3DParts = async () => {
        try {
            await addQuoteItemsFrom3d({ organisationId, quoteId: quoteId }).unwrap()

            const successMessage = is3DTube
                ? `${t('Rotary parts added to $t(quote)')}`
                : `${t('3D parts added to $t(quote)')}`

            enqueueSnackbar(successMessage, { variant: 'success' })
            calculateQuote({ organisationId, quoteId: quoteId })
            handleClose()
        } catch (error) {
            const errorMessage = is3DTube ? `${t('Rotary parts not added')}` : `${t('3D parts not added')}`
            enqueueSnackbar(errorMessage, { variant: 'error' })
            handleClose()
        }
    }

    return (
        <Dialog
            fullScreen={true}
            open={open}
            sx={classes.dialogRoot}
            onClose={handleClose}
        >
            <DialogContent sx={classes.dialogContent}>
                <Unfold
                    organisationId={organisationId}
                    quoteId={quoteId}
                    unfoldTube={is3DTube}
                    onCancelClicked={handleClose}
                    onSubmitClicked={handleAdd3DParts}
                />
            </DialogContent>
        </Dialog>
    )
}

Add3DPartsDialog.propTypes = {
    onClose: PropTypes.func.isRequired,
    is3DTube: PropTypes.bool,
    open: PropTypes.bool,
}

export default Add3DPartsDialog
