import { useTranslation } from 'react-i18next'
import Box from '@mui/material/Box'
import Checkbox from '@mui/material/Checkbox'
import TableCell from '@mui/material/TableCell'
import TextField from '@mui/material/TextField'
import PropTypes from 'prop-types'

const OrderProcessCell = ({
    bottomInputLabel = '',
    bottomInputValue = '',
    showBottomInput = true,
    showTopInput = true,
    sx,
    topInputLabel = '',
    topInputValue = '',
}) => {
    const { t } = useTranslation()

    return (
        <TableCell
            align="left"
            sx={sx}
        >
            <Box
                display="flex"
                gap={1}
            >
                <Box
                    display="flex"
                    flexDirection="column"
                    gap={2}
                >
                    {showTopInput ? (
                        <TextField
                            id="outlined-basic"
                            InputLabelProps={{
                                shrink: true,
                                sx: { pointerEvents: 'none' },
                            }}
                            InputProps={{
                                sx: { typography: 'inputTextSmall' },
                            }}
                            inputProps={{
                                sx: { textAlign: 'right' },
                            }}
                            label={topInputLabel || ''}
                            size="small"
                            sx={{ minWidth: 90, maxwidth: 1, pointerEvents: 'none' }}
                            value={topInputValue || ''}
                            variant="outlined"
                        />
                    ) : null}

                    {showBottomInput ? (
                        <TextField
                            id="outlined-basic"
                            InputLabelProps={{
                                shrink: true,
                                sx: { pointerEvents: 'none' },
                            }}
                            InputProps={{
                                sx: { typography: 'inputTextSmall' },
                            }}
                            inputProps={{
                                sx: { textAlign: 'right' },
                            }}
                            label={bottomInputLabel || t('Total time')}
                            size="small"
                            sx={{ minWidth: 90, maxWidth: 1, pointerEvents: 'none' }}
                            value={bottomInputValue || ''}
                            variant="outlined"
                        />
                    ) : null}
                </Box>

                <Checkbox sx={{ pointerEvents: 'none' }} />
            </Box>
        </TableCell>
    )
}

OrderProcessCell.propTypes = {
    bottomInputLabel: PropTypes.string,
    bottomInputValue: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
    showBottomInput: PropTypes.bool,
    showTopInput: PropTypes.bool,
    sx: PropTypes.oneOfType([
        PropTypes.arrayOf(PropTypes.oneOfType([PropTypes.func, PropTypes.object, PropTypes.bool])),
        PropTypes.func,
        PropTypes.object,
    ]),
    topInputLabel: PropTypes.string,
    topInputValue: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
}

export default OrderProcessCell
