import { memo, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { NumericFormat } from 'react-number-format'
import { useDispatch, useSelector } from 'react-redux'
import { useParams } from 'react-router-dom'
import { Box, FormControl, MenuItem, Select, TextField, Typography } from '@mui/material'
import { useSnackbar } from 'notistack'
import PropTypes from 'prop-types'
import { useDebouncedCallback } from 'use-debounce'

import { useGetQuoteItemsQuery, useLazyGetQuoteItemsQuery, useUpdateQuoteItemMutation } from '@/app/services/quoteItems'
import { useGetQuoteQuery, useUpdateQuoteMutation } from '@/app/services/quotes'
import { selectLocale } from '@/app/slices/appSlice'
import { selectCurrencyCode, selectOrganisationId } from '@/app/slices/organisationSlice'
import { setSelectedQuoteStatus } from '@/app/slices/quoteItemsSlice'
import TbxTooltip from '@/common/components/TbxTooltip/TbxTooltip'
import { getCurrencyFormat } from '@/common/utils'
import { QUOTE_STATUS } from '@/common/utils/Constants/Constants'

const classes = {
    foldingSection: {
        width: '100%',
        gap: '12px',
    },
    bold: {
        fontWeight: 'bold',
    },
    itemColumn: {
        display: 'flex',
        flex: 1,
        fontSize: '14px',
    },
    formControl_medium: {
        width: '120px',
    },
    formControl_small: {
        width: '80px',
    },
}

const ItemRow = ({ children }) => (
    <Box
        alignItems="center"
        display="flex"
        flexDirection="row"
        justifyContent="space-between"
        width="100%"
    >
        {children}
    </Box>
)

const ItemFolding = ({ quoteItemId }) => {
    const { t } = useTranslation()
    const { enqueueSnackbar } = useSnackbar()
    const { quoteId } = useParams()
    const dispatch = useDispatch()

    const organisationId = useSelector(selectOrganisationId)
    const locale = useSelector(selectLocale)
    const currencyCode = useSelector(selectCurrencyCode)

    const { data: selectedQuote } = useGetQuoteQuery({ organisationId, quoteId })
    const { quoteItem } = useGetQuoteItemsQuery(
        {
            organisationId,
            quoteId,
        },
        {
            selectFromResult: ({ data }) => ({
                quoteItem: data?.find((item) => item.id === quoteItemId),
            }),
        }
    )

    const [updateQuote] = useUpdateQuoteMutation()
    const [updateQuoteItem] = useUpdateQuoteItemMutation()
    const [getQuoteItems] = useLazyGetQuoteItemsQuery()

    const [itemSource, setItemSource] = useState(quoteItem?.foldingSource || '')
    const [itemMaxLength, setItemMaxLength] = useState(quoteItem?.maxFoldLength || '')
    const [itemNumberOfFolds, setItemNumberOfFolds] = useState(quoteItem?.numberOfFolds || '')

    const units = quoteItem?.isImperial ? 'in' : 'mm'

    const isPartFromLibrary = Boolean(quoteItem?.partLibraryEntryId)

    const currencyFormat = getCurrencyFormat(currencyCode, locale)

    const handleUpdateQuote = async () => {
        try {
            await updateQuote({
                organisationId,
                quoteId,
                quote: {
                    ...selectedQuote,
                    status: QUOTE_STATUS.NotCalculated,
                },
            }).unwrap()
        } catch (_e) {
            const errorMessage = t('$t(An error occurred) updating the $t(quote).')
            enqueueSnackbar(errorMessage, { variant: 'error' })
        }
    }

    const handleUpdateQuoteItem = async (quoteItem) => {
        try {
            await updateQuoteItem({
                organisationId,
                quoteId,
                quoteItemId: quoteItem?.id,
                quoteItem,
            }).unwrap()

            if (selectedQuote.status !== QUOTE_STATUS.NotCalculated) {
                handleUpdateQuote()
            }
        } catch (error) {
            enqueueSnackbar(t('Failed to update the $t(quote) item'), {
                variant: 'error',
            })
        }
    }

    const debounceHandleUpdateQuoteItem = useDebouncedCallback(handleUpdateQuoteItem, 800)

    const handleSourceChange = async (event) => {
        if (selectedQuote.status !== QUOTE_STATUS.NotCalculated) {
            dispatch(setSelectedQuoteStatus(QUOTE_STATUS.NotCalculated))
        }

        const value = event?.target?.value
        if (Boolean(value) && itemSource !== value) {
            setItemSource(value)

            await handleUpdateQuoteItem({
                ...quoteItem,
                foldingSource: value,
            })

            if (value === 'Drawing') {
                await getQuoteItems({ organisationId, quoteId })
            }
        }
    }

    const handleMaxLengthChange = async (values) => {
        debounceHandleUpdateQuoteItem.cancel()
        const { floatValue } = values
        if (selectedQuote.status !== QUOTE_STATUS.NotCalculated) {
            dispatch(setSelectedQuoteStatus(QUOTE_STATUS.NotCalculated))
        }
        setItemMaxLength(floatValue)

        debounceHandleUpdateQuoteItem({
            ...quoteItem,
            maxFoldLength: floatValue,
            numberOfFolds: itemNumberOfFolds,
        })
    }

    const handleNumberOfFoldsChange = async (values) => {
        debounceHandleUpdateQuoteItem.cancel()
        const { floatValue } = values
        if (selectedQuote.status !== QUOTE_STATUS.NotCalculated) {
            dispatch(setSelectedQuoteStatus(QUOTE_STATUS.NotCalculated))
        }
        setItemNumberOfFolds(floatValue)

        debounceHandleUpdateQuoteItem({
            ...quoteItem,
            numberOfFolds: floatValue,
            maxFoldLength: itemMaxLength,
        })
    }

    return (
        <Box
            alignItems="flex-start"
            display="flex"
            flex="1"
            flexDirection="column"
            sx={classes.foldingSection}
        >
            <ItemRow>
                <Typography
                    data-testid="quote-item-folding-section-title"
                    sx={classes.bold}
                >
                    {t('Folding')}
                </Typography>
            </ItemRow>
            <ItemRow>
                <Typography
                    data-testid="nqp-quote-item-source-label"
                    sx={classes.itemColumn}
                >
                    {t('Source')}
                </Typography>
                <TbxTooltip
                    title={isPartFromLibrary ? t('Cannot edit parts from library') : ''}
                    arrow
                >
                    <FormControl
                        color="primary"
                        margin="none"
                        size="small"
                        sx={classes.formControl_medium}
                        variant="outlined"
                    >
                        <Select
                            data-testid="nqp-quote-item-source"
                            disabled={isPartFromLibrary}
                            id="select-itemSource"
                            labelId="select-itemSource-label"
                            value={itemSource}
                            onChange={handleSourceChange}
                        >
                            <MenuItem
                                key={'Drawing'}
                                value={'Drawing'}
                            >
                                {t('Drawing')}
                            </MenuItem>
                            <MenuItem
                                key={'Manual'}
                                value={'Manual'}
                            >
                                {t('Manual')}
                            </MenuItem>
                        </Select>
                    </FormControl>
                </TbxTooltip>
            </ItemRow>
            <ItemRow>
                <Typography
                    data-testid="nqp-quote-item-max-length-label"
                    sx={classes.itemColumn}
                >
                    {t('Max length')}
                </Typography>
                <TbxTooltip
                    title={isPartFromLibrary ? t('Cannot edit parts from library') : ''}
                    arrow
                >
                    <NumericFormat
                        allowNegative={false}
                        customInput={TextField}
                        decimalSeparator={currencyFormat.decimal}
                        disabled={itemSource === 'Drawing' || isPartFromLibrary}
                        inputProps={{
                            style: { textAlign: 'right' },
                            'data-testid': 'nqp-quote-item-max-length-quantity',
                        }}
                        placeholder={`0 ${units}`}
                        size="small"
                        suffix={` ${units}`}
                        sx={classes.formControl_medium}
                        thousandSeparator={currencyFormat.group}
                        value={itemMaxLength}
                        variant="outlined"
                        onValueChange={handleMaxLengthChange}
                    />
                </TbxTooltip>
            </ItemRow>
            <ItemRow>
                <Typography
                    data-testid="nqp-quote-item-number-of-folds-label"
                    sx={classes.itemColumn}
                >
                    {t('Number of folds')}
                </Typography>
                <TbxTooltip
                    title={isPartFromLibrary ? t('Cannot edit parts from library') : ''}
                    arrow
                >
                    <NumericFormat
                        allowNegative={false}
                        customInput={TextField}
                        decimalScale={0}
                        decimalSeparator={currencyFormat.decimal}
                        disabled={itemSource === 'Drawing' || isPartFromLibrary}
                        inputProps={{
                            style: { textAlign: 'right' },
                            'data-testid': 'nqp-quote-item-max-length-quantity',
                        }}
                        placeholder="0"
                        size="small"
                        sx={classes.formControl_small}
                        thousandSeparator={currencyFormat.group}
                        value={itemNumberOfFolds}
                        variant="outlined"
                        onValueChange={handleNumberOfFoldsChange}
                    />
                </TbxTooltip>
            </ItemRow>
        </Box>
    )
}

ItemFolding.propTypes = {
    quoteItemId: PropTypes.string,
}

export default memo(ItemFolding)
