import { Box } from '@mui/material'
import PropTypes from 'prop-types'

const classes = {
    root: {
        width: 16,
        height: 16,
        borderRadius: '2px',
    },
}

const LayerColorAdornment = ({ className, color = 'blue' }) => {
    const colorIsNone = !color || color.toLowerCase() === 'none'

    const containerStyle = Object.assign({}, classes.root, className)

    return (
        <Box
            style={colorIsNone ? { border: '0.5px dashed black' } : { backgroundColor: color }}
            sx={containerStyle}
        />
    )
}

LayerColorAdornment.propTypes = {
    color: PropTypes.string,
}

export default LayerColorAdornment
