import { useMemo, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useSelector } from 'react-redux'
import { ViewQuilt } from '@mui/icons-material'
import { Box, Button, Dialog, DialogContent, DialogTitle, Divider, Grid, Typography } from '@mui/material'
import { alpha } from '@mui/material/styles'
import PropTypes from 'prop-types'

import { useGetMaterialsQuery } from '@/app/services/materials'
import {
    selectCurrencyCode,
    selectCurrencySymbol,
    selectOrganisationId,
    selectPaidFeatures,
    selectUseImperialUnits,
} from '@/app/slices/organisationSlice'
import { getMaterialConsumptionName } from '@/common/helpers/MaterialConsumptionModes'
import { useCurrencyFormatter, useNumberFormatter } from '@/common/hooks/'
import { fractionToPercentage } from '@/common/utils'
import QuoteCalculatorNestDialog from '@/features/quotes/components/QuoteCalculatorNestDialog/QuoteCalculatorNestDialog'

const classes = {
    dialog: {
        maxWidth: '680px',
    },
    dialogContent: {
        gap: 2,
    },
    dialogSection: {
        gap: 1,
    },
    bold: {
        fontWeight: 700,
    },
    summaryRow: {
        fontWeight: 700,
        fontSize: '16px',
    },
    clickable: {
        cursor: 'pointer',
    },
    dialogBackdrop: {
        backgroundColor: (theme) => alpha(theme.palette.text.primary, 0.9),
    },
}

const makeSafeFunc = (func) => (value) => (value && !isNaN(value) ? func(value) : '-')

const PriceDetailsDialog = ({ onClose, open, quoteItem, quoteOverrides, taxRate }) => {
    const { t } = useTranslation()

    const paidFeatures = useSelector(selectPaidFeatures)
    const organisationHasNestingEnabled = paidFeatures?.hasMaterialConsumptionModeOptions

    const currencySymbol = useSelector(selectCurrencySymbol)
    const currencyCode = useSelector(selectCurrencyCode)
    const useImperialUnits = useSelector(selectUseImperialUnits)

    const organisationId = useSelector(selectOrganisationId)
    const { data: materials } = useGetMaterialsQuery({ organisationId })

    const { formatCurrency } = useCurrencyFormatter({
        currencyCode,
        currencyDisplay: 'narrowSymbol',
    })

    const formatCurrencySafe = makeSafeFunc(formatCurrency)

    const { n: timeFormat } = useNumberFormatter({
        numberOfDecimalPlaces: 2,
    })

    const timeFormatSafe = makeSafeFunc(timeFormat)

    const units = useImperialUnits ? 'in' : 'mm'

    const isRotary = useMemo(() => {
        return Boolean(quoteItem?.profile)
    }, [quoteItem])

    const otherChargePriceItem = useMemo(() => {
        if (!quoteItem) {
            return null
        }
        return quoteItem.otherChargePrice
    }, [quoteItem])

    const showPriceAdjustments = useMemo(() => {
        if (!quoteOverrides && !otherChargePriceItem) return false
        const hasCuttingTechOverrides = quoteOverrides?.cuttingTechnologyOverrides?.some((o) =>
            Boolean(o.extraHourlyRatePrice)
        )
        const hasSheetOverrides = quoteOverrides?.sheetOverrides?.some((o) => Boolean(o.extraSheetPrice))
        return Boolean(hasCuttingTechOverrides || hasSheetOverrides || Boolean(otherChargePriceItem))
    }, [quoteOverrides, otherChargePriceItem])

    const showFoldingSection = useMemo(() => {
        if (!quoteItem) {
            return false
        }
        return quoteItem.numberOfFolds > 0
    }, [quoteItem])

    const showSecProcessesSection = useMemo(() => {
        if (!quoteItem) {
            return false
        }
        return quoteItem.secondaryProcesses.length > 0
    }, [quoteItem])

    const customerSuppliedHandlingFee = useMemo(() => {
        if (!quoteItem) {
            return null
        }
        const material = materials ? Object.values(materials).find((m) => m.materialId === quoteItem.materialId) : {}
        const fee = fractionToPercentage(material?.customerSuppliedMarkupPercentage)
        return fee ? `${fee}%` : '-'
    }, [quoteItem, materials, fractionToPercentage])

    const markup = useMemo(() => {
        if (!quoteItem) {
            return null
        }
        return fractionToPercentage(quoteItem.sheetMarkupPercentage)
    }, [quoteItem, fractionToPercentage])

    const extraSheetPrice = useMemo(() => {
        if (!quoteItem || !quoteOverrides) {
            return null
        }
        const sheetOverride = quoteOverrides.sheetOverrides.find((o) => o.sheetId === quoteItem.sheetId)
        return sheetOverride?.extraSheetPrice
    }, [quoteOverrides, quoteItem])

    // const extraHourlyRatePrice = useMemo(() => {
    //     if (!quoteItem || !quoteOverrides) {
    //         return null
    //     }
    //     const cuttingTechOverride = quoteOverrides.cuttingTechnologyOverrides.find(
    //         (o) => o.cuttingTechnologyId === quoteItem.cuttingTechnologyId
    //     )
    //     return cuttingTechOverride?.extraHourlyRatePrice
    // }, [quoteOverrides, quoteItem])

    const priceAdjustmentUnit = useMemo(() => {
        return otherChargePriceItem || 0
    }, [otherChargePriceItem])

    const priceAdjustmentLine = useMemo(() => {
        if (!quoteItem) {
            return null
        }
        return formatCurrencySafe(priceAdjustmentUnit * quoteItem?.quantity)
    }, [priceAdjustmentUnit, quoteItem, formatCurrencySafe])

    const fullSheetSellPrice = useMemo(() => {
        if (!quoteItem) {
            return null
        }
        const { sheetCostUnrounded, sheetMarkupPercentage } = quoteItem
        const price = sheetCostUnrounded * (1 + sheetMarkupPercentage) + (extraSheetPrice || 0)
        return formatCurrencySafe(price)
    }, [quoteItem, formatCurrencySafe, extraSheetPrice])

    const handlingFeePerSheet = useMemo(() => {
        if (!quoteItem) {
            return null
        }
        const material = materials ? Object.values(materials).find((m) => m.materialId === quoteItem.materialId) : {}
        const handlingFee = quoteItem.sheetCostUnrounded * material?.customerSuppliedMarkupPercentage
        return formatCurrencySafe(handlingFee)
    }, [quoteItem, materials, formatCurrencySafe])

    const nestBoundsPercentage = useMemo(() => {
        if (!quoteItem) {
            return null
        }
        return fractionToPercentage(quoteItem.nestArea / quoteItem.totalSheetArea, 2)
    }, [quoteItem, fractionToPercentage])

    const rotaryProfileConsumptionPercentage = useMemo(() => {
        if (!quoteItem) {
            return null
        }
        return fractionToPercentage(quoteItem.rotaryProfileConsumption / quoteItem.totalRotaryLength, 2)
    }, [quoteItem, fractionToPercentage])

    const percentageOfSheetUsed = useMemo(() => {
        if (!quoteItem) {
            return null
        }
        const { materialConsumptionMode } = quoteItem
        const nestPercentage = isRotary ? rotaryProfileConsumptionPercentage : nestBoundsPercentage
        const percentageUsed =
            materialConsumptionMode === 'NestBounds'
                ? ((1 / quoteItem.partsPerSheet) * nestPercentage) / 100
                : 1 / quoteItem.partsPerSheet
        return timeFormat(fractionToPercentage(percentageUsed))
    }, [
        quoteItem,
        fractionToPercentage,
        timeFormat,
        isRotary ? rotaryProfileConsumptionPercentage : nestBoundsPercentage,
    ])

    const totalMaterialPriceUnit = useMemo(() => {
        if (!quoteItem) {
            return null
        }
        return formatCurrencySafe(quoteItem.materialPrice)
    }, [quoteItem, formatCurrencySafe])

    const totalMaterialPriceLine = useMemo(() => {
        if (!quoteItem) {
            return null
        }
        return formatCurrencySafe(quoteItem.materialPrice * quoteItem.quantity)
    }, [quoteItem, formatCurrencySafe])

    const setupPriceLine = useMemo(() => {
        if (!quoteItem) {
            return null
        }
        return formatCurrencySafe(quoteItem.setupPrice * quoteItem.quantity)
    }, [quoteItem, formatCurrencySafe])

    const setupPriceItem = useMemo(() => {
        if (!quoteItem) {
            return null
        }
        return formatCurrencySafe(quoteItem.setupPrice)
    }, [quoteItem, formatCurrencySafe])

    const setupTimeItem = useMemo(() => {
        if (!quoteItem) {
            return null
        }
        return timeFormatSafe(quoteItem.setupTimePerItemSeconds)
    }, [quoteItem, timeFormatSafe])

    const sheetChangeTimeItem = useMemo(() => {
        if (!quoteItem) {
            return null
        }
        return timeFormatSafe(quoteItem.sheetChangeTimePerItemSeconds)
    }, [quoteItem, timeFormatSafe])

    const sheetChangePriceItem = useMemo(() => {
        if (!quoteItem) {
            return null
        }
        return formatCurrencySafe(quoteItem.sheetChangePrice)
    }, [quoteItem, formatCurrencySafe])

    const sheetChangePriceLine = useMemo(() => {
        if (!quoteItem) {
            return null
        }
        return formatCurrencySafe(quoteItem.sheetChangePrice * quoteItem.quantity)
    }, [quoteItem, formatCurrencySafe])

    const cuttingRate = useMemo(() => {
        if (!quoteItem) {
            return null
        }
        return formatCurrencySafe(quoteItem.cuttingAppliedHourlyRatePrice)
    }, [quoteItem, formatCurrencySafe])

    const cuttingFeedRate = useMemo(() => {
        if (!quoteItem) {
            return null
        }
        return timeFormatSafe(quoteItem.cuttingFeedRateMinute)
    }, [quoteItem, timeFormatSafe])

    const cuttingSetupRate = useMemo(() => {
        if (!quoteItem) {
            return null
        }
        return formatCurrencySafe(quoteItem.cuttingAppliedHourlyRatePrice / 3600)
    }, [quoteItem, formatCurrencySafe])

    const cuttingTimeItem = useMemo(() => {
        if (!quoteItem) {
            return null
        }
        return timeFormatSafe(quoteItem.runtime)
    }, [quoteItem, timeFormatSafe])

    const cuttingPriceItem = useMemo(() => {
        if (!quoteItem) {
            return null
        }
        return formatCurrencySafe(quoteItem.cutPrice)
    }, [quoteItem, formatCurrencySafe])

    const cuttingPriceLine = useMemo(() => {
        if (!quoteItem) {
            return null
        }
        return formatCurrencySafe(quoteItem.cutPrice * quoteItem.quantity)
    }, [quoteItem, formatCurrencySafe])

    const totalCuttingPriceItem = useMemo(() => {
        if (!quoteItem) {
            return null
        }
        return formatCurrencySafe(quoteItem.processingTotalPrice)
    }, [quoteItem, formatCurrencySafe])

    const totalCuttingPriceLine = useMemo(() => {
        if (!quoteItem) {
            return null
        }
        return formatCurrencySafe(quoteItem.processingTotalPrice * quoteItem.quantity)
    }, [quoteItem, formatCurrencySafe])

    const foldingSetupTimeItem = useMemo(() => {
        if (!quoteItem) {
            return null
        }
        return timeFormatSafe(quoteItem.foldingSetupTimePerItemSeconds)
    }, [quoteItem, timeFormatSafe])

    const foldingSetupPriceItem = useMemo(() => {
        if (!quoteItem) {
            return null
        }
        return formatCurrencySafe(quoteItem.foldingSetupPricePerItem)
    }, [quoteItem, formatCurrencySafe])

    const foldingSetupPriceLine = useMemo(() => {
        if (!quoteItem) {
            return null
        }
        return formatCurrencySafe(quoteItem.foldingSetupPricePerItem * quoteItem.quantity)
    }, [quoteItem, formatCurrencySafe])

    const foldingSetupRateItem = useMemo(() => {
        if (!quoteItem) {
            return null
        }
        const { foldingSetupPricePerItem, foldingSetupTimePerItemSeconds } = quoteItem
        const rate = foldingSetupPricePerItem / foldingSetupTimePerItemSeconds
        return formatCurrencySafe(isNaN(rate) ? 0 : rate)
    }, [quoteItem, formatCurrencySafe])

    const foldingProcessingTimeItem = useMemo(() => {
        if (!quoteItem) {
            return null
        }
        return timeFormatSafe(quoteItem.foldingProcessingTimePerItemSeconds)
    }, [quoteItem, timeFormatSafe])

    const foldingProcessingPriceItem = useMemo(() => {
        if (!quoteItem) {
            return null
        }
        return formatCurrencySafe(quoteItem.foldingProcessingPricePerItem)
    }, [quoteItem, formatCurrencySafe])

    const foldingProcessingPriceLine = useMemo(() => {
        if (!quoteItem) {
            return null
        }
        return formatCurrencySafe(quoteItem.foldingProcessingPricePerItem * quoteItem.quantity)
    }, [quoteItem, formatCurrencySafe])

    const foldingProcessingRateItem = useMemo(() => {
        if (!quoteItem) {
            return null
        }
        const { foldingProcessingPricePerItem, foldingProcessingTimePerItemSeconds } = quoteItem
        const rate = foldingProcessingPricePerItem / foldingProcessingTimePerItemSeconds
        return formatCurrencySafe(isNaN(rate) ? 0 : rate)
    }, [quoteItem, formatCurrencySafe])

    const foldingTotalPriceItem = useMemo(() => {
        if (!quoteItem) {
            return null
        }
        return formatCurrencySafe(quoteItem.foldingTotalPricePerItem)
    }, [quoteItem, formatCurrencySafe])

    const foldingTotalPriceLine = useMemo(() => {
        if (!quoteItem) {
            return null
        }
        return formatCurrencySafe(quoteItem.foldingTotalPricePerItem * quoteItem.quantity)
    }, [quoteItem, formatCurrencySafe])

    const secProcessesPriceItem = useMemo(() => {
        if (!quoteItem) {
            return null
        }
        return formatCurrencySafe(quoteItem.secondaryProcessTotalPricePerItem)
    }, [quoteItem, formatCurrencySafe])

    const secProcessesPriceLine = useMemo(() => {
        if (!quoteItem) {
            return null
        }
        return formatCurrencySafe(quoteItem.secondaryProcessTotalPricePerItem * quoteItem.quantity)
    }, [quoteItem, formatCurrencySafe])

    const subtotalUnitPrice = useMemo(() => {
        if (!quoteItem) {
            return null
        }
        return formatCurrencySafe(quoteItem.itemPrice)
    }, [quoteItem, formatCurrencySafe])

    const subtotalLinePrice = useMemo(() => {
        if (!quoteItem) {
            return null
        }
        return formatCurrencySafe(quoteItem.linePrice)
    }, [quoteItem, formatCurrencySafe])

    const taxUnitPrice = useMemo(() => {
        if (!quoteItem) {
            return null
        }
        return formatCurrencySafe(quoteItem.itemPrice * taxRate)
    }, [quoteItem, formatCurrencySafe, taxRate])

    const taxLinePrice = useMemo(() => {
        if (!quoteItem) {
            return null
        }
        return formatCurrencySafe(quoteItem.linePrice * taxRate)
    }, [quoteItem, formatCurrencySafe, taxRate])

    const totalUnitPrice = useMemo(() => {
        if (!quoteItem) {
            return null
        }
        return formatCurrencySafe(quoteItem.itemPrice * (1 + taxRate))
    }, [quoteItem, formatCurrencySafe, taxRate])

    const totalLinePrice = useMemo(() => {
        if (!quoteItem) {
            return null
        }
        return formatCurrencySafe(quoteItem.linePrice * (1 + taxRate))
    }, [quoteItem, formatCurrencySafe, taxRate])

    const [openNest, setOpenNest] = useState(false)
    const handleClickOpenNest = () => {
        setOpenNest(true)
    }
    const handleCloseNest = () => {
        setOpenNest(false)
    }

    return (
        <>
            <Dialog
                open={open}
                PaperProps={{
                    sx: classes.dialog,
                }}
                slotProps={{
                    backdrop: {
                        sx: classes.dialogBackdrop,
                    },
                }}
                fullWidth
                onClose={onClose}
            >
                <DialogTitle>
                    <Box sx={classes.titleContainer}>
                        {t('PRICE DETAILS FOR PART: {{name}}', { name: quoteItem?.name })}
                    </Box>
                </DialogTitle>
                <DialogContent>
                    <Box
                        display="flex"
                        flexDirection="column"
                        sx={classes.dialogContent}
                    >
                        <Box
                            alignItems="center"
                            display="flex"
                            justifyContent="space-between"
                        >
                            <Typography
                                data-testid="quantity-label"
                                sx={classes.bold}
                                variant="body2"
                            >
                                {t('Quantity')}
                            </Typography>

                            <Typography
                                data-testid="quantity"
                                sx={classes.bold}
                                variant="body2"
                            >
                                {quoteItem?.quantity}
                            </Typography>
                        </Box>

                        <Divider />

                        <Box
                            alignItems="center"
                            display="flex"
                            justifyContent="space-between"
                        >
                            <Typography
                                data-testid="material-section-label"
                                sx={classes.bold}
                                variant="body2"
                            >
                                {t('Material')}
                            </Typography>
                        </Box>
                        <Box
                            display="flex"
                            flexDirection="column"
                            sx={classes.dialogSection}
                        >
                            <Box
                                alignItems="center"
                                display="flex"
                                justifyContent="space-between"
                            >
                                <Typography
                                    data-testid="sheet-cost-label"
                                    variant="body2"
                                >
                                    {t(isRotary ? 'Full material record cost' : 'Full sheet cost')}
                                </Typography>
                                <Typography
                                    data-testid="sheet-cost"
                                    variant="body2"
                                >
                                    {formatCurrencySafe(quoteItem?.sheetCostUnrounded)}
                                </Typography>
                            </Box>
                            {!quoteItem?.isCustomerSuppliedMaterial ? (
                                <>
                                    <Box
                                        alignItems="center"
                                        display="flex"
                                        justifyContent="space-between"
                                    >
                                        <Typography
                                            data-testid="mark-up-percentage-label"
                                            variant="body2"
                                        >
                                            {t('Mark-up (%)')}
                                        </Typography>
                                        <Typography
                                            data-testid="mark-up-percentage"
                                            variant="body2"
                                        >
                                            {markup}%
                                        </Typography>
                                    </Box>
                                    {showPriceAdjustments ? (
                                        <Box
                                            alignItems="center"
                                            display="flex"
                                            justifyContent="space-between"
                                        >
                                            <Typography
                                                data-testid="price-adjustment-label"
                                                variant="body2"
                                            >
                                                {t('Sell price adjustment')}
                                            </Typography>
                                            <Typography
                                                data-testid="price-adjustment"
                                                variant="body2"
                                            >
                                                {formatCurrencySafe(extraSheetPrice)}
                                            </Typography>
                                        </Box>
                                    ) : null}
                                    <Box
                                        alignItems="center"
                                        display="flex"
                                        justifyContent="space-between"
                                    >
                                        <Typography
                                            data-testid="sheet-sell-price-label"
                                            variant="body2"
                                        >
                                            {t(isRotary ? 'Full material record sell price' : 'Full sheet sell price')}
                                        </Typography>
                                        <Typography
                                            data-testid="sheet-sell-price"
                                            variant="body2"
                                        >
                                            {fullSheetSellPrice}
                                        </Typography>
                                    </Box>
                                </>
                            ) : null}
                            {quoteItem?.isCustomerSuppliedMaterial ? (
                                <>
                                    <Box
                                        alignItems="center"
                                        display="flex"
                                        justifyContent="space-between"
                                    >
                                        <Typography
                                            data-testid="handling-fee-label"
                                            variant="body2"
                                        >
                                            {t('Customer-supplied handling fee (%)')}
                                        </Typography>
                                        <Typography
                                            data-testid="handling-fee"
                                            variant="body2"
                                        >
                                            {customerSuppliedHandlingFee}
                                        </Typography>
                                    </Box>
                                    <Box
                                        alignItems="center"
                                        display="flex"
                                        justifyContent="space-between"
                                    >
                                        <Typography
                                            data-testid="handling-fee-per-sheet-label"
                                            variant="body2"
                                        >
                                            {t(`Material handling fee per ${isRotary ? 'length' : 'sheet'}`)}
                                        </Typography>
                                        <Typography
                                            data-testid="handling-fee-per-sheet"
                                            variant="body2"
                                        >
                                            {handlingFeePerSheet}
                                        </Typography>
                                    </Box>
                                </>
                            ) : null}
                            <Box
                                alignItems="center"
                                display="flex"
                                justifyContent="space-between"
                            >
                                <Typography
                                    data-testid="material-consumption-mode-label"
                                    variant="body2"
                                >
                                    {t('Material consumption mode')}
                                </Typography>
                                <Typography
                                    data-testid="material-consumption-mode"
                                    variant="body2"
                                >
                                    {t(getMaterialConsumptionName(isRotary, quoteItem?.materialConsumptionMode))}
                                </Typography>
                            </Box>
                            <Box
                                alignItems="center"
                                display="flex"
                                justifyContent="space-between"
                            >
                                <Box>
                                    <Typography
                                        data-testid="parts-per-sheet-label"
                                        variant="body2"
                                    >
                                        {t(`Parts per ${isRotary ? 'length' : 'sheet'}`)}
                                    </Typography>
                                </Box>
                                <Box
                                    alignItems="center"
                                    display="flex"
                                    flexDirection="row"
                                    gap={1}
                                >
                                    {quoteItem?.nestImageUri ? (
                                        <Button
                                            aria-label="viewNest"
                                            color="primary"
                                            endIcon={<ViewQuilt />}
                                            size="small"
                                            sx={{ py: 0, px: 0.5 }}
                                            variant="text"
                                            onClick={handleClickOpenNest}
                                        >
                                            {t('NEST')}
                                        </Button>
                                    ) : null}
                                    <Typography
                                        data-testid="parts-per-sheet"
                                        variant="body2"
                                    >
                                        {quoteItem?.partsPerSheet}
                                    </Typography>
                                </Box>
                            </Box>
                            {quoteItem?.materialConsumptionMode === 'NestBounds' ? (
                                <Box
                                    alignItems="center"
                                    display="flex"
                                    justifyContent="space-between"
                                >
                                    <Typography
                                        data-testid="nest-bounds-percentage-label"
                                        variant="body2"
                                    >
                                        {t(`Nest bounds percentage of ${isRotary ? 'material length' : 'sheet'}`)}
                                    </Typography>
                                    <Typography
                                        data-testid="nest-bounds-percentage"
                                        variant="body2"
                                    >
                                        {isRotary ? rotaryProfileConsumptionPercentage : nestBoundsPercentage}%
                                    </Typography>
                                </Box>
                            ) : null}
                            <Box
                                alignItems="center"
                                display="flex"
                                justifyContent="flex-end"
                            >
                                <Grid
                                    spacing={2}
                                    container
                                >
                                    <Grid
                                        xs={8}
                                        item
                                    ></Grid>
                                    <Grid
                                        xs={2}
                                        item
                                    >
                                        <Typography
                                            align="right"
                                            data-testid="unit-price-label"
                                            sx={classes.bold}
                                            variant="body2"
                                        >
                                            {t('Unit price')}
                                        </Typography>
                                    </Grid>
                                    <Grid
                                        xs={2}
                                        item
                                    >
                                        <Typography
                                            align="right"
                                            data-testid="line-price-label"
                                            sx={classes.bold}
                                            variant="body2"
                                        >
                                            {t('Line price')}
                                        </Typography>
                                    </Grid>
                                </Grid>
                            </Box>
                            <Box
                                alignItems="center"
                                display="flex"
                                justifyContent="space-between"
                            >
                                <Grid
                                    spacing={2}
                                    container
                                >
                                    <Grid
                                        xs={8}
                                        item
                                    >
                                        <Typography
                                            data-testid="percentage-used-label"
                                            variant="body2"
                                        >
                                            {t(`Percentage of ${isRotary ? 'length' : 'sheet'} used`)}
                                        </Typography>
                                    </Grid>
                                    <Grid
                                        xs={2}
                                        item
                                    >
                                        <Typography
                                            align="right"
                                            data-testid="percentage-used-label"
                                            variant="body2"
                                        >
                                            {percentageOfSheetUsed}%
                                        </Typography>
                                    </Grid>
                                    <Grid
                                        xs={2}
                                        item
                                    >
                                        <Typography
                                            align="right"
                                            variant="body2"
                                        >
                                            -
                                        </Typography>
                                    </Grid>
                                </Grid>
                            </Box>
                            <Box
                                alignItems="center"
                                display="flex"
                                justifyContent="space-between"
                            >
                                <Grid
                                    spacing={2}
                                    container
                                >
                                    <Grid
                                        xs={8}
                                        item
                                    >
                                        <Typography
                                            data-testid="material-price-label"
                                            sx={classes.bold}
                                            variant="body2"
                                        >
                                            {t('Total material price')}
                                        </Typography>
                                    </Grid>
                                    <Grid
                                        xs={2}
                                        item
                                    >
                                        <Typography
                                            align="right"
                                            data-testid="material-price-unit"
                                            sx={classes.bold}
                                            variant="body2"
                                        >
                                            {totalMaterialPriceUnit}
                                        </Typography>
                                    </Grid>
                                    <Grid
                                        xs={2}
                                        item
                                    >
                                        <Typography
                                            align="right"
                                            data-testid="material-price-line"
                                            sx={classes.bold}
                                            variant="body2"
                                        >
                                            {totalMaterialPriceLine}
                                        </Typography>
                                    </Grid>
                                </Grid>
                            </Box>
                        </Box>

                        <Divider />
                        <Box
                            alignItems="center"
                            display="flex"
                            justifyContent="space-between"
                        >
                            <Typography
                                data-testid="cutting-section-label"
                                sx={classes.bold}
                                variant="body2"
                            >
                                {t('Cutting')}
                            </Typography>
                        </Box>
                        <Box
                            display="flex"
                            flexDirection="column"
                            sx={classes.dialogSection}
                        >
                            <Box
                                alignItems="center"
                                display="flex"
                                justifyContent="space-between"
                            >
                                <Typography
                                    data-testid="cutting-rate-label"
                                    variant="body2"
                                >{`${t('Cutting rate')} (${currencySymbol}/${t('hour')})`}</Typography>
                                <Typography
                                    data-testid="cutting-rate"
                                    variant="body2"
                                >
                                    {cuttingRate}
                                </Typography>
                            </Box>
                            <Box
                                alignItems="center"
                                display="flex"
                                justifyContent="space-between"
                            >
                                <Typography
                                    data-testid="cutting-feed-rate-label"
                                    variant="body2"
                                >
                                    {t(`Cutting feed rate (${units}/min)`)}
                                </Typography>
                                <Typography
                                    data-testid="cutting-feed-rate"
                                    variant="body2"
                                >
                                    {cuttingFeedRate}
                                </Typography>
                            </Box>
                            <Box
                                alignItems="center"
                                display="flex"
                                justifyContent="flex-end"
                            >
                                <Grid
                                    spacing={2}
                                    container
                                >
                                    <Grid
                                        xs={4}
                                        item
                                    ></Grid>
                                    <Grid
                                        xs={2}
                                        item
                                    >
                                        <Typography
                                            align="right"
                                            sx={classes.bold}
                                            variant="body2"
                                        >{`${t('Rate')} (${currencySymbol}/${t('sec')})`}</Typography>
                                    </Grid>
                                    <Grid
                                        xs={2}
                                        item
                                    >
                                        <Typography
                                            align="right"
                                            sx={classes.bold}
                                            variant="body2"
                                        >
                                            {t('Unit time (s)')}
                                        </Typography>
                                    </Grid>
                                    <Grid
                                        xs={2}
                                        item
                                    >
                                        <Typography
                                            align="right"
                                            sx={classes.bold}
                                            variant="body2"
                                        >
                                            {t('Unit price')}
                                        </Typography>
                                    </Grid>
                                    <Grid
                                        xs={2}
                                        item
                                    >
                                        <Typography
                                            align="right"
                                            sx={classes.bold}
                                            variant="body2"
                                        >
                                            {t('Line price')}
                                        </Typography>
                                    </Grid>
                                </Grid>
                            </Box>
                            <Box
                                alignItems="center"
                                display="flex"
                                justifyContent="flex-end"
                            >
                                <Grid
                                    spacing={2}
                                    container
                                >
                                    <Grid
                                        xs={4}
                                        item
                                    >
                                        <Typography
                                            data-testid="setup-label"
                                            variant="body2"
                                        >
                                            {t('Setup')}
                                        </Typography>
                                    </Grid>
                                    <Grid
                                        xs={2}
                                        item
                                    >
                                        <Typography
                                            align="right"
                                            data-testid="setup-rate"
                                            variant="body2"
                                        >
                                            {cuttingSetupRate}
                                        </Typography>
                                    </Grid>
                                    <Grid
                                        xs={2}
                                        item
                                    >
                                        <Typography
                                            align="right"
                                            data-testid="setup-time"
                                            variant="body2"
                                        >
                                            {setupTimeItem}
                                        </Typography>
                                    </Grid>
                                    <Grid
                                        xs={2}
                                        item
                                    >
                                        <Typography
                                            align="right"
                                            data-testid="setup-item-price"
                                            variant="body2"
                                        >
                                            {setupPriceItem}
                                        </Typography>
                                    </Grid>
                                    <Grid
                                        xs={2}
                                        item
                                    >
                                        <Typography
                                            align="right"
                                            data-testid="setup-line-price"
                                            variant="body2"
                                        >
                                            {setupPriceLine}
                                        </Typography>
                                    </Grid>
                                </Grid>
                            </Box>
                            <Box
                                alignItems="center"
                                display="flex"
                                justifyContent="flex-end"
                            >
                                <Grid
                                    spacing={2}
                                    container
                                >
                                    <Grid
                                        xs={4}
                                        item
                                    >
                                        <Typography
                                            data-testid="sheet-change-label"
                                            variant="body2"
                                        >
                                            {isRotary ? t('Loading') : t('Sheet change')}
                                        </Typography>
                                    </Grid>
                                    <Grid
                                        xs={4}
                                        item
                                    >
                                        <Typography
                                            align="right"
                                            data-testid="sheet-change-time"
                                            variant="body2"
                                        >
                                            {sheetChangeTimeItem}
                                        </Typography>
                                    </Grid>
                                    <Grid
                                        xs={2}
                                        item
                                    >
                                        <Typography
                                            align="right"
                                            data-testid="sheet-change-item-price"
                                            variant="body2"
                                        >
                                            {sheetChangePriceItem}
                                        </Typography>
                                    </Grid>
                                    <Grid
                                        xs={2}
                                        item
                                    >
                                        <Typography
                                            align="right"
                                            data-testid="sheet-change-line-price"
                                            variant="body2"
                                        >
                                            {sheetChangePriceLine}
                                        </Typography>
                                    </Grid>
                                </Grid>
                            </Box>
                            <Box
                                alignItems="center"
                                display="flex"
                                justifyContent="flex-end"
                            >
                                <Grid
                                    spacing={2}
                                    container
                                >
                                    <Grid
                                        xs={4}
                                        item
                                    >
                                        <Typography
                                            data-testid="cutting-label"
                                            variant="body2"
                                        >
                                            {t('Cutting')}
                                        </Typography>
                                    </Grid>
                                    <Grid
                                        xs={4}
                                        item
                                    >
                                        <Typography
                                            align="right"
                                            data-testid="cutting-time"
                                            variant="body2"
                                        >
                                            {cuttingTimeItem}
                                        </Typography>
                                    </Grid>
                                    <Grid
                                        xs={2}
                                        item
                                    >
                                        <Typography
                                            align="right"
                                            data-testid="cutting-item-price"
                                            variant="body2"
                                        >
                                            {cuttingPriceItem}
                                        </Typography>
                                    </Grid>
                                    <Grid
                                        xs={2}
                                        item
                                    >
                                        <Typography
                                            align="right"
                                            data-testid="cutting-line-price"
                                            variant="body2"
                                        >
                                            {cuttingPriceLine}
                                        </Typography>
                                    </Grid>
                                </Grid>
                            </Box>
                            <Box
                                alignItems="center"
                                display="flex"
                                justifyContent="space-between"
                            >
                                <Grid
                                    spacing={2}
                                    container
                                >
                                    <Grid
                                        xs={8}
                                        item
                                    >
                                        <Typography
                                            data-testid="total-cutting-price-label"
                                            sx={classes.bold}
                                            variant="body2"
                                        >
                                            {t('Total cutting price')}
                                        </Typography>
                                    </Grid>
                                    <Grid
                                        xs={2}
                                        item
                                    >
                                        <Typography
                                            align="right"
                                            data-testid="total-cutting-item-price"
                                            sx={classes.bold}
                                            variant="body2"
                                        >
                                            {totalCuttingPriceItem}
                                        </Typography>
                                    </Grid>
                                    <Grid
                                        xs={2}
                                        item
                                    >
                                        <Typography
                                            align="right"
                                            data-testid="total-cutting-line-price"
                                            sx={classes.bold}
                                            variant="body2"
                                        >
                                            {totalCuttingPriceLine}
                                        </Typography>
                                    </Grid>
                                </Grid>
                            </Box>
                        </Box>

                        <Divider />

                        {showFoldingSection ? (
                            <>
                                <Box
                                    alignItems="center"
                                    display="flex"
                                    justifyContent="space-between"
                                >
                                    <Typography
                                        data-testid="folding-section-label"
                                        sx={classes.bold}
                                        variant="body2"
                                    >
                                        {t('Folding')}
                                    </Typography>
                                </Box>
                                <Box
                                    display="flex"
                                    flexDirection="column"
                                    sx={classes.dialogSection}
                                >
                                    <Box
                                        alignItems="center"
                                        display="flex"
                                        justifyContent="flex-end"
                                    >
                                        <Grid
                                            spacing={2}
                                            container
                                        >
                                            <Grid
                                                xs={4}
                                                item
                                            ></Grid>
                                            <Grid
                                                xs={2}
                                                item
                                            >
                                                <Typography
                                                    align="right"
                                                    sx={classes.bold}
                                                    variant="body2"
                                                >
                                                    {t(`${t('Rate')} (${currencySymbol}/${t('sec')})`)}
                                                </Typography>
                                            </Grid>
                                            <Grid
                                                xs={2}
                                                item
                                            >
                                                <Typography
                                                    align="right"
                                                    sx={classes.bold}
                                                    variant="body2"
                                                >
                                                    {t('Unit time (s)')}
                                                </Typography>
                                            </Grid>
                                            <Grid
                                                xs={2}
                                                item
                                            >
                                                <Typography
                                                    align="right"
                                                    sx={classes.bold}
                                                    variant="body2"
                                                >
                                                    {t('Unit price')}
                                                </Typography>
                                            </Grid>
                                            <Grid
                                                xs={2}
                                                item
                                            >
                                                <Typography
                                                    align="right"
                                                    sx={classes.bold}
                                                    variant="body2"
                                                >
                                                    {t('Line price')}
                                                </Typography>
                                            </Grid>
                                        </Grid>
                                    </Box>
                                    <Box
                                        alignItems="center"
                                        display="flex"
                                        justifyContent="flex-end"
                                    >
                                        <Grid
                                            spacing={2}
                                            container
                                        >
                                            <Grid
                                                xs={4}
                                                item
                                            >
                                                <Typography variant="body2">{t('Setup')}</Typography>
                                            </Grid>
                                            <Grid
                                                xs={2}
                                                item
                                            >
                                                <Typography
                                                    align="right"
                                                    data-testid="folding-setup-rate"
                                                    variant="body2"
                                                >
                                                    {foldingSetupRateItem}
                                                </Typography>
                                            </Grid>
                                            <Grid
                                                xs={2}
                                                item
                                            >
                                                <Typography
                                                    align="right"
                                                    data-testid="folding-setup-time"
                                                    variant="body2"
                                                >
                                                    {foldingSetupTimeItem}
                                                </Typography>
                                            </Grid>
                                            <Grid
                                                xs={2}
                                                item
                                            >
                                                <Typography
                                                    align="right"
                                                    data-testid="folding-setup-item-price"
                                                    variant="body2"
                                                >
                                                    {foldingSetupPriceItem}
                                                </Typography>
                                            </Grid>
                                            <Grid
                                                xs={2}
                                                item
                                            >
                                                <Typography
                                                    align="right"
                                                    data-testid="folding-setup-line-price"
                                                    variant="body2"
                                                >
                                                    {foldingSetupPriceLine}
                                                </Typography>
                                            </Grid>
                                        </Grid>
                                    </Box>
                                    <Box
                                        alignItems="center"
                                        display="flex"
                                        justifyContent="flex-end"
                                    >
                                        <Grid
                                            spacing={2}
                                            container
                                        >
                                            <Grid
                                                xs={4}
                                                item
                                            >
                                                <Typography
                                                    data-testid="folding-label"
                                                    variant="body2"
                                                >
                                                    {t('Folding')}
                                                </Typography>
                                            </Grid>
                                            <Grid
                                                xs={2}
                                                item
                                            >
                                                <Typography
                                                    align="right"
                                                    data-testid="folding-processing-rate"
                                                    variant="body2"
                                                >
                                                    {foldingProcessingRateItem}
                                                </Typography>
                                            </Grid>
                                            <Grid
                                                xs={2}
                                                item
                                            >
                                                <Typography
                                                    align="right"
                                                    data-testid="folding-processing-time"
                                                    variant="body2"
                                                >
                                                    {foldingProcessingTimeItem}
                                                </Typography>
                                            </Grid>
                                            <Grid
                                                xs={2}
                                                item
                                            >
                                                <Typography
                                                    align="right"
                                                    data-testid="folding-item-processing-price"
                                                    variant="body2"
                                                >
                                                    {foldingProcessingPriceItem}
                                                </Typography>
                                            </Grid>
                                            <Grid
                                                xs={2}
                                                item
                                            >
                                                <Typography
                                                    align="right"
                                                    data-testid="folding-line-processing-price"
                                                    variant="body2"
                                                >
                                                    {foldingProcessingPriceLine}
                                                </Typography>
                                            </Grid>
                                        </Grid>
                                    </Box>
                                    <Box
                                        alignItems="center"
                                        display="flex"
                                        justifyContent="space-between"
                                    >
                                        <Grid
                                            spacing={2}
                                            container
                                        >
                                            <Grid
                                                xs={8}
                                                item
                                            >
                                                <Typography
                                                    data-testid="total-folding-price-label"
                                                    sx={classes.bold}
                                                    variant="body2"
                                                >
                                                    {t('Total folding price')}
                                                </Typography>
                                            </Grid>
                                            <Grid
                                                xs={2}
                                                item
                                            >
                                                <Typography
                                                    align="right"
                                                    data-testid="total-folding-item-price"
                                                    sx={classes.bold}
                                                    variant="body2"
                                                >
                                                    {foldingTotalPriceItem}
                                                </Typography>
                                            </Grid>
                                            <Grid
                                                xs={2}
                                                item
                                            >
                                                <Typography
                                                    align="right"
                                                    data-testid="total-folding-line-price"
                                                    sx={classes.bold}
                                                    variant="body2"
                                                >
                                                    {foldingTotalPriceLine}
                                                </Typography>
                                            </Grid>
                                        </Grid>
                                    </Box>
                                </Box>

                                <Divider />
                            </>
                        ) : null}

                        <Box
                            display="flex"
                            flexDirection="column"
                            sx={classes.dialogSection}
                        >
                            <Box
                                alignItems="center"
                                display="flex"
                                justifyContent="flex-end"
                            >
                                <Grid
                                    spacing={2}
                                    container
                                >
                                    <Grid
                                        xs={8}
                                        item
                                    ></Grid>
                                    <Grid
                                        xs={2}
                                        item
                                    >
                                        <Typography
                                            align="right"
                                            sx={classes.bold}
                                            variant="body2"
                                        >
                                            {t('Unit price')}
                                        </Typography>
                                    </Grid>
                                    <Grid
                                        xs={2}
                                        item
                                    >
                                        <Typography
                                            align="right"
                                            sx={classes.bold}
                                            variant="body2"
                                        >
                                            {t('Line price')}
                                        </Typography>
                                    </Grid>
                                </Grid>
                            </Box>
                            {showSecProcessesSection ? (
                                <Box
                                    alignItems="center"
                                    display="flex"
                                    justifyContent="space-between"
                                >
                                    <Grid
                                        spacing={2}
                                        container
                                    >
                                        <Grid
                                            xs={8}
                                            item
                                        >
                                            <Typography
                                                data-testid="other-secproc-label"
                                                variant="body2"
                                            >
                                                {t('Other secondary processes')}
                                            </Typography>
                                        </Grid>
                                        <Grid
                                            xs={2}
                                            item
                                        >
                                            <Typography
                                                align="right"
                                                data-testid="other-secproc-item-price"
                                                variant="body2"
                                            >
                                                {secProcessesPriceItem}
                                            </Typography>
                                        </Grid>
                                        <Grid
                                            xs={2}
                                            item
                                        >
                                            <Typography
                                                align="right"
                                                data-testid="other-secproc-line-price"
                                                variant="body2"
                                            >
                                                {secProcessesPriceLine}
                                            </Typography>
                                        </Grid>
                                    </Grid>
                                </Box>
                            ) : null}
                            {showPriceAdjustments ? (
                                <Box
                                    alignItems="center"
                                    display="flex"
                                    justifyContent="space-between"
                                >
                                    <Grid
                                        spacing={2}
                                        container
                                    >
                                        <Grid
                                            xs={8}
                                            item
                                        >
                                            <Typography
                                                data-testid="price-adjustment-label"
                                                variant="body2"
                                            >
                                                {t('Price adjustment')}
                                            </Typography>
                                        </Grid>
                                        <Grid
                                            xs={2}
                                            item
                                        >
                                            <Typography
                                                align="right"
                                                data-testid="price-adjustment-item"
                                                variant="body2"
                                            >
                                                {formatCurrencySafe(priceAdjustmentUnit)}
                                            </Typography>
                                        </Grid>
                                        <Grid
                                            xs={2}
                                            item
                                        >
                                            <Typography
                                                align="right"
                                                data-testid="price-adjustment-line"
                                                variant="body2"
                                            >
                                                {priceAdjustmentLine}
                                            </Typography>
                                        </Grid>
                                    </Grid>
                                </Box>
                            ) : null}
                            <Box
                                alignItems="center"
                                display="flex"
                                justifyContent="space-between"
                            >
                                <Grid
                                    spacing={2}
                                    container
                                >
                                    <Grid
                                        xs={8}
                                        item
                                    >
                                        <Typography
                                            data-testid="subtotal-label"
                                            sx={classes.bold}
                                            variant="body2"
                                        >
                                            {t('Subtotal')}
                                        </Typography>
                                    </Grid>
                                    <Grid
                                        xs={2}
                                        item
                                    >
                                        <Typography
                                            align="right"
                                            data-testid="subtotal-item"
                                            sx={classes.bold}
                                            variant="body2"
                                        >
                                            {subtotalUnitPrice}
                                        </Typography>
                                    </Grid>
                                    <Grid
                                        xs={2}
                                        item
                                    >
                                        <Typography
                                            align="right"
                                            data-testid="subtotal-line"
                                            sx={classes.bold}
                                            variant="body2"
                                        >
                                            {subtotalLinePrice}
                                        </Typography>
                                    </Grid>
                                </Grid>
                            </Box>
                            <Box
                                alignItems="center"
                                display="flex"
                                justifyContent="space-between"
                            >
                                <Grid
                                    spacing={2}
                                    container
                                >
                                    <Grid
                                        xs={8}
                                        item
                                    >
                                        <Typography
                                            data-testid="taxes-label"
                                            variant="body2"
                                        >
                                            {t(`Taxes (${fractionToPercentage(taxRate)}%)`)}
                                        </Typography>
                                    </Grid>
                                    <Grid
                                        xs={2}
                                        item
                                    >
                                        <Typography
                                            align="right"
                                            data-testid="taxes-item"
                                            variant="body2"
                                        >
                                            {taxUnitPrice}
                                        </Typography>
                                    </Grid>
                                    <Grid
                                        xs={2}
                                        item
                                    >
                                        <Typography
                                            align="right"
                                            data-testid="taxes-line"
                                            variant="body2"
                                        >
                                            {taxLinePrice}
                                        </Typography>
                                    </Grid>
                                </Grid>
                            </Box>
                        </Box>

                        <Divider />
                        <Box
                            display="flex"
                            flexDirection="column"
                            sx={classes.dialogSection}
                        >
                            <Box
                                alignItems="center"
                                display="flex"
                                justifyContent="space-between"
                                mb={2}
                            >
                                <Grid
                                    spacing={2}
                                    container
                                >
                                    <Grid
                                        xs={8}
                                        item
                                    >
                                        <Typography
                                            data-testid="total-label"
                                            sx={classes.summaryRow}
                                            variant="body2"
                                        >
                                            {t('Total')}
                                        </Typography>
                                    </Grid>
                                    <Grid
                                        xs={2}
                                        item
                                    >
                                        <Typography
                                            align="right"
                                            data-testid="total-item"
                                            sx={classes.summaryRow}
                                            variant="body2"
                                        >
                                            {totalUnitPrice}
                                        </Typography>
                                    </Grid>
                                    <Grid
                                        xs={2}
                                        item
                                    >
                                        <Typography
                                            align="right"
                                            data-testid="total-line"
                                            sx={classes.summaryRow}
                                            variant="body2"
                                        >
                                            {totalLinePrice}
                                        </Typography>
                                    </Grid>
                                </Grid>
                            </Box>
                        </Box>
                    </Box>
                </DialogContent>
            </Dialog>
            <QuoteCalculatorNestDialog
                handleCloseNest={handleCloseNest}
                nestImageUri={quoteItem?.nestImageUri}
                openNest={openNest}
                organisationNestingEnabled={organisationHasNestingEnabled}
                title={quoteItem?.name}
            />
        </>
    )
}

PriceDetailsDialog.propTypes = {
    open: PropTypes.bool,
    quoteItem: PropTypes.object,
    quoteOverrides: PropTypes.object,
    taxRate: PropTypes.number,
    onClose: PropTypes.func,
}

export default PriceDetailsDialog
