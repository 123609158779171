import { useTranslation } from 'react-i18next'
import { useSelector } from 'react-redux'
import { useNavigate } from 'react-router-dom'
import { Box, Button, Grid, Typography } from '@mui/material'

import { selectOrganisation } from '@/app/slices/organisationSlice'

import PublicAppBar from '../../MainAppBar/PublicAppBar'

const classes = {
    root: {
        backgroundColor: 'background.paper',
        borderRadius: 1,
        border: (theme) => `1px ${theme.palette.grey[300]} solid`,
        width: '100%',
        height: 'calc(100vh - 64px)',
        gap: 3,
    },
    logoImgContainer: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        flexDirection: 'column',
        width: '100%',
        marginBottom: 2,
    },
    logoImg: {
        height: 80,
    },
    notFoundImage: {
        width: '542px',
    },
    notFoundMessage: {
        gap: 1,
    },
}

const NotFound = () => {
    const { t } = useTranslation()

    const navigate = useNavigate()
    const organisation = useSelector(selectOrganisation)

    return (
        <>
            <PublicAppBar
                organisationLogo={organisation?.logoUri}
                organisationName={organisation?.name}
            />
            <Grid
                component="section"
                xs={12}
                container
                item
            >
                <Box
                    alignItems="center"
                    display="flex"
                    flexDirection="column"
                    justifyContent="center"
                    p={12}
                    sx={classes.root}
                >
                    <Box
                        alt="Not Found"
                        component="img"
                        src="404.svg"
                        sx={classes.notFoundImage}
                    />

                    <Box
                        alignItems="center"
                        display="flex"
                        flexDirection="column"
                        justifyContent="center"
                        sx={classes.notFoundMessage}
                    >
                        <Typography variant="h4">{t('Page not found')}</Typography>
                        <Typography variant="body1">{t("We can't find the page you're looking for.")}</Typography>
                    </Box>

                    <Button
                        color="primary"
                        size="large"
                        variant="contained"
                        onClick={() => navigate('/')}
                    >
                        Back to Home
                    </Button>
                </Box>
            </Grid>
        </>
    )
}

export default NotFound
