import { useTranslation } from 'react-i18next'
import { Archive, Delete, EditRounded, Unarchive } from '@mui/icons-material'
import { Box, IconButton, TableCell, TableRow } from '@mui/material'
import PropTypes from 'prop-types'

import { TbxTooltip } from '@/common/components'
import { useNumberFormatter } from '@/common/hooks'

const classes = {
    tableRow: {
        '&:hover': {
            background: (theme) => theme.palette.action.hover,
        },
    },
    tableCell: {
        paddingBlock: 2,
        paddingInline: 2,
    },
    tableCellWithEllipsis: {
        overflow: 'hidden',
        whiteSpace: 'nowrap',
        textOverflow: 'ellipsis',
    },

    iconColor: (active = true) => ({
        color: active ? 'secondary.main' : 'text.disabled',
    }),
    activeContainer: (active = true) => ({
        color: active ? 'text.primary' : 'text.disabled',
    }),

    zoneTitle: {
        fontWeight: 500,
        color: 'text.primary',
        textTransform: 'uppercase',
    },
    zoneBorderLeft: {
        borderLeft: '2px dashed #0078A4;',
    },
    zoneBorderRight: {
        borderRight: '2px dashed #0078A4;',
    },
}

const DeliveryContainerRow = ({
    container,
    handleArchiveButtonClick,
    handleEditButtonClick,
    handleUnarchiveButtonClick,
}) => {
    const { t } = useTranslation()

    const { n } = useNumberFormatter()

    return (
        <TableRow
            key={container.containerId}
            sx={classes.tableRow}
        >
            <TableCell
                sx={[classes.tableCell, classes.tableCellWithEllipsis, classes.activeContainer(!container.isDeleted)]}
            >
                {container?.name}
            </TableCell>

            <TableCell
                align="right"
                sx={[classes.tableCell, classes.activeContainer(!container.isDeleted)]}
            >
                {container?.maximumWeight ? n(container?.maximumWeight) : 'No max'}
            </TableCell>

            <TableCell
                align="right"
                sx={[classes.tableCell, classes.activeContainer(!container.isDeleted)]}
            >
                {container?.maximumLength ? n(container?.maximumLength) : 'No max'}
            </TableCell>

            <TableCell
                align="right"
                sx={[classes.tableCell, classes.activeContainer(!container.isDeleted)]}
            >
                {container?.maximumWidth ? n(container?.maximumWidth) : 'No max'}
            </TableCell>

            <TableCell
                align="right"
                sx={[classes.tableCell, classes.activeContainer(!container.isDeleted)]}
            >
                {container?.maximumHeight ? n(container?.maximumHeight) : 'No max'}
            </TableCell>

            <TableCell
                align="right"
                sx={classes.tableCell}
            >
                <Box
                    display="flex"
                    justifyContent="flex-end"
                >
                    <IconButton
                        disabled={container.isDeleted}
                        size="small"
                        onClick={() => {
                            handleEditButtonClick(container)
                        }}
                    >
                        <EditRounded
                            fontSize="small"
                            sx={classes.iconColor(!container.isDeleted)}
                        />
                    </IconButton>

                    <TbxTooltip
                        title={
                            !container.isDeleted ? t('Archive delivery container') : t('Unarchive delivery container')
                        }
                        arrow
                    >
                        {!container.isDeleted ? (
                            <span>
                                <IconButton
                                    aria-label="archive"
                                    data-testid="archive-button"
                                    size="small"
                                    sx={classes.iconColor()}
                                    onClick={() => {
                                        handleArchiveButtonClick(container)
                                    }}
                                >
                                    <Archive fontSize="small" />
                                </IconButton>
                            </span>
                        ) : (
                            <span>
                                <IconButton
                                    aria-label="unarchive"
                                    data-testid="unarchive-button"
                                    size="small"
                                    sx={classes.iconColor()}
                                    onClick={() => {
                                        handleUnarchiveButtonClick(container)
                                    }}
                                >
                                    <Unarchive fontSize="small" />
                                </IconButton>
                            </span>
                        )}
                    </TbxTooltip>
                </Box>
            </TableCell>
        </TableRow>
    )
}

DeliveryContainerRow.propTypes = {
    container: PropTypes.object.isRequired,
    handleArchiveButtonClick: PropTypes.func.isRequired,
    handleEditButtonClick: PropTypes.func.isRequired,
    handleUnarchiveButtonClick: PropTypes.func.isRequired,
}

export default DeliveryContainerRow
