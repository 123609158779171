import NetworkManager from '../managers/NetworkManager/NetworkManager'

class PromotionalCodeService {
    getPromotionalCodes = async (cancelTokenSource) => {
        const config = {
            ...(cancelTokenSource && { cancelToken: cancelTokenSource.token }),
        }
        const result = await NetworkManager.get('/promotionalCode', config)
        return result?.data
    }

    validatePromotionalCode = async (code, cancelTokenSource) => {
        const config = {
            ...(cancelTokenSource && { cancelToken: cancelTokenSource.token }),
        }

        const result = await NetworkManager.get(`/promotionalCode/validate/${code}`, config)
        return result?.data
    }

    redeemPromotionalCode = async (code, cancelTokenSource) => {
        const config = {
            ...(cancelTokenSource && { cancelToken: cancelTokenSource.token }),
        }

        const result = await NetworkManager.post(`/promotionalCode/redeem/${code}`, null, config)
        return result?.data
    }

    importPromotionalCode = async (file, cancelTokenSource) => {
        const config = {
            ...(cancelTokenSource && { cancelToken: cancelTokenSource.token }),
        }
        const formData = new FormData()
        formData.append('file', file, file.name)
        const result = await NetworkManager.post('/promotionalCode/import', formData, config)
        return result?.data
    }

    exportPromotionalCodes = async (cancelTokenSource) => {
        const config = {
            ...(cancelTokenSource && { cancelToken: cancelTokenSource.token }),
        }
        const result = await NetworkManager.download('/promotionalCode/export', 'get', config)
        return result?.data
    }
}

export default new PromotionalCodeService()
