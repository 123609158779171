import { useSelector } from 'react-redux'

import { selectAppBarState } from '@/app/slices/appSlice'
import ActivateSubscriptionBar from '@/layout/ActivateSubscriptionBar/ActivateSubscriptionBar'
import FreePlanBar from '@/layout/FreePlanBar/FreePlanBar'
import TrialWithSubscriptionBar from '@/layout/TrialWithSubscriptionBar/TrialWithSubscriptionBar'

const TbxToolbarMessages = () => {
    const appBarState = useSelector(selectAppBarState)

    if (appBarState?.freePlan && !import.meta.env.VITE_RESELLER) {
        return <FreePlanBar />
    }

    if (appBarState?.showSubscriptionBar && !import.meta.env.VITE_RESELLER) {
        return <ActivateSubscriptionBar />
    }

    if (appBarState?.showTrialWithSubscriptionBar && !import.meta.env.VITE_RESELLER) {
        return <TrialWithSubscriptionBar />
    }

    return null
}

export default TbxToolbarMessages
