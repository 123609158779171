// based on https://docs.microsoft.com/en-us/azure/azure-monitor/app/javascript-react-plugin
import { ReactPlugin } from '@microsoft/applicationinsights-react-js'
import { ApplicationInsights } from '@microsoft/applicationinsights-web'

import { browserHistory } from '../BrowserHistory/BrowserHistory'

const reactPlugin = new ReactPlugin()

let appInsights

if (import.meta.env.VITE_APPINSIGHTS_CONNECTIONSTRING) {
    appInsights = new ApplicationInsights({
        config: {
            namePrefix: import.meta.env.VITE_NAME,
            connectionString: import.meta.env.VITE_APPINSIGHTS_CONNECTIONSTRING,
            extensions: [reactPlugin],
            extensionConfig: {
                [reactPlugin.identifier]: { history: browserHistory },
            },
        },
    })
    appInsights.loadAppInsights()
}

export { appInsights, reactPlugin }
