/**
 * A compareFunction for use in a @type {Path[]}'s @see Array.prototype.sort() operation.
 * Sorts paths by descending depth, then by descending area.
 * This makes outermost, largest paths render first so they don't obscure the smaller inner paths.
 *
 * @param {Path} a The first Path for comparison
 * @param {Path} b The second Path for comparison
 * @returns If pathSort(a, b) returns a value > than 0, sort b before a. If pathSort(a, b) returns a value <= 0, leave a and b in the same order
 */
export function pathSort(a, b) {
    return (a?.depth ?? 0) - (b?.depth ?? 0) || (b?.width ?? 0) * (b?.height ?? 0) - (a?.width ?? 0) * (a?.height ?? 0)
}
