import { useTranslation } from 'react-i18next'
import { Add, Remove } from '@mui/icons-material'
import { Box, Button, IconButton } from '@mui/material'
import PropTypes from 'prop-types'

const classes = {
    root: {
        height: 'auto',
        display: 'block',
        position: 'absolute',
        right: 24,
        bottom: 24,
        maxWidth: 320,
        zIndex: 1000,
        padding: 2,
    },
    controlsContainer: {
        display: 'flex',
        flexDirection: 'column',
        gap: 1,
        alignItems: 'center',
    },
    iconButton: {
        padding: '4px',
        minWidth: 0,
        width: '28px',
        height: '28px',
        fontSize: '13px',
    },
    resetButton: {
        height: '28px',
        fontSize: '13px',
    },
}

const ZoomControls = ({ onZoomIn, onZoomOut, onZoomReset }) => {
    const { t } = useTranslation()

    return (
        <Box sx={classes.root}>
            <Box sx={classes.controlsContainer}>
                <Button
                    color="primary"
                    size="small"
                    sx={classes.iconButton}
                    variant="contained"
                    disableRipple
                    onClick={onZoomIn}
                >
                    <Add />
                </Button>
                <Button
                    color="primary"
                    sx={classes.resetButton}
                    variant="contained"
                    disableRipple
                    onClick={onZoomReset}
                >
                    {t('Reset')}
                </Button>
                <Button
                    color="primary"
                    size="small"
                    sx={classes.iconButton}
                    variant="contained"
                    disableRipple
                    onClick={onZoomOut}
                >
                    <Remove />
                </Button>
            </Box>
        </Box>
    )
}

ZoomControls.propTypes = {
    onZoomIn: PropTypes.func,
    onZoomOut: PropTypes.func,
    onZoomReset: PropTypes.func,
}

export default ZoomControls
