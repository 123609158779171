import { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { FormControl, InputLabel, MenuItem, Select } from '@mui/material'
import allCountries from 'country-region-data'
import PropTypes from 'prop-types'

const CountrySelectInput = ({ disabled = false, onChange, required = false, value }) => {
    const { t } = useTranslation()
    const [selectedValue, setSelectedValue] = useState('')

    useEffect(() => {
        setSelectedValue(value)
    }, [value])

    const handleChange = (event) => {
        const newCountryCode = event.target.value
        const newCountryIndex = allCountries?.findIndex((country) => country.countryShortCode === newCountryCode)
        const newSelectedCountry = allCountries[newCountryIndex].countryShortCode

        setSelectedValue(newSelectedCountry)

        if (typeof onChange === 'function') {
            onChange(newSelectedCountry)
        }
    }

    return (
        <FormControl
            disabled={disabled}
            fullWidth
        >
            <InputLabel
                data-testid="organisation-cad-file-version-label"
                id="cad-file-version-select-label"
                required={required}
            >
                {t('Select country')}
            </InputLabel>
            <Select
                id="cad-file-select"
                inputProps={{
                    'data-testid': 'organisation-cad-file-version',
                }}
                label={t('Select country')}
                labelId="cad-file-version-select-label"
                required={required}
                value={selectedValue}
                onChange={handleChange}
            >
                {allCountries.map((country) => {
                    return (
                        <MenuItem
                            key={country.countryShortCode}
                            value={country.countryShortCode}
                        >
                            {country.countryName}
                        </MenuItem>
                    )
                })}
            </Select>
        </FormControl>
    )
}

CountrySelectInput.propTypes = {
    disabled: PropTypes.bool,
    required: PropTypes.bool,
    value: PropTypes.string,
    onChange: PropTypes.func,
}

export default CountrySelectInput
