import axios from 'axios'

import { msalInstance, tokenRequest } from '@/authConfig'
import { appInsights, browserFileDownload } from '@/common/utils'

import NetworkError, { NetworkErrorType } from './NetworkError/NetworkError'

export class NetworkManager {
    constructor() {
        this.instance = axios.create({
            baseURL: import.meta.env.VITE_API_SERVER_URL,
            timeout: 30000,
        })

        this.instance.interceptors.response.use(
            (response) => {
                return response
            },
            (error) => {
                if (
                    error.request &&
                    error.request.responseType === 'blob' &&
                    error.response &&
                    error.response.data instanceof Blob &&
                    error.response.data.type &&
                    error.response.data.type.toLowerCase().indexOf('text') !== -1
                ) {
                    return new Promise((resolve, reject) => {
                        const reader = new FileReader()
                        reader.onload = () => {
                            error.response.data = reader.result
                            resolve(Promise.reject(error))
                        }

                        reader.onerror = () => {
                            reject(error)
                        }

                        reader.readAsText(error.response.data)
                    })
                }

                return Promise.reject(error)
            }
        )
    }

    getCancelTokenSource = () => {
        return axios.CancelToken.source()
    }

    getRequestHeaders = () => {
        return new Promise((resolve, reject) => {
            return msalInstance
                .acquireTokenSilent(tokenRequest)
                .then((token) => {
                    resolve({
                        headers: {
                            'Content-Type': 'application/json',
                            Authorization: `Bearer ${token.accessToken}`,
                        },
                    })
                })
                .catch(reject)
        })
    }

    get(endpoint, config) {
        return new Promise((resolve, reject) => {
            this.getRequestHeaders()
                .then((headerConfig) => {
                    this.instance
                        .get(endpoint, { ...headerConfig, ...config })
                        .then((response) => {
                            resolve(response)
                        })
                        .catch((error) => {
                            reject(this.handleNetworkingError(error))
                        })
                })
                .catch((error) => {
                    reject(this.handleNetworkingError(error))
                })
        })
    }

    post(endpoint, data, config) {
        return new Promise((resolve, reject) => {
            this.getRequestHeaders()
                .then((options) => {
                    this.instance
                        .post(endpoint, data, { ...options, ...config, timeout: 0 })
                        .then((response) => {
                            resolve(response)
                        })
                        .catch((error) => {
                            reject(this.handleNetworkingError(error))
                        })
                })
                .catch((error) => {
                    reject(this.handleNetworkingError(error))
                })
        })
    }

    async postWithoutAuthentication(endpoint, data, config) {
        try {
            return await this.instance.post(endpoint, data, {
                ...config,
                timeout: 0,
            })
        } catch (error) {
            throw this.handleNetworkingError(error)
        }
    }

    download(endpoint, method, config) {
        const onSuccess = (response) => {
            const disposition = response.headers['content-disposition']
            let filename = ''
            const filetype = response.headers['content-type']
            if (disposition && disposition.indexOf('attachment') !== -1) {
                const filenameRegex = /filename[^;=\n]*=((['"]).*?\2|[^;\n]*)/
                const matches = filenameRegex.exec(disposition)
                if (matches != null && matches[1]) {
                    filename = matches[1].replace(/['"]/g, '')
                }
            }

            browserFileDownload(response.data, filename, filetype)
        }

        const configuration = {
            responseType: 'blob',
            timeout: 0,
            ...config,
        }
        if (method.toLowerCase() === 'post') {
            return new Promise((resolve, reject) => {
                this.post(endpoint, null, configuration)
                    .then((response) => {
                        onSuccess(response)
                        resolve(response)
                    })
                    .catch((error) => {
                        reject(error)
                    })
            })
        } else {
            return new Promise((resolve, reject) => {
                this.get(endpoint, configuration)
                    .then((response) => {
                        onSuccess(response)
                        resolve(response)
                    })
                    .catch((error) => {
                        reject(error)
                    })
            })
        }
    }

    handleNetworkingError(error) {
        error = error ?? this?.e
        if (axios.isCancel(error)) {
            if (import.meta.env.VITE_ENV === 'development') {
                console.error('Network request canceled')
            }
            if (appInsights) {
                appInsights.trackException({
                    exception: {
                        ...error,
                        message: 'Request cancelled: ' + error.message,
                    },
                })
            }
            return new NetworkError(NetworkErrorType.cancel, 'Request cancelled')
        }
        if (error.response) {
            // The request was made and the server responded with a status code
            // that falls out of the range of 2xx
            if (import.meta.env.VITE_ENV === 'development') {
                console.error('Network error occurred')
                console.error(error.response)
            }
            if (appInsights) {
                appInsights.trackException({
                    exception: {
                        ...error,
                        message: 'Network error occurred: ' + error.message,
                    },
                })
            }
            return new NetworkError(NetworkErrorType.general, 'A network error occurred', error.response)
        } else if (error.request) {
            // The request was made but no response was received
            // `error.request` is an instance of XMLHttpRequest in the browser and an instance of
            // http.ClientRequest in node.js
            if (import.meta.env.VITE_ENV === 'development') {
                console.error('Network timeout occurred')
                console.error(error.request)
            }
            if (appInsights) {
                appInsights.trackException({
                    exception: {
                        ...error,
                        message: 'Network timeout occurred: ' + error.message,
                    },
                })
            }
            return new NetworkError(NetworkErrorType.timeout, 'A network timeout occurred', error.response) // TODO Why are we logging response here if it's empty?
        } else {
            // Something happened in setting up the request that triggered an Error
            if (import.meta.env.VITE_ENV === 'development') {
                console.error('General network error occurred\nCannot find Response/Request message')
            }
            if (appInsights) {
                appInsights.trackException({
                    exception: {
                        ...error,
                        message:
                            'General network error occurred\nCannot find response/request message: ' + error.message,
                    },
                })
            }
            return new NetworkError(
                NetworkErrorType.general,
                `A general network error occurred: ${error}`,
                error.response
            )
        }
    }
}

export default new NetworkManager()
