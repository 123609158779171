import { memo, useMemo } from 'react'
import { useTranslation } from 'react-i18next'
import {
    CheckCircleTwoTone,
    CircleTwoTone,
    DoNotDisturbOnTwoTone,
    ErrorTwoTone,
    HighlightOffTwoTone,
} from '@mui/icons-material'
import { Box, Typography } from '@mui/material'
import PropTypes from 'prop-types'

import TbxTooltip from '@/common/components/TbxTooltip/TbxTooltip'

const classes = {
    count: {
        fontSize: '16px',
        lineHeight: '140%',
    },
    badgeContainer: {
        border: '1px solid',
        borderRadius: 3,
        padding: '0 8px 0 1px',
    },
    success: (showBackground) => ({
        color: 'success.main',
        borderColor: 'success.main',
        background: showBackground ? 'rgba(46, 125, 50, 0.3)' : 'none',
    }),
    warning: (showBackground) => ({
        color: 'warning.main',
        borderColor: 'warning.main',
        background: showBackground ? 'rgba(237, 108, 2, 0.3)' : 'none',
    }),
    error: (showBackground) => ({
        color: 'error.main',
        borderColor: 'error.main',
        background: showBackground ? 'rgba(211, 47, 47, 0.3)' : 'none',
    }),
    unset: (showBackground) => ({
        color: 'text.primary',
        borderColor: 'text.primary',
        background: showBackground ? 'rgba(52, 73, 94, 0.3)' : 'none',
    }),
    overlay: {
        color: 'primary.contrastText',
    },
}

const QuoteItemStatus = ({
    count = 0,
    marginLeft = 1,
    marginRight = 0,
    messages = null,
    paddingLeft = 6,
    paddingRight = 0,
    size = 'small',
    status = 'status',
    useBackground = true,
}) => {
    const { t } = useTranslation()

    const title = useMemo(() => {
        if (!messages) return null
        if (Array.isArray(messages) && messages?.length) {
            return messages?.map((message, index) => {
                return (
                    <Box
                        component="span"
                        display="block"
                        key={index}
                        py={0.5}
                    >
                        {t(message)}
                    </Box>
                )
            })
        } else {
            return (
                <Box
                    component="span"
                    display="block"
                    py={0.5}
                >
                    {t(messages)}
                </Box>
            )
        }
    }, [messages])

    switch (status) {
        case 'success':
            return (
                <TbxTooltip
                    title={title || ''}
                    arrow
                >
                    <Box
                        alignItems="center"
                        display="flex"
                        ml={marginLeft}
                        mr={marginRight}
                        sx={[
                            useBackground && count && classes.badgeContainer,
                            classes.success(useBackground && !!count),
                        ]}
                    >
                        <CheckCircleTwoTone
                            aria-label="Success"
                            color="success"
                            fontSize={size}
                        />
                        {count ? (
                            <Typography
                                style={{ paddingLeft, paddingRight }}
                                sx={classes.count}
                            >
                                {count}
                            </Typography>
                        ) : null}
                    </Box>
                </TbxTooltip>
            )
        case 'warning':
            return (
                <TbxTooltip
                    title={title || ''}
                    arrow
                >
                    <Box
                        alignItems="center"
                        display="flex"
                        ml={marginLeft}
                        mr={marginRight}
                        sx={[
                            useBackground && count && classes.badgeContainer,
                            classes.warning(useBackground && !!count),
                        ]}
                    >
                        <ErrorTwoTone
                            aria-label="Warning"
                            color="warning"
                            fontSize={size}
                        />
                        {count ? (
                            <Typography
                                style={{ paddingLeft, paddingRight }}
                                sx={classes.count}
                            >
                                {count}
                            </Typography>
                        ) : null}
                    </Box>
                </TbxTooltip>
            )
        case 'error':
            return (
                <TbxTooltip
                    title={title || ''}
                    arrow
                >
                    <Box
                        alignItems="center"
                        display="flex"
                        ml={marginLeft}
                        mr={marginRight}
                        sx={[useBackground && count && classes.badgeContainer, classes.error(useBackground && !!count)]}
                    >
                        <HighlightOffTwoTone
                            aria-label="Error"
                            color="error"
                            fontSize={size}
                        />
                        {count ? (
                            <Typography
                                style={{ paddingLeft, paddingRight }}
                                sx={classes.count}
                            >
                                {count}
                            </Typography>
                        ) : null}
                    </Box>
                </TbxTooltip>
            )

        case 'unset':
            return (
                <TbxTooltip
                    title={title || ''}
                    arrow
                >
                    <Box
                        alignItems="center"
                        display="flex"
                        ml={marginLeft}
                        mr={marginRight}
                        sx={[useBackground && count && classes.badgeContainer, classes.unset(useBackground && !!count)]}
                    >
                        <DoNotDisturbOnTwoTone
                            aria-label="Unset"
                            color="inherit"
                            fontSize={size}
                        />
                        {count ? (
                            <Typography
                                style={{ paddingLeft, paddingRight }}
                                sx={classes.count}
                            >
                                {count}
                            </Typography>
                        ) : null}
                    </Box>
                </TbxTooltip>
            )

        case 'overlay':
            return (
                <Box
                    alignItems="center"
                    display="flex"
                    ml={marginLeft}
                    mr={marginRight}
                >
                    <CircleTwoTone
                        aria-label="Overlay"
                        fontSize={size}
                        sx={classes.overlay}
                    />
                </Box>
            )
    }
}

QuoteItemStatus.propTypes = {
    count: PropTypes.number,
    marginLeft: PropTypes.number,
    marginRight: PropTypes.number,
    messages: PropTypes.oneOfType([PropTypes.string, PropTypes.array]),
    paddingLeft: PropTypes.number,
    paddingRight: PropTypes.number,
    size: PropTypes.oneOf(['small', 'medium', 'large']),
    status: PropTypes.oneOf(['success', 'warning', 'error', 'unset', 'overlay']),
    useBackground: PropTypes.bool,
}

export default memo(QuoteItemStatus)
