import _ from 'lodash'

import CustomError from '../Utilities/CustomError'

import Layer from './Layer'
import Point from './Point'

export default class Circle {
    constructor(json) {
        if (arguments.length !== 3) {
            const jsonValid =
                json &&
                Object.prototype.hasOwnProperty.call(json, 'layer') &&
                Object.prototype.hasOwnProperty.call(json, 'isLineTypeSolid') &&
                Object.prototype.hasOwnProperty.call(json, 'type') &&
                Object.prototype.hasOwnProperty.call(json, 'centrePoint') &&
                Object.prototype.hasOwnProperty.call(json, 'radius')
            if (jsonValid) {
                _.extend(this, json)
                this.layer = new Layer(json.layer)
                this.svg = null
            } else {
                throw new CustomError('Invalid json supplied', 'InvalidJSONError')
            }
        } else {
            this.centrePoint = new Point(arguments[0], arguments[1])
            this.radius = arguments[2]
        }
    }

    toSvg() {
        return `<circle cx="${this.centrePoint.x}" cy="${this.centrePoint.y}" r="${this.radius}" />`
    }
}

Circle.ToolpathType = 'DtoCircle'
