import 'dayjs/locale/de'
import 'dayjs/locale/en'
import 'dayjs/locale/en-gb'
import 'dayjs/locale/es'
import 'dayjs/locale/fr'
import 'dayjs/locale/it'
import 'dayjs/locale/pt'
import 'dayjs/locale/vi'

import { useMemo } from 'react'
import { useSelector } from 'react-redux'
import { LocalizationProvider } from '@mui/x-date-pickers'
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs'
import { deDE, enUS, esES, frFR, itIT, ptBR, viVN } from '@mui/x-date-pickers/locales'
import dayjs from 'dayjs'
import isBetween from 'dayjs/plugin/isBetween'
import timezone from 'dayjs/plugin/timezone'
import utc from 'dayjs/plugin/utc'

import { selectOrganisation } from '@/app/slices/organisationSlice'

dayjs.extend(utc)
dayjs.extend(timezone)
dayjs.extend(isBetween)

const TbxLocalizationProvider = ({ children }) => {
    const organisation = useSelector(selectOrganisation)

    const getAdapterLocale = (language) => {
        const adapter = (language) => {
            switch (language) {
                case 'en-US':
                    return 'en'
                case 'en-GB':
                    return 'en-gb'
                case 'de-DE':
                    return 'de'
                case 'es-ES':
                    return 'es'
                case 'fr-FR':
                    return 'fr'
                case 'it-IT':
                    return 'it'
                case 'pt-PT':
                    return 'pt'
                case 'vi-VN':
                    return 'vi'
                default:
                    return 'en'
            }
        }

        dayjs.locale(adapter(language))

        return adapter(language)
    }

    const getLocaleText = (language) => {
        switch (language) {
            case 'en-US':
                return enUS.components.MuiLocalizationProvider.defaultProps.localeText
            case 'en-GB':
                return enUS.components.MuiLocalizationProvider.defaultProps.localeText
            case 'de-DE':
                return deDE.components.MuiLocalizationProvider.defaultProps.localeText
            case 'es-ES':
                return esES.components.MuiLocalizationProvider.defaultProps.localeText
            case 'fr-FR':
                return frFR.components.MuiLocalizationProvider.defaultProps.localeText
            case 'it-IT':
                return itIT.components.MuiLocalizationProvider.defaultProps.localeText
            case 'pt-PT':
                return ptBR.components.MuiLocalizationProvider.defaultProps.localeText
            case 'vi-VN':
                return viVN.components.MuiLocalizationProvider.defaultProps.localeText
            default:
                return enUS.components.MuiLocalizationProvider.defaultProps.localeText
        }
    }

    const adapterLocale = useMemo(() => getAdapterLocale(organisation.language), [organisation.language])

    const localeText = useMemo(() => getLocaleText(organisation.language), [organisation.language])

    return (
        <LocalizationProvider
            adapterLocale={adapterLocale}
            dateAdapter={AdapterDayjs}
            localeText={{
                ...localeText,
                fieldMonthPlaceholder: (params) => (params.contentType === 'letter' ? 'MMM' : 'MM'),
            }}
        >
            {children}
        </LocalizationProvider>
    )
}

export default TbxLocalizationProvider
