import { useEffect, useLayoutEffect, useRef, useState } from 'react'
import { Motion, spring } from 'react-motion'
import { Box } from '@mui/material'
import PropTypes from 'prop-types'
import svgDragSelect from 'svg-drag-select'

import { pathSort } from '@/common/utils'

import CadView from '../CadView'

import PathRenderer from './PathRenderer'

const defaultPathStrokeWidth = 0.1
const selectedPathStrokeWidth = 0.5
const svgId = 'partDrawing'

const classes = {
    root: {
        strokeLinecap: 'square',
        strokeWidth: '0.5px',
        stroke: '#FFFFFF',
        fillOpacity: 1,
        strokeOpacity: 1,
        height: '100%',
        width: '100%',
        display: 'flex',
        userSelect: 'none',
    },
    fillOff: {
        fillOpacity: 0,
    },
    selectionWindow: {
        fill: '#000000',
        stroke: 'primary.main',
        strokeWidth: `${defaultPathStrokeWidth}%`,
        strokeDasharray: '1px 0.5px',
        fillOpacity: 0.25,
        strokeOpacity: 1,
    },
    cartesian: {
        transform: 'scaleY(-1)',
    },
}

const DrawingRenderer = ({
    drawing,
    noFill = false,
    onMouseMove,
    onPathsSelected,
    onWheel,
    originalCadSvg,
    selectAreaClassName,
    selectedPaths = {},
    showCad,
    showOpenPathHighlight,
    viewBox,
}) => {
    const svgRef = useRef()
    const [cadRef, setCadRef] = useState(null)
    const animateRef = useRef()

    const [reverseAnimation, setReverseAnimation] = useState(false)

    useEffect(() => {
        const svgElement = document.getElementById(svgId)
        if (showCad || !drawing || !drawing.paths || !viewBox || viewBox.width === 0 || viewBox.height === 0) {
            return
        }

        const {
            cancel,
            // a div element overlaying dragging area.
            // you can customize the style of this element.
            // this element has "svg-drag-select-area-overlay" class by default.
            dragAreaOverlay,
        } = svgDragSelect({
            // the svg element (required).
            svg: svgElement,
            // followings are optional parameters with default values.
            // selects only descendants of this SVGElement if specified.
            referenceElement: null,
            // "enclosure": selects enclosed elements using getEnclosureList().
            // "intersection": selects intersected elements using getIntersectionList().
            // function: custom selector implementation
            selector: 'enclosure',

            onSelectionStart({
                // a `PointerEvent` instance with "pointerdown" type.
                // (in case of Safari, a `MouseEvent` or a `TouchEvent` is used instead.)
                cancel,
                // cancel() cancels.
                pointerEvent,
            }) {
                // Handles mouse left button only.
                if (pointerEvent?.button !== 0) {
                    cancel()
                    return
                }
            },

            onSelectionEnd({
                // a `PointerEvent` instance with "pointerup" type.
                // (in case of Safari, a `MouseEvent` or a `TouchEvent` is used instead.)
                dragAreaInClientCoordinate,
                // selected element array.
                pointerEvent,
                selectedElements,
            }) {
                if (!pointerEvent || typeof onPathsSelected !== 'function') {
                    return
                }
                // If click
                // the drag area is the box made when clicking and dragging, if it has no width and height then it
                // is a click and not a drag.
                if (dragAreaInClientCoordinate.height === 0 && dragAreaInClientCoordinate.width === 0) {
                    // Find clicked element
                    const clickedElement = document.elementFromPoint(pointerEvent.clientX, pointerEvent.clientY)
                    // If no element found (click outside of svg)
                    if (!clickedElement) {
                        return
                    }

                    // If clicked element is not the root <svg>
                    if (clickedElement.id !== svgId) {
                        // If shift key is held, add path to selected
                        if (pointerEvent.shiftKey) {
                            onPathsSelected([clickedElement.id], true)
                            // Else assign new selected path
                        } else {
                            onPathsSelected([clickedElement.id], false)
                        }
                    } else {
                        onPathsSelected([], !!pointerEvent.shiftKey)
                    }

                    return
                }

                // If drag, select all paths within select box
                // If shift key is held, add path to selected
                if (pointerEvent.shiftKey) {
                    onPathsSelected(
                        selectedElements.map((e) => e.id),
                        true
                    )
                    // Else assign new selected path
                } else {
                    onPathsSelected(
                        selectedElements.map((e) => e.id),
                        false
                    )
                }
            },
        })

        if (dragAreaOverlay) {
            dragAreaOverlay.className = selectAreaClassName
        }

        // Important cleanup of svg click handler methods created by svgDragSelect()
        // Need to call this when the drawing changes or we need to use new state values
        return () => {
            cancel()
        }
    }, [showCad, drawing, selectedPaths, selectAreaClassName, viewBox])

    useLayoutEffect(() => {
        return () => {
            cancelAnimationFrame(animateRef.current)
        }
    }, [])

    const handleAnimationRest = () => {
        animateRef.current = requestAnimationFrame(() => setReverseAnimation(!reverseAnimation))
    }

    const getUserCoordinatesForEvent = (event, ref) => {
        if (!ref || !ref.current) {
            return
        }

        const svg = ref.current
        let userCoordinates = svg.createSVGPoint()
        userCoordinates.x = event.clientX
        userCoordinates.y = event.clientY
        userCoordinates = userCoordinates.matrixTransform(svg.getScreenCTM()?.inverse())

        return userCoordinates
    }

    const handleMouseMove = (event) => {
        const userCoordinates = getUserCoordinatesForEvent(event, showCad ? cadRef : svgRef)

        if (typeof onMouseMove === 'function') {
            onMouseMove(event, userCoordinates)
        }
    }

    const handleOnWheelScroll = (event) => {
        const userCoordinates = getUserCoordinatesForEvent(event, showCad ? cadRef : svgRef)
        if (typeof onWheel === 'function') {
            onWheel(event, userCoordinates)
        }

        return false
    }

    const pathEntrySort = ([, a], [, b]) => pathSort(a, b)

    const paths = !!drawing && !!drawing.paths && Object.entries(drawing.paths)
    const springSettings = { stiffness: 100, damping: 10 }

    const drawingStyle = Object.assign({}, classes.root, classes.cartesian, noFill ? classes.fillOff : {})

    return drawing ? (
        showCad ? (
            <CadView
                cadSvg={originalCadSvg}
                setRef={setCadRef}
                viewBoxHeight={viewBox ? viewBox.height : null}
                viewBoxWidth={viewBox ? viewBox.width : null}
                viewBoxX={viewBox ? viewBox.minX : null}
                viewBoxY={viewBox ? viewBox.minY : null}
                onWheel={handleOnWheelScroll}
            />
        ) : (
            <Box
                component="svg"
                id={svgId}
                ref={svgRef}
                sx={drawingStyle}
                viewBox={viewBox ? `${viewBox.minX} ${viewBox.minY} ${viewBox.width} ${viewBox.height}` : null}
                onMouseMove={handleMouseMove}
                onWheel={handleOnWheelScroll}
            >
                <defs>
                    <marker
                        id="open-path-highlight"
                        markerHeight="15"
                        markerWidth="15"
                        refX="15"
                        refY="15"
                        viewBox="0 0 50 50"
                    >
                        <circle
                            cx="15"
                            cy="15"
                            fill="rgba(255, 204, 0, 0.5)"
                            r="15"
                            stroke="rgba(255, 204, 0, 1)"
                        />
                    </marker>
                </defs>
                {paths ? (
                    <Motion
                        defaultStyle={{
                            strokeWidth: reverseAnimation ? selectedPathStrokeWidth : defaultPathStrokeWidth,
                        }}
                        style={{
                            strokeWidth: reverseAnimation
                                ? spring(defaultPathStrokeWidth, springSettings)
                                : spring(selectedPathStrokeWidth, springSettings),
                        }}
                        onRest={handleAnimationRest}
                    >
                        {(interpolatingStyle) => (
                            <g>
                                {paths
                                    .slice()
                                    .sort(pathEntrySort)
                                    .map(([pathId, path]) => {
                                        const pathSelected = Boolean(selectedPaths[pathId])
                                        return (
                                            <PathRenderer
                                                drawingPath={path}
                                                key={pathId}
                                                showOpenPathHighlight={showOpenPathHighlight}
                                                style={
                                                    pathSelected
                                                        ? {
                                                              strokeWidth: `${interpolatingStyle.strokeWidth}%`,
                                                          }
                                                        : { strokeWidth: `${defaultPathStrokeWidth}%` }
                                                }
                                                usingCartesianCoordinates={true}
                                            />
                                        )
                                    })}
                            </g>
                        )}
                    </Motion>
                ) : null}
            </Box>
        )
    ) : null
}

DrawingRenderer.propTypes = {
    drawing: PropTypes.object,
    noFill: PropTypes.bool,
    originalCadSvg: PropTypes.oneOfType([PropTypes.string, PropTypes.object]),
    selectAreaClassName: PropTypes.string,
    selectedPaths: PropTypes.object,
    showCad: PropTypes.bool,
    showOpenPathHighlight: PropTypes.bool,
    viewBox: PropTypes.shape({
        height: PropTypes.number,
        minX: PropTypes.number,
        minY: PropTypes.number,
        width: PropTypes.number,
    }),
    onMouseMove: PropTypes.func,
    onPathsSelected: PropTypes.func,
    onWheel: PropTypes.func,
}

export default DrawingRenderer
