import { useTranslation } from 'react-i18next'
import { Add, FileCopy } from '@mui/icons-material'
import { Button, CircularProgress } from '@mui/material'
import PropTypes from 'prop-types'

import TbxToolbar from '@/common/components/TbxToolbar/TbxToolbar'
import TbxToolbarActions from '@/common/components/TbxToolbar/TbxToolbarActions'
import TbxToolbarTitle from '@/common/components/TbxToolbar/TbxToolbarTitle'

import TbxToolbarMessages from '../../TbxToolbar/TbxToolbarMessages'

const CustomerCentralToolbar = ({ customer, handleAddCustomerClick, handleCreateQuoteClick, isCreatingQuote }) => {
    const { t } = useTranslation()

    return (
        <TbxToolbar>
            <TbxToolbarTitle>{t('Customer Central')}</TbxToolbarTitle>

            <TbxToolbarMessages />

            <TbxToolbarActions>
                <Button
                    color="secondary"
                    disabled={customer?.isDeleted || isCreatingQuote}
                    size="small"
                    startIcon={
                        !isCreatingQuote ? (
                            <FileCopy />
                        ) : (
                            <CircularProgress
                                color="secondary"
                                size={16}
                                thickness={2}
                            />
                        )
                    }
                    variant="outlined"
                    onClick={!isCreatingQuote ? handleCreateQuoteClick : null}
                >
                    {!isCreatingQuote ? t('Create new quote') : t('Creating quote')}
                </Button>

                <Button
                    color="primary"
                    data-testid="add-customer-button"
                    startIcon={<Add />}
                    variant="contained"
                    onClick={handleAddCustomerClick}
                >
                    {t('Add customer')}
                </Button>
            </TbxToolbarActions>
        </TbxToolbar>
    )
}

CustomerCentralToolbar.propTypes = {
    customer: PropTypes.object,
    handleAddCustomerClick: PropTypes.func,
    handleCreateQuoteClick: PropTypes.func,
    isCreatingQuote: PropTypes.bool,
}

export default CustomerCentralToolbar
