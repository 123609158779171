import { memo, useCallback, useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useDispatch, useSelector } from 'react-redux'
import { Navigate, useNavigate, useParams } from 'react-router-dom'
import {
    closestCenter,
    DndContext,
    DragOverlay,
    KeyboardSensor,
    PointerSensor,
    useSensor,
    useSensors,
} from '@dnd-kit/core'
import { restrictToVerticalAxis } from '@dnd-kit/modifiers'
import { arrayMove, sortableKeyboardCoordinates } from '@dnd-kit/sortable'
import { DeleteForeverRounded, UnfoldLess, UnfoldMore } from '@mui/icons-material'
import { Box, Checkbox, FormControlLabel, Grid, IconButton, Typography } from '@mui/material'
import { useSnackbar } from 'notistack'

import { selectStoreSettings } from '@/app/services/organisation'
import { selectQuote, useGetQuoteQuery, useUpdateQuoteMutation } from '@/app/services/web-store/webStoreQuote'
import {
    selectQuoteItems,
    useDeleteQuoteItemsMutation,
    useGetQuoteItemsQuery,
    useUpdateQuoteItemsMutation,
} from '@/app/services/web-store/webStoreQuoteItems'
import {
    selectCurrentContact,
    selectGuestUser,
    selectIsContactLoggedIn,
    selectIsGuestLoggedIn,
} from '@/app/slices/web-store/webStoreAuthSlice'
import {
    clearExpandedItems,
    clearSelectedItems,
    selectSelectedItems,
    setExpandedItemsIds,
    setSelectedItemsIds,
} from '@/app/slices/web-store/webStoreQuoteItemsSlice'
import { QUOTE_USER_TYPE, WEB_STORE_QUOTE_STATUS } from '@/common/utils'

import QuoteActions from '../../components/Quote/QuoteActions'
import QuoteItemList from '../../components/Quote/QuoteItemList'
import QuoteItemOverlay from '../../components/Quote/QuoteItemOverlay'
import QuoteItemsBulkEdit from '../../components/Quote/QuoteItemsBulkEdit'
import QuoteSummary from '../../components/Quote/QuoteSummary'
import TbxDialog from '../../components/shared/TbxDialog'

const classes = {
    root: {
        backgroundColor: 'background.paper',
        borderRadius: 2,
        border: (theme) => `1px solid ${theme.palette.grey[300]}`,
        width: '100%',
    },
    empty: {
        paddingBlock: 14,
    },
    quoteSummaryWrapper: {
        position: {
            lg: 'sticky',
        },
        top: {
            lg: '88px',
        },
    },
}

const Quote = () => {
    const dispatch = useDispatch()
    const { t } = useTranslation()
    const { enqueueSnackbar } = useSnackbar()
    const navigate = useNavigate()

    const { organisationId, quoteId } = useParams()

    const sensors = useSensors(
        useSensor(PointerSensor),
        useSensor(KeyboardSensor, {
            coordinateGetter: sortableKeyboardCoordinates,
        })
    )

    const { isError, isLoading, isSuccess } = useGetQuoteQuery({
        organisationId,
        quoteId,
    })
    const { isLoading: isLoadingItems } = useGetQuoteItemsQuery({
        organisationId,
        quoteId,
    })

    const quote = useSelector((state) => selectQuote(state, { organisationId, quoteId }))
    const quoteItems = useSelector((state) => selectQuoteItems(state, { organisationId, quoteId }))
    const quoteItemsIds = quoteItems.map((item) => item.id)

    const [allCollapsed, setAllCollapsed] = useState(false)
    const [selectAll, setSelectAll] = useState(false)
    const [isDeleteDialogOpen, setIsDeleteDialogOpen] = useState(false)
    const [activeItemId, setActiveItemId] = useState(null)
    const [activeItemName, setActiveItemName] = useState(null)

    const [updateQuoteItems] = useUpdateQuoteItemsMutation()
    const [deleteQuoteItems, { isLoading: isDeleting }] = useDeleteQuoteItemsMutation()

    const [updateQuote] = useUpdateQuoteMutation()

    const selectedItemsArray = useSelector(selectSelectedItems)

    const isContactLoggedIn = useSelector(selectIsContactLoggedIn)
    const isGuestLoggedIn = useSelector(selectIsGuestLoggedIn)
    const currentUser = useSelector(selectCurrentContact)
    const guestUser = useSelector(selectGuestUser)
    const storeSettings = useSelector((state) => selectStoreSettings(state, { organisationId }))

    const onDragStart = (event) => {
        const id = event.active.id
        setActiveItemId(id)

        const activeItem = quoteItems.find((item) => item.id === id)
        setActiveItemName(activeItem.name)
    }

    const onDragEnd = useCallback(
        async (event) => {
            const { active, over } = event

            if (!active || !over) {
                return
            }

            if (active.id === over.id) {
                return
            }

            const oldIndex = active.data.current.sortable.index
            const newIndex = over.data.current.sortable.index

            const items = structuredClone(arrayMove(quoteItems, oldIndex, newIndex))

            items.forEach((item, index) => {
                item.index = index
            })

            try {
                await updateQuoteItems({ organisationId, quoteId, quoteItems: items })
            } catch (e) {
                enqueueSnackbar(t('Failed to reorder $t(quote) items. Undoing changes'), { variant: 'error' })
            } finally {
                setActiveItemId(null)
            }
        },
        [quoteItems, updateQuoteItems, organisationId, quoteId, enqueueSnackbar, t]
    )

    const handleDeleteDialogOpen = () => {
        setIsDeleteDialogOpen(true)
    }

    const handleDeleteDialogClose = () => {
        setIsDeleteDialogOpen(false)
    }

    const collapseAll = () => {
        dispatch(clearExpandedItems())
        setAllCollapsed(true)
    }

    const expandAll = () => {
        dispatch(setExpandedItemsIds(quoteItemsIds))
        setAllCollapsed(false)
    }

    const handleSelectAllItems = () => {
        setSelectAll(!selectAll)
        if (!selectAll) {
            dispatch(setSelectedItemsIds(quoteItemsIds))
        } else {
            dispatch(clearSelectedItems())
        }
    }

    const handleDeleteAllItems = async () => {
        const quoteItemsIds = quoteItems.map((item) => item.id)
        try {
            await deleteQuoteItems({
                organisationId,
                quoteId,
                quoteItemsIds,
            }).unwrap()
            enqueueSnackbar(t('All parts deleted'), { variant: 'success' })
        } catch (error) {
            enqueueSnackbar(t('Failed to delete all $t(quote) items'), {
                variant: 'error',
            })
        } finally {
            setIsDeleteDialogOpen(false)
        }
    }

    const assignCustomerContact = async () => {
        try {
            await updateQuote({
                quoteId: quote.id,
                organisationId: quote.organisationId,
                quote: {
                    name: quote.name,
                    quoteId: quote.id,
                    customerContactId: currentUser.contactId,
                    quoteUserType: QUOTE_USER_TYPE.Customer,
                    deliveryPricingMethod: quote.deliveryPricingMethod,
                },
            }).unwrap()
        } catch (error) {
            enqueueSnackbar(t("Couldn't assign the quote to your user"), {
                variant: 'error',
            })
        }
    }

    useEffect(() => {
        if (!quoteItemsIds.length) {
            if (selectAll !== false) {
                setSelectAll(false)
            }
        } else {
            const allSelected = quoteItemsIds.every((id) => selectedItemsArray.includes(id))
            if (selectAll !== allSelected) {
                setSelectAll(allSelected)
            }
        }
    }, [quoteItemsIds, selectedItemsArray, selectAll])

    useEffect(() => {
        if (!isLoadingItems && quoteItems.length) {
            dispatch(setExpandedItemsIds(quoteItemsIds))
        }
    }, [isLoadingItems])

    useEffect(() => {
        if (!storeSettings.webStoreIsPublic && !isContactLoggedIn) {
            sessionStorage.setItem('redirect', quoteId)
            navigate(`/store/${organisationId}/login`)
        }

        if (!isGuestLoggedIn && !isContactLoggedIn) {
            sessionStorage.setItem('redirect', quoteId)
            navigate(`/store/${organisationId}/login`)
        }
    }, [isContactLoggedIn, isGuestLoggedIn, storeSettings])

    useEffect(() => {
        if (
            isSuccess &&
            quote?.customerContactId &&
            [QUOTE_USER_TYPE.Customer, QUOTE_USER_TYPE.Internal].includes(quote?.quoteUserType) &&
            !isContactLoggedIn
        ) {
            navigate(`/store/${organisationId}/login`)
        }

        if (
            isSuccess &&
            quote?.customerContactId &&
            [QUOTE_USER_TYPE.Customer, QUOTE_USER_TYPE.Internal].includes(quote?.quoteUserType) &&
            isContactLoggedIn &&
            currentUser.contactId !== quote.customerContactId
        ) {
            navigate(`/store/${organisationId}`)
        }
    }, [isSuccess, quote, isContactLoggedIn])

    useEffect(() => {
        if (isSuccess && isContactLoggedIn && !quote?.customerContactId) {
            assignCustomerContact()
        }
    }, [isSuccess, isContactLoggedIn, quote])

    if (!isLoading && isError) {
        return <Navigate to={`/store/${organisationId}/not-found`} />
    }

    if (!isLoading && isSuccess && quote.status === WEB_STORE_QUOTE_STATUS.Ordered) {
        return <Navigate to={`/store/${organisationId}/${quoteId}/order-confirmation`} />
    }

    if (!isLoading && isSuccess && quote.status === WEB_STORE_QUOTE_STATUS.PendingOrderConfirmation) {
        return <Navigate to={`/store/${organisationId}/${quoteId}/order-confirmation`} />
    }

    if (!isLoading && isSuccess) {
        const emptyContainerStyle = Object.assign({}, classes.root, classes.empty)
        const quoteSummaryWrapperStyle = Object.assign({}, classes.root, classes.quoteSummaryWrapper)
        return (
            <>
                <QuoteActions />
                <Grid
                    component="section"
                    lg={9}
                    xs={12}
                    container
                    item
                >
                    {!isLoadingItems && !quoteItems.length ? (
                        <Box
                            alignItems="center"
                            display="flex"
                            flexDirection="column"
                            justifyContent="center"
                            sx={emptyContainerStyle}
                        >
                            <Box textAlign="center">
                                <Typography
                                    component="h2"
                                    variant="h6"
                                >
                                    {t('No parts in this $t(quote)')}
                                </Typography>
                                <Typography>
                                    {t('Please add your parts, select the material and get a quote.')}
                                </Typography>
                            </Box>
                        </Box>
                    ) : null}

                    {!isLoadingItems && Boolean(quoteItems.length) ? (
                        <Box
                            display="flex"
                            flexDirection="column"
                            sx={classes.root}
                        >
                            <Box
                                display="flex"
                                justifyContent="space-between"
                                pt={1.5}
                                px={3}
                            >
                                <FormControlLabel
                                    aria-label="Select all part"
                                    control={
                                        <Checkbox
                                            checked={selectAll}
                                            color="primary"
                                            size="small"
                                            onChange={handleSelectAllItems}
                                        />
                                    }
                                    label={t('Select all')}
                                />

                                <Box>
                                    {allCollapsed ? (
                                        <IconButton
                                            aria-label="Expand all"
                                            color="primary"
                                            size="large"
                                            onClick={expandAll}
                                        >
                                            <UnfoldMore />
                                        </IconButton>
                                    ) : (
                                        <IconButton
                                            aria-label="Collapse all"
                                            color="primary"
                                            size="large"
                                            onClick={collapseAll}
                                        >
                                            <UnfoldLess />
                                        </IconButton>
                                    )}

                                    <IconButton
                                        aria-label="delete"
                                        color="primary"
                                        size="large"
                                        onClick={handleDeleteDialogOpen}
                                    >
                                        <DeleteForeverRounded />
                                    </IconButton>
                                </Box>
                            </Box>

                            <DndContext
                                collisionDetection={closestCenter}
                                modifiers={[restrictToVerticalAxis]}
                                sensors={sensors}
                                onDragEnd={onDragEnd}
                                onDragStart={onDragStart}
                            >
                                <Box
                                    pb={3}
                                    px={3}
                                >
                                    <QuoteItemList quoteItems={quoteItems} />
                                    <DragOverlay>
                                        {activeItemId ? (
                                            <QuoteItemOverlay
                                                id={activeItemId}
                                                name={activeItemName}
                                            />
                                        ) : null}
                                    </DragOverlay>
                                </Box>
                            </DndContext>
                        </Box>
                    ) : null}
                </Grid>

                <Grid
                    component="aside"
                    lg={3}
                    xs={12}
                    item
                >
                    <Box sx={quoteSummaryWrapperStyle}>
                        <QuoteSummary />
                    </Box>
                </Grid>

                <TbxDialog
                    closeButtonText={t('cancel')}
                    confirmButtonText={isDeleting ? t('Deleting...') : t('Yes, delete all')}
                    content={t('Are you sure you want to delete all parts from $t(quote)')}
                    handleClose={handleDeleteDialogClose}
                    handleConfirmClose={handleDeleteAllItems}
                    isLoading={isDeleting}
                    isOpen={isDeleteDialogOpen}
                    key={quoteId}
                    title={t('Delete all')}
                />

                <QuoteItemsBulkEdit />
            </>
        )
    }
}

export default memo(Quote)
