import { memo } from 'react'
import { AccordionDetails, Box, Divider } from '@mui/material'
import PropTypes from 'prop-types'

import MiscItemDrawing from './MiscItemDrawing'
import MiscItemPrices from './MiscItemPrices'
import MiscItemSettings from './MiscItemSettings'

const classes = {
    AccordionDetailsRoot: {
        display: 'flex',
        backgroundColor: 'background.paper',
        padding: 3,
        gap: '24px',
        borderTop: (theme) => `1px solid ${theme.palette.grey[400]}`,
    },

    ItemDetails_Processes: {
        width: '220px',
        gap: '16px',
    },
    ListItemIconRoot: {
        minWidth: 'initial',
        paddingRight: 1,
    },
}

const MiscItemDetails = ({ miscItemId }) => {
    return (
        <AccordionDetails sx={classes.AccordionDetailsRoot}>
            <MiscItemDrawing miscItemId={miscItemId} />
            <Divider
                orientation="vertical"
                flexItem
            />
            <MiscItemSettings miscItemId={miscItemId} />
            <Divider
                orientation="vertical"
                flexItem
            />

            <MiscItemPrices miscItemId={miscItemId} />
        </AccordionDetails>
    )
}

MiscItemDetails.propTypes = {
    isRotary: PropTypes.bool,
    miscItemId: PropTypes.string,
}

export default memo(MiscItemDetails)
