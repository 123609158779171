import { api } from './api'

const apiVersion = 'v2'

export const ratesApi = api
    .enhanceEndpoints({
        addTagTypes: ['Rates'],
    })
    .injectEndpoints({
        endpoints: (builder) => ({
            getRates: builder.query({
                query: ({ organisationId }) => ({
                    url: `/${apiVersion}/organisation/${organisationId}/rate-tables/rates`,
                }),
                transformResponse: (response) => {
                    delete response.$type

                    return response
                },
                providesTags: (result, _error, _arg) =>
                    result
                        ? [
                              ...(Object.keys(result).map((rateId) => ({ type: 'Rates', id: rateId })) ?? []),
                              { type: 'Rates', id: 'LIST' },
                          ]
                        : [{ type: 'Rates', id: 'LIST' }],
            }),

            createRate: builder.mutation({
                query: ({ organisationId, rate }) => ({
                    url: `/organisations/${organisationId}/rate-table/rate`,
                    method: 'PUT',
                    body: rate,
                }),
                invalidatesTags: [{ type: 'Rates', id: 'LIST' }],
            }),

            updateRate: builder.mutation({
                query: ({ organisationId, rate }) => ({
                    url: `/organisations/${organisationId}/rate-table/rate`,
                    method: 'PUT',
                    body: rate,
                }),
                invalidatesTags: (_result, _error, arg) => [{ type: 'Rates', id: arg.rate.rateId }],
            }),

            ratesBulkUpdate: builder.mutation({
                query: ({ organisationId, updatedRates }) => ({
                    url: `/organisations/${organisationId}/rate-table/rate/bulk-update`,
                    method: 'PUT',
                    body: { rates: updatedRates },
                }),
                invalidatesTags: (_result, _error, arg) =>
                    arg.updatedRates.map((rate) => ({ type: 'Rates', id: rate.rateId })),
            }),

            archiveRate: builder.mutation({
                query: ({ organisationId, rateId }) => ({
                    url: `/organisations/${organisationId}/rate-table/rate/${rateId}`,
                    method: 'DELETE',
                }),
                invalidatesTags: (_result, _error, arg) => [
                    { type: 'Rates', id: 'LIST' },
                    { type: 'Rates', id: arg.rateId },
                ],
            }),

            importRates: builder.mutation({
                query: ({ emptyFieldsTreatment, file, organisationId, rateTableId }) => {
                    const formData = new FormData()
                    formData.append('file', file, file.name)
                    return {
                        url: `/organisations/${organisationId}/rate-table/${rateTableId}/import?emptyFieldsTreatment=${emptyFieldsTreatment}`,
                        method: 'POST',
                        body: formData,
                    }
                },
                invalidatesTags: (_result, _error, arg) => [
                    { type: 'Rates', id: 'LIST' },
                    { type: 'RateTables', id: arg.rateTableId },
                ],
            }),

            exportRates: builder.query({
                query: ({ organisationId, rateTableId }) => ({
                    url: `/organisations/${organisationId}/rate-table/${rateTableId}/export`,
                    responseHandler: (response) => {
                        return response.text()
                    },
                }),
            }),

            downloadRateTemplate: builder.query({
                query: ({ organisationId, rateTableId }) => ({
                    url: `/organisations/${organisationId}/rate-table/${rateTableId}/export-template`,
                    responseHandler: (response) => {
                        return response.text()
                    },
                }),
            }),
        }),
    })

export const {
    useArchiveRateMutation,
    useCreateRateMutation,
    useGetRatesQuery,
    useImportRatesMutation,
    useLazyDownloadRateTemplateQuery,
    useLazyExportRatesQuery,
    useRatesBulkUpdateMutation,
    useUpdateRateMutation,
} = ratesApi
