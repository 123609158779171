export const extractCSSFromDOM = () => {
    const styleSheets = document.styleSheets
    let cssString = ''
    for (const item in styleSheets) {
        const ss = styleSheets[item]
        try {
            const rules = ss['cssRules']
            for (const rule in rules) {
                if (!isNaN(rule)) {
                    const ruleObject = rules[rule]
                    cssString = `${cssString} ${ruleObject.cssText || ''}`
                }
            }
        } catch (error) {
            //
        }
    }
    return cssString
}
