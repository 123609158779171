import { useEffect } from 'react'
import { useNavigate } from 'react-router-dom'
import { useMsal } from '@azure/msal-react'

import { signUpRequest } from '@/authConfig'
import { PageSpinner } from '@/common/components'

const Register = () => {
    const navigate = useNavigate()
    const { instance: msalInstance } = useMsal()

    useEffect(() => {
        if (msalInstance.getActiveAccount()) {
            navigate('/')
        } else {
            msalInstance.loginRedirect(signUpRequest)
        }
    })

    return <PageSpinner />
}

export default Register
