import { api } from './api'

export const contactsApi = api
    .enhanceEndpoints({
        addTagTypes: ['Contacts'],
    })
    .injectEndpoints({
        endpoints: (builder) => ({
            getContacts: builder.query({
                query: ({ customerId, includeDeleted, organisationId }) => ({
                    url: `/organisations/${organisationId}/customers/${customerId}/contacts`,
                    params: { includeDeleted },
                }),
                providesTags: (result, _error, _arg) =>
                    result
                        ? [
                              { type: 'Contacts', id: 'LIST' },
                              ...result.map(({ contactId }) => ({ type: 'Contacts', id: contactId })),
                          ]
                        : [{ type: 'Contacts', id: 'LIST' }],
                transformResponse: (response) => {
                    // sort contacts by name
                    return response?.sort((a, b) => a.name.toLowerCase().localeCompare(b.name.toLowerCase()))
                },
            }),

            getContact: builder.query({
                query: ({ contactId, customerId, organisationId }) =>
                    `/organisations/${organisationId}/customers/${customerId}/contacts/${contactId}`,
                providesTags: (_result, _error, arg) => [{ type: 'Contacts', id: arg.contactId }],
            }),

            createContact: builder.mutation({
                query: ({ contact, customerId, organisationId }) => ({
                    url: `/organisations/${organisationId}/customers/${customerId}/contacts`,
                    method: 'POST',
                    body: contact,
                }),
                invalidatesTags: (_result, _error, arg) => [
                    { type: 'Customers', id: arg.customerId },
                    { type: 'Contacts', id: 'LIST' },
                ],
            }),

            updateContact: builder.mutation({
                query: ({ contact, contactId, customerId, organisationId }) => ({
                    url: `/organisations/${organisationId}/customers/${customerId}/contacts/${contactId}`,
                    method: 'PUT',
                    body: contact,
                }),
                invalidatesTags: (_result, _error, arg) => [
                    { type: 'Customers', id: arg.customerId },
                    { type: 'Contacts', id: arg.contactId },
                ],
            }),

            deleteContacts: builder.mutation({
                query: ({ contactIds, customerId, organisationId }) => ({
                    url: `/organisations/${organisationId}/customers/${customerId}/contacts`,
                    method: 'DELETE',
                    body: contactIds,
                }),
                invalidatesTags: (_result, _error, arg) => [
                    { type: 'Contacts', id: 'LIST' },
                    arg.contactIds.map((contactId) => ({ type: 'Contacts', id: contactId })),
                ],
            }),

            archiveContact: builder.mutation({
                query: ({ contactId, customerId, organisationId }) => ({
                    url: `/organisations/${organisationId}/customers/${customerId}/contacts/${contactId}/archive`,
                    method: 'PUT',
                }),
                invalidatesTags: (_result, _error, arg) => [{ type: 'Contacts', id: arg.contactId }],
            }),

            unarchiveContact: builder.mutation({
                query: ({ contactId, customerId, organisationId }) => ({
                    url: `/organisations/${organisationId}/customers/${customerId}/contacts/${contactId}/unarchive`,
                    method: 'PUT',
                }),
                invalidatesTags: (_result, _error, arg) => [{ type: 'Contacts', id: arg.contactId }],
            }),

            setAsDefaultContact: builder.mutation({
                query: ({ contactId, customerId, organisationId }) => ({
                    url: `/organisations/${organisationId}/customers/${customerId}/contacts/${contactId}/default`,
                    method: 'PUT',
                }),
                invalidatesTags: (_result, _error, arg) => [{ type: 'Contacts', id: arg.contactId }],
            }),
        }),
    })

export const {
    useArchiveContactMutation,
    useCreateContactMutation,
    useDeleteContactsMutation,
    useGetContactQuery,
    useGetContactsQuery,
    useSetAsDefaultContactMutation,
    useUnarchiveContactMutation,
    useUpdateContactMutation,
} = contactsApi
