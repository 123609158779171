import { useCallback, useEffect } from 'react'
import { useTranslation } from 'react-i18next'
import { useSelector } from 'react-redux'
import { Box, Button, Container, Typography } from '@mui/material'
import PropTypes from 'prop-types'

import { selectCurrentUser } from '@/app/slices/appSlice'
import { selectOrganisation , selectPaidFeatures } from '@/app/slices/organisationSlice'
import { useToolBoxTreatments } from '@/common/hooks'
import { getLanguageToUse } from '@/common/utils'

const unfoldUri = `${import.meta.env.VITE_UNFOLD_URI}/?cnxname=${import.meta.env.VITE_UNFOLD_CNXNAME}&container=${
    import.meta.env.VITE_UNFOLD_CONTAINER
}&appname=${import.meta.env.VITE_UNFOLD_APPNAME}`

const classes = {
    container: {
        display: 'flex',
        flexDirection: 'column',
        height: '100%',
        backgroundColor: 'background.paper',
        border: '1px solid hsl(0, 0%, 15%)',
        boxSizing: 'border-box',
        overflow: 'hidden',
    },
    topBar: {
        borderBottom: '1px solid hsl(0, 0%, 20%)',
        boxSizing: 'border-box',
        height: 64,
        width: '100%',
        padding: 3,
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'space-between',
        backgroundColor: 'hsl(0, 0%, 15%)',
    },
    body: {
        display: 'flex',
        height: '100%',
        width: '100%',
        border: 0,
    },
    title: {
        textTransform: 'uppercase',
        color: 'text.primary',
    },
    buttonContainer: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'space-between',
    },
    button: {
        width: 100,
    },
}

const Unfold = ({ onCancelClicked, onSubmitClicked, organisationId, quoteId, unfoldTube = false }) => {
    const { t } = useTranslation()

    const { replaceSplinesWithLinearSegments, showViewer3D, showViewerTube } = useToolBoxTreatments()
    
    const paidFeatures = useSelector(selectPaidFeatures)


    const organisation = useSelector(selectOrganisation)

    const currentUser = useSelector(selectCurrentUser)

    useEffect(() => {
        const handleMessageFromUnFold = (unFoldEvent) => {
            if (unFoldEvent.origin !== import.meta.env.VITE_UNFOLD_URI) {
                return
            }
            if (unFoldEvent.data === '3dDone') {
                onSubmitClicked()
            }
        }
        window.addEventListener('message', handleMessageFromUnFold)

        return () => {
            window.removeEventListener('message', handleMessageFromUnFold)
        }
    }, [onSubmitClicked])

    const getUnfoldUrl = useCallback(() => {
        let finalURI =
            unfoldUri +
            `&oid=${organisationId}&qid=${quoteId}&linear=${replaceSplinesWithLinearSegments}&lng=${getLanguageToUse(
                currentUser,
                organisation
            )}&thumbnailSize=2&organisationUnits=${organisation.defaultDrawingUnits === 'Imperial' ? 'in' : 'mm'}`
        
        if (unfoldTube) {
            finalURI += `&unfoldTube=${unfoldTube}`
            if (showViewerTube || paidFeatures.hasShowViewerTube)
            {
                finalURI += '&useViewer=true'
            }
        }
        else
        {
            if (showViewer3D || paidFeatures.hasShowViewer3D)
            {
                finalURI += '&useViewer=true'
            }
        }


        return finalURI
    }, [organisationId, quoteId, unfoldTube, replaceSplinesWithLinearSegments, organisation, currentUser])

    return (
        <Container
            disableGutters={true}
            maxWidth={false}
            sx={classes.container}
        >
            <Box sx={classes.topBar}>
                <Typography
                    sx={classes.title}
                    variant="h5"
                >
                    {unfoldTube ? t('Add Rotary Parts') : t('Add 3D Parts')}
                </Typography>
                <Box sx={classes.buttonContainer}>
                    <Button
                        color="secondary"
                        sx={classes.button}
                        variant="contained"
                        onClick={onCancelClicked}
                    >
                        {t('Cancel')}
                    </Button>
                </Box>
            </Box>
            <iframe
                data-testid={unfoldTube ? 'unfold3d-container-tube' : 'unfold3d-container-3d'}
                src={getUnfoldUrl()}
                style={classes.body}
                title="unfold3d"
            />
        </Container>
    )
}

Unfold.propTypes = {
    organisationId: PropTypes.string,
    quoteId: PropTypes.string,
    unfoldTube: PropTypes.bool,
    onCancelClicked: PropTypes.func,
    onSubmitClicked: PropTypes.func,
}

export default Unfold
