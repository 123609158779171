import { api } from './api'

export const partLibraryApi = api
    .enhanceEndpoints({
        addTagTypes: ['PartLibraryEntries', 'PartLibraryEntriesQuotes'],
    })
    .injectEndpoints({
        endpoints: (builder) => ({
            getPartLibraryEntries: builder.query({
                query: ({ customerId, organisationId }) =>
                    `/organisations/${organisationId}/part-library-entries/${customerId}`,
                providesTags: (result, _error, _arg) =>
                    result
                        ? [
                              { type: 'PartLibraryEntries', id: 'LIST' },
                              ...result.map(({ partLibraryId }) => ({ type: 'PartLibraryEntries', id: partLibraryId })),
                          ]
                        : [{ type: 'PartLibraryEntries', id: 'LIST' }],
            }),

            getPartLibraryEntryQuotes: builder.query({
                query: ({ organisationId, partLibraryId }) =>
                    `/organisations/${organisationId}/part-library-entries/partLibraryEntry/${partLibraryId}/quotes`,
                providesTags: (result, _error, arg) =>
                    result
                        ? [
                              { type: 'PartLibraryEntriesQuotes', id: 'LIST' },
                              ...result.map(() => ({ type: 'PartLibraryEntriesQuotes', id: arg.partLibraryId })),
                          ]
                        : [{ type: 'PartLibraryEntriesQuotes', id: 'LIST' }],
            }),

            createPartLibraryEntries: builder.mutation({
                query: ({ organisationId, quoteId }) => ({
                    url: `/organisations/${organisationId}/part-library-entries/quote/${quoteId}`,
                    method: 'POST',
                }),
                invalidatesTags: (_result, _error, arg) => [
                    { type: 'PartLibraryEntries', id: 'LIST' },
                    { type: 'QuoteItems', id: 'LIST' },
                    { type: 'Quotes', id: arg.quoteId },
                ],
            }),

            updatePartLibraryEntry: builder.mutation({
                query: ({ customerId, fixedPrice, fixedPriceEndTime, organisationId, partLibraryEntryId }) => ({
                    url: `/organisations/${organisationId}/part-library-entries/partLibraryEntry/${partLibraryEntryId}`,
                    method: 'PUT',
                    body: { fixedPrice, fixedPriceEndTime },
                }),
                async onQueryStarted(
                    { customerId, fixedPrice, fixedPriceEndTime, organisationId, partLibraryEntryId },
                    { dispatch, queryFulfilled }
                ) {
                    dispatch(
                        partLibraryApi.util.updateQueryData(
                            'getPartLibraryEntries',
                            { organisationId, customerId },
                            (draft) => {
                                const index = draft.findIndex((item) => item.partLibraryId === partLibraryEntryId)
                                draft[index].fixedPrice = fixedPrice
                                draft[index].fixedPriceEndTime = fixedPriceEndTime
                            }
                        )
                    )

                    try {
                        await queryFulfilled
                    } catch {
                        //patchResult.undo()
                        dispatch(partLibraryApi.util.invalidateTags([{ type: 'PartLibraryEntries', id: 'LIST' }]))
                    }
                },
            }),

            addPartLibrariesEntriesToQuote: builder.mutation({
                query: ({ organisationId, quoteId, quoteItemsToCreate }) => ({
                    url: `/organisations/${organisationId}/part-library-entries/quote/${quoteId}`,
                    method: 'PUT',
                    body: quoteItemsToCreate,
                }),
                invalidatesTags: [{ type: 'PartLibraryEntries', id: 'LIST' }],
            }),

            deletePartLibraryEntry: builder.mutation({
                query: ({ deletePartLibraryEntry, organisationId, partLibraryEntryId, quoteId }) => ({
                    url: `/organisations/${organisationId}/part-library-entries/quote/${quoteId}/${partLibraryEntryId}/${deletePartLibraryEntry}/delete`,
                    method: 'DELETE',
                }),
                invalidatesTags: (_result, _error, arg) => [
                    { type: 'PartLibraryEntries', id: 'LIST' },
                    { type: 'PartLibraryEntries', id: arg.partLibraryEntryId },
                ],
            }),
        }),
    })

export const {
    useAddPartLibrariesEntriesToQuoteMutation,
    useCreatePartLibraryEntriesMutation,
    useDeletePartLibraryEntryMutation,
    useGetPartLibraryEntriesQuery,
    useGetPartLibraryEntryQuotesQuery,
    useLazyGetPartLibraryEntriesQuery,
    useLazyGetPartLibraryEntryQuotesQuery,
    useUpdatePartLibraryEntryMutation,
} = partLibraryApi
