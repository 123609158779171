import { useCallback, useEffect } from 'react'
import { useTranslation } from 'react-i18next'
import { useSelector } from 'react-redux'
import { Box, Button, Container, Typography } from '@mui/material'
import PropTypes from 'prop-types'

import { selectCurrentUser } from '@/app/slices/appSlice'
import { selectOrganisation, selectUseImperialUnits } from '@/app/slices/organisationSlice'
import { useToolBoxTreatments } from '@/common/hooks'
import { getLanguageToUse } from '@/common/utils'

const pdf2QuoteUri = `${import.meta.env.VITE_PDF2QUOTE_URI}/?cnxname=${
    import.meta.env.VITE_PDF2QUOTE_CNXNAME
}&container=${import.meta.env.VITE_PDF2QUOTE_CONTAINER}&app=${import.meta.env.VITE_PDF2QUOTE_APPNAME}`

const classes = {
    container: {
        display: 'flex',
        flexDirection: 'column',
        height: '100%',
        backgroundColor: 'background.paper',
        border: '1px solid hsl(0, 0%, 15%)',
        boxSizing: 'border-box',
        overflow: 'hidden',
    },
    topBar: {
        borderBottom: '1px solid hsl(0, 0%, 20%)',
        boxSizing: 'border-box',
        height: 64,
        width: '100%',
        padding: 3,
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'space-between',
        backgroundColor: 'hsl(0, 0%, 15%)',
    },
    body: {
        display: 'flex',
        height: '100%',
        width: '100%',
        border: 0,
    },
    title: {
        textTransform: 'uppercase',
        color: 'text.primary',
    },
    buttonContainer: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'space-between',
    },
    button: {
        width: 100,
    },
}

const Pdf2Quote = ({ onCancelClicked, onSubmitClicked, quoteId }) => {
    const { t } = useTranslation()

    const { replaceSplinesWithLinearSegments } = useToolBoxTreatments()

    const organisation = useSelector(selectOrganisation)
    const useImperialUnits = useSelector(selectUseImperialUnits)

    const currentUser = useSelector(selectCurrentUser)

    useEffect(() => {
        const handleMessageFromPdfInterface = (pdfEvent) => {
            if (pdfEvent.origin !== import.meta.env.VITE_PDF2QUOTE_URI) {
                return
            }
            // TODO What is the data from this event?
            if (pdfEvent.data === 'pdfDone') {
                onSubmitClicked()
            }
        }
        window.addEventListener('message', handleMessageFromPdfInterface)

        return () => {
            window.removeEventListener('message', handleMessageFromPdfInterface)
        }
    }, [onSubmitClicked])

    const getPdfUploadUrl = useCallback(() => {
        return (
            pdf2QuoteUri +
            `&oid=${organisation.organisationId}&qid=${quoteId}&language=${getLanguageToUse(
                currentUser,
                organisation
            )}&units=${useImperialUnits ? 'in' : 'mm'}`
        )
    }, [quoteId, replaceSplinesWithLinearSegments, organisation, currentUser])

    return (
        <Container
            disableGutters={true}
            maxWidth={false}
            sx={classes.container}
        >
            <Box sx={classes.topBar}>
                <Typography
                    sx={classes.title}
                    variant="h5"
                >
                    {t('Add Parts from PDF')}
                </Typography>
                <Box sx={classes.buttonContainer}>
                    <Button
                        color="secondary"
                        sx={classes.button}
                        variant="contained"
                        onClick={onCancelClicked}
                    >
                        {t('Cancel')}
                    </Button>
                </Box>
            </Box>
            <iframe
                src={getPdfUploadUrl()}
                style={classes.body}
                title="pdfUpload"
            />
        </Container>
    )
}

Pdf2Quote.propTypes = {
    quoteId: PropTypes.string,
    onCancelClicked: PropTypes.func,
    onSubmitClicked: PropTypes.func,
}

export default Pdf2Quote
