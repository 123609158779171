import { webStoreApi } from './webStoreApi'

export const webStoreMaterialApi = webStoreApi
    .enhanceEndpoints({
        addTagTypes: ['WebStoreMaterials'],
    })
    .injectEndpoints({
        endpoints: (builder) => ({
            getMaterials: builder.query({
                query: ({ organisationId }) => `/${organisationId}/0/material-cutting-tech`,
                providesTags: ['WebStoreMaterials'],
            }),
        }),
    })

export const selectMaterials = (state, query) => {
    return webStoreApi.endpoints.getMaterials.select(query)(state)?.data ?? []
}

export const { useGetMaterialsQuery } = webStoreMaterialApi
