import {
    inchesToMm,
    mmToInches,
    numbersWithinPercentageTolerance,
    organisationDimensionTolerance,
    organisationLengthPrecision,
} from '../GeneralUtils/GeneralUtils'

const sheetVolume = (thickness, width, height, useImperialUnits) => {
    let result = 0.0

    if (!useImperialUnits) {
        thickness = thickness / 1000
        width = width / 1000
        height = height / 1000
    } else {
        thickness = thickness / 12
        width = width / 12
        height = height / 12
    }

    result = height * width * thickness

    return result
}

const rectangleVolume = (thickness, materialLength, width, height, useImperialUnits) => {
    let result = 0.0

    if (!useImperialUnits) {
        thickness = thickness / 1000
        materialLength = materialLength / 1000
        width = width / 1000
        height = height / 1000
    } else {
        thickness = thickness / 12
        materialLength = materialLength / 12
        width = width / 12
        height = height / 12
    }

    result = materialLength * (width * height - (width - 2 * thickness) * (height - 2 * thickness))

    return result
}

const circleVolume = (thickness, materialLength, diameter, useImperialUnits) => {
    let result = 0.0

    if (!useImperialUnits) {
        thickness = thickness / 1000
        materialLength = materialLength / 1000
        diameter = diameter / 1000
    } else {
        thickness = thickness / 12
        materialLength = materialLength / 12
        diameter = diameter / 12
    }

    result = materialLength * Math.PI * (Math.pow(diameter / 2, 2) - Math.pow((diameter - 2 * thickness) / 2, 2))

    return result
}

const ovalVolume = (thickness, materialLength, width, height, useImperialUnits) => {
    let result = 0.0
    if (!useImperialUnits) {
        thickness = thickness / 1000
        materialLength = materialLength / 1000
        width = width / 1000
        height = height / 1000
    } else {
        thickness = thickness / 12
        materialLength = materialLength / 12
        width = width / 12
        height = height / 12
    }

    result =
        materialLength *
        ((width - height) * 2 * thickness +
            Math.PI * (Math.pow(height / 2, 2) - Math.pow((height - 2 * thickness) / 2, 2)))

    return result
}

export const sheetWeight = ({ density, height, thickness, useImperialUnits, width }) => {
    return density * sheetVolume(thickness, width, height, useImperialUnits)
}

export const rectangleWeight = ({ density, height, materialLength, thickness, useImperialUnits, width }) => {
    return density * rectangleVolume(thickness, materialLength, width, height, useImperialUnits)
}

export const circleWeight = ({ density, diameter, materialLength, thickness, useImperialUnits }) => {
    return density * circleVolume(thickness, materialLength, diameter, useImperialUnits)
}

export const ovalWeight = ({ density, height, materialLength, thickness, useImperialUnits, width }) => {
    return density * ovalVolume(thickness, materialLength, width, height, useImperialUnits)
}

const materialWeight = (profile, density, thickness, materialLength, width, height, useImperialUnits, diameter) => {
    let mWeight = 0.0
    switch (profile) {
        case 'Rectangle':
        case 'Square':
            mWeight = rectangleWeight({
                density,
                thickness,
                materialLength,
                width,
                height,
                useImperialUnits,
            })
            break
        case 'Circle':
            mWeight = circleWeight({
                density,
                thickness,
                materialLength,
                diameter,
                useImperialUnits,
            })
            break
        case 'FlatSidedOval':
            mWeight = ovalWeight({
                density,
                thickness,
                materialLength,
                width,
                height,
                useImperialUnits,
            })
            break
        default:
            mWeight = sheetWeight({
                density,
                thickness,
                width,
                height,
                useImperialUnits,
            })
            break
    }
    return mWeight
}

export const calculateRatePrice = (
    val,
    profile,
    { density, diameter, height, materialLength, thickness, useImperialUnits, width }
) => {
    let result = 0.0

    if (profile === 'Square') {
        height = width
    }

    const mWeight = materialWeight(
        profile,
        density,
        thickness,
        materialLength,
        width,
        height,
        useImperialUnits,
        diameter
    )

    if (mWeight > 0) {
        result = val / mWeight
        result = parseFloat(result).toFixed(2)
    }
    return result
}

export const calculateCostPrice = (
    val,
    profile,
    { density, diameter, height, materialLength, thickness, useImperialUnits, width }
) => {
    let result = 0.0

    if (profile === 'Square') {
        height = width
    }

    const mWeight = materialWeight(
        profile,
        density,
        thickness,
        materialLength,
        width,
        height,
        useImperialUnits,
        diameter
    )

    if (mWeight > 0) {
        result = val * mWeight
        result = parseFloat(result).toFixed(2)
    }
    return result
}

export const getPartDimensions = (part) => {
    if (!part) return null
    return {
        profile: part.profile,
        displayBoundBoxHeight: part.displayBoundBoxHeight,
        displayBoundBoxWidth: part.displayBoundBoxWidth,
        rotaryProfileLength: part.rotaryProfileLength,
        rotaryProfileWidth: part.rotaryProfileWidth,
        diameter: part.diameter,
        isImperial: part.isImperial,
    }
}

export const getPartDimensionsDisplay = (part, dimensionFormat) => {
    if (!part || !part.profile || typeof dimensionFormat !== 'function') return null
    switch (part.profile.toLowerCase()) {
        case 'square':
            return part.rotaryProfileWidth
        case 'circle':
            return part.diameter
        default:
            return `${dimensionFormat(part.rotaryProfileWidth)} x ${dimensionFormat(part.rotaryProfileLength)}`
    }
}

export const getRecordDimensionsDisplay = (sheet, dimensionFormat) => {
    if (!sheet || !sheet.profile || typeof dimensionFormat !== 'function') return null
    switch (sheet.profile.toLowerCase()) {
        case 'square':
            return sheet.sheetWidth
        case 'circle':
            return sheet.diameter
        default:
            return `${dimensionFormat(sheet.sheetWidth)} x ${dimensionFormat(sheet.sheetHeight)}`
    }
}

export const isProfileSizeFit = (sheet, partDimensions, organisation) => {
    if (
        !sheet ||
        !partDimensions ||
        (sheet.profile && partDimensions.profile && sheet.profile !== partDimensions.profile)
    ) {
        return false
    }

    if (!sheet.profile) {
        //we check later if a flat part can fit on a sheet, currently we do not have the means
        //of letting a user know that their part wont fit on a sheet on the part details panel
        //this if statement can be removed when we handle this case better later
        return true
    }

    const orgUsesImperial = organisation.defaultDrawingUnits.toLowerCase() === 'imperial'
    const orgAndPartUnitsAreEqual = partDimensions.isImperial === orgUsesImperial

    const convertedPartDimensions = {
        rotaryProfileWidth:
            !partDimensions.rotaryProfileWidth || orgAndPartUnitsAreEqual
                ? partDimensions.rotaryProfileWidth
                : orgUsesImperial
                  ? mmToInches(partDimensions.rotaryProfileWidth)
                  : inchesToMm(partDimensions.rotaryProfileWidth),
        rotaryProfileLength:
            !partDimensions.rotaryProfileLength || orgAndPartUnitsAreEqual
                ? partDimensions.rotaryProfileLength
                : orgUsesImperial
                  ? mmToInches(partDimensions.rotaryProfileLength)
                  : inchesToMm(partDimensions.rotaryProfileLength),
        diameter:
            !partDimensions.diameter || orgAndPartUnitsAreEqual
                ? partDimensions.diameter
                : orgUsesImperial
                  ? mmToInches(partDimensions.diameter)
                  : inchesToMm(partDimensions.diameter),
        displayBoundBoxHeight:
            !partDimensions.displayBoundBoxHeight || orgAndPartUnitsAreEqual
                ? partDimensions.displayBoundBoxHeight
                : orgUsesImperial
                  ? mmToInches(partDimensions.displayBoundBoxHeight)
                  : inchesToMm(partDimensions.displayBoundBoxHeight),
        displayBoundBoxWidth:
            !partDimensions.displayBoundBoxWidth || orgAndPartUnitsAreEqual
                ? partDimensions.displayBoundBoxWidth
                : orgUsesImperial
                  ? mmToInches(partDimensions.displayBoundBoxWidth)
                  : inchesToMm(partDimensions.displayBoundBoxWidth),
    }

    const width = partDimensions.profile
        ? convertedPartDimensions.rotaryProfileWidth
        : convertedPartDimensions.displayBoundBoxWidth
    const height = partDimensions.profile
        ? convertedPartDimensions.rotaryProfileLength
        : convertedPartDimensions.displayBoundBoxHeight

    switch (sheet.profile?.toLowerCase()) {
        case 'square':
            return dimensionsSameWithTolerance(
                sheet.sheetWidth,
                convertedPartDimensions.rotaryProfileWidth,
                organisation,
                true
            )
        case 'circle':
            return dimensionsSameWithTolerance(sheet.diameter, convertedPartDimensions.diameter, organisation, true)
        case 'flatsidedoval':
            return (
                dimensionsSameWithTolerance(sheet.sheetWidth, width, organisation, true) &&
                dimensionsSameWithTolerance(sheet.sheetHeight, height, organisation, true)
            )
        case 'rectangle':
            return (
                (dimensionsSameWithTolerance(sheet.sheetWidth, width, organisation, true) &&
                    dimensionsSameWithTolerance(sheet.sheetHeight, height, organisation, true)) ||
                (dimensionsSameWithTolerance(sheet.sheetHeight, width, organisation, true) &&
                    dimensionsSameWithTolerance(sheet.sheetWidth, height, organisation, true))
            )
        default:
            return (
                partFitsWithTolerance(sheet.sheetHeight, width, organisation) &&
                partFitsWithTolerance(sheet.sheetWidth, height, organisation)
            )
    }
}

export const areProfileSizeEqual = (size1, size2) => {
    if (!size1 || !size1.profile || !size2 || !size2.profile) return true
    return (
        size1.rotaryProfileWidth === size2.rotaryProfileWidth &&
        size1.rotaryProfileLength === size2.rotaryProfileLength &&
        size1.diameter === size2.diameter &&
        size1.displayBoundBoxWidth === size2.displayBoundBoxWidth &&
        size1.displayBoundBoxHeight === size2.displayBoundBoxHeight
    )
}

export const thicknessSameWithTolerance = (
    sheet,
    partThickness,
    partDimensions,
    organisation,
    usePercentage = false
) => {
    const orgUsesImperial = organisation.defaultDrawingUnits.toLowerCase() === 'imperial'
    const orgAndPartUnitsAreEqual = partDimensions.isImperial === orgUsesImperial
    const convertedPartThickness = orgAndPartUnitsAreEqual
        ? partThickness
        : orgUsesImperial
          ? mmToInches(partThickness)
          : inchesToMm(partThickness)

    return dimensionsSameWithTolerance(sheet.thickness, convertedPartThickness, organisation, usePercentage)
}

const PROFILE_SIZE_TOLERANCE_PERCENTAGE = 2

const dimensionsSameWithTolerance = (sheetDimension, partDimension, organisation, usePercentage = false) => {
    const precision = organisationLengthPrecision(organisation)
    if (usePercentage) {
        return numbersWithinPercentageTolerance(
            partDimension?.toFixed(precision),
            sheetDimension?.toFixed(precision),
            PROFILE_SIZE_TOLERANCE_PERCENTAGE
        )
    } else {
        return (
            Math.abs(partDimension?.toFixed(precision) - sheetDimension?.toFixed(precision)) <=
            organisationDimensionTolerance(organisation)
        )
    }
}

const partFitsWithTolerance = (sheetDimension, partDimension, organisation) => {
    const precision = organisationLengthPrecision(organisation)
    return (
        partDimension?.toFixed(precision) - sheetDimension?.toFixed(precision) <=
        organisationDimensionTolerance(organisation)
    )
}
