import { Button, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle, Slide } from '@mui/material'
import { alpha } from '@mui/material/styles'
import PropTypes from 'prop-types'

const classes = {
    dialogBackdrop: {
        backgroundColor: (theme) => alpha(theme.palette.text.primary, 0.9),
    },
}

const TbxDialog = ({
    closeButtonText = 'Close',
    confirmButtonText = 'Confirm',
    content = 'Dialog Subtitle',
    direction = 'down',
    handleClose,
    handleConfirmClose,
    isLoading = false,
    isOpen = false,
    maxWidth = 'sm',
    title = 'Dialog Title',
}) => {
    return (
        <Dialog
            aria-describedby="alert-dialog-description"
            aria-labelledby="alert-dialog-title"
            BackdropProps={{
                sx: classes.dialogBackdrop,
            }}
            maxWidth={maxWidth}
            open={isOpen}
            TransitionComponent={Slide}
            TransitionProps={{ direction: direction }}
            fullWidth
            onClose={handleClose}
        >
            <DialogTitle id="alert-dialog-title">{title}</DialogTitle>
            <DialogContent>
                <DialogContentText id="alert-dialog-description">{content}</DialogContentText>
            </DialogContent>
            <DialogActions>
                <Button
                    color="secondary"
                    disabled={isLoading}
                    onClick={handleClose}
                >
                    {closeButtonText}
                </Button>
                <Button
                    color="primary"
                    disabled={isLoading}
                    onClick={handleConfirmClose}
                >
                    {confirmButtonText}
                </Button>
            </DialogActions>
        </Dialog>
    )
}

TbxDialog.propTypes = {
    children: PropTypes.node,
    closeButtonText: PropTypes.string,
    confirmButtonText: PropTypes.string,
    content: PropTypes.string,
    direction: PropTypes.string,
    handleClose: PropTypes.func,
    handleConfirmClose: PropTypes.func,
    isLoading: PropTypes.bool,
    isOpen: PropTypes.bool,
    maxWidth: PropTypes.oneOf(['xs', 'sm', 'md', 'lg', 'xl', false]),
    title: PropTypes.string,
}

export default TbxDialog
