import MuiDivider from '@mui/material/Divider'
import PropTypes from 'prop-types'

const Divider = ({
    absolute = false,
    flexItem = false,
    light = false,
    orientation = 'horizontal',
    variant = 'fullWidth',
    ...props
}) => {
    return (
        <MuiDivider
            absolute={absolute}
            flexItem={flexItem}
            light={light}
            orientation={orientation}
            variant={variant}
            {...props}
        />
    )
}

Divider.propTypes = {
    absolute: PropTypes.bool,
    classes: PropTypes.object,
    flexItem: PropTypes.bool,
    light: PropTypes.bool,
    orientation: PropTypes.oneOf(['horizontal', 'vertical']),
    variant: PropTypes.oneOf(['fullWidth', 'inset', 'middle']),
}

export default Divider
