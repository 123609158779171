import { createSlice } from '@reduxjs/toolkit'

import { customersApi } from '../services/customers'

const initialState = {
    customers: [],
    selectedCustomer: null,
    selectedCustomerId: null,
    showAddCustomerForm: false,
}

export const customersSlice = createSlice({
    name: 'customersSlice',
    initialState,
    reducers: {
        setCustomers: (state, action) => {
            state.customers = action.payload
        },
        setSelectedCustomer: (state, action) => {
            state.selectedCustomer = action.payload
        },
        setSelectedCustomerId: (state, action) => {
            state.selectedCustomerId = action.payload
        },
        setShowAddCustomerForm: (state, action) => {
            state.showAddCustomerForm = action.payload
        },
    },
    extraReducers: (builder) => {
        builder.addMatcher(customersApi.endpoints.getCustomers.matchFulfilled, (state, { payload }) => {
            state.customers = payload
        })
    },
})

export const selectCustomers = (state) => state.customersSlice.customers
export const selectSelectedCustomer = (state) => state.customersSlice.selectedCustomer
export const selectSelectedCustomerId = (state) => state.customersSlice.selectedCustomerId
export const selectShowAddCustomerForm = (state) => state.customersSlice.showAddCustomerForm

export const { setCustomers, setSelectedCustomer, setSelectedCustomerId, setShowAddCustomerForm } =
    customersSlice.actions

export default customersSlice.reducer
