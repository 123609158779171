const DEFAULT_CURRENCY = 'USD'
const DEFAULT_LOCALE = 'en-US'
const DEFAULT_DECIMAL_PLACES = 2

//export new regex
export const acceptedPatterns = {
    email: /^[^\s@]+@[^\s@]+\.[^\s@]{2,4}$/i,
}

export function inchesToMm(value) {
    return value * 25.4
}

export function mmToInches(value) {
    return value / 25.4
}

export function percentageToFraction(percentage) {
    return percentage != null ? parseFloat((percentage / 100).toFixed(4)) : undefined
}

export function fractionToPercentage(fraction) {
    return fraction != null ? parseFloat((fraction * 100).toFixed(4)) : undefined
}

export function formatNumber(value, locale = DEFAULT_LOCALE, useImperialUnits = null) {
    let decimalPlaces = DEFAULT_DECIMAL_PLACES

    if (useImperialUnits != null) {
        decimalPlaces = useImperialUnits ? 4 : 2
    }

    const formatter = new Intl.NumberFormat(locale, {
        style: 'decimal',
        unitDisplay: 'long',
        signDisplay: 'auto',
        useGrouping: true,
        minimumFractionDigits: decimalPlaces,
        maximumFractionDigits: decimalPlaces,
    })
    return formatter.format(value)
}

export function formatCurrency(
    value,
    currency = DEFAULT_CURRENCY,
    locale = DEFAULT_LOCALE,
    decimalPlaces = DEFAULT_DECIMAL_PLACES
) {
    const formatter = new Intl.NumberFormat(locale, {
        style: 'currency',
        currency: currency,
        unitDisplay: 'long',
        signDisplay: 'auto',
        useGrouping: true,
        minimumFractionDigits: decimalPlaces,
        maximumFractionDigits: decimalPlaces,
    })
    return formatter.format(value)
}

export function organisationIsImperial(defaultDrawingUnits) {
    return (
        defaultDrawingUnits?.localeCompare('imperial', undefined, {
            sensitivity: 'accent',
        }) === 0
    )
}

export function organisationDimensionTolerance(defaultDrawingUnits) {
    return organisationIsImperial(defaultDrawingUnits) ? 0.0001 : 0.01
}

export function organisationLengthPrecision(defaultDrawingUnits) {
    return organisationIsImperial(defaultDrawingUnits) ? 4 : 2
}

export function loadWebStoreFavicon(src) {
    const favicon = document.getElementById('favicon')
    favicon.href = src
}
