import { Controller, useForm } from 'react-hook-form'
import { useTranslation } from 'react-i18next'
import { useSelector } from 'react-redux'
import { CloseRounded } from '@mui/icons-material'
import {
    Box,
    Dialog,
    DialogActions,
    DialogContent,
    DialogTitle,
    IconButton,
    InputAdornment,
    TextField,
} from '@mui/material'
import Button from '@mui/material/Button'
import Typography from '@mui/material/Typography'
import { useSnackbar } from 'notistack'
import PropTypes from 'prop-types'

import {
    useCreateDeliveryContainerMutation,
    useUpdateDeliveryContainerMutation,
} from '@/app/services/deliveryContainers'
import { selectOrganisationId, selectUseImperialUnits } from '@/app/slices/organisationSlice'

const AddDeliveryContainerDialog = ({ container, onClose, open }) => {
    const { t } = useTranslation()
    const { enqueueSnackbar } = useSnackbar()

    const organisationId = useSelector(selectOrganisationId)
    const isImperial = useSelector(selectUseImperialUnits)

    const [createContainer, { isLoading: isCreating }] = useCreateDeliveryContainerMutation()
    const [updateContainer, { isLoading: isUpdating }] = useUpdateDeliveryContainerMutation()

    const weightUnit = isImperial ? 'lb' : 'kg'
    const lengthUnit = isImperial ? 'in' : 'mm'

    const {
        control,
        formState: { isDirty, isValid },
        handleSubmit,
        reset,
    } = useForm({
        mode: 'all',
        defaultValues: {
            name: container?.name ?? '',
            maximumWeight: container?.maximumWeight,
            maximumLength: container?.maximumLength,
            maximumWidth: container?.maximumWidth,
            maximumHeight: container?.maximumHeight,
            isDeleted: container?.isDeleted ?? false,
        },
    })

    const handleAddOrUpdateButtonClick = async (containerData, _e) => {
        // if container exists, update it instead of creating a new one
        if (container) {
            try {
                await updateContainer({
                    organisationId,
                    container: { ...container, ...containerData },
                }).unwrap()
                enqueueSnackbar(t('Delivery container updated successfully'), { variant: 'success' })
            } catch (error) {
                console.error(error)
                enqueueSnackbar(t('Failed to update delivery container'), { variant: 'error' })
            } finally {
                reset()
            }
        } else {
            try {
                await createContainer({
                    organisationId,
                    container: {
                        ...containerData,
                        isDeleted: false,
                    },
                }).unwrap()
                enqueueSnackbar(t('Delivery container added successfully'), { variant: 'success' })
            } catch (error) {
                console.error(error)
                enqueueSnackbar(t('Failed to add delivery container'), { variant: 'error' })
            } finally {
                reset()
            }
        }

        onClose()
    }

    const closeDialog = () => {
        reset()
        onClose()
    }

    return (
        <Dialog
            maxWidth="xs"
            open={open}
            sx={{
                '& .MuiDialog-paper': {
                    borderRadius: 2,
                    p: 2,
                    maxWidth: 'fit-content',
                },
            }}
            fullWidth
            onClose={closeDialog}
        >
            <DialogTitle
                alignItems="center"
                display="flex"
                justifyContent="space-between"
                sx={{ p: 2 }}
            >
                {container ? (
                    <Typography variant="h6">{t('Edit delivery container')}</Typography>
                ) : (
                    <Typography variant="h6">{t('Add delivery container')}</Typography>
                )}
                <IconButton
                    size="small"
                    onClick={closeDialog}
                >
                    <CloseRounded fontSize="small" />
                </IconButton>
            </DialogTitle>

            <DialogContent sx={{ p: 2 }}>
                <Box
                    display="flex"
                    flexDirection="column"
                    gap={2}
                    mb={2}
                >
                    <Controller
                        control={control}
                        name="name"
                        render={({ field: { onBlur, onChange, value }, fieldState }) => (
                            <TextField
                                error={fieldState.invalid}
                                helperText={fieldState.error?.message || ''}
                                label={t('Delivery container name')}
                                value={value}
                                autoFocus
                                fullWidth
                                required
                                onBlur={onBlur}
                                onChange={onChange}
                            />
                        )}
                        rules={{ required: 'Required' }}
                    />

                    <Controller
                        control={control}
                        name="maximumWeight"
                        render={({ field: { onBlur, onChange, value }, fieldState }) => (
                            <TextField
                                error={fieldState.invalid}
                                helperText={fieldState.error?.message || ''}
                                inputProps={{ sx: { textAlign: 'right' } }}
                                InputProps={{
                                    endAdornment: <InputAdornment position="end">{weightUnit}</InputAdornment>,
                                }}
                                label={t('Maximum weight')}
                                value={value}
                                fullWidth
                                onBlur={onBlur}
                                onChange={onChange}
                            />
                        )}
                        rules={{
                            pattern: {
                                value: /^\d+(\.\d{1,4})?$/,
                                message: 'Invalid value',
                            },
                        }}
                    />

                    <Controller
                        control={control}
                        name="maximumLength"
                        render={({ field: { onBlur, onChange, value }, fieldState }) => (
                            <TextField
                                error={fieldState.invalid}
                                helperText={fieldState.error?.message || ''}
                                inputProps={{ sx: { textAlign: 'right' } }}
                                InputProps={{
                                    endAdornment: <InputAdornment position="end">{lengthUnit}</InputAdornment>,
                                }}
                                label={t('Maximum length')}
                                value={value}
                                fullWidth
                                onBlur={onBlur}
                                onChange={onChange}
                            />
                        )}
                        rules={{
                            pattern: {
                                value: /^\d+(\.\d{1,4})?$/,
                                message: 'Invalid value',
                            },
                        }}
                    />

                    <Controller
                        control={control}
                        name="maximumWidth"
                        render={({ field: { onBlur, onChange, value }, fieldState }) => (
                            <TextField
                                error={fieldState.invalid}
                                helperText={fieldState.error?.message || ''}
                                inputProps={{ sx: { textAlign: 'right' } }}
                                InputProps={{
                                    endAdornment: <InputAdornment position="end">{lengthUnit}</InputAdornment>,
                                }}
                                label={t('Maximum width')}
                                value={value}
                                fullWidth
                                onBlur={onBlur}
                                onChange={onChange}
                            />
                        )}
                        rules={{
                            pattern: {
                                value: /^\d+(\.\d{1,4})?$/,
                                message: 'Invalid value',
                            },
                        }}
                    />

                    <Controller
                        control={control}
                        name="maximumHeight"
                        render={({ field: { onBlur, onChange, value }, fieldState }) => (
                            <TextField
                                error={fieldState.invalid}
                                helperText={fieldState.error?.message || ''}
                                inputProps={{ sx: { textAlign: 'right' } }}
                                InputProps={{
                                    endAdornment: <InputAdornment position="end">{lengthUnit}</InputAdornment>,
                                }}
                                label={t('Maximum height')}
                                value={value}
                                fullWidth
                                onBlur={onBlur}
                                onChange={onChange}
                            />
                        )}
                        rules={{
                            pattern: {
                                value: /^\d+(\.\d{1,4})?$/,
                                message: 'Invalid value',
                            },
                        }}
                    />
                </Box>
            </DialogContent>

            <DialogActions sx={{ p: 2 }}>
                <Button
                    color="secondary"
                    disabled={isCreating || isUpdating}
                    key="cancel"
                    variant="outlined"
                    onClick={closeDialog}
                >
                    {t('Cancel')}
                </Button>
                <Button
                    color="primary"
                    disabled={!isDirty || !isValid || isCreating || isUpdating}
                    key="add"
                    variant="contained"
                    onClick={handleSubmit(handleAddOrUpdateButtonClick)}
                >
                    {container
                        ? isUpdating
                            ? t('Updating...')
                            : t('Update container')
                        : isCreating
                          ? t('Adding...')
                          : t('Add container')}
                </Button>
            </DialogActions>
        </Dialog>
    )
}

AddDeliveryContainerDialog.defaultProps = {
    open: false,
}

AddDeliveryContainerDialog.propTypes = {
    container: PropTypes.object,
    open: PropTypes.bool,
    onClose: PropTypes.func,
}

export default AddDeliveryContainerDialog
