import _ from 'lodash'

import CustomError from '../Utilities/CustomError'

import * as EntityConstants from './EntityConstants'
import Layer from './Layer'
import Line from './Line'

export default class Arc {
    constructor(json) {
        this['$type'] = json['$type']
        this.layer = undefined
        this.isLineTypeSolid = undefined
        this.type = undefined
        this.startPoint = undefined
        this.endPoint = undefined
        this.centrePoint = undefined
        this.radius = undefined
        this.largeArcFlag = undefined
        this.sweepFlag = undefined

        const jsonValid =
            json &&
            Object.prototype.hasOwnProperty.call(json, 'layer') &&
            Object.prototype.hasOwnProperty.call(json, 'isLineTypeSolid') &&
            Object.prototype.hasOwnProperty.call(json, 'type') &&
            Object.prototype.hasOwnProperty.call(json, 'startPoint') &&
            Object.prototype.hasOwnProperty.call(json, 'endPoint') &&
            Object.prototype.hasOwnProperty.call(json, 'centrePoint') &&
            Object.prototype.hasOwnProperty.call(json, 'radius') &&
            Object.prototype.hasOwnProperty.call(json, 'largeArcFlag') &&
            Object.prototype.hasOwnProperty.call(json, 'sweepFlag')

        if (jsonValid) {
            _.extend(this, json)
            this.layer = new Layer(json.layer)
        } else {
            throw new CustomError('Invalid json supplied', 'InvalidJSONError')
        }
    }

    getSvgPathStr(lastPt) {
        // If this arc's radius exceeds MAX_ARC_RADIUS, render this as a Line() with the same start and endpoint instead.
        // This is done in order to avoid SVG rendering bugs observable in Chrome and Firefox when rendering paths with
        // certain characteristics.
        // e.g.
        // <path d="M -175 -238.47716 A 20549806 20549806 0 0 0 -174.99446 238.66647" />
        if (this.radius <= EntityConstants.MAX_ARC_RADIUS) {
            let pathStr = ''
            const lastPtMeetsStartPoint =
                lastPt &&
                Math.abs(lastPt.x - this.startPoint.x) < EntityConstants.POINT_COMPARISON_PRECISION &&
                Math.abs(lastPt.y - this.startPoint.y) < EntityConstants.POINT_COMPARISON_PRECISION

            if (!lastPt || !lastPtMeetsStartPoint) {
                pathStr += !lastPtMeetsStartPoint ? ' ' : ''
                pathStr += 'M' + this.startPoint.x + ' ' + this.startPoint.y
            }

            pathStr +=
                ' A' +
                this.radius +
                ' ' +
                this.radius +
                ' 0 ' +
                (this.largeArcFlag ? '1' : '0') +
                ' ' +
                (this.sweepFlag ? '1' : '0') +
                ' ' +
                this.endPoint.x +
                ' ' +
                this.endPoint.y
            return pathStr
        } else {
            const lineApprox = new Line({
                layer: this.layer,
                isLineTypeSolid: this.isLineTypeSolid,
                type: this.type,
                startPoint: this.startPoint,
                endPoint: this.endPoint,
            })
            lineApprox.startPoint = this.startPoint
            lineApprox.endPoint = this.endPoint
            return lineApprox.getSvgPathStr(lastPt)
        }
    }
}

Arc.ToolpathType = 'DtoArc'
