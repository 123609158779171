/* eslint-disable react/display-name */
import { useCallback, useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useSelector } from 'react-redux'
import {
    closestCenter,
    DndContext,
    DragOverlay,
    KeyboardSensor,
    PointerSensor,
    useSensor,
    useSensors,
} from '@dnd-kit/core'
import { restrictToVerticalAxis } from '@dnd-kit/modifiers'
import { arrayMove, SortableContext, sortableKeyboardCoordinates, verticalListSortingStrategy } from '@dnd-kit/sortable'
import { Box, LinearProgress, Table, TableBody, TableCell, TableHead, TableRow, Typography } from '@mui/material'
import { useSnackbar } from 'notistack'
import PropTypes from 'prop-types'

import {
    useDeleteSecondaryProcessMutation,
    useGetSecondaryProcessesQuery,
    useSortSecondaryProcessesMutation,
    useUpdateSecondaryProcessMutation,
} from '@/app/services/secondaryProcesses'
import { selectIsOnFreePlan, selectOrganisation, selectOrganisationId } from '@/app/slices/organisationSlice'
import { TbxShowToggle } from '@/common/components'

import ArchiveSecondaryProcessDialog from './ArchiveSecondaryProcessDialog/ArchiveSecondaryProcessDialog'
import SecondaryProcessesRow from './SecondaryProcessesRow'
import SecondaryProcessOverlay from './SecondaryProcessOverlay'

const classes = {
    root: {
        marginTop: '2rem',
    },
    table: {
        background: (theme) => theme.palette.background.paper,
        border: '1px solid rgba(52, 73, 94, 0.12)',
        borderBottom: 'none',
        boxSizing: 'border-box',
        borderRadius: '8px 8px 0 0',
        borderCollapse: 'separate',
    },
    headerTableCell: {
        padding: '12px 12px 6px 12px',
        verticalAlign: 'bottom',
    },

    titleSection: {
        display: 'flex',
        marginBottom: 2,
        alignItems: 'center',
        justifyContent: 'space-between',
    },
    tableLoader: {
        border: 'none',
    },
}

export const SecondaryProcessesTable = ({ onEditProcess }) => {
    const { t } = useTranslation()
    const { enqueueSnackbar } = useSnackbar()

    const [showArchived, setShowArchived] = useState(false)
    const [activeItemId, setActiveItemId] = useState(null)
    const [activeItemName, setActiveItemName] = useState(null)
    const [secondaryProcessesIds, setsecondaryProcessesIds] = useState([])
    const [openDialog, setOpenDialog] = useState(false)
    const [selectedProcess, setSelectedProcess] = useState(null)

    const organisation = useSelector(selectOrganisation)
    const organisationId = useSelector(selectOrganisationId)

    const {
        data: secondaryProcesses,
        isFetching,
        refetch,
    } = useGetSecondaryProcessesQuery({
        organisationId,
        requestParams: { includeDeleted: showArchived },
    })

    const isOnFreePlan = useSelector(selectIsOnFreePlan)
    const secondaryProcessCount = secondaryProcesses?.filter((sp) => !sp.isDeleted).length ?? 0

    const [updateSecondaryProcess, { isLoading: isUpdating }] = useUpdateSecondaryProcessMutation()
    const [deleteSecondaryProcess, { isLoading: isDeliting }] = useDeleteSecondaryProcessMutation()
    const [sortSecondaryProcesses, { isLoading: isSorting }] = useSortSecondaryProcessesMutation()

    const sensors = useSensors(
        useSensor(PointerSensor),
        useSensor(KeyboardSensor, {
            coordinateGetter: sortableKeyboardCoordinates,
        })
    )

    useEffect(() => {
        refetch()
    }, [showArchived])

    useEffect(() => {
        setsecondaryProcessesIds(secondaryProcesses?.map((process) => process.secondaryProcessId) ?? [])
    }, [secondaryProcesses])

    const editProcessClick = (process) => onEditProcess(process)

    const archiveProcessClick = useCallback((process) => {
        setSelectedProcess(process)
        setOpenDialog('DeleteProcess')
    }, [])

    const closeArchiveProcessDialogClick = useCallback(() => {
        setSelectedProcess(undefined)
        setOpenDialog(undefined)
    }, [])

    const archiveProcess = async () => {
        const maxIndex = Math.max(-1, ...(secondaryProcesses?.map((p) => p.index ?? -1) ?? []))

        try {
            if (selectedProcess.isDeleted) {
                const updatedProcess = structuredClone(selectedProcess)
                updatedProcess.isDeleted = false
                updatedProcess.index = maxIndex + 1

                await updateSecondaryProcess({ organisationId, secondaryProcess: updatedProcess }).unwrap()

                enqueueSnackbar(t('Secondary process unarchived'), { variant: 'success' })
            } else {
                await deleteSecondaryProcess({
                    organisationId,
                    secondaryProcessId: selectedProcess.secondaryProcessId,
                }).unwrap()

                enqueueSnackbar(t('Secondary process archived'), { variant: 'success' })
            }
        } catch (error) {
            enqueueSnackbar(t('An error occurred archiving secondary processes'), { variant: 'error' })
        } finally {
            closeArchiveProcessDialogClick()
        }
    }

    const handleShowArchivedChange = () => {
        setShowArchived(!showArchived)
    }

    const onDragStart = (event) => {
        const id = event.active.id
        setActiveItemId(id)

        const activeItem = secondaryProcesses?.find((item) => item.secondaryProcessId === id)
        setActiveItemName(activeItem.name)
    }

    const onDragEnd = useCallback(
        async (event) => {
            const { active, over } = event
            if (!active || !over) {
                return
            }

            if (active.id === over.id) {
                return
            }

            const oldIndex = active.data.current.sortable.index
            const newIndex = over.data.current.sortable.index

            const items = structuredClone(arrayMove(secondaryProcesses, oldIndex, newIndex))

            items.forEach((item, index) => {
                item.index = index
            })

            await sortSecondaryProcesses({
                organisationId,
                sortParams: {
                    sourceId: active.id,
                    destinationId: over.id,
                },
                items,
                showArchived,
            })
                .then(() => {
                    enqueueSnackbar(t('Secondary processes reordered'), { variant: 'success' })
                })
                .catch(() => {
                    enqueueSnackbar(t('Could not save changes to secondary process'), { variant: 'error' })
                })
        },
        [secondaryProcesses, organisation.organisationId]
    )

    return (
        <div style={{ marginBottom: '24px' }}>
            {openDialog === 'DeleteProcess' ? (
                <ArchiveSecondaryProcessDialog
                    mode={selectedProcess?.isDeleted ? 'unarchive' : 'archive'}
                    name={selectedProcess?.name}
                    onCancelClose={closeArchiveProcessDialogClick}
                    onConfirmClose={archiveProcess}
                />
            ) : null}
            <Box sx={classes.titleSection}>
                <Typography
                    data-testid="custom-secondary-processes-heading"
                    variant="h6"
                >
                    {t('Custom secondary processes')}
                </Typography>
                <TbxShowToggle
                    checked={showArchived}
                    label={t('Show archived processes')}
                    onChange={handleShowArchivedChange}
                />
            </Box>

            <DndContext
                collisionDetection={closestCenter}
                modifiers={[restrictToVerticalAxis]}
                sensors={sensors}
                onDragEnd={onDragEnd}
                onDragStart={onDragStart}
            >
                <Table
                    aria-label="secondary processes"
                    sx={classes.table}
                >
                    <colgroup>
                        <col width="240px" />
                        <col width="100px" />
                        <col width="100px" />
                        <col width="120px" />
                        <col width="160px" />
                        <col width="100px" />
                        <col width="120px" />
                        <col width="120px" />
                        <col width="130px" />
                        <col width="124px" />
                    </colgroup>
                    <TableHead>
                        <TableRow>
                            <TableCell
                                align="left"
                                data-testid="secondary-processes-name-header"
                                sx={classes.headerTableCell}
                            >
                                {t('Name')}
                            </TableCell>
                            <TableCell
                                align="right"
                                data-testid="secondary-processes-hourly-rate-header"
                                sx={classes.headerTableCell}
                            >
                                {t('Hourly Rate')}
                            </TableCell>
                            <TableCell
                                align="right"
                                data-testid="secondary-processes-setup-time-header"
                                sx={classes.headerTableCell}
                            >
                                {t('Setup Time')}
                            </TableCell>
                            <TableCell
                                align="left"
                                data-testid="secondary-processes-setup-mode-header"
                                sx={classes.headerTableCell}
                            >
                                {t('Setup Mode')}
                            </TableCell>
                            <TableCell
                                align="left"
                                data-testid="secondary-processes-measure-type-header"
                                sx={classes.headerTableCell}
                            >
                                {t('Measure Type')}
                            </TableCell>
                            <TableCell
                                align="right"
                                data-testid="secondary-processes-price-per-part-header"
                                sx={classes.headerTableCell}
                            >
                                {t('Price per Measure')}
                            </TableCell>
                            <TableCell
                                align="right"
                                data-testid="secondary-processes-time-per-part-header"
                                sx={classes.headerTableCell}
                            >
                                {t('Time per Part')}
                            </TableCell>
                            <TableCell
                                align="right"
                                data-testid="secondary-processes-time-per-measure-header"
                                sx={classes.headerTableCell}
                            >
                                {t('Time per Measure')}
                            </TableCell>
                            <TableCell
                                align="left"
                                data-testid="secondary-processes-customer-visible-header"
                                sx={classes.headerTableCell}
                            >
                                {t('Customer Visible')}
                            </TableCell>
                            <TableCell align="right"></TableCell>
                        </TableRow>
                    </TableHead>

                    <TableBody>
                        {isFetching || isUpdating || isDeliting || isSorting ? (
                            <TableRow>
                                <TableCell
                                    colSpan={100}
                                    sx={classes.tableLoader}
                                >
                                    <LinearProgress />
                                </TableCell>
                            </TableRow>
                        ) : null}
                        <SortableContext
                            items={secondaryProcessesIds}
                            strategy={verticalListSortingStrategy}
                        >
                            {secondaryProcesses?.map((secProcess) => {
                                return (
                                    <SecondaryProcessesRow
                                        archiveProcessClick={archiveProcessClick}
                                        editProcessClick={editProcessClick}
                                        id={secProcess.secondaryProcessId}
                                        index={secProcess.index}
                                        isImperial={organisation.defaultDrawingUnits === 'Imperial'}
                                        isOnFreePlan={isOnFreePlan}
                                        key={secProcess.secondaryProcessId}
                                        process={secProcess}
                                        secondaryProcessCount={secondaryProcessCount}
                                    />
                                )
                            })}
                        </SortableContext>
                        <DragOverlay wrapperElement="tr">
                            {activeItemId ? (
                                <SecondaryProcessOverlay
                                    id={activeItemId}
                                    name={activeItemName}
                                />
                            ) : null}
                        </DragOverlay>
                    </TableBody>
                </Table>
            </DndContext>
        </div>
    )
}

SecondaryProcessesTable.propTypes = {
    onEditProcess: PropTypes.func,
}
