import { useTranslation } from 'react-i18next'
import { Box, TextField } from '@mui/material'
import PropTypes from 'prop-types'

import DimensionList from './DimensionList/DimensionList'

const classes = {
    partName: {
        width: '100%',
    },
    specificationContainer: {
        padding: 2,
    },
}

const PartSpecificationPanel = ({
    dimensions,
    onDimensionChanged,
    onDimensionSelected,
    onPartNameChanged,
    partName,
    selectedDimension,
}) => {
    const { t } = useTranslation()

    const handlePartNameFocussed = (event) => {
        event.target.setSelectionRange(0, event.target.value.length)
    }

    const handlePartNameChanged = (event) => {
        if (typeof onPartNameChanged === 'function') {
            onPartNameChanged(event.target.value)
        }
    }

    return (
        <Box sx={classes.specificationContainer}>
            <TextField
                label={t('Part name')}
                sx={classes.partName}
                value={partName}
                variant="outlined"
                onChange={handlePartNameChanged}
                onFocus={handlePartNameFocussed}
            />
            <DimensionList
                dimensions={dimensions}
                selectedDimension={selectedDimension}
                onDimensionChanged={onDimensionChanged}
                onDimensionSelected={onDimensionSelected}
            />
        </Box>
    )
}

PartSpecificationPanel.propTypes = {
    dimensions: PropTypes.object,
    partName: PropTypes.string,
    selectedDimension: PropTypes.object,
    onDimensionChanged: PropTypes.func,
    onDimensionSelected: PropTypes.func,
    onPartNameChanged: PropTypes.func,
}

export default PartSpecificationPanel
