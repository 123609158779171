import { useEffect, useState } from 'react'
import { TextField } from '@mui/material'
import PropTypes from 'prop-types'
import { useDebounce } from 'use-debounce'

const LayerName = ({ handleUpdateLayerName, isDisabled, layerName }) => {
    const [itemLayerName, setItemLayerName] = useState(layerName ?? '')

    const handleNameChange = async (event) => {
        const value = event?.target?.value
        setItemLayerName(value)
    }

    const [debouncedItemLayerName] = useDebounce(itemLayerName, 1000)

    useEffect(() => {
        if (debouncedItemLayerName !== layerName) {
            handleUpdateLayerName(debouncedItemLayerName)
        }
    }, [debouncedItemLayerName])

    return (
        <TextField
            disabled={isDisabled}
            inputProps={{
                'data-testid': 'drawingdoctordrawer-layers-layername-input',
            }}
            size="small"
            sx={{ width: '100%' }}
            value={itemLayerName}
            variant="outlined"
            onBlur={handleNameChange}
            onChange={handleNameChange}
        />
    )
}

LayerName.propTypes = {
    handleUpdateLayerName: PropTypes.func,
    isDisabled: PropTypes.bool,
    layerName: PropTypes.string,
}

export default LayerName
