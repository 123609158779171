import { useCallback, useEffect, useState } from 'react'
import { unstable_batchedUpdates } from 'react-dom'
import { useTranslation } from 'react-i18next'
import { useDispatch, useSelector } from 'react-redux'
import { Outlet, useLocation, useNavigate } from 'react-router-dom'
import { EventType, InteractionStatus, InteractionType, PublicClientApplication } from '@azure/msal-browser'
import { MsalAuthenticationTemplate, useIsAuthenticated, useMsal } from '@azure/msal-react'
import { useAppInsightsContext, useTrackEvent } from '@microsoft/applicationinsights-react-js'
import { SplitClient, SplitFactoryProvider } from '@splitsoftware/splitio-react'
import _ from 'lodash'
import PropTypes from 'prop-types'

import { useCreateUserMutation, useGetOrCreateUserContextMutation } from '@/app/services/user'
import {
    selectAppIsLoading,
    selectCurrentUser,
    setAppIsLoading,
    setLanguage,
    setLocale,
    setUserRole,
} from '@/app/slices/appSlice'
import {
    selectOrganisation,
    selectPlanMetadata,
    setLastPaymentStatus,
    setOrganisation,
} from '@/app/slices/organisationSlice'
import { loginRequest, resetPasswordRequest } from '@/authConfig'
import { PageSpinner } from '@/common/components'
import useEffectOnlyOnUpdate from '@/common/hooks/useEffectOnlyOnUpdate'
import {
    AuthorisationUtils,
    getLanguageToUse,
    getLocaleToUse,
    getUserDtoFromMsalAccountInfo,
    initUserFlow,
    Paths,
    RESELLERS,
} from '@/common/utils'
import Layout from '@/layout/Layout'

import UserObserverContainer from './UserObserverContainer'

const noSubscriptionRequired = [
    Paths.ACTIVATE_PATHNAME,
    Paths.ACTIVATE_BILLING_DETAILS_PATHNAME,
    Paths.ACTIVATE_PAYMENT_DETAILS_PATHNAME,
    Paths.ERROR_PATHNAME,
    Paths.NO_ACTIVE_SUBSCRIPTION_PATHNAME,
]
const UserRequiredContainer = () => {
    const navigate = useNavigate()
    const location = useLocation()
    const dispatch = useDispatch()

    const appIsLoading = useSelector(selectAppIsLoading)

    const userIsAuthenticated = useIsAuthenticated()

    const [splitConfig, setSplitConfig] = useState({
        core: {
            authorizationKey: import.meta.env.VITE_SPLIT_APIKEY,
            key: 'anonymous',
        },
    })
    const { inProgress, instance: msalInstance } = useMsal()
    const appInsights = useAppInsightsContext()
    const { i18n } = useTranslation()

    const [createUser] = useCreateUserMutation()
    const [getOrCreateUserContext] = useGetOrCreateUserContextMutation()

    const organisation = useSelector(selectOrganisation)

    const currentUser = useSelector(selectCurrentUser)
    const planMetadata = useSelector(selectPlanMetadata)

    const trackRedirectToWelcome = useTrackEvent(appInsights, `User redirected to ${Paths.WELCOME_PATHNAME}`, {
        currentUser,
        organisation,
    })
    const trackRedirectToNoActiveSubscription = useTrackEvent(
        appInsights,
        `User redirected to ${Paths.NO_ACTIVE_SUBSCRIPTION_PATHNAME}`,
        {
            currentUser,
            organisation,
        }
    )
    const trackRedirectToProjects = useTrackEvent(appInsights, `User redirected to ${Paths.DASHBOARD_PATHNAME}`, {
        currentUser,
        organisation,
    })
    const trackNoRedirection = useTrackEvent(appInsights, 'User not redirected', {
        currentUser,
        organisation,
    })
    const trackRedirectToAdmin = useTrackEvent(appInsights, `User redirected to ${Paths.ADMINISTRATION}`, {
        currentUser,
        organisation,
    })

    useEffect(() => {
        const callbackId = msalInstance.addEventCallback((message) => {
            if (message.eventType === EventType.LOGIN_FAILURE && message.interactionType === InteractionType.Redirect) {
                if (message.error.errorMessage && message.error.errorMessage.indexOf('AADB2C90118') > -1) {
                    msalInstance.loginRedirect(resetPasswordRequest)
                } else {
                    msalInstance.loginRedirect()
                }
            }
        })

        return () => {
            if (callbackId) {
                msalInstance.removeEventCallback(callbackId)
            }
        }
    }, [msalInstance])

    const initSplit = useCallback(
        (user) => {
            if (user && !_.isEmpty(user)) {
                setSplitConfig((prevConfig) => ({
                    ...prevConfig,
                    core: {
                        ...prevConfig.core,
                        key: user.emailAddress,
                    },
                }))
            } else {
                setSplitConfig((prevConfig) => ({
                    ...prevConfig,
                    core: {
                        ...prevConfig.core,
                        key: 'anonymous',
                        trafficType: 'anonymous',
                    },
                }))
            }
        },
        [setSplitConfig]
    )

    const initUserAndOrg = useCallback(async () => {
        const msalUser = msalInstance.getActiveAccount()

        if (msalUser && (!currentUser || _.isEmpty(currentUser))) {
            let userResult = null
            let userError = null
            const userDto = getUserDtoFromMsalAccountInfo(msalUser)
            try {
                userResult = await getOrCreateUserContext({ user: userDto }).unwrap()
            } catch (error) {
                if ('status' in error) {
                    if (error.status === 404) {
                        try {
                            userResult = await createUser({ user: userDto }).unwrap()
                        } catch (error) {
                            userError = error
                        }
                    } else if (error.status === 401) {
                        await msalInstance.logoutRedirect()
                        return
                    } else {
                        userError = error
                    }
                } else {
                    userError = error
                }
            }

            if (userError || !userResult || !userResult.user) {
                if (userError?.status === 'FETCH_ERROR') {
                    navigate(Paths.ERROR_PATHNAME)
                    return
                }

                if (import.meta.env.VITE_ENV === 'development') {
                    console.error('An error occurred handling current user.')
                    console.error(userError)
                }

                await msalInstance.logoutRedirect()
                return
            }

            const appUser = userResult.user

            const selectedOrganisationId = userResult.selectedOrganisationId

            const organisationUser =
                appUser &&
                selectedOrganisationId &&
                Array.isArray(appUser.organisationUsers) &&
                appUser.organisationUsers.length &&
                appUser.organisationUsers.find((ou) => ou.organisationId === selectedOrganisationId)

            const selectedOrganisation =
                organisationUser && appUser.organisations.find((o) => o.organisationId === selectedOrganisationId)

            let redirectTo = ''

            if (appUser) {
                if (!selectedOrganisation) {
                    // New user, show them the user welcome
                    if (location.pathname !== Paths.WELCOME_PATHNAME) {
                        redirectTo = Paths.WELCOME_PATHNAME
                    }
                } else if (
                    !selectedOrganisation?.hasActiveSubscription &&
                    (!selectedOrganisation.trial || selectedOrganisation?.trial?.hasExpired) &&
                    !selectedOrganisation.freePlan
                ) {
                    // Organisation doesn't have an active subcription
                    redirectTo = Paths.NO_ACTIVE_SUBSCRIPTION_PATHNAME
                } else if (location.pathname === '/') {
                    redirectTo = Paths.DASHBOARD_PATHNAME
                }
            }

            unstable_batchedUpdates(() => {
                const lastPayment = userResult.lastPayment
                const localeToUse = getLocaleToUse(selectedOrganisation)
                const languageToUse = getLanguageToUse(appUser, selectedOrganisation)

                if (selectedOrganisation) {
                    dispatch(setOrganisation(selectedOrganisation))
                }

                const role = organisationUser && organisationUser.role
                if (role) {
                    dispatch(setUserRole(role.toLowerCase()))
                } else {
                    dispatch(setUserRole('user'))
                }

                if (lastPayment) {
                    dispatch(setLastPaymentStatus(lastPayment.status.toLowerCase()))
                } else {
                    dispatch(setLastPaymentStatus('unknown'))
                }
                i18n.changeLanguage(languageToUse)
                dispatch(setLanguage(languageToUse))
                dispatch(setLocale(localeToUse))

                initSplit(appUser)

                switch (redirectTo) {
                    case Paths.WELCOME_PATHNAME:
                        trackRedirectToWelcome({
                            appUser,
                            selectedOrganisation,
                        })

                        initUserFlow({
                            user: appUser,
                            organisation: selectedOrganisation,
                            localeToUse: localeToUse,
                            language: languageToUse,
                            planMetadata,
                            reseller: import.meta.env.VITE_RESELLER
                                ? RESELLERS[import.meta.env.VITE_RESELLER].name
                                : undefined,
                        })
                        dispatch(setAppIsLoading(false))
                        navigate(Paths.WELCOME_PATHNAME)
                        break
                    case Paths.NO_ACTIVE_SUBSCRIPTION_PATHNAME:
                        if (
                            location.pathname === Paths.ADMINISTRATION &&
                            AuthorisationUtils.isSiteAdminOrSupport(msalInstance.getActiveAccount())
                        ) {
                            trackRedirectToAdmin({
                                appUser,
                                selectedOrganisation,
                            })

                            dispatch(setAppIsLoading(false))
                            navigate(Paths.ADMINISTRATION)
                            break
                        }

                        if (!noSubscriptionRequired.includes(location.pathname)) {
                            trackRedirectToNoActiveSubscription({
                                appUser,
                                selectedOrganisation,
                            })

                            dispatch(setAppIsLoading(false))
                            navigate(Paths.NO_ACTIVE_SUBSCRIPTION_PATHNAME)
                            break
                        }
                        break
                    case Paths.DASHBOARD_PATHNAME:
                        trackRedirectToProjects({
                            appUser,
                            selectedOrganisation,
                        })

                        initUserFlow({
                            user: appUser,
                            organisation: selectedOrganisation,
                            localeToUse: localeToUse,
                            language: languageToUse,
                            planMetadata,
                            reseller: import.meta.env.VITE_RESELLER
                                ? RESELLERS[import.meta.env.VITE_RESELLER].name
                                : undefined,
                        })
                        dispatch(setAppIsLoading(false))
                        navigate(Paths.DASHBOARD_PATHNAME)
                        break
                }

                initUserFlow({
                    user: appUser,
                    organisation: selectedOrganisation,
                    localeToUse: localeToUse,
                    language: languageToUse,
                    planMetadata,
                    reseller: import.meta.env.VITE_RESELLER ? RESELLERS[import.meta.env.VITE_RESELLER].name : undefined,
                })
                trackNoRedirection({ appUser })
                dispatch(setAppIsLoading(false))
            })
        }
    }, [navigate, location, currentUser, msalInstance, planMetadata])

    useEffect(() => {
        // Update UI or interact with EventMessage here
        if (inProgress === InteractionStatus.None) {
            initUserAndOrg()
        }
    }, [inProgress])

    useEffect(() => {
        if (organisation && currentUser && userIsAuthenticated && splitConfig && !_.isEmpty(splitConfig)) {
            unstable_batchedUpdates(() => {
                const languageToUse = getLanguageToUse(currentUser, organisation)
                const localeToUse = getLocaleToUse(organisation)
                i18n.changeLanguage(languageToUse)
                dispatch(setLanguage(languageToUse))
                dispatch(setLocale(localeToUse))

                initSplit(currentUser)
            })
        }
    }, [organisation, currentUser, userIsAuthenticated])

    useEffectOnlyOnUpdate(() => {
        if (organisation && currentUser && userIsAuthenticated && !noSubscriptionRequired.includes(location.pathname)) {
            const localeToUse = getLocaleToUse(organisation)
            const languageToUse = getLanguageToUse(currentUser, organisation)
            initUserFlow({
                user: currentUser,
                organisation: organisation,
                localeToUse: localeToUse,
                language: languageToUse,
                planMetadata,
                reseller: import.meta.env.VITE_RESELLER ? RESELLERS[import.meta.env.VITE_RESELLER].name : undefined,
            })
        }
    }, [organisation, currentUser, userIsAuthenticated, location.pathname])

    return (
        <MsalAuthenticationTemplate
            authenticationRequest={loginRequest}
            errorComponent={PageSpinner}
            interactionType={InteractionType.Redirect}
            loadingComponent={PageSpinner}
        >
            {appIsLoading ? (
                <PageSpinner />
            ) : (
                <SplitFactoryProvider config={splitConfig}>
                    <SplitClient splitKey={currentUser ? currentUser?.emailAddress : undefined}>
                        <UserObserverContainer>
                            <Layout>
                                <Outlet />
                            </Layout>
                        </UserObserverContainer>
                    </SplitClient>
                </SplitFactoryProvider>
            )}
        </MsalAuthenticationTemplate>
    )
}

UserRequiredContainer.propTypes = {
    msalInstance: PropTypes.instanceOf(PublicClientApplication),
}

export default UserRequiredContainer
