import { useState } from 'react'
import { Controller, useForm } from 'react-hook-form'
import { useTranslation } from 'react-i18next'
import { NumericFormat } from 'react-number-format'
import { useDispatch, useSelector } from 'react-redux'
import { Box, Button, Card, CardActions, CardContent, CircularProgress, TextField, Typography } from '@mui/material'
import { useSnackbar } from 'notistack'
import PropTypes from 'prop-types'

import { useUpdateCustomerMutation } from '@/app/services/customers'
import { selectLocale } from '@/app/slices/appSlice'
import { setSelectedCustomerId } from '@/app/slices/customersSlice'
import { selectIsOnFreePlan, selectOrganisation, selectOrganisationId } from '@/app/slices/organisationSlice'
import { fractionToPercentage, getNumberFormat, percentageToFraction } from '@/common/utils'

const classes = {
    centeredContent: {
        width: '100%',
        height: '100%',
        boxSizing: 'border-box',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
    },
    root: {
        display: 'flex',
        flexDirection: 'column',
    },
    alignEnd: {
        textAlign: 'right',
    },
    errorLabel: {
        color: 'error.main',
    },
    fieldLabel: {
        fontWeight: 500,
    },
    card: {
        width: 300,
        marginBottom: 3,
    },
    cardActions: {
        padding: '0px 8px 24px 0',
    },
    cardContent: {
        padding: 3,
    },
    cardTitle: {
        color: 'text.primary',
        fontSize: '1.3rem',
        fontWeight: 500,
        lineHeight: '24px',
        paddingBottom: 3,
    },
    input: {
        marginBottom: 3,
    },
}

const CustomerPricingTab = ({ customer }) => {
    const { t } = useTranslation()
    const { enqueueSnackbar } = useSnackbar()

    const dispatch = useDispatch()

    const organisation = useSelector(selectOrganisation)
    const organisationId = useSelector(selectOrganisationId)
    const isOnFreePlan = useSelector(selectIsOnFreePlan)
    const locale = useSelector(selectLocale)

    const [updateCustomer] = useUpdateCustomerMutation()

    const [isLoading, setIsLoading] = useState(false)

    const {
        control,
        formState: { isDirty, isValid },
        handleSubmit,
        reset,
        //setValue,
    } = useForm({
        mode: 'all',
        defaultValues: {
            customerId: customer?.customerId,
            cuttingRateDiscountPercentage: fractionToPercentage(customer?.cuttingRateDiscountPercentage),
            sheetChangeTimeSeconds: customer?.sheetChangeTimeSeconds,
            setupTimeSeconds: customer?.setupTimeSeconds,
        },
    })

    const numberFormat = getNumberFormat(locale)
    const decimalPlaces = numberFormat['decimalPlaces']
    const groupSeparator = numberFormat['group']
    const decimalSeparator = groupSeparator === '.' ? ',' : '.'

    const handleResetClick = () => {
        reset({
            cuttingRateDiscountPercentage: fractionToPercentage(customer?.cuttingRateDiscountPercentage),
            setupTimeSeconds: customer?.setupTimeSeconds,
            sheetChangeTimeSeconds: customer?.sheetChangeTimeSeconds,
        })
    }

    const handleUpdateClick = async (data) => {
        setIsLoading(true)
        try {
            const updatedCustomer = await updateCustomer({
                organisationId,
                customer: {
                    ...customer,
                    ...{
                        organisationId: organisation.organisationId,
                        cuttingRateDiscountPercentage: data.cuttingRateDiscountPercentage
                            ? percentageToFraction(data.cuttingRateDiscountPercentage)
                            : null,
                        setupTimeSeconds: data.setupTimeSeconds,
                        sheetChangeTimeSeconds: data.sheetChangeTimeSeconds,
                    },
                },
            }).unwrap()

            dispatch(setSelectedCustomerId(updatedCustomer.customerId))

            enqueueSnackbar(t('Customer pricing settings updated'), {
                variant: 'success',
            })

            reset({ ...data })
        } catch (error) {
            enqueueSnackbar(t('$t(An error occurred) updating customer pricing settings.'), {
                variant: 'error',
            })
        } finally {
            setIsLoading(false)
        }
    }

    const editingDisabled = () => customer?.isDeleted ?? true

    return (
        <Box sx={classes.root}>
            {!customer ? (
                <Box sx={classes.centeredContent}>
                    <Typography
                        sx={classes.errorLabel}
                        variant="body1"
                    >
                        {t('$t(An error occurred) loading customer pricing settings')}
                    </Typography>
                </Box>
            ) : (
                <form onSubmit={handleSubmit(handleUpdateClick)}>
                    <CardActions sx={classes.cardActions}>
                        {isLoading ? (
                            <CircularProgress />
                        ) : (
                            <>
                                <Button
                                    color="primary"
                                    disabled={!isDirty || editingDisabled() || isOnFreePlan}
                                    sx={classes.updateButton}
                                    variant="contained"
                                    onClick={handleResetClick}
                                >
                                    {t('Reset')}
                                </Button>
                                <Button
                                    color="primary"
                                    disabled={!isDirty || !isValid || editingDisabled() || isOnFreePlan}
                                    sx={classes.updateButton}
                                    type="submit"
                                    variant="contained"
                                >
                                    {t('Save')}
                                </Button>
                            </>
                        )}
                    </CardActions>

                    <Box
                        display="flex"
                        gap={2}
                    >
                        <Card sx={classes.card}>
                            <CardContent sx={classes.cardContent}>
                                <Typography sx={classes.cardTitle}>{t('Cutting')}</Typography>
                                <Controller
                                    control={control}
                                    name="cuttingRateDiscountPercentage"
                                    render={({ field: { onChange, value }, fieldState }) => (
                                        <NumericFormat
                                            allowNegative={true}
                                            customInput={TextField}
                                            decimalScale={decimalPlaces}
                                            decimalSeparator={decimalSeparator}
                                            disabled={editingDisabled() || isOnFreePlan}
                                            error={fieldState.invalid}
                                            helperText={
                                                fieldState.error?.message ??
                                                t('Note: negative values will increase cutting rate.')
                                            }
                                            InputLabelProps={{
                                                'data-testid': 'cutting-rate-discount-input-label',
                                                sx: classes.fieldLabel,
                                                shrink: true,
                                            }}
                                            inputProps={{
                                                'data-testid': 'cutting-rate-discount-input',
                                                style: { textAlign: 'right' },
                                            }}
                                            label={t('Cutting rate discount')}
                                            placeholder={'0%'}
                                            suffix="%"
                                            sx={classes.input}
                                            thousandSeparator={groupSeparator}
                                            value={value}
                                            onValueChange={({ floatValue }) => onChange(floatValue)}
                                        />
                                    )}
                                    rules={{
                                        min: -1000000,
                                        max: 100,
                                    }}
                                />
                                <Controller
                                    control={control}
                                    name="setupTimeSeconds"
                                    render={({ field: { onChange, value }, fieldState }) => (
                                        <NumericFormat
                                            allowNegative={false}
                                            customInput={TextField}
                                            decimalScale={0}
                                            decimalSeparator={decimalSeparator}
                                            disabled={editingDisabled() || isOnFreePlan}
                                            error={fieldState.invalid}
                                            helperText={fieldState.error?.message || ' '}
                                            InputLabelProps={{
                                                'data-testid': 'setup-time-input-label',
                                                sx: classes.fieldLabel,
                                                shrink: true,
                                            }}
                                            inputProps={{
                                                'data-testid': 'setup-time-input',
                                                style: { textAlign: 'right' },
                                            }}
                                            label={t('Setup time override')}
                                            placeholder={t('Default')}
                                            suffix={t(' s')}
                                            thousandSeparator={groupSeparator}
                                            value={value}
                                            fullWidth
                                            onValueChange={({ floatValue }) => onChange(floatValue)}
                                        />
                                    )}
                                    rules={{
                                        min: 0,
                                        max: 1000000,
                                    }}
                                />
                                <Controller
                                    control={control}
                                    name="sheetChangeTimeSeconds"
                                    render={({ field: { onChange, value }, fieldState }) => (
                                        <NumericFormat
                                            allowNegative={false}
                                            customInput={TextField}
                                            decimalScale={0}
                                            decimalSeparator={decimalSeparator}
                                            disabled={editingDisabled() || isOnFreePlan}
                                            error={fieldState.invalid}
                                            helperText={fieldState.error?.message || ' '}
                                            InputLabelProps={{
                                                'data-testid': 'sheet-change-time-input-label',
                                                sx: classes.fieldLabel,
                                                shrink: true,
                                            }}
                                            inputProps={{
                                                'data-testid': 'sheet-change-time-input',
                                                style: { textAlign: 'right' },
                                            }}
                                            label={t('Sheet change time override')}
                                            placeholder={t('Default')}
                                            suffix={t(' s')}
                                            thousandSeparator={groupSeparator}
                                            value={value}
                                            fullWidth
                                            onValueChange={({ floatValue }) => onChange(floatValue)}
                                        />
                                    )}
                                    rules={{
                                        min: 0,
                                        max: 1000000,
                                    }}
                                />
                            </CardContent>
                        </Card>
                    </Box>
                </form>
            )}
        </Box>
    )
}

CustomerPricingTab.propTypes = {
    customer: PropTypes.object.isRequired,
    organisation: PropTypes.object,
}

export default CustomerPricingTab
