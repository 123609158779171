import { useTranslation } from 'react-i18next'
import { Delete } from '@mui/icons-material'
import { Box, CircularProgress, Toolbar as MuiToolbar, Typography } from '@mui/material'
import IconButton from '@mui/material/IconButton'
import { lighten } from '@mui/material/styles'
import Tooltip from '@mui/material/Tooltip'
import PropTypes from 'prop-types'

const classes = {
    root: (showDivider) => ({
        paddingLeft: 2,
        paddingRight: 2,
        borderWidth: showDivider ? 1 : 0,
        borderBottomStyle: showDivider ? 'solid' : 'none',
        borderColor: (theme) => theme.palette.divider,
    }),
    highlight: (theme) =>
        theme.palette.mode === 'light'
            ? {
                  color: theme.palette.secondary.main,
                  backgroundColor: lighten(theme.palette.secondary.light, 0.85),
              }
            : {
                  color: theme.palette.text.primary,
                  backgroundColor: theme.palette.secondary.dark,
              },
    title: (titleTransform) => {
        let textTransform = 'none'

        if (titleTransform === 'uppercase') {
            textTransform = 'uppercase'
        } else if (titleTransform === 'lowercase') {
            textTransform = 'lowercase'
        }

        return {
            flex: '1 1 0%',
            textTransform: textTransform,
            color: 'text.primary',
        }
    },
    selection: {
        flex: '5 1 0%',
    },
    loadingContainer: {
        flex: '1 1 0%',
        marginRight: 2,
    },
}

const TableToolbar = ({
    actionPanel,
    allowMultiRowDelete,
    isLoading = false,
    loadingComponent = <CircularProgress />,
    numberOfRowsSelected,
    onDelete,
    selectionComponent,
    showDivider = false,
    showSelectionComponent = true,
    style,
    title,
    titleTransform = 'default',
}) => {
    const { t } = useTranslation()

    const handleDeleteClicked = () => {
        if (typeof onDelete === 'function') {
            onDelete()
        }
    }
    const showSelection = showSelectionComponent && numberOfRowsSelected > 0

    const toolbarClass = [classes.root(showDivider), showSelection && classes.highlight]

    return (
        <MuiToolbar
            style={style}
            sx={toolbarClass}
        >
            {isLoading ? <Box sx={classes.loadingContainer}>{loadingComponent}</Box> : null}
            {showSelection ? (
                selectionComponent ? (
                    <Box sx={classes.selection}>{selectionComponent}</Box>
                ) : (
                    <Typography
                        color="inherit"
                        component="div"
                        sx={classes.selection}
                        variant="subtitle1"
                    >
                        {numberOfRowsSelected + ' ' + t('selected')}
                    </Typography>
                )
            ) : (
                !isLoading && (
                    <Typography
                        component="div"
                        id="tableTitle"
                        sx={classes.title(titleTransform)}
                        variant="h6"
                    >
                        {title}
                    </Typography>
                )
            )}
            {allowMultiRowDelete && numberOfRowsSelected > 0 ? <Tooltip title={t('Delete')}>
                    <IconButton
                        aria-label="delete"
                        size="large"
                        onClick={handleDeleteClicked}
                    >
                        <Delete />
                    </IconButton>
                </Tooltip> : null}
            {actionPanel}
        </MuiToolbar>
    )
}

TableToolbar.propTypes = {
    actionPanel: PropTypes.node,
    allowMultiRowDelete: PropTypes.bool,
    isLoading: PropTypes.bool,
    loadingComponent: PropTypes.node,
    numberOfRowsSelected: PropTypes.number,
    selectionComponent: PropTypes.node,
    showDivider: PropTypes.bool,
    showSelectionComponent: PropTypes.bool,
    style: PropTypes.object,
    title: PropTypes.string,
    titleTransform: PropTypes.oneOf(['default', 'uppercase', 'lowercase']),
    onDelete: PropTypes.func,
}

export default TableToolbar
