import { formatNumber } from '@/common/helpers/formatUtilities'

import { PRODUCT_DETAILS, RESELLERS } from '../Constants/Constants'

// Get current date in string format
export function currentDateTimeString() {
    const now = new Date()

    return (
        now.getFullYear() +
        String(now.getMonth() + 1).padStart(2, '0') +
        String(now.getDate()).padStart(2, '0') +
        now.getHours() +
        now.getMinutes()
    )
}

export function stringIsEmptyOrWhitespace(str) {
    return typeof str === 'undefined' || str === null || str.trim() === ''
}

export function getCookieByName(cname) {
    const name = cname + '='
    const decodedCookie = decodeURIComponent(document.cookie)
    const ca = decodedCookie.split(';')
    for (let i = 0; i < ca.length; i++) {
        let c = ca[i]
        while (c.charAt(0) === ' ') {
            c = c.substring(1)
        }
        if (c.indexOf(name) === 0) {
            return c.substring(name.length, c.length)
        }
    }
    return null
}

export function inchesToMm(value) {
    return value * 25.4
}

export function mmToInches(value) {
    return value / 25.4
}

export const canQuoteItemFitOnSheet = (quoteItem, organisationDrawingUnits) =>
    quoteItem &&
    quoteItem.minimumBoundBoxWidth &&
    canPartFitOnSheet(
        {
            drawing: {
                width: quoteItem.minimumBoundBoxWidth,
                height: quoteItem.minimumBoundBoxHeight,
            },
            isImperial: quoteItem.isImperial,
            material: {
                sheetSize: {
                    width: quoteItem.sheet?.sheetWidth,
                    height: quoteItem.sheet?.sheetHeight,
                },
            },
        },
        organisationDrawingUnits,
        quoteItem.profile
    )

export function canPartFitOnSheet(
    {
        drawing: { height: partHeight = 0, width: partWidth = 0 },
        isImperial: partIsImperial,
        material: {
            sheetSize = {
                width: 0,
                height: 0,
            },
        },
    },
    organisationDrawingUnits,
    isRotary
) {
    if (isRotary) {
        return true
    }
    const convertedSheetSize = convertSheetSize(
        sheetSize,
        partIsImperial,
        organisationDrawingUnits?.toLowerCase() === 'imperial'
    )

    const largestPartSide = Math.max(partWidth, partHeight)
    const smallestPartSide = Math.min(partWidth, partHeight)
    const largestSheetSide = Math.max(convertedSheetSize?.width, convertedSheetSize?.height)
    const smallestSheetSide = Math.min(convertedSheetSize?.width, convertedSheetSize?.height)

    const canFitOnSheetNoRotation = largestPartSide <= largestSheetSide && smallestPartSide <= smallestSheetSide
    let canFitOnSheet = canFitOnSheetNoRotation
    if (!canFitOnSheetNoRotation) {
        canFitOnSheet = partCanFitWithRotation(largestSheetSide, smallestSheetSide, largestPartSide, smallestPartSide)
    }

    return canFitOnSheet
}

export function partCanFitWithRotation(a, b, p, q) {
    // https://stackoverflow.com/questions/13784274/detect-if-one-rect-can-be-put-into-another-rect
    // based off this paper https://www.jstor.org/stable/2691523
    // formula to calculate if a smaller rectangle can fit in a larger one for a larger rectangle with sides q and p, And a smaller rectangle a and b

    const canFitOnSheet =
        q <= b && (p <= a || b * (p * p + q * q) >= 2 * p * q * a + (p * p - q * q) * Math.sqrt(p * p + q * q - a * a))
    return canFitOnSheet
}

export function convertSheetSize({ height, width }, partIsImperial, sheetIsImperial) {
    const resultSheetSize = { width, height }
    // if both sheet and part are using same unit of measure then no conversion needed
    if (partIsImperial !== sheetIsImperial) {
        if (partIsImperial) {
            // part is imperial, convert sheet to imperial
            resultSheetSize.width = mmToInches(width)
            resultSheetSize.height = mmToInches(height)
        } else {
            // part is metric, convert sheet to metric
            resultSheetSize.width = inchesToMm(width)
            resultSheetSize.height = inchesToMm(height)
        }
    }

    return resultSheetSize
}

export function rowHasErrors(rowData, organisationDrawingUnits) {
    const materialError = !rowData.material
    const quantityError = rowData.quantity <= 0
    const sizeError = rowData.material ? !canPartFitOnSheet(rowData, organisationDrawingUnits) : true
    // TODO Use of rowData.calculationErrors should be changed
    // and calculationErrors should come through from the issue list instead
    return materialError || quantityError || sizeError || rowData.calculationErrors?.length > 0
}

export function quoteItemHasErrors(quoteItem, organisationDrawingUnits, issueSeverityDictionary) {
    const drawingError = hasSevereIssues(quoteItem.drawingMetadata?.issues, issueSeverityDictionary)
    const materialError =
        !quoteItem.cuttingTechnologyId || !quoteItem.materialId || !quoteItem.sheetId || !quoteItem.thickness
    const quantityError = quoteItem.quantity <= 0
    const sizeError = !materialError ? !canQuoteItemFitOnSheet(quoteItem, organisationDrawingUnits) : true
    // TODO Use of quoteItem.calculationErrors should be changed
    // and calculationErrors should come through from the issue list instead
    return materialError || quantityError || sizeError
}

const issueSeverity = {
    issue: 0,
    warning: 1,
}

export function hasSevereIssues(partIssues, issueSeverityDictionary) {
    return (
        partIssues &&
        issueSeverityDictionary &&
        partIssues.some((i) => issueSeverityDictionary[i.issueType] === issueSeverity.issue)
    )
}

export function sortedParts(parts, organisationDrawingUnits, issueSeverityDictionary) {
    function sortPartsByStatus(part1, part2) {
        const part1Status = hasSevereIssues(part1.drawing?.issues, issueSeverityDictionary)
            ? 0
            : part1.drawing?.issues.length > 0
              ? 1
              : rowHasErrors(part1, organisationDrawingUnits)
                ? 2
                : 3

        const part2Status = hasSevereIssues(part2.drawing?.issues, issueSeverityDictionary)
            ? 0
            : part2.drawing?.issues.length > 0
              ? 1
              : rowHasErrors(part2, organisationDrawingUnits)
                ? 2
                : 3

        return part1Status < part2Status ? -1 : part1Status === part2Status ? 0 : 1
    }

    return parts.sort(sortPartsByStatus)
}

export function hasSevereValidationIssues(qi) {
    return (
        Array.isArray(qi.quoteItemMetadata?.quoteItemValidationIssues) &&
        qi.quoteItemMetadata?.quoteItemValidationIssues.length > 0 &&
        qi.quoteItemMetadata?.quoteItemValidationIssues?.some((i) => i.severity === 'Error')
    )
}

export const getMessageForValidationIssue = (issue) => {
    switch (issue.type) {
        case 'ParametersInvalid':
            return 'Cutting time cannot be calculated'
        case 'CuttingTechnologyProfileConstraints':
            return 'Material length exceeds cutting technology size constraints'
        case 'CuttingTechnologyConstraints':
            return 'Material size exceeds cutting technology size constraints'
        case 'SheetConstraints':
            return 'Part cannot fit on material'
        case 'NumberOfPartsOrRuntimeInvalid':
            return 'Part nesting or cutting time cannot be calculated'
        case 'SheetWithWebConstraints':
            return 'Part with web cannot fit on material'
        case 'SheetWithWebAndChuckAllowanceConstraints':
            return 'Part with chuck allowance cannot fit on material'
        case 'NoCuttingTechnologySelected':
            return 'Cutting Technology is required'
        case 'NoSheetSelected':
            return 'Sheet is required'
        case 'SheetExpired':
            return 'Selected sheet has expired'
        case 'NoMatchingRate':
            return 'No rate entry found for sheet selection. Check rate table.'
        case 'HasSmallHoles':
            return 'Part contains profiles too small to process'
        case 'NoMaterialSelected':
            return 'Material is required'
        case 'NoThicknessSelected':
            return 'Thickness is required'
        default:
            return issue.message
    }
}

export function sheetCanBeCut(sheet, cuttingTech, rateTable, rates) {
    const errorOutput = { errorMessage: '' }
    const canBeCut =
        sheetHasRateTable(rateTable, errorOutput) &&
        sheetCanBeUsedWithCuttingTech(sheet, cuttingTech, errorOutput) &&
        sheetHasRateTableThickness(sheet, rateTable, rates, errorOutput)
    return { canBeCut: canBeCut, errorMessage: errorOutput.errorMessage }
}

const sheetCanBeUsedWithCuttingTech = (sheet, cuttingTech, errorOutput) => {
    let length = sheet.sheetWidth
    let width = sheet.sheetHeight
    if (width > length) {
        const tmp = length
        length = width
        width = tmp
    }

    const sheetNotLargerThanMax =
        length <= (cuttingTech?.maximumSheetLength ? cuttingTech?.maximumSheetLength : length) &&
        width <= (cuttingTech?.maximumSheetWidth ? cuttingTech?.maximumSheetWidth : width)
    if (!sheetNotLargerThanMax) {
        errorOutput.errorMessage = 'Sheet size is larger than the cutting technology’s maximum settings'
    }
    return sheetNotLargerThanMax
}

const sheetHasRateTable = (rateTable, errorOutput) => {
    if (!rateTable) {
        errorOutput.errorMessage = 'No rate table assigned'
        return false
    } else if (rateTable.isDeleted) {
        errorOutput.errorMessage =
            'The assigned rate table has been archived. Unarchive the rate table or assign a new one.'
        return false
    }
    return true
}

const sheetHasRateTableThickness = (sheet, rateTable, rates, errorOutput) => {
    if (rateTable) {
        const sortedRates = Object.values(rates).filter(
            (rate) => rate.rateTableId === rateTable.rateTableId && !rate.isDeleted
        )
        sortedRates.sort((a, b) => a.thickness - b.thickness)
        const rateTableHasSheetThickness =
            sheet.thickness >= sortedRates[0]?.thickness &&
            sheet.thickness <= sortedRates[sortedRates.length - 1]?.thickness
        if (!rateTableHasSheetThickness) {
            errorOutput.errorMessage = 'Sheet thickness not present in rate table'
        }
        return rateTableHasSheetThickness
    }
    return false
}

export function sortQuoteItems({ _quote, issueSeverityDictionary, organisationDrawingUnits, quoteItems }) {
    function sortQuoteItemsByStatus(qi1, qi2) {
        const qi1Status = hasSevereIssues(qi1.drawingMetadata?.issues, issueSeverityDictionary)
            ? 0
            : hasSevereValidationIssues(qi1)
              ? 1
              : qi1.drawingMetadata?.issues?.length > 0
                ? 2
                : quoteItemHasErrors(qi1, organisationDrawingUnits)
                  ? 3
                  : 4

        const qi2Status = hasSevereIssues(qi2.drawingMetadata?.issues, issueSeverityDictionary)
            ? 0
            : hasSevereValidationIssues(qi2)
              ? 1
              : qi2.drawingMetadata?.issues?.length > 0
                ? 2
                : quoteItemHasErrors(qi2, organisationDrawingUnits)
                  ? 3
                  : 4

        return qi1Status < qi2Status ? -1 : qi1Status === qi2Status ? 0 : 1
    }

    return quoteItems.sort(sortQuoteItemsByStatus)
}

const capitalLetters = /([A-Z])/g
const leadingKebabSeparator = /^-/
const leadingSpaceAndLowerCaseLetter = /^ [a-z]/

export function pascalToKebabCase(text) {
    return text.replace(capitalLetters, (x) => '-' + x.toLowerCase()).replace(leadingKebabSeparator, '')
}

export function pascalToSentenceCase(text) {
    return text
        .replace(capitalLetters, (x) => ' ' + x.toLowerCase())
        .replace(leadingSpaceAndLowerCaseLetter, (substring) => substring.substring(1).toUpperCase())
}

export function blobToBase64(blob) {
    const reader = new FileReader()
    reader.readAsDataURL(blob)
    return new Promise((resolve) => {
        reader.onloadend = () => {
            resolve(reader.result)
        }
    })
}

const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]{2,4}$/

export function validateEmail(email) {
    return emailRegex.test(email)
}

export function percentageToFraction(percentage, numberOfDecimalPlaces) {
    return percentage != null ? parseFloat((percentage / 100).toFixed(numberOfDecimalPlaces ?? 4)) : undefined
}

export function fractionToPercentage(fraction, numberOfDecimalPlaces) {
    return fraction != null && !isNaN(fraction)
        ? parseFloat((fraction * 100).toFixed(numberOfDecimalPlaces ?? 4))
        : undefined
}

export function getLocaleToUse(organisation) {
    return organisation && organisation.locale
}

export function getLanguageToUse(user, organisation) {
    return (user && user.language) || (organisation && organisation.language)
}

export function browserFileDownload(payload, filename, filetype = '') {
    const url = window.URL.createObjectURL(new Blob([payload], { type: filetype }))
    const link = document.createElement('a')
    link.href = url
    link.setAttribute('download', filename)
    document.body.appendChild(link)
    link.click()
    document.body.removeChild(link)
    window.URL.revokeObjectURL(url)
    return
}

/**
 * Since we support only a limited number of locales and use en-US localization for the rest,
 * consult a map given by this function.
 * @param {string} localeId
 * @returns
 */
export const getSupportedLocale = (localeId) => {
    const localeMap = {
        'en-AU': 'en-AU',
        'gsw-FR': 'fr',
        'br-FR': 'fr',
        'ca-FR': 'fr',
        'co-FR': 'fr',
        'fr-FR': 'fr',
        'ia-FR': 'fr',
        'oc-FR': 'fr',
        'en-DE': 'de',
        'de-DE': 'de',
        'nds-DE': 'de',
        'dsb-DE': 'de',
        'ksh-DE': 'de',
        'hsb-DE': 'de',
        'ca-IT': 'it',
        'fur-IT': 'it',
        'de-IT': 'it',
        'it-IT': 'it',
        'en-NZ': 'en-AU',
        'mi-NZ': 'en-AU',
        'kw-GB': 'en-GB',
        'en-GB': 'en-GB',
        'gd-GB': 'en-GB',
        'cy-GB': 'en-GB',
        'es-ES': 'es',
        'pl-PL': 'pl',
        'pt-PT': 'pt',
        'pt-BR': 'pt',
        es: 'es',
        fr: 'fr',
        de: 'de',
        it: 'it',
        pt: 'pt',
        pl: 'pl',
    }

    const mappedLocale = localeMap[localeId]

    if (!mappedLocale) {
        return 'en-US'
    } else {
        return mappedLocale
    }
}

export function getProductDetails(resellerName = import.meta.env.VITE_RESELLER) {
    if (resellerName) {
        return RESELLERS[resellerName]
    } else {
        return PRODUCT_DETAILS
    }
}

export function organisationIsImperial(organisation) {
    return (
        organisation?.defaultDrawingUnits?.localeCompare('imperial', undefined, {
            sensitivity: 'accent',
        }) === 0
    )
}

export function organisationLengthPrecision(organisation) {
    return organisationIsImperial(organisation) ? 4 : 2
}

export function organisationDimensionTolerance(organisation) {
    return organisationIsImperial(organisation) ? 0.0001 : 0.01
}

export function setPageTitleAndFavicon(theme) {
    const resellerName = import.meta.env.VITE_RESELLER
    const faviconElement = document.getElementById('favicon')
    const productDetails = getProductDetails(resellerName)
    if (resellerName) {
        document.title = productDetails.title
        faviconElement.href = `${productDetails.name}/favicon.ico`
        return productDetails.theme(theme)
    } else {
        document.title = productDetails.title
        faviconElement.href = 'favicon.ico'
        return theme
    }
}

export function numbersWithinPercentageTolerance(number1, number2, tolerance) {
    const percentageDifference = 100 * (1 - Math.min(number1, number2) / Math.max(number1, number2))
    return percentageDifference <= tolerance
}

export const MAX_DRAWING_UPLOAD_SIZE_BYTES = 4194304

const quoteItemsDimensions = (quoteItems, useImperialUnits) =>
    quoteItems.map((quoteItem) => {
        const { isImperial, minimumBoundBoxHeight, minimumBoundBoxWidth } = quoteItem
        const long = Math.max(minimumBoundBoxWidth, minimumBoundBoxHeight)
        const short = Math.min(minimumBoundBoxWidth, minimumBoundBoxHeight)
        return {
            long,
            short,
            isImperial,
            longInOrgUnits:
                useImperialUnits === isImperial
                    ? long
                    : useImperialUnits && !isImperial
                      ? mmToInches(long)
                      : inchesToMm(long),
            shortInOrgUnits:
                useImperialUnits === isImperial
                    ? short
                    : useImperialUnits && !isImperial
                      ? mmToInches(short)
                      : inchesToMm(short),
        }
    })

export const getLargestDimensions = (quoteItems, organisation) => {
    if (!quoteItems?.length || !organisation) return { long: '', short: '' }
    const orgIsImperial = organisationIsImperial(organisation)
    const dimensions = quoteItemsDimensions(quoteItems, orgIsImperial)

    const largestDimension = (key) =>
        dimensions.reduce((max, item) => (item[key] > max[key] ? item : max), dimensions[0])

    const largestLong = largestDimension('longInOrgUnits')
    const largestShort = largestDimension('shortInOrgUnits')

    const formatDimension = (dimension) =>
        `${formatNumber(dimension, organisation.locale, orgIsImperial)} ${orgIsImperial ? 'in' : 'mm'}`

    return {
        long: formatDimension(largestLong.longInOrgUnits),
        short: formatDimension(largestShort.shortInOrgUnits),
    }
}

export const secondsToHHMMSS = (seconds) => {
    return new Date(seconds * 1000).toISOString().slice(11, 19)
}

export const secondsToDHMS = (seconds) => {
    if (seconds === 0) {
        return String.fromCharCode(8212)
    }

    const days = Math.floor(seconds / (3600 * 24))
    const hours = Math.floor((seconds % (3600 * 24)) / 3600)
    const minutes = Math.floor((seconds % 3600) / 60)
    const secs = Math.floor(seconds % 60)

    if (days === 0) {
        return `${hours}h ${minutes}m ${secs}s`
    }

    return `${days}d ${hours}h ${minutes}m ${secs}s`
}

export function getNumberFormat(locale) {
    const formatter = new Intl.NumberFormat(locale)

    const numberFormat = {}
    formatter.formatToParts(10000.01).forEach((item) => {
        numberFormat[item.type] = item.value
    })

    numberFormat['decimal'] = numberFormat['group'] === '.' ? ',' : '.'
    numberFormat['decimalPlaces'] = numberFormat['fraction'] ? numberFormat['fraction'].length : 0

    return numberFormat
}

export function loadWebStoreFavicon(src) {
    const favicon = document.getElementById('favicon')
    favicon.href = src
}
