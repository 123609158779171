import { useTranslation } from 'react-i18next'
import Chip from '@mui/material/Chip'
import PropTypes from 'prop-types'

import { GET_PAYMENT_STATUS_LABEL, QUOTE_PAYMENT_STATUS } from '@/common/utils'

const statusChipColor = (status) => {
    switch (status) {
        case (status = QUOTE_PAYMENT_STATUS?.ManualPaid):
            return 'success.light'
        case (status = QUOTE_PAYMENT_STATUS?.GatewayPaid):
            return 'success.main'
        case (status = QUOTE_PAYMENT_STATUS?.Unpaid):
            return 'error.light'
        default:
            return 'secondary'
    }
}
const QuotePaidStatusChip = ({ paymentStatus }) => {
    const { t } = useTranslation()

    return (
        <Chip
            data-testid="quote-paid-status-chip"
            label={t(GET_PAYMENT_STATUS_LABEL(paymentStatus))}
            size="small"
            sx={{
                textTransform: 'uppercase',
                fontSize: '11px',
                backgroundColor: statusChipColor(paymentStatus),
                color: 'white',
                userSelect: 'none',
            }}
            title={t(GET_PAYMENT_STATUS_LABEL(paymentStatus))}
        />
    )
}

QuotePaidStatusChip.propTypes = {
    paymentStatus: PropTypes.string,
}

export default QuotePaidStatusChip
