import MuiFab from '@mui/material/Fab'
import MuiIconButton from '@mui/material/IconButton'
import PropTypes from 'prop-types'

const IconButton = ({ className, classes, color = 'default', disabled, size, type, ...props }) => {
    return type === 'floating' ? (
        <MuiFab
            classes={classes}
            color={color}
            disabled={disabled}
            size={size}
            sx={className}
            {...props}
        />
    ) : (
        <MuiIconButton
            classes={classes}
            color={color}
            disabled={disabled}
            size={size}
            sx={className}
            {...props}
        />
    )
}

IconButton.propTypes = {
    children: PropTypes.node,
    classes: PropTypes.object,
    className: PropTypes.object,
    color: PropTypes.oneOf(['default', 'inherit', 'primary', 'secondary']),
    disabled: PropTypes.bool,
    size: PropTypes.oneOf(['large', 'medium', 'small']),
    type: PropTypes.oneOf(['default', 'floating']),
    onClick: PropTypes.func,
}

export default IconButton
