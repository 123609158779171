import { useTranslation } from 'react-i18next'
import { useSelector } from 'react-redux'
import { useNavigate } from 'react-router-dom'
import { Box, Button, Grid, Typography } from '@mui/material'

import { selectOrganisation } from '@/app/slices/organisationSlice'

import PublicAppBar from '../../MainAppBar/PublicAppBar'

const classes = {
    root: {
        backgroundColor: 'background.paper',
        borderRadius: 1,
        border: (theme) => `1px ${theme.palette.grey[300]} solid`,
        width: '100%',
        height: 'calc(100vh - 64px)',
        gap: 3,
    },
    sorryImage: {
        width: '700px',
    },
    sorryMessage: {
        gap: 1,
    },
}

const GenericErrorPage = () => {
    const { t } = useTranslation()

    const navigate = useNavigate()
    const organisation = useSelector(selectOrganisation)

    return (
        <>
            <PublicAppBar
                organisationLogo={organisation?.logoUri}
                organisationName={organisation?.name}
            />
            <Grid
                component="section"
                xs={12}
                container
                item
            >
                <Box
                    alignItems="center"
                    display="flex"
                    flexDirection="column"
                    justifyContent="center"
                    p={12}
                    sx={classes.root}
                >
                    <Box
                        alt="Sorry"
                        component="img"
                        src="sorry.svg"
                        sx={classes.sorryImage}
                    />

                    <Box
                        alignItems="center"
                        display="flex"
                        flexDirection="column"
                        justifyContent="center"
                        sx={classes.sorryMessage}
                    >
                        <Typography variant="h4">{t('Something went wrong.')}</Typography>
                        <Typography variant="body2">
                            {t("It's not you, it's us, please try that again, or contact support for help.")}
                        </Typography>
                    </Box>

                    <Button
                        color="primary"
                        size="large"
                        variant="contained"
                        onClick={() => navigate('/')}
                    >
                        {t('Back to your dashboard')}
                    </Button>
                </Box>
            </Grid>
        </>
    )
}

export default GenericErrorPage
