import { styled } from '@mui/material/styles'
import Tooltip, { tooltipClasses } from '@mui/material/Tooltip'
import PropTypes from 'prop-types'

const StyledTooltip = styled(({ className, ...props }) => (
    <Tooltip
        {...props}
        classes={{ popper: className }}
    />
))(({ theme }) => ({
    [`& .${tooltipClasses.tooltip}`]: {
        fontFamily: theme.typography.inter,
        color: 'white',
        backgroundColor: 'rgba(0, 0, 0, 0.9)',
        fontWeight: 400,
        fontSize: '14px',
        lineHeight: '20px',
        padding: '12px 16px',
    },
    [`& .${tooltipClasses.arrow}`]: {
        '&:before': {
            backgroundColor: 'rgba(0, 0, 0, 0.9)',
        },
    },
}))

const TbxTooltip = ({ children, ...props }) => {
    return <StyledTooltip {...props}>{children}</StyledTooltip>
}

TbxTooltip.propTypes = {
    children: PropTypes.node.isRequired,
    arrow: PropTypes.bool,
    title: PropTypes.node,
}

export default TbxTooltip
