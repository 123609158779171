// select quoteitem by id and return warnings
export const quoteItemWarnings = (quoteItem) => {
    return quoteItem?.issues?.warnings?.length ? quoteItem.issues.warnings : false
}

// select quoteitem by id and return errors
export const quoteItemErrors = (quoteItem) => {
    return quoteItem?.issues?.errors?.length ? quoteItem.issues.errors : false
}

// select quoteitem by id and return if is unset
export const quoteItemUnset = (quoteItem) => {
    return !quoteItem?.cuttingTechnologyId || !quoteItem?.materialId || !quoteItem?.thickness || !quoteItem?.sheetId
}

export const quoteItemSuccess = (quoteItem) => {
    return !quoteItem?.issues?.warnings?.length && !quoteItem?.issues?.errors?.length
}

// select quoteitem by id and return if is calculated
export const quoteItemCalculated = (quoteItem) => {
    return Boolean(quoteItem?.itemPrice) && Boolean(quoteItem?.linePrice)
}

// check if any quote.quoteitems has issues warning or is-error
export const quoteHasIssues = (quoteItems) => {
    if (!quoteItems?.length) return false
    return quoteItems.some((item) =>
        Object.keys(item?.issues).some((key) => {
            if (key !== '$type') {
                return item?.issues[key]?.length
            }
        })
    )
}

// count how many quote.quoteitems has warnings or error
export const quoteIssuesCount = (quoteItems) => {
    if (!quoteItems?.length) return false
    return quoteItems.reduce(
        (acc, item) => {
            if (quoteItemUnset(item)) acc.unset += 1
            if (!quoteItemUnset(item) && quoteItemWarnings(item)) acc.warnings += 1
            if (!quoteItemUnset(item) && quoteItemErrors(item)) acc.errors += 1
            if (!quoteItemUnset(item) && quoteItemSuccess(item)) acc.success += 1
            return acc
        },
        { unset: 0, warnings: 0, errors: 0, success: 0 }
    )
}

// select quoteitem by id and check if has warnings or error
export const quoteItemHasIssues = (quoteItem) => {
    if (!quoteItem?.issues) return false
    return Object.keys(quoteItem?.issues).some((key) => {
        if (key !== '$type') {
            return quoteItem?.issues[key]?.length
        }
    })
}

const sortByUserDefinedIndex = (isAsc) => (a, b) => {
    return isAsc ? a.index - b.index : b.index - a.index
}

const sortByDefault = (isAsc) => (a, b) => {
    const dateA = new Date(a.createDateUtc)
    const dateB = new Date(b.createDateUtc)
    return isAsc ? dateA - dateB : dateB - dateA
}

const sortByName = (isAsc) => (a, b) => {
    return isAsc ? a.name.localeCompare(b.name) : b.name.localeCompare(a.name)
}

const sortByQuantity = (isAsc) => (a, b) => {
    return isAsc ? a.quantity - b.quantity : b.quantity - a.quantity
}

const sortByUnitPrice = (isAsc) => (a, b) => {
    return isAsc ? a.itemPrice - b.itemPrice : b.itemPrice - a.itemPrice
}

const sortByMaterialThickness = (isAsc, materials) => (a, b) => {
    const materialArray = Object.values(materials)

    const materialA = materialArray.find((material) => material.materialId === a.materialId)
    const materialB = materialArray.find((material) => material.materialId === b.materialId)

    const materialThicknessA = {
        materialName: materialA?.materialName ?? '',
        thickness: a.thickness,
    }
    const materialThicknessB = {
        materialName: materialB?.materialName ?? '',
        thickness: b.thickness,
    }

    if (materialThicknessA.materialName === materialThicknessB.materialName) {
        return isAsc
            ? materialThicknessA.thickness - materialThicknessB.thickness
            : materialThicknessB.thickness - materialThicknessA.thickness
    }

    return isAsc
        ? materialThicknessA.materialName.localeCompare(materialThicknessB.materialName)
        : materialThicknessB.materialName.localeCompare(materialThicknessA.materialName)
}

export const getSortFunction = (selectedCriteria, selectedDirection, materials) => {
    const isAsc = selectedDirection === 'ASC'
    switch (selectedCriteria) {
        case 'Default':
            return sortByDefault(isAsc)
        case 'Name':
            return sortByName(isAsc)
        case 'MaterialThickness':
            return sortByMaterialThickness(isAsc, materials)
        case 'Quantity':
            return sortByQuantity(isAsc)
        case 'UnitPrice':
            return sortByUnitPrice(isAsc)
        default:
            return sortByUserDefinedIndex(isAsc)
    }
}
