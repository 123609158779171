import { useSelector } from 'react-redux'

import { selectLocale } from '@/app/slices/appSlice'

export default function useNumberFormatter(props) {
    const config = props || {}
    const appLocale = useSelector(selectLocale)
    const defaultLocale = 'en-US'

    const locale = config.locale || appLocale || defaultLocale
    const numberOfDecimalPlaces = config.numberOfDecimalPlaces ?? 2

    const formatterOptions = {
        style: 'decimal',
        unitDisplay: 'long',
        signDisplay: 'auto',
        useGrouping: true,
        ...config,
    }

    formatterOptions.minimumFractionDigits = numberOfDecimalPlaces
    formatterOptions.maximumFractionDigits = numberOfDecimalPlaces

    const formatter = new Intl.NumberFormat(locale, formatterOptions)

    const n = (value) => {
        return value ? formatter.format(value) : value
    }

    return {
        n,
    }
}
