import { api } from './api'

export const organistionUsersApi = api
    .enhanceEndpoints({
        addTagTypes: ['OrganisationUsers'],
    })
    .injectEndpoints({
        endpoints: (builder) => ({
            getOrganisationUsers: builder.query({
                query: ({ organisationId }) => `/organisations/${organisationId}/users`,
                providesTags: (result, _error, _arg) =>
                    result
                        ? [
                              { type: 'OrganisationUsers', id: 'LIST' },
                              ...result.map(({ userId }) => ({ type: 'OrganisationUsers', id: userId })),
                          ]
                        : [{ type: 'OrganisationUsers', id: 'LIST' }],
            }),

            getOrganisationUser: builder.query({
                query: ({ organisationId, userId }) => `/organisations/${organisationId}/users/${userId}`,
                providesTags: (_result, _error, arg) => [{ type: 'OrganisationUsers', id: arg.userId }],
            }),

            addOrganisationUser: builder.mutation({
                query: ({ emailAddress, firstName, lastName, organisationId, role }) => ({
                    url: `/organisations/${organisationId}/users`,
                    method: 'POST',
                    body: { firstName, lastName, emailAddress, role },
                }),
                invalidatesTags: [{ type: 'OrganisationUsers', id: 'LIST' }],
            }),

            updateOrganisationUser: builder.mutation({
                query: ({ organisationId, role, userId }) => ({
                    url: `/organisations/${organisationId}/users/${userId}`,
                    method: 'POST',
                    body: { role },
                }),
                //invalidates updated user only
                invalidatesTags: (_result, _error, arg) => [{ type: 'OrganisationUsers', id: arg.userId }],
            }),

            removeOrganisationUser: builder.mutation({
                query: ({ organisationId, userId }) => ({
                    url: `/organisations/${organisationId}/users/${userId}`,
                    method: 'DELETE',
                }),
                invalidatesTags: (result, _error, arg) => [{ type: 'OrganisationUsers', id: arg.userId }],
            }),
        }),
    })

export const {
    useAddOrganisationUserMutation,
    useGetOrganisationUserQuery,
    useGetOrganisationUsersQuery,
    useLazyGetOrganisationUserQuery,
    useLazyGetOrganisationUsersQuery,
    useRemoveOrganisationUserMutation,
    useUpdateOrganisationUserMutation,
} = organistionUsersApi
