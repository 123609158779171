import PropTypes from 'prop-types'

import { AppBarPortal } from '../MainAppBar'

const TbxToolbar = ({ children }) => {
    return <AppBarPortal>{children}</AppBarPortal>
}

TbxToolbar.propTypes = {
    children: PropTypes.node,
}

export default TbxToolbar
