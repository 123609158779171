import SvgIcon from '@mui/material/SvgIcon'

// https://materialdesignicons.com/icon/arrow-right

const LibraryIcon = (props) => {
    return (
        <SvgIcon
            {...props}
            viewBox="0 0 24 24"
        >
            <path
                clipRule="evenodd"
                d="M6.67199 5.54199H13.328C16.702 5.54199 18.39 5.54199 19.338 6.52899C20.285 7.51599 20.062 9.03999 19.616 12.089L19.194 14.981C18.844 17.372 18.669 18.568 17.772 19.284C16.875 20 15.552 20 12.905 20H7.09499C4.44899 20 3.12499 20 2.22799 19.284C1.33099 18.568 1.15599 17.372 0.805993 14.981L0.383994 12.089C-0.0630064 9.03999 -0.286007 7.51599 0.661993 6.52899C1.60999 5.54199 3.29799 5.54199 6.67199 5.54199ZM5.99999 16C5.99999 15.586 6.37299 15.25 6.83299 15.25H13.167C13.627 15.25 14 15.586 14 16C14 16.414 13.627 16.75 13.167 16.75H6.83299C6.37299 16.75 5.99999 16.414 5.99999 16Z"
                fillRule="evenodd"
            />
            <path
                d="M6.51001 0H13.49C13.723 0 13.9 1.05239e-07 14.057 0.0150001C15.165 0.124 16.071 0.79 16.456 1.687H3.54401C3.92901 0.79 4.83601 0.124 5.94401 0.0150001C6.09901 1.05239e-07 6.27801 0 6.51001 0Z"
                opacity="0.4"
            />
            <path
                d="M4.31001 2.72314C2.92001 2.72314 1.78001 3.56314 1.40001 4.67614C1.39168 4.69936 1.38368 4.7227 1.37601 4.74614C1.77908 4.62831 2.19112 4.5437 2.60801 4.49314C3.68801 4.35514 5.05401 4.35514 6.64001 4.35514H13.532C15.118 4.35514 16.484 4.35514 17.564 4.49314C17.984 4.54714 18.398 4.62614 18.796 4.74614C18.7886 4.72271 18.781 4.69938 18.773 4.67614C18.393 3.56214 17.253 2.72314 15.862 2.72314H4.31001Z"
                opacity="0.7"
            />
        </SvgIcon>
    )
}

export default LibraryIcon
