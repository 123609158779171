import { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useSelector } from 'react-redux'
import { useParams } from 'react-router-dom'
import { Edit, Save, Undo } from '@mui/icons-material'
import { Box, IconButton, TextField, Typography } from '@mui/material'
import { useSnackbar } from 'notistack'
import PropTypes from 'prop-types'

import { useGetMiscItemsQuery, useUpdateMiscItemMutation } from '@/app/services/miscItems'
import { selectOrganisationId } from '@/app/slices/organisationSlice'

const MiscItemName = ({ id, isPartFromLibrary = false, name }) => {
    const [isEditing, setIsEditing] = useState(false)
    const [itemName, setItemName] = useState(name)
    const { t } = useTranslation()
    const { enqueueSnackbar } = useSnackbar()
    const { quoteId } = useParams()

    const organisationId = useSelector((state) => selectOrganisationId(state))
    const [updateMiscItem, { isLoading: isUpdating }] = useUpdateMiscItemMutation()

    const { miscItem } = useGetMiscItemsQuery(
        {
            organisationId,
            quoteId,
        },
        {
            selectFromResult: ({ data }) => ({
                miscItem: data?.find((item) => item.id === id),
            }),
        }
    )

    const handleUpdateMiscItem = async (attributes) => {
        try {
            const result = await updateMiscItem({
                organisationId,
                quoteId,
                miscItemId: id,
                miscItem: {
                    ...miscItem,
                    ...attributes,
                },
            }).unwrap()
            enqueueSnackbar(t('$t(Quote) item updated'), { variant: 'success' })
            return result
        } catch (error) {
            enqueueSnackbar(t('Failed to update the $t(quote) item'), {
                variant: 'error',
            })
        }
    }

    const handleItemNameChange = (event) => {
        const value = event.target.value
        setItemName(value)
    }

    const enableEditing = (event) => {
        event.stopPropagation()
        setIsEditing(true)
    }
    const saveEditing = async (event) => {
        event.stopPropagation()
        if (itemName !== name) {
            await handleUpdateMiscItem({ name: itemName })
        }
        setIsEditing(false)
    }

    const reset = (event) => {
        event.stopPropagation()
        setItemName(name)
        setIsEditing(false)
    }

    const onKeyDown = (event) => {
        if (event?.key === 'Enter') saveEditing(event)
    }

    useEffect(() => {
        setItemName(name)
    }, [name])

    return (
        <Box>
            {isEditing ? (
                <>
                    <TextField
                        key={`name-input-${id}`}
                        value={itemName}
                        onChange={handleItemNameChange}
                        onClick={(event) => event.stopPropagation()}
                        onKeyDown={onKeyDown}
                    />

                    <IconButton
                        color="secondary"
                        data-testid="save-quote-item-name"
                        disabled={isUpdating || !itemName}
                        onClick={saveEditing}
                    >
                        <Save fontSize="small" />
                    </IconButton>
                    <IconButton
                        color="secondary"
                        data-testid="undo-quote-item-name"
                        disabled={isUpdating}
                        onClick={reset}
                    >
                        <Undo fontSize="small" />
                    </IconButton>
                </>
            ) : (
                <Box
                    alignItems="center"
                    display="flex"
                >
                    <Typography
                        mr={0.5}
                        variant="body1"
                    >
                        {itemName}
                    </Typography>
                    {!isPartFromLibrary ? (
                        <IconButton
                            color="secondary"
                            data-testid="edit-quote-item-name"
                            onClick={enableEditing}
                        >
                            <Edit fontSize="small" />
                        </IconButton>
                    ) : null}
                </Box>
            )}
        </Box>
    )
}

MiscItemName.propTypes = {
    id: PropTypes.string,
    isPartFromLibrary: PropTypes.bool,
    name: PropTypes.string,
}

export default MiscItemName
