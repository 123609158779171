import { useEffect, useState } from 'react'
import { Box } from '@mui/material'
import PropTypes from 'prop-types'

const TextRenderer = ({ hidden = false, onClick, path, sx, textEntity, usingCartesianCoordinates }) => {
    const [textLines, setTextLines] = useState([])

    useEffect(() => {
        const escapedNewLineChar = textEntity.newLineChar.toString().replace('\\', '\\\\')
        const newLineCharLower = escapedNewLineChar.toLowerCase()
        const newLineCharUpper = escapedNewLineChar.toUpperCase()

        const regex = new RegExp(`[${newLineCharLower}|${newLineCharUpper}]+`)
        const splitText = textEntity.displayText.split(regex)

        setTextLines(splitText)
    }, [textEntity])

    return (
        textEntity &&
        !hidden && (
            <Box
                component="text"
                hidden={hidden}
                id={path.pathId}
                sx={sx}
                transform={usingCartesianCoordinates ? `matrix(1,0,0-1,0,${2 * textEntity.bottomLeft.y})` : null}
                x={textEntity.bottomLeft.x}
                y={textEntity.bottomLeft.y}
                onClick={onClick}
            >
                {textLines.map((textLine, index) => (
                    <tspan
                        dy={index > 0 ? '1.2em' : 0}
                        key={index}
                        x={textEntity.bottomLeft.x}
                    >
                        {textLine}
                    </tspan>
                ))}
            </Box>
        )
    )
}

TextRenderer.propTypes = {
    hidden: PropTypes.bool,
    path: PropTypes.object,
    sx: PropTypes.oneOfType([
        PropTypes.arrayOf(PropTypes.oneOfType([PropTypes.func, PropTypes.object, PropTypes.bool])),
        PropTypes.func,
        PropTypes.object,
    ]),
    textEntity: PropTypes.object,
    usingCartesianCoordinates: PropTypes.bool,
    onClick: PropTypes.func,
}

export default TextRenderer
