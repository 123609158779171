import {
    Box,
    InputAdornment as MuiInputAdornment,
    InputLabel as MuiInputLabel,
    TextField as MuiTextField,
} from '@mui/material'
import PropTypes from 'prop-types'

import SelectOption from '../SelectOption/SelectOption'

const styleClasses = {
    container: (props) => ({
        display: 'flex',
        alignItems: props.labelPosition === 'start' || props.labelPosition === 'end' ? 'center' : 'normal',
        flexDirection: props.labelPosition === 'top' || props.labelPosition === 'bottom' ? 'column' : 'row',
        justifyContent: props.justifycontent,
    }),
    inputLabelTopOrBottom: {
        mx: 0,
        my: 1,
    },
    labelRoot: {
        margin: 1,
        overflow: 'inherit',
        whiteSpace: 'inherit',
    },
    denseInputAdornment: {
        marginRight: '4px',
        marginLeft: '4px',
    },
    selectPlaceholder: {
        color: (theme) => theme.palette.text.disabled,
    },
    input: {},
    adornment: {},
    inputDisabled: {},
}

const Input = ({
    FormHelperTextProps,
    InputProps,
    adornmentDense,
    adornmentEnd,
    adornmentStart,
    autoComplete,
    autoFocus,
    children,
    className,
    classes,
    color,
    defaultValue,
    disabled,
    error,
    fullWidth,
    helperText,
    hideLabel,
    inputProps,
    inputRef,
    justifycontent = 'space-between',
    label,
    labelPosition = 'start',
    labelProps,
    labelText,
    multiline,
    name,
    onBlur,
    onChange,
    onEnter,
    onEscape,
    onFocus,
    onKeyDown,
    onKeyUp,
    placeholder,
    readOnly,
    required,
    rows,
    rowsMax,
    textAlign = 'right',
    type = 'text',
    value,
    variant,
    ...rest
}) => {
    const inputRootClass =
        labelPosition === 'top' || labelPosition === 'bottom' ? styleClasses.inputLabelTopOrBottom : {}

    const handleKeyUp = (event) => {
        switch (event.which) {
            case 13:
                if (typeof onEnter === 'function') {
                    onEnter(event)
                }
                break
            case 27:
                if (typeof onEscape === 'function') {
                    onEscape(event)
                }
                break
            default:
                break
        }

        if (typeof onKeyUp === 'function') {
            onKeyUp(event)
        }
    }

    const getTextfieldInput = (isSelect) => {
        return (
            <MuiTextField
                {...rest}
                autoComplete={autoComplete}
                autoFocus={autoFocus}
                classes={classes}
                color={color}
                defaultValue={defaultValue}
                disabled={disabled}
                error={error}
                FormHelperTextProps={FormHelperTextProps}
                fullWidth={fullWidth}
                helperText={helperText}
                inputProps={{
                    ...inputProps,
                }}
                InputProps={{
                    endAdornment: adornmentEnd && (
                        <MuiInputAdornment
                            position="end"
                            sx={adornmentDense ? styleClasses.denseInputAdornment : null}
                        >
                            {adornmentEnd}
                        </MuiInputAdornment>
                    ),
                    startAdornment: adornmentStart && (
                        <MuiInputAdornment
                            position="start"
                            sx={adornmentDense ? styleClasses.denseInputAdornment : null}
                        >
                            {adornmentStart}
                        </MuiInputAdornment>
                    ),
                    ...InputProps,
                    inputProps: { ...inputProps, style: { textAlign: textAlign } },
                    readOnly: readOnly,
                }}
                inputRef={inputRef}
                label={label}
                maxRows={rowsMax}
                multiline={multiline}
                name={name}
                placeholder={placeholder ? placeholder : isSelect ? '-' : null}
                required={required}
                rows={rows}
                select={isSelect}
                SelectProps={inputProps}
                sx={inputRootClass}
                type={type}
                value={isSelect && !value ? '-1' : value}
                variant={variant}
                onBlur={onBlur}
                onChange={onChange}
                onFocus={onFocus}
                onKeyDown={onKeyDown}
                onKeyUp={handleKeyUp}
            >
                {isSelect && !value && value !== 0 ? (
                    <SelectOption
                        key={-1}
                        value={-1}
                    >
                        <Box sx={styleClasses.selectPlaceholder}>{placeholder ?? '-'}</Box>
                    </SelectOption>
                ) : null}
                {children}
            </MuiTextField>
        )
    }

    const renderInput = () => {
        switch (type) {
            case 'select':
                return getTextfieldInput(true)
            default:
                return getTextfieldInput(false)
        }
    }

    const renderLabel = () => {
        return (
            labelText &&
            !hideLabel && (
                <MuiInputLabel
                    classes={classes}
                    sx={{
                        ...styleClasses.labelRoot,
                        ...labelProps?.sx,
                    }}
                    {...labelProps}
                    required={required}
                >
                    {labelText}
                </MuiInputLabel>
            )
        )
    }

    const containerClass = Object.assign({}, styleClasses.container({ labelPosition, justifycontent }), className)

    return (
        <Box sx={containerClass}>
            {!labelPosition || labelPosition === 'top' || labelPosition === 'start' ? renderLabel() : null}
            {renderInput()}
            {labelPosition === 'bottom' || labelPosition === 'end' ? renderLabel() : null}
        </Box>
    )
}

export const inputPropTypes = {
    labelPosition: PropTypes.oneOf(['start', 'end', 'bottom', 'top', '']).isRequired,
    textAlign: PropTypes.oneOf(['left', 'center', 'right']).isRequired,
    type: PropTypes.oneOf(['text', 'number', 'radio', 'checkbox', 'select']).isRequired,
    adornmentDense: PropTypes.bool,
    adornmentEnd: PropTypes.node,
    adornmentStart: PropTypes.node,
    autoComplete: PropTypes.string,
    autoFocus: PropTypes.bool,
    children: PropTypes.node,
    classes: PropTypes.object,
    className: PropTypes.object,
    color: PropTypes.oneOf(['primary', 'secondary']),
    defaultValue: PropTypes.any,
    disabled: PropTypes.bool,
    error: PropTypes.oneOfType([PropTypes.bool, PropTypes.string]),
    FormHelperTextProps: PropTypes.object,
    fullWidth: PropTypes.bool,
    helperText: PropTypes.node,
    hideLabel: PropTypes.bool,
    inputProps: PropTypes.object,
    InputProps: PropTypes.object,
    inputRef: PropTypes.any,
    justifycontent: PropTypes.oneOf(['start', 'end', 'center', 'space-between', 'space-around']),
    label: PropTypes.node,
    labelProps: PropTypes.object,
    labelText: PropTypes.string,
    margin: PropTypes.oneOf(['dense', 'none', 'normal']),
    multiline: PropTypes.bool,
    name: PropTypes.string,
    placeholder: PropTypes.string,
    readOnly: PropTypes.bool,
    required: PropTypes.bool,
    rows: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
    rowsMax: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
    style: PropTypes.object,
    value: PropTypes.any,
    variant: PropTypes.oneOf(['filled', 'outlined', 'standard']),
    onBlur: PropTypes.func,
    onChange: PropTypes.func,
    onEnter: PropTypes.func,
    onEscape: PropTypes.func,
    onFocus: PropTypes.func,
    onKeyDown: PropTypes.func,
    onKeyUp: PropTypes.func,
}

Input.propTypes = inputPropTypes

export default Input
