import { useMemo, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useSelector } from 'react-redux'
import { Save } from '@mui/icons-material'
import { Box, Checkbox, Dialog, DialogContent, DialogTitle, IconButton, Typography } from '@mui/material'
import PropTypes from 'prop-types'

import { useGetSecondaryProcessesQuery } from '@/app/services/secondaryProcesses'
import { selectOrganisationId } from '@/app/slices/organisationSlice'

const SecondaryProcessesModal = ({ accentColor = 'secondary', onClose, open, secondaryProcesses }) => {
    const { t } = useTranslation()

    const organisationId = useSelector(selectOrganisationId)
    const { data: organisationSecondaryProcesses } = useGetSecondaryProcessesQuery({ organisationId })
    const [enabledProcesses, setEnabledProcesses] = useState(secondaryProcesses || [])

    const onCheckboxChange = (event) => {
        const { checked, value } = event.target
        if (checked) {
            const processToEnable = organisationSecondaryProcesses.find(
                (process) => process.secondaryProcessId === value
            )
            setEnabledProcesses([...enabledProcesses, processToEnable])
        } else {
            setEnabledProcesses(enabledProcesses.filter((process) => process.secondaryProcessId !== value))
        }
    }

    const formattedProcesses = useMemo(() => {
        const newProcessList = enabledProcesses.map((process) => {
            const { $type, ...cleanProcess } = process
            return {
                ...cleanProcess,
                isEnabled: true,
                input:
                    organisationSecondaryProcesses.find((p) => p.secondaryProcessId === process.secondaryProcessId)
                        .measureType === 'Quantity'
                        ? process.input || 1
                        : 0,
            }
        })

        return [...newProcessList]
    }, [enabledProcesses])

    const ItemRow = ({ children }) => (
        <Box
            alignItems="center"
            display="flex"
            flexDirection="row"
            justifyContent="flex-start"
            width="100%"
        >
            {children}
        </Box>
    )

    const sortedProcesses = useMemo(() => {
        return [...organisationSecondaryProcesses].sort((a, b) => (a.index < b.index ? -1 : 1))
    }, [organisationSecondaryProcesses])

    return (
        <Dialog
            open={open}
            PaperProps={{ sx: { width: 380 } }}
            onClose={() => onClose()}
        >
            <DialogTitle
                alignItems="center"
                data-testid="nqp-quote-item-secondary-processes-modal"
                display="flex"
                justifyContent="space-between"
            >
                <Typography variant="strong1">{t('SECONDARY PROCESSES')}</Typography>
                <IconButton
                    color={accentColor}
                    data-testid="nqp-quote-item-secondary-process-save-button"
                    onClick={() => onClose(formattedProcesses)}
                >
                    <Save />
                </IconButton>
            </DialogTitle>
            <DialogContent>
                <Typography variant="body2">
                    {t('Select the secondary processes you want to use in this part.')}
                </Typography>
                <Box sx={{ mt: 3 }}>
                    {sortedProcesses.map((process) => (
                        <ItemRow key={process.secondaryProcessId}>
                            <Checkbox
                                checked={enabledProcesses.some(
                                    (ep) => ep.secondaryProcessId === process.secondaryProcessId
                                )}
                                color={accentColor}
                                data-testid="nqp-secondary-process-option-checkbox"
                                size="small"
                                value={process.secondaryProcessId}
                                onChange={onCheckboxChange}
                            />
                            <Typography data-testid="nqp-secondary-process-option-label">{process.name}</Typography>
                        </ItemRow>
                    ))}
                </Box>
            </DialogContent>
        </Dialog>
    )
}

SecondaryProcessesModal.propTypes = {
    accentColor: PropTypes.string,
    open: PropTypes.bool,
    primaryColor: PropTypes.string,
    secondaryProcesses: PropTypes.array,
    onClose: PropTypes.func,
}

export default SecondaryProcessesModal
