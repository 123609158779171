import { createTheme, darken } from '@mui/material/styles'

export const theme = createTheme({
    palette: {
        mode: 'light',
        primary: {
            main: '#F78F1E',
        },
        secondary: {
            main: '#0078A4',
        },
        action: {
            active: '#9FA2B4',
            hover: 'rgba(52, 73, 94, 0.04)',
            selected: 'rgba(52, 73, 94, 0.08)',
            disabled: 'rgba(52, 73, 94, 0.26)',
            disabledBackground: 'rgba(52, 73, 94, 0.12)',
            focus: 'rgba(52, 73, 94, 0.12)',
        },
        background: {
            default: '#FAFAFA',
            paper: '#FFFFFF',
        },
        divider: 'rgba(52, 73, 94, 0.12)',
        text: {
            primary: '#34495E',
            secondary: '#5E7387',
            disabled: 'rgba(52, 73, 94, 0.38);',
        },
    },
    typography: {
        fontFamily: "'Roboto', 'Asap', sans-serif",
        inter: "'Inter', sans-serif",
        strong1: {
            fontWeight: 700,
            fontSize: '1rem',
            lineHeight: 1.5,
            letterSpacing: '0.00938em',
        },
        strong2: {
            fontWeight: 700,
            fontSize: '0.875rem',
            lineHeight: 1.43,
            letterSpacing: '0.01071em',
        },
        small: {
            fontWeight: 400,
            fontSize: '0.75rem',
            lineHeight: 1.43,
            letterSpacing: '0.0125em',
        },
        inputTextSmall: {
            fontWeight: 400,
            fontSize: '0.875rem',
            lineHeight: 1.5,
            letterSpacing: '0.0125em',
        },
    },
    components: {
        MuiTextField: {
            defaultProps: {
                variant: 'standard',
            },
        },
        MuiFormControl: {
            defaultProps: {
                variant: 'standard',
            },
        },
        MuiButton: {
            defaultProps: {
                disableElevation: true,
            },
            styleOverrides: {
                containedPrimary: {
                    color: '#FFFFFF',
                },
            },
        },
        MuiFab: {
            styleOverrides: {
                root: {
                    boxShadow: 'none',
                },
            },
        },
        MuiCardActionArea: {
            styleOverrides: {
                focusHighlight: {
                    backgroundColor: darken('#E9ECEF', 0.5),
                },
            },
        },
        MuiTableCell: {
            styleOverrides: {
                root: {
                    padding: '8px',
                },
            },
        },
        MuiTypography: {
            defaultProps: {
                variantMapping: {
                    strong1: 'p',
                    strong2: 'p',
                    small: 'p',
                    inputTextSmall: 'p',
                },
            },
        },
    },
})
