import { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useDispatch, useSelector } from 'react-redux'
import { Archive, Unarchive } from '@mui/icons-material'
import { Box, Card, CardContent, IconButton, Typography } from '@mui/material'
import { alpha } from '@mui/material/styles'
import PropTypes from 'prop-types'

import { useArchiveCustomerMutation, useUnarchiveCustomerMutation } from '@/app/services/customers'
import { selectCurrentUser, selectLocale } from '@/app/slices/appSlice'
import { setSelectedCustomer, setSelectedCustomerId } from '@/app/slices/customersSlice'
import { selectOrganisationId } from '@/app/slices/organisationSlice'
import { DateTime, TbxTooltip } from '@/common/components'

import ArchiveCustomerDialog from './ArchiveCustomerDialog'

const classes = {
    root: (isSelected) => ({
        position: 'relative',
        marginBottom: '12px',
        backgroundColor: (theme) => (isSelected ? theme.palette.primary.main : theme.palette.background.default),
        cursor: 'pointer',
        '&:hover': {
            backgroundColor: (theme) =>
                isSelected ? theme.palette.primary.main : alpha(theme.palette.primary.main, 0.12),
        },
        '&:focus': {
            borderColor: (theme) => theme.palette.primary.main,
        },
        '&:focus-visible': {
            outline: `1px solid ${'primary.main'}`,
        },
    }),
    cardContent: {
        display: 'flex',
        flexDirection: 'column',
        gap: '12px',
        padding: '12px',
        '&:last-child': {
            paddingBottom: '12px',
        },
    },
    contentGroup: {
        display: 'flex',
        alignItems: 'flex-start',
        justifyContent: 'space-between',
    },

    contentGroup__baseline: {
        display: 'flex',
        justifyContent: 'space-between',
        alignItems: 'flex-end',
    },

    contentGroupColumns: {
        display: 'flex',
        flexDirection: 'column',
    },

    contentTitle: (isSelected) => ({
        color: (theme) => (isSelected ? theme.palette.common.white : theme.palette.text.primary),
    }),

    contentSubtitle: (isSelected) => ({
        color: (theme) => (isSelected ? theme.palette.common.white : theme.palette.text.secondary),
    }),

    contentQuotesCount: (isSelected) => ({
        color: (theme) => (isSelected ? theme.palette.common.white : theme.palette.action.active),
    }),

    contentIcon: (isSelected) => ({
        color: (theme) => (isSelected ? theme.palette.common.white : theme.palette.primary.main),
    }),

    contentArchived: {
        color: (theme) => theme.palette.text.disabled,
    },
}

const CustomersListItem = ({ customer, onCustomerSelected, selectedCustomer }) => {
    const { t } = useTranslation()
    const dispatch = useDispatch()

    const organisationId = useSelector(selectOrganisationId)
    const organisation = useSelector(selectOrganisationId)
    const locale = useSelector(selectLocale)

    const currentUser = useSelector(selectCurrentUser)

    const [archiveCustomer] = useArchiveCustomerMutation()
    const [unarchiveCustomer] = useUnarchiveCustomerMutation()

    const [isSelected, setIsSelected] = useState(false)
    const [showDeleteDialog, setShowDeleteDialog] = useState(false)

    const getBillingAddressA = () => {
        const address = customer.billingAddress
        if (!address) return ''
        return [address.line1, address.line2, address.city].filter((item) => item).join(', ')
    }

    const getBillingAddressB = () => {
        const address = customer.billingAddress
        if (!address) return ''
        return [address.state, address.country].filter((item) => item).join(', ')
    }

    const handleArchiveCustomerClick = () => {
        customer.isDeleted
            ? unarchiveCustomer({ organisationId, customerId: customer.customerId })
            : archiveCustomer({ organisationId, customerId: customer.customerId })
        hideArchiveDialog()
    }

    const handleCustomerItemClick = () => {
        dispatch(setSelectedCustomerId(customer.customerId))
        dispatch(setSelectedCustomer(customer))
        typeof onCustomerSelected === 'function' && onCustomerSelected()
    }

    const hideArchiveDialog = () => setShowDeleteDialog(false)

    const showArchiveDialog = (e) => {
        e.stopPropagation()
        setShowDeleteDialog(true)
    }

    useEffect(() => {
        selectedCustomer && setIsSelected(customer.customerId === selectedCustomer.customerId)
    }, [customer, selectedCustomer, setIsSelected])

    return (
        <>
            <Card
                sx={classes.root(isSelected)}
                tabIndex={0}
                variant="outlined"
                onClick={handleCustomerItemClick}
            >
                <CardContent sx={classes.cardContent}>
                    <Box sx={classes.contentGroup}>
                        <Box sx={classes.contentGroupColumns}>
                            <Typography
                                component="h2"
                                sx={customer.isDeleted ? classes.contentArchived : classes.contentTitle(isSelected)}
                                variant="subtitle2"
                            >
                                {customer.companyName}
                            </Typography>

                            <Typography
                                sx={customer.isDeleted ? classes.contentArchived : classes.contentSubtitle(isSelected)}
                                variant="body2"
                            >
                                {t('Last activity: ')}
                                <Typography
                                    component="span"
                                    variant="subtitle2"
                                >
                                    {customer.lastActivityDateUtc ? (
                                        <DateTime
                                            format="DD/MM/YYYY"
                                            locale={locale}
                                        >
                                            {customer.lastActivityDateUtc}
                                        </DateTime>
                                    ) : (
                                        t('no activity')
                                    )}
                                </Typography>
                            </Typography>
                        </Box>
                        <TbxTooltip
                            title={customer.isDeleted ? t('Unarchive customer') : t('Archive customer')}
                            arrow
                        >
                            <IconButton
                                disableRipple={true}
                                size="small"
                                sx={classes.contentIcon(isSelected)}
                                onClick={(event) => showArchiveDialog(event)}
                            >
                                {customer.isDeleted ? <Unarchive /> : <Archive />}
                            </IconButton>
                        </TbxTooltip>
                    </Box>
                    <Box sx={classes.contentGroup__baseline}>
                        <Box sx={classes.contentGroupColumns}>
                            <Typography
                                sx={customer.isDeleted ? classes.contentArchived : classes.contentSubtitle(isSelected)}
                                variant="body2"
                            >
                                {getBillingAddressA()}
                            </Typography>

                            <Typography
                                sx={customer.isDeleted ? classes.contentArchived : classes.contentSubtitle(isSelected)}
                                variant="body2"
                            >
                                {getBillingAddressB()}
                            </Typography>
                        </Box>

                        <Box sx={classes.contentGroupColumns}>
                            <Typography
                                align="right"
                                sx={customer.isDeleted ? classes.contentArchived : classes.contentSubtitle(isSelected)}
                                variant="h5"
                            >
                                {customer.numberOfQuotes}
                            </Typography>
                            <Typography
                                sx={
                                    customer.isDeleted
                                        ? classes.contentArchived
                                        : classes.contentQuotesCount(isSelected)
                                }
                                variant="body2"
                            >
                                {t('$t(quotes)')}
                            </Typography>
                        </Box>
                    </Box>
                </CardContent>
            </Card>

            {showDeleteDialog ? (
                <ArchiveCustomerDialog
                    companyName={customer.companyName}
                    mode={customer.isDeleted ? 'unarchive' : 'archive'}
                    onCancelClose={hideArchiveDialog}
                    onConfirmClose={handleArchiveCustomerClick}
                />
            ) : null}
        </>
    )
}

CustomersListItem.propTypes = {
    availableCustomerCount: PropTypes.number,
    customer: PropTypes.object,
    selectedCustomer: PropTypes.object,
    onCustomerSelected: PropTypes.func,
}

export default CustomersListItem
