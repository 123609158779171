export const UserRole = {
    SiteAdmin: 'SiteAdmin',
    SiteSupport: 'SiteSupport',
}

/**
 * Returns true if the user is a site admin
 * @param {AccountInfo} accountInfo from @azure/msal-browser PublicClientApplication.getActiveAccount()
 * @see {@link https://azuread.github.io/microsoft-authentication-library-for-js/ref/classes/_azure_msal_browser.publicclientapplication.html#getactiveaccount @azure/msal-browser documentation for PublicClientApplication.getActiveAccount()}
 * @returns {boolean} True if account has UserRole == SiteAdmin
 */
export function isSiteAdmin(accountInfo) {
    return accountInfo?.idTokenClaims?.extension_UserRole === UserRole.SiteAdmin
}

/**
 * Returns true if the user is a site admin
 * @param {AccountInfo} accountInfo from @azure/msal-browser PublicClientApplication.getActiveAccount()
 * @see {@link https://azuread.github.io/microsoft-authentication-library-for-js/ref/classes/_azure_msal_browser.publicclientapplication.html#getactiveaccount @azure/msal-browser documentation for PublicClientApplication.getActiveAccount()}
 * @returns {boolean} True if account has UserRole == SiteAdmin || UserRole == SiteSupport
 */
export function isSiteAdminOrSupport(accountInfo) {
    return (
        accountInfo?.idTokenClaims?.extension_UserRole === UserRole.SiteAdmin ||
        accountInfo?.idTokenClaims?.extension_UserRole === UserRole.SiteSupport
    )
}
