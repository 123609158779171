import { useMemo, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useSelector } from 'react-redux'
import { Archive, Edit, Favorite, FavoriteBorder, Unarchive } from '@mui/icons-material'
import { IconButton, TableCell, TableRow } from '@mui/material'
import { useSnackbar } from 'notistack'
import PropTypes from 'prop-types'

import {
    useArchiveContactMutation,
    useSetAsDefaultContactMutation,
    useUnarchiveContactMutation,
} from '@/app/services/contacts'
import { selectOrganisationId } from '@/app/slices/organisationSlice'
import { TbxTooltip } from '@/common/components'

import ArchiveContactDialog from './ArchiveContactDialog/ArchiveContactDialog'

const classes = {
    cell: (isDeleted) => ({
        padding: '24px',
        color: isDeleted ? 'text.disabled' : 'text.primary',
        border: 'none',
    }),
    row: {
        '&:hover': {
            background: (theme) => theme.palette.action.hover,
        },
    },
    icon: {
        color: 'text.primary',
    },
    disabledIcon: (isDeleted) => ({
        color: isDeleted ? 'text.disabled' : 'text.primary',
    }),
    button: {
        margin: '0px 8px',
    },
}

const CustomerContactsRow = ({ contact, customer, onEditClick }) => {
    const { t } = useTranslation()
    const { enqueueSnackbar } = useSnackbar()

    const organisationId = useSelector(selectOrganisationId)

    const [archiveContact] = useArchiveContactMutation()
    const [unarchiveContact] = useUnarchiveContactMutation()
    const [setAsDefaultContact] = useSetAsDefaultContactMutation()

    const [showDeleteDialog, setShowDeleteDialog] = useState(false)

    const isDeleted = useMemo(() => customer?.isDeleted || contact?.isDeleted, [customer, contact])

    const handleEditContactClick = () => {
        typeof onEditClick === 'function' && onEditClick(contact)
    }

    const handleArchiveContactClick = () => {
        setShowDeleteDialog(true)
    }

    const handleSetContactAsDefaultClick = async () => {
        try {
            await setAsDefaultContact({
                organisationId,
                customerId: customer.customerId,
                contactId: contact.contactId,
            }).unwrap()

            enqueueSnackbar(t('Contact set as default'), {
                variant: 'success',
            })
        } catch (error) {
            enqueueSnackbar(t('Failed to set contact as default'), {
                variant: 'error',
            })
        }
    }

    const hideArchiveDialog = () => {
        setShowDeleteDialog(false)
    }

    const confirmArchiveContact = async () => {
        try {
            await archiveContact({
                organisationId,
                customerId: customer.customerId,
                contactId: contact.contactId,
            }).unwrap()
            hideArchiveDialog()

            enqueueSnackbar(t('Contact archived'), {
                variant: 'success',
            })
        } catch (error) {
            enqueueSnackbar(t('Failed to archive contact'), {
                variant: 'error',
            })
        }
    }

    const confirmUnarchiveContact = async () => {
        try {
            await unarchiveContact({
                organisationId,
                customerId: customer.customerId,
                contactId: contact.contactId,
            }).unwrap()
            hideArchiveDialog()

            enqueueSnackbar(t('Contact unarchived'), {
                variant: 'success',
            })
        } catch (error) {
            enqueueSnackbar(t('Failed to unarchive contact'), {
                variant: 'error',
            })
        }
    }

    return (
        <TableRow sx={classes.row}>
            <TableCell sx={classes.cell(isDeleted)}>{contact?.name ?? ''}</TableCell>
            <TableCell sx={classes.cell(isDeleted)}>{contact?.email ?? ''}</TableCell>
            <TableCell sx={classes.cell(isDeleted)}>{contact?.phone ?? ''}</TableCell>
            <TableCell
                align="right"
                sx={classes.cell(isDeleted)}
            >
                <TbxTooltip
                    title={t('Set as default contact')}
                    arrow
                >
                    <IconButton
                        disabled={customer?.isDeleted || contact?.isDeleted}
                        size="small"
                        onClick={contact?.isDefault ? null : handleSetContactAsDefaultClick}
                    >
                        {contact?.isDefault ? (
                            <Favorite
                                fontSize="small"
                                sx={classes.disabledIcon(isDeleted)}
                            />
                        ) : (
                            <FavoriteBorder
                                fontSize="small"
                                sx={classes.disabledIcon(isDeleted)}
                            />
                        )}
                    </IconButton>
                </TbxTooltip>
                <IconButton
                    disabled={customer?.isDeleted || contact?.isDeleted}
                    size="small"
                    sx={classes.button}
                    onClick={handleEditContactClick}
                >
                    <Edit
                        fontSize="small"
                        sx={classes.disabledIcon(isDeleted)}
                    />
                </IconButton>
                <TbxTooltip
                    title={t(contact?.isDeleted ? 'Unarchive contact' : 'Archive contact')}
                    arrow
                >
                    <IconButton
                        disabled={customer?.isDeleted}
                        size="small"
                        onClick={handleArchiveContactClick}
                    >
                        {contact?.isDeleted ? (
                            <Unarchive
                                fontSize="small"
                                sx={classes.icon}
                            />
                        ) : (
                            <Archive
                                fontSize="small"
                                sx={classes.icon}
                            />
                        )}
                    </IconButton>
                </TbxTooltip>
            </TableCell>
            {showDeleteDialog ? (
                <ArchiveContactDialog
                    mode={contact.isDeleted ? 'unarchive' : 'archive'}
                    name={contact.name}
                    onCancelClose={hideArchiveDialog}
                    onConfirmClose={contact.isDeleted ? confirmUnarchiveContact : confirmArchiveContact}
                />
            ) : null}
        </TableRow>
    )
}

CustomerContactsRow.propTypes = {
    contact: PropTypes.object,
    customer: PropTypes.object,
    onEditClick: PropTypes.func,
}

export default CustomerContactsRow
