import { useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useSelector } from 'react-redux'
import { useNavigate } from 'react-router-dom'
import { Archive, Unarchive } from '@mui/icons-material'
import { IconButton, TableCell, TableRow, Typography } from '@mui/material'
import PropTypes from 'prop-types'

import { selectLocale } from '@/app/slices/appSlice'
import { selectOrganisation } from '@/app/slices/organisationSlice'
import { DateTime, TbxTooltip } from '@/common/components'
import { GET_STATUS_LABEL, getFormattedPrice, QUOTE_SOURCE_TYPES } from '@/common/utils'

import ArchiveQuoteDialog from './ArchiveQuoteDialog'

const classes = {
    cell: (isDeleted) => ({
        padding: '24px',
        color: isDeleted ? 'text.disabled' : 'text.primary',
        border: 'none',
    }),
    row: {
        '&:hover': {
            background: (theme) => theme.palette.action.hover,
        },
    },
    icon: {
        color: 'text.primary',
    },
    disabledIcon: (isDeleted) => ({
        color: isDeleted ? 'text.disabled' : 'text.primary',
    }),
    button: {
        margin: '0px 8px',
    },
    quoteName: {
        fontSize: '14px',
        lineHeight: '20px',
        letterSpacing: '0.17px',
        color: '#0078A4',
        textDecoration: 'underline',
        cursor: 'pointer',
    },
}

const QuoteHistoryRow = ({ onArchiveQuoteClick, onUnarchiveQuoteClick, quote }) => {
    const { t } = useTranslation()

    const organisation = useSelector(selectOrganisation)
    const locale = useSelector(selectLocale)
    const [showDeleteDialog, setShowDeleteDialog] = useState(false)

    const navigate = useNavigate()

    const { isDeleted } = quote

    const handleArchiveQuoteClick = () => {
        setShowDeleteDialog(true)
    }

    const hideArchiveDialog = () => {
        setShowDeleteDialog(false)
    }

    const confirmArchiveQuote = () => {
        onArchiveQuoteClick(quote.id)
        hideArchiveDialog()
    }

    const confirmUnarchiveQuote = () => {
        onUnarchiveQuoteClick(quote.id)
        hideArchiveDialog()
    }

    const handleQuoteNameClick = () => {
        navigate(`/quotes/${quote.id}`)
    }

    return (
        <TableRow sx={classes.row}>
            <TableCell
                sx={classes.cell(isDeleted)}
                onClick={handleQuoteNameClick}
            >
                <Typography sx={classes.quoteName}>{quote?.name ?? ''}</Typography>
            </TableCell>
            <TableCell sx={classes.cell(isDeleted)}>{quote?.numberOfItems ?? ''}</TableCell>
            <TableCell sx={classes.cell(isDeleted)}>
                {getFormattedPrice(quote.lineTaxedPrice || 0, organisation.currencyCode, organisation.locale)}
            </TableCell>
            <TableCell sx={classes.cell(isDeleted)}>{quote.createdByUserName}</TableCell>
            <TableCell sx={classes.cell(isDeleted)}>
                <DateTime
                    format="DD-MMM-YYYY"
                    locale={locale}
                >
                    {quote.lastModifiedDateUtc}
                </DateTime>
            </TableCell>
            <TableCell sx={classes.cell(isDeleted)}>
                {t(quote.quoteSourceType === QUOTE_SOURCE_TYPES.Internal ? 'Internal' : 'Web Store')}
            </TableCell>
            <TableCell sx={classes.cell(isDeleted)}>{t(GET_STATUS_LABEL(quote.status))}</TableCell>
            <TableCell
                align="right"
                sx={classes.cell(isDeleted)}
            >
                <TbxTooltip
                    title={t(isDeleted ? 'Unarchive $t(quote)' : 'Archive $t(quote)')}
                    arrow
                >
                    <IconButton
                        size="small"
                        onClick={handleArchiveQuoteClick}
                    >
                        {isDeleted ? (
                            <Unarchive
                                fontSize="small"
                                sx={classes.icon}
                            />
                        ) : (
                            <Archive
                                fontSize="small"
                                sx={classes.disabledIcon(isDeleted)}
                            />
                        )}
                    </IconButton>
                </TbxTooltip>
            </TableCell>
            {showDeleteDialog ? (
                <ArchiveQuoteDialog
                    mode={quote.isDeleted ? 'unarchive' : 'archive'}
                    name={quote?.name}
                    onCancelClose={hideArchiveDialog}
                    onConfirmClose={quote.isDeleted ? confirmUnarchiveQuote : confirmArchiveQuote}
                />
            ) : null}
        </TableRow>
    )
}

QuoteHistoryRow.propTypes = {
    quote: PropTypes.object,
    onArchiveQuoteClick: PropTypes.func,
    onUnarchiveQuoteClick: PropTypes.func,
}

export default QuoteHistoryRow
