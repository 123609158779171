import NetworkManager from '../managers/NetworkManager/NetworkManager'

class PdfService {
    getPdf = (documentName, cssContent, htmlBody) => {
        return new Promise((resolve, reject) => {
            NetworkManager.postWithoutAuthentication(
                '/pdf/v2',
                {
                    documentName,
                    cssContent,
                    htmlBody,
                },
                { responseType: 'blob' }
            )
                .then((response) => {
                    resolve(response.data)
                })
                .catch((error) => {
                    reject(error)
                })
        })
    }
}

export default new PdfService()
