import { webStoreApi } from './webStoreApi'

export const webStoreAuthApi = webStoreApi.injectEndpoints({
    endpoints: (builder) => ({
        login: builder.mutation({
            query: ({ emailAddress, organisationId }) => ({
                url: `/${organisationId}/temp-login`,
                method: 'POST',
                body: { emailAddress },
            }),
        }),
        validateCode: builder.mutation({
            query: ({ code, emailAddress, organisationId }) => ({
                url: `/${organisationId}/temp-login/verify`,
                method: 'POST',
                body: { emailAddress, code },
            }),
        }),
    }),
})

export const { useLoginMutation, useValidateCodeMutation } = webStoreAuthApi
