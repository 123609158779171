import { useTranslation } from 'react-i18next'
import { Box, Typography } from '@mui/material'
import PropTypes from 'prop-types'

import { getAddressDetails } from '@/common/helpers/addressUtilities'

const AccountingDocumentsCustomerInfo = ({
    billingAddress,
    customer,
    customerContact,
    deliveryAddress,
    deliveryOption,
    deliveryPricingMethod,
    lastModifiedByUserName,
    pickupAddress,
}) => {
    const { t } = useTranslation()
    return (
        <Box
            className="quote-info"
            display="flex"
            flex={1}
            flexDirection="column"
            gap={2}
        >
            <Box
                className="quote-customer-info"
                display="flex"
                flexDirection="column"
                gap={0.5}
            >
                <Typography
                    color="text.secondary"
                    variant="strong1"
                >
                    {t('To')}
                </Typography>
                <Box
                    display="flex"
                    flexDirection="column"
                    gap={0.5}
                >
                    <Typography variant="body2">{customerContact?.name || ''}</Typography>
                    <Typography variant="strong2">{customer ? customer.companyName : ''}</Typography>
                    <Typography variant="body2">{getAddressDetails(billingAddress)}</Typography>
                    <Typography variant="body2">
                        {[customerContact?.email, customerContact?.phone].filter(Boolean).join(' | ')}
                    </Typography>
                </Box>
            </Box>

            <Box
                display="flex"
                gap={1}
            >
                <Typography
                    color="text.secondary"
                    variant="strong2"
                >
                    {t('Last modified by:')}
                </Typography>
                <Typography variant="body2">{`${lastModifiedByUserName}`}</Typography>
            </Box>

            <Box
                className="quote-shipping-info"
                display="flex"
                flexDirection="column"
                gap={0.5}
            >
                <Typography
                    color="text.secondary"
                    variant="strong1"
                >
                    Shipping
                </Typography>

                <Typography variant="body2">
                    {deliveryOption === 0
                        ? `${t('Pickup from ')} ${getAddressDetails(pickupAddress) || t('N/A')}`
                        : `${t('Delivery to ')} ${
                              getAddressDetails(deliveryAddress) || t('N/A')
                          }. ${deliveryPricingMethod ? `(${deliveryPricingMethod})` : ''}`}
                </Typography>
            </Box>
        </Box>
    )
}

AccountingDocumentsCustomerInfo.propTypes = {
    billingAddress: PropTypes.object,
    customer: PropTypes.object,
    customerContact: PropTypes.object,
    deliveryAddress: PropTypes.object,
    deliveryOption: PropTypes.number,
    deliveryPricingMethod: PropTypes.string,
    lastModifiedByUserName: PropTypes.string,
    pickupAddress: PropTypes.object,
}

export default AccountingDocumentsCustomerInfo
