import { setSelectedCustomer, setSelectedCustomerId } from '../slices/customersSlice'

import { api } from './api'

export const customersApi = api
    .enhanceEndpoints({
        addTagTypes: ['Customers'],
    })
    .injectEndpoints({
        endpoints: (builder) => ({
            getCustomers: builder.query({
                query: ({ organisationId, params }) => ({
                    url: `/organisations/${organisationId}/customers`,
                    params,
                }),
                providesTags: (result, _error, _arg) =>
                    result
                        ? [
                              { type: 'Customers', id: 'LIST' },
                              ...result.map(({ customerId }) => ({ type: 'Customers', id: customerId })),
                          ]
                        : [{ type: 'Customers', id: 'LIST' }],
                transformResponse: (response) => {
                    return response?.sort((a, b) =>
                        a.companyName.toLowerCase().localeCompare(b.companyName.toLowerCase())
                    )
                },
            }),

            getCustomer: builder.query({
                query: ({ customerId, organisationId }) => `/organisations/${organisationId}/customers/${customerId}`,
                providesTags: (result, _error, arg) => [{ type: 'Customers', id: arg.customerId }],
            }),

            createCustomer: builder.mutation({
                query: ({ customer, organisationId }) => ({
                    url: `/organisations/${organisationId}/customers`,
                    method: 'POST',
                    body: customer,
                }),
                invalidatesTags: [{ type: 'Customers', id: 'LIST' }],
            }),

            updateCustomer: builder.mutation({
                query: ({ customer, organisationId }) => ({
                    url: `/organisations/${organisationId}/customers/${customer.customerId}`,
                    method: 'PUT',
                    body: customer,
                }),
                async onQueryStarted({ customer, organisationId }, { dispatch, queryFulfilled }) {
                    dispatch(
                        customersApi.util.updateQueryData(
                            'getCustomers',
                            { customerId: customer.customerId, organisationId },
                            (draft) => {
                                const index = draft.findIndex((c) => c.customerId === customer.customerId)
                                draft[index] = customer
                            }
                        )
                    )

                    try {
                        await queryFulfilled
                        dispatch(setSelectedCustomer(customer))
                        dispatch(setSelectedCustomerId(customer.customerId))
                        dispatch(customersApi.util.invalidateTags([{ type: 'Customers', id: 'LIST' }]))
                    } catch {
                        //patchResult.undo()
                        dispatch(
                            customersApi.util.invalidateTags([
                                { type: 'Customers', id: customer.customerId },
                                { type: 'Customers', id: 'LIST' },
                            ])
                        )
                    }
                },
            }),

            archiveCustomer: builder.mutation({
                query: ({ customerId, organisationId }) => ({
                    url: `/organisations/${organisationId}/customers/${customerId}`,
                    method: 'DELETE',
                }),
                invalidatesTags: (result, error, arg) => [{ type: 'Customers', id: arg.customerId }],
            }),

            unarchiveCustomer: builder.mutation({
                query: ({ customerId, organisationId }) => ({
                    url: `/organisations/${organisationId}/customers/${customerId}/unarchive`,
                    method: 'PUT',
                }),
                invalidatesTags: (result, error, arg) => [{ type: 'Customers', id: arg.customerId }],
            }),
        }),
    })

export const {
    useArchiveCustomerMutation,
    useCreateCustomerMutation,
    useGetCustomerQuery,
    useGetCustomersQuery,
    useLazyGetCustomerQuery,
    useUnarchiveCustomerMutation,
    useUpdateCustomerMutation,
} = customersApi
