import { useMemo, useState } from 'react'
import { Trans, useTranslation } from 'react-i18next'
import { useDispatch, useSelector } from 'react-redux'
import { Add } from '@mui/icons-material'
import { Box, Button, FormControl, InputLabel, Link, MenuItem, Select, Typography } from '@mui/material'
import PropTypes from 'prop-types'

import { useGetCuttingTechnologiesQuery } from '@/app/services/cuttingTechnologies'
import { useGetRateTablesQuery } from '@/app/services/rateTables'
import {
    selectIsOnFreePlan,
    selectOrganisationId,
    selectPaidFeatures,
    selectReduceTrialAccess,
} from '@/app/slices/organisationSlice'
import { setShowAddRateTableForm } from '@/app/slices/rateTablesSlice'
import { TbxShowToggle, TbxTooltip } from '@/common/components'
import TbxToolbarSearch from '@/common/components/TbxToolbar/TbxToolbarSearch'

import RateTablesListItem from './RateTablesListItem'

const classes = {
    searchInput: {
        width: '100%',
        '& input::placeholder': {
            fontSize: '16px',
            lineHeight: '24px',
            color: 'black',
        },
        mb: 1,
    },
    toggleContainer: {
        margin: '8px 0 16px 0',
    },
}

const compareRateTables = (a, b) => {
    if (a.name.toLocaleLowerCase() < b.name.toLocaleLowerCase()) {
        return -1
    }
    if (a.name.toLocaleLowerCase() > b.name.toLocaleLowerCase()) {
        return 1
    }
    return 0
}

const RateTablesList = ({ onClose }) => {
    const { t } = useTranslation()
    const dispatch = useDispatch()
    

    const organisationId = useSelector(selectOrganisationId)
    const paidFeatures = useSelector(selectPaidFeatures)
    const reduceTrialAccess = useSelector(selectReduceTrialAccess)
    const isOnFreePlan = useSelector(selectIsOnFreePlan)

    const { data: cuttingTechnologies } = useGetCuttingTechnologiesQuery({ organisationId })
    const { data: rateTables, isLoading } = useGetRateTablesQuery({ organisationId })

    const [showArchivedRateTables, setShowArchivedRateTables] = useState(false)

    const [rateTablesSearchValue, setRateTablesSearchValue] = useState('')
    const [rateTablesFilterOption, setRateTablesFilterOption] = useState('All')

    const filterOptions = useMemo(() => {
        const options = [{ label: 'All', value: 'All' }]
        if (!cuttingTechnologies) return options

        Object.values(cuttingTechnologies)
            .filter((ct) => !ct.isDeleted)
            .map((ct) => {
                options.push({ label: `${ct.name} (${ct.type})`, value: ct.cuttingTechnologyId })
            })
        return options
    }, [cuttingTechnologies])

    const filteredRateTables = useMemo(() => {
        if (!rateTables) return []
        const rateTablesArray = Object.values(rateTables)

        const filteredRateTables =
            rateTablesFilterOption && rateTablesFilterOption !== 'All'
                ? rateTablesArray.filter((rt) => rt.cuttingTechnologyId === rateTablesFilterOption)
                : rateTablesArray

        let searchedRateTables = rateTablesSearchValue
            ? filteredRateTables.filter((rateTable) =>
                  rateTable.name.toLocaleLowerCase().includes(rateTablesSearchValue.toLocaleLowerCase())
              )
            : filteredRateTables

        searchedRateTables = searchedRateTables?.slice()?.sort(compareRateTables)

        return showArchivedRateTables ? searchedRateTables : searchedRateTables.filter((rt) => !rt.isDeleted)
    }, [rateTablesSearchValue, rateTablesFilterOption, rateTables, showArchivedRateTables])

    const handleShowArchivedRateTablesChange = () => {
        setShowArchivedRateTables(!showArchivedRateTables)
    }

    const handleSearchValueChange = (value) => {
        setRateTablesSearchValue(value)
    }

    const handleFilterOptionChange = (event) => {
        setRateTablesFilterOption(event.target.value)
    }

    const handleAddRateTableClick = () => {
        dispatch(setShowAddRateTableForm(true))
    }

    const moreThanOneCuttingTechEnabled = paidFeatures?.hasAdvancedMaterials

    return (
        <>
            <FormControl
                size="small"
                sx={{ mt: '72px', mb: 2 }}
                variant="outlined"
                fullWidth
            >
                <InputLabel id="material-filter-label">Cutting Technology</InputLabel>
                <Select
                    data-testid="materials-filter-select"
                    disabled={!moreThanOneCuttingTechEnabled}
                    label="Cutting Technology"
                    labelId="material-filter-label"
                    value={rateTablesFilterOption}
                    onChange={handleFilterOptionChange}
                >
                    {filterOptions.map((option) => (
                        <MenuItem
                            key={option.value}
                            value={option.value}
                        >
                            {option.label}
                        </MenuItem>
                    ))}
                </Select>
            </FormControl>

            <TbxToolbarSearch
                dataTestId="rate-table-toolbar-search"
                handleChange={handleSearchValueChange}
                placeholder="Search rate tables..."
                searchValue={rateTablesSearchValue}
                sx={classes.searchInput}
            />

            <TbxShowToggle
                checked={showArchivedRateTables}
                label={t('Show archived rate tables')}
                sx={classes.toggleContainer}
                onChange={handleShowArchivedRateTablesChange}
            />

            <TbxTooltip
                title={
                    isOnFreePlan ? (
                        <Trans t={t}>
                            Not available in free mode. <Link href="/activate">Subscribe here.</Link>
                        </Trans>
                    ) : reduceTrialAccess ? (
                        <Trans t={t}>
                            Not available in trial mode. <Link href="/activate">Subscribe here.</Link>
                        </Trans>
                    ) : (
                        ''
                    )
                }
                arrow
            >
                <span>
                    <Button
                        color="secondary"
                        data-testid="add-rate-table-button"
                        disabled={reduceTrialAccess}
                        startIcon={<Add />}
                        variant="contained"
                        fullWidth
                        onClick={handleAddRateTableClick}
                    >
                        {t('Add new rate table')}
                    </Button>
                </span>
            </TbxTooltip>

            {isLoading ? (
                <Typography
                    my={3}
                    textAlign="center"
                    variant="body2"
                >
                    {t('Loading rate tables...')}
                </Typography>
            ) : (
                <Box my={2}>
                    {filteredRateTables?.map((rateTable) => (
                        <RateTablesListItem
                            key={rateTable.rateTableId}
                            rateTable={rateTable}
                            onRateTableSelected={onClose}
                        />
                    ))}
                </Box>
            )}
        </>
    )
}

RateTablesList.propTypes = {
    rateTables: PropTypes.arrayOf(PropTypes.object),
    rateTablesFilterValue: PropTypes.string,
    onClose: PropTypes.func,
}

export default RateTablesList
