import { useTranslation } from 'react-i18next'
import { useNavigate, useParams } from 'react-router-dom'
import { Box, Button, Grid, Typography } from '@mui/material'

const classes = {
    root: {
        backgroundColor: 'background.paper',
        borderRadius: 1,
        border: (theme) => `1px ${theme.palette.grey[300]} solid`,
        width: '100%',
        gap: 3,
    },
    notFoundImage: {
        width: '542px',
    },
    notFoundMessage: {
        gap: 1,
    },
}

const NotFound = () => {
    const { organisationId } = useParams()
    const { t } = useTranslation()

    const navigate = useNavigate()

    return (
        <Grid
            component="section"
            xs={12}
            container
            item
        >
            <Box
                alignItems="center"
                display="flex"
                flexDirection="column"
                justifyContent="center"
                p={12}
                sx={classes.root}
            >
                <Box
                    alt="Not Found"
                    component="img"
                    src="404.svg"
                    sx={classes.notFoundImage}
                />

                <Box
                    alignItems="center"
                    display="flex"
                    flexDirection="column"
                    justifyContent="center"
                    sx={classes.notFoundMessage}
                >
                    <Typography variant="h4">{t('Page not found')}</Typography>
                    <Typography variant="body1">{t("We can't find the page you're looking for.")}</Typography>
                </Box>

                <Button
                    color="primary"
                    size="large"
                    variant="contained"
                    onClick={() => navigate(`/store/${organisationId}/`)}
                >
                    Back to Home
                </Button>
            </Box>
        </Grid>
    )
}

export default NotFound
