import MuiLink from '@mui/material/Link'
import PropTypes from 'prop-types'

const Link = (props) => {
    return <MuiLink {...props} />
}

Link.propTypes = {
    classes: PropTypes.object,
    color: PropTypes.oneOf(['initial', 'inherit', 'primary', 'secondary', 'textPrimary', 'textSecondary', 'error']),
    TypographyClasses: PropTypes.object,
    underline: PropTypes.oneOf(['none', 'hover', 'always']),
}

export default Link
