import { useRef, useState } from 'react'
import { Trans, useTranslation } from 'react-i18next'
import { useDispatch, useSelector } from 'react-redux'
import { CloudUploadOutlined, PostAdd, SaveAlt } from '@mui/icons-material'
import { Box, IconButton, Typography } from '@mui/material'
import { useSnackbar } from 'notistack'
import PropTypes from 'prop-types'

import { useGetMaterialsQuery } from '@/app/services/materials'
import { useImportRatesMutation, useLazyDownloadRateTemplateQuery } from '@/app/services/rates'
import { selectOrganisationId } from '@/app/slices/organisationSlice'
import { selectSelectedRateTableId, setSelectedRateTableId } from '@/app/slices/rateTablesSlice'
import { Button, DashedBorderContainer, Dialog, FileUploader } from '@/common/components'
import { browserFileDownload } from '@/common/utils'

const classes = {
    dialog: {
        width: 600,
    },
    dialogContent: {
        display: 'flex',
        flexDirection: 'column',
        gap: '16px',
        alignItems: 'baseline',
        color: 'text.secondary',
        letterSpacing: '0.17px',
        lineHeight: '20px',
    },
    inputLabel: {
        width: 160,
    },
    input: {
        width: 180,
    },
    formInput: {
        width: 400,
    },
    importButton: {
        marginRight: '16px',
    },
    uploadActionContainer: {
        borderRadius: '8px',
        border: (theme) => `dashed 1px ${theme.palette.primary.main}`,
        margin: '8px 0',
        padding: '16px 8px',
        justifyContent: 'flex-start',
    },
    dropzoneLabel: {
        color: 'text.primary',
        marginLeft: '6px',
    },
    bold: {
        fontWeight: 700,
    },
    radioGroup: {
        paddingLeft: '12px',
    },
}

const ImportRates = ({ onClose }) => {
    const { t } = useTranslation()
    const { enqueueSnackbar } = useSnackbar()

    const dispatch = useDispatch()

    const organisationId = useSelector(selectOrganisationId)

    const { refetch } = useGetMaterialsQuery({ organisationId })

    const selectedRateTableId = useSelector(selectSelectedRateTableId)

    const [importRates] = useImportRatesMutation()
    const [downloadTemplate] = useLazyDownloadRateTemplateQuery()

    const emptyFieldsTreatment = 'ignore'

    const [file, setFile] = useState(null)

    const fileUploaderRef = useRef(null)

    const handleSelectFileButton = () => {
        if (fileUploaderRef.current) {
            fileUploaderRef.current.value = null
            fileUploaderRef.current.click()
        }
    }

    const handleDownloadTemplateClick = async () => {
        try {
            const response = await downloadTemplate({ organisationId, rateTableId: selectedRateTableId }).unwrap()

            browserFileDownload(response, 'Rate_table_import_template.csv', 'text/csv')

            enqueueSnackbar(t('Template downloaded successfully'), { variant: 'success' })
        } catch (error) {
            console.error(error)
            enqueueSnackbar(t('Error downloading template'), { variant: 'error' })
        }
    }

    const handleDropzoneAction = (files) => {
        if (files.length !== 1) {
            return
        }
        setFile(files[0])
    }

    const handleImportClick = async () => {
        if (!file) {
            enqueueSnackbar(t('Please select a file to import'), { variant: 'error' })
        }

        try {
            await importRates({ organisationId, rateTableId: selectedRateTableId, file, emptyFieldsTreatment })
            await refetch()
            dispatch(setSelectedRateTableId(selectedRateTableId))
            onClose()
        } catch (error) {
            enqueueSnackbar(t('Error importing file'), { variant: 'error' })
        }
    }

    return (
        <Dialog
            actions={[
                <Button
                    color="primary"
                    disabled={!file}
                    key="add"
                    size="large"
                    startIcon={<PostAdd />}
                    sx={classes.importButton}
                    variant="contained"
                    onClick={handleImportClick}
                >
                    {t('Import')}
                </Button>,
            ]}
            actionsPosition="end"
            className={classes.dialog}
            contentClassName={classes.dialogContent}
            open={true}
            title={t('Import rate table rates')}
            onClose={onClose}
        >
            <Typography variant="body2">
                {t(
                    "Download the template and add rates for various thicknesses. Don't delete the column headings as they are needed for the import to succeed."
                )}
            </Typography>
            <Typography variant="body2">
                {t(
                    'To update thicknesses already in the rate table, export them to a CSV file and edit in the spreadsheet before importing again. Existing thicknesses will be updated if the values in the "Thickness" column matches the details of an entry that already exists.'
                )}
            </Typography>

            <Button
                color="secondary"
                startIcon={<SaveAlt />}
                variant="outlined"
                onClick={handleDownloadTemplateClick}
            >
                {t('Download template')}
            </Button>

            <FileUploader
                acceptedFileTypes={['.csv', '.xlsx']}
                dragActiveText={t('Browse and select a CSV file or Drag and Drop one here')}
                ref={fileUploaderRef}
                singleFileUpload={true}
                onFilesAccepted={handleDropzoneAction}
            >
                <Box sx={classes.dialogActionsContainer}>
                    <DashedBorderContainer className={classes.uploadActionContainer}>
                        <IconButton
                            color="primary"
                            size="large"
                            disableRipple
                            onClick={handleSelectFileButton}
                        >
                            <CloudUploadOutlined style={{ fontSize: 36 }} />
                        </IconButton>
                        <Box sx={classes.dialogTextContainer}>
                            {!file ? (
                                <Typography
                                    sx={classes.dropzoneLabel}
                                    variant="body2"
                                >
                                    <Trans t={t}>
                                        Browse and select a{' '}
                                        <Box
                                            component="span"
                                            sx={classes.bold}
                                        >
                                            CSV
                                        </Box>{' '}
                                        file or Drag and Drop one here.
                                    </Trans>
                                </Typography>
                            ) : null}
                            {file ? (
                                <Typography
                                    sx={classes.dropzoneLabel}
                                    variant="body2"
                                >
                                    {file.name}
                                </Typography>
                            ) : null}
                        </Box>
                    </DashedBorderContainer>
                </Box>
            </FileUploader>
        </Dialog>
    )
}

ImportRates.propTypes = {
    onClose: PropTypes.func,
}

export default ImportRates
