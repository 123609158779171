import { Controller, useForm } from 'react-hook-form'
import { useTranslation } from 'react-i18next'
import { useSelector } from 'react-redux'
import { CloseOutlined, CloseRounded } from '@mui/icons-material'
import {
    Box,
    Dialog,
    DialogActions,
    DialogContent,
    DialogTitle,
    Divider,
    IconButton,
    List,
    ListItem,
    ListItemText,
    Switch,
    TextField,
} from '@mui/material'
import Button from '@mui/material/Button'
import Typography from '@mui/material/Typography'
import { useSnackbar } from 'notistack'
import PropTypes from 'prop-types'

import { useCreateDeliveryZoneMutation, useUpdateDeliveryZoneMutation } from '@/app/services/deliveryZones'
import { selectOrganisationId } from '@/app/slices/organisationSlice'
import FormLocationPicker from '@/common/components/LocationPicker/LocationPicker'

const AddDeliveryZoneDialog = ({ deliveryZone, nextIndex, onClose, open }) => {
    const { t } = useTranslation()
    const { enqueueSnackbar } = useSnackbar()

    const organisationId = useSelector(selectOrganisationId)

    const [createDeliveryZone, { isLoading: isCreating }] = useCreateDeliveryZoneMutation()
    const [updateDeliveryZone, { isLoading: isUpdating }] = useUpdateDeliveryZoneMutation()

    const {
        control,
        formState: { isDirty, isValid },
        handleSubmit,
        reset,
        setValue,
        watch,
    } = useForm({
        mode: 'all',
        defaultValues: {
            name: deliveryZone?.name ?? '',
            zoneRegions: deliveryZone?.zoneRegions ?? [],
        },
    })

    const minLocations = (locations) => {
        return locations?.length > 0 || t('At least one region must be provided.')
    }

    const currentZoneRegions = watch('zoneRegions').filter((zoneRegion) => 'region' in zoneRegion)
    const newZoneRegions = watch('zoneRegions').filter((zoneRegion) => !('region' in zoneRegion))

    const handleAddOrUpdateButtonClick = async (deliveryZoneData, _e) => {
        const zoneRegions = deliveryZoneData.zoneRegions.map((zoneRegion) => {
            if ('region' in zoneRegion) return zoneRegion
            return { region: zoneRegion.description, placeId: zoneRegion.place_id }
        })

        // if zone exists, update it instead of creating a new one
        if (deliveryZone) {
            try {
                await updateDeliveryZone({
                    organisationId,
                    zone: { ...deliveryZone, name: deliveryZoneData.name, zoneRegions },
                }).unwrap()
                enqueueSnackbar(t('Delivery zone updated successfully'), { variant: 'success' })
            } catch (error) {
                console.error(error)
                enqueueSnackbar(t('Failed to update delivery zone'), { variant: 'error' })
            } finally {
                reset()
            }
        } else {
            try {
                await createDeliveryZone({
                    organisationId,
                    zone: {
                        name: deliveryZoneData.name,
                        zoneRegions,
                        isDeleted: false,
                        index: nextIndex,
                    },
                }).unwrap()
                enqueueSnackbar(t('Delivery zone added successfully'), { variant: 'success' })
            } catch (error) {
                console.error(error)
                enqueueSnackbar(t('Failed to add delivery zone'), { variant: 'error' })
            } finally {
                reset()
            }
        }

        onClose()
    }

    const closeDialog = () => {
        reset()
        onClose()
    }

    return (
        <Dialog
            maxWidth="xs"
            open={open}
            sx={{
                '& .MuiDialog-paper': {
                    borderRadius: 2,
                    p: 2,
                },
            }}
            fullWidth
            onClose={closeDialog}
        >
            <DialogTitle
                alignItems="center"
                display="flex"
                justifyContent="space-between"
                sx={{ p: 2 }}
            >
                {deliveryZone ? (
                    <Typography variant="h6">{t('Edit delivery zone')}</Typography>
                ) : (
                    <Typography variant="h6">{t('Add delivery zone')}</Typography>
                )}
                <IconButton
                    size="small"
                    onClick={closeDialog}
                >
                    <CloseRounded fontSize="small" />
                </IconButton>
            </DialogTitle>

            <DialogContent sx={{ p: 2 }}>
                <Box
                    display="flex"
                    flexDirection="column"
                    gap={2}
                >
                    <Controller
                        control={control}
                        name="name"
                        render={({ field: { onBlur, onChange, value }, fieldState }) => (
                            <TextField
                                error={fieldState.invalid}
                                helperText={fieldState.error?.message || ''}
                                label={t('Delivery zone name')}
                                value={value}
                                autoFocus
                                fullWidth
                                required
                                onBlur={onBlur}
                                onChange={onChange}
                            />
                        )}
                        rules={{ required: 'Required' }}
                    />

                    <Box>
                        <Typography
                            color="text.primary"
                            mt={2}
                            variant="body1"
                        >
                            {t('Select regions where delivery is available:')}
                        </Typography>

                        <FormLocationPicker
                            control={control}
                            label={t('Start typing a location')}
                            name="zoneRegions"
                            requestOptionTypes={['(regions)']}
                            rules={{
                                validate: (value) => minLocations(value),
                            }}
                            showChips={false}
                            variant="standard"
                            required
                        />
                    </Box>

                    <Box>
                        {newZoneRegions?.length > 0 ? (
                            <>
                                <Typography
                                    color="text.primary"
                                    variant="strong2"
                                >
                                    {t('New regions to be added:')}
                                </Typography>
                                <List dense>
                                    {newZoneRegions?.map((zoneRegion, index) => (
                                        <ListItem
                                            key={index}
                                            secondaryAction={
                                                <IconButton
                                                    aria-label="delete"
                                                    color="secondary"
                                                    edge="end"
                                                    onClick={() => {
                                                        const newZoneRegions = [...watch('zoneRegions')]
                                                        const index = newZoneRegions.findIndex(
                                                            (item) => item.description === zoneRegion.description
                                                        )
                                                        newZoneRegions.splice(index, 1)
                                                        setValue('zoneRegions', newZoneRegions, { shouldDirty: true })
                                                    }}
                                                >
                                                    <CloseOutlined />
                                                </IconButton>
                                            }
                                            disableGutters
                                        >
                                            <ListItemText
                                                id={`switch-list-label-${zoneRegion.description}`}
                                                primary={zoneRegion.description}
                                            />
                                        </ListItem>
                                    ))}
                                </List>
                                <Divider sx={{ marginBottom: 2 }} />
                            </>
                        ) : null}

                        {currentZoneRegions?.length > 0 ? (
                            <>
                                <Typography
                                    color="text.primary"
                                    variant="strong2"
                                >
                                    {t('Current regions:')}
                                </Typography>
                                <List dense>
                                    {currentZoneRegions?.map((zoneRegion, index) => (
                                        <ListItem
                                            key={index}
                                            disableGutters
                                        >
                                            <ListItemText
                                                id={`switch-list-label-${zoneRegion.region}`}
                                                primary={zoneRegion.region || zoneRegion.description}
                                            />
                                            <Switch
                                                checked={!zoneRegion.isDeleted}
                                                color="secondary"
                                                edge="end"
                                                inputProps={{
                                                    'aria-labelledby': `switch-list-label-${zoneRegion.region}`,
                                                }}
                                                size="small"
                                                onChange={() => {
                                                    const newZoneRegions = [...watch('zoneRegions')]
                                                    newZoneRegions[index].isDeleted = !zoneRegion.isDeleted
                                                    setValue('zoneRegions', newZoneRegions, { shouldDirty: true })
                                                }}
                                            />
                                        </ListItem>
                                    ))}
                                </List>
                            </>
                        ) : null}
                    </Box>
                </Box>
            </DialogContent>

            <DialogActions sx={{ p: 2 }}>
                <Button
                    color="secondary"
                    disabled={isCreating || isUpdating}
                    key="cancel"
                    variant="outlined"
                    onClick={closeDialog}
                >
                    {t('Cancel')}
                </Button>
                <Button
                    color="primary"
                    disabled={!isDirty || !isValid || isCreating || isUpdating}
                    key="add"
                    variant="contained"
                    onClick={handleSubmit(handleAddOrUpdateButtonClick)}
                >
                    {deliveryZone
                        ? isUpdating
                            ? t('Updating...')
                            : t('Update delivery zone')
                        : isCreating
                          ? t('Adding...')
                          : t('Add delivery zone')}
                </Button>
            </DialogActions>
        </Dialog>
    )
}

AddDeliveryZoneDialog.defaultProps = {
    open: false,
}

AddDeliveryZoneDialog.propTypes = {
    deliveryZone: PropTypes.object,
    nextIndex: PropTypes.number,
    open: PropTypes.bool,
    onClose: PropTypes.func,
}

export default AddDeliveryZoneDialog
