import { useTranslation } from 'react-i18next'
import { useSelector } from 'react-redux'
import {
    Box,
    Checkbox,
    Link,
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableHead,
    TableRow,
    TextField,
    Typography,
} from '@mui/material'
import PropTypes from 'prop-types'

import { selectLocale } from '@/app/slices/appSlice'
import { DateTime, OrganisationLogo, ThumbnailUri } from '@/common/components'
import { getAddressDetails } from '@/common/helpers/addressUtilities'
import { useNumberFormatter } from '@/common/hooks'
import { MiscItemIcon } from '@/common/icons'
import CustomerSuppliedMaterialIcon from '@/common/icons/CustomerSuppliedMaterialIcon/CustomerSuppliedMaterialIcon'
import { DELIVERY_PRICING_METHODS, hasSevereValidationIssues, quoteItemHasErrors } from '@/common/utils'

import FoldingDetailsDisplay from '../../components/FoldingDetailsDisplay'
import ProductionDocumentsCustomerInfo from '../../components/ProductionDocumentsCustomerInfo'
import SecondaryProcessDetailsDisplay from '../../components/SecondaryProcessDetailsDisplay'

const classes = {
    documentContainer: {
        width: 1024,
        margin: '24px auto',
    },
    card: {
        backgroundColor: 'common.white',
        border: 1,
        borderColor: 'grey.300',
        borderRadius: 2,
        py: 3,
        px: 3,
        my: 3,
    },

    footerTextContent: {
        '& p': {
            margin: 0,
        },
        '& p:first-of-type': {
            color: 'text.primary',
            '@media print': {
                color: '#2b2b2b',
            },
        },
    },
    footer: {
        '@media print': {
            position: 'relative',
            textAlign: 'center',
            bottom: 0,
            left: '50%',
            transform: 'translate(-50%, 0)',
        },
    },
    customerSuppliedMaterial: {
        display: 'flex',
        flexDirection: 'row',
        justifyContent: 'space-between',
        alignItems: 'center',
    },
    selectionCheckbox: {
        pointerEvents: 'none',
    },
}

const DeliveryDocket = ({ customer, customerContact, issueSeverityDictionary, organisation, quote, showShipping }) => {
    const { t } = useTranslation()

    const locale = useSelector(selectLocale)

    const { n: metricNumberFormatter } = useNumberFormatter({
        numberOfDecimalPlaces: 2,
    })

    const { n: imperialNumberFormatter } = useNumberFormatter({
        numberOfDecimalPlaces: 3,
    })

    const { n: q } = useNumberFormatter({ numberOfDecimalPlaces: 0 })

    const formatNumberWithX = (str) => {
        const parts = str.split('x')
        const formattedParts = parts.map((part) =>
            part.isImperial ? imperialNumberFormatter(Number(part)) : metricNumberFormatter(Number(part))
        )
        return formattedParts.join(' x ')
    }

    const units = organisation?.defaultDrawingUnits?.toString().toLowerCase() === 'imperial' ? 'in' : 'mm'
    const partUnits = (part) => (part.isImperial ? 'in' : 'mm')
    const weightUnits = organisation?.defaultDrawingUnits?.toString().toLowerCase() === 'imperial' ? 'lb' : 'kg'

    const partHasAnyErrors = (part) => {
        const isNull = part === null
        const hasErrors =
            quoteItemHasErrors(part, organisation.defaultDrawingUnits, issueSeverityDictionary) ||
            hasSevereValidationIssues(part)
        return isNull || hasErrors
    }

    const renderPartDetails = (part) => {
        if (partHasAnyErrors(part))
            return (
                <Typography variant="small">{t('Part issue. This part is not included in this $t(quote).')}</Typography>
            )
        else
            return (
                <>
                    <Typography variant="body2">{t('Cutting')} </Typography>
                    {/* <OtherChargeDetailsDisplay part={part} /> */}
                    <FoldingDetailsDisplay part={part} />
                    <SecondaryProcessDetailsDisplay part={part} />
                </>
            )
    }

    const renderPartNameAndId = (part) => {
        if (!part.partId) return <Typography variant="body2">{part.name}</Typography>
        else
            return (
                <>
                    <Typography variant="body2">{part.name}</Typography>
                    <Typography variant="body2">{t('ID: ') + part.partId}</Typography>
                </>
            )
    }

    const getMaterialText = (part) => {
        if (part.material) {
            if (part.material.materialName && part.thickness) {
                return (
                    <>
                        {part.material.materialName.toString()}
                        <br />
                        {t('Thickness')}: {`${part.thickness.toString()} ${units}`}
                        {part.isCustomerSuppliedMaterial ? (
                            <Box sx={classes.customerSuppliedMaterial}>
                                {t('Customer-supplied')}
                                <CustomerSuppliedMaterialIcon
                                    color="secondary"
                                    sx={{ verticalAlign: 'bottom' }}
                                />
                            </Box>
                        ) : undefined}
                    </>
                )
            }
        }
        return 'N/A'
    }

    const renderWeight = (part) => {
        return part.mass ? `${t('Unit weight:')} ${part.mass?.toFixed(2)?.toString()} ${weightUnits}` : null
    }

    const renderPartSize = (part) => {
        if (part.displayBoundBoxWidth && part.displayBoundBoxHeight) {
            const n = part.isImperial ? imperialNumberFormatter : metricNumberFormatter
            const bounds = part.profile
                ? part.profile.toLowerCase() === 'circle'
                    ? `${n(part.sheet?.diameter)} x ${n(part.rotaryPartLength)}`
                    : `${n(part.sheet?.sheetWidth)} x ${n(part.sheet?.sheetHeight)} x ${n(part.rotaryPartLength)}`
                : `${n(part.displayBoundBoxWidth)} x ${n(part.displayBoundBoxHeight)}`
            return <div style={{ whiteSpace: 'break-spaces' }}>{`${bounds} ${partUnits(part)}`}</div>
        }
        return <div>{'-'}</div>
    }

    const sortQuoteItems = (quoteItems) => {
        return quoteItems.toSorted((a, b) => a.index - b.index)
    }

    const sortMiscItems = (miscItems) => {
        return miscItems.toSorted((a, b) => a.index - b.index)
    }

    const deliveryPricingMethod = showShipping ? DELIVERY_PRICING_METHODS[quote.deliveryPricingMethod]?.documentLabel : ''

    return quote && quote.organisationId && quote.quoteItems ? (
        <Box sx={classes.documentContainer}>
            <Box
                alignItems="flex-end"
                display="flex"
                gap={2}
                id="QuoteHeader"
                justifyContent="space-between"
                sx={classes.card}
            >
                <Box
                    className="quote-info"
                    display="flex"
                    flex={2}
                    flexDirection="column"
                    gap={2}
                >
                    <Typography
                        textTransform="uppercase"
                        variant="h4"
                    >
                        {`${t('Delivery docket')}: `}
                        <Typography
                            component="span"
                            fontWeight={700}
                            textTransform="uppercase"
                            variant="h4"
                        >
                            {quote.workOrderNumberText}
                        </Typography>
                    </Typography>
                    {customer ? (
                        <ProductionDocumentsCustomerInfo
                            customer={customer}
                            customerContact={customerContact}
                            deliveryAddress={quote.deliveryAddress}
                            deliveryOption={quote.deliveryOption}
                            deliveryPricingMethod={deliveryPricingMethod}
                            lastModifiedByUserName={quote.lastModifiedByUserName}
                            pickupAddress={organisation.pickupAddress}
                        />
                    ) : null}
                </Box>

                <Box
                    alignItems="flex-end"
                    alignSelf="stretch"
                    className="quote-logo-info"
                    display="flex"
                    flex={1}
                    flexDirection="column"
                    gap={2}
                    justifyContent="space-between"
                >
                    <OrganisationLogo
                        organisationId={quote.organisationId}
                        organisationLogoUri={organisation.logoUri}
                        wrapperHeight={100}
                        wrapperWidth="auto"
                    />
                    <Box
                        alignItems="flex-end"
                        className="quote-details-info"
                        display="flex"
                        flexDirection="column"
                        gap={0.5}
                    >
                        {quote.purchaseReference ? (
                            <Box
                                display="flex"
                                gap={1}
                            >
                                <Typography
                                    color="text.secondary"
                                    variant="body2"
                                >
                                    {t('Purchase reference:')}
                                </Typography>
                                <Typography
                                    minWidth={80}
                                    textAlign="right"
                                    variant="strong2"
                                >
                                    {quote.purchaseReference}
                                </Typography>
                            </Box>
                        ) : null}
                        <Box
                            display="flex"
                            gap={1}
                        >
                            <Typography
                                color="text.secondary"
                                variant="body2"
                            >
                                {t('Quote number:')}
                            </Typography>
                            <Typography
                                minWidth={80}
                                textAlign="right"
                                variant="strong2"
                            >
                                {quote.name}
                            </Typography>
                        </Box>
                        <Box
                            display="flex"
                            gap={1}
                        >
                            <Typography
                                color="text.secondary"
                                variant="body2"
                            >
                                {t('PO number:')}
                            </Typography>
                            <Typography
                                minWidth={80}
                                textAlign="right"
                                variant="strong2"
                            >
                                {quote.poNumber}
                            </Typography>
                        </Box>

                        {quote.orderedDateUtc ? (
                            <Box
                                display="flex"
                                gap={1}
                            >
                                <Typography
                                    color="text.secondary"
                                    variant="body2"
                                >
                                    {t('Ordered date:')}
                                </Typography>
                                <Typography
                                    minWidth={80}
                                    textAlign="right"
                                    variant="body2"
                                >
                                    <DateTime
                                        format="DD-MMM-YYYY"
                                        locale={locale}
                                    >
                                        {quote.orderedDateUtc}
                                    </DateTime>
                                </Typography>
                            </Box>
                        ) : null}

                        {quote.requiredDateUtc ? (
                            <Box
                                display="flex"
                                gap={1}
                            >
                                <Typography
                                    color="text.secondary"
                                    variant="body2"
                                >
                                    {t('Required shipping date:')}
                                </Typography>
                                <Typography
                                    minWidth={80}
                                    textAlign="right"
                                    variant="strong2"
                                >
                                    <DateTime
                                        format="DD-MMM-YYYY"
                                        locale={locale}
                                    >
                                        {quote.requiredDateUtc}
                                    </DateTime>
                                </Typography>
                            </Box>
                        ) : null}
                    </Box>
                </Box>
            </Box>

            <Box
                display="flex"
                flexDirection="column"
                gap={1.5}
                height="min-content"
                id="deliveryNotes"
                justifyContent="space-between"
                sx={classes.card}
            >
                <Typography variant="h6">{t('Delivery notes')}</Typography>
                <Box
                    alignItems="flex-end"
                    alignSelf="flex-end"
                    display="flex"
                    gap={1}
                >
                    <Typography variant="body2">{t('Signature')}:</Typography>
                    <TextField sx={{ pointerEvents: 'none' }} />
                </Box>
                <Box
                    alignItems="flex-end"
                    alignSelf="flex-end"
                    display="flex"
                    gap={1}
                >
                    <Typography variant="body2">{t('Printed name')}:</Typography>
                    <TextField sx={{ pointerEvents: 'none' }} />
                </Box>
                <Box
                    alignItems="flex-end"
                    alignSelf="flex-end"
                    display="flex"
                    gap={1}
                >
                    <Typography variant="body2">{t('Date')}:</Typography>
                    <TextField sx={{ pointerEvents: 'none' }} />
                </Box>
            </Box>

            <Box
                display="flex"
                flexDirection="column"
                gap={1}
                id="orderNotes"
                minHeight="200px"
                sx={[classes.card, { pb: 10 }]}
            >
                <Typography variant="h6">{t('Order notes')}</Typography>
                {quote.customerNotes ? (
                    <Box>
                        <Typography
                            component="span"
                            variant="strong2"
                        >
                            {t('Customer notes') + ': '}
                        </Typography>
                        <Typography
                            component="span"
                            variant="body2"
                        >
                            {quote.customerNotes}
                        </Typography>
                    </Box>
                ) : null}
                {quote.vendorNotes ? (
                    <Box>
                        <Typography
                            component="span"
                            variant="strong2"
                        >
                            {t('Vendor notes') + ': '}
                        </Typography>
                        <Typography
                            component="span"
                            variant="body2"
                        >
                            {quote.vendorNotes}
                        </Typography>
                    </Box>
                ) : null}
            </Box>

            <Box
                id="QuoteContent"
                sx={classes.card}
            >
                <TableContainer>
                    <Table
                        aria-label="Prices table"
                        style={{ tableLayout: 'fixed' }}
                        sx={{ minWidth: 952 }}
                    >
                        <colgroup>
                            <col style={{ width: '30px' }} />
                            <col style={{ width: '100px' }} />
                            <col style={{ width: '200px' }} />
                            <col style={{ width: '180px' }} />
                            <col style={{ width: '120px' }} />
                            <col style={{ width: '60px' }} />
                            <col style={{ width: '100px' }} />
                        </colgroup>
                        <TableHead>
                            <TableRow sx={{ verticalAlign: 'bottom', height: 60 }}>
                                <TableCell align="left">#</TableCell>
                                <TableCell align="center">{t('Part')}</TableCell>
                                <TableCell align="left">{t('Part details')}</TableCell>
                                <TableCell align="left">{t('Material details')}</TableCell>
                                <TableCell align="left">{t('Processes')}</TableCell>
                                <TableCell
                                    align="right"
                                    sx={{ borderRight: '1px dashed rgba(52, 73, 94, 0.38)' }}
                                >
                                    {t('Quantity')}
                                </TableCell>
                                <TableCell>{t('Checked by')}</TableCell>
                            </TableRow>
                        </TableHead>
                        <TableBody>
                            {sortQuoteItems(quote.quoteItems).map((row, index) => {
                                const cellStyle =
                                    row.customerNotes || row.vendorNotes
                                        ? {
                                              borderBottom: '1px dashed rgba(52, 73, 94, 0.38)',
                                              marginBottom: 10,
                                          }
                                        : null
                                return (
                                    <>
                                        <TableRow key={row.id}>
                                            <TableCell
                                                align="left"
                                                component="th"
                                                scope="row"
                                                sx={[{ py: 1.5 }, cellStyle]}
                                            >
                                                {index + 1}
                                            </TableCell>
                                            <TableCell
                                                align="center"
                                                sx={[{ py: 1.5 }, cellStyle]}
                                            >
                                                <ThumbnailUri
                                                    lazyLoading={false}
                                                    uri={row.thumbnailUri}
                                                />
                                            </TableCell>
                                            <TableCell
                                                align="left"
                                                sx={[{ py: 1.5, pr: 2 }, cellStyle]}
                                            >
                                                {renderPartNameAndId(row)}
                                                {renderPartSize(row)}
                                                {renderWeight(row)}
                                            </TableCell>
                                            <TableCell
                                                align="left"
                                                sx={[{ py: 1.5 }, cellStyle]}
                                            >
                                                {getMaterialText(row)}
                                            </TableCell>
                                            <TableCell
                                                align="left"
                                                sx={[{ py: 1.5 }, cellStyle]}
                                            >
                                                {renderPartDetails(row)}
                                            </TableCell>
                                            <TableCell
                                                align="right"
                                                sx={[
                                                    {
                                                        py: 1.5,
                                                        borderRight: '1px dashed rgba(52, 73, 94, 0.38)',
                                                        typography: 'strong2',
                                                    },
                                                    cellStyle,
                                                ]}
                                            >
                                                {q(row.quantity)}
                                            </TableCell>
                                            <TableCell sx={[{ py: 1.5 }, cellStyle]}>
                                                {!partHasAnyErrors(row) ? (
                                                    <>
                                                        <Checkbox sx={classes.selectionCheckbox} />
                                                        {t('Vendor')}

                                                        <Checkbox sx={classes.selectionCheckbox} />
                                                        {t('Customer')}
                                                    </>
                                                ) : null}
                                            </TableCell>
                                        </TableRow>

                                        {row.customerNotes ? (
                                            <TableRow>
                                                <TableCell
                                                    colSpan={7}
                                                    sx={[{ pt: 2 }, row.vendorNotes ? { borderBottom: 'none' } : null]}
                                                >
                                                    <TextField
                                                        InputLabelProps={{
                                                            sx: { typography: 'strong1' },
                                                        }}
                                                        InputProps={{ sx: { typography: 'body2' } }}
                                                        label={t('Customer Notes')}
                                                        size="medium"
                                                        value={row.customerNotes}
                                                        variant="outlined"
                                                        fullWidth
                                                    />
                                                </TableCell>
                                            </TableRow>
                                        ) : null}

                                        {row.vendorNotes ? (
                                            <TableRow>
                                                <TableCell
                                                    colSpan={7}
                                                    sx={{ pt: 2 }}
                                                >
                                                    <TextField
                                                        InputLabelProps={{
                                                            sx: { typography: 'strong1' },
                                                        }}
                                                        InputProps={{ sx: { typography: 'body2' } }}
                                                        label={t('Vendor Notes')}
                                                        size="medium"
                                                        value={row.vendorNotes}
                                                        variant="outlined"
                                                        fullWidth
                                                    />
                                                </TableCell>
                                            </TableRow>
                                        ) : null}
                                    </>
                                )
                            })}

                            {sortMiscItems(quote.miscellaneousItems).map((row, index) => {
                                const cellStyle =
                                    row.customerNotes || row.vendorNotes
                                        ? {
                                              borderBottom: '1px dashed rgba(52, 73, 94, 0.38)',
                                              marginBottom: 10,
                                          }
                                        : null
                                return (
                                    <>
                                        <TableRow key={row.id}>
                                            <TableCell
                                                align="left"
                                                component="th"
                                                scope="row"
                                                sx={[{ py: 1.5 }, cellStyle]}
                                            >
                                                {quote.quoteItems.length + index + 1}
                                            </TableCell>
                                            <TableCell
                                                align="center"
                                                sx={[{ py: 1.5 }, cellStyle]}
                                            >
                                                <MiscItemIcon
                                                    fontSize="large"
                                                    titleAccess={t('Miscellaneous item icon')}
                                                />
                                            </TableCell>
                                            <TableCell
                                                align="left"
                                                colSpan={3}
                                                sx={[{ py: 1.5, pr: 2 }, cellStyle]}
                                            >
                                                {renderPartNameAndId(row)}
                                                {row.dimensions ? (
                                                    <Typography variant="body2">
                                                        {formatNumberWithX(row.dimensions)} {units}
                                                    </Typography>
                                                ) : null}
                                                {row.weight ? (
                                                    <Typography variant="body2">
                                                        {row.weight} {weightUnits}
                                                    </Typography>
                                                ) : null}
                                            </TableCell>

                                            <TableCell
                                                align="right"
                                                sx={[
                                                    {
                                                        py: 1.5,
                                                        borderRight: '1px dashed rgba(52, 73, 94, 0.38)',
                                                        typography: 'strong2',
                                                    },
                                                    cellStyle,
                                                ]}
                                            >
                                                {q(row.quantity)}
                                            </TableCell>
                                            <TableCell sx={[{ py: 1.5 }, cellStyle]}>
                                                <Checkbox sx={classes.selectionCheckbox} />
                                                {t('Vendor')}

                                                <Checkbox sx={classes.selectionCheckbox} />
                                                {t('Customer')}
                                            </TableCell>
                                        </TableRow>

                                        {row.vendorNotes ? (
                                            <TableRow>
                                                <TableCell
                                                    colSpan={7}
                                                    sx={{ pt: 2 }}
                                                >
                                                    <TextField
                                                        InputLabelProps={{
                                                            sx: { typography: 'strong1' },
                                                        }}
                                                        InputProps={{ sx: { typography: 'body2' } }}
                                                        label={t('Vendor Notes')}
                                                        size="medium"
                                                        value={row.vendorNotes}
                                                        variant="outlined"
                                                        fullWidth
                                                    />
                                                </TableCell>
                                            </TableRow>
                                        ) : null}
                                    </>
                                )
                            })}
                        </TableBody>
                    </Table>
                </TableContainer>
            </Box>

            {!import.meta.env.VITE_RESELLER ? (
                <Box
                    component="footer"
                    sx={!organisation?.showBrandingInDocumentFooter ? { displayPrint: 'none' } : classes.footer}
                    textAlign="center"
                >
                    <Typography variant="small">
                        {t('Powered by')}{' '}
                        <Link
                            color="primary"
                            href={`https://tempustools.com?org_id=${quote.organisationId}&utm_source=quotemate&utm_medium=virality&utm_campaign=document-branding&utm_content=delivery-docket`}
                            target="_blank"
                            underline="always"
                        >
                            Tempus Tools
                        </Link>
                    </Typography>
                </Box>
            ) : null}
        </Box>
    ) : null
}

DeliveryDocket.propTypes = {
    customer: PropTypes.object,
    customerContact: PropTypes.object,
    issueSeverityDictionary: PropTypes.object,
    organisation: PropTypes.object,
    quote: PropTypes.object,
    showShipping: PropTypes.bool
}

export default DeliveryDocket
