import { useTranslation } from 'react-i18next'
import { Box, Switch, Typography } from '@mui/material'
import PropTypes from 'prop-types'

const classes = {
    toggleContainer: {
        display: 'flex',
        flexDirection: 'row',
        justifyContent: 'space-between',
        alignItems: 'center',
        paddingLeft: '8px',
    },
    toggleLabel: {
        marginRight: '12px',
    },
}

const TbxShowToggle = ({ checked, color = 'primary', label, onChange, size = 'small', sx }) => {
    const { t } = useTranslation()

    return (
        <Box sx={[classes.toggleContainer, ...(Array.isArray(sx) ? sx : [sx])]}>
            <Typography
                color="textSecondary"
                sx={classes.toggleLabel}
                variant="body2"
            >
                {t(label)}
            </Typography>
            <Switch
                checked={checked}
                color={color}
                size={size}
                onChange={onChange}
            />
        </Box>
    )
}

TbxShowToggle.propTypes = {
    checked: PropTypes.bool,
    color: PropTypes.string,
    label: PropTypes.string,
    size: PropTypes.string,
    sx: PropTypes.oneOfType([
        PropTypes.arrayOf(PropTypes.oneOfType([PropTypes.func, PropTypes.object, PropTypes.bool])),
        PropTypes.func,
        PropTypes.object,
    ]),
    onChange: PropTypes.func,
}

export default TbxShowToggle
