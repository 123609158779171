import { useCallback, useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useSelector } from 'react-redux'
import {
    LinearProgress,
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableHead,
    TablePagination,
    TableRow,
    TableSortLabel,
    Typography,
} from '@mui/material'
import _ from 'lodash'
import PropTypes from 'prop-types'
import useLocalStorage from 'use-local-storage'

import { selectPaidFeatures } from '@/app/slices/organisationSlice'
import { useToolBoxTreatments } from '@/common/hooks'
import { PARTS_PER_PAGE_DEFAULT_VALUE, PARTS_PER_PAGE_OPTIONS, PARTS_PER_PAGE_VARIABLE } from '@/common/utils'

import PartLibraryRow from './PartLibraryRow'

const classes = {
    tableContainer: {
        background: (theme) => theme.palette.background.paper,
        border: (theme) => `1px solid ${theme.palette.grey[400]}`,
        boxSizing: 'border-box',
        borderRadius: 2,
    },
    table: {
        tableLayout: 'fixed',
        boxSizing: 'border-box',
        borderCollapse: 'separate',
        px: 1,
    },
    titleTableCell: {
        '& p': {
            paddingInline: '12px',
            paddingBlock: '12px',
        },
    },
    headerTableCell: {
        padding: '8px',
        verticalAlign: 'bottom',
    },
    tableFooter: {
        display: 'flex',
        alignItems: 'center',
        flexDirection: 'row-reverse',
    },
    columnsTitles: {
        '& th': {
            pt: 2,
            borderBottom: 'none',
        },
    },
    columns: {
        '& th': {
            paddingTop: 0,
        },
    },

    columnBorder: {
        borderRight: (theme) => `1px dashed ${theme.palette.text.disabled}`,
    },

    headerTitle: {
        fontWeight: 500,
        lineHeight: '24px',
        letterSpacing: '0.17px',
        color: 'text.primary',
    },

    sortLabel: {
        justifyContent: 'space-between',
        width: '100%',
    },
    tableLoader: {
        border: 'none',
        position: 'relative',
        marginBottom: -1,
        height: 8,
        borderRadius: '8px 8px 0 0',
    },
}

const PartLibraryTable = ({
    archivePartLibrary,
    handleDetailSecondaryProcessClick,
    handleProjectMoreDetailsClick,
    isImperial,
    isLoading,
    partsFromLibrary,
    unarchivePartLibrary,
}) => {
    const { t } = useTranslation()

    const paidFeatures = useSelector(selectPaidFeatures)

    const { showFixedPriceParts } = useToolBoxTreatments()

    const [order, setOrder] = useState('asc')
    const [orderBy, setOrderBy] = useState('name')
    const [rows, setRows] = useState([])

    const [currentPage, setCurrentPage] = useState(0)
    const [currentRowsPerPage, setCurrentRowsPerPage] = useLocalStorage(
        PARTS_PER_PAGE_VARIABLE,
        PARTS_PER_PAGE_DEFAULT_VALUE
    )

    const showFixedPricePartsColumn = showFixedPriceParts && paidFeatures?.hasFixedPriceParts

    const stableSort = useCallback((array, comparator) => {
        const stabilizedThis = array?.map((el, index) => [el, index])
        stabilizedThis?.sort((a, b) => {
            const order = comparator(a[0], b[0])
            if (order !== 0) return order
            return a[1] - b[1]
        })
        return stabilizedThis?.map((el) => el[0])
    }, [])

    const handleRequestSort = (property) => {
        const isAsc = orderBy === property && order === 'asc'
        setOrder(isAsc ? 'desc' : 'asc')
        setOrderBy(property)
    }

    const descendingComparator = useCallback((a, b, orderBy) => {
        const first = a[orderBy]
        const next = b[orderBy]
        switch (typeof first) {
            case 'number':
                if (next < first) return -1
                if (next > first) return 1
                break
            case 'string':
                return next?.localeCompare(first, undefined, { sensitivity: 'base' })
            case 'object':
                if (!first.$type || !first.$type.includes('SheetSize')) {
                    return 0
                }
                return descendingComparator(
                    { sheetSize: first.height * first.width },
                    { sheetSize: next.height * next.width },
                    orderBy
                )
            default:
                return 0
        }
        return 0
    }, [])

    const getComparator = useCallback(
        (order, orderBy) => {
            return order === 'desc'
                ? (a, b) => descendingComparator(a, b, orderBy)
                : (a, b) => -descendingComparator(a, b, orderBy)
        },
        [descendingComparator]
    )

    useEffect(() => {
        setRows(_.cloneDeep(partsFromLibrary))
    }, [partsFromLibrary])

    useEffect(() => {
        setRows(stableSort(partsFromLibrary, getComparator(order, orderBy)))
    }, [order, orderBy, partsFromLibrary, stableSort, getComparator])

    const createColumnHeader = (columnId, columnTitle, isSortable) =>
        isSortable ? (
            <TableSortLabel
                active={orderBy === columnId}
                direction={orderBy === columnId ? order : 'asc'}
                sx={classes.sortLabel}
                onClick={() => {
                    handleRequestSort(columnId)
                }}
            >
                <Typography
                    sx={classes.headerTitle}
                    variant="body2"
                >
                    {columnTitle}
                </Typography>
            </TableSortLabel>
        ) : (
            <Typography
                sx={classes.headerTitle}
                variant="body2"
            >
                {columnTitle}
            </Typography>
        )

    const handleChangePage = (_, newPage) => {
        setCurrentPage(newPage)
    }

    const handleChangeRowsPerPage = (event) => {
        const newRowsPerPage = parseInt(event.target.value, 10)
        setCurrentRowsPerPage(newRowsPerPage)
        setCurrentPage(0)
    }
    // END TABLE STUFF
    return (
        <>
            {isLoading ? <LinearProgress sx={classes.tableLoader} /> : null}

            <TableContainer sx={classes.tableContainer}>
                <Table
                    aria-label="partsFromLibrary"
                    sx={classes.table}
                >
                    <colgroup>
                        <col style={{ width: '100px' }} />
                        <col style={{ width: '120px' }} />
                        <col style={{ width: '180px' }} />
                        <col style={{ width: '150px' }} />
                        <col style={{ width: '150px' }} />
                        <col style={{ width: '150px' }} />
                        <col style={{ width: '140px' }} />
                        <col style={{ width: '100px' }} />
                        <col style={{ width: '100px' }} />
                        <col style={{ width: '80px' }} />
                        {showFixedPricePartsColumn ? <col style={{ width: '150px' }} /> : null}
                        <col style={{ width: '40px' }} />
                    </colgroup>

                    <TableHead>
                        {showFixedPricePartsColumn ? (
                            <TableRow sx={classes.columnsTitles}>
                                <TableCell
                                    align="left"
                                    colSpan={10}
                                    data-testid="review-table-parts-header"
                                    sx={[classes.headerTableCell, classes.titleTableCell, classes.columnBorder]}
                                ></TableCell>

                                <TableCell
                                    align="center"
                                    colSpan={1}
                                    data-testid="review-table-prices-header"
                                    sx={[classes.headerTableCell, classes.columnBorder]}
                                >
                                    <Typography variant="strong2">{t('FIX PART PRICE')}</Typography>
                                </TableCell>
                            </TableRow>
                        ) : null}
                        <TableRow>
                            <TableCell
                                align="center"
                                sx={classes.headerTableCell}
                            >
                                {createColumnHeader('thumb', t('Thumb'), false)}
                            </TableCell>
                            <TableCell
                                align="left"
                                sx={classes.headerTableCell}
                            >
                                {createColumnHeader('partLibraryId', t('Part ID'), true)}
                            </TableCell>
                            <TableCell
                                align="left"
                                sx={classes.headerTableCell}
                            >
                                {createColumnHeader('name', t('Part name'), true)}
                            </TableCell>
                            <TableCell
                                align="left"
                                sx={classes.headerTableCell}
                            >
                                {createColumnHeader('bounds', t('Bounds W x L'), true)}
                            </TableCell>
                            <TableCell
                                align="left"
                                sx={classes.headerTableCell}
                            >
                                {createColumnHeader('cuttingTechnology', t('Cutting technology'), true)}
                            </TableCell>
                            <TableCell
                                align="left"
                                sx={classes.headerTableCell}
                            >
                                {createColumnHeader(
                                    'materialThickness',
                                    t('Material and thickness ({{unitOfMeasurement}})', {
                                        unitOfMeasurement: isImperial ? 'in' : 'mm',
                                    }),
                                    true
                                )}
                            </TableCell>
                            <TableCell
                                align="left"
                                sx={classes.headerTableCell}
                            >
                                {createColumnHeader('secondaryProcess', t('Secondary processes'), true)}
                            </TableCell>
                            <TableCell
                                align="left"
                                sx={classes.headerTableCell}
                            >
                                {createColumnHeader('lastQuote', t('Last $t(quote)'), true)}
                            </TableCell>
                            <TableCell
                                align="right"
                                sx={classes.headerTableCell}
                            >
                                {createColumnHeader('lastPrice', t('Last price'), true)}
                            </TableCell>
                            <TableCell
                                align="right"
                                sx={[classes.headerTableCell, showFixedPricePartsColumn ? classes.columnBorder : null]}
                            >
                                {createColumnHeader('lastQuantity', t('Last quantity'), true)}
                            </TableCell>
                            {showFixedPricePartsColumn ? (
                                <TableCell
                                    align="center"
                                    sx={[classes.headerTableCell, classes.columnBorder]}
                                >
                                    <Typography
                                        sx={classes.headerTitle}
                                        variant="body2"
                                    >
                                        {t(' Fixed price')}
                                    </Typography>
                                    <Typography
                                        sx={classes.headerTitle}
                                        variant="body2"
                                    >
                                        {t('End date')}
                                    </Typography>
                                </TableCell>
                            ) : null}

                            <TableCell
                                align="center"
                                sx={classes.headerTableCell}
                            ></TableCell>
                        </TableRow>
                    </TableHead>

                    <TableBody>
                        {rows?.length > 0 ? rows
                                ?.slice(
                                    currentPage * currentRowsPerPage,
                                    currentPage * currentRowsPerPage + currentRowsPerPage
                                )
                                .map((partFromLibrary) => {
                                    return (
                                        <PartLibraryRow
                                            archivePartLibrary={archivePartLibrary}
                                            handleDetailSecondaryProcessClick={handleDetailSecondaryProcessClick}
                                            handleProjectMoreDetailsClick={handleProjectMoreDetailsClick}
                                            key={partFromLibrary.partLibraryId}
                                            partFromLibrary={partFromLibrary}
                                            unarchivePartLibrary={unarchivePartLibrary}
                                        />
                                    )
                                }) : null}
                    </TableBody>
                </Table>

                <TablePagination
                    component="div"
                    count={partsFromLibrary?.length}
                    labelDisplayedRows={({ count, from, to }) => `${from}-${to} of ${count}`}
                    labelRowsPerPage={t('Parts per page')}
                    page={currentPage}
                    rowsPerPage={currentRowsPerPage}
                    rowsPerPageOptions={PARTS_PER_PAGE_OPTIONS}
                    sx={classes.tableFooter}
                    onPageChange={handleChangePage}
                    onRowsPerPageChange={handleChangeRowsPerPage}
                />
            </TableContainer>
        </>
    )
}
PartLibraryTable.propTypes = {
    archivePartLibrary: PropTypes.func,
    handleDetailSecondaryProcessClick: PropTypes.func,
    handleProjectMoreDetailsClick: PropTypes.func,
    isImperial: PropTypes.bool,
    isLoading: PropTypes.bool,
    organisation: PropTypes.object,
    partsFromLibrary: PropTypes.arrayOf(PropTypes.object),
    unarchivePartLibrary: PropTypes.func,
}

export default PartLibraryTable
