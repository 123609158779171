import { api } from './api'

export const userApi = api
    .enhanceEndpoints({
        addTagTypes: ['User'],
    })
    .injectEndpoints({
        endpoints: (builder) => ({
            getUser: builder.query({
                query: () => '/users',
                providesTags: ['User'],
            }),

            getOrCreateUserContext: builder.mutation({
                query: ({ user }) => ({
                    url: '/users/context/',
                    method: 'POST',
                    body: user,
                }),
            }),

            createUser: builder.mutation({
                query: ({ user }) => ({
                    url: '/users',
                    method: 'POST',
                    body: user,
                }),
            }),

            updateUser: builder.mutation({
                query: ({ user, userId }) => ({
                    url: `/users/${userId}`,
                    method: 'POST',
                    body: user,
                }),
                invalidatesTags: ['User'],
            }),

            createOrganisation: builder.mutation({
                query: ({ options, userId }) => ({
                    url: `/users/${userId}/organisation`,
                    method: 'POST',
                    body: options,
                }),
            }),

            startTrial: builder.mutation({
                query: ({ userId }) => ({
                    url: `/users/${userId}/startTrial`,
                    method: 'POST',
                }),
            }),
        }),
    })

export const {
    useCreateOrganisationMutation,
    useCreateUserMutation,
    useGetOrCreateUserContextMutation,
    useGetUserQuery,
    useStartTrialMutation,
    useUpdateUserMutation,
} = userApi
