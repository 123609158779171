import { useSelector } from 'react-redux'
import { Box, Typography } from '@mui/material'

import { useGetCuttingTechnologiesQuery } from '@/app/services/cuttingTechnologies'
import { useGetRateTablesQuery } from '@/app/services/rateTables'
import { selectOrganisationId } from '@/app/slices/organisationSlice'
import { selectSelectedRateTableId } from '@/app/slices/rateTablesSlice'

const classes = {
    nameContainer: {
        display: 'flex',
        flexDirection: 'row',
        alignItems: 'center',
    },
    rateTableName: {
        fontWeight: 400,
        fontSize: '34px',
        lineHeight: '123.5%',
        letterSpacing: '0.25px',
        color: 'text.primary',
    },
}

const RateTableHeader = () => {
    const organisationId = useSelector(selectOrganisationId)
    const { data: cuttingTechnologies } = useGetCuttingTechnologiesQuery({ organisationId })
    const { data: rateTables } = useGetRateTablesQuery({ organisationId })

    const selectedRateTableId = useSelector(selectSelectedRateTableId)
    const selectedRateTable = rateTables[selectedRateTableId]

    const cuttingTech = cuttingTechnologies[selectedRateTable?.cuttingTechnologyId]

    return (
        <Box sx={classes.nameContainer}>
            <Typography sx={classes.rateTableName}>
                {cuttingTech?.name}: {selectedRateTable?.name}
            </Typography>
        </Box>
    )
}

export default RateTableHeader
