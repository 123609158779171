import SvgIcon from '@mui/material/SvgIcon'

const ProcessesIcon = (props) => {
    return (
        <SvgIcon
            {...props}
            viewBox="0 0 24 24"
        >
            <path d="M17.5844 9.3093L16.7225 8.24045C16.6954 8.20423 16.6613 8.17372 16.6224 8.15065C16.5834 8.12758 16.5403 8.11241 16.4955 8.106C16.4507 8.0996 16.405 8.10209 16.3612 8.11333C16.3173 8.12456 16.2761 8.14433 16.2399 8.1715L9.99986 13.102L3.75984 8.1715C3.72362 8.14433 3.68241 8.12456 3.63855 8.11333C3.59469 8.10209 3.54905 8.0996 3.50423 8.106C3.45941 8.11241 3.4163 8.12758 3.37734 8.15065C3.33838 8.17372 3.30435 8.20423 3.27719 8.24045L2.4153 9.3093C2.35652 9.37817 2.32719 9.4674 2.33364 9.55772C2.34009 9.64804 2.38181 9.73219 2.44978 9.79201L9.79301 15.55C9.8499 15.6009 9.92354 15.629 9.99986 15.629C10.0762 15.629 10.1498 15.6009 10.2067 15.55L17.5499 9.79201C17.6179 9.73219 17.6596 9.64804 17.6661 9.55772C17.6725 9.4674 17.6432 9.37817 17.5844 9.3093Z" />
            <path d="M19.6531 13.7917H14.9989L14.7921 13.8607L10 17.6534L5.20794 13.8607L5.00109 13.7917H0.346925C0.300316 13.7862 0.253057 13.7913 0.208706 13.8066C0.164355 13.822 0.124068 13.8472 0.0908782 13.8804C0.0576885 13.9136 0.0324614 13.9539 0.0170962 13.9983C0.00173091 14.0426 -0.00337194 14.0899 0.00217185 14.1365V19.6531C-0.00337194 19.6998 0.00173091 19.747 0.0170962 19.7914C0.0324614 19.8357 0.0576885 19.876 0.0908782 19.9092C0.124068 19.9424 0.164355 19.9676 0.208706 19.983C0.253057 19.9984 0.300316 20.0035 0.346925 19.9979H19.6531C19.6997 20.0035 19.7469 19.9984 19.7913 19.983C19.8356 19.9676 19.8759 19.9424 19.9091 19.9092C19.9423 19.876 19.9675 19.8357 19.9829 19.7914C19.9983 19.747 20.0034 19.6998 19.9978 19.6531V14.1365C20.0034 14.0899 19.9983 14.0426 19.9829 13.9983C19.9675 13.9539 19.9423 13.9136 19.9091 13.8804C19.8759 13.8472 19.8356 13.822 19.7913 13.8066C19.7469 13.7913 19.6997 13.7862 19.6531 13.7917Z" />
            <path d="M8.27601 6.20605V9.86082C8.27296 9.91483 8.28408 9.9687 8.30827 10.0171C8.33246 10.0655 8.36888 10.1067 8.41391 10.1367L9.79292 11.3089C9.8498 11.3598 9.92345 11.388 9.99977 11.388C10.0761 11.388 10.1497 11.3598 10.2066 11.3089L11.5856 10.1367C11.6307 10.1067 11.6671 10.0655 11.6913 10.0171C11.7155 9.9687 11.7266 9.91483 11.7235 9.86082V6.20605H8.27601Z" />
            <path d="M12.7581 0H7.24203C7.05163 0 6.89728 0.154367 6.89728 0.344789V5.17184C6.89728 5.36226 7.05163 5.51663 7.24203 5.51663H12.7581C12.9485 5.51663 13.1028 5.36226 13.1028 5.17184V0.344789C13.1028 0.154367 12.9485 0 12.7581 0Z" />
        </SvgIcon>
    )
}

export default ProcessesIcon
