import { useTranslation } from 'react-i18next'
import { useSelector } from 'react-redux'
import HowToRegRoundedIcon from '@mui/icons-material/HowToRegRounded'
import Box from '@mui/material/Box'
import { blueGrey } from '@mui/material/colors'
import TableCell from '@mui/material/TableCell'
import TableRow from '@mui/material/TableRow'
import TextField from '@mui/material/TextField'
import Typography from '@mui/material/Typography'
import PropTypes from 'prop-types'

import { selectUseImperialUnits } from '@/app/slices/organisationSlice'
import { ThumbnailUri } from '@/common/components'
import { useNumberFormatter } from '@/common/hooks'

import OrderProcessCell from './OrderProcessCell'

const classes = {
    noPageBreak: {
        breakInside: 'avoid',
    },

    colDivider: {
        py: 2,
        borderRight: `1px dashed ${blueGrey[200]}`,
        typography: 'body2',
        '&:last-of-type': {
            borderRight: 0,
        },
    },

    rowDivider: {
        borderBottom: `1px dashed ${blueGrey[200]}`,
    },
}

const WorkOrderPartRow = ({ colspan, hasFolding, organisation, part, uniqueSecondaryProcesses }) => {
    const { t } = useTranslation()

    const { n: metricNumberFormatter } = useNumberFormatter({
        numberOfDecimalPlaces: 2,
    })

    const { n: imperialNumberFormatter } = useNumberFormatter({
        numberOfDecimalPlaces: 3,
    })

    const useImperialUnits = useSelector(selectUseImperialUnits)

    const numberFormatter = part.isImperial ? imperialNumberFormatter : metricNumberFormatter
    const hasNotes = Boolean(part.customerNotes || part.vendorNotes || part.privateNotes)

    const partUnits = part.isImperial ? 'in' : 'mm'
    const areaUnits = useImperialUnits ? 'ft²' : 'm²'
    const weightUnits = useImperialUnits ? 'lb' : 'kg'

    const measureTypes = [
        {
            name: t('Rectangular area - 1 side no edges ({{areaUnits}})', {
                areaUnits: areaUnits,
            }),
            unit: areaUnits,
            shortName: t('1 side no edges'),
            value: 'RectangularArea1Side',
        },
        {
            name: t('Surface area - 1 side no edges ({{areaUnits}})', {
                areaUnits: areaUnits,
            }),
            unit: areaUnits,
            shortName: t('1 side no edges'),
            value: 'SurfaceArea1Side',
        },
        {
            name: t('Surface area - 2 sides plus edges ({{areaUnits}})', {
                areaUnits: areaUnits,
            }),
            unit: areaUnits,
            shortName: t('2 sides + edges'),
            value: 'SurfaceArea2Sides',
        },
        {
            name: t('Net part weight ({{weightUnits}})', {
                weightUnits: weightUnits,
            }),
            unit: weightUnits,
            shortName: t('Net part weight'),
            value: 'Weight',
        },
        { name: t('Quantity'), unit: '', shortName: t('Quantity'), value: 'Quantity' },
    ]

    const calculateCuttingProcessTimeSeconds = (part) => {
        const { quantity, runtime, setupTimePerItemSeconds, sheetChangeTimePerItemSeconds, unloadTimePerItemSeconds } =
            part

        const totalProcessTime =
            quantity *
            ((setupTimePerItemSeconds ?? 0) +
                (sheetChangeTimePerItemSeconds ?? 0) +
                (runtime ?? 0) +
                (unloadTimePerItemSeconds ?? 0))

        return totalProcessTime
    }

    const calculateSecondaryProcessTimeSeconds = (part, sp) => {
        const setupTime = sp.setupTimePerItemSeconds ?? 0
        const timePerItem = sp.timePerItemSeconds ?? 0
        const totalProcessTime = part.quantity * (setupTime + timePerItem)
        return totalProcessTime
    }

    const secondsToMinutes = (seconds) => {
        if (!seconds) {
            return '0 min'
        }
        const minutes = (seconds / 60).toFixed(2)
        return `${minutes} min`
    }

    const getMeasureType = (secondaryProcess) => {
        return measureTypes.find((type) => type.value === secondaryProcess.measureType)
    }

    const renderPartNameAndId = (part) => {
        if (!part.partId) return <Typography variant="body2">{part.name}</Typography>
        else
            return (
                <>
                    <Typography variant="body2">{part.name}</Typography>
                    <Typography variant="body2">{t('ID: ') + part.partId}</Typography>
                </>
            )
    }

    const renderPartSize = (part) => {
        if (!part.displayBoundBoxWidth || !part.displayBoundBoxHeight) {
            return <Typography variant="body2">{'-'}</Typography>
        }

        let bounds = ''
        if (part.profile) {
            if (part.profile.toLowerCase() === 'circle') {
                bounds = `${numberFormatter(part.sheet?.diameter)} x ${numberFormatter(part.rotaryPartLength)}`
            } else {
                bounds = `${numberFormatter(part.sheet?.sheetWidth)} x ${numberFormatter(
                    part.sheet?.sheetHeight
                )} x ${numberFormatter(part.rotaryPartLength)}`
            }
        } else {
            bounds = `${numberFormatter(part.displayBoundBoxWidth)} x ${numberFormatter(part.displayBoundBoxHeight)}`
        }

        return (
            <Typography
                style={{ whiteSpace: 'break-spaces' }}
                variant="body2"
            >
                {`${bounds} ${partUnits}`}
            </Typography>
        )
    }

    const renderCustomerSuppliedMaterial = (part) => {
        const { isCustomerSuppliedMaterial } = part

        if (!isCustomerSuppliedMaterial) {
            return null
        }

        return (
            <Box
                display="flex"
                justifyContent="space-between"
            >
                <Typography variant="body2">{t('Customer-supplied')}</Typography>
                <HowToRegRoundedIcon
                    color="secondary"
                    fontSize="small"
                />
            </Box>
        )
    }

    return (
        <>
            <TableRow sx={classes.noPageBreak}>
                <TableCell
                    align="left"
                    sx={[{ py: 2 }, hasNotes && classes.rowDivider]}
                >
                    <Box
                        alignItems="center"
                        display="flex"
                        gap={1}
                    >
                        <ThumbnailUri
                            lazyLoading={false}
                            sx={{ flex: '0 1 auto' }}
                            uri={part.thumbnailUri}
                        />
                        <Box
                            display="flex"
                            flex={1}
                            flexDirection="column"
                            gap={0.5}
                            justifyContent="center"
                            px={1}
                        >
                            {renderPartNameAndId(part)}
                            {renderPartSize(part)}
                            {renderCustomerSuppliedMaterial(part)}
                        </Box>
                    </Box>
                </TableCell>
                <TableCell
                    align="right"
                    sx={[classes.colDivider, hasNotes && classes.rowDivider]}
                >
                    {part.quantity}
                </TableCell>

                <OrderProcessCell
                    bottomInputValue={secondsToMinutes(calculateCuttingProcessTimeSeconds(part))}
                    sx={[classes.colDivider, hasNotes && classes.rowDivider]}
                    topInputLabel={t('Program')}
                />

                {part.numberOfFolds ? (
                    <OrderProcessCell
                        bottomInputValue={secondsToMinutes(part.quantity * part.foldingTotalTimePerItemSeconds)}
                        sx={[classes.colDivider, hasNotes && classes.rowDivider]}
                        topInputLabel={t('Nº of folds')}
                        topInputValue={part.numberOfFolds}
                    />
                ) : null}

                {!part.numberOfFolds && hasFolding ? (
                    <TableCell sx={[classes.colDivider, hasNotes && classes.rowDivider]}></TableCell>
                ) : null}

                {uniqueSecondaryProcesses.map((usp) => {
                    const sp = part.secondaryProcesses.find((sp) => sp.secondaryProcess.name === usp)
                    const key = `${part.id}-${usp.toLowerCase().replace(/ /g, '-')}`
                    if (!sp) {
                        return (
                            <TableCell
                                key={key}
                                sx={[classes.colDivider, hasNotes && classes.rowDivider]}
                            />
                        )
                    }

                    const measureType = getMeasureType(sp.secondaryProcess)
                    const measureValue = sp.measureValuePerItem
                        ? measureType.value === 'Quantity'
                            ? sp.measureValuePerItem
                            : `${numberFormatter(sp.measureValuePerItem)} ${measureType?.unit}`
                        : ''
                    return (
                        <OrderProcessCell
                            bottomInputValue={secondsToMinutes(calculateSecondaryProcessTimeSeconds(part, sp))}
                            key={key}
                            sx={[classes.colDivider, hasNotes && classes.rowDivider]}
                            topInputLabel={measureType?.shortName}
                            topInputValue={measureValue}
                        />
                    )
                })}
            </TableRow>

            {part.customerNotes ? (
                <TableRow>
                    <TableCell
                        colSpan={colspan}
                        sx={[
                            { py: 2, borderColor: blueGrey[200] },
                            part.vendorNotes && { borderBottom: 'none', pb: 0 },
                        ]}
                    >
                        <TextField
                            InputLabelProps={{
                                shrink: true,
                                sx: { pointerEvents: 'none', fontWeight: 700 },
                            }}
                            InputProps={{
                                sx: { typography: 'inputTextSmall' },
                            }}
                            label={t('Customer Notes')}
                            sx={{ pointerEvents: 'none' }}
                            value={part.customerNotes}
                            variant="outlined"
                            fullWidth
                        />
                    </TableCell>
                </TableRow>
            ) : null}

            {part.vendorNotes ? (
                <TableRow>
                    <TableCell
                        colSpan={colspan}
                        sx={[
                            { py: 2, borderColor: blueGrey[200] },
                            part.privateNotes && { borderBottom: 'none', pb: 0 },
                        ]}
                    >
                        <TextField
                            InputLabelProps={{
                                shrink: true,
                                sx: { pointerEvents: 'none', fontWeight: 700 },
                            }}
                            InputProps={{
                                sx: { typography: 'inputTextSmall' },
                            }}
                            label={t('Vendor Notes')}
                            sx={{ pointerEvents: 'none' }}
                            value={part.vendorNotes}
                            variant="outlined"
                            fullWidth
                        />
                    </TableCell>
                </TableRow>
            ) : null}

            {part.privateNotes ? (
                <TableRow>
                    <TableCell
                        colSpan={colspan}
                        sx={{ py: 2, borderColor: blueGrey[200] }}
                    >
                        <TextField
                            InputLabelProps={{
                                shrink: true,
                                sx: { pointerEvents: 'none', fontWeight: 700 },
                            }}
                            InputProps={{
                                sx: { typography: 'inputTextSmall' },
                            }}
                            label={t('Private Notes')}
                            sx={{ pointerEvents: 'none' }}
                            value={part.privateNotes}
                            variant="outlined"
                            fullWidth
                        />
                    </TableCell>
                </TableRow>
            ) : null}
        </>
    )
}

WorkOrderPartRow.propTypes = {
    colspan: PropTypes.number.isRequired,
    hasFolding: PropTypes.bool.isRequired,
    organisation: PropTypes.object.isRequired,
    part: PropTypes.object.isRequired,
    uniqueSecondaryProcesses: PropTypes.array.isRequired,
}

export default WorkOrderPartRow
