import { useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useSelector } from 'react-redux'
import { useParams } from 'react-router-dom'
import {
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableHead,
    TablePagination,
    TableRow,
    TableSortLabel,
    Typography,
} from '@mui/material'
import { useSnackbar } from 'notistack'
import useLocalStorage from 'use-local-storage'

import { useGetQuoteItemsQuery, useUpdateQuoteItemMutation } from '@/app/services/quoteItems'
import { selectQuote } from '@/app/services/quotes'
import { selectOrganisationId } from '@/app/slices/organisationSlice'
import { PARTS_PER_PAGE_DEFAULT_VALUE, PARTS_PER_PAGE_OPTIONS, PARTS_PER_PAGE_VARIABLE } from '@/common/utils'
import PriceDetailsDialog from '@/features/quotes/components/PriceDetailsDialog/PriceDetailsDialog'

import ItemNotesModal from '../common/ItemNotesModal'

import QuoteReviewTableRow from './QuoteReviewPartTableRow'

const classes = {
    tableContainer: {
        background: (theme) => theme.palette.background.paper,
        border: (theme) => `1px solid ${theme.palette.grey[400]}`,
        boxSizing: 'border-box',
        borderRadius: 2,
    },
    table: {
        tableLayout: 'fixed',
        boxSizing: 'border-box',
        borderCollapse: 'separate',
        px: 1,
    },
    titleTableCell: {
        '& p': {
            paddingInline: '12px',
            paddingBlock: '12px',
        },
    },
    headerTableCell: {
        padding: '8px',
        verticalAlign: 'bottom',
    },
    tableFooter: {
        display: 'flex',
        alignItems: 'center',
        flexDirection: 'row-reverse',
    },
    columnsTitles: {
        '& th': {
            pt: 2,
            borderBottom: 'none',
        },
    },
    columns: {
        '& th': {
            paddingTop: 0,
        },
    },

    columnBorder: {
        borderRight: (theme) => `1px dashed ${theme.palette.text.disabled}`,
    },

    headerTitle: {
        fontWeight: 500,
        lineHeight: '24px',
        letterSpacing: '0.17px',
        color: 'text.primary',
    },

    sortLabel: {
        justifyContent: 'space-between',
        width: '100%',
    },
}

const QuoteReviewTable = () => {
    const { t } = useTranslation()
    const { enqueueSnackbar } = useSnackbar()
    const { quoteId } = useParams()

    const organisationId = useSelector(selectOrganisationId)
    const [updateQuoteItem] = useUpdateQuoteItemMutation()

    const selectedQuote = useSelector((state) => selectQuote(state, { organisationId, quoteId }))
    const { data: quoteItems, isLoading: isLoadingItems } = useGetQuoteItemsQuery({
        organisationId,
        quoteId,
    })

    const [selectedQuoteItem, setSelectedQuoteItem] = useState(null)
    const [openNotesModal, setOpenNotesModal] = useState(false)
    const [openPriceDetailsModal, setOpenPriceDetailsModal] = useState(false)
    const [orderBy, setOrderBy] = useState('index')
    const [order, setOrder] = useState('asc')
    const [currentPage, setCurrentPage] = useState(0)
    const [currentRowsPerPage, setCurrentRowsPerPage] = useLocalStorage(
        PARTS_PER_PAGE_VARIABLE,
        PARTS_PER_PAGE_DEFAULT_VALUE
    )

    const handleOpenNotesModal = (quoteItem) => {
        setSelectedQuoteItem(quoteItem)
        setOpenNotesModal(true)
    }
    const handleCloseNotesModal = () => {
        setSelectedQuoteItem(null)
        setOpenNotesModal(false)
    }

    const handleOpenPriceDetailsModal = (quoteItem) => {
        setSelectedQuoteItem(quoteItem)
        setOpenPriceDetailsModal(true)
    }

    const handleClosePriceDetailsModal = () => {
        setSelectedQuoteItem(null)
        setOpenPriceDetailsModal(false)
    }

    const handleChangePage = (_, newPage) => {
        setCurrentPage(newPage)
    }

    const handleChangeRowsPerPage = (event) => {
        const newRowsPerPage = parseInt(event.target.value, 10)
        setCurrentRowsPerPage(newRowsPerPage)
        setCurrentPage(0)
    }

    const handleRequestSort = (property) => {
        const isAsc = orderBy === property && order === 'asc'
        setOrder(isAsc ? 'desc' : 'asc')
        setOrderBy(property)
    }

    const handleUpdateQuoteItem = async (quoteItem) => {
        try {
            await updateQuoteItem({
                organisationId,
                quoteId,
                quoteItemId: quoteItem?.id,
                quoteItem,
            }).unwrap()
        } catch (error) {
            enqueueSnackbar(t('Failed to update the $t(quote) item'), {
                variant: 'error',
            })
        }
    }

    const createColumnHeader = (columnId, columnTitle, isSortable) =>
        isSortable ? (
            <TableSortLabel
                active={orderBy === columnId}
                direction={orderBy === columnId ? order : 'asc'}
                sx={classes.sortLabel}
                onClick={() => {
                    handleRequestSort(columnId)
                }}
            >
                <Typography
                    sx={classes.headerTitle}
                    variant="body2"
                >
                    {columnTitle}
                </Typography>
            </TableSortLabel>
        ) : (
            <Typography
                sx={classes.headerTitle}
                variant="body2"
            >
                {columnTitle}
            </Typography>
        )

    return (
        <>
            <TableContainer sx={classes.tableContainer}>
                <Table sx={classes.table}>
                    <colgroup>
                        <col style={{ width: '100px' }} />
                        <col style={{ width: '160px' }} />
                        <col style={{ width: '90px' }} />
                        <col style={{ width: '180px' }} />
                        <col style={{ width: '150px' }} />
                        <col style={{ width: '70px' }} />
                        <col style={{ width: '70px' }} />
                        <col style={{ width: '70px' }} />
                        <col style={{ width: '70px' }} />
                        <col style={{ width: '85px' }} />
                        <col style={{ width: '90px' }} />
                        <col style={{ width: '80px' }} />
                        <col style={{ width: '80px' }} />
                    </colgroup>
                    <TableHead>
                        <TableRow sx={classes.columnsTitles}>
                            <TableCell
                                align="left"
                                colSpan={6}
                                data-testid="review-table-parts-header"
                                sx={[classes.headerTableCell, classes.titleTableCell, classes.columnBorder]}
                            >
                                <Typography variant="strong1">{t('PARTS')}</Typography>
                            </TableCell>

                            <TableCell
                                align="center"
                                colSpan={7}
                                data-testid="review-table-prices-header"
                                sx={classes.headerTableCell}
                            >
                                <Typography variant="strong2">{t('PRICES')}</Typography>
                            </TableCell>
                        </TableRow>
                        <TableRow sx={classes.columns}>
                            <TableCell
                                align="center"
                                data-testid="review-table-thumbnail-header"
                                sx={classes.headerTableCell}
                            >
                                {createColumnHeader('thumbnail', t('Thumbnail'), false)}
                            </TableCell>

                            <TableCell
                                align="left"
                                data-testid="review-table-partDetails-header"
                                sx={classes.headerTableCell}
                            >
                                {createColumnHeader('partDetails', t('Part details'), false)}
                            </TableCell>

                            <TableCell
                                align="left"
                                data-testid="review-table-cuttingTech-header"
                                sx={classes.headerTableCell}
                            >
                                {createColumnHeader('cuttingTech', t('Cutting technology'), false)}
                            </TableCell>

                            <TableCell
                                align="left"
                                data-testid="review-table-materialDetails-header"
                                sx={classes.headerTableCell}
                            >
                                {createColumnHeader('materialDetails', t('Material details'), false)}
                            </TableCell>

                            <TableCell
                                align="left"
                                data-testid="review-table-secondaryProcesses-header"
                                sx={classes.headerTableCell}
                            >
                                {createColumnHeader('secondaryProcesses', t('Secondary processes'), false)}
                            </TableCell>

                            <TableCell
                                align="right"
                                data-testid="review-table-quantity-header"
                                sx={[classes.headerTableCell, classes.columnBorder]}
                            >
                                {createColumnHeader('quantity', t('Quantity'), false)}
                            </TableCell>

                            <TableCell
                                align="right"
                                data-testid="review-table-materialPrice-header"
                                sx={classes.headerTableCell}
                            >
                                {createColumnHeader('materialPrice', t('Material'), false)}
                            </TableCell>

                            <TableCell
                                align="right"
                                data-testid="review-table-cuttingPrice-header"
                                sx={classes.headerTableCell}
                            >
                                {createColumnHeader('cuttingPrice', t('Cutting'), false)}
                            </TableCell>

                            <TableCell
                                align="right"
                                data-testid="review-table-foldingPrice-header"
                                sx={classes.headerTableCell}
                            >
                                {createColumnHeader('foldingPrice', t('Folding'), false)}
                            </TableCell>

                            <TableCell
                                align="right"
                                data-testid="review-table-secondaryProcessesPrice-header"
                                sx={classes.headerTableCell}
                            >
                                {createColumnHeader('secondaryProcessesPrice', t('Secondary processes'), false)}
                            </TableCell>

                            <TableCell
                                align="right"
                                data-testid="review-table-adjustment-header"
                                sx={classes.headerTableCell}
                            >
                                {createColumnHeader('adjustment', t('Adjustment'), false)}
                            </TableCell>

                            <TableCell
                                align="right"
                                data-testid="review-table-unitPrice-header"
                                sx={classes.headerTableCell}
                            >
                                {createColumnHeader('unitPrice', t('Unit price'), false)}
                            </TableCell>

                            <TableCell
                                align="right"
                                data-testid="review-table-lineTotal-header"
                                sx={classes.headerTableCell}
                            >
                                {createColumnHeader('lineTotal', t('Line total'), false)}
                            </TableCell>
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {quoteItems
                            ?.slice(
                                currentPage * currentRowsPerPage,
                                currentPage * currentRowsPerPage + currentRowsPerPage
                            )
                            .map((quoteItem) => (
                                <QuoteReviewTableRow
                                    handleOpenNotesModal={handleOpenNotesModal}
                                    handleOpenPriceDetailsModal={handleOpenPriceDetailsModal}
                                    key={quoteItem.id}
                                    quoteItem={quoteItem}
                                />
                            ))}
                    </TableBody>
                </Table>

                <TablePagination
                    component="div"
                    count={quoteItems?.length}
                    labelDisplayedRows={({ count, from, to }) => `${from}-${to} of ${count}`}
                    labelRowsPerPage={t('Parts per page')}
                    page={currentPage}
                    rowsPerPage={currentRowsPerPage}
                    rowsPerPageOptions={PARTS_PER_PAGE_OPTIONS}
                    sx={classes.tableFooter}
                    onPageChange={handleChangePage}
                    onRowsPerPageChange={handleChangeRowsPerPage}
                />
            </TableContainer>

            <ItemNotesModal
                handleUpdate={handleUpdateQuoteItem}
                open={openNotesModal}
                quoteItem={selectedQuoteItem}
                onClose={handleCloseNotesModal}
            />

            <PriceDetailsDialog
                open={openPriceDetailsModal}
                quoteItem={selectedQuoteItem}
                quoteOverrides={selectedQuote?.quoteOverrides}
                taxRate={selectedQuote?.taxRate}
                onClose={handleClosePriceDetailsModal}
            />
        </>
    )
}

export default QuoteReviewTable
