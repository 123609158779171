import { Box } from '@mui/material'
import PropTypes from 'prop-types'

const classes = {
    root: {
        height: 32,
    },
}

const AppLogo = ({ className, logoUrl, onClick }) => {
    const boxStyle = Object.assign({}, classes.root, className)

    return logoUrl ? (
        <Box
            alt="[Logo Image]"
            component="img"
            src={logoUrl}
            sx={boxStyle}
            onClick={onClick}
        />
    ) : null
}

AppLogo.propTypes = {
    className: PropTypes.string,
    logoUrl: PropTypes.string,
    onClick: PropTypes.func,
}

export default AppLogo
