import { useCallback, useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useDispatch, useSelector } from 'react-redux'
import { useNavigate } from 'react-router-dom'
import { Box, Typography } from '@mui/material'
import { useSnackbar } from 'notistack'

import { useCreateQuoteMutation, useUpdateQuoteMutation } from '@/app/services/quotes'
import { setShowDrawerMenu, setShowNestedDrawerMenu } from '@/app/slices/appSlice'
import {
    selectSelectedCustomer,
    selectSelectedCustomerId,
    selectShowAddCustomerForm,
    setSelectedCustomer,
    setSelectedCustomerId,
    setShowAddCustomerForm,
} from '@/app/slices/customersSlice'
import { selectDefaultTaxRateId, selectOrganisation, selectOrganisationId } from '@/app/slices/organisationSlice'
import { TbxDrawer } from '@/common/components'
import { CustomerCentralToolbar } from '@/common/components/MainAppBar'

import AddCustomerForm from '../components/AddCustomerForm'
import CustomerHeader from '../components/CustomerHeader'
import CustomerTabs from '../components/Tabs/CustomerTabs'

const classes = {
    root: {
        display: 'flex',
        flexDirection: 'column',
        minHeight: 'calc(100vh - 64px)',
        padding: '24px 30px',
        backgroundColor: '#FAFAFA',
        flex: 1,
        maxWidth: '100%',
    },
    header: {
        display: 'flex',
        flexDirection: 'row',
        justifyContent: 'space-between',
        marginBottom: 1,
    },

    noCustomerText: {
        fontSize: '34px',
        lineHeight: '123.5%',
        color: 'rgba(52, 73, 94, 0.38)',
    },

    noCustomerContainer: {
        display: 'flex',
        maxWidth: '450px',
        height: 'calc(100vh - 64px)',
        justifyContent: 'center',
        alignItems: 'center',
        textAlign: 'center',
        background: 'url(toolbox-box.png)',
        backgroundRepeat: 'no-repeat',
        backgroundPosition: 'center',
    },
}

const CustomerCentral = () => {
    const { t } = useTranslation()
    const navigate = useNavigate()
    const dispatch = useDispatch()
    const { enqueueSnackbar } = useSnackbar()

    const organisation = useSelector(selectOrganisation)
    const organisationId = useSelector(selectOrganisationId)

    const selectedCustomerId = useSelector(selectSelectedCustomerId)
    const selectedCustomer = useSelector(selectSelectedCustomer)
    const showAddCustomerForm = useSelector(selectShowAddCustomerForm)
    const defaultTaxRateId = useSelector(selectDefaultTaxRateId)

    const [createQuote] = useCreateQuoteMutation()
    const [updateQuote] = useUpdateQuoteMutation()

    const [isCreatingQuote, setIsCreatingQuote] = useState(false)

    const handleDrawerClose = () => {
        dispatch(setShowAddCustomerForm(false))
    }

    const handleAddCustomerClick = () => {
        dispatch(setShowAddCustomerForm(true))
    }

    const updateQuoteCustomerContact = useCallback(
        async (quote) => {
            const customerContacts = selectedCustomer?.contacts ?? []
            const defaultContact =
                customerContacts?.find((contact) => contact.isDefault === true) || customerContacts[0]

            try {
                await updateQuote({
                    organisationId,
                    quoteId: quote.id,
                    quote: {
                        ...quote,
                        customerContactId: defaultContact.contactId ?? '',
                        customerId: selectedCustomer.customerId ?? '',
                        customerName: selectedCustomer.customerName ?? '',
                        taxRateId: selectedCustomer.taxRateId ?? defaultTaxRateId,
                    },
                }).unwrap()
            } catch (error) {
                enqueueSnackbar(t('An error occurred updating the $t(quote) contact'), {
                    variant: 'error',
                })
            }
        },
        [organisationId, selectedCustomer]
    )

    const handleCreateQuoteClick = async () => {
        setIsCreatingQuote(true)
        const newProjectName = `${organisation.projectNumberPrefix}${organisation.nextProjectNumber}`
        try {
            const createdQuote = await createQuote({ organisationId, name: newProjectName }).unwrap()

            await updateQuoteCustomerContact(createdQuote)
            navigate(`/quotes/${createdQuote.id}`)
        } catch (error) {
            enqueueSnackbar(t('An error occurred while creating the $t(quote)'), {
                variant: 'error',
            })
        } finally {
            setIsCreatingQuote(false)
        }
    }

    useEffect(() => {
        if (!selectedCustomerId) {
            dispatch(setShowDrawerMenu(true))
            dispatch(setShowNestedDrawerMenu('customers'))
        }
        return () => {
            dispatch(setSelectedCustomerId(null))
            dispatch(setSelectedCustomer(null))
        }
    }, [])

    return (
        <>
            <CustomerCentralToolbar
                customer={selectedCustomer}
                handleAddCustomerClick={handleAddCustomerClick}
                handleCreateQuoteClick={handleCreateQuoteClick}
                isCreatingQuote={isCreatingQuote}
            />

            {(selectedCustomerId && (
                <Box sx={classes.root}>
                    <Box sx={classes.header}>
                        <CustomerHeader customer={selectedCustomer} />
                    </Box>

                    <CustomerTabs customer={selectedCustomer} />
                </Box>
            )) || (
                <Box sx={classes.noCustomerContainer}>
                    <Typography sx={classes.noCustomerText}>
                        {t('Select a customer from the list to view its details.')}
                    </Typography>
                </Box>
            )}

            <TbxDrawer
                showDrawer={showAddCustomerForm}
                onClose={handleDrawerClose}
            >
                <AddCustomerForm
                    onCancel={handleDrawerClose}
                    onCreate={handleDrawerClose}
                />
            </TbxDrawer>
        </>
    )
}

export default CustomerCentral
