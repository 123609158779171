import { useCallback } from 'react'
import { useMsal } from '@azure/msal-react'
import { Avatar } from '@mui/material'
import PropTypes from 'prop-types'

import { getUserDtoFromMsalAccountInfo } from '@/common/utils/DataModel/DataModel'

const UserAvatar = ({ onClick, sx, ...props }) => {
    const { instance: msalInstance } = useMsal()
    const user = msalInstance.getActiveAccount()
    const { firstName, lastName, name } = user?.idTokenClaims ? getUserDtoFromMsalAccountInfo(user) : {}

    const getTextAvatar = useCallback(() => {
        if (user) {
            const firstInitial =
                firstName && firstName.length
                    ? firstName.charAt(0).toUpperCase()
                    : name && name.length
                      ? name.charAt(0).toUpperCase()
                      : ''
            const lastInitial = lastName && lastName.length ? lastName.charAt(0).toUpperCase() : ''

            return firstInitial + lastInitial
        }
    }, [firstName, lastName, user, name])

    return (
        <Avatar
            sx={[...(Array.isArray(sx) ? sx : [sx])]}
            onClick={typeof onClick === 'function' ? onClick : undefined}
            {...props}
        >
            {getTextAvatar()}
        </Avatar>
    )
}

UserAvatar.propTypes = {
    sx: PropTypes.oneOfType([
        PropTypes.arrayOf(PropTypes.oneOfType([PropTypes.func, PropTypes.object, PropTypes.bool])),
        PropTypes.func,
        PropTypes.object,
    ]),
    onClick: PropTypes.func,
}

export default UserAvatar
