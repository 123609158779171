const DEFAULT_CURRENCY = 'USD'
const DEFAULT_LOCALE = 'en-US'

export function getCurrencyFormatter(currencyCode = DEFAULT_CURRENCY, locale = DEFAULT_LOCALE, options) {
    const currencyOptions = {
        style: 'currency',
        currency: currencyCode,
        ...options,
    }
    const formatter = new Intl.NumberFormat(locale, currencyOptions)

    return formatter
}

export function getFormattedPrice(amount, currencyCode = DEFAULT_CURRENCY, locale = DEFAULT_LOCALE, options) {
    const formatter = getCurrencyFormatter(currencyCode, locale, options)
    const formattedText = formatter.format(amount || 0)
    return formattedText
}

export function getCurrencySymbol(currencyCode = DEFAULT_CURRENCY, locale = DEFAULT_LOCALE) {
    return (0)
        .toLocaleString(locale, {
            style: 'currency',
            currency: currencyCode,
            minimumFractionDigits: 0,
            maximumFractionDigits: 0,
        })
        .replace(/\d/g, '')
        .trim()
}

export function getSampleFormattedCurrencyString(locale, currencyCode) {
    const formatter = new Intl.NumberFormat(locale, {
        style: 'currency',
        currency: currencyCode,
    })
    return formatter.format(2500)
}

export function getCurrencyFormat(currencyCode, locale) {
    const formatter = new Intl.NumberFormat(locale, {
        style: 'currency',
        currency: currencyCode,
    })

    const currencyFormat = {}
    formatter.formatToParts(10000.01).forEach((item) => {
        currencyFormat[item.type] = item.value
    })

    currencyFormat['decimal'] = currencyFormat['group'] === '.' ? ',' : '.'
    currencyFormat['decimalPlaces'] = currencyFormat['fraction'] ? currencyFormat['fraction'].length : 0
    currencyFormat['currencySymbolPosition'] = getCurrencySymbolPosition(currencyCode, locale)
    return currencyFormat
}

const getCurrencySymbolPosition = function (currency, locale) {
    const formatter = new Intl.NumberFormat(locale, {
        style: 'currency',
        currency: currency,
        currencyDisplay: 'code',
    })
    const parts = formatter.formatToParts(99)
    const currencyIndex = parts.findIndex((part) => part.value === currency)

    if (currencyIndex === 0) {
        return 'before'
    } else {
        return 'after'
    }
}
