import { forwardRef } from 'react'
import MuiPopover from '@mui/material/Popover'
import { HTMLElementType } from '@mui/utils'
import PropTypes from 'prop-types'

const Popover = forwardRef(
    (
        {
            anchorEl,
            anchorOrigin = { horizontal: 'left', vertical: 'top' },
            anchorPosition,
            anchorReference = 'anchorEl',
            children,
            className,
            marginThreshold,
            onClose,
            open,
            transformOrigin = { horizontal: 'left', vertical: 'top' },
            ...other
        },
        ref
    ) => {
        return (
            <MuiPopover
                anchorEl={anchorEl}
                anchorOrigin={anchorOrigin}
                anchorPosition={anchorPosition}
                anchorReference={anchorReference}
                className={className}
                marginThreshold={marginThreshold}
                open={open}
                ref={ref}
                transformOrigin={transformOrigin}
                onClose={onClose}
                {...other}
            >
                {children}
            </MuiPopover>
        )
    }
)

Popover.displayName = 'Popover'

Popover.propTypes = {
    open: PropTypes.bool.isRequired,
    anchorEl: PropTypes.oneOfType([HTMLElementType, PropTypes.func]),
    anchorOrigin: PropTypes.shape({
        horizontal: PropTypes.oneOfType([PropTypes.oneOf(['center', 'left', 'right']), PropTypes.number]).isRequired,
        vertical: PropTypes.oneOfType([PropTypes.oneOf(['bottom', 'center', 'top']), PropTypes.number]).isRequired,
    }),
    anchorPosition: PropTypes.shape({
        left: PropTypes.number.isRequired,
        top: PropTypes.number.isRequired,
    }),
    anchorReference: PropTypes.oneOf(['anchorEl', 'anchorPosition', 'none']),
    children: PropTypes.node,
    className: PropTypes.string,
    marginThreshold: PropTypes.number,
    transformOrigin: PropTypes.shape({
        horizontal: PropTypes.oneOfType([PropTypes.oneOf(['center', 'left', 'right']), PropTypes.number]).isRequired,
        vertical: PropTypes.oneOfType([PropTypes.oneOf(['bottom', 'center', 'top']), PropTypes.number]).isRequired,
    }),
    onClose: PropTypes.func,
}

export default Popover
