import { useEffect, useRef } from 'react'
import { useLocation } from 'react-router-dom'

const ScrollToAnchor = () => {
    const location = useLocation()
    const lastHash = useRef('')

    useEffect(() => {
        if (location.hash) {
            lastHash.current = location.hash.slice(1) // safe hash for further use after navigation
        }

        if (lastHash.current && document.getElementById(lastHash.current)) {
            const offsetTop = document.getElementById(lastHash.current).offsetTop
            setTimeout(() => {
                window.scrollTo({ top: offsetTop, behavior: 'smooth' })
                lastHash.current = ''
            }, 100)
        } else {
            window.scrollTo({ top: 0, behavior: 'smooth' })
        }
    }, [location])

    return null
}

export default ScrollToAnchor
