import { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useSelector } from 'react-redux'
import { useParams } from 'react-router-dom'
import { Box, IconButton } from '@mui/material'
import { useSnackbar } from 'notistack'
import PropTypes from 'prop-types'
import { v4 as uuidv4 } from 'uuid'

import { useGetQuoteItemsQuery, useUpdateQuoteItemDrawingMutation } from '@/app/services/quoteItems'
import { selectOrganisationId } from '@/app/slices/organisationSlice'
import TbxTooltip from '@/common/components/TbxTooltip/TbxTooltip'
import { DeleteIcon } from '@/common/icons'
import DeleteLayerAlertDialog from '@/features/quotes/components/DrawingDoctor/Components/DeleteLayerAlertDialog'

import LayerName from './LayerName'
import LayerType from './LayerType'

const classes = {
    colourAdornment: {
        width: 16,
        height: 16,
        borderRadius: 1,
        padding: 1,
    },
    icon: {
        color: 'action.active',
    },
}

const Layers = ({ drawing, quoteItemId }) => {
    const { t } = useTranslation()
    const { quoteId } = useParams()
    const { enqueueSnackbar } = useSnackbar()

    const organisationId = useSelector(selectOrganisationId)
    const { quoteItem } = useGetQuoteItemsQuery(
        {
            organisationId,
            quoteId,
        },
        {
            selectFromResult: ({ data }) => ({
                quoteItem: data?.find((item) => item.id === quoteItemId),
            }),
        }
    )

    const [updateQuoteItemDrawing, { isLoading: isUpdating }] = useUpdateQuoteItemDrawingMutation({
        fixedCacheKey: 'updateQuoteItemDrawing',
    })

    const isPartFromLibrary = Boolean(quoteItem?.partLibraryEntryId)

    const [layersMap, setLayersMap] = useState({})
    const [pathToLayersMap, setPathToLayersMap] = useState({})
    const [nameToLayersMap, setNameToLayerMap] = useState({})
    const [layerIdToDelete, setLayerIdToDelete] = useState(null)

    const handleUpdateQuoteItemDrawing = async (quoteItemDrawing) => {
        try {
            const quoteItemId = quoteItem.id
            const quoteId = quoteItem.quoteId
            quoteItemDrawing.paths = Object.values(quoteItemDrawing.paths)
            const drawing = { ...quoteItemDrawing }
            await updateQuoteItemDrawing({
                organisationId,
                quoteId,
                quoteItemId,
                drawing,
            }).unwrap()

            enqueueSnackbar(t('$t(Quote) item updated'), { variant: 'success' })
        } catch (error) {
            enqueueSnackbar(t('Failed to update the $t(quote) item drawing'), {
                variant: 'error',
            })
        }
    }

    const removeLayerFromPart = (layerId) => {
        const updatedDrawingPaths = []

        if (drawing?.paths) {
            Object.values(drawing.paths).forEach((path) => {
                const pathKey = getPartPathKey(quoteItem, path)

                if (pathToLayersMap[pathKey] !== layerId) {
                    updatedDrawingPaths.push(path)
                }
            })
            drawing.paths = updatedDrawingPaths
        }

        return drawing
    }

    const handleLayerDeleteConfirm = () => {
        const updatedDrawing = removeLayerFromPart(layerIdToDelete)

        setLayerIdToDelete(null)

        handleUpdateQuoteItemDrawing(updatedDrawing)
    }

    const handleLayerDeleteClick = (layerId) => {
        setLayerIdToDelete(layerId)
    }

    const compareLayerNames = (a, b) => {
        if (a[1].name.toLowerCase() < b[1].name.toLowerCase()) {
            return -1
        }
        if (a[1].name.toLowerCase() > b[1].name.toLowerCase()) {
            return 1
        }
        return 0
    }

    useEffect(() => {
        const newLayersMap = {}
        const newPathToLayersMap = {}
        const newNameToLayersMap = {}

        const getPartPathKey = (quoteItem, path) => {
            return `${quoteItem.partId}-${path.pathId}`
        }
        if (drawing?.paths) {
            Object.values(drawing.paths).forEach((path) => {
                const layer = path.layer
                let layerId = newNameToLayersMap[layer.name]

                if (!layerId) {
                    layerId = uuidv4()

                    newNameToLayersMap[layer.name] = layerId
                    newLayersMap[layerId] = layer
                }
                const pathKey = getPartPathKey(quoteItem, path)
                newPathToLayersMap[pathKey] = layerId
            })

            setLayersMap(newLayersMap)
            setPathToLayersMap(newPathToLayersMap)
            setNameToLayerMap(nameToLayersMap)
        }
    }, [drawing])

    const updateLayerInLayersMap = (layerId, propertyName, newValue) => {
        const updatedLayersMap = { ...layersMap }

        updatedLayersMap[layerId][propertyName] = newValue

        setLayersMap(updatedLayersMap)

        return updatedLayersMap
    }

    const getPartPathKey = (quoteItem, path) => {
        return `${quoteItem.partId}-${path.pathId}`
    }

    const updateLayersInPart = (layerId, updatedLayer) => {
        if (drawing?.paths) {
            Object.values(drawing.paths).forEach((path) => {
                const pathKey = getPartPathKey(quoteItem, path)

                if (pathToLayersMap[pathKey] === layerId) {
                    const newLayer = { ...updatedLayer }
                    path.layer = newLayer

                    path.entities.forEach((entity) => {
                        entity.layer = { ...updatedLayer }
                    })
                }
            })
        }

        return drawing
    }

    const handleLayerTypeChange = (newType, layerId) => {
        // Update the layer values for the component
        const updatedLayersMap = updateLayerInLayersMap(layerId, 'type', newType)
        const updatedLayer = updatedLayersMap[layerId]

        // Apply updated layer to parts and related paths
        const updatedDrawing = updateLayersInPart(layerId, updatedLayer)

        handleUpdateQuoteItemDrawing(updatedDrawing)
    }

    const handleLayerNameChange = (newName, layerId) => {
        if (!newName) {
            enqueueSnackbar(t('Layer name is required'), {
                variant: 'error',
            })
        } else if (nameToLayersMap[newName]) {
            enqueueSnackbar(t('Layer names must be unique'), {
                variant: 'error',
            })
        } else {
            const updatedLayersMap = updateLayerInLayersMap(layerId, 'name', newName)
            const updatedLayer = updatedLayersMap[layerId]

            const updatedDrawing = updateLayersInPart(layerId, updatedLayer)

            handleUpdateQuoteItemDrawing(updatedDrawing)
        }
    }

    const layers = Object.entries(layersMap).sort((a, b) => compareLayerNames(a, b))

    return (
        <Box>
            {layers.map(([layerId, layer], _index) => (
                <Box
                    alignItems={'center'}
                    display={'flex'}
                    flexDirection={'row'}
                    gap={1}
                    justifyContent={'space-between'}
                    key={layerId}
                    margin={1}
                >
                    <Box
                        style={
                            !layer.colour || layer.colour.toLowerCase() === 'none'
                                ? { border: '0.5px dashed black' }
                                : { backgroundColor: layer.colour.toLowerCase() }
                        }
                        sx={classes.colourAdornment}
                    />
                    <TbxTooltip
                        title={isPartFromLibrary ? t('Cannot edit parts from library') : ''}
                        arrow
                    >
                        <Box flex={1}>
                            <LayerName
                                handleUpdateLayerName={(newName) => {
                                    handleLayerNameChange(newName, layerId)
                                }}
                                isDisabled={isUpdating || isPartFromLibrary}
                                layerName={layer.name}
                            />
                        </Box>
                    </TbxTooltip>
                    <TbxTooltip
                        title={isPartFromLibrary ? t('Cannot edit parts from library') : ''}
                        arrow
                    >
                        <Box flex={1}>
                            <LayerType
                                handleUpdateLayerType={(newType) => {
                                    handleLayerTypeChange(newType, layerId)
                                }}
                                isDisabled={isUpdating || isPartFromLibrary}
                                layerType={layer.type}
                            />
                        </Box>
                    </TbxTooltip>
                    {layers.length > 1 && !isPartFromLibrary ? (
                        <IconButton
                            disabled={isUpdating}
                            size="small"
                            sx={classes.icon}
                            onClick={() => handleLayerDeleteClick(layerId)}
                        >
                            <DeleteIcon fontSize="small" />
                        </IconButton>
                    ) : null}
                </Box>
            ))}
            <DeleteLayerAlertDialog
                layerName={layerIdToDelete ? layersMap[layerIdToDelete].name : ''}
                open={Boolean(layerIdToDelete)}
                onCancelClose={() => setLayerIdToDelete(null)}
                onOkClose={handleLayerDeleteConfirm}
            />
        </Box>
    )
}

Layers.propTypes = {
    drawing: PropTypes.object,
    quoteItemId: PropTypes.string,
}

export default Layers
