import { createSlice } from '@reduxjs/toolkit'

const initialState = {
    selectedMaterialId: null,
    showAddMaterialForm: false,
}

export const materialsSlice = createSlice({
    name: 'materialsSlice',
    initialState,
    reducers: {
        setSelectedMaterialId: (state, action) => {
            state.selectedMaterialId = action.payload
        },
        setShowAddMaterialForm: (state, action) => {
            state.showAddMaterialForm = action.payload
        },
    },
})

export const selectSelectedMaterialId = (state) => state.materialsSlice.selectedMaterialId
export const selectShowAddMaterialForm = (state) => state.materialsSlice.showAddMaterialForm

export const { setSelectedMaterialId, setShowAddMaterialForm } = materialsSlice.actions

export default materialsSlice.reducer
