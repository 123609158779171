import { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useSelector } from 'react-redux'
import { FormControl, InputLabel, MenuItem, Select } from '@mui/material'
import PropTypes from 'prop-types'

import { selectCurrentUser } from '@/app/slices/appSlice'

const OrganisationSelect = ({ disabled = false, inputProps, onChange, sx, value = '' }) => {
    const { t } = useTranslation()

    const currentUser = useSelector(selectCurrentUser)

    const [selectedValue, setSelectedValue] = useState('')

    const userOrganisations = currentUser.organisations.toSorted((a, b) => {
        return a.name.localeCompare(b.name)
    })

    const handleChange = (event) => {
        const selectedOrganisation = event.target.value
        const organisationIndex = currentUser.organisations.findIndex((o) => o.organisationId === selectedOrganisation)
        const newSelectedOrganisation = currentUser.organisations[organisationIndex]

        setSelectedValue(newSelectedOrganisation.organisationId)

        if (typeof onChange === 'function') {
            onChange(newSelectedOrganisation.organisationId)
        }
    }

    useEffect(() => {
        setSelectedValue(value)
    }, [value])

    return (
        <FormControl fullWidth>
            <InputLabel data-testid="select-organisation-label">{t('Organisation')}</InputLabel>
            <Select
                disabled={disabled}
                fullWidth={true}
                inputProps={inputProps}
                label={t('Organisation')}
                sx={[sx, ...(Array.isArray(sx) ? sx : [sx])]}
                value={selectedValue ?? ''}
                onChange={handleChange}
            >
                {userOrganisations.map((org) => (
                    <MenuItem
                        key={org.organisationId}
                        value={org.organisationId}
                    >
                        {org.name}
                    </MenuItem>
                ))}
            </Select>
        </FormControl>
    )
}

OrganisationSelect.propTypes = {
    disabled: PropTypes.bool,
    inputProps: PropTypes.object,
    sx: PropTypes.oneOfType([
        PropTypes.arrayOf(PropTypes.oneOfType([PropTypes.func, PropTypes.object, PropTypes.bool])),
        PropTypes.func,
        PropTypes.object,
    ]),
    value: PropTypes.string,
    onChange: PropTypes.func,
    onClick: PropTypes.func,
}

export default OrganisationSelect
