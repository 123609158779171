import { useEffect } from 'react'
import { useTranslation } from 'react-i18next'
import { useSelector } from 'react-redux'
import { Box, Typography } from '@mui/material'
import dayjs from 'dayjs'
import duration from 'dayjs/plugin/duration'
import { useSnackbar } from 'notistack'

import { selectIsCurrentUserOwner } from '@/app/slices/appSlice'
import { selectOrganisation, selectResolvedPlanMetadata } from '@/app/slices/organisationSlice'
import RoleRequiredContainer from '@/common/components/RoleRequiredContainer/RoleRequiredContainer'
import { useToolBoxTreatments } from '@/common/hooks'

import * as ParagonService from '../../../../common/services/ParagonService'

import Integrations from './Integrations'
import Payments from './Payments'
import Settings from './Settings'
import UserManagement from './UserManagement'

dayjs.extend(duration)

const classes = {
    container: {
        width: '100%',
        height: '100%',
        display: 'flex',
        flexDirection: 'column',
        padding: 2,
        boxSizing: 'border-box',
    },
    section: {
        my: 3,
    },
    centeredContent: {
        height: '100%',
        alignItems: 'center',
        display: 'flex',
        justifyContent: 'center',
    },
}

const OrganisationSettings = () => {
    const { t } = useTranslation()
    const { enqueueSnackbar } = useSnackbar()

    const { showIntegrations, showPayments } = useToolBoxTreatments()

    const organisation = useSelector(selectOrganisation)
    const resolvedPlanMetadata = useSelector(selectResolvedPlanMetadata)
    const isUSerOwner = useSelector(selectIsCurrentUserOwner)

    const showUpdatedToast = () => {
        enqueueSnackbar(t('Organisation settings updated'), { variant: 'success' })
    }

    const showErrorToast = (errorType) => {
        const message =
            errorType === 'uploadImageError'
                ? 'An error occurred updating your organisation logo'
                : 'An error occurred updating your organisation settings'
        enqueueSnackbar(message, { variant: 'error' })
    }

    useEffect(() => {
        if (organisation && (showIntegrations || resolvedPlanMetadata?.xero)) {
            ParagonService.authenticate(
                import.meta.env.VITE_PARAGON_PROJECT_ID,
                organisation?.organisationId,
                dayjs().startOf('second').valueOf(),
                dayjs.duration(60, 'minutes').asSeconds()
            )
        }
    }, [organisation, showIntegrations, resolvedPlanMetadata])

    return (
        <RoleRequiredContainer role="administrator">
            <Box sx={classes.container}>
                {organisation ? (
                    <>
                        <Box sx={classes.section}>
                            <Settings
                                onSettingsUpdated={showUpdatedToast}
                                onSettingsUpdateError={showErrorToast}
                            />
                        </Box>

                        {!import.meta.env.VITE_RESELLER && showPayments && isUSerOwner ? (
                            <Box sx={classes.section}>
                                <Payments />
                            </Box>
                        ) : null}

                        <Box sx={classes.section}>
                            <UserManagement />
                        </Box>

                        {!import.meta.env.VITE_RESELLER && (showIntegrations || resolvedPlanMetadata?.xero) ? (
                            <Box sx={classes.section}>
                                <Integrations />
                            </Box>
                        ) : null}
                    </>
                ) : (
                    <Box sx={classes.centeredContent}>
                        <Typography
                            color="error.main"
                            variant="strong1"
                        >
                            {t('An error occurred loading your organisation details, please try againg.')}
                        </Typography>
                    </Box>
                )}
            </Box>
        </RoleRequiredContainer>
    )
}

export default OrganisationSettings
