import { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useSelector } from 'react-redux'
import { Edit, Save, Undo } from '@mui/icons-material'
import { Box, IconButton, TextField } from '@mui/material'
import { useSnackbar } from 'notistack'
import PropTypes from 'prop-types'

import { useUpdateCustomerMutation } from '@/app/services/customers'
import { selectCustomers } from '@/app/slices/customersSlice'
import { selectOrganisationId } from '@/app/slices/organisationSlice'

const classes = {
    container: {
        display: 'flex',
        alignItems: 'flex-start',
        justifyContent: 'space-between',
        width: '100%',
    },

    inputContainer: {
        display: 'flex',
        flexDirection: 'column',
    },
    customerName: {
        fontWeight: 500,
        fontSize: '28px',
        color: 'text.primary',
    },

    accountCode: {
        fontWeight: 400,
        fontSize: '21px',

        color: 'text.secondary',
    },
}

const CustomerHeader = ({ customer }) => {
    const { t } = useTranslation()
    const { enqueueSnackbar } = useSnackbar()

    const organisationId = useSelector(selectOrganisationId)

    const customers = useSelector(selectCustomers)

    const [updateCustomer] = useUpdateCustomerMutation()

    const [companyName, setCompanyName] = useState(customer?.companyName || '')
    const [accountCode, setAccountCode] = useState(customer?.accountCode || '')

    const FONT_SIZE = 14
    const DEFAULT_INPUT_WIDTH = 200

    const [inputWidth, setInputWidth] = useState(DEFAULT_INPUT_WIDTH)
    const [isEditing, setIsEditing] = useState(false)

    useEffect(() => {
        if (companyName.length * FONT_SIZE > DEFAULT_INPUT_WIDTH) {
            setInputWidth((companyName.length + 1) * FONT_SIZE)
        } else {
            setInputWidth(DEFAULT_INPUT_WIDTH)
        }
    }, [companyName.length])

    useEffect(() => {
        customer && setIsEditing(false)
        setCompanyName(customer?.companyName || '')
        setAccountCode(customer?.accountCode || '')
    }, [customer])

    const handleEditButtonClick = async () => {
        setIsEditing(!isEditing)
        try {
            if (isEditing) {
                // Persist changes and update context
                const updatedCustomer = {
                    ...customer,
                    companyName,
                    accountCode,
                }
                await updateCustomer({ organisationId, customer: updatedCustomer }).unwrap()

                enqueueSnackbar(t('Successfully updated the $t(customer)'), {
                    variant: 'success',
                })
            }
        } catch (error) {
            enqueueSnackbar(t('An error occurred while updating the $t(customer)'), {
                variant: 'error',
            })
        }
    }

    const handleUndoButtonClick = () => {
        setCompanyName(customer.companyName || '')
        setAccountCode(customer.accountCode || '')
        setIsEditing(false)
    }

    const handleCompanyNameChange = (event) => {
        setCompanyName(event.target.value)
    }

    const handleAccountCodeChange = (event) => {
        setAccountCode(event.target.value)
    }

    const isValid = () => {
        return !!companyName
    }

    //check if company name is unique
    const nameIsUnique = (name) => {
        if (name === customer?.companyName) {
            return true
        }

        return customers?.every((c) => c.companyName?.toLowerCase() !== name?.toLowerCase()?.trim())
    }

    return (
        <Box sx={classes.container}>
            <Box
                alignItems="baseline"
                display="flex"
            >
                <Box sx={classes.inputContainer}>
                    <TextField
                        data-testid="company-name"
                        error={!isValid() || !nameIsUnique(companyName)}
                        helperText={
                            !isValid()
                                ? t('Required')
                                : null || !nameIsUnique(companyName)
                                ? t('Company name already exists')
                                : null
                        }
                        inputProps={{
                            sx: classes.customerName,
                            style: { width: inputWidth },
                        }}
                        InputProps={{
                            readOnly: !isEditing,
                            disableUnderline: !isEditing,
                        }}
                        placeholder={t('Company name')}
                        value={companyName}
                        onChange={handleCompanyNameChange}
                    />

                    <TextField
                        data-testid="account-code"
                        inputProps={{
                            sx: classes.accountCode,
                            style: { width: inputWidth },
                        }}
                        InputProps={{
                            readOnly: !isEditing,
                            disableUnderline: !isEditing,
                        }}
                        placeholder={!isEditing ? null : t('Account code')}
                        value={accountCode}
                        onChange={handleAccountCodeChange}
                    />
                </Box>

                {!customer?.isDeleted ? <>
                        <IconButton
                            aria-label={isEditing ? 'Save' : 'Edit'}
                            color="secondary"
                            disabled={isEditing ? !isValid() || !nameIsUnique(companyName) : null}
                            onClick={handleEditButtonClick}
                        >
                            {isEditing ? <Save /> : <Edit />}
                        </IconButton>
                        {isEditing ? <IconButton
                                aria-label={'Undo'}
                                color="secondary"
                                onClick={handleUndoButtonClick}
                                     >
                                <Undo />
                            </IconButton> : null}
                    </> : null}
            </Box>
        </Box>
    )
}

CustomerHeader.propTypes = {
    customer: PropTypes.object,
}

export default CustomerHeader
