import { useEffect, useState } from 'react'
import { useSelector } from 'react-redux'
import PropTypes from 'prop-types'

import { selectUserRole } from '@/app/slices/appSlice'

import Unauthorized from './Unauthorized/Unauthorized'

const RoleRequiredContainer = ({ children, role }) => {
    const userRole = useSelector(selectUserRole)
    const [userIsAuthorized, setUserIsAuthorized] = useState(Boolean(role))

    useEffect(() => {
        if (!role) {
            setUserIsAuthorized(false)
        } else {
            switch (role) {
                case 'owner':
                    setUserIsAuthorized(userRole === 'owner')
                    break
                case 'administrator':
                    setUserIsAuthorized(userRole === 'administrator' || userRole === 'owner')
                    break
                case 'user':
                    setUserIsAuthorized(userRole === 'administrator' || userRole === 'owner' || userRole === 'user')
                    break
                default:
                    setUserIsAuthorized(false)
                    break
            }
        }
    }, [userRole, role])

    return userIsAuthorized ? children : <Unauthorized />
}

RoleRequiredContainer.propTypes = {
    children: PropTypes.node.isRequired,
    role: PropTypes.oneOf(['user', 'administrator', 'owner']).isRequired,
}

export default RoleRequiredContainer
