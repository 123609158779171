import { memo, useMemo } from 'react'
import { useSelector } from 'react-redux'
import { useParams } from 'react-router-dom'
import { blueGrey } from '@mui/material/colors'
import Divider from '@mui/material/Divider'
import { Box } from '@mui/system'

import { useGetQuoteItemsQuery } from '@/app/services/quoteItems'
import { useGetQuoteQuery } from '@/app/services/quotes'
import { useGetTaxRatesQuery } from '@/app/services/taxRates'
import { selectOrganisationId, selectPaidFeatures } from '@/app/slices/organisationSlice'
import { TbxTooltip } from '@/common/components'
import { useToolBoxTreatments } from '@/common/hooks'
import { QUOTE_STATUS } from '@/common/utils/Constants/Constants'

import QuoteIntegrationChip from '../common/QuoteIntegrationChip'
import QuotePaidStatusChip from '../common/QuotePaidStatusChip'
import QuoteSourceChip from '../common/QuoteSourceChip'
import QuoteStatusChip from '../common/QuoteStatusChip'
import QuoteTaxRateChip from '../common/QuoteTaxRateChip'

import QuoteCustomerContact from './QuoteCustomerContact'
import QuoteDetailsOptions from './QuoteDetailsOptions'
import QuoteNotes from './QuoteNotes'
import QuotePriceAdjustment from './QuotePriceAdjustment'
import QuoteShippingOptions from './QuoteShippingOptions'

const classes = {
    content: {
        backgroundColor: 'background.paper',
        borderRadius: 2,
        border: (theme) => `1px solid ${theme.palette.grey[400]}`,
        width: '100%',
        marginBottom: 3,
        padding: 3,
    },

    divider: {
        borderStyle: 'dashed',
        borderColor: blueGrey[200],
    },
}
const QuoteHeader = () => {
    const { quoteId } = useParams()
    const { showIntegrationStatus, showIntegrations, showMultipleTaxRates, showPayments } = useToolBoxTreatments()

    const organisationId = useSelector(selectOrganisationId)
    const paidFeatures = useSelector(selectPaidFeatures)

    const { data: quote, refetch } = useGetQuoteQuery({
        organisationId,
        quoteId,
    })
    const { data: quoteItems } = useGetQuoteItemsQuery({
        organisationId,
        quoteId,
    })

    const { data: taxRates } = useGetTaxRatesQuery({ organisationId, params: { includeDeleted: true } })

    const hasPartsFromLibrary = useMemo(() => {
        return quoteItems?.some((item) => item?.partLibraryEntryId)
    }, [quoteItems])

    const quoteStatus = quote?.status
    const quoteSource = quote?.quoteSourceType
    const quoteTaxRate = taxRates?.find((taxRate) => taxRate.id === quote?.taxRateId) ?? null

    const isEditable = useMemo(() => {
        return [QUOTE_STATUS.NotCalculated, QUOTE_STATUS.Calculated].includes(quoteStatus)
    }, [quote?.status])

    const isDraft = useMemo(() => {
        return [QUOTE_STATUS.NotCalculated, QUOTE_STATUS.Calculated, QUOTE_STATUS.Draft].includes(quoteStatus)
    }, [quote?.status])

    return (
        <>
            <Box
                display="flex"
                flexDirection="column"
                gap={3}
                sx={classes.content}
            >
                <Box
                    alignItems="center"
                    display="flex"
                    justifyContent="space-between"
                >
                    <Box
                        display="flex"
                        gap={1}
                    >
                        <QuoteStatusChip
                            key={`${quote?.id}-status-chip`}
                            quoteStatus={quoteStatus}
                        />
                        <QuoteSourceChip
                            key={`${quote?.id}-source-chip`}
                            quoteSource={quoteSource}
                        />
                        {showIntegrationStatus && showIntegrations ? (
                            <TbxTooltip
                                title={quote?.integrationExportFailureMessage ?? ''}
                                arrow
                            >
                                <span>
                                    <QuoteIntegrationChip
                                        integrationExportFailureMessage={quote?.integrationExportFailureMessage}
                                        integrationExportSucceeded={quote?.integrationExportSucceeded}
                                        key={`${quote?.id}-integration-chip`}
                                    />
                                </span>
                            </TbxTooltip>
                        ) : null}
                        {showMultipleTaxRates && !isEditable && quoteTaxRate ? (
                            <QuoteTaxRateChip
                                key={`${quote?.id}-tax-rate-chip`}
                                quoteTaxRate={quoteTaxRate}
                            />
                        ) : null}
                        {showPayments && !isEditable ? (
                            <QuotePaidStatusChip
                                key={`${quote?.id}-paid-status-chip`}
                                paymentStatus={quote?.paymentStatus}
                            />
                        ) : null}
                    </Box>

                    <Box
                        display="flex"
                        gap={1}
                    >
                        {isDraft ? (
                            <QuotePriceAdjustment
                                key={`${quote?.id}-price-adjustment`}
                                quote={quote}
                                quoteItems={quoteItems}
                            />
                        ) : null}
                        {!paidFeatures?.isFreePlan ? <QuoteNotes key={`${quote?.id}-notes`} /> : null}
                    </Box>
                </Box>

                <Box
                    alignItems="center"
                    display="flex"
                    gap={3}
                >
                    <QuoteCustomerContact
                        hasPartsFromLibrary={hasPartsFromLibrary}
                        key={`${quote?.id}-customer-contact`}
                        quote={quote}
                        refetch={refetch}
                    />

                    <Divider
                        orientation="vertical"
                        sx={classes.divider}
                        flexItem
                    />

                    <QuoteDetailsOptions
                        key={`${quote?.id}-details-options`}
                        quote={quote}
                    />
                </Box>
            </Box>

            <Box sx={classes.content}>
                <QuoteShippingOptions
                    key={`${quote?.id}-shipping-options`}
                    quote={quote}
                />
            </Box>
        </>
    )
}

export default memo(QuoteHeader)
