import { forwardRef } from 'react'
import { Button as MuiButton } from '@mui/material'

const Button = forwardRef((props, ref) => {
    const { children, ...other } = props

    return (
        <MuiButton
            ref={ref}
            {...other}
        >
            {children}
        </MuiButton>
    )
})

Button.displayName = 'Button'

export default Button
