import { Divider } from '@mui/material'

const TbxToolbarDivider = () => {
    return (
        <Divider
            orientation="vertical"
            sx={{
                backgroundColor: 'divider',
            }}
            flexItem
        />
    )
}

export default TbxToolbarDivider
