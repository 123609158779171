import MuiTableCell from '@mui/material/TableCell'
import PropTypes from 'prop-types'

const TableCell = ({ align = 'inherit', children, colSpan = 1, padding, ...other }) => {
    return (
        <MuiTableCell
            align={align}
            colSpan={colSpan}
            padding={padding}
            {...other}
        >
            {children}
        </MuiTableCell>
    )
}

TableCell.propTypes = {
    align: PropTypes.oneOf(['center', 'inherit', 'justify', 'left', 'right']),
    children: PropTypes.node,
    colSpan: PropTypes.number,
    padding: PropTypes.oneOf(['default', 'checkbox', 'none']),
}

export default TableCell
