import { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import PropTypes from 'prop-types'

import { Button, Dialog, Input } from '@/common/components'
import { stringIsEmptyOrWhitespace } from '@/common/utils'

const classes = {
    root: {
        width: 360,
    },
}

const ExtractPartDialog = ({ initialName, multiple = false, onClose, onSubmit, open = false }) => {
    const { t } = useTranslation()

    const [extractPartName, setExtractPartName] = useState(initialName)

    const partNameIsEmpty = stringIsEmptyOrWhitespace(extractPartName)

    useEffect(() => {
        setExtractPartName(initialName)
    }, [initialName])

    const handleSubmitClick = () => {
        if (typeof onSubmit === 'function') {
            onSubmit(extractPartName)
        }
    }

    return (
        <Dialog
            actions={[
                <Button
                    className="extract-part-dialog-button-extract"
                    color="primary"
                    disabled={partNameIsEmpty}
                    key={1}
                    variant="contained"
                    onClick={handleSubmitClick}
                >
                    {t('Extract')}
                </Button>,
            ]}
            className={classes.root}
            open={open}
            title={multiple ? t('New parts name prefix') : t('New part name')}
            onClose={onClose}
        >
            <Input
                autoFocus={true}
                className="extract-part-dialog-input-name"
                error={partNameIsEmpty}
                fullWidth={true}
                textAlign="left"
                value={extractPartName}
                onChange={(event) => setExtractPartName(event.target.value)}
            />
        </Dialog>
    )
}

ExtractPartDialog.propTypes = {
    initialName: PropTypes.string,
    multiple: PropTypes.bool,
    open: PropTypes.bool,
    onClose: PropTypes.func,
    onSubmit: PropTypes.func,
}

export default ExtractPartDialog
