import { createSlice } from '@reduxjs/toolkit'

import { contactsApi } from '../services/contacts'
import { customersApi } from '../services/customers'

const initialState = {
    allContacts: [],
    customerContacts: [],
    selectedContact: undefined,
}

export const contactsSlice = createSlice({
    name: 'contactsSlice',
    initialState,
    reducers: {
        setAllContacts: (state, action) => {
            state.allContacts = action.payload
        },
        setCustomerContacts: (state, action) => {
            state.customerContacts = action.payload
        },
        setSelectedContact: (state, action) => {
            state.selectedContact = action.payload
        },
    },
    extraReducers: (builder) => {
        builder
            .addMatcher(customersApi.endpoints.getCustomers.matchFulfilled, (state, { payload }) => {
                state.allContacts = payload.reduce((acc, customer) => {
                    return [...acc, ...customer.contacts]
                }, [])
            })

            .addMatcher(contactsApi.endpoints.getContacts.matchFulfilled, (state, { payload }) => {
                state.customerContacts = payload
            })
    },
})

export const selectAllContacts = (state) => state.contactsSlice.allContacts
export const selectCustomerContacts = (state) => state.contactsSlice.customerContacts
export const selectSelectedContact = (state) => state.contactsSlice.selectedContact

export const { setAllContacts, setCustomerContacts, setSelectedContact } = contactsSlice.actions

export default contactsSlice.reducer
