import { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useSelector } from 'react-redux'
import { useParams } from 'react-router-dom'
import { Close } from '@mui/icons-material'
import {
    Box,
    Dialog,
    DialogContent,
    DialogTitle as MuiDialogTitle,
    IconButton,
    ToggleButton,
    ToggleButtonGroup,
    Typography,
} from '@mui/material'
import _ from 'lodash'
import PropTypes from 'prop-types'

import { useGetQuoteItemDrawingQuery } from '@/app/services/web-store/webStoreQuoteItems'
import { selectOrganisationId } from '@/app/slices/organisationSlice'
import { useNumberFormatter } from '@/common/hooks'

import { quoteItemErrors, quoteItemHasIssues, quoteItemWarnings } from '../../../helpers/quoteUtilities'
import DrawingCleaner from '../../DrawingCleaner/DrawingCleaner'
import QuoteItemStatus from '../../Quote/QuoteItemStatus'

const classes = {
    root: {
        minWidth: '1000px',
    },
    titleContainer: {
        display: 'flex',
        flexDirection: 'row',
        justifyContent: 'space-between',
        alignItems: 'center',
    },
    flex: {
        display: 'flex',
        alignItems: 'center',
        gap: '12px',
    },
    content: {
        height: '640px',
        backgroundColor: '#303030',
        padding: 0,
    },
    toggleButtonGroup: {
        backgroundColor: 'primary.main',
    },
    toggleButton: {
        color: 'primary.contrastText',
        padding: '8px 12px',
        '&.Mui-selected': {
            color: 'primary.contrastText',
        },
    },
}

const DrawingCleanerModal = ({ mode = 'webstore', onClose, open, quoteItem }) => {
    const { t } = useTranslation()

    const params = useParams()
    const { quoteId } = params

    const organisationId =
        mode === 'webstore' ? params.organisationId : useSelector((state) => selectOrganisationId(state))

    const [cadView, setCadView] = useState(null)
    const [showCadView, setShowCadView] = useState(false)

    const handleViewChange = (event, newView) => setShowCadView(newView)

    const drawingCleanerDecimalPlaces = (quoteItem) => (quoteItem.isImperial ? 3 : 2)

    const { data: drawing, isLoading: isLoadingDrawing } = useGetQuoteItemDrawingQuery({
        organisationId,
        quoteId: quoteId,
        quoteItemId: quoteItem.id,
    })

    const isPartFlat = quoteItem.profile === undefined || quoteItem.profile === null || quoteItem.profile === ''

    const { n } = useNumberFormatter({
        numberOfDecimalPlaces: drawingCleanerDecimalPlaces(quoteItem),
    })

    const renderDimensions = () => {
        if (!quoteItem) return null
        return (
            <Typography variant="body1">
                {quoteItem.profile
                    ? quoteItem.diameter
                        ? `${n(quoteItem.diameter)} x ${n(quoteItem.diameter)} x ${n(quoteItem.rotaryPartLength)}`
                        : `${n(quoteItem.rotaryProfileWidth)} x ${n(quoteItem.rotaryProfileLength)} x ${n(
                              quoteItem.rotaryPartLength
                          )}`
                    : `${n(quoteItem.displayBoundBoxWidth)} x ${n(quoteItem.displayBoundBoxHeight)}`}
            </Typography>
        )
    }

    useEffect(() => {
        const baseURL = import.meta.env.VITE_API_SERVER_URL
        fetch(`${baseURL}/store/${organisationId}/${quoteId}/quote-items/${quoteItem?.id}/cad-view`)
            .then((response) => {
                if (!response.ok) {
                    throw new Error('Network response was not ok')
                }
                return response.text() // SVG is typically returned as text
            })
            .then((svgData) => setCadView(svgData))
            .catch((error) => {
                console.error('There was a problem with the fetch operation:', error)
            })
    }, [])

    const hasIssues = quoteItemHasIssues(quoteItem)
    const hasWarnings = quoteItemWarnings(quoteItem)
    const hasErrors = quoteItemErrors(quoteItem)

    return (
        <Dialog
            maxWidth="lg"
            open={open}
            fullWidth
            onClose={onClose}
        >
            <MuiDialogTitle sx={classes.titleContainer}>
                <Box sx={classes.flex}>
                    <Typography variant="body1">{quoteItem.name}</Typography>

                    {!hasIssues ? <QuoteItemStatus /> : null}
                    {hasIssues && hasWarnings ? (
                        <QuoteItemStatus
                            messages={hasWarnings}
                            status="warning"
                        />
                    ) : null}
                    {hasIssues && hasErrors ? (
                        <QuoteItemStatus
                            messages={hasErrors}
                            status="error"
                        />
                    ) : null}
                </Box>

                <ToggleButtonGroup
                    data-testid="quote-item-toolbar-button-toggle-cad-view"
                    size="small"
                    sx={classes.toggleButtonGroup}
                    value={showCadView}
                    exclusive
                    onChange={handleViewChange}
                >
                    <ToggleButton
                        sx={classes.toggleButton}
                        value={false}
                    >
                        {t('Cut Path')}
                    </ToggleButton>
                    <ToggleButton
                        sx={classes.toggleButton}
                        value={true}
                    >
                        {isPartFlat ? t('Original CAD') : t('3D Model')}
                    </ToggleButton>
                </ToggleButtonGroup>

                <Box sx={classes.flex}>
                    {renderDimensions()}
                    <IconButton
                        color="primary"
                        size="small"
                        onClick={onClose}
                    >
                        <Close fontSize="small" />
                    </IconButton>
                </Box>
            </MuiDialogTitle>
            <DialogContent sx={classes.content}>
                {!isLoadingDrawing ? (
                    <DrawingCleaner
                        disabled={typeof quoteItem.partLibraryEntryId === 'undefined' ? false : true}
                        drawing={_.cloneDeep(drawing)}
                        originalCadSvg={cadView}
                        part={quoteItem}
                        showCadView={showCadView}
                        showLoadingOverlay={false}
                        webStoreMode={true}
                    />
                ) : null}
            </DialogContent>
        </Dialog>
    )
}

DrawingCleanerModal.propTypes = {
    mode: PropTypes.oneOf(['webstore', 'quotemate']),
    open: PropTypes.bool,
    quoteItem: PropTypes.object,
    onClose: PropTypes.func,
}

export default DrawingCleanerModal
