import { createSlice } from '@reduxjs/toolkit'

const initialState = {
    selectedRateTableId: null,
    showAddRateTableForm: false,
}

export const rateTablesSlice = createSlice({
    name: 'rateTablesSlice',
    initialState,
    reducers: {
        setSelectedRateTableId: (state, action) => {
            state.selectedRateTableId = action.payload
        },
        setShowAddRateTableForm: (state, action) => {
            state.showAddRateTableForm = action.payload
        },
    },
})

export const selectSelectedRateTableId = (state) => state.rateTablesSlice.selectedRateTableId
export const selectShowAddRateTableForm = (state) => state.rateTablesSlice.showAddRateTableForm

export const { setSelectedRateTableId, setShowAddRateTableForm } = rateTablesSlice.actions

export default rateTablesSlice.reducer
