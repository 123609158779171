import { useTranslation } from 'react-i18next'
import { useSelector } from 'react-redux'
import {
    LinearProgress,
    Table,
    TableBody,
    TableCell,
    TableHead,
    TablePagination,
    TableRow,
    TableSortLabel,
} from '@mui/material'
import PropTypes from 'prop-types'
import useLocalStorage from 'use-local-storage'

import { selectOrganisation } from '@/app/slices/organisationSlice'
import { PARTS_PER_PAGE_DEFAULT_VALUE, PARTS_PER_PAGE_OPTIONS, PARTS_PER_PAGE_VARIABLE } from '@/common/utils'

import QuoteHistoryRow from './QuoteHistoryRow'

const classes = {
    root: {
        display: 'flex',
        flexDirection: 'column',
    },
    searchBar: {
        display: 'flex',
        width: '100%',
        marginBottom: '24px',
        justifyContent: 'space-between',
    },
    table: {
        tableLayout: 'fixed',
        border: '1px solid rgba(52, 73, 94, 0.12)',
        boxSizing: 'border-box',
        borderRadius: '8px 8px 0 0',
        borderCollapse: 'separate',
        backgroundColor: 'background.paper',
    },
    headerTableCell: {
        padding: '17px 24px',
        verticalAlign: 'bottom',
        fontWeight: 600,
        fontSize: '14px',
        lineHeight: '18px',
        alignItems: 'center',
        color: 'text.primary',
    },
    tableFooter: {
        border: '1px solid rgba(52, 73, 94, 0.12)',
        borderTop: 'none',
        boxSizing: 'border-box',
        borderRadius: '0px 0px 8px 8px',
        backgroundColor: 'background.paper',
    },
    tablePaginationCaption: {
        fontSize: '12px',
        color: '#5E7387',
    },
}

export const QuoteHistoryTable = ({
    currentPage,
    filteredQuotes,
    handlePageOrderChanged,
    isLoading,
    onArchiveQuoteClick,
    onUnarchiveQuoteClick,
    order,
    orderBy,
    totalQuoteCount,
}) => {
    const { t } = useTranslation()

    const organisation = useSelector(selectOrganisation)

    const handleRequestSort = (property) => {
        const isAsc = orderBy === property && order === 'asc'
        handlePageOrderChanged(currentPage, rowsPerPage, isAsc ? 'desc' : 'asc', property)
    }

    const handleChangeRowsPerPage = (event) => {
        setRowsPerPage(parseInt(event.target.value, 10))
        const newRowsPerPage = parseInt(event.target.value, 10)
        handlePageOrderChanged(0, newRowsPerPage, order, orderBy)
    }

    const [rowsPerPage, setRowsPerPage] = useLocalStorage(PARTS_PER_PAGE_VARIABLE, PARTS_PER_PAGE_DEFAULT_VALUE)

    const createColumnHeader = (columnId, columnLabel, isSortable) => (
        <TableSortLabel
            active={isSortable ? orderBy === columnId : null}
            direction={isSortable && orderBy === columnId ? order : 'asc'}
            hideSortIcon={!isSortable}
            onClick={
                isSortable
                    ? () => {
                          handleRequestSort(columnId)
                      }
                    : null
            }
        >
            {columnLabel}
        </TableSortLabel>
    )

    return (
        <>
            {isLoading ? <LinearProgress className={classes.tableLoader} /> : null}
            <Table
                aria-label="contacts"
                sx={classes.table}
            >
                <TableHead>
                    <TableRow>
                        <TableCell sx={classes.headerTableCell}>
                            {createColumnHeader('name', t('$t(Quote) name'), true)}
                        </TableCell>
                        <TableCell sx={classes.headerTableCell}>
                            {createColumnHeader('numberOfItems', t('Number of parts'), true)}
                        </TableCell>
                        <TableCell sx={classes.headerTableCell}>
                            {createColumnHeader('lineTaxedPrice', t('$t(Quote) total'), true)}
                        </TableCell>
                        <TableCell sx={classes.headerTableCell}>
                            {createColumnHeader('lastModifiedByUserName', t('Last modified by'), true)}
                        </TableCell>
                        <TableCell sx={classes.headerTableCell}>
                            {createColumnHeader('lastModifiedDateUtc', t('Last modified'), true)}
                        </TableCell>
                        <TableCell sx={classes.headerTableCell}>
                            {createColumnHeader('quoteSourceType', t('Source'), true)}
                        </TableCell>
                        <TableCell sx={classes.headerTableCell}>
                            {createColumnHeader('status', t('Status'), true)}
                        </TableCell>
                        <TableCell></TableCell>
                    </TableRow>
                </TableHead>
                <TableBody>
                    {filteredQuotes?.map((quote) => {
                        return (
                            <QuoteHistoryRow
                                key={quote.id}
                                quote={quote}
                                onArchiveQuoteClick={onArchiveQuoteClick}
                                onUnarchiveQuoteClick={onUnarchiveQuoteClick}
                            />
                        )
                    })}
                </TableBody>
            </Table>
            <TablePagination
                classes={{
                    caption: classes.tablePaginationCaption,
                }}
                component="div"
                count={totalQuoteCount}
                labelDisplayedRows={({ count, from, to }) => `${from}-${to} of ${count}`}
                labelRowsPerPage={t('$t(Quotes) per page')}
                page={currentPage}
                rowsPerPage={rowsPerPage}
                rowsPerPageOptions={PARTS_PER_PAGE_OPTIONS}
                sx={classes.tableFooter}
                onPageChange={(_, newPage) => {
                    handlePageOrderChanged(newPage, rowsPerPage, order, orderBy)
                }}
                onRowsPerPageChange={handleChangeRowsPerPage}
            />
        </>
    )
}

QuoteHistoryTable.propTypes = {
    currentPage: PropTypes.number,
    filteredQuotes: PropTypes.arrayOf(PropTypes.object),
    handlePageOrderChanged: PropTypes.func,
    isLoading: PropTypes.bool,
    order: PropTypes.string,
    orderBy: PropTypes.string,
    totalQuoteCount: PropTypes.number,
    onArchiveQuoteClick: PropTypes.func,
    onUnarchiveQuoteClick: PropTypes.func,
}
