import { memo } from 'react'
import { AccordionDetails, Box, Divider } from '@mui/material'
import PropTypes from 'prop-types'

import ItemSecondaryProcesses from './SecondaryProcesses/ItemSecondaryProcesses'
import ItemDrawing from './ItemDrawing'
import ItemFolding from './ItemFolding'
import ItemPricesAndTimes from './ItemPricesAndTimes'
import ItemSettings from './ItemSettings'

const classes = {
    AccordionDetailsRoot: {
        display: 'flex',
        backgroundColor: 'background.paper',
        padding: 3,
        gap: '24px',
        borderTop: (theme) => `1px solid ${theme.palette.grey[400]}`,
    },

    ItemDetails_Processes: {
        width: '220px',
        gap: '16px',
    },
    ListItemIconRoot: {
        minWidth: 'initial',
        paddingRight: 1,
    },
}

const ItemDetails = ({ isRotary, quoteItemId }) => {
    return (
        <AccordionDetails sx={classes.AccordionDetailsRoot}>
            <ItemDrawing
                key={`item-drawing-${quoteItemId}`}
                quoteItemId={quoteItemId}
            />
            <Divider
                orientation="vertical"
                flexItem
            />
            <ItemSettings
                key={`item-settings-${quoteItemId}`}
                quoteItemId={quoteItemId}
            />
            <Divider
                orientation="vertical"
                flexItem
            />
            <Box
                alignItems="flex-start"
                display="flex"
                flex="1 0 auto"
                flexDirection="column"
                sx={classes.ItemDetails_Processes}
            >
                {!isRotary ? (
                    <>
                        <ItemFolding
                            key={`item-folding-${quoteItemId}`}
                            quoteItemId={quoteItemId}
                        />
                        <Divider flexItem />
                    </>
                ) : null}
                <ItemSecondaryProcesses
                    key={`item-secondary-processes-${quoteItemId}`}
                    quoteItemId={quoteItemId}
                />
            </Box>
            <Divider
                orientation="vertical"
                flexItem
            />

            <ItemPricesAndTimes quoteItemId={quoteItemId} />
        </AccordionDetails>
    )
}

ItemDetails.propTypes = {
    isRotary: PropTypes.bool,
    quoteItemId: PropTypes.string,
}

export default memo(ItemDetails)
