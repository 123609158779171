import { Box } from '@mui/material'

import MaterialRequirements from './MaterialRequirements'
import PriceTotals from './PriceTotals'
import Summary from './Summary'

const QuoteSummary = () => {
    return (
        <Box
            alignItems="space-between"
            display="flex"
            flexDirection="row"
            gap={3}
            mt={3}
        >
            <MaterialRequirements />
            <Summary />
            <PriceTotals />
        </Box>
    )
}

export default QuoteSummary
