import { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useDispatch, useSelector } from 'react-redux'
import { useParams } from 'react-router-dom'
import { FormControl, InputLabel, MenuItem, Select } from '@mui/material'
import { useSnackbar } from 'notistack'

import { useGetMaterialsQuery } from '@/app/services/materials'
import { selectQuoteItems, useUpdateQuoteItemsMutation } from '@/app/services/quoteItems'
import { selectQuote, useUpdateQuoteMutation } from '@/app/services/quotes'
import { selectOrganisationId } from '@/app/slices/organisationSlice'
import { setSelectedQuoteStatus } from '@/app/slices/quoteItemsSlice'
import { QUOTE_STATUS } from '@/common/utils'
import { getSortFunction } from '@/common/utils/quoteUtilities'

const QuoteItemListSort = () => {
    const { t } = useTranslation()
    const { enqueueSnackbar } = useSnackbar()
    const { quoteId } = useParams()
    const dispatch = useDispatch()

    const organisationId = useSelector(selectOrganisationId)
    const selectedQuote = useSelector((state) => selectQuote(state, { organisationId: organisationId, quoteId }))
    const quoteItems = useSelector((state) => selectQuoteItems(state, { organisationId: organisationId, quoteId }))

    const { data: materials } = useGetMaterialsQuery({ organisationId })

    const [selectedCriteria, setSelectedCriteria] = useState(selectedQuote.sortQuoteItemsBy ?? 'Custom')
    const [selectedDirection, setSelectedDirection] = useState(selectedQuote.sortQuoteItemsAsc ? 'ASC' : 'DESC')

    const [updateQuote] = useUpdateQuoteMutation()
    const [updateQuoteItems] = useUpdateQuoteItemsMutation()

    const handleUpdateQuote = async (updatedFields) => {
        try {
            await updateQuote({
                organisationId,
                quoteId,
                quote: {
                    ...selectedQuote,
                    ...updatedFields,
                },
            }).unwrap()
        } catch (_e) {
            const errorMessage = t('$t(An error occurred) updating the $t(quote).')
            enqueueSnackbar(errorMessage, { variant: 'error' })
        }
    }

    const handleUpdateQuoteItems = async (sortedList) => {
        try {
            await updateQuoteItems({
                organisationId,
                quoteId,
                quoteItems: sortedList,
            }).unwrap()
        } catch (e) {
            enqueueSnackbar(t('Failed to reorder $t(quote) items. Undoing changes'), { variant: 'error' })
        }
    }

    const handleCriteriaChange = (event) => {
        const { value } = event.target
        setSelectedCriteria(value)

        const sortedList = [...quoteItems]
            .sort(getSortFunction(value, selectedDirection, materials))
            .map((quoteItem, index) => ({ ...quoteItem, index }))

        handleUpdateQuote({ sortQuoteItemsBy: value })
        handleUpdateQuoteItems(sortedList)
    }

    const handleDirectionChange = (event) => {
        const { value } = event.target
        setSelectedDirection(value)

        const sortedList = [...quoteItems]
            .sort(getSortFunction(selectedCriteria, value, materials))
            .map((quoteItem, index) => ({ ...quoteItem, index }))

        handleUpdateQuote({ sortQuoteItemsAsc: value == 'ASC' })
        handleUpdateQuoteItems(sortedList)
    }

    useEffect(() => {
        setSelectedCriteria(selectedQuote.sortQuoteItemsBy ?? 'Custom')
        setSelectedDirection(selectedQuote.sortQuoteItemsAsc ? 'ASC' : 'DESC')
    }, [selectedQuote])

    return (
        <>
            <FormControl variant="outlined">
                <InputLabel
                    data-testid="sort-by-label"
                    id="selected-criteria-label"
                >
                    {t('Sort by')}
                </InputLabel>
                <Select
                    data-testid="sort-by"
                    id="selected-criteria"
                    label={t('Sort by')}
                    labelId="selected-criteria-label"
                    size="small"
                    value={selectedCriteria}
                    onChange={handleCriteriaChange}
                >
                    <MenuItem value={'Default'}>{t('Upload order')}</MenuItem>
                    {selectedCriteria === 'Custom' ? <MenuItem value={'Custom'}>{t('User defined')}</MenuItem> : null}
                    <MenuItem value={'Name'}>{t('Part name')}</MenuItem>
                    <MenuItem value={'MaterialThickness'}>{t('Material & Thickness')}</MenuItem>
                </Select>
            </FormControl>
            <FormControl variant="outlined">
                <InputLabel
                    data-testid="order-by-label"
                    id="selected-direction-label"
                >
                    {t('Order by')}
                </InputLabel>
                <Select
                    data-testid="order-by"
                    disabled={selectedCriteria === 'Custom'}
                    id="selected-direction"
                    label={t('Order by')}
                    labelId="selected-direction-label"
                    size="small"
                    value={selectedDirection}
                    onChange={handleDirectionChange}
                >
                    <MenuItem value={'ASC'}>{t('ASC')}</MenuItem>
                    <MenuItem value={'DESC'}>{t('DESC')}</MenuItem>
                </Select>
            </FormControl>
        </>
    )
}

export default QuoteItemListSort
