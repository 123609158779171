import { useCallback, useEffect, useMemo, useRef } from 'react'

import { getSingletonChannel } from '../utils/GetSingletonChannel'

export function useBroadcastChannel(channelName, onMessageReceived) {
    const channel = useMemo(() => getSingletonChannel(channelName), [channelName])
    const isSubscribed = useRef(false)

    useEffect(() => {
        if (!isSubscribed.current) {
            if (!channel) {
                console.error('Channel is not defined')
            } else {
                channel.onmessage = (event) => onMessageReceived(event.data)
            }
        }

        return () => {
            if (isSubscribed.current) {
                channel.close()
                isSubscribed.current = true
            }
        }
    }, [])

    const postMessage = useCallback(
        (message) => {
            try {
                channel?.postMessage(message)
            } catch (error) {
                console.error('Failed to post message:', error)
            }
        },
        [channel]
    )

    return {
        postMessage,
    }
}
