import { useEffect, useMemo, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useSelector } from 'react-redux'
import { useParams } from 'react-router-dom'
import { Close, Save } from '@mui/icons-material'
import { Box, Dialog, DialogContent, DialogTitle, Divider, IconButton, Typography } from '@mui/material'
import { useSnackbar } from 'notistack'
import PropTypes from 'prop-types'

import { selectQuoteItems, useUpdateQuoteItemsAttributesMutation } from '@/app/services/quoteItems'
import { selectQuote, useUpdateQuoteMutation } from '@/app/services/quotes'
import { selectOrganisationId } from '@/app/slices/organisationSlice'
import { selectSelectedItems } from '@/app/slices/quoteItemsSlice'
import { TbxTooltip } from '@/common/components'
import TbxShadowScroll from '@/common/components/TbxShadowScroll'
import { QUOTE_STATUS } from '@/common/utils/Constants/Constants'
import TbxDialog from '@/features/web-store/components/shared/TbxDialog'

import FoldingBulkEdit from './FoldingBulkEdit'
import SecondaryProcessesBulkEdit from './SecondaryProcessesBulkEdit'
import SettingsBulkEdit from './SettingsBulkEdit'

const QuoteItemsBulkUpdate = ({ onClose, open }) => {
    const { t } = useTranslation()
    const { enqueueSnackbar } = useSnackbar()
    const { quoteId } = useParams()

    const organisationId = useSelector(selectOrganisationId)
    const selectedItemsArray = useSelector(selectSelectedItems)
    const quoteItems = useSelector((state) => selectQuoteItems(state, { organisationId, quoteId }))
    const selectedQuote = useSelector((state) => selectQuote(state, { organisationId, quoteId }))

    const [updateQuoteItems] = useUpdateQuoteItemsAttributesMutation()
    const [updateQuote] = useUpdateQuoteMutation()

    const [itemQuantity, setItemQuantity] = useState('')
    const [itemCuttingTechnology, setItemCuttingTechnology] = useState('')
    const [itemMaterial, setItemMaterial] = useState('')
    const [itemThickness, setItemThickness] = useState('')
    const [itemSheet, setItemSheet] = useState('')
    const [itemConsumptionMode, setItemConsumptionMode] = useState('')
    const [itemIsCustomerSuppliedMaterial, setItemIsCustomerSuppliedMaterial] = useState(false)
    const [itemSecondaryProcesses, setItemSecondaryProcesses] = useState([])
    const [itemUnit, setItemUnit] = useState('')

    const [itemSource, setItemSource] = useState('')
    const [itemMaxLength, setItemMaxLength] = useState(null)
    const [itemNumberOfFolds, setItemNumberOfFolds] = useState(null)

    const [isSavingData, setIsSavingData] = useState(false)
    const [isConfirmationDialogOpen, setIsConfirmationDialogOpen] = useState(false)
    const [userMadeSettingsChanges, setUserMadeSettingsChanges] = useState(false)
    const [userMadeFoldingChanges, setUserMadeFoldingChanges] = useState(false)
    const [userMadeSecProcessesChanges, setUserMadeSecProcessesChanges] = useState(false)

    const itemsCount = useMemo(() => selectedItemsArray?.length, [selectedItemsArray])

    const anyPartIsRotary = useMemo(() => {
        const selectedItems = quoteItems.filter((item) => selectedItemsArray.includes(item.id))
        return selectedItems.some((item) => 'profile' in item)
    }, [quoteItems, selectedItemsArray])

    useEffect(() => {
        // make sure state is fresh when dialog opened, changing quote item after opening
        // bulk edit once before sometimes resulted in stale selections
        resetState()
    }, [open])

    const resetState = () => {
        setItemQuantity('')
        setItemCuttingTechnology('')
        setItemMaterial('')
        setItemThickness('')
        setItemSheet('')
        setItemConsumptionMode('')
        setItemIsCustomerSuppliedMaterial(false)
        setItemSecondaryProcesses([])
        setItemSource('')
        setItemMaxLength(null)
        setItemNumberOfFolds(null)
        setItemUnit('')
    }

    const handleCloseClick = () => {
        setIsConfirmationDialogOpen(true)
    }

    const hideConfirmationDialog = () => {
        setIsConfirmationDialogOpen(false)
    }

    const handleConfirmationDialogClose = () => {
        onClose()
        resetState()
        setIsConfirmationDialogOpen(false)
    }

    const handleSaveChangesClick = async () => {
        await saveChanges()
        onClose()
        resetState()
        setIsConfirmationDialogOpen(false)
    }

    const saveChanges = async () => {
        setIsSavingData(true)
        const quoteItems = {
            quoteItemIds: selectedItemsArray,
            attributes: {
                ...(Boolean(itemQuantity) && { quantity: itemQuantity }),
                ...(userMadeSettingsChanges && {
                    ...(itemCuttingTechnology && { cuttingTechnologyId: itemCuttingTechnology }),
                    ...(itemMaterial && { materialId: itemMaterial }),
                    ...(itemThickness && { thickness: itemThickness }),
                    ...(itemSheet && { sheetId: itemSheet }),
                    ...(itemConsumptionMode && { materialConsumptionMode: itemConsumptionMode }),
                    ...(itemIsCustomerSuppliedMaterial !== undefined && {
                        isCustomerSuppliedMaterial: itemIsCustomerSuppliedMaterial,
                    }),
                    ...(itemUnit && { isImperial: itemUnit === 'in' }),
                    ...(itemUnit && { drawingMetadata: { isImperial: itemUnit === 'in' } }),
                }),
                ...(userMadeFoldingChanges && {
                    foldingSource: itemSource,
                    ...(itemNumberOfFolds && { numberOfFolds: itemSource === 'Drawing' ? 0 : itemNumberOfFolds }),
                    ...(itemMaxLength && { maxFoldLength: itemSource === 'Drawing' ? 0 : itemMaxLength }),
                }),
                ...(userMadeSecProcessesChanges && {
                    secondaryProcess: itemSecondaryProcesses.filter((process) => process.isEnabled !== null),
                }),
            },
        }

        try {
            await updateQuoteItems({ organisationId, quoteId, quoteItems }).unwrap()
            enqueueSnackbar(t('{{length}} parts updated', { length: selectedItemsArray.length }), {
                variant: 'success',
            })
            handleUpdateQuote()
        } catch (error) {
            enqueueSnackbar(
                t('Failed to update {{length}} parts from $t(quote)', {
                    length: selectedItemsArray.length,
                }),
                { variant: 'error' }
            )
        } finally {
            setIsSavingData(false)
        }
    }

    const handleUpdateQuote = async () => {
        try {
            await updateQuote({
                organisationId,
                quoteId: quoteId,
                quote: {
                    ...selectedQuote,
                    status: QUOTE_STATUS.NotCalculated,
                },
            }).unwrap()
        } catch (error) {
            enqueueSnackbar(t('Failed to calculate $t(quote)'), { variant: 'error' })
        }
    }

    return (
        <>
            <Dialog
                maxWidth="lg"
                open={open}
                onClose={(_, reason) => {
                    if (reason !== 'backdropClick') onClose()
                }}
            >
                <DialogTitle>
                    <Box
                        alignItems="center"
                        display="flex"
                        flexDirection="row"
                        justifyContent="space-between"
                    >
                        <Typography variant="strong1">
                            {t(`Editing ${itemsCount} ${itemsCount > 1 ? 'parts' : 'part'}`)}
                        </Typography>
                        <Box
                            display="flex"
                            flexDirection="row"
                        >
                            <TbxTooltip title={t('Save and close')}>
                                <span>
                                    <IconButton
                                        aria-label="save"
                                        color="secondary"
                                        disabled={isSavingData}
                                        onClick={handleSaveChangesClick}
                                    >
                                        <Save />
                                    </IconButton>
                                </span>
                            </TbxTooltip>
                            <TbxTooltip title={t('Close')}>
                                <span>
                                    <IconButton
                                        aria-label="close"
                                        color="secondary"
                                        onClick={handleCloseClick}
                                    >
                                        <Close />
                                    </IconButton>
                                </span>
                            </TbxTooltip>
                        </Box>
                    </Box>
                </DialogTitle>
                <DialogContent>
                    <Box
                        display="flex"
                        flexDirection="row"
                        gap={3}
                    >
                        <SettingsBulkEdit
                            itemConsumptionMode={itemConsumptionMode}
                            itemCuttingTechnology={itemCuttingTechnology}
                            itemIsCustomerSuppliedMaterial={itemIsCustomerSuppliedMaterial}
                            itemMaterial={itemMaterial}
                            itemQuantity={itemQuantity}
                            itemSheet={itemSheet}
                            itemThickness={itemThickness}
                            itemUnit={itemUnit}
                            setItemConsumptionMode={setItemConsumptionMode}
                            setItemCuttingTechnology={setItemCuttingTechnology}
                            setItemIsCustomerSuppliedMaterial={setItemIsCustomerSuppliedMaterial}
                            setItemMaterial={setItemMaterial}
                            setItemQuantity={setItemQuantity}
                            setItemSheet={setItemSheet}
                            setItemThickness={setItemThickness}
                            setItemUnit={setItemUnit}
                            setUserMadeSettingsChanges={setUserMadeSettingsChanges}
                        />
                        <Divider
                            orientation="vertical"
                            flexItem
                        />
                        <Box
                            display="flex"
                            flexDirection="column"
                            gap={3}
                        >
                            {!anyPartIsRotary ? (
                                <>
                                    <FoldingBulkEdit
                                        itemMaxLength={itemMaxLength}
                                        itemNumberOfFolds={itemNumberOfFolds}
                                        itemSource={itemSource}
                                        itemUnit={itemUnit}
                                        setItemMaxLength={setItemMaxLength}
                                        setItemNumberOfFolds={setItemNumberOfFolds}
                                        setItemSource={setItemSource}
                                        setUserMadeFoldingChanges={setUserMadeFoldingChanges}
                                    />
                                    <Divider flexItem />
                                </>
                            ) : null}
                            <TbxShadowScroll wrapperSx={{ height: '200px' }}>
                                <SecondaryProcessesBulkEdit
                                    itemSecondaryProcesses={itemSecondaryProcesses}
                                    setItemSecondaryProcesses={setItemSecondaryProcesses}
                                    setUserMadeSecProcessesChanges={setUserMadeSecProcessesChanges}
                                />
                            </TbxShadowScroll>
                        </Box>
                    </Box>
                </DialogContent>
            </Dialog>
            <TbxDialog
                closeButtonText={t('No')}
                confirmButtonText={t('Yes, close')}
                content={t('Your changes will be lost')}
                handleClose={hideConfirmationDialog}
                handleConfirmClose={handleConfirmationDialogClose}
                isOpen={isConfirmationDialogOpen}
                title={t('Do you want to close without saving?')}
            />
        </>
    )
}

QuoteItemsBulkUpdate.propTypes = {
    open: PropTypes.bool,
    onClose: PropTypes.func,
}

export default QuoteItemsBulkUpdate
