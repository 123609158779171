import { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useSelector } from 'react-redux'
import { useMsal } from '@azure/msal-react'
import {
    Alert,
    AlertTitle,
    Box,
    Button,
    FormControl,
    InputLabel,
    Link,
    MenuItem,
    Select,
    Typography,
} from '@mui/material'
import Grid from '@mui/material/Unstable_Grid2'

import { selectCurrentUser, selectUserRole } from '@/app/slices/appSlice'
import { selectIsOnTrial, selectOrganisation } from '@/app/slices/organisationSlice'
import { useToolBoxTreatments, useUserContextSwitcher } from '@/common/hooks'
import { getProductDetails, LIBELLULA_PRICING_REDIRECT_URL, TOOLBOX_PRICING_REDIRECT_URL } from '@/common/utils'

const NoActiveSubscriptionCard = () => {
    const { t } = useTranslation()

    const organisation = useSelector(selectOrganisation)

    const currentUser = useSelector(selectCurrentUser)
    const userRole = useSelector(selectUserRole)
    const isOnTrial = useSelector(selectIsOnTrial)

    const [userIsOwner, setUserIsOwner] = useState(false)

    const { showLibellulaWhitelabelling } = useToolBoxTreatments()
    const { setSelectedOrganisation } = useUserContextSwitcher()
    const { instance: msalInstance } = useMsal()

    const handleActivateButtonClicked = () => {
        window.open(
            showLibellulaWhitelabelling
                ? LIBELLULA_PRICING_REDIRECT_URL
                : `${TOOLBOX_PRICING_REDIRECT_URL}${organisation?.paymentGatewayCustomerId}`
        )
    }

    const handlePlanInfoButtonClicked = () => {
        window.open('https://tempustools.com/pricing/')
    }

    const handleChange = (event) => {
        const selectedOrganisation = event.target.value
        const organisationIndex = currentUser.organisations.findIndex((o) => o.organisationId === selectedOrganisation)
        const newSelectedOrganisation = currentUser.organisations[organisationIndex]

        setSelectedOrganisation(newSelectedOrganisation.organisationId)
    }

    const handleSignOutClick = () => {
        msalInstance.logoutRedirect()
    }

    useEffect(() => {
        setUserIsOwner(userRole === 'owner')
    }, [userRole])

    return (
        <Grid
            px={2}
            spacing={4}
            container
        >
            <Grid xs={12}>
                <Typography
                    data-testid="subscription-settings-title"
                    variant="h4"
                >
                    {t('No Subscription')}
                </Typography>
            </Grid>

            <Grid
                columnSpacing={3}
                rowSpacing={3}
                xs={12}
                container
            >
                <Grid xs={12}>
                    <Alert
                        severity="error"
                        variant="filled"
                    >
                        <AlertTitle>{isOnTrial ? t("You're on a trial") : t('Your trial has expired')}</AlertTitle>
                        {isOnTrial
                            ? t('Avoid losing access message')
                            : `${t('No active subscription message')} ${t(
                                  'To continue using {{title}}',
                                  getProductDetails()
                              )}`}
                    </Alert>
                </Grid>

                <Grid xs={6}>
                    <Button
                        color="secondary"
                        size="large"
                        variant="contained"
                        fullWidth
                        onClick={handlePlanInfoButtonClicked}
                    >
                        {t('Plans and Features')}
                    </Button>
                </Grid>

                <Grid xs={6}>
                    <Button
                        color="warning"
                        size="large"
                        variant="contained"
                        fullWidth
                        onClick={handleActivateButtonClicked}
                    >
                        {t('Request pricing')}
                    </Button>
                </Grid>

                {!isOnTrial && currentUser?.organisations?.length > 1 ? <Grid
                        mt={4}
                        xs={12}
                                                                        >
                        <Box
                            mb={4}
                            style={{ textAlign: 'center' }}
                        >
                            <Typography
                                align="center"
                                style={{ marginBottom: 16 }}
                                variant="body1"
                            >
                                {t('Change organisation')}
                            </Typography>

                            <FormControl
                                size="small"
                                sx={{ width: 240 }}
                                variant="outlined"
                            >
                                <InputLabel id="organisation-select-label">{t('Select organisation')}</InputLabel>
                                <Select
                                    id="organisation-select"
                                    label="Select organisation"
                                    labelId="organisation-select-label"
                                    value={organisation.organisationId}
                                    onChange={handleChange}
                                >
                                    {currentUser.organisations.map((org) => (
                                        <MenuItem
                                            key={org.organisationId}
                                            value={org.organisationId}
                                        >
                                            {org.name}
                                        </MenuItem>
                                    ))}
                                </Select>
                            </FormControl>
                        </Box>

                        <Box style={{ textAlign: 'center' }}>
                            <Typography
                                align="center"
                                style={{ marginBottom: 8 }}
                                variant="body2"
                            >
                                or
                            </Typography>
                            <Link
                                color="secondary"
                                component="button"
                                underline="always"
                                variant="body2"
                                onClick={handleSignOutClick}
                            >
                                {t('Sign in with a different account')}
                            </Link>

                            {!userIsOwner ? <Typography
                                    align="center"
                                    style={{ marginTop: 8 }}
                                    variant="body2"
                                            >
                                    {t('Contact your subscription owner to activate your organisation subscription')}
                                </Typography> : null}
                        </Box>
                    </Grid> : null}
            </Grid>
        </Grid>
    )
}

export default NoActiveSubscriptionCard
