import _ from 'lodash'

import CustomError from '../Utilities/CustomError'

export default class Layer {
    constructor(json) {
        const jsonValid =
            json &&
            Object.prototype.hasOwnProperty.call(json, 'name') &&
            Object.prototype.hasOwnProperty.call(json, 'colour') &&
            Object.prototype.hasOwnProperty.call(json, 'type')
        if (jsonValid) {
            _.extend(this, json)
            this.isIgnored = false
        } else {
            throw new CustomError('Invalid json supplied', 'InvalidJSONError')
        }
    }

    isCutting() {
        return this.type === 'Cutting'
    }

    isEtching() {
        return this.type === 'Etching'
    }

    isInfo() {
        return this.type === 'Information'
    }

    isFolding() {
        return this.type === 'Folding'
    }
}

Layer.LayerTypes = [
    { layerName: 'Cutting', layerType: 'Cutting' },
    { layerName: 'Etching', layerType: 'Etching' },
    { layerName: 'Information', layerType: 'Information' },
    { layerName: 'Folding', layerType: 'Folding' },
]
