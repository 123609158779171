export const ACCOUNT_PATHNAME = '/account'
export const ACTIVATE_BILLING_DETAILS_PATHNAME = '/activate/billing-details'
export const ACTIVATE_PATHNAME = '/activate'
export const ACTIVATE_PAYMENT_DETAILS_PATHNAME = '/activate/payment-details'
export const ADMINISTRATION = '/administration'
export const BILLING_HISTORY_PATHNAME = '/billing-history'
export const CUSTOMER_CENTRAL_PATHNAME = '/customer-central'
export const CUSTOMER_FORMATTED_QUOTE_PATHNAME = '/quote'
export const CUSTOMERS_PATHNAME = '/customers'
export const DASHBOARD_PATHNAME = '/dashboard'
export const DOCUMENT_PATHNAME = '/quotes/:quoteId/documents/:documentType/:action?'
export const ERROR_PATHNAME = '/sorry'
export const MATERIALS_MANAGEMENT = '/materials'
export const NO_ACTIVE_SUBSCRIPTION_PATHNAME = '/no-subscription'
export const NOT_FOUND = '/not-found'
export const PAYMENTS_PATHNAME = '/payments'
export const PAYMENTS_SUCCESS_PATHNAME = 'success-message'
export const PROCESS_CONFIGURATION = '/process-configuration'
export const QUOTE_PATHNAME = '/quotes/:quoteId'
export const RATE_TABLES = '/rate-tables'
export const REGISTER_PATHNAME = '/register'
export const SETTINGS = '/settings'
export const SHARED_PATHNAME = '/shared'
export const SHARED_V2_PATHNAME = '/shared/v2'
export const SIGNOUT_PATHNAME = '/signout'
export const WEB_STORE = '/store/:organisationId/*'
export const WEB_STORE_SETTINGS = '/settings/store'
export const WELCOME_PATHNAME = '/welcome'
