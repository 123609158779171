import { memo } from 'react'
import { useSelector } from 'react-redux'
import PropTypes from 'prop-types'

import { selectDisplayQuotePricing } from '@/app/slices/appSlice'

import ItemPrices from './ItemPrices'
import ItemTimes from './ItemTimes'

const ItemPricesAndTimes = ({ quoteItemId }) => {
    const displayPricing = useSelector(selectDisplayQuotePricing)

    return displayPricing && quoteItemId ? (
        <ItemPrices
            key={`item-prices-${quoteItemId}`}
            quoteItemId={quoteItemId}
        />
    ) : (
        <ItemTimes
            key={`item-times-${quoteItemId}`}
            quoteItemId={quoteItemId}
        />
    )
}

ItemPricesAndTimes.propTypes = {
    quoteItemId: PropTypes.string,
}

export default memo(ItemPricesAndTimes)
