import { useState } from 'react'
import { Controller, useForm } from 'react-hook-form'
import { useTranslation } from 'react-i18next'
import { useDispatch, useSelector } from 'react-redux'
import { Box, Button, TextField, Typography } from '@mui/material'
import { useSnackbar } from 'notistack'
import PropTypes from 'prop-types'

import { useUpdateCustomerMutation } from '@/app/services/customers'
import { setSelectedCustomerId } from '@/app/slices/customersSlice'
import { selectOrganisationId } from '@/app/slices/organisationSlice'

const classes = {
    title: {
        fontWeight: 500,
        fontSize: '24px',
        lineHeight: '28px',
        color: 'text.primary',
        marginBottom: '24px',
    },
    buttonsContainer: {
        display: 'flex',
        justifyContent: 'space-evenly',
        marginTop: '24px',
        paddingBottom: '36px', // leave room at the bottom of the form for any hovering action buttons being injected
    },
    input: {
        marginBottom: '24px',
    },
    button: {
        fontWeight: 500,
        minWidth: '142px',
        height: '36px',
    },
}

const AddressEditForm = ({ addressType, customer, onCancel, useOneAddress = false }) => {
    const { t } = useTranslation()
    const { enqueueSnackbar } = useSnackbar()
    const dispatch = useDispatch()

    const organisationId = useSelector(selectOrganisationId)

    const [updateCustomer] = useUpdateCustomerMutation()

    const [isSaving, setIsSaving] = useState(false)

    const { control, formState, handleSubmit } = useForm({
        mode: 'all',
        defaultValues: {
            line1: customer[addressType]?.line1 || '',
            line2: customer[addressType]?.line2 || '',
            city: customer[addressType]?.city || '',
            state: customer[addressType]?.state || '',
            country: customer[addressType]?.country || '',
            postcode: customer[addressType]?.postcode || '',
        },
    })

    const handleUpdateButtonClick = async (data, _) => {
        setIsSaving(true)
        const updatedCustomer = {
            ...customer,
            [addressType]: data,
        }

        if (useOneAddress && addressType === 'deliveryAddress') {
            updatedCustomer['billingAddress'] = data
        }
        try {
            updatedCustomer.useOneAddress = useOneAddress

            await updateCustomer({ organisationId, customer: updatedCustomer }).unwrap()
            dispatch(setSelectedCustomerId(updatedCustomer.customerId))

            onCancel()

            enqueueSnackbar(t('Address updated successfully'), {
                variant: 'success',
            })
        } catch (error) {
            enqueueSnackbar(t('Failed to update address'), {
                variant: 'error',
            })
        } finally {
            setIsSaving(false)
        }
    }

    const getTitle = () => {
        return t(addressType === 'deliveryAddress' ? 'Edit Delivery Address' : 'Edit Billing Address')
    }

    return (
        <>
            <Typography sx={classes.title}>{getTitle()}</Typography>
            <Controller
                control={control}
                name="line1"
                render={({ field: { onBlur, onChange, value }, fieldState }) => (
                    <TextField
                        error={fieldState.invalid}
                        helperText={fieldState.error?.message || ''}
                        label={t('Address line 1')}
                        sx={classes.input}
                        value={value}
                        fullWidth
                        required
                        onBlur={onBlur}
                        onChange={onChange}
                    />
                )}
                rules={{ required: 'Required' }}
            />

            <Controller
                control={control}
                name="line2"
                render={({ field: { onBlur, onChange, value }, fieldState }) => (
                    <TextField
                        error={fieldState.invalid}
                        helperText={fieldState.error?.message || ''}
                        label={t('Address line 2')}
                        sx={classes.input}
                        value={value}
                        fullWidth
                        onBlur={onBlur}
                        onChange={onChange}
                    />
                )}
            />

            <Controller
                control={control}
                name="city"
                render={({ field: { onBlur, onChange, value }, fieldState }) => (
                    <TextField
                        error={fieldState.invalid}
                        helperText={fieldState.error?.message || ''}
                        label={t('City')}
                        sx={classes.input}
                        value={value}
                        fullWidth
                        required
                        onBlur={onBlur}
                        onChange={onChange}
                    />
                )}
                rules={{ required: 'Required' }}
            />

            <Controller
                control={control}
                name="state"
                render={({ field: { onBlur, onChange, value }, fieldState }) => (
                    <TextField
                        error={fieldState.invalid}
                        helperText={fieldState.error?.message || ''}
                        label={t('State')}
                        sx={classes.input}
                        value={value}
                        fullWidth
                        onBlur={onBlur}
                        onChange={onChange}
                    />
                )}
            />

            <Controller
                control={control}
                name="postcode"
                render={({ field: { onBlur, onChange, value }, fieldState }) => (
                    <TextField
                        error={fieldState.invalid}
                        helperText={fieldState.error?.message || ''}
                        label={t('Post Code')}
                        sx={classes.input}
                        value={value}
                        fullWidth
                        onBlur={onBlur}
                        onChange={onChange}
                    />
                )}
            />

            <Controller
                control={control}
                name="country"
                render={({ field: { onBlur, onChange, value }, fieldState }) => (
                    <TextField
                        error={fieldState.invalid}
                        helperText={fieldState.error?.message || ''}
                        label={t('Country')}
                        sx={classes.input}
                        value={value}
                        fullWidth
                        required
                        onBlur={onBlur}
                        onChange={onChange}
                    />
                )}
                rules={{ required: 'Required' }}
            />
            <Box sx={classes.buttonsContainer}>
                <Button
                    color="primary"
                    disabled={isSaving}
                    key="cancel"
                    sx={classes.button}
                    variant="outlined"
                    onClick={onCancel}
                >
                    {t('Cancel')}
                </Button>
                <Button
                    color="primary"
                    disabled={!formState.isValid || isSaving}
                    key="update"
                    sx={classes.button}
                    variant="contained"
                    onClick={handleSubmit(handleUpdateButtonClick)}
                >
                    {isSaving ? t('Saving address...') : t('Update')}
                </Button>
            </Box>
        </>
    )
}

AddressEditForm.propTypes = {
    customer: PropTypes.object.isRequired,
    addressType: PropTypes.oneOf(['deliveryAddress', 'billingAddress']),
    useOneAddress: PropTypes.bool,
    onCancel: PropTypes.func,
}

export default AddressEditForm
