import { useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useDispatch } from 'react-redux'
import { useParams } from 'react-router-dom'
import { Delete } from '@mui/icons-material'
import { Box, Button, CircularProgress, Dialog, DialogContent, IconButton, Typography } from '@mui/material'
import { alpha } from '@mui/material/styles'
import { useSnackbar } from 'notistack'
import PropTypes from 'prop-types'

import { useAddQuoteItemsFromFilesMutation } from '@/app/services/web-store/webStoreQuoteItems'
import { setExpandedItemsIds } from '@/app/slices/web-store/webStoreQuoteItemsSlice'
import TbxShadowScroll from '@/common/components/TbxShadowScroll'

import FileRequirementsList from '../shared/FileRequirementsList'
import QuoteItemDropzone from '../shared/QuoteItemDropzone'

const classes = {
    dialogRoot: {
        borderRadius: 1,
    },
    dialogContent: {
        display: 'flex',
        flexDirection: 'row',
        gap: '8px',
        padding: 3,
    },
    dropzoneContainer: {
        width: '320px',
        flex: '1 0 320px',
        display: 'flex',
        flexDirection: 'row',
        gap: 1,
    },
    fileRequirementsContainer: {
        width: '350px',
        height: '360px',
        flex: '1 0 350px',
    },
    fileListContainer: {
        width: '350px',
        height: '360px',
        overflow: 'hidden',
        flex: '1 0 350px',
        paddingLeft: 2,
    },
    fileListWrapper: {
        marginBottom: 1.5,
    },
    filesToUpload: {
        display: 'flex',
        flexDirection: 'column',
        gap: 1,
        marginBottom: 1.5,
    },
    fileItem: {
        px: 1,
        py: 0.5,
        gap: 1,
        backgroundColor: (theme) => alpha(theme.palette.primary.main, 0.05),
        borderRadius: 0.5,
    },
    smallText: {
        fontSize: '0.75rem',
    },
}

const AddQuoteItemsDialog = ({ onClose, open }) => {
    const dispatch = useDispatch()
    const { t } = useTranslation()

    const { organisationId, quoteId } = useParams()
    const { enqueueSnackbar } = useSnackbar()
    const [uploadQuoteItems, { isLoading: isUploading }] = useAddQuoteItemsFromFilesMutation()
    const [filesToUpload, setFilesToUpload] = useState([])

    const handleAcceptedFiles = (acceptedFiles) => {
        setFilesToUpload((prevFiles) => [...prevFiles, ...acceptedFiles])
    }

    const handleFileRemoval = (fileToRemove) => {
        setFilesToUpload((files) => files.filter((file) => file.name !== fileToRemove))
    }

    const handleClose = () => {
        onClose()
        setFilesToUpload([])
    }

    const handleFilesUpload = async () => {
        if (filesToUpload.length) {
            try {
                const formData = new FormData()
                filesToUpload.forEach((el) => formData.append('files', el, el.name))
                const uploadResponse = await uploadQuoteItems({
                    organisationId,
                    quoteId,
                    drawingFiles: formData,
                }).unwrap()
                const quoteItemsIds = uploadResponse.quoteItems.map((item) => item.id)
                dispatch(setExpandedItemsIds(quoteItemsIds))
                handleClose()
                enqueueSnackbar(`${filesToUpload.length} file(s) ${t('added to $t(quote)')}`, { variant: 'success' })
            } catch (e) {
                let fileUploadErrorMessage = t('Failed to upload $t(quote) items')
                if (e.response?.status === 408 || e.response?.status === 504) {
                    fileUploadErrorMessage = t(
                        'The upload timed out. Please try again. If the problem persists, please contact our support team.'
                    )
                }
                enqueueSnackbar(t(fileUploadErrorMessage), {
                    variant: 'error',
                })
            }
        }
    }

    return (
        <Dialog
            maxWidth="md"
            open={open}
            sx={classes.dialogRoot}
            onClose={handleClose}
        >
            <DialogContent sx={classes.dialogContent}>
                <Box sx={classes.dropzoneContainer}>
                    <QuoteItemDropzone
                        isUploading={isUploading}
                        onFilesAccepted={handleAcceptedFiles}
                    />
                </Box>

                {!filesToUpload.length ? (
                    <Box sx={classes.fileRequirementsContainer}>
                        <FileRequirementsList />
                    </Box>
                ) : null}

                {filesToUpload.length ? (
                    <Box
                        display="flex"
                        flexDirection="column"
                        justifyContent="space-between"
                        sx={classes.fileListContainer}
                    >
                        <TbxShadowScroll
                            scrollContainerSx={classes.filesToUpload}
                            wrapperSx={classes.fileListWrapper}
                        >
                            {filesToUpload.map((file) => (
                                <Box
                                    alignItems="center"
                                    display="flex"
                                    flexDirection="row"
                                    justifyContent="space-between"
                                    key={file.name}
                                    sx={classes.fileItem}
                                >
                                    <Typography variant="body2">{file.name}</Typography>
                                    <IconButton
                                        color="secondary"
                                        size="small"
                                        onClick={() => handleFileRemoval(file.name)}
                                    >
                                        <Delete fontSize="small" />
                                    </IconButton>
                                </Box>
                            ))}
                        </TbxShadowScroll>

                        <Button
                            color="secondary"
                            disabled={isUploading}
                            startIcon={isUploading ? <CircularProgress size={20} /> : null}
                            variant="contained"
                            onClick={handleFilesUpload}
                        >
                            {isUploading ? t('Uploading files...') : t('Upload files')}
                        </Button>
                    </Box>
                ) : null}
            </DialogContent>
        </Dialog>
    )
}

AddQuoteItemsDialog.propTypes = {
    open: PropTypes.bool,
    onClose: PropTypes.func,
}

export default AddQuoteItemsDialog
