import { Controller, useForm } from 'react-hook-form'
import { useTranslation } from 'react-i18next'
import { Button, FormControlLabel, Radio, RadioGroup, Typography } from '@mui/material'
import PropTypes from 'prop-types'

import { Dialog } from '@/common/components'

const ChangeMaterialDensityDialog = ({ handleConfirmButtonClick, material, onCancelClose, showDialog }) => {
    const { t } = useTranslation()

    const mode = material?.type?.toLowerCase()
    const sheetLabel = mode === 'rotary' ? 'record' : 'sheet'
    const sheetLabelForAttributes = mode === 'rotary' ? 'Unit' : 'Sheet'

    const {
        control,
        formState: { isDirty, isValid },
        getValues,
        handleSubmit,
        reset,
        setValue,
    } = useForm({
        mode: 'all',
        defaultValues: {
            'recalculation-mode': null,
        },
    })

    return (
        <Dialog
            actions={[
                <Button
                    color="primary"
                    disabled={!getValues()['recalculation-mode']}
                    key="add"
                    variant="contained"
                    onClick={handleSubmit(() => handleConfirmButtonClick(getValues()))}
                >
                    {t('Confirm')}
                </Button>,
                <Button
                    color="primary"
                    key="cancel"
                    variant="outlined"
                    onClick={onCancelClose}
                >
                    {t('Cancel')}
                </Button>,
            ]}
            className={{ width: 680 }}
            open={showDialog}
            title={t('Warning')}
            onClose={onCancelClose}
        >
            <Typography>{t('Editing a material’s density will cause a recalculation of material costs')}</Typography>
            <Controller
                control={control}
                name="recalculation-mode"
                render={({ field: { onChange, value } }) => (
                    <RadioGroup
                        value={value}
                        onChange={(e, value) => {
                            onChange(value)
                        }}
                    >
                        <FormControlLabel
                            control={<Radio />}
                            label={t(
                                `Keep ${sheetLabelForAttributes.toLowerCase()} costs and recalculate ${sheetLabelForAttributes.toLowerCase()} rates`
                            )}
                            value="keepCosts"
                        />
                        <FormControlLabel
                            control={<Radio />}
                            label={t(
                                `Keep ${sheetLabelForAttributes.toLowerCase()} rates and recalculate ${sheetLabelForAttributes.toLowerCase()} costs`
                            )}
                            value="keepRates"
                        />
                    </RadioGroup>
                )}
            />
        </Dialog>
    )
}

ChangeMaterialDensityDialog.propTypes = {
    handleConfirmButtonClick: PropTypes.func,
    material: PropTypes.object,
    showDialog: PropTypes.bool,
    onCancelClose: PropTypes.func,
}

export default ChangeMaterialDensityDialog
