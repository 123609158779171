import MuiFormHelperText from '@mui/material/FormHelperText'
import PropTypes from 'prop-types'

const InputHelperText = ({
    children,
    className,
    classes,
    disabled,
    error,
    filled,
    focused,
    margin,
    required,
    variant,
}) => {
    return (
        <MuiFormHelperText
            classes={classes}
            className={className}
            disabled={disabled}
            error={error}
            filled={filled}
            focused={focused}
            margin={margin}
            required={required}
            variant={variant}
        >
            {children}
        </MuiFormHelperText>
    )
}

InputHelperText.propTypes = {
    children: PropTypes.node,
    classes: PropTypes.object,
    className: PropTypes.string,
    disabled: PropTypes.bool,
    error: PropTypes.bool,
    filled: PropTypes.bool,
    focused: PropTypes.bool,
    margin: PropTypes.oneOf(['dense']),
    required: PropTypes.bool,
    variant: PropTypes.oneOf(['filled', 'outlined', 'standard']),
}

export default InputHelperText
