import { Box } from '@mui/material'
import PropTypes from 'prop-types'

import { EXPORTABLE_CONTENT, PUBLIC_DOCUMENTS } from '@/common/utils'

import OrderConfirmation from './Quote/OrderConfirmation'
import ProformaInvoice from './Quote/ProformaInvoice'
import QuoteOrder from './Quote/QuoteOrder'
import TaxInvoice from './Quote/TaxInvoice'

const PublicDocuments = ({
    customer,
    customerContact,
    documentType,
    issueSeverityDictionary,
    organisation,
    paymentsEnabled = false,
    quote,
    showshipping,
    taxRate,
}) => {
    return (
        <Box id={EXPORTABLE_CONTENT}>
            {documentType === PUBLIC_DOCUMENTS.Quote ? (
                <QuoteOrder
                    customer={customer}
                    customerContact={customerContact}
                    issueSeverityDictionary={issueSeverityDictionary}
                    organisation={organisation}
                    paymentsEnabled={paymentsEnabled}
                    quote={quote}
                    showShipping={showshipping}
                    taxRate={taxRate}
                />
            ) : null}

            {documentType === PUBLIC_DOCUMENTS.ProformaInvoice ? (
                <ProformaInvoice
                    customer={customer}
                    customerContact={customerContact}
                    issueSeverityDictionary={issueSeverityDictionary}
                    organisation={organisation}
                    paymentsEnabled={paymentsEnabled}
                    quote={quote}
                    showShipping={showshipping}
                    taxRate={taxRate}
                />
            ) : null}

            {documentType === PUBLIC_DOCUMENTS.TaxInvoice ? (
                <TaxInvoice
                    customer={customer}
                    customerContact={customerContact}
                    issueSeverityDictionary={issueSeverityDictionary}
                    organisation={organisation}
                    paymentsEnabled={paymentsEnabled}
                    quote={quote}
                    showShipping={showshipping}
                    taxRate={taxRate}
                />
            ) : null}

            {documentType === PUBLIC_DOCUMENTS.OrderConfirmation ? (
                <OrderConfirmation
                    customer={customer}
                    customerContact={customerContact}
                    issueSeverityDictionary={issueSeverityDictionary}
                    organisation={organisation}
                    paymentsEnabled={paymentsEnabled}
                    quote={quote}
                    taxRate={taxRate}
                />
            ) : null}
        </Box>
    )
}

PublicDocuments.propTypes = {
    customer: PropTypes.object,
    customerContact: PropTypes.object,
    documentType: PropTypes.string,
    issueSeverityDictionary: PropTypes.object,
    organisation: PropTypes.object,
    paymentsEnabled: PropTypes.bool,
    quote: PropTypes.object,
    showshipping: PropTypes.bool,
    taxRate: PropTypes.object
}

export default PublicDocuments
