import { useEffect, useRef } from 'react'
import Box from '@mui/material/Box'
import PropTypes from 'prop-types'

const CadView = ({ cadSvg, setRef, viewBoxHeight, viewBoxWidth, viewBoxX, viewBoxY }) => {
    const classes = {
        root: {
            width: '100%',
            height: '100%',
            strokeLinecap: 'round',
            strokeLinejoin: 'round',
            display: 'flex',
        },
        cadBackground: {
            backgroundColor: 'black',
        },
    }

    const cadSvgRef = useRef()

    useEffect(() => {
        const svgContainerNode = document.getElementById('cadSvg')
        if (svgContainerNode) {
            const svgNode = findSvgElement(svgContainerNode.childNodes)

            if (svgNode) {
                svgNode.replaceWith(...svgNode.childNodes)
                setRef(cadSvgRef)
            }
        }
    }, [cadSvg])

    const findSvgElement = (nodes) => {
        const nodeArray = Array.prototype.slice.call(nodes)
        return nodeArray.find((element) => element.nodeName === 'svg')
    }

    return cadSvg ? (
        <Box
            component="svg"
            dangerouslySetInnerHTML={cadSvg ? { __html: cadSvg } : undefined}
            id="cadSvg"
            ref={cadSvgRef}
            sx={{ ...classes.root, ...classes.cadBackground }}
            viewBox={`${viewBoxX} ${viewBoxY} ${viewBoxWidth} ${viewBoxHeight}`}
        ></Box>
    ) : null
}

CadView.propTypes = {
    cadSvg: PropTypes.string,
    setRef: PropTypes.func,
    viewBox: PropTypes.object,
    viewBoxHeight: PropTypes.number,
    viewBoxWidth: PropTypes.number,
    viewBoxX: PropTypes.number,
    viewBoxY: PropTypes.number,
}

export default CadView
