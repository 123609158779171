import { useSelector } from 'react-redux'

import { selectOrganisation } from '@/app/slices/organisationSlice'
import { useToolBoxTreatments } from '@/common/hooks'
import { LIBELLULA_PRICING_REDIRECT_URL, TOOLBOX_PRICING_REDIRECT_URL } from '@/common/utils'

export const RedirectToPricingRequest = () => {
    const { showLibellulaWhitelabelling } = useToolBoxTreatments()
    const organisation = useSelector(selectOrganisation)

    window.location.href = showLibellulaWhitelabelling
        ? LIBELLULA_PRICING_REDIRECT_URL
        : `${TOOLBOX_PRICING_REDIRECT_URL}${organisation?.paymentGatewayCustomerId}`
    return null
}
