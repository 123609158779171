import { NumericFormat } from 'react-number-format'
import { useSelector } from 'react-redux'
import TextField from '@mui/material/TextField'
import PropTypes from 'prop-types'

import { selectLocale } from '@/app/slices/appSlice'
import { selectCurrencyCode } from '@/app/slices/organisationSlice'
import { getCurrencyFormat, getFormattedPrice } from '@/common/utils'

const CurrencyInput = ({
    InputLabelProps,
    allowNegativeValues = false,
    customPlaceholder,
    disabled = false,
    error,
    helperText,
    inputProps,
    label = 'Currency Input',
    onBlur,
    onChange,
    required = false,
    size = 'small',
    value,
}) => {
    const currencyCode = useSelector(selectCurrencyCode)
    const locale = useSelector(selectLocale)
    const placeholder =
        typeof customPlaceholder === 'number'
            ? getFormattedPrice(customPlaceholder, currencyCode, locale)
            : customPlaceholder

    const handleOnChange = (values) => {
        if (typeof onChange === 'function') {
            const { floatValue } = values
            onChange(floatValue)
        }
    }

    const currencyFormat = getCurrencyFormat(currencyCode, locale)
    const literal = currencyFormat['literal'] ?? ''
    const currencySymbol =
        currencyFormat['currencySymbolPosition'] === 'before'
            ? currencyFormat['currency'] + literal
            : literal + currencyFormat['currency']
    const prefix = currencyFormat['currencySymbolPosition'] === 'before' ? currencySymbol : undefined
    const suffix = currencyFormat['currencySymbolPosition'] === 'after' ? currencySymbol : undefined
    const decimalPlaces = currencyFormat['decimalPlaces']
    const groupSeparator = currencyFormat['group']
    const decimalSeparator = groupSeparator === '.' ? ',' : '.'

    return (
        <NumericFormat
            allowNegative={allowNegativeValues}
            customInput={TextField}
            decimalScale={decimalPlaces}
            decimalSeparator={decimalSeparator}
            disabled={disabled}
            error={error}
            fixedDecimalScale={true}
            helperText={helperText}
            InputLabelProps={
                InputLabelProps || {
                    'data-testid': 'organisation-currency-label',
                }
            }
            inputProps={
                inputProps || {
                    'data-testid': 'organisation-currency-input',
                }
            }
            label={label}
            placeholder={placeholder}
            prefix={prefix}
            required={required}
            size={size}
            suffix={suffix}
            thousandSeparator={groupSeparator}
            value={value ?? null}
            fullWidth
            onBlur={onBlur}
            onValueChange={handleOnChange}
        />
    )
}

export const currencyInputPropTypes = {
    allowNegativeValues: PropTypes.bool,
    customPlaceholder: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
    disabled: PropTypes.bool,
    error: PropTypes.bool,
    helperText: PropTypes.string,
    InputLabelProps: PropTypes.object,
    inputProps: PropTypes.object,
    label: PropTypes.string,
    required: PropTypes.bool,
    size: PropTypes.oneOf(['small', 'medium']),
    value: PropTypes.number,
    onBlur: PropTypes.func,
    onChange: PropTypes.func,
    onErrorChange: PropTypes.func,
}

CurrencyInput.propTypes = currencyInputPropTypes

export default CurrencyInput
