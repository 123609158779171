import _ from 'lodash'
import Snap from 'snapsvg-cjs'

import CustomError from '../Utilities/CustomError'

import Layer from './Layer'

export default class Text {
    constructor(json) {
        const jsonValid =
            json &&
            Object.prototype.hasOwnProperty.call(json, 'layer') &&
            Object.prototype.hasOwnProperty.call(json, 'isLineTypeSolid') &&
            Object.prototype.hasOwnProperty.call(json, 'type') &&
            Object.prototype.hasOwnProperty.call(json, 'bottomLeft') &&
            Object.prototype.hasOwnProperty.call(json, 'displayText')
        if (jsonValid) {
            _.extend(this, json)
            this.layer = new Layer(json.layer)
            this.svg = null
        } else {
            throw new CustomError('Invalid json supplied', 'InvalidJSONError')
        }
    }

    toSvg(paper) {
        const mat = new Snap.Matrix()
        mat.scale(1, -1, this.bottomLeft.x, this.bottomLeft.y)
        const textArr = this.displayText.split('\\P')
        this.svg = paper.text(this.bottomLeft.x, this.bottomLeft.y, textArr)
        this.svg.selectAll('tspan:nth-child(n+2)').attr({
            dy: '1.2em',
            x: this.bottomLeft.x,
        })
        this.svg.transform(mat.toTransformString())
    }
}

Text.ToolpathType = 'DtoText'
