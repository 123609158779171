import { useTranslation } from 'react-i18next'
import { useSortable } from '@dnd-kit/sortable'
import { CSS } from '@dnd-kit/utilities'
import { Archive, DragHandle, EditRounded, Unarchive } from '@mui/icons-material'
import { Box, IconButton, TableCell, TableRow, Typography } from '@mui/material'
import PropTypes from 'prop-types'

import { TbxTooltip } from '@/common/components'

const classes = {
    tableRow: {
        '&:hover': {
            background: (theme) => theme.palette.action.hover,
        },
    },
    tableCell: {
        paddingBlock: 2,
        paddingInline: 2,
    },
    tableCellWithEllipsis: {
        overflow: 'hidden',
        whiteSpace: 'nowrap',
        textOverflow: 'ellipsis',
    },

    iconColor: (active = true) => ({
        color: active ? 'secondary.main' : 'text.disabled',
    }),
    activeZone: (active = true) => ({
        color: active ? 'text.primary' : 'text.disabled',
    }),
}

const DeliveryZonesRow = ({
    handleArchiveButtonClick,
    handleEditButtonClick,
    handleUnarchiveButtonClick,
    id,
    zone,
}) => {
    const { t } = useTranslation()

    const { attributes, isDragging, listeners, setActivatorNodeRef, setNodeRef, transform, transition } = useSortable({
        id: id,
    })

    const style = {
        transform: CSS.Transform.toString(transform),
        transition,
        opacity: isDragging ? 0.5 : undefined,
        zIndex: isDragging ? 0 : undefined,
    }

    return (
        <TableRow
            key={zone.zoneId}
            ref={setNodeRef}
            style={style}
            sx={classes.tableRow}
            {...attributes}
        >
            <TableCell sx={[classes.tableCell, classes.tableCellWithEllipsis, classes.activeZone(!zone.isDeleted)]}>
                <Box
                    alignItems="center"
                    display="flex"
                    gap={1.5}
                >
                    <IconButton
                        {...listeners}
                        ref={setActivatorNodeRef}
                        sx={{ cursor: 'grab' }}
                    >
                        <DragHandle />
                    </IconButton>

                    {zone.name}
                </Box>
            </TableCell>

            <TableCell sx={[classes.tableCell, classes.activeZone(!zone.isDeleted)]}>
                {zone.zoneRegions
                    ?.filter((region) => !region.isDeleted)
                    ?.slice(0, 3)
                    ?.map((zoneRegion) => (
                        <Typography
                            key={zoneRegion.id}
                            variant="body2"
                        >
                            {zoneRegion.region}
                        </Typography>
                    ))}
                {zone.zoneRegions?.filter((region) => !region.isDeleted)?.length > 3 ? (
                    <Typography
                        color="text.secondary"
                        variant="body2"
                    >
                        {t('and')} {zone.zoneRegions.filter((region) => !region.isDeleted).length - 3}{' '}
                        {t('more regions')}
                    </Typography>
                ) : null}
            </TableCell>

            <TableCell
                align="right"
                sx={classes.tableCell}
            >
                <Box
                    display="flex"
                    justifyContent="flex-end"
                >
                    <IconButton
                        disabled={zone.isDeleted}
                        size="small"
                        onClick={() => {
                            handleEditButtonClick(zone)
                        }}
                    >
                        <EditRounded
                            fontSize="small"
                            sx={classes.iconColor(!zone.isDeleted)}
                        />
                    </IconButton>

                    <TbxTooltip
                        title={!zone.isDeleted ? t('Archive delivery zone') : t('Unarchive delivery zone')}
                        arrow
                    >
                        {!zone.isDeleted ? (
                            <span>
                                <IconButton
                                    aria-label="archive"
                                    data-testid="archive-button"
                                    size="small"
                                    sx={classes.iconColor()}
                                    onClick={() => {
                                        handleArchiveButtonClick(zone)
                                    }}
                                >
                                    <Archive fontSize="small" />
                                </IconButton>
                            </span>
                        ) : (
                            <span>
                                <IconButton
                                    aria-label="unarchive"
                                    data-testid="unarchive-button"
                                    size="small"
                                    sx={classes.iconColor()}
                                    onClick={() => {
                                        handleUnarchiveButtonClick(zone)
                                    }}
                                >
                                    <Unarchive fontSize="small" />
                                </IconButton>
                            </span>
                        )}
                    </TbxTooltip>
                </Box>
            </TableCell>
        </TableRow>
    )
}

DeliveryZonesRow.propTypes = {
    handleArchiveButtonClick: PropTypes.func.isRequired,
    handleEditButtonClick: PropTypes.func.isRequired,
    handleUnarchiveButtonClick: PropTypes.func.isRequired,
    id: PropTypes.string.isRequired,
    zone: PropTypes.object.isRequired,
    index: PropTypes.number,
}

export default DeliveryZonesRow
