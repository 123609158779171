import { useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useSelector } from 'react-redux'
import { useParams } from 'react-router-dom'
import { ChromeReaderMode, ChromeReaderModeOutlined } from '@mui/icons-material'
import { Button } from '@mui/material'

import { selectQuote } from '@/app/services/quotes'
import { selectOrganisationId, selectPaidFeatures } from '@/app/slices/organisationSlice'

import QuoteNotesModal from './QuoteNotesModal'

const QuoteNotes = () => {
    const { t } = useTranslation()
    const { quoteId } = useParams()

    const organisationId = useSelector(selectOrganisationId)
    const selectedQuote = useSelector((state) => selectQuote(state, { organisationId, quoteId }))
    const paidFeatures = useSelector(selectPaidFeatures)

    const [showQuoteNotesDialog, setShowQuoteNotesDialog] = useState(false)

    const quoteHasNotes = selectedQuote?.vendorNotes || selectedQuote?.privateNotes

    const disableQuoteNotesButton = paidFeatures?.isFreePlan

    const handleQuoteNotesButtonClick = () => {
        setShowQuoteNotesDialog(true)
    }

    const handleQuoteNotesDialogClose = () => {
        setShowQuoteNotesDialog(false)
    }

    return (
        <>
            <Button
                color="secondary"
                data-testid="quote-notes-button"
                disabled={disableQuoteNotesButton}
                size="small"
                startIcon={quoteHasNotes ? <ChromeReaderMode /> : <ChromeReaderModeOutlined />}
                variant="outlined"
                onClick={handleQuoteNotesButtonClick}
            >
                {t('Quote notes')}
            </Button>

            <QuoteNotesModal
                open={showQuoteNotesDialog}
                quote={selectedQuote}
                onClose={handleQuoteNotesDialogClose}
            />
        </>
    )
}

export default QuoteNotes
