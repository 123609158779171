import { createSlice } from '@reduxjs/toolkit'

const initialState = {
    selectedCuttingTechId: null,
}

export const cuttingTechnologiesSlice = createSlice({
    name: 'cuttingTechnologiesSlice',
    initialState,
    reducers: {
        setSelectedCuttingTechId: (state, action) => {
            state.selectedCuttingTechId = action.payload
        },
    },
})

export const selectSelectedCuttingTechId = (state) => state.cuttingTechnologiesSlice.selectedCuttingTechId

export const { setSelectedCuttingTechId } = cuttingTechnologiesSlice.actions

export default cuttingTechnologiesSlice.reducer
