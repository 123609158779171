import { useTranslation } from 'react-i18next'
import { Box, CircularProgress, Typography } from '@mui/material'
import PropTypes from 'prop-types'

const classes = {
    wrapper: {
        position: 'relative',
        width: '100%',
        height: '100%',
    },
    transparentOverlay: {
        position: 'absolute',
        top: 0,
        left: 0,
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        zIndex: (theme) => theme.zIndex.appBar - 1,
        width: '100%',
        height: '100%',
    },
    loadingComponent: {
        width: '100%',
        height: '100%',
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        justifyContent: 'center',
    },
    loadingText: {
        marginTop: 2,
    },
}

const TbxLoadingOverlay = ({
    labelSize = 'body1',
    labelText = 'Loading...',
    progressColor = 'primary',
    showLoadingLabel = true,
}) => {
    const { t } = useTranslation()

    const loadingLabelText = labelText || `${t('Loading')}...`

    return (
        <Box sx={classes.wrapper}>
            <Box sx={classes.transparentOverlay}>
                <Box sx={classes.loadingComponent}>
                    <CircularProgress
                        color={progressColor}
                        size={36}
                    />
                    {showLoadingLabel ? (
                        <Typography
                            sx={classes.loadingText}
                            variant={labelSize}
                        >
                            {loadingLabelText}
                        </Typography>
                    ) : null}
                </Box>
            </Box>
        </Box>
    )
}

TbxLoadingOverlay.propTypes = {
    labelSize: PropTypes.string,
    labelText: PropTypes.string,
    progressColor: PropTypes.string,
    showLoadingLabel: PropTypes.bool,
}

export default TbxLoadingOverlay
