import { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { Box, Button, Container, Typography } from '@mui/material'
import { useSnackbar } from 'notistack'
import PropTypes from 'prop-types'

import { useGetQuickPartsQuery, useLazyGetQuickPartsDrawingDtoQuery } from '@/app/services/quickPartsApi'
import { PageSpinner } from '@/common/components'

import PartsList from './PartsList/PartsList'
import PartSpecificationPanel from './PartSpecificationPanel/PartSpecificationPanel'
import PartViewer from './PartViewer/PartViewer'

const classes = {
    container: {
        display: 'flex',
        flexDirection: 'column',
        height: '100%',
        backgroundColor: 'background.paper',
        border: '1px solid hsl(0, 0%, 15%)',
        boxSizing: 'border-box',
        overflow: 'hidden',
    },
    topBar: {
        borderBottom: '1px solid hsl(0, 0%, 20%)',
        boxSizing: 'border-box',
        height: 64,
        width: '100%',
        padding: 3,
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'space-between',
        backgroundColor: 'hsl(0, 0%, 15%)',
    },
    body: {
        display: 'flex',
        height: '100%',
    },
    title: {
        textTransform: 'uppercase',
        color: 'text.primary',
    },
    buttonContainer: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'space-between',
        width: 210,
    },
    button: {
        width: 100,
    },
    partsList: {
        width: '400px',
        overflow: 'auto',
        pb: 8,
        backgroundColor: 'hsl(0, 0%, 15%)',
    },
    partsListNQP: {
        width: '400px',
        overflowY: 'auto',
        height: '100%',
        backgroundColor: 'hsl(0, 0%, 15%)',
        flex: '0 0 auto',
    },
    selectedPartSpecification: {
        height: '100%',
        width: '400px',
        backgroundColor: 'hsl(0, 0%, 25%)',
        flex: '0 0 auto',
    },
    selectedPart: {
        flexGrow: 1,
    },
    selectedPartNQP: {
        flexGrow: 1,
    },
    centeredContentContainer: {
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        justifyContent: 'center',
        width: '100%',
    },
    errorText: {
        color: '#FFFFFF',
    },
}

const QuickPart = ({ drawingUnits, locale, nqp, onCancelClicked, onSubmitClicked }) => {
    const { t } = useTranslation()
    const { enqueueSnackbar } = useSnackbar()

    const { data: parts, isError, isLoading } = useGetQuickPartsQuery({ drawingUnits, locale })
    const [getDrawingDto] = useLazyGetQuickPartsDrawingDtoQuery()

    const [partName, setPartName] = useState('QuickPart')
    const [selectedDimension, setSelectedDimension] = useState(null)
    const [selectedPart, setSelectedPart] = useState(null)

    const dimensions = selectedPart ? selectedPart.parameters : null

    const handlePartSelected = (partId) => {
        const partIndex = parts.findIndex((p) => p.id === partId)
        if (partIndex > -1) {
            const newlySelectedPart = {
                ...parts[partIndex],
                drawingUnits,
            }

            setSelectedPart(newlySelectedPart)
            setSelectedDimension(null)
            setPartName('Quickpart - ' + t(newlySelectedPart.description))
        }
    }

    const handleDimensionSelected = (dimension) => {
        setSelectedDimension(dimension)
    }

    const handleDimensionChanged = (dimension, value) => {
        const updatedPart = { ...structuredClone(selectedPart) }
        const parameterIndex = updatedPart.parameters.findIndex((p) => p.name === dimension.name)

        if (parameterIndex > -1) {
            updatedPart.parameters[parameterIndex].value = value
        }

        setSelectedPart(updatedPart)
    }

    const handlePartNameChanged = (newPartName) => {
        setPartName(newPartName)
    }

    const handleSubmitButtonClicked = async () => {
        if (typeof onSubmitClicked !== 'function') {
            return
        }

        try {
            const drawing = await getDrawingDto({ name: partName, part: selectedPart }).unwrap()
            onSubmitClicked(drawing)
            enqueueSnackbar(t('QuickPart submitted successfully'), { variant: 'success' })
        } catch (error) {
            console.error(error)
            enqueueSnackbar(t('$t(An error occurred) submitting your part'), { variant: 'error' })
        }
    }

    useEffect(() => {
        if (parts && parts.length) {
            const firstPart = parts[0]
            setSelectedPart(firstPart)
            setPartName('Quickpart - ' + t(firstPart.description))
        }
    }, [parts])

    return isLoading ? (
        <PageSpinner />
    ) : (
        <Container
            disableGutters={true}
            maxWidth={false}
            sx={classes.container}
        >
            <Box sx={classes.topBar}>
                <Typography
                    sx={classes.title}
                    variant="h5"
                >
                    {t('Add QuickPart')}
                </Typography>
                <Box sx={classes.buttonContainer}>
                    <Button
                        color="secondary"
                        sx={classes.button}
                        variant="contained"
                        disableElevation
                        onClick={onCancelClicked}
                    >
                        {t('Cancel')}
                    </Button>
                    <Button
                        color="primary"
                        sx={classes.button}
                        variant="contained"
                        disableElevation
                        onClick={handleSubmitButtonClicked}
                    >
                        {t('Save')}
                    </Button>
                </Box>
            </Box>
            <Box sx={classes.body}>
                {isError ? (
                    <Box sx={classes.centeredContentContainer}>
                        <Typography
                            sx={classes.errorText}
                            variant="h3"
                        >
                            {t('$t(An error occurred) loading QuickPart')}
                        </Typography>
                    </Box>
                ) : (
                    <>
                        <Box sx={nqp ? classes.partsListNQP : classes.partsList}>
                            <PartsList
                                nqp={nqp}
                                parts={parts}
                                selectedPart={selectedPart}
                                onPartSelected={handlePartSelected}
                            />
                        </Box>
                        <Box sx={classes.selectedPartSpecification}>
                            <PartSpecificationPanel
                                dimensions={dimensions}
                                partName={partName}
                                selectedDimension={selectedDimension}
                                onDimensionChanged={handleDimensionChanged}
                                onDimensionSelected={handleDimensionSelected}
                                onPartNameChanged={handlePartNameChanged}
                            />
                        </Box>
                        <Box sx={nqp ? classes.selectedPartNQP : classes.selectedPart}>
                            <PartViewer
                                nqp={nqp}
                                part={selectedPart}
                                selectedDimension={selectedDimension}
                                onDimensionSelected={handleDimensionSelected}
                            />
                        </Box>
                    </>
                )}
            </Box>
        </Container>
    )
}

QuickPart.propTypes = {
    drawingUnits: PropTypes.string,
    locale: PropTypes.string,
    nqp: PropTypes.bool,
    onCancelClicked: PropTypes.func,
    onSubmitClicked: PropTypes.func,
}

export default QuickPart
