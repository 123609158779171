import { forwardRef } from 'react'
import MuiMenuItem from '@mui/material/MenuItem'
import PropTypes from 'prop-types'

const SelectOption = forwardRef(function MenuItem(
    {
        children,
        className,
        classes,
        dense,
        disableGutters = false,
        disabled,
        role,
        selected,
        tabIndex,
        value,
        ...other
    },
    ref
) {
    return (
        <MuiMenuItem
            classes={classes}
            dense={dense}
            disabled={disabled}
            disableGutters={disableGutters}
            innerRef={ref}
            role={role}
            selected={selected}
            sx={className}
            tabIndex={tabIndex}
            value={value}
            {...other}
        >
            {children}
        </MuiMenuItem>
    )
})

SelectOption.propTypes = {
    children: PropTypes.node,
    classes: PropTypes.object,
    className: PropTypes.object,
    dense: PropTypes.bool,
    disabled: PropTypes.bool,
    disableGutters: PropTypes.bool,
    role: PropTypes.string,
    selected: PropTypes.bool,
    tabIndex: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
    value: PropTypes.any,
}

export default SelectOption
