import { Box } from '@mui/material'
import CircularProgress from '@mui/material/CircularProgress'

const classes = {
    progress: {
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        justifyContent: 'center',
        width: '100vw',
        height: '100vh',
        gap: 2,
    },
}

const PageSpinner = () => {
    return (
        <Box sx={classes.progress}>
            <CircularProgress color="primary" />
        </Box>
    )
}

export default PageSpinner
