import { useNavigate } from 'react-router-dom'
import { AppBar, Box, Button, Toolbar, Typography } from '@mui/material'
import PropTypes from 'prop-types'

const classes = {
    root: {
        flexGrow: 0,
    },
    toolbar: {
        backgroundColor: 'white',
        alignItems: 'center',
    },
    menuButton: {
        marginRight: 2,
    },
    title: {
        flexGrow: 1,
        color: 'primary.main',
        fontWeight: 600,
    },
    actionContainer: {
        display: 'flex',
        alignItems: 'center',
    },
    actionText: {
        color: '#FD0D1B',
        fontWeight: 600,
    },
    actionButton: {
        my: 0,
        mx: 2,
    },
}

const NotificationBar = ({
    actionButtonText,
    actionButtonUrl,
    external = false,
    hideButton,
    leftText,
    reverse = false,
    rightText,
}) => {
    const navigate = useNavigate()

    const handleActionButtonClicked = () => {
        if (external || actionButtonUrl.startsWith('http')) {
            window.open(actionButtonUrl)
        } else {
            navigate(actionButtonUrl)
        }
    }

    const getLeftText = () => {
        return (
            <Typography
                sx={classes.title}
                variant="h6"
            >
                {leftText}
            </Typography>
        )
    }

    const getRightText = () => {
        return (
            <Box sx={classes.actionContainer}>
                <Typography
                    sx={classes.actionText}
                    variant="h6"
                >
                    {rightText}
                </Typography>
                {actionButtonText && !hideButton ? <Button
                        color="primary"
                        sx={classes.actionButton}
                        variant="contained"
                        onClick={handleActionButtonClicked}
                                                   >
                        {actionButtonText}
                    </Button> : null}
            </Box>
        )
    }

    return (
        <AppBar
            elevation={4}
            position="static"
            sx={{ zIndex: 1300 }}
        >
            <Toolbar sx={classes.toolbar}>
                {reverse ? getRightText() : getLeftText()}
                {reverse ? getLeftText() : getRightText()}
            </Toolbar>
        </AppBar>
    )
}

NotificationBar.propTypes = {
    actionButtonText: PropTypes.string,
    actionButtonUrl: PropTypes.string,
    external: PropTypes.bool,
    hideButton: PropTypes.bool,
    leftText: PropTypes.node,
    reverse: PropTypes.bool,
    rightText: PropTypes.node,
}

export default NotificationBar
