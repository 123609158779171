import { SvgIcon } from '@mui/material'

const DropZoneIcon = (props) => (
    <SvgIcon
        viewBox="0 0 24 24"
        xmlns="http://www.w3.org/2000/svg"
        {...props}
    >
        <path
            d="M3.5,0.7C3.5,0.3,3.2,0,2.8,0S2.1,0.3,2.1,0.7v3.5c0,1,0.5,1.9,1.4,2.4v1.1c0,1.2,0.9,2.1,2.1,2.1h5.6v4.9
            c0,0.4,0.3,0.7,0.7,0.7s0.7-0.3,0.7-0.7V9.9h5.6c1.2,0,2.1-0.9,2.1-2.1V6.7c0.9-0.5,1.4-1.4,1.4-2.4V0.7c0-0.4-0.3-0.7-0.7-0.7
            c-0.4,0-0.7,0.3-0.7,0.7v3.5c0,0.8-0.6,1.4-1.4,1.4H4.9C4.2,5.6,3.5,5,3.5,4.2V0.7z M4.9,7.1h14.1v0.7c0,0.4-0.3,0.7-0.7,0.7H5.6
            c-0.4,0-0.7-0.3-0.7-0.7V7.1z M5.6,16.9c-0.4,0-0.7,0.3-0.7,0.7c0,0.4,0.3,0.7,0.7,0.7h2.1c0.4,0,0.7-0.3,0.7-0.7
            c0-0.4-0.3-0.7-0.7-0.7H5.6z M15.5,17.6c0-0.4,0.3-0.7,0.7-0.7h2.1c0.4,0,0.7,0.3,0.7,0.7c0,0.4-0.3,0.7-0.7,0.7h-2.1
            C15.8,18.4,15.5,18,15.5,17.6z M12,18.4c0.4,0,0.7-0.3,0.7-0.7c0-0.4-0.3-0.7-0.7-0.7s-0.7,0.3-0.7,0.7C11.3,18,11.6,18.4,12,18.4z
            M12,19.8c0.4,0,0.7,0.3,0.7,0.7v2.8c0,0.4-0.3,0.7-0.7,0.7s-0.7-0.3-0.7-0.7v-2.8C11.3,20.1,11.6,19.8,12,19.8z M10.4,19.6
            c0.3-0.3,0.3-0.7,0-1c-0.3-0.3-0.7-0.3-1,0l-2.1,2.1c-0.3,0.3-0.3,0.7,0,1c0.3,0.3,0.7,0.3,1,0L10.4,19.6z M13.6,18.6
            c-0.3,0.3-0.3,0.7,0,1c0,0,0,0,0,0l2.1,2.1c0.3,0.3,0.7,0.3,1,0c0.3-0.3,0.3-0.7,0-1l-2.1-2.1C14.3,18.3,13.9,18.3,13.6,18.6
            C13.6,18.6,13.6,18.6,13.6,18.6z"
        />
    </SvgIcon>
)

export default DropZoneIcon
