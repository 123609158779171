import { theme as LightTheme } from '../../themes/LightTheme'
import { WhitelabelThemeOverride } from '../../themes/overrides/WhitelabelThemeOverride'

export const EMPTY_GUID = '00000000-0000-0000-0000-000000000000'

export const RESELLERS = {
    libellula: {
        name: 'libellula',
        title: 'Libellula.QUOTATION',
        theme: WhitelabelThemeOverride,
        logo: 'libellula-logo.png',
        organisationUnits: 'metric',
        selectedLanguage: 'it-IT',
        organisationLocale: 'it-IT',
        organisationCurrencyCode: 'EUR',
        hasWebStoreAccess: false,
    },
    striker: {
        name: 'striker',
        title: 'Striker Quote',
        theme: WhitelabelThemeOverride,
        logo: 'striker-logo.png',
        organisationUnits: 'metric',
        selectedLanguage: 'en-US',
        organisationLocale: 'en-US',
        organisationCurrencyCode: 'USD',
        hasWebStoreAccess: false,
    },
}

export const QUOTE_SOURCE_TYPES = {
    Internal: 0,
    WebStore: 1,
}

export const SHIPPING_OPTIONS = [
    {
        value: 0,
        label: 'Pickup',
    },
    {
        value: 1,
        label: 'Delivery',
    },
]

export const PUBLIC_DOCUMENTS = {
    Quote: 'quote',
    OrderConfirmation: 'order-confirmation',
    ProformaInvoice: 'proforma',
    TaxInvoice: 'tax-invoice',
}

export const PRIVATE_DOCUMENTS = {
    WorkOrderSummary: 'work-order-summary',
    WorkOrder: 'work-order',
    DeliveryDocket: 'delivery-docket',
    ProductionLabels: 'production-labels',
}

export const PRODUCT_DETAILS = {
    name: 'toolbox',
    title: 'ToolBox',
    theme: LightTheme,
    logo: 'app-logo.svg',
    organisationUnits: 'imperial',
    selectedLanguage: 'en-US',
    organisationLocale: 'en-US',
    organisationCurrencyCode: 'USD',
    hasWebStoreAccess: true,
}

export const WEB_STORE_QUOTE_STATUS = {
    NotCalculated: 'NotCalculated',
    Calculated: 'Calculated',
    Ordered: 'Ordered',
    Invoiced: 'Invoiced',
    Dispatched: 'Dispatched',
    Lost: 'Lost',
    Cancelled: 'Cancelled',
    PendingOrderConfirmation: 'PendingOrderConfirmation',
    Rejected: 'Rejected',
    Voided: 'Voided',
    Issued: 'Issued',
}

export const QUOTE_STATUS = {
    NotCalculated: 'NotCalculated',
    Calculated: 'Calculated',
    Draft: 'Draft',
    Issued: 'Issued',
    Ordered: 'Ordered',
    Invoiced: 'Invoiced',
    Lost: 'Lost',
    Cancelled: 'Cancelled',
    PendingOrderConfirmation: 'PendingOrderConfirmation',
    Rejected: 'Rejected',
    Voided: 'Voided',
    Dispatched: 'Dispatched',
}

export const QUOTE_STATUS_ORDER = [
    {
        index: 0,
        status: 'Editing',
    },
    {
        index: 1,
        status: 'Draft',
    },
    {
        index: 2,
        status: 'Issued',
    },
    {
        index: 3,
        status: 'Pending Confirmation',
    },
    {
        index: 4,
        status: 'Ordered',
    },
    {
        index: 5,
        status: 'Invoiced',
    },
    {
        index: 6,
        status: 'Lost',
    },
    {
        index: 7,
        status: 'Rejected',
    },
    {
        index: 8,
        status: 'Cancelled',
    },
    {
        index: 9,
        status: 'Voided',
    },
]

export const QUOTE_PAGE_STATUS_LABEL = {
    NotCalculated: 'Editing',
    Calculated: 'Editing',
    Draft: 'Draft',
    Issued: 'Issued',
    PendingOrderConfirmation: 'Pending Confirmation',
    Ordered: 'Ordered',
    Invoiced: 'Invoiced',
    Lost: 'Lost',
    Cancelled: 'Cancelled',
    Voided: 'Voided',
    Rejected: 'Rejected',
    Dispatched: 'Dispatched',
}

export const QUOTE_PAYMENT_STATUS = {
    Unpaid: 'Unpaid',
    ManualPaid: 'ManualPaid',
    GatewayPaid: 'GatewayPaid',
}

export const QUOTE_PAYMENTS_STATUS_LABEL = {
    Unpaid: 'Unpaid',
    ManualPaid: 'Paid',
    GatewayPaid: 'Paid via Stripe',
}

export const GET_STATUS_LABEL = (status) => {
    return QUOTE_PAGE_STATUS_LABEL[status]
}

export const GET_PAYMENT_STATUS_LABEL = (status) => {
    if (status) {
        return QUOTE_PAYMENTS_STATUS_LABEL[status]
    }
    return QUOTE_PAYMENTS_STATUS_LABEL.Unpaid
}

export const GET_INTEGRATION_STATUS_LABEL = (integrationExportSucceeded, integrationExportFailureMessage) => {
    if (integrationExportSucceeded) {
        return 'Invoice Exported'
    }
    if (integrationExportFailureMessage) {
        return 'Invoice Export Error'
    }
    return 'Not Exported'
}

export const QUOTE_ACTIONS = {
    reviewQuote: {
        id: 'reviewQuote',
        label: 'Review quote',
        handler: 'handleQuoteStatusChange',
        param: QUOTE_STATUS.Draft,
        tagAttrs: { 'data-testid': 'review-quote-button' },
    },
    calculateQuote: {
        id: 'calculateQuote',
        label: 'Calculate',
        handler: 'handleCalculateQuote',
        tagAttrs: { 'data-testid': 'calculate-quote-button' },
    },
    editQuote: {
        id: 'editQuote',
        label: 'Edit quote',
        handler: 'handleQuoteStatusChange',
        param: QUOTE_STATUS.Calculated,
        tagAttrs: { 'data-testid': 'edit-quote-button' },
    },
    editPendingOrder: {
        id: 'editPendingOrder',
        label: 'Edit pending order',
        handler: 'handleQuoteStatusChange',
        param: QUOTE_STATUS.Calculated,
        tagAttrs: { 'data-testid': 'edit-pending-order-button' },
    },
    markAsIssued: {
        id: 'markAsIssued',
        label: 'Mark as issued',
        handler: 'handleQuoteStatusChange',
        param: QUOTE_STATUS.Issued,
        tagAttrs: { 'data-testid': 'mark-as-issued-button' },
    },
    markAsOrdered: {
        id: 'markAsOrdered',
        label: 'Mark as ordered',
        handler: 'handleMarkAsOrdered',
        tagAttrs: { 'data-testid': 'mark-as-ordered-button' },
    },
    markAsLost: {
        id: 'markAsLost',
        label: 'Mark as lost',
        handler: 'handleQuoteStatusChange',
        param: QUOTE_STATUS.Lost,
        tagAttrs: { 'data-testid': 'mark-as-lost-button' },
    },
    markAsInvoiced: {
        id: 'markAsInvoiced',
        label: 'Mark as invoiced',
        handler: 'handleMarkAsInvoiced',
        tagAttrs: { 'data-testid': 'mark-as-invoiced-button' },
    },
    markAsCancelled: {
        id: 'markAsCancelled',
        label: 'Mark as cancelled',
        handler: 'handleQuoteStatusChange',
        param: QUOTE_STATUS.Cancelled,
        tagAttrs: { 'data-testid': 'mark-as-cancelled-button' },
    },
    markAsVoided: {
        id: 'markAsVoided',
        label: 'Mark as voided',
        handler: 'handleQuoteStatusChange',
        param: QUOTE_STATUS.Voided,
        tagAttrs: { 'data-testid': 'mark-as-voided-button' },
    },
    markAsConfirmed: {
        id: 'markAsConfirmed',
        label: 'Confirm pending order',
        handler: 'handleMarkAsOrdered',
        tagAttrs: { 'data-testid': 'mark-as-confirmed-button' },
    },
    markAsRejected: {
        id: 'markAsRejected',
        label: 'Reject pending order',
        handler: 'handleQuoteStatusChange',
        param: QUOTE_STATUS.Rejected,
        tagAttrs: { 'data-testid': 'mark-as-rejected-button' },
    },
    markAsPending: {
        id: 'markAsPending',
        label: 'Revert to pending order',
        handler: 'handleQuoteStatusChange',
        param: QUOTE_STATUS.PendingOrderConfirmation,
        tagAttrs: { 'data-testid': 'mark-as-pending-button' },
    },
    markAsPaid: {
        id: 'markAsPaid',
        label: 'Mark as paid',
        handler: 'handleQuotePaymentStatusChange',
        param: QUOTE_PAYMENT_STATUS.ManualPaid,
        tagAttrs: { 'data-testid': 'mark-as-paid-button' },
        hidden: (showPayments) => !showPayments,
    },
    markAsPaidAndOrdered: {
        id: 'markAsPaidAndOrdered',
        label: 'Mark as paid and ordered',
        handler: 'handleMarkAsPaidAndOrdered',
        param: QUOTE_PAYMENT_STATUS.ManualPaid,
        tagAttrs: { 'data-testid': 'mark-as-paid-and-ordered-button' },
        hidden: (showPayments) => !showPayments,
    },
    markAsUnpaid: {
        id: 'markAsUnpaid',
        label: 'Mark as unpaid',
        handler: 'handleQuotePaymentStatusChange',
        param: QUOTE_PAYMENT_STATUS.Unpaid,
        tagAttrs: { 'data-testid': 'mark-as-unpaid-button' },
        hidden: (showPayments) => !showPayments,
    },
    copyQuoteLink: {
        id: 'copyQuoteLink',
        label: 'Copy quote link',
        handler: 'handleCopyLink',
        param: PUBLIC_DOCUMENTS.Quote,
        tagAttrs: { 'data-testid': 'copy-quote-link-button' },
    },
    copyOrderLink: {
        id: 'copyOrderLink',
        label: 'Copy order confirmation link',
        handler: 'handleCopyLink',
        param: PUBLIC_DOCUMENTS.OrderConfirmation,
        tagAttrs: { 'data-testid': 'copy-order-link-button' },
    },
    copyProformaLink: {
        id: 'copyProformaLink',
        label: 'Copy proforma invoice link',
        handler: 'handleCopyLink',
        param: PUBLIC_DOCUMENTS.ProformaInvoice,
        tagAttrs: { 'data-testid': 'copy-proforma-link-button' },
    },
    copyInvoiceLink: {
        id: 'copyInvoiceLink',
        label: 'Copy invoice link',
        handler: 'handleCopyLink',
        param: PUBLIC_DOCUMENTS.TaxInvoice,
        tagAttrs: { 'data-testid': 'copy-invoice-link-button' },
    },
    emailQuote: {
        id: 'emailQuote',
        label: 'Email quote',
        handler: 'handleSendEmail',
        param: PUBLIC_DOCUMENTS.Quote,
        tagAttrs: { 'data-testid': 'email-quote-button' },
    },
    emailOrder: {
        id: 'emailOrder',
        label: 'Email order confirmation',
        handler: 'handleSendEmail',
        param: PUBLIC_DOCUMENTS.OrderConfirmation,
        tagAttrs: { 'data-testid': 'email-order-button' },
    },
    emailProformaInvoice: {
        id: 'emailProformaInvoice',
        label: 'Email proforma invoice',
        handler: 'handleSendEmail',
        param: PUBLIC_DOCUMENTS.ProformaInvoice,
        tagAttrs: { 'data-testid': 'email-proforma-invoice-button' },
    },
    emailTaxInvoice: {
        id: 'emailTaxInvoice',
        label: 'Email tax invoice',
        handler: 'handleSendEmail',
        param: PUBLIC_DOCUMENTS.TaxInvoice,
        tagAttrs: { 'data-testid': 'email-tax-invoice-button' },
    },
    emailCustomer: {
        id: 'emailCustomer',
        label: 'Email customer',
        handler: 'handleSendEmail',
        param: 'sendCustomer',
        tagAttrs: { 'data-testid': 'email-customer-button' },
    },

    sendToPartLibrary: {
        id: 'sendToPartLibrary',
        label: 'Send to part library',
        handler: 'handleSendToPartLibrary',
        tagAttrs: { 'data-testid': 'send-to-part-library-button' },
    },
    duplicateQuote: {
        id: 'duplicateQuote',
        label: 'Duplicate quote',
        handler: 'handleDuplicateQuote',
        tagAttrs: { 'data-testid': 'duplicate-quote-button' },
    },
    resendInvoiceData: {
        id: 'resendInvoiceData',
        label: 'Resend invoice data',
        handler: 'handleResendInvoiceData',
        tagAttrs: { 'data-testid': 'resend-invoice-data-button' },
        hidden: (showRetryIntegration) => !showRetryIntegration,
    },
}

export const QUOTE_STATE_TO_ACTION_MAP = (status, paymentStatus) => {
    let actions = null
    switch (status) {
        case QUOTE_STATUS.NotCalculated:
            actions = [
                QUOTE_ACTIONS.calculateQuote,
                QUOTE_ACTIONS.emailQuote,
                QUOTE_ACTIONS.markAsIssued,
                QUOTE_ACTIONS.copyQuoteLink,
                QUOTE_ACTIONS.sendToPartLibrary,
                QUOTE_ACTIONS.duplicateQuote,
            ]
            break
        case QUOTE_STATUS.Calculated:
            actions = [
                QUOTE_ACTIONS.reviewQuote,
                QUOTE_ACTIONS.emailQuote,
                QUOTE_ACTIONS.markAsIssued,
                QUOTE_ACTIONS.copyQuoteLink,
                QUOTE_ACTIONS.sendToPartLibrary,
                QUOTE_ACTIONS.duplicateQuote,
            ]
            break
        case QUOTE_STATUS.Draft:
            actions = [
                QUOTE_ACTIONS.markAsIssued,
                QUOTE_ACTIONS.editQuote,
                QUOTE_ACTIONS.emailQuote,
                QUOTE_ACTIONS.copyQuoteLink,
                QUOTE_ACTIONS.sendToPartLibrary,
                QUOTE_ACTIONS.duplicateQuote,
            ]
            break
        case QUOTE_STATUS.Issued:
            actions = [
                QUOTE_ACTIONS.markAsOrdered,
                ...(paymentStatus === QUOTE_PAYMENT_STATUS.Unpaid
                    ? [QUOTE_ACTIONS.markAsPaidAndOrdered]
                    : [QUOTE_ACTIONS.markAsUnpaid]),
                QUOTE_ACTIONS.editQuote,
                QUOTE_ACTIONS.emailQuote,
                QUOTE_ACTIONS.copyQuoteLink,
                QUOTE_ACTIONS.markAsLost,
                QUOTE_ACTIONS.sendToPartLibrary,
                QUOTE_ACTIONS.duplicateQuote,
            ]
            break
        case QUOTE_STATUS.PendingOrderConfirmation:
            actions = [
                QUOTE_ACTIONS.emailCustomer,
                QUOTE_ACTIONS.markAsConfirmed,
                ...(paymentStatus === QUOTE_PAYMENT_STATUS.Unpaid
                    ? [QUOTE_ACTIONS.markAsPaidAndOrdered]
                    : [QUOTE_ACTIONS.markAsUnpaid]),
                QUOTE_ACTIONS.editPendingOrder,

                QUOTE_ACTIONS.markAsRejected,
            ]
            break
        case QUOTE_STATUS.Ordered:
            actions = [
                QUOTE_ACTIONS.markAsInvoiced,
                ...(paymentStatus === QUOTE_PAYMENT_STATUS.Unpaid
                    ? [QUOTE_ACTIONS.markAsPaid]
                    : [QUOTE_ACTIONS.markAsUnpaid]),
                QUOTE_ACTIONS.emailOrder,
                QUOTE_ACTIONS.copyOrderLink,
                QUOTE_ACTIONS.emailProformaInvoice,
                QUOTE_ACTIONS.copyProformaLink,

                QUOTE_ACTIONS.markAsCancelled,
                QUOTE_ACTIONS.duplicateQuote,
            ]
            break
        case QUOTE_STATUS.Invoiced:
            actions = [
                QUOTE_ACTIONS.emailTaxInvoice,
                QUOTE_ACTIONS.copyInvoiceLink,
                ...(paymentStatus === QUOTE_PAYMENT_STATUS.Unpaid
                    ? [QUOTE_ACTIONS.markAsPaid]
                    : [QUOTE_ACTIONS.markAsUnpaid]),
                QUOTE_ACTIONS.markAsVoided,
                QUOTE_ACTIONS.duplicateQuote,
                QUOTE_ACTIONS.resendInvoiceData,
            ]
            break
        case QUOTE_STATUS.Lost:
            actions = [
                QUOTE_ACTIONS.editQuote,
                QUOTE_ACTIONS.emailCustomer,
                ...(paymentStatus === QUOTE_PAYMENT_STATUS.Unpaid
                    ? [QUOTE_ACTIONS.markAsPaidAndOrdered]
                    : [QUOTE_ACTIONS.markAsUnpaid]),
                QUOTE_ACTIONS.duplicateQuote,
            ]
            break
        case QUOTE_STATUS.Cancelled:
            actions = [QUOTE_ACTIONS.duplicateQuote]
            break
        case QUOTE_STATUS.Voided:
            actions = [QUOTE_ACTIONS.duplicateQuote]
            break
        case QUOTE_STATUS.Rejected:
            actions = [QUOTE_ACTIONS.emailCustomer, QUOTE_ACTIONS.markAsPending]
            break
        case QUOTE_STATUS.Dispatched:
            actions = [QUOTE_ACTIONS.markAsLost, QUOTE_ACTIONS.markAsCancelled]
            break
        default:
            actions = null
    }

    return actions
}

export const QUOTE_ADD_PARTS_OPTIONS = [
    {
        id: 'add2dParts',
        label: 'Add 2D Parts',
        handler: 'handleAdd2dParts',
        tagAttrs: { 'data-testid': 'add-2d-parts-button' },
        source: QUOTE_SOURCE_TYPES.Internal,
    },
    {
        id: 'add3dParts',
        label: 'Add 3D Parts',
        handler: 'handleAdd3dParts',
        tagAttrs: { 'data-testid': 'add-3d-parts-button' },
        source: QUOTE_SOURCE_TYPES.Internal,
    },
    {
        id: 'addRotaryParts',
        label: 'Add Rotary Parts',
        handler: 'handleAddRotaryParts',
        tagAttrs: { 'data-testid': 'add-rotary-parts-button' },
        source: QUOTE_SOURCE_TYPES.Internal,
    },
    {
        id: 'addFromQuickParts',
        label: 'Add from Quick Part',
        handler: 'handleAddFromQuickParts',
        tagAttrs: { 'data-testid': 'add-from-quickpart-button' },
        source: QUOTE_SOURCE_TYPES.Internal,
    },
    {
        id: 'addPdfParts',
        label: 'Add parts from PDF',
        handler: 'handleAddPdfParts',
        tagAttrs: { 'data-testid': 'add-pdf-part-button' },
        source: QUOTE_SOURCE_TYPES.Internal,
    },
    {
        id: 'addFromPartsLibrary',
        label: 'Add from Part Library',
        handler: 'handleAddFromPartsLibrary',
        tagAttrs: { 'data-testid': 'add-from-part-library-button' },
        source: QUOTE_SOURCE_TYPES.Internal,
    },
    {
        id: 'addMiscellaneousItem',
        label: 'Add Miscellaneous Item',
        handler: 'handleAddMiscellaneousItem',
        tagAttrs: { 'data-testid': 'add-miscellaneous-item-button' },
        source: QUOTE_SOURCE_TYPES.Internal,
    },
]

export const WEBSTORE_QUOTE_ADD_PARTS_OPTIONS = [
    {
        id: 'add2dParts',
        label: 'Add 2D Parts',
        handler: 'handleAdd2dParts',
        tagAttrs: { 'data-testid': 'add-2d-parts-button' },
        source: QUOTE_SOURCE_TYPES.WebStore,
    },
    {
        id: 'addFromQuickParts',
        label: 'Add from Quick Part',
        handler: 'handleAddFromQuickParts',
        tagAttrs: { 'data-testid': 'add-from-quickpart-button' },
        source: QUOTE_SOURCE_TYPES.WebStore,
    },
    // {
    //     id: 'add3dParts',
    //     label: 'Add 3D Parts',
    //     handler: 'handleAdd3dParts',
    //     tagAttrs: { 'data-testid': 'add-3d-parts-button' },
    // },
    // {
    //     id: 'addRotaryParts',
    //     label: 'Add Rotary Parts',
    //     handler: 'handleAddRotaryParts',
    //     tagAttrs: { 'data-testid': 'add-rotary-parts-button' },
    // },
    // {
    //     id: 'addPdfParts',
    //     label: 'Add parts from PDF',
    //     handler: 'handleAddPdfParts',
    //     tagAttrs: { 'data-testid': 'add-pdf-part-button' },
    // },
    // {
    //     id: 'addFromPartsLibrary',
    //     label: 'Add from Part Library',
    //     handler: 'handleAddFromPartsLibrary',
    //     tagAttrs: { 'data-testid': 'add-from-part-library-button' },
    // },
]

const DOWNLOAD_DOCUMENTS_OPTIONS_KEYS_A = [
    QUOTE_STATUS.NotCalculated,
    QUOTE_STATUS.NotCalculated,
    QUOTE_STATUS.Draft,
    QUOTE_STATUS.Issued,
    QUOTE_STATUS.Lost,
    QUOTE_STATUS.PendingOrderConfirmation,
    QUOTE_STATUS.Rejected,
]

const DOWNLOAD_DOCUMENTS_OPTIONS_KEYS_B = [QUOTE_STATUS.Ordered, QUOTE_STATUS.Cancelled, QUOTE_STATUS.Voided]

export const QUOTE_DOWNLOAD_DOCUMENTS_OPTIONS = {
    ...Object.fromEntries(
        DOWNLOAD_DOCUMENTS_OPTIONS_KEYS_A.map((key) => [
            key,
            [
                {
                    id: PUBLIC_DOCUMENTS.Quote,
                    label: 'Quote',
                    disabled: false,
                    type: 'public',
                    tagAttrs: { 'data-testid': 'download-quote-button' },
                },
                {
                    id: PUBLIC_DOCUMENTS.OrderConfirmation,
                    label: 'Order confirmation',
                    disabled: true,
                    hidden: import.meta.env.VITE_RESELLER ?? false,
                    type: 'public',
                    tagAttrs: { 'data-testid': 'download-order-confirmation-button' },
                },
                {
                    id: PUBLIC_DOCUMENTS.ProformaInvoice,
                    label: 'Proforma invoice',
                    disabled: true,
                    hidden: import.meta.env.VITE_RESELLER ?? false,
                    type: 'public',
                    tagAttrs: { 'data-testid': 'download-proforma-invoice-button' },
                },
                {
                    id: PUBLIC_DOCUMENTS.TaxInvoice,
                    label: 'Tax invoice',
                    disabled: true,
                    hidden: import.meta.env.VITE_RESELLER ?? false,
                    type: 'public',
                    tagAttrs: { 'data-testid': 'download-tax-invoice-button' },
                },
                {
                    id: PRIVATE_DOCUMENTS.WorkOrderSummary,
                    label: 'Work order summary',
                    disabled: true,
                    hidden: import.meta.env.VITE_RESELLER ?? false,
                    type: 'private',
                    tagAttrs: { 'data-testid': 'download-work-order-summary-button' },
                },
                {
                    id: PRIVATE_DOCUMENTS.WorkOrder,
                    label: 'Work order',
                    disabled: true,
                    hidden: import.meta.env.VITE_RESELLER ?? false,
                    type: 'private',
                    tagAttrs: { 'data-testid': 'download-work-order-button' },
                },
                {
                    id: PRIVATE_DOCUMENTS.DeliveryDocket,
                    label: 'Delivery docket',
                    disabled: true,
                    hidden: import.meta.env.VITE_RESELLER ?? false,
                    type: 'private',
                    tagAttrs: { 'data-testid': 'download-delivery-docket-button' },
                },
                {
                    id: PRIVATE_DOCUMENTS.ProductionLabels,
                    label: 'Production labels',
                    disabled: true,
                    hidden: import.meta.env.VITE_RESELLER ?? false,
                    type: 'private',
                    tagAttrs: { 'data-testid': 'download-production-labels-button' },
                },
            ],
        ])
    ),
    ...Object.fromEntries(
        DOWNLOAD_DOCUMENTS_OPTIONS_KEYS_B.map((key) => [
            key,
            [
                {
                    id: PUBLIC_DOCUMENTS.Quote,
                    label: 'Quote',
                    disabled: false,
                    type: 'public',
                    tagAttrs: { 'data-testid': 'download-quote-button' },
                },
                {
                    id: PUBLIC_DOCUMENTS.OrderConfirmation,
                    label: 'Order confirmation',
                    disabled: false,
                    hidden: import.meta.env.VITE_RESELLER ?? false,
                    type: 'public',
                    tagAttrs: { 'data-testid': 'download-order-confirmation-button' },
                },
                {
                    id: PUBLIC_DOCUMENTS.ProformaInvoice,
                    label: 'Proforma invoice',
                    disabled: false,
                    hidden: import.meta.env.VITE_RESELLER ?? false,
                    type: 'public',
                    tagAttrs: { 'data-testid': 'download-proforma-invoice-button' },
                },
                {
                    id: PUBLIC_DOCUMENTS.TaxInvoice,
                    label: 'Tax invoice',
                    disabled: true,
                    hidden: import.meta.env.VITE_RESELLER ?? false,
                    type: 'public',
                    tagAttrs: { 'data-testid': 'download-tax-invoice-button' },
                },
                {
                    id: PRIVATE_DOCUMENTS.WorkOrderSummary,
                    label: 'Work order summary',
                    disabled: false,
                    hidden: import.meta.env.VITE_RESELLER ?? false,
                    type: 'private',
                    tagAttrs: { 'data-testid': 'download-work-order-summary-button' },
                },
                {
                    id: PRIVATE_DOCUMENTS.WorkOrder,
                    label: 'Work order',
                    disabled: false,
                    hidden: import.meta.env.VITE_RESELLER ?? false,
                    type: 'private',
                    tagAttrs: { 'data-testid': 'download-work-order-button' },
                },
                {
                    id: PRIVATE_DOCUMENTS.DeliveryDocket,
                    label: 'Delivery docket',
                    disabled: false,
                    hidden: import.meta.env.VITE_RESELLER ?? false,
                    type: 'private',
                    tagAttrs: { 'data-testid': 'download-delivery-docket-button' },
                },
                {
                    id: PRIVATE_DOCUMENTS.ProductionLabels,
                    label: 'Production labels',
                    disabled: false,
                    hidden: import.meta.env.VITE_RESELLER ?? false,
                    type: 'private',
                    tagAttrs: { 'data-testid': 'download-production-labels-button' },
                },
            ],
        ])
    ),
    [QUOTE_STATUS.Invoiced]: [
        {
            id: PUBLIC_DOCUMENTS.Quote,
            label: 'Quote',
            disabled: false,
            type: 'public',
            tagAttrs: { 'data-testid': 'download-quote-button' },
        },
        {
            id: PUBLIC_DOCUMENTS.OrderConfirmation,
            label: 'Order confirmation',
            disabled: false,
            hidden: import.meta.env.VITE_RESELLER ?? false,
            type: 'public',
            tagAttrs: { 'data-testid': 'download-order-confirmation-button' },
        },
        {
            id: PUBLIC_DOCUMENTS.ProformaInvoice,
            label: 'Proforma invoice',
            disabled: false,
            hidden: import.meta.env.VITE_RESELLER ?? false,
            type: 'public',
            tagAttrs: { 'data-testid': 'download-proforma-invoice-button' },
        },
        {
            id: PUBLIC_DOCUMENTS.TaxInvoice,
            label: 'Tax invoice',
            disabled: false,
            hidden: import.meta.env.VITE_RESELLER ?? false,
            type: 'public',
            tagAttrs: { 'data-testid': 'download-tax-invoice-button' },
        },
        {
            id: PRIVATE_DOCUMENTS.WorkOrderSummary,
            label: 'Work order summary',
            disabled: false,
            hidden: import.meta.env.VITE_RESELLER ?? false,
            type: 'private',
            tagAttrs: { 'data-testid': 'download-work-order-summary-button' },
        },
        {
            id: PRIVATE_DOCUMENTS.WorkOrder,
            label: 'Work order',
            disabled: false,
            hidden: import.meta.env.VITE_RESELLER ?? false,
            type: 'private',
            tagAttrs: { 'data-testid': 'download-work-order-button' },
        },
        {
            id: PRIVATE_DOCUMENTS.DeliveryDocket,
            label: 'Delivery docket',
            disabled: false,
            hidden: import.meta.env.VITE_RESELLER ?? false,
            type: 'private',
            tagAttrs: { 'data-testid': 'download-delivery-docket-button' },
        },
        {
            id: PRIVATE_DOCUMENTS.ProductionLabels,
            label: 'Production labels',
            disabled: false,
            hidden: import.meta.env.VITE_RESELLER ?? false,
            type: 'private',
            tagAttrs: { 'data-testid': 'download-production-labels-button' },
        },
    ],
}

export const QUOTE_USER_TYPE = {
    Customer: 'Customer',
    Guest: 'Guest',
    Internal: 'Internal',
}

export const QUADERNO_JURISDICTIONS = [
    {
        country: 'BE',
        name: 'Belgica',
    },
    {
        country: 'BG',
        name: 'Bulgaria',
    },
    {
        country: 'CY',
        name: 'Cyprus',
    },
    {
        country: 'CZ',
        name: 'Czech Republic',
    },
    {
        country: 'DE',
        name: 'Germany',
    },
    {
        country: 'DK',
        name: 'Denmark',
    },
    {
        country: 'EE',
        name: 'Estonia',
    },
    {
        country: 'EL',
        name: 'Greece',
    },
    {
        country: 'ES',
        name: 'Spain',
    },
    {
        country: 'FI',
        name: 'Finland',
    },
    {
        country: 'FR',
        name: 'France',
    },
    {
        country: 'HR',
        name: 'Croatia',
    },
    {
        country: 'HU',
        name: 'Hungary',
    },
    {
        country: 'IE',
        name: 'Ireland',
    },
    {
        country: 'IT',
        name: 'Italy',
    },
    {
        country: 'LR',
        name: 'Lithuania',
    },
    {
        country: 'LU',
        name: 'Luxembourg',
    },
    {
        country: 'LV',
        name: 'Latvia',
    },
    {
        country: 'MT',
        name: 'Malta',
    },
    {
        country: 'NL',
        name: 'The Netherlands',
    },
    {
        country: 'PL',
        name: 'Poland',
    },
    {
        country: 'PT',
        name: 'Portugal',
    },
    {
        country: 'RO',
        name: 'Romania',
    },
    {
        country: 'SE',
        name: 'Sweden',
    },
    {
        country: 'SI',
        name: 'Slovenia',
    },
    {
        country: 'SK',
        name: 'Slovakia',
    },
    {
        country: 'XI',
        name: 'Northern Ireland',
    },
    {
        country: 'AU',
        name: 'Australia',
    },
    {
        country: 'GB',
        name: 'United Kingdom of Great Bretain and Northern Ireland',
    },
    {
        country: 'CH',
        name: 'Switzerland',
    },
    {
        country: 'NZ',
        name: 'New Zealand',
    },
    {
        country: 'CA',
        name: 'Canada',
    },
]

export const EXPORTABLE_CONTENT = 'exportable-content'
export const FOOTER_BRANDING = 'exportable-footer-branding'
export const FOOTER_SELECTOR = 'exportable-footer'
export const IS_EXPORTING_SELECTOR = 'exportable-is-exporting'

export const CUTTING_TECHNOLOGY_POWERS = [1, 2, 3, 4, 5, 6, 7, 8, 9, 10]

export const PARTS_PER_PAGE_VARIABLE = 'parts-per-page'
export const PARTS_PER_PAGE_DEFAULT_VALUE = 10
export const PARTS_PER_PAGE_OPTIONS = [10, 25, 50]

export const ITEMS_PER_PAGE_VARIABLE = 'items-per-page'
export const ITEMS_PER_PAGE_DEFAULT_VALUE = 5
export const ITEMS_PER_PAGE_OPTIONS = [5, 10, 25, 50]

export const USERS_PER_PAGE_VARIABLE = 'users-per-page'
export const USERS_PER_PAGE_DEFAULT_VALUE = 5
export const USERS_PER_PAGE_OPTIONS = [5, 10, 15]

export const TAX_RATES_PER_PAGE_VARIABLE = 'tax-rates-per-page'
export const TAX_RATES_PER_PAGE_DEFAULT_VALUE = 5
export const TAX_RATES_PER_PAGE_OPTIONS = [5, 10, 15]

export const INVOICES_PER_PAGE_VARIABLE = 'invoices-per-page'
export const INVOICES_PER_PAGE_DEFAULT_VALUE = 5
export const INVOICES_PER_PAGE_OPTIONS = [5, 10, 15]

export const ZONES_PER_PAGE_VARIABLE = 'zones-per-page'
export const ZONES_PER_PAGE_DEFAULT_VALUE = 5
export const ZONES_PER_PAGE_OPTIONS = [5, 10, 15]

export const CONTAINERS_PER_PAGE_VARIABLE = 'containers-per-page'
export const CONTAINERS_PER_PAGE_DEFAULT_VALUE = 5
export const CONTAINERS_PER_PAGE_OPTIONS = [5, 10, 15]

export const ZONE_CONTAINERS_PER_PAGE_VARIABLE = 'zone-containers-per-page'
export const ZONE_CONTAINERS_PER_PAGE_DEFAULT_VALUE = 5
export const ZONE_CONTAINERS_PER_PAGE_OPTIONS = [5, 10, 15]

export const DEFAULT_QUOTE_FOOTER = `Thank you for your enquiry. This estimate is valid for 30 days.
All items are subject to the availability and price of raw materials at the time of order.
Any revisions to this quotation may require a recalculation of price.`

export const DEFAULT_CONTACT_DETAILS = `Business name
Tax ID 1234567890
123 Sample Lane
City, State, Zip Code
+1 (123) 456-7890
sales@yourbusiness.com`

export const DEFAULT_PAYMENT_DETAILS = `Bank name: Big Bank
Routing number: 123456789
Account number 123456789000
SWIFT code: WWWFFF`

export const PROFORMA_INVOICE_NOTES = `This proforma invoice must be paid in full before work can commence.
Please note this is not a tax invoice. A full tax invoice will follow delivery of goods.`

export const LIBELLULA_PRICING_REDIRECT_URL = 'mailto:contact@libellula.eu'
export const TOOLBOX_PRICING_REDIRECT_URL = 'https://tempustools.com/contact-us/pricing-request/?stripe_customer_id='

export const COUNTRY_LANGUAGE_MAP = {
    AU: { locale: 'en-GB', language: 'English' },
    CA: { locale: 'en-US', language: 'English' },
    CL: { locale: 'es-ES', language: 'Spanish' },
    FR: { locale: 'fr-FR', language: 'French' },
    DE: { locale: 'de-DE', language: 'German' },
    IT: { locale: 'it-IT', language: 'Italian' },
    NZ: { locale: 'en-GB', language: 'English' },
    GB: { locale: 'en-GB', language: 'English' },
    US: { locale: 'en-US', language: 'English' },
    BR: { locale: 'pt-PT', language: 'Portuguese' },
    PT: { locale: 'pt-PT', language: 'Portuguese' },
}

export const DELIVERY_PRICING_METHODS = {
    SeparateCharge: {
        value: 'SeparateCharge',
        label: 'Charge the customer for delivery separately',
        shortLabel: 'Charge for delivery separately',
        documentLabel: 'Charged separately',
        sources: [QUOTE_SOURCE_TYPES.WebStore],
    },
    PayUponDelivery: {
        value: 'PayUponDelivery',
        label: 'Customer pays delivery provider upon delivery',
        shortLabel: 'Customer pays delivery provider',
        documentLabel: 'Payable to delivery provider',
        sources: [QUOTE_SOURCE_TYPES.Internal, QUOTE_SOURCE_TYPES.WebStore],
    },
    ChargeToOrder: {
        value: 'ChargeToOrder',
        label: 'Add a delivery charge to the order',
        shortLabel: 'Add a delivery charge',
        documentLabel: 'Included',
        sources: [QUOTE_SOURCE_TYPES.Internal, QUOTE_SOURCE_TYPES.WebStore],
    },
}

export const DELIVERY_PRICING_OPTIONS = [
    {
        value: 'Flat',
        label: () => 'Flat price',
        helperText: () => 'Charge a flat price based on the container size and weight, and the delivery address zone.',
    },
    {
        value: 'ByDistance',
        label: (isImperial) => `Flat price + price per ${isImperial ? 'mile' : 'kilometer'}`,
        helperText: (isImperial) =>
            `Charge a price per ${isImperial ? 'mile' : 'kilometer'} between your address and the delivery address, based on the container size and weight, and the delivery address zone.`,
    },
    {
        value: 'ByWeight',
        label: (isImperial) => `Flat price + price per ${isImperial ? 'pound' : 'kilogram'}`,
        helperText: (isImperial) =>
            `Charge a price per ${isImperial ? 'pound' : 'kilogram'} based on the net weight of parts, container size, and the delivery address zone.`,
    },
]
