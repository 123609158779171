import { createSlice } from '@reduxjs/toolkit'

const initialState = {
    selectedMiscItemsIds: [],
    expandedMiscItemsIds: [],
    miscFilterOptions: 'showAllMiscItems',
}

export const miscItemsSlice = createSlice({
    name: 'miscItemsSlice',
    initialState,
    reducers: {
        setSelectedMiscItemsIds: (state, action) => {
            state.selectedMiscItemsIds = action.payload
        },
        addSelectedMiscItem: (state, action) => {
            state.selectedMiscItemsIds.push(action.payload)
        },
        removeSelectedMiscItem: (state, action) => {
            state.selectedMiscItemsIds = state.selectedMiscItemsIds.filter((item) => item !== action.payload)
        },
        clearSelectedMiscItems: (state) => {
            state.selectedMiscItemsIds = []
        },
        setExpandedMiscItemsIds: (state, action) => {
            state.expandedMiscItemsIds = action.payload
        },
        addExpandedMiscItem: (state, action) => {
            state.expandedMiscItemsIds.push(action.payload)
        },
        removeExpandedMiscItem: (state, action) => {
            state.expandedMiscItemsIds = state.expandedMiscItemsIds.filter((item) => item !== action.payload)
        },
        clearExpandedMiscItems: (state) => {
            state.expandedMiscItemsIds = []
        },
        setMiscFilterOptions: (state, action) => {
            state.miscFilterOptions = action.payload
        },
    },
})

export const selectSelectedMiscItems = (state) => state.miscItemsSlice.selectedMiscItemsIds
export const selectExpandedMiscItems = (state) => state.miscItemsSlice.expandedMiscItemsIds
export const selectMiscFilterOptions = (state) => state.miscItemsSlice.miscFilterOptions

export const {
    addExpandedMiscItem,
    addSelectedMiscItem,
    clearExpandedMiscItems,
    clearSelectedMiscItems,
    removeExpandedMiscItem,
    removeSelectedMiscItem,
    setExpandedMiscItemsIds,
    setSelectedMiscItemsIds,
} = miscItemsSlice.actions

export default miscItemsSlice.reducer
