import { useTranslation } from 'react-i18next'
import { Close } from '@mui/icons-material'
import { Box } from '@mui/material'
import Dialog from '@mui/material/Dialog'
import DialogContent from '@mui/material/DialogContent'
import DialogTitle from '@mui/material/DialogTitle'
import IconButton from '@mui/material/IconButton'
import { alpha } from '@mui/material/styles'
import Typography from '@mui/material/Typography'
import PropTypes from 'prop-types'

import UpgradePlanLink from '@/common/components/UpgradePlanLink/UpgradePlanLink'

const classes = {
    root: {
        margin: 0,
        padding: 2,
    },
    img: {
        '-webkit-filter': 'grayscale(1)' /* Google Chrome, Safari 6+ & Opera 15+ */,
        filter: 'grayscale(1)' /* Microsoft Edge and Firefox 35+ */,
    },
    closeButton: {
        position: 'absolute',
        right: 1,
        top: 1,
        color: 'grey[500]',
    },
    dialogBackdrop: {
        backgroundColor: (theme) => alpha(theme.palette.text.primary, 0.9),
    },
}

const QuoteCalculatorNestDialog = ({
    handleCloseNest,
    nestImageUri,
    onClose,
    openNest,
    organisationNestingEnabled = false,
    title,
}) => {
    const { t } = useTranslation()

    return (
        <Dialog
            aria-labelledby="nest-dialog"
            maxWidth="lg"
            open={openNest}
            slotProps={{
                backdrop: {
                    sx: classes.dialogBackdrop,
                },
            }}
            onClose={handleCloseNest}
        >
            <DialogTitle>
                <Typography variant="h6">{t('Nest for part:') + ' ' + title}</Typography>
                {onClose ? (
                    <IconButton
                        aria-label="close"
                        size="large"
                        sx={classes.closeButton}
                        onClick={onClose}
                    >
                        <Close />
                    </IconButton>
                ) : null}
            </DialogTitle>

            <DialogContent dividers>
                {organisationNestingEnabled ? (
                    <Box
                        alt="Nest View"
                        component="img"
                        src={nestImageUri}
                        sx={classes.img}
                    />
                ) : (
                    <UpgradePlanLink />
                )}
            </DialogContent>
        </Dialog>
    )
}

QuoteCalculatorNestDialog.propTypes = {
    handleCloseNest: PropTypes.func,
    nestImageUri: PropTypes.string,
    openNest: PropTypes.bool,
    organisationNestingEnabled: PropTypes.bool,
    title: PropTypes.string,
    onClose: PropTypes.func,
}

export default QuoteCalculatorNestDialog
