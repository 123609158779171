import { memo, useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useParams } from 'react-router-dom'
import { useSortable } from '@dnd-kit/sortable'
import { CSS } from '@dnd-kit/utilities'
import { Accordion } from '@mui/material'
import PropTypes from 'prop-types'

import { useCalculateQuoteMutation, useGetQuoteQuery } from '@/app/services/quotes'
import {
    addExpandedMiscItem,
    removeExpandedMiscItem,
    selectExpandedMiscItems,
    selectMiscFilterOptions,
} from '@/app/slices/miscItemsSlice'
import { selectOrganisationId } from '@/app/slices/organisationSlice'

import MiscItemDetails from './MiscItemDetails'
import MiscItemSummary from './MiscItemSummary'

const classes = {
    AccordionRoot: {
        overflow: 'hidden',
        my: 2,
        borderRadius: 2,
        border: (theme) => `1px solid ${theme.palette.grey[400]}`,
        backgroundColor: 'background.default',
        '&:before': {
            display: 'none',
        },
        '&.Mui-expanded': {
            minHeight: 62,
        },
        '&.Mui-expanded:first-of-type': {
            marginTop: 2,
        },
        '&:last-of-type': {
            marginBottom: 0,
        },
    },

    AccordionDetailsRoot: {
        backgroundColor: 'background.paper',
    },
}

const MiscItem = ({ id }) => {
    const dispatch = useDispatch()
    const { quoteId } = useParams()
    const organisationId = useSelector(selectOrganisationId)
    const expandedMiscItemsArray = useSelector(selectExpandedMiscItems)
    const filterOptions = useSelector(selectMiscFilterOptions)

    const [, { isLoading: isCalculating }] = useCalculateQuoteMutation({
        fixedCacheKey: 'shared-calculate-quote',
    })
    const { isFetching: isFetchingQuote } = useGetQuoteQuery({
        organisationId,
        quoteId,
    })

    const { attributes, isDragging, listeners, setActivatorNodeRef, setNodeRef, transform, transition } = useSortable({
        id: id,
        disabled: isCalculating || isFetchingQuote || filterOptions !== 'showAllMiscItems',
    })

    const [isExpanded, setIsExpanded] = useState(true)

    const style = {
        transform: CSS.Transform.toString(transform),
        transition,
        opacity: isDragging ? 0.5 : undefined,
        zIndex: isDragging ? 0 : undefined,
    }

    const handleExpandedState = () => {
        setIsExpanded(!isExpanded)
        if (isExpanded) {
            dispatch(removeExpandedMiscItem(id))
        } else {
            dispatch(addExpandedMiscItem(id))
        }
    }

    useEffect(() => {
        setIsExpanded(expandedMiscItemsArray.includes(id))
    }, [expandedMiscItemsArray, id])

    return (
        <Accordion
            expanded={isExpanded}
            ref={setNodeRef}
            style={style}
            sx={classes.AccordionRoot}
            variant="outlined"
            square
            {...attributes}
            onChange={() => handleExpandedState()}
        >
            <MiscItemSummary
                listeners={listeners}
                miscItemId={id}
                setActivatorNodeRef={setActivatorNodeRef}
            />

            <MiscItemDetails miscItemId={id} />
        </Accordion>
    )
}

MiscItem.propTypes = {
    id: PropTypes.string,
}

export default memo(MiscItem)
