import SvgIcon from '@mui/material/SvgIcon'

const MiscItemIcon = (props) => {
    return (
        <SvgIcon
            {...props}
            fill="none"
            viewBox="0 0 24 24"
        >
            <path
                d="M15.5 6.5C15.5 8.433 13.933 10 12 10C10.067 10 8.5 8.433 8.5 6.5C8.5 4.567 10.067 3 12 3C13.933 3 15.5 4.567 15.5 6.5Z"
                fill="none"
                opacity="0.4"
                stroke="#141B34"
                strokeWidth="1.5"
            />
            <path
                d="M22 17.5C22 19.433 20.433 21 18.5 21C16.567 21 15 19.433 15 17.5C15 15.567 16.567 14 18.5 14C20.433 14 22 15.567 22 17.5Z"
                fill="none"
                stroke="#141B34"
                strokeWidth="1.5"
            />
            <path
                d="M9 17.5C9 19.433 7.433 21 5.5 21C3.567 21 2 19.433 2 17.5C2 15.567 3.567 14 5.5 14C7.433 14 9 15.567 9 17.5Z"
                fill="none"
                stroke="#141B34"
                strokeWidth="1.5"
            />
        </SvgIcon>
    )
}

export default MiscItemIcon
