import { useEffect, useRef, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { List, ListItem, ListItemText, TextField } from '@mui/material'
import PropTypes from 'prop-types'

const classes = {
    list: {
        width: '100%',
    },
    listItem: {
        display: 'flex',
        justifyContent: 'space-between',
    },
    textField: {
        width: '120px',
    },
    dimensionName: {
        color: 'text.primary',
    },
}

const DimensionList = ({ dimensions, onDimensionChanged, onDimensionSelected, selectedDimension }) => {
    const { t } = useTranslation()
    const inputRef = useRef(null)

    const [initialDimensionMap, setInitialDimensionMap] = useState({})
    const [dimensionMap, setDimensionMap] = useState({})

    useEffect(() => {
        if (dimensions) {
            const dimMap = {}
            dimensions.forEach((dimension) => {
                dimMap[dimension.name] = dimension.value.toString()
            })

            setDimensionMap(dimMap)
            setInitialDimensionMap(dimMap)
        }
    }, [dimensions])

    useEffect(() => {
        if (selectedDimension && inputRef && inputRef.current) {
            // Set a slight delay to allow the focus to happen
            // https://github.com/mui-org/material-ui/issues/1594#issuecomment-272547735
            const timeout = setTimeout(() => {
                inputRef.current.focus()
                inputRef.current.setSelectionRange(0, inputRef.current.value.length)
            }, 100)

            return () => {
                clearTimeout(timeout)
            }
        }
    }, [selectedDimension])

    const handleParamValueChanged = (dimension, event) => {
        const newDimMap = { ...dimensionMap }

        newDimMap[dimension.name] = event.target.value

        setDimensionMap(newDimMap)
    }

    const handleParamTextfieldBlur = (dimension) => {
        // If new value is not set, set to the old value
        if (!dimensionMap[dimension.name]) {
            const newDimMap = { ...dimensionMap }
            newDimMap[dimension.name] = initialDimensionMap[dimension.name]
            setDimensionMap(newDimMap)
            return
        }

        if (typeof onDimensionChanged === 'function') {
            // Only fire change event if there has been a value change
            if (dimensionMap[dimension.name] !== initialDimensionMap[dimension.name]) {
                const newValue = parseFloat(dimensionMap[dimension.name])
                onDimensionChanged(dimension, newValue)
            }
        }
    }

    const handleParamTextfieldFocused = (dimension) => {
        if (typeof onDimensionSelected === 'function') {
            onDimensionSelected(dimension.name)
        }
    }

    return dimensions && dimensions.length ? (
        <List sx={classes.list}>
            {dimensions.map((dimension) => {
                const descriptionText = t(dimension.description) + ' (' + dimension.shortName + ')'
                return (
                    <ListItem
                        disableGutters={true}
                        key={dimension.index}
                        sx={classes.listItem}
                    >
                        <ListItemText
                            primary={t(descriptionText)}
                            primaryTypographyProps={{ sx: classes.dimensionName }}
                        />
                        <TextField
                            inputProps={{ style: { textAlign: 'right' } }}
                            inputRef={dimension.name === selectedDimension ? inputRef : null}
                            sx={classes.textField}
                            value={
                                typeof dimensionMap[dimension.name] !== 'undefined'
                                    ? dimensionMap[dimension.name]
                                    : dimension.value
                            }
                            variant="outlined"
                            onBlur={() => handleParamTextfieldBlur(dimension)}
                            onChange={(event) => handleParamValueChanged(dimension, event)}
                            onFocus={() => handleParamTextfieldFocused(dimension)}
                        />
                    </ListItem>
                )
            })}
        </List>
    ) : null
}

DimensionList.propTypes = {
    dimensions: PropTypes.array,
    drawingUnits: PropTypes.string,
    selectedDimension: PropTypes.string,
    onDimensionChanged: PropTypes.func,
    onDimensionSelected: PropTypes.func,
}

export default DimensionList
