import { useTranslation } from 'react-i18next'
import { Close, Delete, Save } from '@mui/icons-material'
import { Box, Dialog, IconButton, Slide, Typography } from '@mui/material'
import PropTypes from 'prop-types'

const classes = {
    dialog: {
        pointerEvents: 'none',
    },
    modal: {
        padding: '24px',
        minWidth: '224px',
        gap: '24px',
    },
    modalPaper: {
        display: 'block',
        position: 'absolute',
        top: '4.5rem',
        left: '14rem',
        pointerEvents: 'auto',
        borderRadius: '8px',
    },
    title: {
        fontWeight: 700,
    },
    actionButtons: {
        gap: '8px',
    },
}

const TbxBulkEditModal = ({
    children,
    direction = 'right',
    disableSave,
    handleClose,
    handleDelete,
    handleSave,
    items,
    open,
}) => {
    const { t } = useTranslation()

    const getTitle = () => {
        if (!items) return ''

        return items.length === 1
            ? t('Editing {{length}} part', { length: items.length })
            : t('Editing {{length}} parts', { length: items.length })
    }

    const handleDeleteClick = () => handleDelete(items)
    const handleSaveClick = () => handleSave(items)
    const handleCloseClick = (_, reason) => {
        reason !== 'backdropClick' && handleClose()
    }

    return (
        <Dialog
            open={!!open}
            PaperProps={{
                sx: classes.modalPaper,
            }}
            sx={classes.dialog}
            TransitionComponent={Slide}
            TransitionProps={{ direction: direction }}
            disableAutoFocus
            disableEnforceFocus
            disableScrollLock
            hideBackdrop
            keepMounted
            onClose={handleCloseClick}
        >
            <Box
                display="flex"
                flexDirection="column"
                sx={classes.modal}
            >
                <Box
                    alignItems="center"
                    display="flex"
                    justifyContent="space-between"
                >
                    <Typography
                        sx={classes.title}
                        variant="body1"
                    >
                        {getTitle()}
                    </Typography>
                    <Box
                        display="flex"
                        sx={classes.actionButtons}
                    >
                        <IconButton
                            aria-label="save"
                            color="primary"
                            disabled={disableSave}
                            size="small"
                            onClick={handleSaveClick}
                        >
                            <Save />
                        </IconButton>
                        <IconButton
                            aria-label="archive"
                            color="primary"
                            size="small"
                            onClick={handleDeleteClick}
                        >
                            <Delete />
                        </IconButton>
                        <IconButton
                            aria-label="close"
                            color="primary"
                            size="small"
                            onClick={handleCloseClick}
                        >
                            <Close />
                        </IconButton>
                    </Box>
                </Box>
                {children}
            </Box>
        </Dialog>
    )
}

TbxBulkEditModal.propTypes = {
    direction: PropTypes.string,
    disableSave: PropTypes.bool,
    handleClose: PropTypes.func,
    handleDelete: PropTypes.func,
    handleSave: PropTypes.func,
    items: PropTypes.array,
    open: PropTypes.bool,
}

export default TbxBulkEditModal
