import { Box } from '@mui/material'
import PropTypes from 'prop-types'

const TbxToolbarActions = ({ children }) => {
    return (
        <Box
            display="flex"
            gap={2}
            sx={{ minWidth: 'fit-content' }}
        >
            {children}
        </Box>
    )
}

TbxToolbarActions.propTypes = {
    children: PropTypes.node,
}

export default TbxToolbarActions
