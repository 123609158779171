import SvgIcon from '@mui/material/SvgIcon'

const PaymentMethodsIcon = (props) => {
    return (
        <SvgIcon
            viewBox="0 0 300 50"
            {...props}
        >
            <defs>
                <style>
                    {
                        '.cls-11,.cls-12,.cls-4,.cls-5{stroke-width:0}.cls-4{fill:#0071ce}.cls-5{fill:#000}.cls-11{fill:#3c4043}.cls-12{fill:#fff}'
                    }
                </style>
            </defs>
            <g id="AMEX">
                <path
                    className="cls-4"
                    d="m43.6 36.7-2.1-2.4-2.2 2.4H25.8V25.9h-4.4l5.4-12.2H32l1.9 4.2v-4.2h6.5l1.1 3.2 1.1-3.2h5v-1.9c0-1.1-.9-2-2-2H2c-1.1 0-2 .9-2 2V38c0 1.1.9 2 2 2h43.5c1.1 0 2-.9 2-2v-1.3h-4Z"
                />
                <path
                    className="cls-4"
                    d="M44.2 35.5h3.4l-4.5-4.8 4.5-4.6h-3.3l-2.9 3-2.7-3h-3.4l4.5 4.7-4.5 4.7h3.3l2.9-3 2.8 3Z"
                />
                <path
                    className="cls-4"
                    d="M29.8 33.3v-1.4h5.3v-2.2h-5.3v-1.4h5.5v-2.2h-8.1v9.4h8.1v-2.2h-5.5ZM45 24.7h2.5v-9.5h-4l-2.1 6-2.2-5.9h-4v9.4h2.6v-6.6l2.4 6.6h2.3l2.4-6.6v6.6Zm-14-9.4h-3.4l-4.2 9.5h2.9l.8-1.9h4.4l.8 1.9h2.9L31 15.3Zm-3 5.4 1.3-3.1 1.3 3.1h-2.5Zm17.2 9.8 2.4 2.6v-5.2l-2.4 2.6Z"
                />
            </g>
            <g id="MASTERCARD">
                <path
                    d="M78.6 14.7h12.5v20.5H78.6V14.7Z"
                    style={{
                        strokeWidth: 0,
                        fill: '#ff5f00',
                    }}
                />
                <path
                    d="M79.9 25c0-4 1.9-7.8 5-10.3-5.6-4.5-13.9-3.4-18.3 2.2-4.5 5.6-3.4 13.9 2.2 18.3 4.7 3.7 11.4 3.7 16.1 0-3.1-2.4-5-6.2-5-10.3Z"
                    style={{
                        fill: '#eb001b',
                        strokeWidth: 0,
                    }}
                />
                <path
                    d="M104.7 33.1v-.4h-.3.2v.4Zm.9 0v-.5h-.2l-.2.3-.2-.3h-.2v.5-.4l.2.3.2-.3v.4h.3Zm.4-8.1c0 7.2-5.8 13-13 13s-5.8-1-8.1-2.8c5.6-4.5 6.6-12.6 2.2-18.3-.7-.8-1.3-1.5-2.2-2.2 5.6-4.5 13.9-3.4 18.3 2.2C105 19.2 106 22 106 25Z"
                    style={{
                        fill: '#f79e1b',
                        strokeWidth: 0,
                    }}
                />
            </g>
            <g id="VISA">
                <path
                    className="cls-12"
                    d="M122.1 9.9h47.6v30.3h-47.6V9.9Z"
                />
                <path
                    d="M123 35.2h46v4.1h-46v-4.1Z"
                    style={{
                        fill: '#fcb316',
                        strokeWidth: 0,
                    }}
                />
                <path
                    d="M123 10.7h46v4.1h-46v-4.1Zm18.4 8.7-4.8 11.4h-3.1l-2.4-9.1c0-.4-.3-.8-.7-1-.9-.4-1.9-.8-2.9-.9v-.3h5c.7 0 1.3.5 1.3 1.2l1.3 6.6 3-7.7h3.1Zm12.1 7.7c0-3-4.1-3.2-4.1-4.5s.4-.8 1.3-.9c1 0 2 0 2.9.5l.5-2.4c-.8-.3-1.9-.5-2.8-.5-2.9 0-5 1.5-5 3.8s1.4 2.5 2.6 3.1 1.5.9 1.5 1.4c0 .8-.9 1.1-1.8 1.1s-2.1-.3-3-.7l-.5 2.5c1 .4 2.2.6 3.3.6 3.1-.2 5.1-1.7 5.1-4m7.6 3.7h2.7l-2.4-11.4h-2.5c-.6 0-1 .3-1.3.8l-4.4 10.5h3.1l.6-1.7h3.8l.3 1.7Zm-3.2-4.1 1.5-4.3.9 4.3h-2.4Zm-12.3-7.3-2.4 11.4h-2.9l2.4-11.4h2.9Z"
                    style={{
                        fill: '#10357f',
                        strokeWidth: 0,
                    }}
                />
            </g>
            <g
                data-name="APPLE PAY"
                id="APPLE_PAY"
            >
                <path
                    className="cls-5"
                    d="M225.5 10.2h-39.9c-.3 0-.6.2-.8.3-.3.2-.5.3-.7.5s-.3.4-.5.7c-.2.3-.3.5-.3.8v24.9c0 .3.2.6.3.8.2.3.3.5.5.7s.4.4.7.5c.3.2.5.3.8.3h41.5c.3 0 .6-.2.8-.3.3-.2.5-.3.7-.5s.4-.4.5-.7c.2-.3.3-.5.3-.8v-25c0-.3-.2-.6-.3-.8-.2-.3-.3-.5-.5-.7s-.4-.4-.7-.5c-.3-.2-.5-.3-.8-.3h-1.7Z"
                />
                <path
                    className="cls-12"
                    d="M225.5 11.2h1.6c.2 0 .4 0 .6.2s.3.2.4.3.3.3.3.4.2.3.2.6v24.6c0 .2 0 .4-.2.6s-.2.3-.3.4-.3.3-.4.3-.3.2-.6.2h-41.4c-.2 0-.4 0-.6-.2-.2 0-.3-.2-.4-.3-.2-.2-.3-.3-.3-.4s-.2-.3-.2-.6V12.7c0-.2 0-.4.2-.6 0-.2.2-.3.3-.4.2-.2.3-.3.4-.3s.3-.2.6-.2h39.8"
                />
                <path
                    className="cls-5"
                    d="M195.9 20.2c.4-.5.7-1.2.6-1.9-.6 0-1.3.4-1.7.8-.3.4-.7 1.1-.6 1.8.7 0 1.3-.3 1.7-.8m.6 1c-.9 0-1.8.5-2.2.5s-1.2-.5-1.9-.5-1.9.6-2.4 1.4c-1 1.8-.3 4.4.8 5.8.5.7 1.1 1.5 1.9 1.4.8 0 1-.5 1.9-.5s1.2.5 1.9.4c.8 0 1.3-.7 1.8-1.4.6-.8.8-1.6.8-1.6s-1.5-.6-1.5-2.4 1.2-2.2 1.3-2.2c-.7-1-1.8-1.1-2.1-1.2m7.9-1.7c2 0 3.5 1.4 3.5 3.4s-1.4 3.5-3.5 3.5h-2.3v3.6h-1.7V19h4Zm-2.3 5.6h1.9c1.4 0 2.3-.8 2.3-2.1s-.8-2.1-2.3-2.1h-1.9v4.2Zm6.3 2.8c0-1.3 1-2.2 2.9-2.3l2.1-.2v-.6c0-.8-.6-1.3-1.6-1.3s-1.5.4-1.7 1.1h-1.5c0-1.4 1.3-2.4 3.2-2.4s3.1 1 3.1 2.5v5.3h-1.5v-1.3c-.4.8-1.4 1.4-2.4 1.4-1.5 0-2.6-.9-2.6-2.4Zm4.9-.7v-.6h-1.9c-.9.2-1.5.6-1.5 1.3s.6 1.1 1.4 1.1 2-.8 2-1.8Zm3.1 5.8v-1.3h.5c.8 0 1.2-.3 1.3-1.1l.2-.5-2.8-7.7h1.7l1.9 6.3 1.9-6.3h1.7l-2.9 8.2c-.7 1.9-1.4 2.5-3 2.5h-.7.2Z"
                />
            </g>
            <g
                data-name="G PAY"
                id="G_PAY"
            >
                <path
                    className="cls-12"
                    d="M284.9 9.8h-26.7c-8.3 0-15.1 6.8-15.1 15.1S249.9 40 258.2 40h26.7c8.3 0 15.1-6.8 15.1-15.1s-6.8-15.1-15.1-15.1Z"
                    id="Base"
                />
                <path
                    className="cls-11"
                    d="M284.9 11.1c1.9 0 3.7.3 5.4 1.1 1.7.7 3.1 1.7 4.5 3 1.3 1.3 2.3 2.8 3 4.5.8 1.7 1.1 3.5 1.1 5.4s-.3 3.7-1.1 5.4c-.7 1.7-1.7 3.1-3 4.5-1.3 1.3-2.8 2.3-4.5 3-1.7.8-3.5 1.1-5.4 1.1h-26.7c-1.9 0-3.7-.3-5.4-1.1-1.7-.7-3.1-1.7-4.5-3-1.3-1.3-2.3-2.8-3-4.5s-1.1-3.5-1.1-5.4.3-3.7 1.1-5.4c.7-1.7 1.7-3.1 3-4.5 1.3-1.3 2.8-2.3 4.5-3s3.5-1.1 5.4-1.1h26.7m0-1.3h-26.7c-8.3 0-15.1 6.8-15.1 15.1S249.9 40 258.2 40h26.7c8.3 0 15.1-6.8 15.1-15.1s-6.8-15.1-15.1-15.1Z"
                    id="Outline"
                />
                <path
                    className="cls-11"
                    d="M270.2 26.1v4.6h-1.4V19.3h3.9c1 0 1.8.3 2.5 1s1 1.4 1 2.4-.3 1.8-1 2.4c-.7.7-1.5.9-2.5.9h-2.4Zm0-5.3v4h2.4c.6 0 1.1-.2 1.4-.6.4-.4.6-.8.6-1.4s-.2-1-.6-1.3c-.4-.4-.8-.6-1.4-.6h-2.4Z"
                    data-name="Letter p"
                    id="Letter_p"
                />
                <path
                    className="cls-11"
                    d="M280 22.7c1.1 0 1.9.3 2.5.8s.9 1.3.9 2.4v4.8h-1.3v-1.1c-.6.8-1.4 1.3-2.4 1.3s-1.6-.3-2.1-.8-.8-1.1-.8-1.9.3-1.4.9-1.9 1.4-.7 2.4-.7 1.6.2 2.1.5v-.3c0-.5-.2-.9-.6-1.3s-.8-.5-1.4-.5c-.8 0-1.4.3-1.9 1l-1.3-.8c.7-1 1.8-1.5 3.1-1.5Zm-1.9 5.5c0 .4.2.7.5.9s.7.4 1.1.4 1.2-.3 1.7-.7.8-1 .8-1.6c-.4-.3-1.1-.5-1.9-.5s-1.1.2-1.5.4-.6.7-.6 1.1Z"
                    data-name="Letter a"
                    id="Letter_a"
                />
                <path
                    className="cls-11"
                    d="m291.4 22.9-4.9 11.2H285l1.8-3.9-3.2-7.2h1.6l2.3 5.6 2.3-5.6h1.7Z"
                    data-name="Letter y"
                    id="Letter_y"
                />
                <path
                    d="M264.5 25.1v-1.3h-6.1v2.5h3.5c-.2.8-.6 1.5-1.3 2v1.6h2.1c1.3-1.1 1.9-2.8 1.9-4.8h-.2Z"
                    data-name="Blue 500"
                    id="Blue_500"
                    style={{
                        fill: '#4285f4',
                        strokeWidth: 0,
                    }}
                />
                <path
                    d="M260.5 28.3c-.6.4-1.3.6-2.2.6-1.7 0-3.1-1.2-3.6-2.7h-2.2v1.7c1.1 2.2 3.4 3.6 5.8 3.5 1.8 0 3.2-.6 4.3-1.6l-2.1-1.6Z"
                    data-name="Green 500"
                    id="Green_500"
                    style={{
                        fill: '#34a853',
                        strokeWidth: 0,
                    }}
                />
                <path
                    d="M254.4 25c0-.4 0-.8.2-1.3V22h-2.2c-.4.8-.7 1.9-.7 2.9s.3 2 .7 2.9l2.2-1.7c-.2-.4-.2-.8-.2-1.3Z"
                    data-name="Yellow 500"
                    id="Yellow_500"
                    style={{
                        fill: '#fabb05',
                        strokeWidth: 0,
                    }}
                />
                <path
                    d="M258.3 21.1c.9 0 1.9.3 2.5 1l1.9-1.9c-1.1-1-2.6-1.7-4.4-1.7s-4.7 1.3-5.8 3.6l2.2 1.7c.5-1.5 1.9-2.7 3.6-2.7Z"
                    data-name="Red 500"
                    id="Red_500"
                    style={{
                        fill: '#e94235',
                        strokeWidth: 0,
                    }}
                />
            </g>
        </SvgIcon>
    )
}
export default PaymentMethodsIcon
