import { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useSelector } from 'react-redux'
import { CloseRounded } from '@mui/icons-material'
import {
    Button,
    Dialog,
    DialogActions,
    DialogContent,
    DialogTitle,
    FormControl,
    FormHelperText,
    IconButton,
    InputLabel,
    MenuItem,
    Select,
} from '@mui/material'
import Typography from '@mui/material/Typography'
import { useSnackbar } from 'notistack'
import PropTypes from 'prop-types'

import { useUpdateOrganisationUserMutation } from '@/app/services/organisationUsers'
import { selectOrganisationId } from '@/app/slices/organisationSlice'

const ChangeUserRole = ({ onClose, onUpdateUserRole, open = false, user }) => {
    const { t } = useTranslation()
    const { enqueueSnackbar } = useSnackbar()

    const userRoles = [
        { name: t('User'), value: 'user' },
        { name: t('Administrator'), value: 'administrator' },
    ]

    const helperTextMap = {
        user: t('Create quotes, process drawings and download outputs'),
        administrator: t(
            'Manage materials and users. Create quotes, process drawings and download outputs. The Administrator (Owner) can also manage subscriptions.'
        ),
    }

    const organisationId = useSelector(selectOrganisationId)
    const [updateUserRole, { isLoading }] = useUpdateOrganisationUserMutation()

    const [userRole, setUserRole] = useState()

    useEffect(() => {
        if (user) {
            setUserRole(user.role.toLowerCase())
        }
    }, [user])

    const handleUpdateButtonClick = async () => {
        try {
            await updateUserRole({
                organisationId,
                userId: user.userId,
                role: userRole,
            })

            onUpdateUserRole()
            enqueueSnackbar(t('User role updated'), {
                variant: 'success',
            })
        } catch (error) {
            console.error(error)
            enqueueSnackbar(t('Error updating user role'), {
                variant: 'error',
            })
        }
    }

    const handleChangeUserRole = (event) => {
        setUserRole(event.target.value)
    }

    const closeDialog = () => {
        onClose()
    }

    return (
        <Dialog
            maxWidth="xs"
            open={open}
            sx={{
                '& .MuiDialog-paper': {
                    width: '100%',
                    maxWidth: 380,
                    borderRadius: 2,
                    p: 2,
                },
            }}
            fullWidth
            onClose={closeDialog}
        >
            <DialogTitle
                alignItems="center"
                display="flex"
                justifyContent="space-between"
                sx={{ p: 2 }}
            >
                <Typography variant="h6">{t('Change user role')}</Typography>
                <IconButton
                    size="small"
                    onClick={closeDialog}
                >
                    <CloseRounded fontSize="small" />
                </IconButton>
            </DialogTitle>

            <DialogContent sx={{ p: 2 }}>
                <Typography
                    mb={2}
                    variant="strong1"
                >{`${user?.firstName ?? ''} ${user?.lastName ?? ''}`}</Typography>

                <FormControl fullWidth>
                    <InputLabel data-testid="material-type-label">{t('Role')} *</InputLabel>
                    <Select
                        label={t('Role')}
                        value={userRole}
                        variant="standard"
                        fullWidth
                        required
                        onChange={handleChangeUserRole}
                    >
                        {userRoles.map((role) => (
                            <MenuItem
                                key={role.value}
                                value={role.value}
                            >
                                {role.name}
                            </MenuItem>
                        ))}
                    </Select>
                    <FormHelperText>{helperTextMap[userRole]}</FormHelperText>
                </FormControl>
            </DialogContent>

            <DialogActions sx={{ p: 2 }}>
                <Button
                    color="secondary"
                    disabled={isLoading}
                    key="cancel"
                    variant="outlined"
                    onClick={closeDialog}
                >
                    {t('Cancel')}
                </Button>
                <Button
                    color="primary"
                    disabled={userRole === user?.role || isLoading}
                    key="changeRole"
                    variant="contained"
                    onClick={handleUpdateButtonClick}
                >
                    {isLoading ? t('Changing role...') : t('Change')}
                </Button>
            </DialogActions>
        </Dialog>
    )
}

ChangeUserRole.propTypes = {
    onClose: PropTypes.func.isRequired,
    onUpdateUserRole: PropTypes.func.isRequired,
    open: PropTypes.bool,
    user: PropTypes.object,
}

export default ChangeUserRole
