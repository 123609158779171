import { Fragment, useCallback, useEffect, useRef, useState } from 'react'
import { useTranslation } from 'react-i18next'
import {
    Box,
    Checkbox,
    CircularProgress,
    darken,
    lighten,
    Table as MuiTable,
    TableBody,
    TableContainer,
    TablePagination,
    Tooltip,
    Typography,
} from '@mui/material'
import _ from 'lodash'
import PropTypes from 'prop-types'

import { PARTS_PER_PAGE_OPTIONS, pascalToKebabCase, pascalToSentenceCase } from '@/common/utils'

import TableActionsCell from './TableActionsCell/TableActionsCell'
import TableCell from './TableCell/TableCell'
import TableEditComponent from './TableEditComponent/TableEditComponent'
import TableHead from './TableHead/TableHead'
import TableRow from './TableRow/TableRow'
import TableToolbar from './TableToolbar/TableToolbar'

export function getCellAlignmentForColumn(column) {
    if (!column) {
        return 'inherit'
    }

    if (column.align) {
        return column.align
    }

    switch (column.type) {
        case 'numeric':
        case 'datetime':
        case 'currency':
            return 'right'
        case 'boolean':
            return 'center'
        case 'text':
            return 'left'
        default:
            return 'inherit'
    }
}

const classes = {
    root: {
        width: '100%',
    },
    tableFooter: {
        background: 'background.default',
        overflow: 'hidden',
    },
    tableContainerRoot: {
        overflowX: 'hidden',
        flexGrow: 1,
    },
    inputRoot: {
        width: '100%',
    },
    noDataCell: {
        textAlign: 'center',
    },
    noDataText: (theme) =>
        theme.palette.mode === 'light'
            ? {
                  color: lighten(theme.palette.text.primary, 0.25),
              }
            : {
                  color: darken(theme.palette.text.primary, 0.25),
              },
    editableTableCell: {
        cursor: 'pointer',
    },
    tableCellColumnBorders: {
        border: 'solid 1px',
        borderBottom: 'none',
        borderTop: 'none',
    },
    tableCellFullBorders: {
        border: 'solid 1px',
    },
    tableCellNoBorder: {
        border: 'none',
    },
    toolbar: {
        zIndex: 1000,
        backgroundColor: 'background.default',
    },
}

const Table = ({
    actionColumnPosition = 'end',
    allowMultiRowDelete = true,
    allowMultiRowSelect = true,
    borderStyle = 'rows',
    className,
    columns = [],
    data = [],
    editMode = 'row',
    footerRows,
    hideEmptyTable = false,
    hideToolbar = false,
    isLoading = false,
    loadingComponent = <CircularProgress />,
    locale,
    nestedData = [],
    noDataText,
    onPageChange,
    onRowClicked,
    onRowDeleted,
    onRowUpdated,
    onRowsDeleted,
    onRowsPerPageChange,
    onSelectionChanged,
    orderColumn = '',
    orderDirection = 'asc',
    page = 0,
    rowActions = [],
    rowsPerPage = 10,
    rowsPerPageLabel = 'Rows per page',
    selectedData,
    selectionComponent,
    showPageCount = true,
    showPagination = true,
    showRowDeleteAction = false,
    showRowsPerPage = true,
    showSelectionComponent = true,
    showToolbarDivider = false,
    stickyFooter = false,
    stickyHeader = false,
    stickyToolbar = false,
    tableContainerProps,
    title = '',
    titleTransform = 'default',
    toolbarActionPanelContent,
}) => {
    const { t } = useTranslation()

    const rootRef = useRef()
    const toolbarRef = useRef()
    const [cellBeingUpdated, setCellBeingUpdated] = useState({
        rowIndex: -1,
        columnIndex: -1,
    })
    const [order, setOrder] = useState(orderDirection)
    const [orderBy, setOrderBy] = useState(orderColumn)
    const [rows, setRows] = useState([])
    const [rowsOld, setRowsOld] = useState([])
    const [selected, setSelected] = useState([])
    const [editingRow, setEditingRow] = useState(null)
    const [currentPage, setCurrentPage] = useState(page)
    const [currentRowsPerPage, setCurrentRowsPerPage] = useState(showPagination ? rowsPerPage : rows.length)

    useEffect(() => {
        if (!showPagination) {
            setCurrentRowsPerPage(rows.length)
        } else {
            setCurrentRowsPerPage(rowsPerPage)
        }
    }, [showPagination, rows, rowsPerPage])

    const descendingComparator = useCallback((a, b, orderBy) => {
        const first = a[orderBy]
        const next = b[orderBy]

        switch (typeof first) {
            case 'number':
                if (next < first) return -1
                if (next > first) return 1
                break
            case 'string':
                return next?.localeCompare(first, undefined, { sensitivity: 'base' })
            default:
                return 0
        }
        return 0
    }, [])

    const getComparator = useCallback(
        (order, orderBy) => {
            return order === 'desc'
                ? (a, b) => descendingComparator(a, b, orderBy)
                : (a, b) => -descendingComparator(a, b, orderBy)
        },
        [descendingComparator]
    )

    const stableSort = useCallback((array, comparator) => {
        const stabilizedThis = array.map((el, index) => [el, index])
        stabilizedThis.sort((a, b) => {
            const order = comparator(a[0], b[0])
            if (order !== 0) return order
            return a[1] - b[1]
        })
        return stabilizedThis.map((el) => el[0])
    }, [])

    useEffect(() => {
        setRows(_.cloneDeep(data))
        setRowsOld(_.cloneDeep(data))
    }, [data])

    useEffect(() => {
        setCurrentPage(page)
    }, [page])

    useEffect(() => {
        setCurrentRowsPerPage(showPagination ? rowsPerPage : rows.length)
    }, [showPagination, rowsPerPage, rows])

    useEffect(() => {
        if (selectedData) {
            setSelected(selectedData)
        }
    }, [selectedData])

    useEffect(() => {
        setRows(stableSort(data, getComparator(order, orderBy)))
    }, [order, orderBy, data, stableSort, getComparator])

    const revertRowState = () => {
        setRows(stableSort(_.cloneDeep(rowsOld), getComparator(order, orderBy)))
    }

    const saveRowState = () => {
        setRowsOld(_.cloneDeep(rows))
    }

    const selectedRowIndex = (row) =>
        selected.findIndex((element) => {
            return _.isEqual(row, element)
        })
    const isSelected = (row) => selectedRowIndex(row) !== -1

    const handleRequestSort = (_event, property) => {
        const isAsc = orderBy === property && order === 'asc'
        setOrder(isAsc ? 'desc' : 'asc')
        setOrderBy(property)
    }

    const handleSelectionChanged = (newSelection) => {
        setSelected(newSelection)

        if (typeof onSelectionChanged === 'function') {
            onSelectionChanged(newSelection)
        }
    }

    const handleSelectAllClick = (event) => {
        if (event.target.checked) {
            const newSelecteds = rows
            handleSelectionChanged(newSelecteds)
            return
        }
        handleSelectionChanged([])
    }

    const handleSelectClick = (event, row) => {
        event.stopPropagation()

        const selectedIndex = selectedRowIndex(row)
        let newSelected = []

        if (selectedIndex === -1) {
            newSelected = newSelected.concat(selected, row)
        } else if (selectedIndex === 0) {
            newSelected = newSelected.concat(selected.slice(1))
        } else if (selectedIndex === selected.length - 1) {
            newSelected = newSelected.concat(selected.slice(0, -1))
        } else if (selectedIndex > 0) {
            newSelected = newSelected.concat(selected.slice(0, selectedIndex), selected.slice(selectedIndex + 1))
        }

        handleSelectionChanged(newSelected)
    }

    const handleRowClick = (event, row) => {
        if (allowMultiRowSelect) {
            handleSelectionChanged([row])
        }
        if (typeof onRowClicked === 'function') {
            onRowClicked(event, row)
        }
    }

    const handleCellClick = (cellCoordinates) => {
        setCellBeingUpdated(cellCoordinates)
    }

    const handleCellValueChanged = (value, cellCoordinates) => {
        const newRows = [...rows]

        const columnId = columns[cellCoordinates.columnIndex].id
        newRows[cellCoordinates.rowIndex][columnId] = value

        setRows(newRows)
    }

    const handleCellInputBlur = (cellCoordinates) => {
        const columnId = columns[cellCoordinates.columnIndex].id
        const oldRow = rowsOld[cellCoordinates.rowIndex]
        const newRow = rows[cellCoordinates.rowIndex]

        if (editMode === 'cell') {
            if (typeof onRowUpdated === 'function') {
                if (!_.isEqual(oldRow[columnId], newRow[columnId])) {
                    onRowUpdated(newRow)
                }
            }

            saveRowState()
        }

        setCellBeingUpdated({ rowIndex: -1, columnIndex: -1 })
    }

    const handleEditButonClicked = (_event, row) => {
        setEditingRow(row)
    }

    const handleEditSaveButtonClicked = (_event, row, _rowIndex) => {
        if (typeof onRowUpdated === 'function') {
            onRowUpdated(row)
        }

        saveRowState()
        setEditingRow(null)
    }

    const handleEditCancelButtonClicked = (_event, _row, _rowIndex) => {
        revertRowState()
        setEditingRow(null)
    }

    const handleRowDeleteClicked = (_event, row, _rowIndex) => {
        if (typeof onRowDeleted === 'function') {
            onRowDeleted(row)
        }
    }

    const handleMultiRowDeleteClicked = () => {
        if (typeof onRowsDeleted === 'function') {
            onRowsDeleted(selected)
        }
    }

    const handleChangePage = (_, newPage) => {
        setCurrentPage(newPage)

        if (typeof onPageChange === 'function') {
            onPageChange(newPage)
        }
    }

    const handleChangeRowsPerPage = (event) => {
        const newRowsPerPage = parseInt(event.target.value, 10)
        setCurrentRowsPerPage(newRowsPerPage)
        setCurrentPage(0)

        if (typeof onRowsPerPageChange === 'function') {
            onRowsPerPageChange(newRowsPerPage)
        }
    }

    const customRowActionsSpecified = rowActions && rowActions.length > 0
    const shouldRenderActionsColumn = showRowDeleteAction || editMode === 'row' || customRowActionsSpecified

    const tableCellStyle = Object.assign(
        {},
        borderStyle === 'none' ? classes.tableCellNoBorder : {},
        borderStyle === 'columns' ? classes.tableCellColumnBorders : {},
        borderStyle === 'cells' ? classes.tableCellFullBorders : {}
    )

    const renderActionCells = (row, rowIndex) => {
        const isEditingThisRow = _.isEqual(editingRow, row)

        return (
            <TableActionsCell
                actions={rowActions}
                className={tableCellStyle}
                hidden={!shouldRenderActionsColumn}
                isEditing={isEditingThisRow}
                row={row}
                rowIndex={rowIndex}
                showDeleteAction={showRowDeleteAction}
                showEditAction={editMode === 'row'}
                onDeleteClicked={handleRowDeleteClicked}
                onEditCancelClicked={handleEditCancelButtonClicked}
                onEditClicked={handleEditButonClicked}
                onEditSaveClicked={handleEditSaveButtonClicked}
            />
        )
    }

    const renderEditComponent = (cellCoordinates) => {
        const column = columns[cellCoordinates.columnIndex]
        const row = rows[cellCoordinates.rowIndex]
        const readOnly = typeof row.partLibraryEntryId !== 'undefined' ? true : false
        const EditComponent = column.editComponent || TableEditComponent

        return (
            <EditComponent
                allowNegativeValues={column.allowNegativeValues}
                autoFocus={true}
                inputProps={{ style: { textAlign: getCellAlignmentForColumn(column) } }}
                locale={locale}
                readOnly={readOnly}
                rowData={row}
                type={column.type}
                value={row[column.id]}
                onBlur={() => {
                    handleCellInputBlur(cellCoordinates)
                }}
                onChange={(value) => {
                    handleCellValueChanged(value, cellCoordinates)
                }}
                onClick={() => handleCellClick(cellCoordinates)}
            />
        )
    }

    const getToolbarStyle = () => {
        if (!stickyToolbar) {
            return
        }

        if (!rootRef || !rootRef.current) {
            return
        }

        const top = 0

        return {
            position: 'sticky',
            top: top,
        }
    }

    const getTableHeadCellStyle = () => {
        if (!stickyHeader) {
            return
        }

        return {
            position: 'sticky',
        }
    }

    const getFooterStyle = () => {
        if (stickyFooter) {
            return {
                width: '100%',
                opacity: 1,
            }
        }
    }

    const tableIsEmpty = !rows || rows.length === 0

    const containerStyle = Object.assign({}, classes.root, className)

    return (
        <Box
            ref={rootRef}
            sx={containerStyle}
        >
            <Box
                ref={toolbarRef}
                style={getToolbarStyle()}
                sx={classes.toolbar}
            >
                {!hideToolbar ? <TableToolbar
                        actionPanel={toolbarActionPanelContent}
                        allowMultiRowDelete={allowMultiRowDelete}
                        isLoading={isLoading}
                        loadingComponent={loadingComponent}
                        numberOfRowsSelected={selected.length}
                        selectionComponent={selectionComponent}
                        showDivider={showToolbarDivider}
                        showSelectionComponent={showSelectionComponent}
                        title={title}
                        titleTransform={titleTransform}
                        onDelete={handleMultiRowDeleteClicked}
                                /> : null}
            </Box>
            {!(tableIsEmpty && hideEmptyTable) ? <Box sx={tableContainerProps ? tableContainerProps.sx : null}>
                    <TableContainer sx={classes.tableContainerRoot}>
                        <MuiTable
                            style={{
                                tableLayout: 'fixed',
                                marginRight: ['quote', 'estimate'].includes(title.toLowerCase()) ? '26px' : 0,
                            }}
                        >
                            <TableHead
                                actionColumnPosition={actionColumnPosition}
                                allowMultiRowSelect={allowMultiRowSelect}
                                borderStyle={borderStyle}
                                cellStyle={getTableHeadCellStyle()}
                                columns={columns}
                                numberOfRowsSelected={selected.length}
                                order={order}
                                orderBy={orderBy}
                                rowCount={rows.length}
                                showActionsColumn={shouldRenderActionsColumn}
                                onRequestSort={handleRequestSort}
                                onSelectAllClick={handleSelectAllClick}
                            />
                            <TableBody>
                                {!tableIsEmpty ? rows
                                        .slice(
                                            currentPage * currentRowsPerPage,
                                            currentPage * currentRowsPerPage + currentRowsPerPage
                                        )
                                        .map((row, rowIndex) => {
                                            const isItemSelected = isSelected(row)
                                            const nestedDataRow =
                                                nestedData[currentPage * currentRowsPerPage + rowIndex]

                                            return (
                                                <Fragment key={rowIndex}>
                                                    <TableRow
                                                        key={rowIndex}
                                                        selected={isItemSelected}
                                                        hover
                                                        onClick={(event) => handleRowClick(event, row)}
                                                    >
                                                        {allowMultiRowSelect ? <TableCell padding="none">
                                                                <Checkbox
                                                                    checked={isItemSelected}
                                                                    onClick={(event) => handleSelectClick(event, row)}
                                                                />
                                                            </TableCell> : null}
                                                        {actionColumnPosition === 'start' ? renderActionCells(row, rowIndex) : null}
                                                        {columns.map((column, columnIndex) => {
                                                            const cellCoordinates = {
                                                                rowIndex: currentPage * currentRowsPerPage + rowIndex,
                                                                columnIndex: columnIndex,
                                                            }

                                                            const isEditingCell =
                                                                _.isEqual(cellCoordinates, cellBeingUpdated) ||
                                                                _.isEqual(row, editingRow)
                                                            const cellEditEnabled =
                                                                column.editable && editMode === 'cell'

                                                            const cellRootStyle = Object.assign(
                                                                {},
                                                                tableCellStyle,
                                                                cellEditEnabled ? classes.editableTableCell : {}
                                                            )

                                                            return (
                                                                !column.hidden && (
                                                                    <TableCell
                                                                        align={getCellAlignmentForColumn(column)}
                                                                        key={columnIndex}
                                                                        style={column.cellStyle}
                                                                        sx={cellRootStyle}
                                                                        onClick={() =>
                                                                            cellEditEnabled && !isEditingCell
                                                                                ? handleCellClick(cellCoordinates)
                                                                                : undefined
                                                                        }
                                                                    >
                                                                        <Tooltip
                                                                            title={
                                                                                column.tooltip
                                                                                    ? column.tooltip(row)
                                                                                    : ''
                                                                            }
                                                                        >
                                                                            <div>
                                                                                {column.editable
                                                                                    ? renderEditComponent(
                                                                                          cellCoordinates
                                                                                      )
                                                                                    : column.render
                                                                                      ? column.render(row)
                                                                                      : row[column.id]}
                                                                            </div>
                                                                        </Tooltip>
                                                                    </TableCell>
                                                                )
                                                            )
                                                        })}
                                                        {actionColumnPosition === 'end' ? renderActionCells(row, rowIndex) : null}
                                                    </TableRow>
                                                    {!!nestedDataRow && !!Object.keys(nestedDataRow).length > 0
                                                        ? Object.keys(nestedDataRow).reduce(
                                                              (result, issueTypeKey, index) => {
                                                                  issueTypeKey &&
                                                                      result.push(
                                                                          <TableRow
                                                                              className={
                                                                                  'drawing-issue ' +
                                                                                  pascalToKebabCase(issueTypeKey)
                                                                              }
                                                                              key={'row ' + index}
                                                                              selected={isItemSelected}
                                                                              onClick={(event) =>
                                                                                  handleRowClick(event, row)
                                                                              }
                                                                          >
                                                                              {allowMultiRowSelect ? <TableCell
                                                                                      key={index + ' padding'}
                                                                                                     ></TableCell> : null}
                                                                              <TableCell
                                                                                  align="inherit"
                                                                                  colSpan={3}
                                                                                  key={
                                                                                      index +
                                                                                      ' ' +
                                                                                      pascalToKebabCase(issueTypeKey)
                                                                                  }
                                                                              >
                                                                                  {nestedDataRow[issueTypeKey] ||
                                                                                      pascalToSentenceCase(
                                                                                          issueTypeKey
                                                                                      )}
                                                                              </TableCell>
                                                                          </TableRow>
                                                                      )

                                                                  return result
                                                              },
                                                              []
                                                          )
                                                        : undefined}
                                                </Fragment>
                                            )
                                        }) : null}
                                {tableIsEmpty && !isLoading ? <TableRow>
                                        <TableCell
                                            colSpan={
                                                columns.length +
                                                (rowActions.length > 0 ? 1 : 0) +
                                                (allowMultiRowSelect || showRowDeleteAction || editMode === 'row'
                                                    ? 1
                                                    : 0)
                                            }
                                            sx={classes.noDataCell}
                                        >
                                            <Typography
                                                sx={classes.noDataText}
                                                variant="h5"
                                            >
                                                {noDataText ?? t('No data')}
                                            </Typography>
                                        </TableCell>
                                    </TableRow> : null}
                                {footerRows}
                            </TableBody>
                        </MuiTable>
                    </TableContainer>
                    {showPagination ? <TablePagination
                            component="div"
                            count={rows.length}
                            labelDisplayedRows={({ count, from, to }) =>
                                showPageCount ? `${from}-${to} ${t('of')} ${count}` : ''
                            }
                            labelRowsPerPage={rowsPerPageLabel}
                            page={currentPage}
                            rowsPerPage={currentRowsPerPage}
                            rowsPerPageOptions={showRowsPerPage ? PARTS_PER_PAGE_OPTIONS : []}
                            style={getFooterStyle()}
                            sx={classes.tableFooter}
                            onPageChange={handleChangePage}
                            onRowsPerPageChange={handleChangeRowsPerPage}
                                      /> : null}
                </Box> : null}
        </Box>
    )
}

Table.propTypes = {
    actionColumnPosition: PropTypes.oneOf(['end', 'start']),
    allowMultiRowDelete: PropTypes.bool,
    allowMultiRowSelect: PropTypes.bool,
    borderStyle: PropTypes.oneOf(['none', 'columns', 'rows', 'cells']),
    className: PropTypes.object,
    columns: PropTypes.arrayOf(
        PropTypes.shape({
            align: PropTypes.oneOf(['center', 'inherit', 'justify', 'left', 'right']),
            cellStyle: PropTypes.object,
            editable: PropTypes.bool,
            editComponent: PropTypes.any,
            emptyValue: PropTypes.object,
            hidden: PropTypes.bool,
            id: PropTypes.string,
            label: PropTypes.node,
            sortable: PropTypes.bool,
            type: PropTypes.oneOf(['text', 'numeric', 'currency', 'datetime', 'select', 'boolean']),
        })
    ),
    data: PropTypes.arrayOf(PropTypes.object),
    editMode: PropTypes.oneOf(['none', 'row', 'cell']),
    footerRows: PropTypes.node,
    hideEmptyTable: PropTypes.bool,
    hideToolbar: PropTypes.bool,
    isLoading: PropTypes.bool,
    loadingComponent: PropTypes.node,
    locale: PropTypes.string,
    nestedData: PropTypes.arrayOf(PropTypes.object),
    noDataText: PropTypes.string,
    orderColumn: PropTypes.string,
    orderDirection: PropTypes.oneOf(['asc', 'desc']),
    page: PropTypes.number,
    rowActions: PropTypes.arrayOf(PropTypes.object),
    rowsPerPage: PropTypes.number,
    rowsPerPageLabel: PropTypes.string,
    selectedData: PropTypes.arrayOf(PropTypes.object),
    selectionComponent: PropTypes.node,
    showPageCount: PropTypes.bool,
    showPagination: PropTypes.bool,
    showRowDeleteAction: PropTypes.bool,
    showRowsPerPage: PropTypes.bool,
    showSelectionComponent: PropTypes.bool,
    showToolbarDivider: PropTypes.bool,
    stickyFooter: PropTypes.bool,
    stickyHeader: PropTypes.bool,
    stickyToolbar: PropTypes.bool,
    tableContainerProps: PropTypes.object,
    title: PropTypes.string,
    titleTransform: PropTypes.oneOf(['default', 'uppercase', 'lowercase']),
    toolbarActionPanelContent: PropTypes.node,
    onPageChange: PropTypes.func,
    onRowClicked: PropTypes.func,
    onRowDeleted: PropTypes.func,
    onRowsDeleted: PropTypes.func,
    onRowsPerPageChange: PropTypes.func,
    onRowUpdated: PropTypes.func,
    onSelectionChanged: PropTypes.func,
}

export default Table
