import { useEffect, useMemo, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { FormControl, InputLabel, MenuItem, Select } from '@mui/material'
import PropTypes from 'prop-types'

const setupModeOptions = [
    { name: 'Flat', value: 'Flat' },
    { name: 'Rotary', value: 'Rotary' },
]

const CuttingTechTypeSelect = ({
    classes,
    disabled = false,
    fullWidth = true,
    includeEmptyOption = false,
    includeNoneOption = true,
    inputProps,
    label,
    onChange,
    sx,
    value = 'flat',
}) => {
    const { t } = useTranslation()

    const options = useMemo(() => {
        const options = setupModeOptions.map((o) => ({
            name: t(o.name),
            value: o.value,
        }))

        if (includeNoneOption) {
            options.unshift({ name: 'None', value: 'None' })
        }

        if (includeEmptyOption) {
            options.unshift({ name: '-', value: '' })
        }

        return options
    }, [includeEmptyOption, includeNoneOption])

    const [selectedValue, setSelectedValue] = useState('')

    useEffect(() => {
        setSelectedValue(value)
    }, [value])

    const handleChange = (event) => {
        const newSelectedValue = event.target.value.toString()

        setSelectedValue(newSelectedValue)

        if (typeof onChange === 'function') {
            onChange(newSelectedValue)
        }
    }

    return (
        <FormControl
            disabled={disabled}
            fullWidth={fullWidth}
        >
            <InputLabel htmlFor="cutting-tech-type-select">{label}</InputLabel>
            <Select
                classes={classes}
                data-testid="cutting-tech-type-wrapper"
                fullWidth={fullWidth}
                inputProps={{ ...inputProps, id: 'cutting-tech-type-select' }}
                label={label}
                readOnly={disabled}
                sx={sx}
                type="select"
                value={selectedValue}
                onChange={handleChange}
            >
                {options.map((mode) => {
                    return (
                        <MenuItem
                            key={mode.value}
                            value={mode.value}
                        >
                            {t(mode.name)}
                        </MenuItem>
                    )
                })}
            </Select>
        </FormControl>
    )
}

CuttingTechTypeSelect.propTypes = {
    classes: PropTypes.object,
    disabled: PropTypes.bool,
    fullWidth: PropTypes.bool,
    includeEmptyOption: PropTypes.bool,
    includeNoneOption: PropTypes.bool,
    inputProps: PropTypes.object,
    label: PropTypes.node,
    sx: PropTypes.oneOfType([
        PropTypes.arrayOf(PropTypes.oneOfType([PropTypes.func, PropTypes.object, PropTypes.bool])),
        PropTypes.func,
        PropTypes.object,
    ]),
    value: PropTypes.string,
    onChange: PropTypes.func,
}

export default CuttingTechTypeSelect
