import { Box } from '@mui/material'
import PropTypes from 'prop-types'

const CircleRenderer = ({ circle, onClick, path, sx }) => {
    return (
        circle && (
            <Box
                component="circle"
                cx={circle.centrePoint.x}
                cy={circle.centrePoint.y}
                id={path.pathId}
                r={circle.radius}
                sx={sx}
                onClick={onClick}
            />
        )
    )
}

CircleRenderer.propTypes = {
    circle: PropTypes.object,
    path: PropTypes.object,

    sx: PropTypes.oneOfType([
        PropTypes.arrayOf(PropTypes.oneOfType([PropTypes.func, PropTypes.object, PropTypes.bool])),
        PropTypes.func,
        PropTypes.object,
    ]),
    onClick: PropTypes.func,
}

export default CircleRenderer
