import { forwardRef } from 'react'
import { Archive, Create, DragHandle } from '@mui/icons-material'
import IconButton from '@mui/material/IconButton'
import Typography from '@mui/material/Typography'
import { Box } from '@mui/system'
import PropTypes from 'prop-types'

const classes = {
    Overlay: {
        borderRadius: 2,
        overflow: 'hidden',
        backgroundColor: 'primary.main',

        boxShadow: 15,
        zIndex: 10,
    },

    OverlayContent: {
        px: 2,
        py: 1.5,
        display: 'flex',
        justifyContent: 'space-between',
    },

    OverlayContentLeft: {
        gap: 1.5,
    },

    OverlayContentRight: {
        gap: 6,
    },

    OverlayContentColor: {
        color: 'primary.contrastText',
    },
}

const SecondaryProcessOverlay = forwardRef(({ id, name, ...props }, ref) => {
    return (
        <Box sx={classes.Overlay}>
            <Box sx={classes.OverlayContent}>
                <Box
                    alignItems="center"
                    display="flex"
                    gap={1.5}
                    sx={classes.OverlayContentLeft}
                >
                    <IconButton
                        size="small"
                        sx={classes.OverlayContentColor}
                    >
                        <DragHandle />
                    </IconButton>

                    <Typography sx={classes.OverlayContentColor}>{name}</Typography>
                </Box>

                <Box
                    alignItems="center"
                    display="flex"
                    sx={classes.OverlayContentRight}
                >
                    <Box
                        alignItems="center"
                        display="flex"
                        gap={0.5}
                    >
                        <IconButton sx={classes.OverlayContentColor}>
                            <Create />
                        </IconButton>

                        <Archive sx={classes.OverlayContentColor} />
                    </Box>
                </Box>
            </Box>
        </Box>
    )
})

SecondaryProcessOverlay.displayName = 'SecondaryProcessOverlay'

SecondaryProcessOverlay.propTypes = {
    id: PropTypes.string.isRequired,
    name: PropTypes.string.isRequired,
}

export default SecondaryProcessOverlay
