import { useEffect, useMemo, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useSelector } from 'react-redux'
import { useParams } from 'react-router-dom'
import { Tune } from '@mui/icons-material'
import { Box, FormControl, MenuItem, OutlinedInput, Select, Switch, TextField, Typography } from '@mui/material'
import PropTypes from 'prop-types'

import {
    useGetCuttingTechnologiesQuery,
    useLazyGetCuttingTechnologyMaterialsQuery,
    useLazyGetMaterialThicknessesQuery,
    useLazyGetThicknessSheetsQuery,
} from '@/app/services/cuttingTechnologies'
import { selectQuoteItems } from '@/app/services/quoteItems'
import { selectLocale } from '@/app/slices/appSlice'
import { selectOrganisationId, selectPaidFeatures, selectUseImperialUnits } from '@/app/slices/organisationSlice'
import { selectSelectedItems } from '@/app/slices/quoteItemsSlice'
import { TbxTooltip } from '@/common/components'
import UpgradePlanLink from '@/common/components/UpgradePlanLink/UpgradePlanLink'
import { formatNumber } from '@/features/web-store/helpers/utilities'

const classes = {
    ItemDetails_Settings: {
        width: '366px',
        gap: '8px',
    },
    formControl_small: {
        width: '120px',
    },
    formControl_large: {
        width: '350px',
    },
    formControl_thickness: {
        flex: 1,
    },
    formControl_sheet: {
        flex: 2,
    },
    formControl_medium: {
        width: '220px',
    },
    formControl_toggle: {
        width: '220px',
        display: 'flex',
        flexDirection: 'row-reverse',
    },
}

const SettingsBulkEdit = ({
    itemConsumptionMode,
    itemCuttingTechnology,
    itemIsCustomerSuppliedMaterial,
    itemMaterial,
    itemQuantity,
    itemSheet,
    itemThickness,
    itemUnit,
    setItemConsumptionMode,
    setItemCuttingTechnology,
    setItemIsCustomerSuppliedMaterial,
    setItemMaterial,
    setItemQuantity,
    setItemSheet,
    setItemThickness,
    setItemUnit,
    setUserMadeSettingsChanges,
}) => {
    const { t } = useTranslation()
    const { quoteId } = useParams()

    const organisationId = useSelector(selectOrganisationId)
    const useImperialUnits = useSelector(selectUseImperialUnits)
    const locale = useSelector(selectLocale)
    const paidFeatures = useSelector(selectPaidFeatures)
    const quoteItems = useSelector((state) => selectQuoteItems(state, { organisationId, quoteId }))
    const selectedItemsArray = useSelector(selectSelectedItems)

    const [quantityError, setQuantityError] = useState(null)

    const orgHasCustomerSuppliedMaterial = useMemo(() => paidFeatures?.hasCustomerSuppliedMaterial, [paidFeatures])
    const orgHasMaterialConsumptionMode = useMemo(() => paidFeatures?.hasMaterialConsumptionModeOptions, [paidFeatures])
    const materialConsumptionModeEnabled = useMemo(
        () => itemMaterial && orgHasMaterialConsumptionMode,
        [itemMaterial, orgHasMaterialConsumptionMode]
    )

    const mode = 'flat' //TODO: Adapt this flag to depend on part profile

    const { data: cuttingTechOptionsData, isLoading: isLoadingCuttingTechs } = useGetCuttingTechnologiesQuery({
        organisationId,
        params: {
            mode: mode,
        },
    })

    const cuttingTechOptions = useMemo(() => {
        if (!cuttingTechOptionsData) return []
        return Object.values(cuttingTechOptionsData).filter((tech) => !tech?.isDeleted)
    }, [cuttingTechOptionsData])

    const [getCuttingTechnologyMaterials, { data: materialOptions, isLoading: isLoadingMaterials }] =
        useLazyGetCuttingTechnologyMaterialsQuery()

    const [getMaterialThicknesses, { data: thicknessOptions, isLoading: isLoadingThicknesses }] =
        useLazyGetMaterialThicknessesQuery()

    const [getThicknessSheets, { data: sheetOptions, isLoading: isLoadingSheets }] = useLazyGetThicknessSheetsQuery()

    const consumptionModeOptions = [
        {
            label: t(mode === 'flat' ? 'Whole Sheet' : 'Whole Length'),
            value: 'WholeSheet',
        },
        { label: t('Nest Bounds'), value: 'NestBounds' },
    ]

    const selectedSheet = useMemo(() => {
        if (!itemSheet) return null
        return sheetOptions?.find((option) => option.sheetId === itemSheet) || {}
    }, [itemSheet, sheetOptions])

    const units = useImperialUnits ? 'in' : 'mm'

    const handleQuantityChange = async (event) => {
        const value = parseInt(event.target.value, 10)
        setQuantityError(!value || isNaN(value) || value <= 0)
        setItemQuantity(value || 0)
    }

    const handleCuttingTechChange = async (event) => {
        const value = event?.target?.value
        if (Boolean(value) && itemCuttingTechnology !== value) {
            setItemCuttingTechnology(value)
            setItemMaterial('')
            setItemThickness('')
            setItemSheet('')
            setItemConsumptionMode('')
            setItemIsCustomerSuppliedMaterial(false)
            setUserMadeSettingsChanges(true)
            await getCuttingTechnologyMaterials(
                {
                    organisationId,
                    cuttingTechnologyId: value,
                },
                true
            )
        }
    }

    const handleMaterialChange = async (event) => {
        const value = event?.target?.value
        if (Boolean(value) && itemMaterial !== value) {
            setItemMaterial(value)
            setItemThickness('')
            setItemSheet('')
            setItemConsumptionMode('')
            setItemIsCustomerSuppliedMaterial(false)
            setUserMadeSettingsChanges(true)
            await getMaterialThicknesses(
                {
                    organisationId,
                    cuttingTechnologyId: itemCuttingTechnology,
                    materialId: value,
                },
                true
            )
        }
    }

    const handleThicknessChange = async (event) => {
        const value = event?.target?.value
        if (Boolean(value) && itemThickness !== value) {
            setItemThickness(value)
            setItemConsumptionMode('')
            setItemIsCustomerSuppliedMaterial(false)
            setUserMadeSettingsChanges(true)
            const data = await getThicknessSheets(
                {
                    organisationId,
                    cuttingTechnologyId: itemCuttingTechnology,
                    materialId: itemMaterial,
                    params: {
                        thickness: value,
                    },
                },
                true
            ).unwrap()

            const firstSheet = data[0]

            const { defaultMaterialConsumptionMode, isSoldByOrganisation, sheetId } = firstSheet

            const shouldUpdateSheetProps = !data.map((item) => item.sheetId).includes(itemSheet)

            if (shouldUpdateSheetProps) {
                setItemSheet(sheetId)
                setItemConsumptionMode(defaultMaterialConsumptionMode || '')
                setItemIsCustomerSuppliedMaterial(!isSoldByOrganisation)
            }
        }
    }

    const handleSheetChange = async (event) => {
        const value = event?.target?.value

        if (Boolean(value) && itemSheet !== value) {
            const { defaultMaterialConsumptionMode, isSoldByOrganisation } = selectedSheet
            setUserMadeSettingsChanges(true)
            setItemSheet(value)
            setItemConsumptionMode(defaultMaterialConsumptionMode)
            setItemIsCustomerSuppliedMaterial(!isSoldByOrganisation)
        }
    }

    const handleConsumptionModeChange = async (event) => {
        const value = event?.target?.value
        if (Boolean(value) && itemConsumptionMode !== value) {
            setUserMadeSettingsChanges(true)
            setItemConsumptionMode(value)
        }
    }

    const handleIsCustomerSuppliedMaterialChange = async (event) => {
        const value = event?.target?.checked
        setItemIsCustomerSuppliedMaterial(value)
        setUserMadeSettingsChanges(true)
    }

    const handleUnitChange = async (event) => {
        const value = event?.target?.value
        setItemUnit(value)
        setUserMadeSettingsChanges(true)
    }

    const quoteItemsFromSelection = useMemo(
        () => quoteItems.filter((item) => selectedItemsArray.includes(item.id)),
        [quoteItems, selectedItemsArray]
    )

    const allItemsHaveSameProperty = (array, property) => {
        return array.every((element) => element[property] === array[0][property])
    }

    useEffect(() => {
        if (!quoteItemsFromSelection.length) return

        if (allItemsHaveSameProperty(quoteItemsFromSelection, 'quantity')) {
            setItemQuantity(quoteItemsFromSelection[0].quantity)
        }

        if (allItemsHaveSameProperty(quoteItemsFromSelection, 'cuttingTechnologyId')) {
            setItemCuttingTechnology(quoteItemsFromSelection[0].cuttingTechnologyId)
            getCuttingTechnologyMaterials(
                {
                    organisationId,
                    cuttingTechnologyId: quoteItemsFromSelection[0].cuttingTechnologyId,
                },
                true
            )
        }

        if (allItemsHaveSameProperty(quoteItemsFromSelection, 'materialId')) {
            setItemMaterial(quoteItemsFromSelection[0].materialId)
            getMaterialThicknesses(
                {
                    organisationId,
                    cuttingTechnologyId: quoteItemsFromSelection[0].cuttingTechnologyId,
                    materialId: quoteItemsFromSelection[0].materialId,
                },
                true
            )
        }

        if (allItemsHaveSameProperty(quoteItemsFromSelection, 'thickness')) {
            setItemThickness(quoteItemsFromSelection[0].thickness)
            getThicknessSheets(
                {
                    organisationId,
                    cuttingTechnologyId: quoteItemsFromSelection[0].cuttingTechnologyId,
                    materialId: quoteItemsFromSelection[0].materialId,
                    params: {
                        thickness: quoteItemsFromSelection[0].thickness,
                    },
                },
                true
            )
        }

        if (allItemsHaveSameProperty(quoteItemsFromSelection, 'sheetId')) {
            setItemSheet(quoteItemsFromSelection[0].sheetId)
        }

        if (allItemsHaveSameProperty(quoteItemsFromSelection, 'materialConsumptionMode'))
            setItemConsumptionMode(quoteItemsFromSelection[0].materialConsumptionMode)

        if (allItemsHaveSameProperty(quoteItemsFromSelection, 'isCustomerSuppliedMaterial'))
            setItemIsCustomerSuppliedMaterial(quoteItemsFromSelection[0].isCustomerSuppliedMaterial)

        if (allItemsHaveSameProperty(quoteItemsFromSelection, 'isImperial')) {
            setItemUnit(quoteItemsFromSelection[0].isImperial ? 'in' : 'mm')
        }
    }, [quoteItemsFromSelection])

    return (
        <Box
            display="flex"
            flexDirection="column"
        >
            <Box
                alignItems="center"
                display="flex"
                flexDirection="row"
                gap={1}
                mb={2}
            >
                <Tune fontSize="small" />
                <Typography variant="strong1">{t('Properties')}</Typography>
            </Box>
            <Box
                alignItems="flex-start"
                display="flex"
                flex="0 0 auto"
                flexDirection="column"
                justifyContent="space-around"
                sx={classes.ItemDetails_Settings}
            >
                <Box
                    alignItems="center"
                    display="flex"
                    flexDirection="row"
                    justifyContent="space-between"
                    width="100%"
                >
                    <Typography
                        data-testid="bulk-edit-cutting-technology-label"
                        variant="body2"
                    >
                        {t('Cutting technology')}
                    </Typography>
                    <FormControl
                        color="primary"
                        margin="none"
                        size="small"
                        sx={classes.formControl_medium}
                        variant="outlined"
                    >
                        <Select
                            id="select-itemCuttingTechnology"
                            input={<OutlinedInput sx={{ typography: 'inputTextSmall' }} />}
                            inputProps={{ 'data-testid': 'bulk-edit-cutting-technology' }}
                            labelId="select-itemCuttingTechnology-label"
                            SelectDisplayProps={{ style: { textAlign: 'right' } }}
                            value={itemCuttingTechnology}
                            onChange={handleCuttingTechChange}
                        >
                            {!isLoadingCuttingTechs
                                ? cuttingTechOptions?.map((ct) => (
                                      <MenuItem
                                          key={ct.cuttingTechnologyId}
                                          sx={{ typography: 'inputTextSmall' }}
                                          value={ct.cuttingTechnologyId}
                                      >
                                          {ct.name}
                                      </MenuItem>
                                  ))
                                : null}
                        </Select>
                    </FormControl>
                </Box>
                <Box
                    alignItems="center"
                    display="flex"
                    flexDirection="row"
                    justifyContent="space-between"
                    width="100%"
                >
                    <Typography
                        data-testid="bulk-edit-material-label"
                        variant="body2"
                    >
                        {t('Material')}
                    </Typography>
                    <FormControl
                        color="primary"
                        margin="none"
                        size="small"
                        sx={classes.formControl_medium}
                        variant="outlined"
                    >
                        <Select
                            id="select-itemMaterial"
                            input={<OutlinedInput sx={{ typography: 'inputTextSmall' }} />}
                            inputProps={{ 'data-testid': 'bulk-edit-material' }}
                            labelId="select-itemMaterial-label"
                            SelectDisplayProps={{ style: { textAlign: 'right' } }}
                            value={itemMaterial}
                            onChange={handleMaterialChange}
                        >
                            {!isLoadingMaterials
                                ? materialOptions?.map((material) => (
                                      <MenuItem
                                          key={material.materialId}
                                          sx={{ typography: 'inputTextSmall' }}
                                          value={material.materialId}
                                      >
                                          {material.materialName}
                                      </MenuItem>
                                  ))
                                : null}
                        </Select>
                    </FormControl>
                </Box>
                <Box
                    alignItems="center"
                    display="flex"
                    flexDirection="row"
                    justifyContent="space-between"
                    width="100%"
                >
                    <Typography
                        data-testid="bulk-edit-thickness-label"
                        variant="body2"
                    >
                        {t('Thickness')}
                    </Typography>
                    <FormControl
                        color="primary"
                        disabled={!thicknessOptions?.length}
                        margin="none"
                        size="small"
                        sx={classes.formControl_medium}
                        variant="outlined"
                    >
                        <Select
                            id="select-itemThickness"
                            input={<OutlinedInput sx={{ typography: 'inputTextSmall' }} />}
                            inputProps={{ 'data-testid': 'bulk-edit-thickness' }}
                            labelId="select-itemThickness-label"
                            SelectDisplayProps={{ style: { textAlign: 'right' } }}
                            value={itemThickness}
                            onChange={handleThicknessChange}
                        >
                            {!isLoadingThicknesses
                                ? thicknessOptions?.map((thickness) => (
                                      <MenuItem
                                          key={thickness}
                                          sx={{ typography: 'inputTextSmall', justifyContent: 'flex-end' }}
                                          value={thickness}
                                      >
                                          {formatNumber(thickness, locale, useImperialUnits)} {units}
                                      </MenuItem>
                                  ))
                                : null}
                        </Select>
                    </FormControl>
                </Box>
                <Box
                    alignItems="center"
                    display="flex"
                    flexDirection="row"
                    justifyContent="space-between"
                    width="100%"
                >
                    <Typography
                        data-testid="bulk-edit-sheet-label"
                        variant="body2"
                    >
                        {t('Sheet size')}
                    </Typography>
                    <FormControl
                        color="primary"
                        disabled={!sheetOptions?.length}
                        margin="none"
                        size="small"
                        sx={classes.formControl_medium}
                        variant="outlined"
                    >
                        <Select
                            id="select-itemSheet"
                            input={<OutlinedInput sx={{ typography: 'inputTextSmall' }} />}
                            inputProps={{ 'data-testid': 'bulk-edit-sheet-size' }}
                            labelId="select-itemSheet-label"
                            SelectDisplayProps={{ style: { textAlign: 'right' } }}
                            value={itemSheet}
                            onChange={handleSheetChange}
                        >
                            {!isLoadingSheets
                                ? sheetOptions?.map((sheet) => (
                                      <MenuItem
                                          key={sheet.sheetId}
                                          sx={{ typography: 'inputTextSmall', justifyContent: 'flex-end' }}
                                          value={sheet.sheetId}
                                      >
                                          {`${formatNumber(sheet.sheetWidth, locale)} x ${formatNumber(
                                              sheet.sheetHeight,
                                              locale
                                          )} ${units}`}
                                      </MenuItem>
                                  ))
                                : null}
                        </Select>
                    </FormControl>
                </Box>
                <Box
                    alignItems="center"
                    display="flex"
                    flexDirection="row"
                    justifyContent="space-between"
                    width="100%"
                >
                    <Typography
                        data-testid="bulk-edit-consumption-mode-label"
                        variant="body2"
                    >
                        {t('Consumption mode')}
                    </Typography>
                    <FormControl
                        color="primary"
                        margin="none"
                        size="small"
                        sx={classes.formControl_medium}
                        variant="outlined"
                    >
                        <Select
                            data-testid="bulk-edit-consumption-mode"
                            disabled={!materialConsumptionModeEnabled}
                            id="select-itemConsumptionMode"
                            input={<OutlinedInput sx={{ typography: 'inputTextSmall' }} />}
                            labelId="select-itemConsumptionMode-label"
                            SelectDisplayProps={{ style: { textAlign: 'right' } }}
                            size="small"
                            value={itemConsumptionMode}
                            onChange={handleConsumptionModeChange}
                        >
                            {consumptionModeOptions.map((mode) => (
                                <MenuItem
                                    key={mode.value}
                                    sx={{ typography: 'inputTextSmall' }}
                                    value={mode.value}
                                >
                                    {mode.label}
                                </MenuItem>
                            ))}
                        </Select>
                    </FormControl>
                </Box>
                <Box
                    alignItems="center"
                    display="flex"
                    flexDirection="row"
                    justifyContent="space-between"
                    width="100%"
                >
                    <Typography
                        data-testid="bulk-edit-customer-supplied-material-label"
                        variant="body2"
                    >
                        {t('Customer supplied material')}
                    </Typography>
                    <TbxTooltip
                        leaveDelay={500}
                        title={
                            selectedSheet ? (
                                orgHasCustomerSuppliedMaterial ? (
                                    !selectedSheet.isSoldByOrganisation ? (
                                        t('Not for sale. Customer-supplied only.')
                                    ) : (
                                        ''
                                    )
                                ) : (
                                    <UpgradePlanLink />
                                )
                            ) : (
                                ''
                            )
                        }
                        arrow
                        disableFocusListener
                        disableTouchListener
                    >
                        <FormControl
                            color="primary"
                            disabled={!orgHasCustomerSuppliedMaterial || !selectedSheet?.isSoldByOrganisation}
                            margin="none"
                            size="small"
                            sx={classes.formControl_toggle}
                            variant="outlined"
                        >
                            <Switch
                                checked={itemIsCustomerSuppliedMaterial}
                                data-testid="bulk-edit-customer-supplied-material"
                                size="small"
                                onChange={handleIsCustomerSuppliedMaterialChange}
                            />
                        </FormControl>
                    </TbxTooltip>
                </Box>
                <Box
                    alignItems="center"
                    display="flex"
                    flexDirection="row"
                    justifyContent="space-between"
                    width="100%"
                >
                    <Typography
                        data-testid="bulk-edit-quantity-label"
                        variant="body2"
                    >
                        {t('Quantity')}
                    </Typography>
                    <TextField
                        color="primary"
                        error={quantityError}
                        helperText={quantityError ? t('Quantity cannot be less than 1') : null}
                        inputProps={{
                            style: { textAlign: 'right' },
                            min: 1,
                            'data-testid': 'bulk-edit-quantity',
                            inputMode: 'numeric',
                            pattern: '[0-9]*',
                        }}
                        size="small"
                        sx={classes.formControl_medium}
                        type="text"
                        value={itemQuantity}
                        variant="outlined"
                        fullWidth
                        required
                        onChange={handleQuantityChange}
                    />
                </Box>
                <Box
                    alignItems="center"
                    display="flex"
                    flexDirection="row"
                    justifyContent="space-between"
                    width="100%"
                >
                    <Typography
                        data-testid="bulk-edit-units-label"
                        variant="body2"
                    >
                        {t('Units')}
                    </Typography>
                    <FormControl
                        color="primary"
                        margin="none"
                        size="small"
                        sx={classes.formControl_medium}
                        variant="outlined"
                    >
                        <Select
                            data-testid="bulk-edit-units-mode"
                            id="select-itemUnit"
                            input={<OutlinedInput sx={{ typography: 'inputTextSmall' }} />}
                            labelId="select-itemUnit-label"
                            SelectDisplayProps={{ style: { textAlign: 'right' } }}
                            size="small"
                            value={itemUnit}
                            onChange={handleUnitChange}
                        >
                            <MenuItem
                                sx={{ typography: 'inputTextSmall' }}
                                value="mm"
                            >
                                {t('Millimetres')}
                            </MenuItem>
                            <MenuItem
                                sx={{ typography: 'inputTextSmall' }}
                                value="in"
                            >
                                {t('Inches')}
                            </MenuItem>
                        </Select>
                    </FormControl>
                </Box>
            </Box>
        </Box>
    )
}

SettingsBulkEdit.propTypes = {
    itemConsumptionMode: PropTypes.string,
    itemCuttingTechnology: PropTypes.string,
    itemIsCustomerSuppliedMaterial: PropTypes.bool,
    itemMaterial: PropTypes.string,
    itemQuantity: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
    itemSheet: PropTypes.string,
    itemThickness: PropTypes.string,
    itemUnit: PropTypes.string,
    setItemConsumptionMode: PropTypes.func,
    setItemCuttingTechnology: PropTypes.func,
    setItemIsCustomerSuppliedMaterial: PropTypes.func,
    setItemMaterial: PropTypes.func,
    setItemQuantity: PropTypes.func,
    setItemSheet: PropTypes.func,
    setItemThickness: PropTypes.func,
    setItemUnit: PropTypes.func,
    setUserMadeSettingsChanges: PropTypes.func,
}

export default SettingsBulkEdit
