import { useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useSelector } from 'react-redux'
import { useParams } from 'react-router-dom'
import {
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableHead,
    TablePagination,
    TableRow,
    TableSortLabel,
    Typography,
} from '@mui/material'
import { useSnackbar } from 'notistack'
import useLocalStorage from 'use-local-storage'

import { useGetMiscItemsQuery, useUpdateMiscItemMutation } from '@/app/services/miscItems'
import { selectOrganisationId } from '@/app/slices/organisationSlice'
import { ITEMS_PER_PAGE_DEFAULT_VALUE, ITEMS_PER_PAGE_OPTIONS, ITEMS_PER_PAGE_VARIABLE } from '@/common/utils'

import ItemNotesModal from '../common/ItemNotesModal'

import QuoteReviewMiscItemTableRow from './QuoteReviewMiscItemTableRow'

const classes = {
    tableContainer: {
        background: (theme) => theme.palette.background.paper,
        border: (theme) => `1px solid ${theme.palette.grey[400]}`,
        boxSizing: 'border-box',
        borderRadius: 2,
    },
    table: {
        tableLayout: 'fixed',
        boxSizing: 'border-box',
        borderCollapse: 'separate',
        px: 1,
    },
    titleTableCell: {
        '& p': {
            paddingInline: '12px',
            paddingBlock: '12px',
        },
    },
    headerTableCell: {
        padding: '8px',
        verticalAlign: 'bottom',
    },
    tableFooter: {
        display: 'flex',
        alignItems: 'center',
        flexDirection: 'row-reverse',
    },
    columnsTitles: {
        '& th': {
            pt: 2,
            borderBottom: 'none',
        },
    },
    columns: {
        '& th': {
            paddingTop: 0,
        },
    },

    columnBorder: {
        borderRight: (theme) => `1px dashed ${theme.palette.text.disabled}`,
    },

    headerTitle: {
        fontWeight: 500,
        lineHeight: '24px',
        letterSpacing: '0.17px',
        color: 'text.primary',
    },

    sortLabel: {
        justifyContent: 'space-between',
        width: '100%',
    },
}

const QuoteReviewTable = () => {
    const { t } = useTranslation()
    const { enqueueSnackbar } = useSnackbar()
    const { quoteId } = useParams()

    const organisationId = useSelector(selectOrganisationId)
    const [updateMiscItem] = useUpdateMiscItemMutation()

    const { data: miscItems, isLoading: isLoadingItems } = useGetMiscItemsQuery({
        organisationId,
        quoteId,
    })

    const [selectedMiscItem, setSelectedMiscItem] = useState(null)
    const [openNotesModal, setOpenNotesModal] = useState(false)

    const [orderBy, setOrderBy] = useState('index')
    const [order, setOrder] = useState('asc')
    const [currentPage, setCurrentPage] = useState(0)
    const [currentRowsPerPage, setCurrentRowsPerPage] = useLocalStorage(
        ITEMS_PER_PAGE_VARIABLE,
        ITEMS_PER_PAGE_DEFAULT_VALUE
    )

    const handleOpenNotesModal = (quoteItem) => {
        setSelectedMiscItem(quoteItem)
        setOpenNotesModal(true)
    }
    const handleCloseNotesModal = () => {
        setSelectedMiscItem(null)
        setOpenNotesModal(false)
    }

    const handleChangePage = (_, newPage) => {
        setCurrentPage(newPage)
    }

    const handleChangeRowsPerPage = (event) => {
        const newRowsPerPage = parseInt(event.target.value, 10)
        setCurrentRowsPerPage(newRowsPerPage)
        setCurrentPage(0)
    }

    const handleRequestSort = (property) => {
        const isAsc = orderBy === property && order === 'asc'
        setOrder(isAsc ? 'desc' : 'asc')
        setOrderBy(property)
    }

    const handleUpdateMiscItem = async (miscItem) => {
        try {
            await updateMiscItem({
                organisationId,
                quoteId,
                miscItemId: miscItem?.id,
                miscItem,
            }).unwrap()
        } catch (error) {
            enqueueSnackbar(t('Failed to update the miscellaneous item'), {
                variant: 'error',
            })
        }
    }

    const createColumnHeader = (columnId, columnTitle, isSortable) =>
        isSortable ? (
            <TableSortLabel
                active={orderBy === columnId}
                direction={orderBy === columnId ? order : 'asc'}
                sx={classes.sortLabel}
                onClick={() => {
                    handleRequestSort(columnId)
                }}
            >
                <Typography
                    sx={classes.headerTitle}
                    variant="body2"
                >
                    {columnTitle}
                </Typography>
            </TableSortLabel>
        ) : (
            <Typography
                sx={classes.headerTitle}
                variant="body2"
            >
                {columnTitle}
            </Typography>
        )

    return (
        <>
            <TableContainer sx={classes.tableContainer}>
                <Table sx={classes.table}>
                    <colgroup>
                        <col style={{ width: '100px' }} />
                        <col style={{ width: '200px' }} />
                        {/* <col style={{ width: '100px' }} /> */}
                        <col style={{ width: '120px' }} />
                        <col style={{ width: '100px' }} />
                        <col style={{ width: '100px' }} />
                        <col style={{ width: '80px' }} />
                        <col style={{ width: '80px' }} />
                        <col style={{ width: '80px' }} />
                        <col style={{ width: '80px' }} />
                    </colgroup>
                    <TableHead>
                        <TableRow sx={classes.columnsTitles}>
                            <TableCell
                                align="left"
                                colSpan={6}
                                data-testid="review-table-parts-header"
                                sx={[classes.headerTableCell, classes.titleTableCell, classes.columnBorder]}
                            >
                                <Typography variant="strong1">{t('MISCELLANEOUS ITEMS')}</Typography>
                            </TableCell>

                            <TableCell
                                align="center"
                                colSpan={3}
                                data-testid="review-table-prices-header"
                                sx={classes.headerTableCell}
                            >
                                <Typography variant="strong2">{t('PRICES')}</Typography>
                            </TableCell>
                        </TableRow>
                        <TableRow sx={classes.columns}>
                            <TableCell
                                align="center"
                                data-testid="review-table-thumbnail-header"
                                sx={classes.headerTableCell}
                            >
                                {createColumnHeader('thumbnail', t('Thumbnail'), false)}
                            </TableCell>

                            <TableCell
                                align="left"
                                data-testid="review-table-partDetails-header"
                                sx={classes.headerTableCell}
                            >
                                {createColumnHeader('itemDetails', t('Item details'), false)}
                            </TableCell>

                            {/* <TableCell
                                align="left"
                                data-testid="review-table-cuttingTech-header"
                                sx={classes.headerTableCell}
                            >
                                {createColumnHeader('category', t('Category'), false)}
                            </TableCell> */}

                            <TableCell
                                align="right"
                                data-testid="review-table-materialDetails-header"
                                sx={classes.headerTableCell}
                            >
                                {createColumnHeader('dimensions', t('Dimensions'), false)}
                            </TableCell>

                            <TableCell
                                align="right"
                                data-testid="review-table-secondaryProcesses-header"
                                sx={classes.headerTableCell}
                            >
                                {createColumnHeader('weight', t('Weight'), false)}
                            </TableCell>

                            <TableCell
                                align="right"
                                data-testid="review-table-secondaryProcesses-header"
                                sx={classes.headerTableCell}
                            >
                                {createColumnHeader('time', t('Time'), false)}
                            </TableCell>

                            <TableCell
                                align="right"
                                data-testid="review-table-quantity-header"
                                sx={[classes.headerTableCell, classes.columnBorder]}
                            >
                                {createColumnHeader('quantity', t('Quantity'), false)}
                            </TableCell>

                            <TableCell
                                align="right"
                                data-testid="review-table-unitPrice-header"
                                sx={classes.headerTableCell}
                            >
                                {createColumnHeader('adjustment', t('Adjustment'), false)}
                            </TableCell>

                            <TableCell
                                align="right"
                                data-testid="review-table-unitPrice-header"
                                sx={classes.headerTableCell}
                            >
                                {createColumnHeader('unitPrice', t('Unit price'), false)}
                            </TableCell>

                            <TableCell
                                align="right"
                                data-testid="review-table-lineTotal-header"
                                sx={classes.headerTableCell}
                            >
                                {createColumnHeader('lineTotal', t('Line total'), false)}
                            </TableCell>
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {miscItems
                            ?.slice(
                                currentPage * currentRowsPerPage,
                                currentPage * currentRowsPerPage + currentRowsPerPage
                            )
                            .map((miscItem) => (
                                <QuoteReviewMiscItemTableRow
                                    handleOpenNotesModal={handleOpenNotesModal}
                                    key={miscItem.id}
                                    miscItem={miscItem}
                                />
                            ))}
                    </TableBody>
                </Table>

                <TablePagination
                    component="div"
                    count={miscItems?.length}
                    labelDisplayedRows={({ count, from, to }) => `${from}-${to} of ${count}`}
                    labelRowsPerPage={t('Items per page')}
                    page={currentPage}
                    rowsPerPage={currentRowsPerPage}
                    rowsPerPageOptions={ITEMS_PER_PAGE_OPTIONS}
                    sx={classes.tableFooter}
                    onPageChange={handleChangePage}
                    onRowsPerPageChange={handleChangeRowsPerPage}
                />
            </TableContainer>

            <ItemNotesModal
                handleUpdate={handleUpdateMiscItem}
                miscItem={selectedMiscItem}
                open={openNotesModal}
                onClose={handleCloseNotesModal}
            />
        </>
    )
}

export default QuoteReviewTable
