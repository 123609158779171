import { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useSelector } from 'react-redux'
import { HowToReg } from '@mui/icons-material'
import {
    Box,
    Checkbox,
    Link,
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableFooter,
    TableHead,
    TableRow,
    TextField,
    Typography,
} from '@mui/material'
import PropTypes from 'prop-types'

import { selectLocale } from '@/app/slices/appSlice'
import { DateTime, OrganisationLogo } from '@/common/components'
import { getAddressDetails } from '@/common/helpers/addressUtilities'
import { useNumberFormatter } from '@/common/hooks'
import { DELIVERY_PRICING_METHODS, getLargestDimensions } from '@/common/utils'

import ProductionDocumentsCustomerInfo from '../../components/ProductionDocumentsCustomerInfo'

const classes = {
    documentContainer: {
        width: 1024,
        margin: '24px auto',
    },
    card: {
        backgroundColor: 'common.white',
        border: 1,
        borderColor: 'grey.300',
        borderRadius: 2,
        py: 3,
        px: 3,
        my: 3,
    },
    footerTextContent: {
        '& p': {
            margin: 0,
        },
        '& p:first-of-type': {
            color: 'text.primary',
            '@media print': {
                color: '#2b2b2b',
            },
        },
    },
    footer: {
        '@media print': {
            position: 'relative',
            textAlign: 'center',
            bottom: 0,
            left: '50%',
            transform: 'translate(-50%, 0)',
        },
    },
}

const WorkOrderSummary = ({ customer, customerContact, organisation, quote, showShipping }) => {
    const { t } = useTranslation()

    const locale = useSelector(selectLocale)

    const [materialGroups, setMaterialGroups] = useState(null)
    const [processes, setProcesses] = useState(null)
    const [processesTotalTime, setProcessesTotalTime] = useState(null)

    const { n } = useNumberFormatter({
        numberOfDecimalPlaces: organisation.defaultDrawingUnits.toLowerCase() === 'imperial' ? 4 : 2,
    })

    const { n: q } = useNumberFormatter({ numberOfDecimalPlaces: 0 })

    const { n: d } = useNumberFormatter({ numberOfDecimalPlaces: 2 })

    const formatNumberWithX = (str) => {
        const parts = str.split('x')
        const formattedParts = parts.map((part) => n(Number(part)))
        return formattedParts.join(' x ')
    }

    const organisationLengthUnit = organisation.defaultDrawingUnits === 'Metric' ? 'mm' : 'in'

    const organisationWeightUnit = organisation.defaultDrawingUnits === 'Metric' ? 'kg' : 'lb'

    const largestDimensions = getLargestDimensions(quote.quoteItems, organisation)

    const secondsToMinutes = (seconds) => {
        if (!seconds) {
            return '0 min'
        }
        const minutes = (seconds / 60).toFixed(2)
        return `${minutes} min`
    }

    useEffect(() => {
        const materialGroupsAndProcesses = summariseMaterialGroupsAndProcesses(quote.quoteItems)
        setMaterialGroups(materialGroupsAndProcesses.materialGroups)
        setProcesses(materialGroupsAndProcesses.processes)
        setProcessesTotalTime(materialGroupsAndProcesses.processesTotalTimeMinutes)
    }, [quote, setMaterialGroups])

    const summariseMaterialGroupsAndProcesses = (quoteItems) => {
        return quoteItems.reduce(
            (result, currentItem) => {
                // Material groups
                const currentItemGroupKey =
                    currentItem.materialId +
                    currentItem.sheetId +
                    currentItem.thickness +
                    (currentItem.isCustomerSuppliedMaterial ? 1 : 0)

                // (quantity / parts per sheet) * nest bounds %)
                const requiredSheets = currentItem.quantity / currentItem.partsPerSheet

                const cuttingTimeMinutes =
                    (currentItem.quantity *
                        ((currentItem.setupTimePerItemSeconds ?? 0) +
                            (currentItem.sheetChangeTimePerItemSeconds ?? 0) +
                            (currentItem.runtime ?? 0) +
                            (currentItem.unloadTimePerItemSeconds ?? 0))) /
                    60

                result.processesTotalTimeMinutes += cuttingTimeMinutes

                if (result.materialGroups[currentItemGroupKey] === undefined) {
                    result.materialGroups[currentItemGroupKey] = {
                        key: currentItemGroupKey,
                        name: currentItem.material?.materialName,
                        sheetWidth: currentItem.sheet?.sheetWidth,
                        sheetHeight: currentItem.sheet?.sheetHeight,
                        processingTotalTime: cuttingTimeMinutes,
                        thickness: currentItem.thickness,
                        requiredSheets: requiredSheets,
                        netWeight: currentItem.mass * currentItem.quantity,
                        isCustomerSuppliedMaterial: currentItem.isCustomerSuppliedMaterial,
                    }
                } else {
                    result.materialGroups[currentItemGroupKey].processingTotalTime += cuttingTimeMinutes
                    result.materialGroups[currentItemGroupKey].requiredSheets += requiredSheets
                    result.materialGroups[currentItemGroupKey].netWeight += currentItem.mass * currentItem.quantity
                }

                // Processes
                // Cutting
                if (result.processes['cutting'] === undefined) {
                    result.processes['cutting'] = {
                        time: cuttingTimeMinutes,
                        name: t('Cutting'),
                    }
                } else {
                    result.processes['cutting'].time += cuttingTimeMinutes
                }

                // Folding
                const foldingTimeMinutes = (currentItem.quantity * currentItem.foldingTotalTimePerItemSeconds) / 60

                if (foldingTimeMinutes > 0) {
                    result.processesTotalTimeMinutes += foldingTimeMinutes
                    if (result.processes['folding'] === undefined) {
                        result.processes['folding'] = {
                            time: foldingTimeMinutes,
                            name: t('Folding'),
                        }
                    } else {
                        result.processes['folding'].time += foldingTimeMinutes
                    }
                }

                // Secondary Processes
                for (const process of Object.values(currentItem.secondaryProcesses)) {
                    const processTimeMinutes =
                        (currentItem.quantity * (process.setupTimePerItemSeconds + process.timePerItemSeconds)) / 60

                    result.processesTotalTimeMinutes += processTimeMinutes
                    if (result.processes[process.secondaryProcessId] === undefined) {
                        result.processes[process.secondaryProcessId] = {
                            time: processesTotalTime,
                            name: process.secondaryProcess.name,
                        }
                        result.processes[process.secondaryProcessId].time = processTimeMinutes
                    } else {
                        result.processes[process.secondaryProcessId].time += processTimeMinutes
                    }
                }

                return result
            },
            {
                materialGroups: {},
                processes: {
                    cutting: { name: t('Cutting'), time: 0 },
                    folding: { name: t('Folding'), time: 0 },
                },
                processesTotalTimeMinutes: 0,
            }
        )
    }

    const sortMiscItems = (miscItems) => {
        return miscItems.toSorted((a, b) => a.index - b.index)
    }
    
    const deliveryPricingMethod = showShipping ? DELIVERY_PRICING_METHODS[quote.deliveryPricingMethod]?.documentLabel : ''

    return quote && quote.organisationId && quote.quoteItems ? (
        <Box sx={classes.documentContainer}>
            {/* Header */}
            <Box
                alignItems="flex-end"
                display="flex"
                gap={2}
                justifyContent="space-between"
                sx={classes.card}
            >
                <Box
                    className="quote-info"
                    display="flex"
                    flex={2}
                    flexDirection="column"
                    gap={2}
                >
                    <Typography
                        textTransform="uppercase"
                        variant="h4"
                    >
                        {`${t('Work order summary')}: `}
                        <Typography
                            component="span"
                            fontWeight={700}
                            textTransform="uppercase"
                            variant="h4"
                        >
                            {quote.workOrderNumberText}
                        </Typography>
                    </Typography>
                    {customer ? (
                        <ProductionDocumentsCustomerInfo
                            customer={customer}
                            customerContact={customerContact}
                            deliveryAddress={quote.deliveryAddress}
                            deliveryOption={quote.deliveryOption}
                            deliveryPricingMethod={deliveryPricingMethod}
                            lastModifiedByUserName={quote.lastModifiedByUserName}
                            pickupAddress={organisation.pickupAddress}
                        />
                    ) : null}
                </Box>

                <Box
                    alignItems="flex-end"
                    className="quote-logo-info"
                    display="flex"
                    flex={1}
                    flexDirection="column"
                    gap={2}
                >
                    <OrganisationLogo
                        organisationId={quote.organisationId}
                        organisationLogoUri={organisation.logoUri}
                        wrapperHeight={100}
                        wrapperWidth="auto"
                    />
                    <Box
                        alignItems="flex-end"
                        className="quote-details-info"
                        display="flex"
                        flexDirection="column"
                        gap={0.5}
                    >
                        <Box
                            display="flex"
                            gap={1}
                        >
                            <Typography
                                color="text.secondary"
                                variant="body2"
                            >
                                {t('Quote number:')}
                            </Typography>
                            <Typography
                                minWidth={80}
                                textAlign="right"
                                variant="strong2"
                            >
                                {quote.name}
                            </Typography>
                        </Box>
                        <Box
                            display="flex"
                            gap={1}
                        >
                            <Typography
                                color="text.secondary"
                                variant="body2"
                            >
                                {t('PO number:')}
                            </Typography>
                            <Typography
                                minWidth={80}
                                textAlign="right"
                                variant="strong2"
                            >
                                {quote.poNumber}
                            </Typography>
                        </Box>

                        {quote.orderedDateUtc ? (
                            <Box
                                display="flex"
                                gap={1}
                            >
                                <Typography
                                    color="text.secondary"
                                    variant="body2"
                                >
                                    {t('Ordered date:')}
                                </Typography>
                                <Typography
                                    minWidth={80}
                                    textAlign="right"
                                    variant="body2"
                                >
                                    <DateTime
                                        format="DD-MMM-YYYY"
                                        locale={locale}
                                    >
                                        {quote.orderedDateUtc}
                                    </DateTime>
                                </Typography>
                            </Box>
                        ) : null}

                        {quote.requiredDateUtc ? (
                            <Box
                                display="flex"
                                gap={1}
                            >
                                <Typography
                                    color="text.secondary"
                                    variant="body2"
                                >
                                    {t('Required shipping date:')}
                                </Typography>
                                <Typography
                                    minWidth={80}
                                    textAlign="right"
                                    variant="strong2"
                                >
                                    <DateTime
                                        format="DD-MMM-YYYY"
                                        locale={locale}
                                    >
                                        {quote.requiredDateUtc}
                                    </DateTime>
                                </Typography>
                            </Box>
                        ) : null}
                    </Box>
                </Box>
            </Box>

            <Box
                display="flex"
                flexDirection="column"
                gap={1}
                sx={[classes.card, { pb: 10 }]}
            >
                <Typography variant="h6">{t('Order notes')}</Typography>
                {quote.customerNotes ? (
                    <Box>
                        <Typography
                            component="span"
                            variant="strong2"
                        >
                            {t('Customer notes') + ': '}
                        </Typography>
                        <Typography
                            component="span"
                            variant="body2"
                        >
                            {quote.customerNotes}
                        </Typography>
                    </Box>
                ) : null}
                {quote.vendorNotes ? (
                    <Box>
                        <Typography
                            component="span"
                            variant="strong2"
                        >
                            {t('Vendor notes') + ': '}
                        </Typography>
                        <Typography
                            component="span"
                            variant="body2"
                        >
                            {quote.vendorNotes}
                        </Typography>
                    </Box>
                ) : null}
                {quote.privateNotes ? (
                    <Box>
                        <Typography
                            component="span"
                            variant="strong2"
                        >
                            {t('Private notes') + ': '}
                        </Typography>
                        <Typography
                            component="span"
                            variant="body2"
                        >
                            {quote.privateNotes}
                        </Typography>
                    </Box>
                ) : null}
            </Box>

            {/* Order summary */}
            <Box
                alignItems="flex-end"
                display="flex"
                justifyContent="space-between"
                sx={classes.card}
            >
                <Box
                    display="flex"
                    flex={1}
                    flexDirection="column"
                    gap={1}
                >
                    <Typography variant="h6">{t('Order summary')}</Typography>
                    <Typography variant="body2">{t('Net weight')}</Typography>
                    <Typography variant="body2">{t('Largest long-dimension of a part')}</Typography>
                    <Typography variant="body2">{t('Largest short-dimension of a part')}</Typography>
                </Box>

                <Box
                    alignItems="flex-end"
                    display="flex"
                    flex={1}
                    flexDirection="column"
                    gap={2}
                >
                    <Box
                        alignItems="flex-end"
                        className="quote-details-info"
                        display="flex"
                        flexDirection="column"
                        gap={0.5}
                    >
                        <Box
                            display="flex"
                            gap={1}
                        >
                            <Typography variant="strong2">
                                {d(quote.totalMass)} {organisationWeightUnit}
                            </Typography>
                        </Box>
                        <Box
                            display="flex"
                            gap={1}
                        >
                            <Typography variant="strong2">{largestDimensions.long}</Typography>
                        </Box>
                        <Box
                            display="flex"
                            gap={1}
                        >
                            <Typography variant="strong2">{largestDimensions.short}</Typography>
                        </Box>
                    </Box>
                </Box>
            </Box>

            {/* Cutting and material summary */}
            <Box sx={classes.card}>
                <Box
                    display="flex"
                    flex={1}
                    flexDirection="column"
                    gap={1}
                >
                    <Typography variant="h6">{t('Cutting and material summary')}</Typography>
                </Box>
                <TableContainer>
                    <Table sx={{ minWidth: 952 }}>
                        <TableHead>
                            <TableRow sx={{ verticalAlign: 'bottom', height: 60 }}>
                                <TableCell
                                    align="left"
                                    colSpan={2}
                                    width={220}
                                >
                                    {t('Material group')}
                                </TableCell>
                                <TableCell
                                    align="left"
                                    colSpan={2}
                                    width={230}
                                >
                                    {t('Estimated material requirements')}
                                </TableCell>
                                <TableCell
                                    align="right"
                                    width={100}
                                >
                                    {t('Total cutting time')}
                                </TableCell>
                                <TableCell
                                    align="right"
                                    width={100}
                                >
                                    {t('Net weight of parts')}
                                </TableCell>
                                <TableCell
                                    align="center"
                                    width={70}
                                >
                                    {t('In stock')}
                                </TableCell>
                                <TableCell
                                    align="left"
                                    width={180}
                                >
                                    {t('On order')}
                                </TableCell>
                            </TableRow>
                        </TableHead>
                        <TableBody>
                            {materialGroups
                                ? Object.values(materialGroups)?.map((group) => (
                                      <TableRow key={group.key}>
                                          <TableCell sx={{ py: 1.5 }}>{group.name}</TableCell>
                                          <TableCell
                                              align="right"
                                              sx={{ py: 1.5 }}
                                          >
                                              {n(group.thickness)} {organisationLengthUnit}
                                          </TableCell>
                                          <TableCell sx={{ py: 1.5 }}>
                                              {n(group.sheetWidth)} x {n(group.sheetHeight)} {organisationLengthUnit}
                                          </TableCell>
                                          <TableCell
                                              align="right"
                                              sx={{ py: 1.5 }}
                                          >
                                              {d(group.requiredSheets)} {t('sheets')}
                                          </TableCell>
                                          <TableCell
                                              align="right"
                                              sx={{ py: 1.5 }}
                                          >
                                              {d(group.processingTotalTime)} {'mins'}
                                          </TableCell>
                                          <TableCell
                                              align="right"
                                              sx={{ py: 1.5 }}
                                          >
                                              {d(group.netWeight)} {organisationWeightUnit}
                                          </TableCell>
                                          <TableCell
                                              align="center"
                                              sx={{ py: 1.5 }}
                                          >
                                              {group.isCustomerSuppliedMaterial ? (
                                                  'N/A'
                                              ) : (
                                                  <Checkbox sx={{ pointerEvents: 'none' }} />
                                              )}
                                          </TableCell>
                                          <TableCell sx={{ py: 1.5 }}>
                                              {group.isCustomerSuppliedMaterial ? (
                                                  <>
                                                      {t('Customer supplied')} <HowToReg />
                                                  </>
                                              ) : (
                                                  <TextField
                                                      defaultValue={' '}
                                                      InputLabelProps={{
                                                          shrink: true,
                                                          sx: { pointerEvents: 'none' },
                                                      }}
                                                      label={t('Date/Number')}
                                                      size="small"
                                                      sx={{ pointerEvents: 'none' }}
                                                      variant="outlined"
                                                  />
                                              )}
                                          </TableCell>
                                      </TableRow>
                                  ))
                                : null}
                        </TableBody>
                    </Table>
                </TableContainer>
            </Box>

            {/* Process summary */}
            <Box sx={classes.card}>
                <Box
                    display="flex"
                    flex={1}
                    flexDirection="column"
                    gap={1}
                >
                    <Typography variant="h6">{t('Process summary')}</Typography>
                </Box>
                <TableContainer>
                    <Table sx={{ minWidth: 952 }}>
                        <TableHead>
                            <TableRow sx={{ verticalAlign: 'bottom', height: 60 }}>
                                <TableCell
                                    align="left"
                                    sx={{ borderBottom: 0 }}
                                    width={700}
                                />
                                <TableCell
                                    align="left"
                                    sx={{ borderBottom: 0 }}
                                    width={110}
                                >
                                    {t('Total time')}
                                </TableCell>
                                <TableCell
                                    align="center"
                                    sx={{ borderBottom: 0 }}
                                >
                                    {t('Process complete')}
                                </TableCell>
                            </TableRow>
                        </TableHead>
                        <TableBody>
                            <TableRow>
                                <TableCell sx={{ py: 0, borderBottom: 0 }}>{t('Cutting')}</TableCell>
                                <TableCell sx={{ py: 0, borderBottom: 0 }}>
                                    {processes ? d(processes['cutting'].time) : null} {'mins'}
                                </TableCell>
                                <TableCell
                                    align="center"
                                    sx={{ py: 0, borderBottom: 0 }}
                                >
                                    <Checkbox sx={{ pointerEvents: 'none' }} />
                                </TableCell>
                            </TableRow>
                            {processes && processes['folding'] !== undefined && processes['folding'].time > 0 ? (
                                <TableRow>
                                    <TableCell sx={{ py: 0, borderBottom: 0 }}>{t('Folding')}</TableCell>
                                    <TableCell sx={{ py: 0, borderBottom: 0 }}>
                                        {processes ? d(processes['folding'].time) : null} {'mins'}
                                    </TableCell>
                                    <TableCell
                                        align="center"
                                        sx={{ py: 0, borderBottom: 0 }}
                                    >
                                        <Checkbox sx={{ pointerEvents: 'none' }} />
                                    </TableCell>
                                </TableRow>
                            ) : null}
                            {processes
                                ? Object.values(processes)
                                      .filter((process) => process.name !== 'Cutting' && process.name !== 'Folding')
                                      ?.map((process) => (
                                          <TableRow key={process.name}>
                                              <TableCell sx={{ py: 0, borderBottom: 0 }}>{process.name}</TableCell>
                                              <TableCell sx={{ py: 0, borderBottom: 0 }}>
                                                  {process.time === 0 ? '-' : d(process.time) + ' mins'}
                                              </TableCell>
                                              <TableCell
                                                  align="center"
                                                  sx={{ py: 0, borderBottom: 0 }}
                                              >
                                                  <Checkbox sx={{ pointerEvents: 'none' }} />
                                              </TableCell>
                                          </TableRow>
                                      ))
                                : null}
                        </TableBody>
                        <TableFooter>
                            <TableRow>
                                <TableCell sx={{ py: 1.5, borderTop: 0.8, borderBottom: 0, borderColor: '#E6E9EB' }}>
                                    <Typography
                                        color="text.primary"
                                        variant="strong2"
                                    >
                                        {t('Total')}
                                    </Typography>
                                </TableCell>
                                <TableCell sx={{ py: 1.5, borderTop: 0.8, borderBottom: 0, borderColor: '#E6E9EB' }}>
                                    <Typography
                                        color="text.primary"
                                        variant="strong2"
                                    >
                                        {processes ? d(processesTotalTime) : null} {'mins'}
                                    </Typography>
                                </TableCell>
                                <TableCell sx={{ py: 1.5, borderTop: 0.8, borderBottom: 0, borderColor: '#E6E9EB' }} />
                            </TableRow>
                        </TableFooter>
                    </Table>
                </TableContainer>
            </Box>

            {/* Miscellaneous Items */}
            {quote.numberOfMiscItems.length ? (
                <Box sx={classes.card}>
                    <Box
                        display="flex"
                        flex={1}
                        flexDirection="column"
                        gap={1}
                    >
                        <Typography variant="h6">{t('Miscellaneous Items')}</Typography>
                    </Box>
                    <TableContainer>
                        <Table sx={{ minWidth: 952 }}>
                            <TableHead>
                                <TableRow sx={{ verticalAlign: 'bottom', height: 60 }}>
                                    <TableCell
                                        align="left"
                                        width={220}
                                    >
                                        {t('Name')}
                                    </TableCell>
                                    <TableCell
                                        align="right"
                                        width={230}
                                    >
                                        {t('Dimensions')}
                                    </TableCell>
                                    <TableCell
                                        align="right"
                                        width={100}
                                    >
                                        {t('Weight')}
                                    </TableCell>
                                    <TableCell
                                        align="right"
                                        width={100}
                                    >
                                        {t('Time')}
                                    </TableCell>
                                    <TableCell
                                        align="right"
                                        width={100}
                                    >
                                        {t('Quantity')}
                                    </TableCell>
                                    <TableCell
                                        align="center"
                                        width={70}
                                    >
                                        {t('In stock')}
                                    </TableCell>
                                    <TableCell
                                        align="left"
                                        width={180}
                                    >
                                        {t('On order')}
                                    </TableCell>
                                </TableRow>
                            </TableHead>
                            <TableBody>
                                {sortMiscItems(quote.miscellaneousItems)?.map((item) => (
                                    <TableRow key={item.id}>
                                        <TableCell sx={{ py: 1.5 }}>{item.name}</TableCell>
                                        <TableCell
                                            align="right"
                                            sx={{ py: 1.5 }}
                                        >
                                            {item?.dimensions
                                                ? `${formatNumberWithX(item.dimensions)} ${organisationLengthUnit}`
                                                : null}
                                        </TableCell>

                                        <TableCell
                                            align="right"
                                            sx={{ py: 1.5 }}
                                        >
                                            {item?.weight ? `${d(item.weight)} ${organisationWeightUnit}` : null}
                                        </TableCell>
                                        <TableCell
                                            align="right"
                                            sx={{ py: 1.5 }}
                                        >
                                            {item?.productionTimePerItemSeconds
                                                ? secondsToMinutes(item.productionTimePerItemSeconds)
                                                : null}
                                        </TableCell>
                                        <TableCell
                                            align="right"
                                            sx={{ py: 1.5 }}
                                        >
                                            {q(item?.quantity)}
                                        </TableCell>
                                        <TableCell
                                            align="center"
                                            sx={{ py: 1.5 }}
                                        >
                                            <Checkbox sx={{ pointerEvents: 'none' }} />
                                        </TableCell>
                                        <TableCell sx={{ py: 1.5 }}>
                                            <TextField
                                                defaultValue={' '}
                                                InputLabelProps={{
                                                    shrink: true,
                                                    sx: { pointerEvents: 'none' },
                                                }}
                                                label={t('Date/Number')}
                                                size="small"
                                                sx={{ pointerEvents: 'none' }}
                                                variant="outlined"
                                            />
                                        </TableCell>
                                    </TableRow>
                                ))}
                            </TableBody>
                        </Table>
                    </TableContainer>
                </Box>
            ) : null}

            {/* Footer */}
            {!import.meta.env.VITE_RESELLER ? (
                <Box
                    component="footer"
                    sx={!organisation?.showBrandingInDocumentFooter ? { displayPrint: 'none' } : classes.footer}
                    textAlign="center"
                >
                    <Typography variant="small">
                        {t('Powered by') + ' '}
                        <Link
                            color="primary"
                            href={`https://tempustools.com?org_id=${quote.organisationId}&utm_source=quotemate&utm_medium=virality&utm_campaign=document-branding&utm_content=work-order-summary`}
                            target="_blank"
                            underline="always"
                        >
                            Tempus Tools
                        </Link>
                    </Typography>
                </Box>
            ) : null}
        </Box>
    ) : null
}

WorkOrderSummary.propTypes = {
    customer: PropTypes.object,
    customerContact: PropTypes.object,
    organisation: PropTypes.object,
    quote: PropTypes.object,
    showShipping: PropTypes.bool
}

export default WorkOrderSummary
