import { useCallback, useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useSelector } from 'react-redux'
import { Add, Search } from '@mui/icons-material'
import {
    Box,
    Button,
    IconButton,
    InputAdornment,
    Table,
    TableBody,
    TableCell,
    TableHead,
    TablePagination,
    TableRow,
    TableSortLabel,
    TextField,
} from '@mui/material'
import _ from 'lodash'
import PropTypes from 'prop-types'
import useLocalStorage from 'use-local-storage'

import { useGetContactsQuery } from '@/app/services/contacts'
import { selectOrganisationId } from '@/app/slices/organisationSlice'
import { TbxDrawer, TbxShowToggle } from '@/common/components'
import { PARTS_PER_PAGE_DEFAULT_VALUE, PARTS_PER_PAGE_OPTIONS, PARTS_PER_PAGE_VARIABLE } from '@/common/utils'

import AddContactForm from '../../AddContactForm'

import CustomerContactsRow from './CustomerContactsRow'

const classes = {
    root: {
        display: 'flex',
        flexDirection: 'column',
    },
    newContactBtn: {
        minWidth: '117px',
        minHeight: '30px',
    },
    searchBar: {
        display: 'flex',
        width: '100%',
        marginBottom: '24px',
        justifyContent: 'space-between',
    },
    searchInput: {
        '& input::placeholder': {
            fontSize: '16px',
            lineHeight: '24px',
            color: 'black',
        },
    },
    table: {
        tableLayout: 'fixed',
        border: '1px solid rgba(52, 73, 94, 0.12)',
        boxSizing: 'border-box',
        borderRadius: '8px 8px 0 0',
        borderCollapse: 'separate',
        backgroundColor: 'background.paper',
    },
    nameColumn: {
        width: '216px',
    },
    emailColumn: {
        width: '280px',
    },
    headerTableCell: {
        padding: '17px 24px',
        verticalAlign: 'bottom',
        fontWeight: 600,
        fontSize: '14px',
        lineHeight: '18px',
        alignItems: 'center',
        color: '#9FA2B4',
    },
    tableFooter: {
        border: '1px solid rgba(52, 73, 94, 0.12)',
        borderTop: 'none',
        boxSizing: 'border-box',
        borderRadius: '0px 0px 8px 8px',
        backgroundColor: 'background.paper',
    },
    tablePaginationCaption: {
        fontSize: '12px',
        color: '#5E7387',
    },
}

const compareContacts = (a, b) => {
    if (a.name < b.name) {
        return -1
    }
    if (a.name > b.name) {
        return 1
    }
    return 0
}

const CustomerContactsTab = ({ customer }) => {
    const { t } = useTranslation()

    const organisationId = useSelector(selectOrganisationId)

    const { data: contacts, isLoading: isLoadingContacts } = useGetContactsQuery({
        organisationId,
        customerId: customer?.customerId,
        includeDeleted: true,
    })

    const [showAddContactForm, setShowAddContactForm] = useState(false)
    const [showArchivedContacts, setShowArchivedContacts] = useState(false)
    const [contactsFilterValue, setContactsFilterValue] = useState('')
    const [filteredContacts, setFilteredContacts] = useState([])

    const [selectedContact, setSelectedContact] = useState(null)

    // Table stuff
    const getFooterStyle = () => {
        return {
            bottom: 0,
            width: '100%',
            zIndex: 1200,
            opacity: 1,
            marginBottom: '60px',
        }
    }

    const [order, setOrder] = useState('asc')
    const [orderBy, setOrderBy] = useState('name')
    const [rows, setRows] = useState([])

    const stableSort = useCallback((array, comparator) => {
        const stabilizedThis = array.map((el, index) => [el, index])
        stabilizedThis.sort((a, b) => {
            const order = comparator(a[0], b[0])
            if (order !== 0) return order
            return a[1] - b[1]
        })
        return stabilizedThis.map((el) => el[0])
    }, [])

    const handleRequestSort = (property) => {
        const isAsc = orderBy === property && order === 'asc'
        setOrder(isAsc ? 'desc' : 'asc')
        setOrderBy(property)
    }

    const descendingComparator = useCallback((a, b, orderBy) => {
        const first = a[orderBy]
        const next = b[orderBy]
        switch (typeof first) {
            case 'number':
                if (next < first) return -1
                if (next > first) return 1
                break
            case 'string':
                return next?.localeCompare(first, undefined, { sensitivity: 'base' })
            case 'object':
                if (!first.$type || !first.$type.includes('SheetSize')) {
                    return 0
                }
                return descendingComparator(
                    { sheetSize: first.height * first.width },
                    { sheetSize: next.height * next.width },
                    orderBy
                )
            default:
                return 0
        }
        return 0
    }, [])

    const getComparator = useCallback(
        (order, orderBy) => {
            return order === 'desc'
                ? (a, b) => descendingComparator(a, b, orderBy)
                : (a, b) => -descendingComparator(a, b, orderBy)
        },
        [descendingComparator]
    )

    useEffect(() => {
        setRows(_.cloneDeep(filteredContacts))
    }, [filteredContacts])

    useEffect(() => {
        setRows(stableSort(filteredContacts, getComparator(order, orderBy)))
    }, [order, orderBy, filteredContacts, stableSort, getComparator])

    const createColumnHeader = (columnId, columnLabel, isSortable) => (
        <TableSortLabel
            active={isSortable ? orderBy === columnId : null}
            direction={isSortable && orderBy === columnId ? order : 'asc'}
            hideSortIcon={!isSortable}
            onClick={
                isSortable ? () => {
                    handleRequestSort(columnId)
                } : null
            }
        >
            {columnLabel}
        </TableSortLabel>
    )

    const [currentPage, setCurrentPage] = useState(0)
    const [currentRowsPerPage, setCurrentRowsPerPage] = useLocalStorage(
        PARTS_PER_PAGE_VARIABLE,
        PARTS_PER_PAGE_DEFAULT_VALUE
    )

    const handleChangePage = (_, newPage) => {
        setCurrentPage(newPage)
    }

    const handleChangeRowsPerPage = (event) => {
        const newRowsPerPage = parseInt(event.target.value, 10)
        setCurrentRowsPerPage(newRowsPerPage)
        setCurrentPage(0)
    }

    useEffect(() => {
        if (!contacts) return

        let filteredContacts = contactsFilterValue
            ? contacts?.filter((contact) =>
                  contact.name.toLocaleLowerCase().includes(contactsFilterValue.toLocaleLowerCase())
              )
            : contacts

        filteredContacts = filteredContacts?.slice()?.sort(compareContacts)

        setFilteredContacts(
            showArchivedContacts ? filteredContacts : filteredContacts.filter((contact) => !contact?.isDeleted)
        )
    }, [contactsFilterValue, contacts, showArchivedContacts])

    const handleShowArchivedContactsChange = () => {
        setShowArchivedContacts(!showArchivedContacts)
    }

    const handleSearchValueChange = (event) => {
        setContactsFilterValue(event.target.value)
    }

    const handleDrawerClose = () => {
        setSelectedContact(null)
        setShowAddContactForm(false)
    }

    const handleNewContactClick = () => {
        setShowAddContactForm(true)
    }

    const handleEditContactClick = (contact) => {
        setSelectedContact(contact)
        setShowAddContactForm(true)
    }

    const nameHeaderStyle = Object.assign({}, classes.nameColumn, classes.headerTableCell)
    const emailHeaderStyle = Object.assign({}, classes.emailColumn, classes.headerTableCell)

    return (
        <Box sx={classes.root}>
            <Box sx={classes.searchBar}>
                <Button
                    color="primary"
                    disabled={customer?.isDeleted}
                    size="small"
                    startIcon={<Add />}
                    sx={classes.newContactBtn}
                    variant="outlined"
                    onClick={handleNewContactClick}
                >
                    {t('Add contact')}
                </Button>
                <TextField
                    InputProps={{
                        startAdornment: (
                            <InputAdornment position="start">
                                <IconButton size="large">
                                    <Search />
                                </IconButton>
                            </InputAdornment>
                        ),
                    }}
                    placeholder={t('Search contact...')}
                    size="small"
                    sx={classes.searchInput}
                    value={contactsFilterValue}
                    variant="outlined"
                    onChange={handleSearchValueChange}
                ></TextField>

                <TbxShowToggle
                    checked={showArchivedContacts}
                    label={t('Show archived contacts')}
                    onChange={handleShowArchivedContactsChange}
                />
            </Box>
            <Table
                aria-label="contacts"
                sx={classes.table}
            >
                <TableHead>
                    <TableRow>
                        <TableCell sx={nameHeaderStyle}>{createColumnHeader('name', t('Name'), true)}</TableCell>
                        <TableCell sx={emailHeaderStyle}>{createColumnHeader('email', t('E-mail'), true)}</TableCell>
                        <TableCell sx={classes.headerTableCell}>
                            {createColumnHeader('phone', t('Phone'), true)}
                        </TableCell>
                        <TableCell></TableCell>
                    </TableRow>
                </TableHead>
                <TableBody>
                    {!isLoadingContacts ? rows
                            ?.slice(
                                currentPage * currentRowsPerPage,
                                currentPage * currentRowsPerPage + currentRowsPerPage
                            )
                            .map((contact) => {
                                return (
                                    <CustomerContactsRow
                                        contact={contact}
                                        customer={customer}
                                        key={contact.contactId}
                                        onEditClick={handleEditContactClick}
                                    />
                                )
                            }) : null}
                </TableBody>
            </Table>
            <TablePagination
                classes={{
                    caption: classes.tablePaginationCaption,
                }}
                component="div"
                count={filteredContacts.length}
                labelDisplayedRows={({ count, from, to }) => `${from}-${to} of ${count}`}
                labelRowsPerPage={t('Contacts per page')}
                page={currentPage}
                rowsPerPage={currentRowsPerPage}
                rowsPerPageOptions={PARTS_PER_PAGE_OPTIONS}
                style={getFooterStyle()}
                sx={classes.tableFooter}
                onPageChange={handleChangePage}
                onRowsPerPageChange={handleChangeRowsPerPage}
            />
            <TbxDrawer
                showDrawer={showAddContactForm}
                onClose={handleDrawerClose}
            >
                <AddContactForm
                    contact={selectedContact}
                    onCancel={handleDrawerClose}
                    onCreate={handleDrawerClose}
                />
            </TbxDrawer>
        </Box>
    )
}

CustomerContactsTab.propTypes = {
    customer: PropTypes.object.isRequired,
    organisation: PropTypes.object,
}

export default CustomerContactsTab
