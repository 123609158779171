import { useEffect, useState } from 'react'
import { useSelector } from 'react-redux'

import { selectMaterials } from '@/app/services/web-store/webStoreMaterial'

export function useQuoteItemPropertyOptions(props) {
    const { materialId: materialValue, organisationId } = props

    const materials = useSelector((state) => selectMaterials(state, { organisationId }))

    const [materialOptions, setMaterialOptions] = useState([])
    const [thicknessOptions, setThicknessOptions] = useState([])

    // Load materials
    useEffect(() => {
        if (materials) {
            setMaterialOptions(materials.slice().sort((a, b) => a.materialName.localeCompare(b.materialName)))
        }
    }, [materials])

    // Load thicknesses
    useEffect(() => {
        if (materialValue) {
            if (!materialOptions) {
                return
            }

            const material = materialOptions.find((material) => material.materialId === materialValue)
            const sheets = material?.sheets

            const thicknesses = sheets
                ?.map((sheet) => sheet.thickness)
                .sort(function (a, b) {
                    return parseFloat(a) - parseFloat(b)
                })
            setThicknessOptions(Array.from(new Set(thicknesses || [])))
        }
    }, [materialValue, materialOptions])

    return {
        materialOptions,
        thicknessOptions,
    }
}
