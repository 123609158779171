import { Box } from '@mui/material'
import PropTypes from 'prop-types'

import { useToolBoxTreatments } from '@/common/hooks'
import { getProductDetails } from '@/common/utils'

const classes = {
    root: {
        height: 128,
        px: 2,
        py: 1,
    },
}

const AppLogo = ({ className, logoUrl = null, onClick }) => {
    const { previewResellerName } = useToolBoxTreatments()

    const productDetails = getProductDetails(previewResellerName)
    const defaultLogoUrl = `${productDetails.logo}`

    const logoUrlToUse = logoUrl || defaultLogoUrl

    return (
        <Box
            alt="[Logo Image]"
            component="img"
            src={`${logoUrlToUse}`}
            sx={className || classes.root}
            onClick={onClick}
        />
    )
}

AppLogo.propTypes = {
    className: PropTypes.object,
    logoUrl: PropTypes.string,
    onClick: PropTypes.func,
}

export default AppLogo
