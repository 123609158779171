import { useTranslation } from 'react-i18next'
import { CheckBoxOutlineBlank, Delete, DragHandle, ExpandMore } from '@mui/icons-material'
import IconButton from '@mui/material/IconButton'
import Typography from '@mui/material/Typography'
import { Box } from '@mui/system'
import PropTypes from 'prop-types'

import QuoteItemStatus from '../common/QuoteItemStatus'

const classes = {
    Overlay: {
        borderRadius: 2,
        overflow: 'hidden',
        backgroundColor: 'action.hover',
    },

    OverlayContent: {
        px: 2,
        py: 1.5,
        display: 'flex',
        justifyContent: 'space-between',
        height: 74,
    },

    OverlayContentLeft: {
        gap: 1.5,
    },

    OverlayContentRight: {
        gap: 6,
    },

    OverlayContentColor: {
        color: 'text.secondary',
    },

    OverlayDetailsContent: {
        height: 436,
        backgroundColor: 'background.default',
    },
}

const SeekQuoteItemOverlay = ({ height, index }) => {
    const { t } = useTranslation()

    return (
        <Box
            id={index}
            index={index}
            sx={classes.Overlay}
        >
            <Box sx={classes.OverlayContent}>
                <Box
                    alignItems="center"
                    display="flex"
                    sx={classes.OverlayContentLeft}
                >
                    <Typography variant="body1">{index + 1}</Typography>
                    <IconButton
                        size="small"
                        sx={classes.OverlayContentColor}
                    >
                        <DragHandle />
                    </IconButton>

                    <CheckBoxOutlineBlank
                        fontSize="small"
                        sx={classes.OverlayContentColor}
                    />

                    <Box
                        alignItems="center"
                        display="flex"
                        gap={2}
                    >
                        <QuoteItemStatus status="overlay" />

                        <Typography sx={classes.OverlayContentColor}>{t('loading part...')}</Typography>
                    </Box>
                </Box>

                <Box
                    alignItems="center"
                    display="flex"
                    sx={classes.OverlayContentRight}
                >
                    <Box
                        alignItems="center"
                        display="flex"
                    >
                        <IconButton sx={classes.OverlayContentColor}>
                            <Delete />
                        </IconButton>

                        <ExpandMore sx={classes.OverlayContentColor} />
                    </Box>
                </Box>
            </Box>
            {height > 400 ? <Box sx={classes.OverlayDetailsContent}></Box> : null}
        </Box>
    )
}

SeekQuoteItemOverlay.propTypes = {
    height: PropTypes.number.isRequired,
    index: PropTypes.number.isRequired,
}

export default SeekQuoteItemOverlay
