import { Box, Dialog as MuiDialog } from '@mui/material'
import DialogActions from '@mui/material/DialogActions'
import DialogContent from '@mui/material/DialogContent'
import DialogTitle from '@mui/material/DialogTitle'
import { alpha } from '@mui/material/styles'
import PropTypes from 'prop-types'

import CrossIcon from '../../icons/CrossIcon/CrossIcon'
import IconButton from '../IconButton/IconButton'

const classes = {
    titleContainer: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'space-between',
    },
    closeIcon: {
        marginRight: '-12px',
    },
    actions: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        my: 2,
    },
    actionsStart: {
        justifyContent: 'flex-start',
    },
    actionsEnd: {
        justifyContent: 'flex-end',
    },
    dialogBackdrop: {
        backgroundColor: (theme) => alpha(theme.palette.text.primary, 0.9),
    },
}

const Dialog = ({
    actions = [],
    actionsPosition = 'center',
    children,
    className,
    contentClassName,
    fullWidth,
    maxWidth,
    onClose,
    open = false,
    showCloseButton = true,
    title,
    ...rest
}) => {
    const dialogActionsStyle = Object.assign(
        {},
        classes.actions,
        actionsPosition === 'start' ? classes.actionsStart : actionsPosition === 'end' ? classes.actionsEnd : {}
    )

    return (
        <MuiDialog
            fullWidth={fullWidth}
            maxWidth={maxWidth}
            open={open}
            PaperProps={{
                sx: className,
            }}
            slotProps={{
                backdrop: {
                    sx: classes.dialogBackdrop,
                },
            }}
            onClose={onClose}
            {...rest}
        >
            <DialogTitle>
                <Box sx={classes.titleContainer}>
                    {title}
                    {showCloseButton ? (
                        <IconButton
                            className={classes.closeIcon}
                            size="large"
                            onClick={onClose}
                        >
                            <CrossIcon />
                        </IconButton>
                    ) : null}
                </Box>
            </DialogTitle>
            <DialogContent sx={contentClassName}>{children}</DialogContent>
            {actions && actions.length > 0 ? (
                <DialogActions sx={dialogActionsStyle}>
                    {actions.map((action) => {
                        return action
                    })}
                </DialogActions>
            ) : null}
        </MuiDialog>
    )
}

Dialog.propTypes = {
    actions: PropTypes.arrayOf(PropTypes.node),
    actionsPosition: PropTypes.oneOf(['end', 'start', 'center']),
    children: PropTypes.node,
    className: PropTypes.object,
    contentClassName: PropTypes.object,
    fullWidth: PropTypes.bool,
    maxWidth: PropTypes.oneOf(['xs', 'sm', 'md', 'lg', 'xl', false]),
    open: PropTypes.bool,
    showCloseButton: PropTypes.bool,
    title: PropTypes.string,
    onClose: PropTypes.func,
}

export default Dialog
