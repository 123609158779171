import { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useSelector } from 'react-redux'
import { FormControl, FormHelperText, InputLabel, MenuItem, Select } from '@mui/material'
import PropTypes from 'prop-types'

import { selectLanguageList } from '@/app/slices/appSlice'

const LanguageSelect = ({
    disabled = false,
    error = false,
    fullWidth = true,
    helperText = '',
    nullValueOptionLabelText = '-',
    onBlur,
    onChange,
    required,
    showNullValueOption = false,
    value,
}) => {
    const { t } = useTranslation()

    const languageList = useSelector(selectLanguageList)

    const [currentLanguage, setCurrentLanguage] = useState('en-US')

    const handleOnChange = (event) => {
        const selectedLanguage = event.target.value

        setCurrentLanguage(selectedLanguage)

        if (typeof onChange === 'function') {
            onChange(selectedLanguage)
        }
    }

    const handleOnBlur = (event) => {
        const selectedLanguage = event.target.value
        setCurrentLanguage(selectedLanguage)

        if (typeof onBlur === 'function') {
            onBlur(selectedLanguage)
        }
    }

    const getLanguageFormattedName = (language) => {
        const nativeName = language.nativeName.split(',')[0]
        return `${nativeName[0].toUpperCase() + nativeName.substr(1)} (${language.region})`
    }

    useEffect(() => {
        value ? setCurrentLanguage(value) : setCurrentLanguage('')
    }, [value])

    return (
        <FormControl
                disabled={disabled}
                style={{ width: '100%' }}
        >
                <InputLabel
                    data-testid="organisation-language-select-label"
                    error={error}
                    id="language-select"
                >
                    {t('Language')}
                </InputLabel>
                <Select
                    fullWidth={fullWidth}
                    inputProps={{
                        'data-testid': 'organisation-language-select-input',
                    }}
                    labelId="language-select"
                    required={required}
                    value={currentLanguage}
                    onBlur={handleOnBlur}
                    onChange={handleOnChange}
                >
                    {showNullValueOption ? <MenuItem
                            key="none"
                            style={{ backgroundColor: '#708090' }}
                            value="none"
                                           >
                            {nullValueOptionLabelText}
                        </MenuItem> : null}
                    {Object.keys(languageList).map((language) => (
                        <MenuItem
                            key={language}
                            value={language}
                        >
                            {getLanguageFormattedName(languageList[language])}
                        </MenuItem>
                    ))}
                </Select>
                <FormHelperText error={error}>{t(helperText)}</FormHelperText>
            </FormControl>
    )
}

LanguageSelect.propTypes = {
    disabled: PropTypes.bool,
    error: PropTypes.bool,
    fullWidth: PropTypes.bool,
    helperText: PropTypes.string,
    nullValueOptionLabelText: PropTypes.string,
    required: PropTypes.bool,
    showNullValueOption: PropTypes.bool,
    value: PropTypes.string,
    onBlur: PropTypes.func,
    onChange: PropTypes.func,
}

export default LanguageSelect
