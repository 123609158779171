import { useTranslation } from 'react-i18next'
import { Button, Typography } from '@mui/material'
import PropTypes from 'prop-types'

import { Dialog } from '@/common/components'

const ArchiveRateDialog = ({ name = undefined, onCancelClose, onConfirmClose }) => {
    const { t } = useTranslation()

    const handleDeleteButtonClick = () => {
        if (typeof onConfirmClose === 'function') {
            onConfirmClose()
        }
    }

    return (
        <Dialog
            actions={[
                <Button
                    color="primary"
                    key="add"
                    variant="contained"
                    onClick={handleDeleteButtonClick}
                >
                    {t('delete')}
                </Button>,
                <Button
                    color="primary"
                    key="cancel"
                    variant="contained"
                    onClick={onCancelClose}
                >
                    {t('Cancel')}
                </Button>,
            ]}
            className={{ width: 680 }}
            open={true}
            title={t('Confirm thickness delete')}
            onClose={onCancelClose}
        >
            <Typography variant="body1">{t('Are you sure you wish to delete thickness') + ` ${name}?`}</Typography>
        </Dialog>
    )
}

ArchiveRateDialog.propTypes = {
    name: PropTypes.string,
    onCancelClose: PropTypes.func,
    onConfirmClose: PropTypes.func,
}

export default ArchiveRateDialog
