import { useTranslation } from 'react-i18next'
import { useSelector } from 'react-redux'
import { useNavigate, useParams } from 'react-router-dom'
import { Box, Grid, Typography } from '@mui/material'
import { useTheme } from '@mui/material/styles'

import { selectStoreSettings } from '@/app/services/organisation'
import { selectIsContactLoggedIn } from '@/app/slices/web-store/webStoreAuthSlice'

import Login from '../../components/Login/Login'

const classes = {
    root: (theme) => ({
        backgroundColor: theme.palette.background.paper,
        borderRadius: 1,
        border: `1px ${theme.palette.grey[300]} solid`,
        width: '100%',
    }),
    mainContent: {
        maxWidth: '600px',
        gap: 5,
    },
    mainContent_text: {
        gap: 4,
    },
    logInInput: {
        marginBottom: 2,
    },
    loginAction: {
        paddingInline: 2,
        gap: 2,
    },
    smallText: {
        fontSize: '0.75rem',
    },
}

const LoginPage = () => {
    const { organisationId } = useParams()
    const theme = useTheme()
    const { t } = useTranslation()

    const navigate = useNavigate()

    const isContactLoggedIn = useSelector(selectIsContactLoggedIn)
    const storeSettings = useSelector((state) => selectStoreSettings(state, { organisationId }))

    if (isContactLoggedIn && !sessionStorage.getItem('redirect')) {
        navigate(`/store/${organisationId}/`)
    }

    return (
        <>
            <Grid
                component="section"
                md={7}
                xs={12}
                container
                item
            >
                <Box
                    alignItems="center"
                    display="flex"
                    justifyContent="center"
                    p={6}
                    sx={classes.root}
                >
                    <Box
                        display="flex"
                        flexDirection="column"
                        sx={classes.mainContent}
                    >
                        <Box
                            display="flex"
                            flexDirection="column"
                            sx={classes.mainContent_text}
                        >
                            <Typography variant="h2">
                                {storeSettings?.webStoreHeadingParagraph ||
                                    t(`Welcome to ${storeSettings.webStoreDisplayName || 'our store'}`)}
                            </Typography>
                            <Typography variant="h5">
                                {storeSettings?.webStoreSubheadingParagraph ||
                                    t('Upload your Parts, get your price and make a payment.')}
                            </Typography>
                        </Box>
                    </Box>
                </Box>
            </Grid>

            <Grid
                component="aside"
                md={5}
                xs={12}
                container
                item
            >
                <Box
                    display="flex"
                    flexDirection={{ xs: 'row', md: 'column' }}
                    p={3}
                    sx={classes.root(theme)}
                >
                    <Login showRequestAccessButton={false} />
                </Box>
            </Grid>
        </>
    )
}

export default LoginPage
