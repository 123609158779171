import { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useSelector } from 'react-redux'
import { CloseRounded } from '@mui/icons-material'
import {
    Button,
    Dialog,
    DialogActions,
    DialogContent,
    DialogTitle,
    FormControl,
    FormHelperText,
    IconButton,
    InputLabel,
    MenuItem,
    Select,
    Typography,
} from '@mui/material'
import { useSnackbar } from 'notistack'
import PropTypes from 'prop-types'

import { useTransferOwnershipMutation } from '@/app/services/organisation'
import { selectOrganisationId } from '@/app/slices/organisationSlice'
import { AlertDialog } from '@/common/components'

const TransferOwnershipDialog = ({ onCancelClose, onTransferClose, open = false, users }) => {
    const { t } = useTranslation()
    const { enqueueSnackbar } = useSnackbar()

    const organisationId = useSelector(selectOrganisationId)

    const [transferOwnership, { isLoading }] = useTransferOwnershipMutation()

    const [selectedUser, setSelectedUser] = useState('')

    const [showTransferConfirmDialog, setShowTransferConfirmDialog] = useState(false)

    useEffect(() => {
        if (users && users.length > 0) {
            setSelectedUser(users[0])
        }
    }, [users])

    const handleChange = (event) => {
        const selectedUserId = event.target.value

        const selectedUserIdx = users.findIndex((u) => u.userId === selectedUserId)
        setSelectedUser(users[selectedUserIdx])
    }

    const handleTransferConfirm = async () => {
        setShowTransferConfirmDialog(false)

        try {
            await transferOwnership({ organisationId, userId: selectedUser.userId }).unwrap()

            enqueueSnackbar(t('Ownership transferred successfully'), {
                variant: 'success',
            })

            if (typeof onTransferClose === 'function') {
                onTransferClose()
            }
        } catch (error) {
            console.error(error)
            enqueueSnackbar(t('Failed to transfer ownership'), {
                variant: 'error',
            })
        }
    }

    return (
        <>
            <Dialog
                maxWidth="xs"
                open={open}
                sx={{
                    '& .MuiDialog-paper': {
                        width: '100%',
                        maxWidth: 380,
                        borderRadius: 2,
                        p: 2,
                    },
                }}
                fullWidth
                onClose={onCancelClose}
            >
                <DialogTitle
                    alignItems="center"
                    display="flex"
                    justifyContent="space-between"
                    sx={{ p: 2 }}
                >
                    <Typography variant="h6">{t('Transfer ownership')}</Typography>
                    <IconButton
                        size="small"
                        onClick={onCancelClose}
                    >
                        <CloseRounded fontSize="small" />
                    </IconButton>
                </DialogTitle>

                <DialogContent sx={{ p: 2 }}>
                    <FormControl>
                        <InputLabel datatest-id="material-type-label">{t('Role')} *</InputLabel>
                        <Select
                            label={t('Role')}
                            value={selectedUser?.userId}
                            variant="standard"
                            fullWidth
                            required
                            onChange={handleChange}
                        >
                            {users.map((user) => (
                                <MenuItem
                                    key={user.userId}
                                    value={user.userId}
                                >
                                    {`${user.firstName} ${user.lastName}`}
                                </MenuItem>
                            ))}
                        </Select>
                        <FormHelperText>
                            {t('Ownership can only be transferred to other administrators')}
                        </FormHelperText>
                    </FormControl>
                </DialogContent>

                <DialogActions sx={{ p: 2 }}>
                    <Button
                        color="secondary"
                        disabled={isLoading}
                        key="cancel"
                        variant="outlined"
                        onClick={onCancelClose}
                    >
                        {t('Cancel')}
                    </Button>
                    <Button
                        color="primary"
                        key="transfer-button"
                        variant="contained"
                        onClick={() => setShowTransferConfirmDialog(true)}
                    >
                        {t('Transfer')}
                    </Button>
                </DialogActions>
            </Dialog>
            <AlertDialog
                content={t(
                    'Are you sure want to transfer ownership to {{userFullname}}? Access to subscription management will transfer from you to {{userFullname}}. This action will take effect immediately.',
                    {
                        userFullname: selectedUser && `${selectedUser.firstName} ${selectedUser.lastName}`,
                    }
                )}
                okButtonText={t('Yes, Transfer')}
                open={showTransferConfirmDialog}
                title={t('Transfer ownership')}
                onCancelClose={() => setShowTransferConfirmDialog(false)}
                onOkClose={handleTransferConfirm}
            />
        </>
    )
}

TransferOwnershipDialog.propTypes = {
    users: PropTypes.arrayOf(
        PropTypes.shape({
            firstName: PropTypes.string.isRequired,
            lastName: PropTypes.string.isRequired,
            userId: PropTypes.string.isRequired,
        })
    ).isRequired,
    open: PropTypes.bool,
    onCancelClose: PropTypes.func,
    onTransferClose: PropTypes.func,
}

export default TransferOwnershipDialog
