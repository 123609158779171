import { Typography } from '@mui/material'
import PropTypes from 'prop-types'

const classes = {
    title: {
        fontWeight: 500,
        fontSize: ['14px', '18px'],
        lineHeight: ['16px', '21px'],
        textTransform: 'uppercase',
        color: 'text.primary',
    },
}

const TbxToolbarTitle = ({ children }) => {
    return <Typography sx={classes.title}>{children}</Typography>
}

TbxToolbarTitle.propTypes = {
    children: PropTypes.node,
}

export default TbxToolbarTitle
