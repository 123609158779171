import { Trans, useTranslation } from 'react-i18next'
import { useSelector } from 'react-redux'
import { Close } from '@mui/icons-material'
import { Box, Grid, Link } from '@mui/material'
import Dialog from '@mui/material/Dialog'
import DialogContent from '@mui/material/DialogContent'
import MuiDialogTitle from '@mui/material/DialogTitle'
import IconButton from '@mui/material/IconButton'
import Typography from '@mui/material/Typography'
import PropTypes from 'prop-types'
import { v4 as uuidv4 } from 'uuid'

import { useGetCuttingTechnologiesQuery } from '@/app/services/cuttingTechnologies'
import {
    useDeleteMaterialRateTableMutation,
    useGetMaterialsQuery,
    useUpdateMaterialRateTableMutation,
} from '@/app/services/materials'
import { useGetRateTablesQuery } from '@/app/services/rateTables'
import { selectSelectedMaterialId } from '@/app/slices/materialsSlice'
import {
    selectIsOnFreePlan,
    selectOrganisationId,
    selectPaidFeatures,
    selectReduceTrialAccess,
} from '@/app/slices/organisationSlice'
import { Input, SelectOption, TbxTooltip } from '@/common/components'
import UpgradePlanLink from '@/common/components/UpgradePlanLink/UpgradePlanLink'
import { AddIcon } from '@/common/icons'

const classes = {
    root: {
        margin: 0,
        padding: 2,
    },
    closeButton: {
        position: 'absolute',
        right: 1,
        top: 1,
        color: 'grey[500]',
    },
    selectInput: {
        width: 200,
    },
    inline: {
        display: 'inline-block',
        marginRight: 2,
        verticalAlign: '-webkit-baseline-middle',
    },
    addNewCuttingTechText: {
        display: 'inline-flex',
        cursor: 'pointer',
    },
    cuttingTechRateTableGrid: {
        width: 650,
        py: 2,
    },
    title: {
        fontWeight: 700,
        fontSize: '16px',
        lineHeight: '150%',
        letterSpacing: '0.15px',
        color: 'text.primary',
    },
}

const DialogTitle = ({ children, onClose, ...other }) => {
    return (
        <MuiDialogTitle
            sx={classes.root}
            disableTypography
            {...other}
        >
            <Typography variant="h6">{children}</Typography>
            {onClose ? (
                <IconButton
                    aria-label="close"
                    size="large"
                    sx={classes.closeButton}
                    onClick={onClose}
                >
                    <Close />
                </IconButton>
            ) : null}
        </MuiDialogTitle>
    )
}

DialogTitle.propTypes = {
    onClose: PropTypes.func,
}

const MaterialRateTableDialog = ({ addCuttingTech, addRateTable, onClose, showDialog = false, title = '' }) => {
    const { t } = useTranslation()

    const organisationId = useSelector(selectOrganisationId)
    const paidFeatures = useSelector(selectPaidFeatures)
    const reduceTrialAccess = useSelector(selectReduceTrialAccess)
    const isOnFreePlan = useSelector(selectIsOnFreePlan)

    const { data: materials } = useGetMaterialsQuery({ organisationId })
    const { data: cuttingTechnologies } = useGetCuttingTechnologiesQuery({ organisationId })
    const { data: rateTables } = useGetRateTablesQuery({ organisationId })

    const selectedMaterialId = useSelector(selectSelectedMaterialId)
    const selectedMaterial = materials[selectedMaterialId]

    const [deleteMaterialRateTable] = useDeleteMaterialRateTableMutation()
    const [updateMaterialRateTable] = useUpdateMaterialRateTableMutation()

    const createRateTableOnChangeFunction = (materialRateTableId, materialId) => async (value) => {
        const VALUE_TO_COMPARE = value?.target?.value
        if (VALUE_TO_COMPARE === 'deselect' || VALUE_TO_COMPARE === -1) {
            await deleteMaterialRateTable({ organisationId, materialRateTableId })
        }
        //if we are adding a new rate table we have to update the MaterialRateTable at a later point
        else if (VALUE_TO_COMPARE !== 'add new rate table') {
            if (!materialRateTableId) {
                materialRateTableId = uuidv4()
            }
            await updateMaterialRateTable({
                organisationId,
                materialRateTable: { materialRateTableId, materialId, rateTableId: VALUE_TO_COMPARE },
            })
        }
    }

    const materialRateTables = selectedMaterial?.materialRateTables
        ? Object.assign(
              {},
              ...(selectedMaterial?.materialRateTables.map((rt) => ({
                  [rateTables[rt.rateTableId]?.cuttingTechnologyId]: {
                      rateTableId: rt.rateTableId,
                      materialRateTableId: rt.materialRateTableId,
                  },
              })) ?? {})
          )
        : {}
    const cuttingTechnologiesArray = cuttingTechnologies ? Object.values(cuttingTechnologies) : []

    const rateTablesArray = rateTables ? Object.values(rateTables) : []

    return (
        <Dialog
            aria-labelledby="nest-dialog"
            maxWidth="md"
            open={showDialog}
            onClose={onClose}
        >
            <DialogTitle
                id="nest-dialog-title"
                onClose={onClose}
            >
                {title}
            </DialogTitle>
            <DialogContent dividers>
                <Grid
                    spacing={2}
                    sx={classes.cuttingTechRateTableGrid}
                    container
                >
                    <Grid
                        justifyContent="center"
                        container
                    >
                        <Grid
                            xs={4}
                            item
                        >
                            <Typography sx={classes.title}>{t('Cutting Technologies')}</Typography>
                        </Grid>
                        <Grid
                            xs={4}
                            item
                        >
                            <Typography sx={classes.title}>{t('Rate Tables')}</Typography>
                        </Grid>
                    </Grid>
                    {cuttingTechnologiesArray
                        ?.sort((a, b) => a?.name?.localeCompare(b?.name))
                        .filter((ct) => !ct?.isDeleted && selectedMaterial?.type === ct?.type)
                        .map((cuttingTech) => (
                            <Grid
                                justifyContent="center"
                                key={cuttingTech.cuttingTechnologyId}
                                container
                            >
                                <Grid
                                    xs={4}
                                    item
                                >
                                    <Typography sx={classes.inline}>{cuttingTech.name}</Typography>
                                </Grid>
                                <TbxTooltip
                                    leaveDelay={500}
                                    title={
                                        !paidFeatures.hasAdvancedMaterials &&
                                        !paidFeatures.hasAdvancedMaterials &&
                                        !cuttingTech.isDefault ? (
                                            <UpgradePlanLink />
                                        ) : (
                                            ''
                                        )
                                    }
                                    arrow
                                    disableFocusListener
                                    disableTouchListener
                                >
                                    <Grid
                                        xs={4}
                                        item
                                    >
                                        <Input
                                            className={classes.inline}
                                            disabled={
                                                !paidFeatures.hasAdvancedMaterials ? !cuttingTech.isDefault : null
                                            }
                                            hideLabel={true}
                                            inputProps={{
                                                sx: classes.selectInput,
                                            }}
                                            type="select"
                                            value={materialRateTables[cuttingTech.cuttingTechnologyId]?.rateTableId}
                                            onChange={createRateTableOnChangeFunction(
                                                materialRateTables[cuttingTech.cuttingTechnologyId]
                                                    ?.materialRateTableId,
                                                selectedMaterial?.materialId
                                            )}
                                        >
                                            {materialRateTables[cuttingTech.cuttingTechnologyId]?.rateTableId ? (
                                                <SelectOption
                                                    key="deselect"
                                                    value="deselect"
                                                >
                                                    {'-'}
                                                </SelectOption>
                                            ) : undefined}
                                            {rateTablesArray
                                                .filter(
                                                    (rt) =>
                                                        rt.cuttingTechnologyId === cuttingTech.cuttingTechnologyId &&
                                                        !rt.isDeleted
                                                )
                                                ?.sort((a, b) => a.name?.localeCompare(b.name))
                                                ?.map((rateTable) => {
                                                    return (
                                                        <SelectOption
                                                            key={rateTable.rateTableId}
                                                            value={rateTable.rateTableId}
                                                        >
                                                            {rateTable.name}
                                                        </SelectOption>
                                                    )
                                                })}
                                            <TbxTooltip
                                                leaveDelay={500}
                                                title={
                                                    isOnFreePlan ? (
                                                        <Trans t={t}>
                                                            Not available in free mode.{' '}
                                                            <Link href="/activate">Subscribe here.</Link>
                                                        </Trans>
                                                    ) : reduceTrialAccess ? (
                                                        <Trans t={t}>
                                                            Not available in trial mode.{' '}
                                                            <Link href="/activate">Subscribe here.</Link>
                                                        </Trans>
                                                    ) : (
                                                        ''
                                                    )
                                                }
                                                arrow
                                            >
                                                <Box
                                                    onClick={reduceTrialAccess ? (e) => e.stopPropagation() : undefined}
                                                >
                                                    <SelectOption
                                                        disabled={reduceTrialAccess}
                                                        key={'add new'}
                                                        value={'add new rate table'}
                                                        onClick={() =>
                                                            addRateTable(
                                                                cuttingTech?.cuttingTechnologyId,
                                                                materialRateTables[cuttingTech?.cuttingTechnologyId]
                                                                    ?.materialRateTableId
                                                            )
                                                        }
                                                    >
                                                        <Link sx={classes.addNewCuttingTechText}>
                                                            <AddIcon />
                                                            <Typography>{t('Add new')}</Typography>
                                                        </Link>
                                                    </SelectOption>
                                                </Box>
                                            </TbxTooltip>
                                        </Input>
                                    </Grid>
                                </TbxTooltip>
                            </Grid>
                        ))}
                    <Grid
                        justifyContent="center"
                        key={'addNew'}
                        container
                    >
                        <Grid
                            xs={4}
                            item
                        >
                            {paidFeatures.hasAdvancedMaterials && !reduceTrialAccess ? (
                                <Link
                                    sx={classes.addNewCuttingTechText}
                                    onClick={reduceTrialAccess ? null : addCuttingTech}
                                >
                                    <AddIcon />
                                    <Typography
                                        disableTypography={true}
                                        onClick={addCuttingTech}
                                    >
                                        {t('Add new')}
                                    </Typography>
                                </Link>
                            ) : null}
                        </Grid>
                        <Grid
                            xs={4}
                            item
                        ></Grid>
                    </Grid>
                </Grid>
            </DialogContent>
        </Dialog>
    )
}

MaterialRateTableDialog.propTypes = {
    addCuttingTech: PropTypes.func,
    addRateTable: PropTypes.func,
    showDialog: PropTypes.bool,
    title: PropTypes.string,
    onClose: PropTypes.func,
}

export default MaterialRateTableDialog
