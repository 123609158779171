import { Controller } from 'react-hook-form'
import { useTranslation } from 'react-i18next'
import { useSelector } from 'react-redux'
import { Link } from 'react-router-dom'
import { FormControlLabel, Radio, RadioGroup, TextField, Typography } from '@mui/material'
import Grid from '@mui/material/Unstable_Grid2'
import PropTypes from 'prop-types'

import { selectUseImperialUnits } from '@/app/slices/organisationSlice'
import { useToolBoxTreatments } from '@/common/hooks'
import { DELIVERY_PRICING_METHODS, DELIVERY_PRICING_OPTIONS, QUOTE_SOURCE_TYPES } from '@/common/utils'
import FormInputAddress from '@/features/web-store/components/shared/FormComponents/FormInputAddress'

const ShippingDefaults = ({ form }) => {
    const { t } = useTranslation()
    const { showShipping } = useToolBoxTreatments()
    const { control, watch } = form

    const isImperial = useSelector(selectUseImperialUnits)

    const defaultpickupAddress = 'View our website for our current address'

    const deliveryPricingOption = watch('deliveryPricingOption')
    const internalDefaultDeliveryMethod = watch('internalDefaultDeliveryMethod')

    const DELIVERY_PRICING_METHODS_INTERNAL = Object.values(DELIVERY_PRICING_METHODS).filter((method) =>
        method.sources.includes(QUOTE_SOURCE_TYPES.Internal)
    )

    return (
        <Grid
            marginBlock={0}
            paddingInline={2}
            spacing={3}
            xs={12}
            container
        >
            <Grid xs={12}>
                <Typography
                    data-testid="shipping-defaults-setting"
                    id="shipping-defaults"
                    variant="h6"
                >
                    {t('Defaults')}
                </Typography>
            </Grid>

            <Grid xs={12}>
                <Typography
                    data-testid="shipping-pickup-setting-title"
                    variant="strong1"
                >
                    {t('Pickup')}
                </Typography>
            </Grid>
            <Grid xs={12}>
                <FormInputAddress
                    control={control}
                    data-testid="shipping-pickup-setting-input"
                    label={t('Pickup address')}
                    name="pickupAddress"
                    placeholder={t(defaultpickupAddress)}
                    variant="standard"
                />
            </Grid>
            <Grid
                mt={-2}
                xs={12}
            >
                <Typography
                    color="text.secondary"
                    variant="small"
                >
                    {t('Please provide a pickup address to display when orders are marked for pickup')}
                </Typography>
            </Grid>

            {showShipping ? (
                <Grid xs={12}>
                    <Typography
                        data-testid="shipping-delivery-setting"
                        mb={1}
                        variant="strong1"
                    >
                        {t('Delivery pricing method')}
                    </Typography>
                    <Typography
                        color="text.secondary"
                        mb={2}
                        variant="body2"
                    >
                        {t(
                            "Set the default delivery pricing method when a quote's shipping option is set to Delivery. This selection can be overridden inside the quote."
                        )}
                    </Typography>

                    <Controller
                        control={control}
                        key="internalDefaultDeliveryMethod"
                        name="internalDefaultDeliveryMethod"
                        render={({ field: { onChange, value } }) => (
                            <RadioGroup
                                data-testid="shipping-delivery-setting-options"
                                sx={{ marginBottom: 2 }}
                                value={value}
                                onChange={(e, value) => {
                                    onChange(value)
                                }}
                            >
                                {DELIVERY_PRICING_METHODS_INTERNAL.map((option) => (
                                    <>
                                        <FormControlLabel
                                            control={<Radio color="primary" />}
                                            data-testid={`shipping-delivery-setting-option-${option.value}`}
                                            key={option.value}
                                            label={t(option.label)}
                                            slotProps={{
                                                typography: {
                                                    variant: 'body1',
                                                    color: 'text.secondary',
                                                },
                                            }}
                                            value={option.value}
                                        />

                                        {option.value === 'ChargeToOrder' ? (
                                            <Typography
                                                color="text.secondary"
                                                ml={4}
                                                mt={-1}
                                                variant="body2"
                                            >
                                                {t('Selected pricing option:')}{' '}
                                                <strong>
                                                    {DELIVERY_PRICING_OPTIONS.find(
                                                        (option) => option.value === deliveryPricingOption
                                                    )?.label(isImperial)}
                                                </strong>
                                                {'. '}
                                                {t('Manage the charge settings')}{' '}
                                                <Link to="#delivery-prices">{t('here.')}</Link>
                                            </Typography>
                                        ) : null}
                                    </>
                                ))}
                            </RadioGroup>
                        )}
                    />

                    <Controller
                        control={control}
                        key={internalDefaultDeliveryMethod}
                        name="internalPreferredDeliveryProvider"
                        render={({ field: { onBlur, onChange, value } }) => (
                            <TextField
                                disabled={internalDefaultDeliveryMethod !== 'PayUponDelivery'}
                                helperText={t('Add the name of the preferred delivery provider. Optional')}
                                InputLabelProps={{
                                    'data-testid': 'preferred-delivery-provider-label',
                                }}
                                inputProps={{
                                    'data-testid': 'preferred-delivery-provider-input',
                                }}
                                label={t('Preferred delivery provider')}
                                value={value}
                                fullWidth
                                onBlur={onBlur}
                                onChange={onChange}
                            />
                        )}
                    />
                </Grid>
            ) : null}
        </Grid>
    )
}

ShippingDefaults.propTypes = {
    form: PropTypes.object.isRequired,
}

export default ShippingDefaults
