import { forwardRef } from 'react'
import { useTranslation } from 'react-i18next'
import { Archive, Close, Delete, Save } from '@mui/icons-material'
import { Box, Dialog, IconButton, Slide, Typography } from '@mui/material'
import PropTypes from 'prop-types'

const classes = {
    dialogContent: {
        paddingBottom: '20px',
    },
    dialogTitleIcon: {
        padding: '0 0 0 10px',
        color: '#5E7387',
    },
    titleContainer: {
        display: 'flex',
        justifyContent: 'space-between',
        alignItems: 'center',
    },
    title: {
        display: 'flex',
        fontSize: '16px',
        lineHeight: '24px',
        fontWeight: 700,
    },
    flex: {
        display: 'flex',
    },
    modalBackdrop: {
        display: 'none',
        backgroundColor: 'transparent',
    },
    modalPaper: {
        display: 'block',
        position: 'absolute',
        top: '15rem',
        left: '10rem',
        pointerEvents: 'auto',
    },
    modal: {
        padding: '24px',
        minWidth: '224px',
        display: 'flex',
        flexFlow: 'column',
        gap: '24px',
    },
    dialog: {
        pointerEvents: 'none',
    },
}

const Transition = forwardRef(function Transition(props, ref) {
    return (
        <Slide
            direction="right"
            ref={ref}
            {...props}
        />
    )
})

const TbxBulkEditModal = ({
    children,
    handleArchive,
    handleClose,
    handleSave,
    items,
    open,
    showDeleteIcon = false,
}) => {
    const { t } = useTranslation()

    const getTitle = () => {
        if (!items) return ''

        return items.length === 1
            ? t('Editing {{length}} row', { length: items.length })
            : t('Editing {{length}} rows', { length: items.length })
    }

    const handleArchiveClick = () => handleArchive(items)
    const handleSaveClick = () => handleSave(items)
    const handleCloseClick = (_, reason) => {
        reason !== 'backdropClick' && handleClose()
    }

    return (
        <Dialog
            open={!!open}
            PaperProps={{
                sx: classes.modalPaper,
            }}
            sx={classes.dialog}
            TransitionComponent={Transition}
            disableAutoFocus
            disableEnforceFocus
            disableScrollLock
            hideBackdrop
            keepMounted
            onClose={handleCloseClick}
        >
            <Box sx={classes.modal}>
                <Box sx={classes.titleContainer}>
                    <Typography sx={classes.title}>{getTitle()}</Typography>
                    <Box sx={classes.flex}>
                        <IconButton
                            aria-label="save"
                            sx={classes.dialogTitleIcon}
                            onClick={handleSaveClick}
                        >
                            <Save />
                        </IconButton>
                        <IconButton
                            aria-label="close"
                            sx={classes.dialogTitleIcon}
                            onClick={handleCloseClick}
                        >
                            <Close />
                        </IconButton>
                        <IconButton
                            aria-label="archive"
                            sx={classes.dialogTitleIcon}
                            onClick={handleArchiveClick}
                        >
                            {showDeleteIcon ? <Delete /> : <Archive />}
                        </IconButton>
                    </Box>
                </Box>
                {children}
            </Box>
        </Dialog>
    )
}

TbxBulkEditModal.propTypes = {
    children: PropTypes.node,
    handleArchive: PropTypes.func,
    handleClose: PropTypes.func,
    handleSave: PropTypes.func,
    items: PropTypes.arrayOf(PropTypes.object),
    open: PropTypes.bool,
    showDeleteIcon: PropTypes.bool,
}

export default TbxBulkEditModal
