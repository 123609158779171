import { useState } from 'react'
import { Controller } from 'react-hook-form'
import { useTranslation } from 'react-i18next'
import Script from 'react-load-script'
import { LocationOn } from '@mui/icons-material'
import { Grid, Typography } from '@mui/material'
import Autocomplete from '@mui/material/Autocomplete'
import TextField from '@mui/material/TextField'
import parse from 'autosuggest-highlight/parse'
import PropTypes from 'prop-types'
import usePlacesAutocomplete, { getDetails } from 'use-places-autocomplete'

const classes = {
    icon: {
        color: 'text.secondary',
        marginRight: 2,
    },
}

const AddressAutocomplete = ({
    control,
    disabled = false,
    hideError = false,
    inputLabelProps = {},
    label,
    name,
    onAddressChanged,
    onAddressClear,
    placeholder = '',
    required = false,
    rules,
    shouldUnregister = false,
    variant = 'outlined',
}) => {
    const { t } = useTranslation()

    const {
        clearSuggestions,
        ready,
        setValue,
        suggestions: { data },
        value: address,
    } = usePlacesAutocomplete({
        debounce: 300,
    })

    const handleChange = async (name, newInputValue, reason, onChange) => {
        if (reason === 'clear') {
            clearSuggestions()
            if (onAddressClear && onAddressClear instanceof Function) {
                onAddressClear(name.split('.')[0])
            }
        }
        onChange(newInputValue ?? '')

        if (newInputValue && onAddressChanged && onAddressChanged instanceof Function) {
            const parameter = {
                placeId: newInputValue?.place_id,
            }

            try {
                const result = await getDetails(parameter)
                onAddressChanged(name.split('.')[0], result)
            } catch (error) {
                console.error(error)
            } finally {
                clearSuggestions()
            }
        }
    }

    return (
        <Controller
            control={control}
            disabled={disabled}
            name={name}
            render={({ field: { onChange, value }, fieldState }) => (
                <Autocomplete
                    disabled={!ready || disabled}
                    filterOptions={(options) => options}
                    getOptionKey={(option) => option.place_id}
                    getOptionLabel={(option) => (typeof option === 'string' ? option : option.description)}
                    id="google-places-address"
                    inputValue={address ?? ''}
                    isOptionEqualToValue={(option, value) => option.place_id === value.place_id}
                    options={data}
                    renderInput={(params) => (
                        <TextField
                            {...params}
                            error={fieldState.error ? !hideError : null}
                            helperText={hideError ? null : t(fieldState.error?.message)}
                            InputLabelProps={inputLabelProps}
                            label={label}
                            placeholder={placeholder}
                            required={required}
                            variant={variant}
                            fullWidth
                        />
                    )}
                    renderOption={(props, option) => {
                        const { key, ...rest } = props
                        const matches = option?.structured_formatting?.main_text_matched_substrings || []
                        const parts = parse(
                            option?.structured_formatting?.main_text,
                            matches?.map((match) => [match?.offset, match?.offset + match?.length])
                        )
                        return (
                            <Grid
                                alignItems="center"
                                component="li"
                                key={key}
                                container
                                {...rest}
                            >
                                <Grid item>
                                    <LocationOn sx={classes.icon} />
                                </Grid>
                                <Grid
                                    item
                                    xs
                                >
                                    {parts.map((part, index) => (
                                        <span
                                            key={index}
                                            style={{ fontWeight: part.highlight ? 700 : 400 }}
                                        >
                                            {part.text}
                                        </span>
                                    ))}

                                    <Typography
                                        color="textSecondary"
                                        variant="body2"
                                    >
                                        {option.structured_formatting.secondary_text}
                                    </Typography>
                                </Grid>
                            </Grid>
                        )
                    }}
                    value={value ?? null}
                    autoComplete
                    clearOnEscape
                    fullWidth
                    includeInputInList
                    onChange={(_event, newInputValue, reason) => {
                        handleChange(name, newInputValue, reason, onChange)
                    }}
                    onInputChange={(_event, newInputValue) => {
                        setValue(newInputValue)
                    }}
                />
            )}
            rules={rules}
            shouldUnregister={shouldUnregister}
        />
    )
}

AddressAutocomplete.propTypes = {
    control: PropTypes.object.isRequired,
    label: PropTypes.string.isRequired,
    name: PropTypes.string.isRequired,
    disabled: PropTypes.bool,
    hideError: PropTypes.bool,
    inputLabelProps: PropTypes.object,
    key: PropTypes.string,
    placeholder: PropTypes.string,
    required: PropTypes.bool,
    rules: PropTypes.object,
    shouldUnregister: PropTypes.bool,
    variant: PropTypes.oneOf(['outlined', 'standard', 'filled']),
    onAddressChanged: PropTypes.func,
    onAddressClear: PropTypes.func,
}

const FormInputAddress = (props) => {
    const [scriptLoaded, setScriptLoaded] = useState(false)

    const handleScriptLoad = () => {
        setScriptLoaded(true)
    }

    return (
        <>
            <Script
                url={`https://maps.googleapis.com/maps/api/js?key=${
                    import.meta.env.VITE_GOOGLE_API_KEY
                }&libraries=places`}
                onLoad={handleScriptLoad}
            />

            {scriptLoaded ? <AddressAutocomplete {...props} /> : null}
        </>
    )
}

FormInputAddress.propTypes = {
    control: PropTypes.object.isRequired,
    label: PropTypes.string.isRequired,
    name: PropTypes.string.isRequired,
    disabled: PropTypes.bool,
    hideError: PropTypes.bool,
    inputLabelProps: PropTypes.object,
    placeholder: PropTypes.string,
    required: PropTypes.bool,
    rules: PropTypes.object,
    shouldUnregister: PropTypes.bool,
    variant: PropTypes.oneOf(['outlined', 'standard', 'filled']),
    onAddressChanged: PropTypes.func,
    onAddressClear: PropTypes.func,
}

export default FormInputAddress
