import { useTranslation } from 'react-i18next'
import { Box, Typography } from '@mui/material'
import PropTypes from 'prop-types'

import { getAddressDetails } from '@/common/helpers/addressUtilities'

const AccountingDocumentsCustomerInfo = ({
    customer,
    customerContact,
    deliveryAddress,
    deliveryOption,
    deliveryPricingMethod,
    lastModifiedByUserName,
    pickupAddress,
}) => {
    const { t } = useTranslation()
    return (
        <Box
            display="flex"
            flexDirection="column"
            gap={1.5}
        >
            <Box
                display="flex"
                gap={1}
            >
                <Typography
                    color="text.secondary"
                    variant="strong2"
                >
                    {t('Last modified by:')}
                </Typography>
                <Typography variant="body2">{`${lastModifiedByUserName}`}</Typography>
            </Box>
            <Box
                display="flex"
                flexDirection="column"
                gap={0.5}
            >
                <Box
                    display="flex"
                    gap={1}
                >
                    <Typography
                        color="text.secondary"
                        variant="strong2"
                    >
                        {t('Customer:')}
                    </Typography>
                    <Typography variant="strong2">{customer ? customer.companyName : ''}</Typography>
                </Box>
                <Box
                    display="flex"
                    flexDirection="column"
                    gap={0.5}
                >
                    <Typography variant="body2">
                        {[customerContact?.name, customerContact?.email, customerContact?.phone]
                            .filter(Boolean)
                            .join(' | ')}
                    </Typography>
                </Box>
            </Box>
            <Box
                className="quote-shipping-info"
                display="flex"
                flexDirection="column"
                gap={0.5}
            >
                <Typography
                    color="text.secondary"
                    variant="strong1"
                >
                    {t('Shipping')}
                </Typography>

                <Typography variant="body2">
                    {deliveryOption === 0
                        ? `${t('Pickup from ')} ${getAddressDetails(pickupAddress) || t('N/A')}`
                        : `${t('Delivery to ')} ${
                              getAddressDetails(deliveryAddress) || t('N/A')
                        }. ${deliveryPricingMethod ? `(${deliveryPricingMethod})` : ''}`}
                </Typography>
            </Box>
        </Box>
    )
}

AccountingDocumentsCustomerInfo.propTypes = {
    customer: PropTypes.object,
    customerContact: PropTypes.object,
    deliveryAddress: PropTypes.object,
    deliveryOption: PropTypes.number,
    deliveryPricingMethod: PropTypes.string,
    lastModifiedByUserName: PropTypes.string,
    pickupAddress: PropTypes.object,
}

export default AccountingDocumentsCustomerInfo
