import { Navigate, Route, Routes } from 'react-router-dom'

import ScrollToTop from './components/shared/ScrollToTop'
import Home from './pages/home/Home'
import Layout from './pages/layout/Layout'
import LoginPage from './pages/login/Login'
import NotFound from './pages/notFound/NotFound'
import Order from './pages/order/Order'
import OrderConfirmation from './pages/orderConfirmation/OrderConfirmation'
import Quote from './pages/quote/Quote'

import './style/main.css'

const Index = () => {
    return (
        <>
            <ScrollToTop />
            <Layout>
                <Routes>
                    <Route
                        element={<Home />}
                        path={'/'}
                    />

                    <Route
                        element={<LoginPage />}
                        path={'/login'}
                    />

                    <Route
                        element={<NotFound />}
                        path={'/not-found'}
                    />

                    <Route
                        element={<OrderConfirmation />}
                        path={'/:quoteId/order-confirmation'}
                    />

                    <Route
                        element={<Order />}
                        path={'/:quoteId/checkout'}
                    />

                    <Route
                        element={<Quote />}
                        path={'/:quoteId'}
                    />

                    <Route
                        element={<Navigate to={'/not-found'} />}
                        path="*"
                    />
                </Routes>
            </Layout>
        </>
    )
}

export default Index
