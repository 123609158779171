import { useTranslation } from 'react-i18next'
import { useSelector } from 'react-redux'
import { useParams } from 'react-router-dom'
import { Dialog, DialogContent } from '@mui/material'
import { useSnackbar } from 'notistack'
import PropTypes from 'prop-types'

import { useAddQuoteItemsFromPDFMutation } from '@/app/services/quoteItems'
import { selectOrganisationId } from '@/app/slices/organisationSlice'
import Pdf2Quote from '@/features/quotes/components/Pdf2Quote/Pdf2Quote'

const classes = {
    dialogContent: {
        display: 'flex',
        flexDirection: 'row',
        p: 0,
    },
}

const AddPDFPartsDialog = ({ onClose, open }) => {
    const { t } = useTranslation()
    const { enqueueSnackbar } = useSnackbar()

    const { quoteId } = useParams()
    const organisationId = useSelector((state) => selectOrganisationId(state))

    const [addQuoteItemsFromPdf] = useAddQuoteItemsFromPDFMutation()

    const handleClose = () => {
        onClose()
    }

    const handleAddPDFParts = async () => {
        try {
            await addQuoteItemsFromPdf({ organisationId, quoteId: quoteId }).unwrap()
            enqueueSnackbar(`${t('PDF part added to $t(quote)')}`, { variant: 'success' })
            handleClose()
        } catch (error) {
            const errorMessage = t('$t(An error occurred) adding PDF part')
            enqueueSnackbar(errorMessage, { variant: 'error' })
            handleClose()
        }
    }

    return (
        <Dialog
            fullScreen={true}
            open={open}
            sx={classes.dialogRoot}
            onClose={handleClose}
        >
            <DialogContent sx={classes.dialogContent}>
                <Pdf2Quote
                    quoteId={quoteId}
                    onCancelClicked={handleClose}
                    onSubmitClicked={handleAddPDFParts}
                />
            </DialogContent>
        </Dialog>
    )
}

AddPDFPartsDialog.propTypes = {
    open: PropTypes.bool,
    onClose: PropTypes.func,
}

export default AddPDFPartsDialog
