import { useTranslation } from 'react-i18next'
import { Button, Dialog, DialogActions, DialogContent, DialogTitle, Typography } from '@mui/material'
import PropTypes from 'prop-types'

const SaveNotesDialog = ({ onCancel, onConfirm, open }) => {
    const { t } = useTranslation()

    return (
        <Dialog
            open={open}
            onClose={onCancel}
        >
            <DialogTitle>{t('You have not saved your changes')}</DialogTitle>
            <DialogContent>
                <Typography variant="body1">
                    {t('Do you want to save your current changes before closing this dialog?')}
                </Typography>
            </DialogContent>
            <DialogActions>
                <Button
                    color="secondary"
                    key="yes"
                    variant="text"
                    onClick={onConfirm}
                >
                    {t('Yes')}
                </Button>

                <Button
                    color="secondary"
                    key="no"
                    variant="text"
                    onClick={onCancel}
                >
                    {t('No')}
                </Button>
            </DialogActions>
        </Dialog>
    )
}

SaveNotesDialog.propTypes = {
    open: PropTypes.bool,
    onCancel: PropTypes.func,
    onConfirm: PropTypes.func,
}

export default SaveNotesDialog
