import { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useSelector } from 'react-redux'
import PropTypes from 'prop-types'

import { selectLanguageList } from '@/app/slices/appSlice'
import { Input, SelectOption } from '@/common/components'

const LegacyLanguageSelect = ({
    className,
    disabled = false,
    fullWidth = true,
    hideLabel = false,
    inputProps,
    labelPosition,
    labelProps,
    labelText,
    nullValueOptionLabelText = '-',
    onLanguageChanged,
    required,
    showNullValueOption = false,
    value,
}) => {
    const { t } = useTranslation()
    const languageList = useSelector(selectLanguageList)

    const [currentLanguage, setCurrentLanguage] = useState('en-US')

    const handleSelectChange = (event) => {
        const selectedLanguage = event.target.value
        setCurrentLanguage(selectedLanguage)

        if (typeof onLanguageChanged === 'function') {
            onLanguageChanged(selectedLanguage)
        }
    }

    const getLanguageFormattedName = (language) => {
        const nativeName = language.nativeName.split(',')[0]
        return `${nativeName[0].toUpperCase() + nativeName.substr(1)} (${language.region})`
    }

    useEffect(() => {
        value ? setCurrentLanguage(value) : setCurrentLanguage(null)
    }, [value])

    return (
        <Input
            className={className}
            disabled={disabled}
            fullWidth={fullWidth}
            hideLabel={hideLabel}
            inputProps={inputProps}
            labelPosition={labelPosition}
            labelProps={labelProps}
            labelText={labelText ?? t('Language')}
            required={required}
            textAlign="left"
            type="select"
            value={currentLanguage}
            onChange={handleSelectChange}
        >
            {showNullValueOption ? (
                <SelectOption
                    key="none"
                    style={{ backgroundColor: '#708090' }}
                    value="none"
                >
                    {nullValueOptionLabelText}
                </SelectOption>
            ) : null}
            {Object.keys(languageList).map((language) => (
                <SelectOption
                    key={language}
                    value={language}
                >
                    {getLanguageFormattedName(languageList[language])}
                </SelectOption>
            ))}
        </Input>
    )
}

LegacyLanguageSelect.propTypes = {
    className: PropTypes.string,
    disabled: PropTypes.bool,
    fullWidth: PropTypes.bool,
    hideLabel: PropTypes.bool,
    inputProps: PropTypes.object,
    labelPosition: PropTypes.oneOf(['start', 'end', 'bottom', 'top']),
    labelProps: PropTypes.object,
    labelText: PropTypes.string,
    nullValueOptionLabelText: PropTypes.string,
    required: PropTypes.bool,
    showNullValueOption: PropTypes.bool,
    value: PropTypes.string,
    onClick: PropTypes.func,
    onLanguageChanged: PropTypes.func,
}

export default LegacyLanguageSelect
