import { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { TextField } from '@mui/material'
import PropTypes from 'prop-types'

import { SelectOption } from '@/common/components'

const ProcessMeasureTypeSelect = ({
    InputProps,
    className,
    disabled = false,
    error,
    fullWidth = true,
    helperText,
    hideLabel = false,
    inputProps,
    isImperial,
    label,
    labelText,
    onChange,
    value = 'rectangulararea1side',
}) => {
    const { t } = useTranslation()

    const areaUnits = isImperial ? 'ft2' : 'm2'
    const weightUnits = isImperial ? 'lb' : 'kg'

    const units = [
        {
            name: t('Rectangular area - 1 side no edges ({{areaUnits}})', {
                areaUnits: areaUnits,
            }),
            value: 'rectangulararea1side',
        },
        {
            name: t('Surface area - 1 side no edges ({{areaUnits}})', {
                areaUnits: areaUnits,
            }),
            value: 'surfacearea1side',
        },
        {
            name: t('Surface area - 2 sides plus edges ({{areaUnits}})', {
                areaUnits: areaUnits,
            }),
            value: 'surfacearea2sides',
        },
        {
            name: t('Net part weight ({{weightUnits}})', {
                weightUnits: weightUnits,
            }),
            value: 'weight',
        },
        { name: t('Quantity'), value: 'quantity' },
    ]

    const [selectedValue, setSelectedValue] = useState('')

    useEffect(() => {
        let lowerCaseValue = value
        if (typeof value === 'string') {
            lowerCaseValue = value.toLowerCase()
        }
        setSelectedValue(lowerCaseValue)
    }, [value])

    const handleChange = (event) => {
        const newSelectedValue = event.target.value

        setSelectedValue(newSelectedValue)

        if (typeof onChange === 'function') {
            onChange(newSelectedValue)
        }
    }

    return (
        <TextField
            disabled={disabled}
            error={error}
            fullWidth={fullWidth}
            helperText={helperText}
            inputProps={inputProps}
            InputProps={InputProps}
            label={label}
            placeholder={!hideLabel ? labelText || t('Select units') : ''}
            sx={className}
            value={selectedValue}
            select
            onChange={handleChange}
        >
            {units.map((unit) => {
                return (
                    <SelectOption
                        key={unit.value}
                        value={unit.value}
                    >
                        {unit.name}
                    </SelectOption>
                )
            })}
        </TextField>
    )
}

ProcessMeasureTypeSelect.propTypes = {
    disabled: PropTypes.bool,
    error: PropTypes.bool,
    fullWidth: PropTypes.bool,
    helperText: PropTypes.string,
    hideLabel: PropTypes.bool,
    inputProps: PropTypes.object,
    InputProps: PropTypes.object,
    isImperial: PropTypes.bool,
    label: PropTypes.node,
    labelText: PropTypes.string,
    value: PropTypes.string,
    onChange: PropTypes.func,
}

export default ProcessMeasureTypeSelect
