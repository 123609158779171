/* eslint-disable no-unused-vars */
import { api } from './api'

export const organisationApi = api
    .enhanceEndpoints({
        addTagTypes: ['Organisation', 'SharedOrganisation'],
    })
    .injectEndpoints({
        endpoints: (builder) => ({
            getOrganisation: builder.query({
                query: ({ organisationId }) => `/organisations/${organisationId}`,
                providesTags: ['Organisation'],
            }),

            getSharedOrganisation: builder.query({
                query: ({ organisationId }) => `/organisations/shared/${organisationId}`,
                providesTags: ['SharedOrganisation'],
            }),

            updateOrganisation: builder.mutation({
                query: ({ organisationDto }) => {
                    const { $type, ...requestModel } = organisationDto
                    return {
                        url: `/organisations/${organisationDto.organisationId}`,
                        method: 'POST',
                        body: requestModel,
                    }
                },
                invalidatesTags: ['Organisation'],
            }),

            getStoreSettings: builder.query({
                query: ({ organisationId }) => `/organisations/${organisationId}/store`,
            }),

            transferOwnership: builder.mutation({
                query: ({ organisationId, userId }) => ({
                    url: `/organisations/${organisationId}/transfer-ownership`,
                    method: 'POST',
                    body: { userId },
                }),
                invalidatesTags: ['Organisation'],
            }),

            uploadOrganisationLogo: builder.mutation({
                query: ({ logo, organisationId }) => {
                    const formData = new FormData()
                    formData.append('file', logo)
                    return {
                        url: `/organisations/${organisationId}/logo`,
                        method: 'POST',
                        body: formData,
                    }
                },
                invalidatesTags: ['Organisation'],
            }),

            changeDrawingUnits: builder.mutation({
                query: ({ drawingUnits, organisationId }) => ({
                    url: `/organisations/${organisationId}/change-drawing-units?drawingUnits=${drawingUnits}`,
                    method: 'POST',
                }),
                invalidatesTags: ['Organisation'],
            }),

            toggleLegacyMaterials: builder.mutation({
                query: ({ organisationId }) => ({
                    url: `/organisations/${organisationId}/toggle-legacy-materials`,
                    method: 'POST',
                }),
                invalidatesTags: ['Organisation'],
            }),

            downloadOrganisationAsJson: builder.mutation({
                query: ({ organisationId }) => ({
                    url: `/organisations/${organisationId}?isFullDownload=true`,
                    method: 'GET',
                }),
                transformResponse: (response, _meta, arg) => {
                    const blob = new Blob([JSON.stringify(response, null, 2)])
                    const url = window.URL.createObjectURL(blob)
                    const link = document.createElement('a')
                    link.href = url
                    link.setAttribute('download', `${arg.organisationId}_${response.name}.json`)
                    document.body.appendChild(link)
                    link.click()
                    document.body.removeChild(link)
                    window.URL.revokeObjectURL(url)
                    return response
                },
            }),

            exportOrganisationAsJson: builder.mutation({
                query: ({ organisationId }) => ({
                    url: `/organisations/${organisationId}/export`,
                    method: 'GET',
                }),
                transformResponse: (response, _meta, arg) => {
                    const blob = new Blob([JSON.stringify(response, null, 2)])
                    const url = window.URL.createObjectURL(blob)
                    const link = document.createElement('a')
                    link.href = url
                    link.setAttribute('download', `${arg.organisationId}_${response.name}.json`)
                    document.body.appendChild(link)
                    link.click()
                    document.body.removeChild(link)
                    window.URL.revokeObjectURL(url)
                    return response
                },
            }),

            importOrganisation: builder.mutation({
                query: ({ file, organisationId }) => {
                    const formData = new FormData()
                    formData.append('file', file)
                    return {
                        url: `/organisations/${organisationId}/import`,
                        method: 'POST',
                        body: formData,
                    }
                },
                invalidatesTags: ['Organisation'],
            }),

            reinitializeOrganisation: builder.mutation({
                query: ({ cuttingTechnologyPower, organisationId }) => ({
                    url: `/organisations/${organisationId}/reinitialise`,
                    method: 'POST',
                    body: { cuttingTechnologyPowerOverride: cuttingTechnologyPower },
                }),
                invalidatesTags: ['Organisation'],
            }),

            uploadStoreLogo: builder.mutation({
                query: ({ file, organisationId }) => {
                    const formData = new FormData()
                    formData.append('file', file)
                    return {
                        url: `/organisations/${organisationId}/store/logo`,
                        method: 'POST',
                        prepareHeaders: (headers) => {
                            headers.set('Content-Type', 'multipart/form-data')
                            return headers
                        },
                        body: formData,
                    }
                },
                invalidatesTags: ['Organisation'],
            }),

            uploadStoreFavicon: builder.mutation({
                query: ({ file, organisationId }) => {
                    const formData = new FormData()
                    formData.append('file', file)
                    return {
                        url: `/organisations/${organisationId}/store/favicon`,
                        method: 'POST',
                        prepareHeaders: (headers) => {
                            headers.set('Content-Type', 'multipart/form-data')
                            return headers
                        },
                        body: formData,
                    }
                },
                invalidatesTags: ['Organisation'],
            }),
        }),
    })

export const selectStoreSettings = (state, query) => {
    return organisationApi.endpoints.getStoreSettings.select(query)(state)?.data ?? {}
}

export const {
    useChangeDrawingUnitsMutation,
    useDownloadOrganisationAsJsonMutation,
    useExportOrganisationAsJsonMutation,
    useGetOrganisationQuery,
    useGetSharedOrganisationQuery,
    useGetStoreSettingsQuery,
    useImportOrganisationMutation,
    useLazyGetOrganisationQuery,
    useLazyGetSharedOrganisationQuery,
    useReinitializeOrganisationMutation,
    useToggleLegacyMaterialsMutation,
    useTransferOwnershipMutation,
    useUpdateOrganisationMutation,
    useUploadOrganisationLogoMutation,
    useUploadStoreFaviconMutation,
    useUploadStoreLogoMutation,
} = organisationApi
