import { useCallback, useMemo } from 'react'
import { useTranslation } from 'react-i18next'
import Chip from '@mui/material/Chip'
import { blueGrey } from '@mui/material/colors'
import allCountries from 'country-region-data'
import PropTypes from 'prop-types'

import { fractionToPercentage } from '@/common/helpers/formatUtilities'

const QuoteTaxRateChip = ({ quoteTaxRate }) => {
    const { t } = useTranslation()
    const countryName = useCallback(
        (countryCode) => {
            const country = allCountries.find((country) => country.countryShortCode === countryCode?.toUpperCase())
            return country?.countryName ?? null
        },
        [allCountries]
    )

    const stateName = useCallback(
        (countryCode, stateCode) => {
            const country = allCountries.find((country) => country.countryShortCode === countryCode?.toUpperCase())
            const state = country?.regions.find((region) => region.shortCode === stateCode)
            return state?.name ?? null
        },
        [allCountries]
    )

    const label = useMemo(() => {
        const { country, displayName, percentage, state } = quoteTaxRate
        const stateLabel = state ? `${stateName(country, state)} - ` : ''
        const countryLabel = country ? `${countryName(country)} - ` : ''
        const percentageLabel = fractionToPercentage(percentage)
        return `${displayName} - ${stateLabel}${countryLabel} (${percentageLabel}%)`
    }, [quoteTaxRate])

    return (
        <Chip
            label={label}
            size="small"
            sx={{
                textTransform: 'uppercase',
                fontSize: '11px',
                backgroundColor: blueGrey[200],
                color: blueGrey[900],
                userSelect: 'none',
            }}
            title={`${t('Tax rate')}: ${label}`}
        />
    )
}

QuoteTaxRateChip.propTypes = {
    quoteTaxRate: PropTypes.number,
}

export default QuoteTaxRateChip
