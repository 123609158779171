import { api } from './api'

export const deliveryZonesApi = api
    .enhanceEndpoints({
        addTagTypes: ['Zones'],
    })
    .injectEndpoints({
        endpoints: (builder) => ({
            getDeliveryZones: builder.query({
                query: ({ organisationId, params }) => ({
                    url: `/shipping/${organisationId}/zones`,
                    params,
                }),
                providesTags: (result, _error, _arg) =>
                    result
                        ? [
                              { type: 'Zones', id: 'LIST' },
                              ...result.map(({ zoneId }) => ({ type: 'Zones', id: zoneId })),
                          ]
                        : [{ type: 'Zones', id: 'LIST' }],
                transformResponse: (responseData) => {
                    if (responseData) {
                        responseData?.sort((a, b) => a.index - b.index)
                    }

                    return responseData
                },
            }),

            getDeliveryZone: builder.query({
                query: ({ organisationId, zoneId }) => ({
                    url: `/shipping/${organisationId}/zone/${zoneId}`,
                }),
                providesTags: (result, _error, arg) => [{ type: 'Zones', id: arg.zoneId }],
            }),

            createDeliveryZone: builder.mutation({
                query: ({ organisationId, zone }) => ({
                    url: `/shipping/${organisationId}/zone`,
                    method: 'POST',
                    body: { ...zone, organisationId },
                }),
                invalidatesTags: [{ type: 'Zones', id: 'LIST' }],
            }),

            updateDeliveryZone: builder.mutation({
                query: ({ organisationId, zone }) => ({
                    url: `/shipping/${organisationId}/zone/update`,
                    method: 'PUT',
                    body: { ...zone, organisationId },
                }),
                async onQueryStarted({ organisationId, zone }, { dispatch, queryFulfilled }) {
                    dispatch(
                        deliveryZonesApi.util.updateQueryData(
                            'getDeliveryZones',
                            { organisationId, params: { includeDeleted: false } },
                            (draft) => {
                                const index = draft.findIndex(({ zoneId }) => zone.zoneId === zoneId)
                                draft[index] = zone
                            }
                        )
                    )
                    try {
                        await queryFulfilled
                        dispatch(deliveryZonesApi.util.invalidateTags([{ type: 'Zones', id: 'LIST' }]))
                    } catch (error) {
                        dispatch(deliveryZonesApi.util.invalidateTags([{ type: 'Zones', id: 'LIST' }]))
                    }
                },
            }),

            updateDeliveryZones: builder.mutation({
                query: ({ organisationId, showArchived, zones }) => ({
                    url: `/shipping/${organisationId}/zone/updateZones`,
                    method: 'PUT',
                    body: zones,
                }),
                async onQueryStarted({ organisationId, showArchived, zones }, { dispatch, queryFulfilled }) {
                    dispatch(
                        deliveryZonesApi.util.updateQueryData(
                            'getDeliveryZones',
                            { organisationId, params: { includeDeleted: showArchived } },
                            (draft) => {
                                Object.assign(draft, zones)
                            }
                        )
                    )
                    try {
                        await queryFulfilled
                    } catch (error) {
                        dispatch(deliveryZonesApi.util.invalidateTags([{ type: 'Zones', id: 'LIST' }]))
                    }
                },
            }),

            archiveDeliveryZone: builder.mutation({
                query: ({ organisationId, zoneId }) => ({
                    url: `/shipping/${organisationId}/zone/${zoneId}`,
                    method: 'DELETE',
                }),
                invalidatesTags: (_result, _error, _arg) => [{ type: 'Zones', id: 'LIST' }],
            }),

            unarchiveDeliveryZone: builder.mutation({
                query: ({ organisationId, zoneId }) => ({
                    url: `/shipping/${organisationId}/zone/${zoneId}/unarchive`,
                    method: 'PUT',
                }),
                invalidatesTags: (_result, _error, _arg) => [{ type: 'Zones', id: 'LIST' }],
            }),
        }),
    })

export const {
    useArchiveDeliveryZoneMutation,
    useCreateDeliveryZoneMutation,
    useGetDeliveryZoneQuery,
    useGetDeliveryZonesQuery,
    useUnarchiveDeliveryZoneMutation,
    useUpdateDeliveryZoneMutation,
    useUpdateDeliveryZonesMutation,
} = deliveryZonesApi
