import { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useDispatch, useSelector } from 'react-redux'
import { Archive, Unarchive } from '@mui/icons-material'
import { Box, Card, CardContent, IconButton, Typography } from '@mui/material'
import { alpha } from '@mui/material/styles'
import PropTypes from 'prop-types'

import { useArchiveMaterialMutation, useUnarchiveMaterialMutation } from '@/app/services/materials'
import { setSelectedMaterialId } from '@/app/slices/materialsSlice'
import { selectIsOnFreePlan, selectOrganisationId } from '@/app/slices/organisationSlice'
import { TbxTooltip } from '@/common/components'

import ArchiveMaterialDialog from './ArchiveMaterialDialog'

const classes = {
    root: (isSelected) => ({
        position: 'relative',
        marginBottom: '12px',
        backgroundColor: (theme) => (isSelected ? theme.palette.primary.main : 'background.default'),
        cursor: 'pointer',
        '&:hover': {
            backgroundColor: (theme) =>
                isSelected ? theme.palette.primary.main : alpha(theme.palette.primary.main, 0.12),
        },
        '&:focus': {
            borderColor: (theme) => theme.palette.primary.main,
        },
        '&:focus-visible': {
            outline: (theme) => `1px solid ${theme.palette.primary.main}`,
        },
    }),
    cardContent: {
        display: 'flex',
        flexDirection: 'column',
        gap: '6px',
        padding: '12px',
        '&:last-child': {
            paddingBottom: '12px',
        },
    },
    contentGroup: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'space-between',
    },

    contentTitle: (isSelected) => ({
        color: isSelected ? 'common.white' : 'text.primary',
    }),

    contentSubtitle: (isSelected) => ({
        color: isSelected ? 'common.white' : 'text.secondary',
    }),

    contentIcon: (isSelected) => ({
        color: (theme) => (isSelected ? 'common.white' : theme.palette.primary.main),
    }),

    contentArchived: {
        color: 'text.disabled',
    },
}

const MaterialsListItem = ({ availableMaterialCount, material, onMaterialSelected, selectedMaterial }) => {
    const { t } = useTranslation()
    const dispatch = useDispatch()

    const organisationId = useSelector(selectOrganisationId)
    const isOnFreePlan = useSelector(selectIsOnFreePlan)

    const [archiveMaterial] = useArchiveMaterialMutation()
    const [unarchiveMaterial] = useUnarchiveMaterialMutation()

    const [isSelected, setIsSelected] = useState(false)

    const [showDeleteDialog, setShowDeleteDialog] = useState(false)

    const shouldRestrictUnarchiving = material.isDeleted && isOnFreePlan && availableMaterialCount > 2

    const handleArchiveMaterialClick = () => {
        material.isDeleted
            ? unarchiveMaterial({ organisationId, materialId: material.materialId })
            : archiveMaterial({ organisationId, materialId: material.materialId })
        hideArchiveDialog()
    }

    const handleMaterialItemClick = () => {
        dispatch(setSelectedMaterialId(material.materialId))
        typeof onMaterialSelected === 'function' && onMaterialSelected()
    }

    const hideArchiveDialog = () => setShowDeleteDialog(false)

    const showArchiveDialog = (e) => {
        e.stopPropagation()
        setShowDeleteDialog(true)
    }

    useEffect(() => {
        selectedMaterial && setIsSelected(material.materialId === selectedMaterial.materialId)
    }, [material, selectedMaterial, setIsSelected])

    return (
        <>
            <Card
                sx={classes.root(isSelected)}
                tabIndex={0}
                variant="outlined"
                onClick={handleMaterialItemClick}
            >
                <CardContent sx={classes.cardContent}>
                    <Box sx={classes.contentGroup}>
                        <Typography
                            component="h2"
                            sx={material.isDeleted ? classes.contentArchived : classes.contentTitle(isSelected)}
                            variant="subtitle2"
                        >
                            {material.materialName}
                        </Typography>

                        <TbxTooltip
                            title={
                                material.isDeleted
                                    ? shouldRestrictUnarchiving
                                        ? t(
                                              'Maximum of 3 materials available on your Free plan.  You must first archive a material before adding another active material.'
                                          )
                                        : t('Unarchive material')
                                    : t('Archive material')
                            }
                            arrow
                        >
                            <span>
                                <IconButton
                                    disabled={shouldRestrictUnarchiving}
                                    disableRipple={true}
                                    size="small"
                                    sx={classes.contentIcon(isSelected)}
                                    onClick={(event) => showArchiveDialog(event)}
                                >
                                    {material.isDeleted ? <Unarchive /> : <Archive />}
                                </IconButton>
                            </span>
                        </TbxTooltip>
                    </Box>
                    <Box sx={classes.contentGroup}>
                        <Typography
                            sx={material.isDeleted ? classes.contentArchived : classes.contentSubtitle(isSelected)}
                            variant="body2"
                        >
                            {material.type === 'Flat' ? t('Flat') : t('Rotary')}
                        </Typography>

                        <Typography
                            sx={material.isDeleted ? classes.contentArchived : classes.contentSubtitle(isSelected)}
                            variant="body2"
                        >
                            {material.type === 'Flat' ? t('Sheets') : t('Profiles')}
                            <Typography
                                component="span"
                                variant="subtitle2"
                            >
                                : {material.sheetsCount}
                            </Typography>
                        </Typography>
                    </Box>
                </CardContent>
            </Card>

            {showDeleteDialog ? <ArchiveMaterialDialog
                    materialName={material.materialName}
                    mode={material.isDeleted ? 'unarchive' : 'archive'}
                    onCancelClose={hideArchiveDialog}
                    onConfirmClose={handleArchiveMaterialClick}
                                /> : null}
        </>
    )
}

MaterialsListItem.propTypes = {
    availableMaterialCount: PropTypes.number,
    material: PropTypes.object,
    selectedMaterial: PropTypes.object,
    onMaterialSelected: PropTypes.func,
}

export default MaterialsListItem
