import NetworkManager from '../managers/NetworkManager/NetworkManager'

class QuoteService {
    exportQuote = async (quoteId) => {
        const result = await NetworkManager.download(`/quote/${quoteId}/export`, 'post')
        return result?.data
    }
}

export default new QuoteService()
