import { Box } from '@mui/material'
import PropTypes from 'prop-types'

const classes = {
    dashedContainer: (alignItems) => {
        let itemAlignment = 'center'
        if (alignItems === 'top') {
            itemAlignment = 'flex-start'
        } else if (alignItems === 'bottom') {
            itemAlignment = 'flex-end'
        }

        return {
            border: (theme) => `dashed 2px ${theme.palette.text.primary}`,
            width: '100%',
            height: '100%',
            display: 'flex',
            alignItems: itemAlignment,
            justifyContent: 'center',
            padding: 2,
            boxSizing: 'border-box',
        }
    },
}

const DashedBorderContainer = ({ alignItems = 'center', children, sx }) => {
    return <Box sx={[classes.dashedContainer(alignItems), ...(Array.isArray(sx) ? sx : [sx])]}>{children}</Box>
}

DashedBorderContainer.propTypes = {
    children: PropTypes.node.isRequired,
    alignItems: PropTypes.oneOf(['top', 'bottom', 'center']),
    sx: PropTypes.oneOfType([
        PropTypes.arrayOf(PropTypes.oneOfType([PropTypes.func, PropTypes.object, PropTypes.bool])),
        PropTypes.func,
        PropTypes.object,
    ]),
}

export default DashedBorderContainer
