import SvgIcon from '@mui/material/SvgIcon'

const AddIcon = (props) => {
    return (
        <SvgIcon
            {...props}
            viewBox="0 0 24 24"
        >
            <path d="M19,13H13V19H11V13H5V11H11V5H13V11H19V13Z" />
        </SvgIcon>
    )
}

export default AddIcon
