export const MaterialConsumptionModes = [
    { name: 'Whole Sheet', value: 'WholeSheet' },
    { name: 'Nest Bounds', value: 'NestBounds' },
]

export const RotaryMaterialConsumptionModes = [
    { name: 'Whole Length', value: 'WholeSheet' },
    { name: 'Nest Bounds', value: 'NestBounds' },
]

export function getMaterialConsumptionModes(isRotary) {
    if (isRotary) {
        return RotaryMaterialConsumptionModes
    }
    return MaterialConsumptionModes
}

export function getMaterialConsumptionName(isRotary, value) {
    const modes = getMaterialConsumptionModes(isRotary)
    return (modes.find((mode) => mode.value === value) ?? modes[0]).name
}
