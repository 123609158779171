import { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { FormControl, InputLabel, MenuItem, Select } from '@mui/material'
import PropTypes from 'prop-types'

const AutoCadVersionSelect = ({ disabled = false, onBlur, onChange, value = 'ACad2018' }) => {
    const { t } = useTranslation()

    const versions = [
        { name: t('AutoCAD 2000'), value: 'ACad2000' },
        { name: t('AutoCAD 2004'), value: 'ACad2004' },
        { name: t('AutoCAD 2007'), value: 'ACad2007' },
        { name: t('AutoCAD 2010'), value: 'ACad2010' },
        { name: t('AutoCAD 2013'), value: 'ACad2013' },
        { name: t('AutoCAD 2018'), value: 'ACad2018' },
    ]

    const [selectedValue, setSelectedValue] = useState('')

    useEffect(() => {
        setSelectedValue(value)
    }, [value])

    const handleChange = (event) => {
        const newSelectedValue = event.target.value

        setSelectedValue(newSelectedValue)

        if (typeof onChange === 'function') {
            onChange(newSelectedValue)
        }
    }

    const handleOnBlur = (event) => {
        const newSelectedValue = event.target.value

        setSelectedValue(newSelectedValue)

        if (typeof onBlur === 'function') {
            onBlur(newSelectedValue)
        }
    }

    return (
        <FormControl
            disabled={disabled}
            fullWidth
        >
            <InputLabel
                data-testid="organisation-cad-file-version-label"
                id="cad-file-version-select-label"
            >
                {t('CAD File Version')}
            </InputLabel>
            <Select
                id="cad-file-select"
                inputProps={{
                    'data-testid': 'organisation-cad-file-version-input',
                }}
                label={t('CAD File Version')}
                labelId="cad-file-version-select-label"
                value={selectedValue}
                onBlur={handleOnBlur}
                onChange={handleChange}
            >
                {versions.map((version) => {
                    return (
                        <MenuItem
                            key={version.value}
                            value={version.value}
                        >
                            {version.name}
                        </MenuItem>
                    )
                })}
            </Select>
        </FormControl>
    )
}

AutoCadVersionSelect.propTypes = {
    disabled: PropTypes.bool,
    value: PropTypes.string,
    onBlur: PropTypes.func,
    onChange: PropTypes.func,
}

export default AutoCadVersionSelect
