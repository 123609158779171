import { useMemo, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useSelector } from 'react-redux'
import { Save } from '@mui/icons-material'
import { Box, Checkbox, Dialog, DialogContent, DialogTitle, IconButton, Typography } from '@mui/material'
import PropTypes from 'prop-types'

import { useGetSecondaryProcessesQuery } from '@/app/services/secondaryProcesses'
import { selectOrganisationId } from '@/app/slices/organisationSlice'

const SecondaryProcessesBulkEditModal = ({ onClose, open, secondaryProcesses }) => {
    const { t } = useTranslation()

    const organisationId = useSelector(selectOrganisationId)
    const { data: organisationSecondaryProcesses } = useGetSecondaryProcessesQuery({ organisationId })
    const [enabledProcesses, setEnabledProcesses] = useState(secondaryProcesses || [])

    const onCheckboxChange = (event) => {
        const { checked, value } = event.target

        const enabledProcess = enabledProcesses.find((p) => p.secondaryProcessId === value)

        if (enabledProcess) {
            enabledProcess.isEnabled = checked
            const newArray = [...enabledProcesses.filter((p) => p.secondaryProcessId !== value), enabledProcess]
            setEnabledProcesses(newArray)
        } else {
            const processToEnable = {
                secondaryProcessId: value,
                isEnabled: true,
                input:
                    organisationSecondaryProcesses.find((p) => p.secondaryProcessId === value).measureType ===
                    'Quantity'
                        ? 1
                        : 0,
            }
            setEnabledProcesses([...enabledProcesses, processToEnable])
        }
    }

    const getProcessDetails = (processId) =>
        organisationSecondaryProcesses?.find((osp) => osp.secondaryProcessId === processId)

    const formattedProcesses = useMemo(() => {
        const newProcessList = enabledProcesses
            .map((process) => {
                const { $type, ...cleanProcess } = process
                const processDetails = getProcessDetails(process.secondaryProcessId)

                return {
                    ...cleanProcess,
                    index: processDetails?.index,
                    input: processDetails?.measureType === 'Quantity' ? process.input || 1 : 0,
                }
            })
            .sort((a, b) => (a.index < b.index ? -1 : 1))

        return [...newProcessList]
    }, [enabledProcesses])

    const ItemRow = ({ children }) => (
        <Box
            alignItems="center"
            display="flex"
            flexDirection="row"
            justifyContent="flex-start"
            width="100%"
        >
            {children}
        </Box>
    )

    const sortedProcesses = useMemo(() => {
        return [...organisationSecondaryProcesses].sort((a, b) => (a.index < b.index ? -1 : 1))
    }, [organisationSecondaryProcesses])

    return (
        <Dialog
            open={open}
            PaperProps={{ sx: { width: 380 } }}
            onClose={() => {
                setEnabledProcesses([])
                onClose()
            }}
        >
            <DialogTitle
                alignItems="center"
                data-testid="bulk-edit-secondary-process-modal"
                display="flex"
                justifyContent="space-between"
            >
                <Typography variant="strong1">{t('SECONDARY PROCESSES')}</Typography>
                <IconButton
                    color="secondary"
                    data-testid="nqp-quote-item-secondary-process-save-button"
                    onClick={() => onClose(formattedProcesses)}
                >
                    <Save />
                </IconButton>
            </DialogTitle>
            <DialogContent>
                <Typography variant="body2">
                    {t('Select the secondary processes you want to use in the selected parts.')}
                </Typography>
                <Box sx={{ mt: 3 }}>
                    {sortedProcesses.map((process) => (
                        <ItemRow key={process.secondaryProcessId}>
                            <Checkbox
                                checked={
                                    enabledProcesses.find((ep) => ep.secondaryProcessId === process.secondaryProcessId)
                                        ?.isEnabled
                                }
                                color="secondary"
                                data-testid="bulk-edit-secondary-process-modal-option-checkbox"
                                indeterminate={
                                    enabledProcesses.find((ep) => ep.secondaryProcessId === process.secondaryProcessId)
                                        ?.isEnabled === null
                                }
                                size="small"
                                value={process.secondaryProcessId}
                                onChange={onCheckboxChange}
                            />
                            <Typography data-testid="bulk-edit-secondary-process-modal-option-label">
                                {process.name}
                            </Typography>
                        </ItemRow>
                    ))}
                </Box>
            </DialogContent>
        </Dialog>
    )
}

SecondaryProcessesBulkEditModal.propTypes = {
    open: PropTypes.bool,
    secondaryProcesses: PropTypes.array,
    onClose: PropTypes.func,
}

export default SecondaryProcessesBulkEditModal
