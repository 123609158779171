import { cloneElement, useEffect, useState } from 'react'
import PropTypes from 'prop-types'

import CheckMarkIcon from '../../../icons/CheckMarkIcon/CheckMarkIcon'
import CrossIcon from '../../../icons/CrossIcon/CrossIcon'
import DeleteIcon from '../../../icons/DeleteIcon/DeleteIcon'
import EditIcon from '../../../icons/EditIcon/EditIcon'
import IconButton from '../../IconButton/IconButton'
import TableCell from '../TableCell/TableCell'

const TableActionsCell = ({
    actions,
    className,
    hidden = false,
    isEditing = false,
    onDeleteClicked,
    onEditCancelClicked,
    onEditClicked,
    onEditSaveClicked,
    row,
    rowIndex,
    showDeleteAction = true,
    showEditAction = true,
}) => {
    const actionWidth = 48
    const [isInEditingMode, setIsInEdtingMode] = useState(false)
    const [cellWidth, setCellWidth] = useState(actionWidth)

    useEffect(() => {
        setIsInEdtingMode(isEditing)
    }, [isEditing])

    useEffect(() => {
        let width = 0

        if (actions) {
            width += actions.length * actionWidth
        }
        if (showEditAction) {
            width += 2 * actionWidth
        }
        if (showDeleteAction) {
            width += actionWidth
        }

        setCellWidth(width)
    }, [actions, showDeleteAction, showEditAction])

    const handleEditButonClicked = (event) => {
        setIsInEdtingMode(true)

        if (typeof onEditClicked === 'function') {
            onEditClicked(event, row, rowIndex)
        }
    }

    const handleEditSaveButonClicked = (event) => {
        if (typeof onEditSaveClicked === 'function') {
            onEditSaveClicked(event, row, rowIndex)
        }

        setIsInEdtingMode(false)
    }

    const handleEditCancelButonClicked = (event) => {
        if (typeof onEditCancelClicked === 'function') {
            onEditCancelClicked(event, row, rowIndex)
        }

        setIsInEdtingMode(false)
    }

    const handleDeleteButtonClicked = (event) => {
        if (typeof onDeleteClicked === 'function') {
            onDeleteClicked(event, row, rowIndex)
        }
    }

    return (
        !hidden && (
            <TableCell
                align="right"
                classes={{ root: className }}
                style={{ width: cellWidth, padding: '10px 16px' }}
            >
                {actions && actions.length > 0
                    ? actions.map((action, index) => {
                          const actionClickHandler = action.props.onClick
                          return cloneElement(action, {
                              key: index,
                              onClick: (event) => {
                                  event.stopPropagation()
                                  if (actionClickHandler) {
                                      actionClickHandler(event, row)
                                  }
                              },
                          })
                      })
                    : null}
                {showEditAction ? (
                    isInEditingMode ? (
                        <>
                            <IconButton
                                size="large"
                                onClick={(event) => {
                                    event.stopPropagation()
                                    handleEditSaveButonClicked(event)
                                }}
                            >
                                <CheckMarkIcon />
                            </IconButton>
                            <IconButton
                                size="large"
                                onClick={(event) => {
                                    event.stopPropagation()
                                    handleEditCancelButonClicked(event)
                                }}
                            >
                                <CrossIcon />
                            </IconButton>
                        </>
                    ) : (
                        <IconButton
                            size="large"
                            onClick={(event) => {
                                event.stopPropagation()
                                handleEditButonClicked(event)
                            }}
                        >
                            <EditIcon />
                        </IconButton>
                    )
                ) : null}
                {showDeleteAction && !isInEditingMode ? (
                    <IconButton
                        size="large"
                        onClick={(event) => {
                            event.stopPropagation()
                            handleDeleteButtonClicked(event)
                        }}
                    >
                        <DeleteIcon />
                    </IconButton>
                ) : null}
            </TableCell>
        )
    )
}

TableActionsCell.propTypes = {
    row: PropTypes.object.isRequired,
    rowIndex: PropTypes.number.isRequired,
    actions: PropTypes.arrayOf(PropTypes.object),
    hidden: PropTypes.bool,
    isEditing: PropTypes.bool,
    showDeleteAction: PropTypes.bool,
    showEditAction: PropTypes.bool,
    onDeleteClicked: PropTypes.func,
    onEditCancelClicked: PropTypes.func,
    onEditClicked: PropTypes.func,
    onEditSaveClicked: PropTypes.func,
}

export default TableActionsCell
