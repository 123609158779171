import { useState } from 'react'
import { useTranslation } from 'react-i18next'
import { NumericFormat } from 'react-number-format'
import { useSelector } from 'react-redux'
import { TextField } from '@mui/material'
import PropTypes from 'prop-types'
import { useDebouncedCallback } from 'use-debounce'

import { selectLocale } from '@/app/slices/appSlice'
import { selectCurrencyCode } from '@/app/slices/organisationSlice'
import TbxTooltip from '@/common/components/TbxTooltip/TbxTooltip'
import { getCurrencyFormat } from '@/common/utils'

const classes = {
    formControl_small: {
        width: '80px',
    },
}

const QuantitySecProcess = ({ isPartFromLibrary, onInputChange, process, sx }) => {
    const { t } = useTranslation()

    const locale = useSelector(selectLocale)
    const currencyCode = useSelector(selectCurrencyCode)

    const [itemQuantity, setItemQuantity] = useState(process.input || 1)

    const [quantityError, setQuantityError] = useState(false)

    const { $type, ...rest } = process

    const currencyFormat = getCurrencyFormat(currencyCode, locale)

    const debounceOnInputChange = useDebouncedCallback(onInputChange, 800)

    const handleInputChange = async (values) => {
        debounceOnInputChange.cancel()
        const { floatValue } = values

        setQuantityError(!floatValue || isNaN(floatValue) || floatValue === 0)
        setItemQuantity(floatValue)

        if (!isNaN(floatValue) && floatValue !== 0) {
            debounceOnInputChange({ ...rest, isEnabled: true, input: floatValue })
        }
    }

    return (
        <TbxTooltip
            title={isPartFromLibrary ? t('Cannot edit parts from library') : ''}
            arrow
        >
            <NumericFormat
                allowNegative={true}
                customInput={TextField}
                decimalSeparator={currencyFormat.decimal}
                disabled={isPartFromLibrary}
                error={quantityError}
                inputProps={{
                    style: { textAlign: 'right' },
                    'data-testid': 'nqp-quote-item-secondary-process-input',
                }}
                placeholder="0"
                size="small"
                sx={{ ...classes.formControl_small, ...sx }}
                thousandSeparator={currencyFormat.group}
                value={itemQuantity}
                variant="outlined"
                onValueChange={handleInputChange}
            />
        </TbxTooltip>
    )
}

QuantitySecProcess.propTypes = {
    isPartFromLibrary: PropTypes.bool,
    process: PropTypes.object,
    sx: PropTypes.object,
    onInputChange: PropTypes.func,
}

export default QuantitySecProcess
