import { useEffect, useRef, useState } from 'react'
import { Box } from '@mui/material'
import PropTypes from 'prop-types'
import svgDragSelect from 'svg-drag-select'

import { pathSort } from '@/common/utils'

import CadView from '../CadView'

import PathRenderer from './PathRenderer'

const svgId = 'partDrawing'

const classes = {
    root: {
        height: '100%',
        width: '100%',
        display: 'flex',
        userSelect: 'none',
    },
    fillOff: {
        fillOpacity: 0,
    },

    cartesian: {
        transform: 'scaleY(-1)',
    },
}

const DrawingRenderer = ({
    cadViewBox,
    drawing,
    noFill,
    onMouseMove,
    onPathsSelected,
    originalCadSvg,
    selectAreaClassName,
    selectedPaths,
    showCad,
    showOpenPathHighlight,
    viewBox,
}) => {
    const svgRef = useRef()
    const [cadRef, setCadRef] = useState(null)

    const getUserCoordinatesForEvent = (event, ref) => {
        if (!ref || !ref.current) {
            return
        }

        const svg = ref.current
        let userCoordinates = svg.createSVGPoint()
        userCoordinates.x = event.clientX
        userCoordinates.y = event.clientY
        userCoordinates = userCoordinates.matrixTransform(svg.getScreenCTM()?.inverse())

        return userCoordinates
    }

    const handleMouseMove = (event) => {
        const userCoordinates = getUserCoordinatesForEvent(event, showCad ? cadRef : svgRef)

        if (typeof onMouseMove === 'function') {
            onMouseMove(event, userCoordinates)
        }
    }

    const pathEntrySort = ([, a], [, b]) => pathSort(a, b)

    const paths = !!drawing && !!drawing.paths && Object.entries(drawing.paths)

    useEffect(() => {
        const svgElement = document.getElementById(svgId)
        if (showCad || !drawing || !drawing.paths || !viewBox || viewBox.width === 0 || viewBox.height === 0) {
            return
        }

        const { cancel, dragAreaOverlay } = svgDragSelect({
            svg: svgElement,
            referenceElement: null,
            selector: 'enclosure',

            onSelectionStart({ cancel, pointerEvent }) {
                // Handles mouse left button only.
                if (pointerEvent?.button !== 0 || pointerEvent?.metaKey || typeof onPathsSelected !== 'function') {
                    cancel()
                    return
                }
            },

            onSelectionEnd({ dragAreaInClientCoordinate, pointerEvent, selectedElements }) {
                if (!pointerEvent || typeof onPathsSelected !== 'function') {
                    cancel()
                    return
                }

                if (dragAreaInClientCoordinate.height < 4 && dragAreaInClientCoordinate.width < 4) {
                    const clickedElement = document.elementFromPoint(pointerEvent.clientX, pointerEvent.clientY)

                    if (!clickedElement) {
                        return
                    }

                    if (clickedElement.id !== svgId) {
                        onPathsSelected([clickedElement.id], true)
                    }

                    return
                }

                if (pointerEvent.shiftKey) {
                    onPathsSelected(
                        selectedElements.map((e) => e.id),
                        true
                    )
                } else {
                    onPathsSelected(
                        selectedElements.map((e) => e.id),
                        false
                    )
                }
            },
        })

        if (dragAreaOverlay) {
            dragAreaOverlay.className = selectAreaClassName
        }

        return () => {
            cancel()
        }
    }, [showCad, drawing, selectedPaths, selectAreaClassName, viewBox])

    return drawing ? (
        showCad ? (
            <CadView
                cadSvg={originalCadSvg}
                setRef={setCadRef}
                viewBoxHeight={cadViewBox ? cadViewBox.height : null}
                viewBoxWidth={cadViewBox ? cadViewBox.width : null}
                viewBoxX={cadViewBox ? cadViewBox.minX : null}
                viewBoxY={cadViewBox ? cadViewBox.minY : null}
            />
        ) : (
            <Box
                component="svg"
                id={svgId}
                ref={svgRef}
                sx={[classes.root, classes.cartesian, noFill && classes.fillOff]}
                viewBox={viewBox ? `${viewBox.minX} ${viewBox.minY} ${viewBox.width} ${viewBox.height}` : null}
                onMouseMove={handleMouseMove}
            >
                <defs>
                    <marker
                        id="open-path-highlight"
                        markerHeight="15"
                        markerWidth="15"
                        refX="15"
                        refY="15"
                        viewBox="0 0 50 50"
                    >
                        <circle
                            cx="15"
                            cy="15"
                            fill="rgba(255, 204, 0, 0.5)"
                            r="15"
                            stroke="rgba(255, 204, 0, 1)"
                        />
                    </marker>
                </defs>
                {paths ? (
                    <g>
                        {paths
                            .slice()
                            .sort(pathEntrySort)
                            .map(([pathId, path]) => {
                                const pathSelected = Boolean(selectedPaths[pathId])
                                return (
                                    <PathRenderer
                                        drawingPath={path}
                                        isPathSelected={pathSelected}
                                        key={pathId}
                                        showOpenPathHighlight={showOpenPathHighlight}
                                        usingCartesianCoordinates={true}
                                    />
                                )
                            })}
                    </g>
                ) : null}
            </Box>
        )
    ) : null
}

DrawingRenderer.propTypes = {
    cadViewBox: PropTypes.shape({
        height: PropTypes.number,
        minX: PropTypes.number,
        minY: PropTypes.number,
        width: PropTypes.number,
    }),
    drawing: PropTypes.object,
    noFill: PropTypes.bool,
    originalCadSvg: PropTypes.oneOfType([PropTypes.string, PropTypes.object]),
    selectAreaClassName: PropTypes.string,
    selectedPaths: PropTypes.object,
    showCad: PropTypes.bool,
    showOpenPathHighlight: PropTypes.bool,
    viewBox: PropTypes.shape({
        height: PropTypes.number,
        minX: PropTypes.number,
        minY: PropTypes.number,
        width: PropTypes.number,
    }),
    onMouseMove: PropTypes.func,
    onPathsSelected: PropTypes.func,
    onWheel: PropTypes.func,
}

export default DrawingRenderer
