import Checkbox from '@mui/material/Checkbox'
import FormControl from '@mui/material/FormControl'
import FormControlLabel from '@mui/material/FormControlLabel'
import FormGroup from '@mui/material/FormGroup'
import FormHelperText from '@mui/material/FormHelperText'
import MenuItem from '@mui/material/MenuItem'
import Select from '@mui/material/Select'
import TextField from '@mui/material/TextField'
import { DateTimePicker, DesktopDatePicker, TimePicker } from '@mui/x-date-pickers'
import PropTypes from 'prop-types'

import CurrencyInput from '../../CurrencyInput/CurrencyInput'
import TbxLocalizationProvider from '../../TbxLocalizationProvider/TbxLocalizationProvider'

const TableEditComponent = ({
    allowNegativeValues,
    autoFocus = true,
    dateSetting,
    error,
    helperText,
    onBlur,
    onChange,
    onKeyDown,
    placeholder,
    readOnly,
    selectOptions,
    title,
    type = 'text',
    value,
}) => {
    const renderLookupField = () => {
        return (
            <FormControl error={Boolean(error)}>
                <Select
                    SelectDisplayProps={{ 'aria-label': title }}
                    style={{
                        fontSize: 13,
                    }}
                    value={typeof value === 'undefined' ? '' : value}
                    onBlur={onBlur}
                    onChange={(event) => onChange(event.target.value)}
                >
                    {Object.keys(selectOptions).map((key) => (
                        <MenuItem
                            key={key}
                            value={key}
                        >
                            {selectOptions[key]}
                        </MenuItem>
                    ))}
                </Select>
                {helperText ? <FormHelperText>{helperText}</FormHelperText> : null}
            </FormControl>
        )
    }

    const renderBooleanField = () => {
        return (
            <FormControl
                component="fieldset"
                error={error}
            >
                <FormGroup>
                    <FormControlLabel
                        control={
                            <Checkbox
                                checked={Boolean(value)}
                                inputProps={{
                                    'aria-label': title,
                                }}
                                style={{
                                    padding: 0,
                                    width: 24,
                                    marginLeft: 9,
                                }}
                                value={String(value)}
                                onBlur={onBlur}
                                onChange={(event) => onChange(event.target.checked)}
                            />
                        }
                        label=""
                    />
                </FormGroup>
                <FormHelperText>{helperText}</FormHelperText>
            </FormControl>
        )
    }

    const renderDateField = () => {
        const dateFormat = dateSetting && dateSetting.format ? dateSetting.format : 'dd-MM-yyyy'
        return (
            <TbxLocalizationProvider>
                <DesktopDatePicker
                    format={dateFormat}
                    slotProps={{
                        textField: {
                            variant: 'outlined',
                            InputProps: {
                                style: {
                                    fontSize: 13,
                                },
                            },
                            inputProps: {
                                'aria-label': `${title}: press space to edit`,
                            },
                        },
                    }}
                    value={value || null}
                    onChange={onChange}
                    onClose={onBlur}
                />
            </TbxLocalizationProvider>
        )
    }

    const renderTimeField = () => {
        return (
            <TbxLocalizationProvider>
                <TimePicker
                    format="HH:mm:ss"
                    slotProps={{
                        textField: {
                            InputProps: {
                                style: {
                                    fontSize: 13,
                                },
                            },
                            inputProps: {
                                'aria-label': `${title}: press space to edit`,
                            },
                        },
                    }}
                    value={value || null}
                    onChange={onChange}
                    onClose={onBlur}
                />
            </TbxLocalizationProvider>
        )
    }

    const renderDateTimeField = () => {
        return (
            <TbxLocalizationProvider>
                <DateTimePicker
                    format="dd.MM.yyyy HH:mm:ss"
                    slotProps={{
                        textField: {
                            InputProps: {
                                style: {
                                    fontSize: 13,
                                },
                            },
                            inputProps: {
                                'aria-label': `${title}: press space to edit`,
                            },
                        },
                    }}
                    value={value || null}
                    onChange={onChange}
                    onClose={onBlur}
                />
            </TbxLocalizationProvider>
        )
    }

    const renderTextField = () => {
        return (
            <TextField
                InputProps={{
                    style: {
                        fontSize: 14,
                    },
                    inputProps: {
                        'aria-label': title,
                    },
                    readOnly: readOnly,
                }}
                placeholder={placeholder || title}
                style={type === 'numeric' ? { float: 'right' } : {}}
                type={type === 'numeric' ? 'number' : 'text'}
                value={typeof value === 'undefined' ? '' : value}
                fullWidth
                onBlur={onBlur}
                onChange={(event) => onChange(type === 'numeric' ? event.target.valueAsNumber : event.target.value)}
            />
        )
    }

    const renderCurrencyField = () => {
        return (
            <CurrencyInput
                allowNegativeValues={allowNegativeValues}
                autoFocus={autoFocus}
                inputProps={{
                    style: {
                        fontSize: 13,
                        textAlign: 'right',
                        'aria-label': title,
                    },
                }}
                placeholder={placeholder || title}
                style={{ float: 'right' }}
                type="number"
                value={typeof value === 'undefined' ? '' : value}
                onBlur={onBlur}
                onChange={(event) => onChange(event)}
                onKeyDown={onKeyDown}
            />
        )
    }

    const renderComponent = () => {
        let component = 'ok'

        if (type === 'select') {
            component = renderLookupField()
        } else if (type === 'boolean') {
            component = renderBooleanField()
        } else if (type === 'date') {
            component = renderDateField()
        } else if (type === 'time') {
            component = renderTimeField()
        } else if (type === 'datetime') {
            component = renderDateTimeField()
        } else if (type === 'currency') {
            component = renderCurrencyField()
        } else {
            component = renderTextField()
        }

        return component
    }

    return renderComponent()
}

TableEditComponent.propTypes = {
    autoFocus: PropTypes.bool,
    dateSetting: PropTypes.shape({
        format: PropTypes.string,
    }),
    error: PropTypes.string,
    helperText: PropTypes.string,
    placeholder: PropTypes.string,
    readOnly: PropTypes.bool,
    selectOptions: PropTypes.arrayOf(PropTypes.object),
    title: PropTypes.string,
    type: PropTypes.oneOf(['text', 'numeric', 'currency', 'datetime', 'select', 'boolean']),
    value: PropTypes.any,
    onChange: PropTypes.func,
    onKeyDown: PropTypes.func,
}

export default TableEditComponent
