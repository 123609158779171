import React, { useEffect, useState } from 'react'
import { Editor } from 'react-draft-wysiwyg'
import { TextField } from '@mui/material'
import { ContentState, convertToRaw, EditorState } from 'draft-js'
import draftToHtml from 'draftjs-to-html'
import htmlToDraft from 'html-to-draftjs'
import PropTypes from 'prop-types'

import 'react-draft-wysiwyg/dist/react-draft-wysiwyg.css'

const RichContentField = React.forwardRef(function DraftField(props, ref) {
    const { component: Component, editorRef, handleOnChange, ...rest } = props

    React.useImperativeHandle(ref, () => ({
        focus: () => editorRef?.current?.focus(),
    }))

    return (
        <Component
            {...rest}
            isSoftReturnDefault={true}
            ref={editorRef}
            toolbar={{
                options: ['inline', 'textAlign', 'list', 'link', 'history'],
                inline: {
                    options: ['bold', 'italic', 'underline', 'strikethrough', 'superscript', 'subscript'],
                },
                list: {
                    options: ['unordered', 'ordered', 'indent', 'outdent'],
                },
                textAlign: {
                    options: ['left', 'center', 'right'],
                },
            }}
            onChange={handleOnChange}
        />
    )
})

RichContentField.propTypes = {
    component: PropTypes.any,
    editorRef: PropTypes.any,
    handleOnChange: PropTypes.func,
}

const RichContentEditor = ({
    dataTestId,
    error,
    fullWidth,
    helperText,
    label,
    onBlur,
    onChange,
    placeholder,
    spellCheck,
    value,
    variant,
}) => {
    const [editorState, setEditorState] = useState(EditorState.createEmpty())
    const [updated, setUpdated] = useState(false)
    const editorRef = React.useRef(null)

    useEffect(() => {
        if (!updated) {
            const defaultValue = value ? value : ''
            const blocksFromHtml = htmlToDraft(defaultValue)
            const contentState = ContentState.createFromBlockArray(
                blocksFromHtml.contentBlocks,
                blocksFromHtml.entityMap
            )
            const newEditorState = EditorState.createWithContent(contentState)
            setEditorState(newEditorState)
        }
    }, [value])

    const onEditorStateChange = (editorState) => {
        setUpdated(true)
        setEditorState(editorState)

        return onChange(draftToHtml(convertToRaw(editorState.getCurrentContent())))
    }

    return (
        <TextField
            error={error}
            fullWidth={fullWidth}
            helperText={helperText}
            InputLabelProps={{
                shrink: true,
                'data-testid': `${dataTestId}-label`,
            }}
            inputProps={{
                'data-testid': `${dataTestId}-input`,
            }}
            InputProps={{
                inputProps: {
                    component: Editor,
                    editorRef,
                    spellCheck,
                    editorState,
                    onEditorStateChange,
                    onBlur,
                    'data-testid': `${dataTestId}-input`,
                },
                inputComponent: RichContentField,
            }}
            label={label}
            minRows={4}
            placeholder={placeholder}
            variant={variant}
            multiline
        />
    )
}

RichContentEditor.propTypes = {
    onChange: PropTypes.func.isRequired,
    dataTestId: PropTypes.string,
    error: PropTypes.bool,
    fullWidth: PropTypes.bool,
    helperText: PropTypes.any,
    label: PropTypes.string,
    placeholder: PropTypes.string,
    spellCheck: PropTypes.bool,
    value: PropTypes.string,
    variant: PropTypes.string,
    onBlur: PropTypes.func,
}

export default RichContentEditor
