import { Box } from '@mui/material'
import Typography from '@mui/material/Typography'
import PropTypes from 'prop-types'

const classes = {
    container: {
        width: '100%',
    },
    title: {
        color: 'text.primary',
        mr: 1,
        my: 1,
    },
}

const InputGroup = ({ children, className, title }) => {
    const containerStyle = Object.assign({}, classes.container, className)

    return (
        <Box sx={containerStyle}>
            <Typography
                sx={classes.title}
                variant="h6"
            >
                {title}
            </Typography>
            {children}
        </Box>
    )
}

InputGroup.propTypes = {
    children: PropTypes.node,
    title: PropTypes.string,
}

export default InputGroup
