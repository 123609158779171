import { useCallback } from 'react'
import { useTranslation } from 'react-i18next'
import { useSelector } from 'react-redux'
import { Button, Typography } from '@mui/material'
import PropTypes from 'prop-types'

import { selectUseImperialUnits } from '@/app/slices/organisationSlice'
import { Dialog } from '@/common/components'
import { useNumberFormatter } from '@/common/hooks'

const ArchiveSheetDialog = ({ materialType, mode = 'archive', onCancelClose, onConfirmClose, sheet }) => {
    const { t } = useTranslation()

    const useImperialUnits = useSelector(selectUseImperialUnits)

    const handleDeleteButtonClick = () => {
        if (typeof onConfirmClose === 'function') {
            onConfirmClose()
        }
    }

    const { n } = useNumberFormatter({
        numberOfDecimalPlaces: 2,
    })

    const unit = useImperialUnits ? 'in' : 'mm'

    const materialIs = useCallback(
        (type) => {
            return materialType === type
        },
        [materialType]
    )

    const alertMessage = useCallback(() => {
        let message = ''
        if (materialIs('Flat')) {
            message = t(
                'Are you sure you wish to {{mode}} sheet with thickness: {{thickness}}{{unit}}, sheet length: {{sheetHeight}}{{unit}} and sheet width: {{sheetWidth}}{{unit}}?',
                {
                    mode,
                    thickness: n(sheet.thickness),
                    unit: unit,
                    sheetHeight: n(sheet.sheetHeight),
                    sheetWidth: n(sheet.sheetWidth),
                }
            )
        }

        if (materialIs('Rotary')) {
            if (sheet.profile === 'Circle') {
                message = t(
                    'Are you sure you wish to {{mode}} record with thickness: {{thickness}}{{unit}}, diameter: {{diameter}}{{unit}} and length: {{materialLength}}{{unit}}?',
                    {
                        mode,
                        thickness: n(sheet.thickness),
                        unit: unit,
                        diameter: n(sheet.diameter),
                        materialLength: n(sheet.materialLength),
                    }
                )
            } else {
                message = t(
                    'Are you sure you wish to {{mode}} record with thickness: {{thickness}}{{unit}} and dimensions: {{sheetWidth}}{{unit}} x {{sheetHeight}}{{unit}} x {{materialLength}}{{unit}}',
                    {
                        mode,
                        unit: unit,
                        thickness: n(sheet.thickness),
                        sheetWidth: n(sheet.sheetWidth),
                        sheetHeight: n(sheet.sheetHeight),
                        materialLength: n(sheet.materialLength),
                    }
                )
            }
        }
        return message
    }, [mode, sheet])

    return (
        <Dialog
            actions={[
                <Button
                    color="primary"
                    key="add"
                    variant="contained"
                    onClick={handleDeleteButtonClick}
                >
                    {t(mode)}
                </Button>,
                <Button
                    color="primary"
                    key="cancel"
                    variant="contained"
                    onClick={onCancelClose}
                >
                    {t('Cancel')}
                </Button>,
            ]}
            className={{ width: 680 }}
            open={true}
            title={t('Confirm {{mode}} {{type}}', {
                mode,
                type: materialIs('Rotary') ? t('record') : t('sheet'),
            })}
            onClose={onCancelClose}
        >
            <Typography variant="body1">{t(alertMessage())}</Typography>
        </Dialog>
    )
}

ArchiveSheetDialog.propTypes = {
    materialType: PropTypes.string,
    mode: PropTypes.oneOf(['archive', 'unarchive']),
    sheet: PropTypes.object,
    onCancelClose: PropTypes.func,
    onConfirmClose: PropTypes.func,
}

export default ArchiveSheetDialog
