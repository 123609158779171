import { webStoreApi } from './webStoreApi'

export const webStoreCustomerApi = webStoreApi
    .enhanceEndpoints({
        addTagTypes: ['WebStoreCustomer', 'WebStoreContact'],
    })
    .injectEndpoints({
        endpoints: (builder) => ({
            getCustomer: builder.query({
                query: ({ customerId, organisationId }) => `/${organisationId}/customer/${customerId}`,
                providesTags: ['WebStoreCustomer'],
            }),

            getContact: builder.query({
                query: ({ contactId, customerId, organisationId }) =>
                    `/${organisationId}/customer/${customerId}/contacts/${contactId}`,
                providesTags: (result, error, arg) => [{ type: 'WebStoreContact', id: arg.contactId }],
            }),

            getContacts: builder.query({
                query: ({ customerId, organisationId }) => `/${organisationId}/customer/${customerId}/contacts`,
                providesTags: (result, _error, _arg) => [
                    { type: 'Contact', id: 'LIST' },
                    ...(result?.map(({ id }) => ({ type: 'WebStoreContact', id })) ?? []),
                ],
            }),

            createCustomer: builder.mutation({
                query: ({ customer, organisationId }) => ({
                    url: `/${organisationId}/customer`,
                    method: 'POST',
                    body: customer,
                    headers: {
                        'Content-Type': 'application/json',
                    },
                }),
                invalidatesTags: ['WebStoreCustomer'],
            }),

            updateCustomer: builder.mutation({
                query: ({ customer, customerId, organisationId }) => ({
                    url: `/${organisationId}/customer/${customerId}`,
                    method: 'PUT',
                    body: customer,
                }),
                invalidatesTags: ['WebStoreCustomer'],
            }),

            createContact: builder.mutation({
                query: ({ contact, customerId, organisationId }) => ({
                    url: `/${organisationId}/customer/${customerId}/contacts`,
                    method: 'POST',
                    body: contact,
                    headers: {
                        'Content-Type': 'application/json',
                    },
                }),
                invalidatesTags: ['WebStoreContact'],
            }),

            updateContact: builder.mutation({
                query: ({ contact, contactId, customerId, organisationId }) => ({
                    url: `/${organisationId}/customer/${customerId}/contacts/${contactId}`,
                    method: 'PUT',
                    body: contact,
                }),
                invalidatesTags: (result, error, arg) => [{ type: 'WebStoreContact', id: arg.contactId }],
            }),
        }),
    })

export const {
    useCreateContactMutation,
    useCreateCustomerMutation,
    useGetContactQuery,
    useGetContactsQuery,
    useGetCustomerQuery,
    useLazyGetContactQuery,
    useLazyGetCustomerQuery,
    useUpdateContactMutation,
    useUpdateCustomerMutation,
} = webStoreCustomerApi
