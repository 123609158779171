import { useTranslation } from 'react-i18next'
import { Box, CircularProgress, Typography } from '@mui/material'
import PropTypes from 'prop-types'

const classes = {
    wrapper: {
        position: 'relative',
        width: '100%',
        height: 'calc(100vh - 64px)',
        overflow: 'hidden',
    },
    transparentOverlay: {
        position: 'absolute',
        top: 0,
        left: 0,
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        zIndex: (theme) => theme.zIndex.appBar - 1,
        width: '100%',
        height: '100%',
    },
    overlay: {
        position: 'absolute',
        top: 0,
        left: 0,
        background: 'rgba(0,0,0,0.2)',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        zIndex: (theme) => theme.zIndex.appBar - 1,
        width: '100%',
        height: '100%',
        transition: 'opacity 500ms ease-in',
    },
    loadingComponent: {
        width: '100%',
        height: '100%',
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        justifyContent: 'center',
    },
    loadingText: {
        marginTop: 2,
    },
}

const LoadingOverlay = ({
    active = true,
    children,
    className,
    labelText = 'Loading',
    loadingComponent = <CircularProgress size={48} />,
    showLoadingComponent = true,
    showLoadingLabel = true,
    transparent,
}) => {
    const { t } = useTranslation()

    const containerStyle = Object.assign({}, classes.wrapper, className)
    return (
        <Box sx={containerStyle}>
            {active ? (
                <Box sx={transparent ? classes.transparentOverlay : classes.overlay}>
                    {showLoadingComponent ? (
                        <Box sx={classes.loadingComponent}>
                            {loadingComponent}
                            {showLoadingLabel ? (
                                <Typography
                                    sx={classes.loadingText}
                                    variant="h6"
                                >
                                    {t(labelText)}...
                                </Typography>
                            ) : null}
                        </Box>
                    ) : null}
                </Box>
            ) : null}
            {children}
        </Box>
    )
}

LoadingOverlay.propTypes = {
    active: PropTypes.bool,
    children: PropTypes.node,
    className: PropTypes.object,
    labelText: PropTypes.string,
    loadingComponent: PropTypes.node,
    showLoadingComponent: PropTypes.bool,
    showLoadingLabel: PropTypes.bool,
    transparent: PropTypes.bool,
}

export default LoadingOverlay
