import { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useDispatch, useSelector } from 'react-redux'
import { Close, Search } from '@mui/icons-material'
import {
    Box,
    Button,
    Container,
    Dialog,
    Divider,
    IconButton,
    InputAdornment,
    MenuItem,
    TextField,
    Typography,
} from '@mui/material'
import DialogContent from '@mui/material/DialogContent'
import MuiDialogTitle from '@mui/material/DialogTitle'
import { DesktopDatePicker } from '@mui/x-date-pickers/DesktopDatePicker'
import dayjs from 'dayjs'
import PropTypes from 'prop-types'
import { v4 as uuidv4 } from 'uuid'

import { useGetPartLibraryEntriesQuery } from '@/app/services/partLibrary'
import { selectOrganisation, selectOrganisationId } from '@/app/slices/organisationSlice'
import { setOpenPartQuoteHistoryModal, setSelectedPartFromLibrary } from '@/app/slices/quoteItemsSlice'
import { ErrorSnackbar, PageSpinner } from '@/common/components'
import TbxLocalizationProvider from '@/common/components/TbxLocalizationProvider/TbxLocalizationProvider'

import PartLibraryTable from './PartLibraryTable'

const classes = {
    container: {
        display: 'flex',
        flexDirection: 'column',
        height: '100%',
        backgroundColor: 'background.paper',
        border: '1px solid hsl(0, 0%, 15%)',
        boxSizing: 'border-box',
        overflow: 'hidden',
    },
    topBar: {
        borderBottom: '1px solid hsl(0, 0%, 20%)',
        boxSizing: 'border-box',
        height: 64,
        width: '100%',
        padding: 2,
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'space-between',

        backgroundColor: 'hsl(0, 0%, 15%)',
    },
    buttonContainer: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'space-between',
        gap: 2,
    },
    searchBar: {
        borderBottom: '1px solid hsl(0, 0%, 20%)',
        boxSizing: 'border-box',
        width: '100%',
        padding: 2,
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'flex-start',
        gap: 2,
        backgroundColor: 'hsl(0, 0%, 20%)',
    },
    title: {
        textTransform: 'uppercase',
        color: 'text.primary',
    },

    iconButton: {
        padding: 0,
    },
    quoteHistotyDialog: {
        display: 'block',
        width: '100%',
        marginBottom: '24px',
        justifyContent: 'space-evenly',
    },

    searchInput: {
        width: '19%',
    },
    textFieldMaterial: {
        width: '12%',
    },
    textFieldThickness: {
        width: '12%',
    },
    textFieldLastQuote: {
        width: '14%',
    },
    textFieldProjectStaus: {
        width: '17%',
        marginRight: '20px',
    },
    paper: {
        padding: 2,
        textAlign: 'center',
        color: 'text.secondary',
    },
    dialogPaper: {
        minHeight: '90%',
        maxHeight: '90%',
        minWidth: '80%',
        maxWidth: '80%',
    },
    dialogPaper17: {
        minWidth: '20%',
        maxWidth: '20%',
    },
    datePicker: {
        display: 'flex',
    },
    textFieldDate: {
        width: '170px',
    },
}

const titleClasses = {
    root: {
        margin: 0,
        padding: 2,
    },
    closeButton: {
        position: 'absolute',
        right: 1,
        top: 1,
        color: 'grey[500]',
    },
}

const DialogTitle = ({ children, onClose, ...other }) => {
    return (
        <MuiDialogTitle
            sx={titleClasses.root}
            disableTypography
            {...other}
        >
            <Typography variant="h6">{children}</Typography>
            {onClose ? (
                <IconButton
                    aria-label="close"
                    size="large"
                    sx={titleClasses.closeButton}
                    onClick={onClose}
                >
                    <Close />
                </IconButton>
            ) : null}
        </MuiDialogTitle>
    )
}

DialogTitle.propTypes = {
    onClose: PropTypes.func,
}

const PartLibrary = ({ customer, handlePartsLibrarySubmit, onCancelClicked }) => {
    const { t } = useTranslation()
    const dispatch = useDispatch()

    const organisation = useSelector(selectOrganisation)
    const organisationId = useSelector(selectOrganisationId)

    const { data: partsFromLibrary, isLoading } = useGetPartLibraryEntriesQuery({
        organisationId,
        customerId: customer.customerId,
    })

    const [materials, setMaterials] = useState(['All'])
    const [filterByMaterial, setFilterByMaterial] = useState('All')
    const [thickness, setThickness] = useState(['All'])
    const [filterByThickness, setFilterByThickness] = useState('All')
    const [filterByLastQuotePeriodFrom, setFilterByLastQuotePeriodFrom] = useState()
    const [filterByLastQuotePeriodTo, setFilterByLastQuotePeriodTo] = useState()
    const [partLibraryIdOrNameSearch, setPartLibraryIdOrNameSearch] = useState('')
    const [selectedPartLibraryParts, setSelectedPartLibraryParts] = useState([])
    const [selectedPartLibraryPartsCount, setSelectedPartLibraryPartsCount] = useState(0)

    const [showDetailSecondaryProcessDialog, setShowDetailSecondaryProcessDialog] = useState(false)
    const [secondaryProcesses, setSecondaryProcesses] = useState([])

    const [showErrorAlert, setShowErrorAlert] = useState(false)

    const handleAddPartsFromPartLibraryToProject = async () => {
        if (typeof handlePartsLibrarySubmit === 'function') {
            await handlePartsLibrarySubmit(selectedPartLibraryParts)
        }
    }

    const handleCloseAlert = () => {
        setShowErrorAlert(false)
    }

    const handleProjectMoreDetailsClick = async (e, selectedPartFromLibrary) => {
        e.preventDefault()

        dispatch(setSelectedPartFromLibrary(selectedPartFromLibrary))
        dispatch(setOpenPartQuoteHistoryModal(true))
    }

    const handleChangeSelectedPartLibraryItem = (e, selectedItem) => {
        e.preventDefault()
        let _selectedPartsFromPartLibrary = selectedPartLibraryParts
        selectedItem.newQuantity = e.target.value
        if (_selectedPartsFromPartLibrary.length > 0 || selectedItem.newQuantity.length > 0) {
            // Update selectedPart to ensure selections remain correct
            const selectedPartIndex = _selectedPartsFromPartLibrary.findIndex((p) => p.partId === selectedItem.partId)
            if (selectedPartIndex === -1 && selectedItem.newQuantity.length > 0) {
                _selectedPartsFromPartLibrary.push(selectedItem)
            } else {
                if (selectedItem.newQuantity.length > 0) {
                    _selectedPartsFromPartLibrary[selectedPartIndex] = selectedItem
                } else {
                    _selectedPartsFromPartLibrary = _selectedPartsFromPartLibrary.filter(
                        (item) => item.partId !== selectedItem.partId
                    )
                }
            }
            setSelectedPartLibraryParts(_selectedPartsFromPartLibrary)
            setSelectedPartLibraryPartsCount(_selectedPartsFromPartLibrary.length)
        }
    }

    // Start Secondary Process Dialog
    const handleDetailSecondaryProcessClick = (e, partLibraryEntrySecondaryProcesses, numberOfFolds) => {
        const processes = [
            ...(numberOfFolds > 0 ? [{ secondaryProcess: { secondaryProcessId: uuidv4(), name: t('Folding') } }] : []),
            ...partLibraryEntrySecondaryProcesses,
        ]
        e.preventDefault()
        setShowDetailSecondaryProcessDialog(true)
        setSecondaryProcesses(processes)
    }
    const handleCloseSecondaryProcessDialog = () => {
        setShowDetailSecondaryProcessDialog(false)
    }
    // End Secondary Process Dialg
    // Start Handlers for the Filters
    const handleChangeFilterByMaterial = (event) => {
        setFilterByMaterial(event.target.value)
    }
    const handleChangeFilterByThickness = (event) => {
        setFilterByThickness(event.target.value)
    }
    const handleChangeFilterByLastQuotePeriodFrom = (date) => {
        if (!date) {
            setFilterByLastQuotePeriodFrom(null)
        }
        date.hour(0).minute(0).second(0).millisecond(0).valueOf()
        setFilterByLastQuotePeriodFrom(date)
    }
    const handleChangeFilterByLastQuotePeriodTo = (date) => {
        if (!date) {
            setFilterByLastQuotePeriodTo(null)
        }
        date.hour(0).minute(0).second(0).millisecond(0).valueOf()
        setFilterByLastQuotePeriodTo(date)
    }

    const handleSearchPartLibraryIdOrName = (event) => {
        setPartLibraryIdOrNameSearch(event.target.value)
    }
    // End Handlers for the Filters

    // Start Part's Project Histoy Methods

    // END Part's Project Histoy Methods

    const checkMaterialValueExist = (partLibrary) => {
        if (
            typeof partLibrary.material.materialName !== 'undefined' &&
            partLibrary.material.materialName === filterByMaterial
        )
            return partLibrary
        else return {}
    }
    const checkThicknessValueExist = (partLibrary) => {
        if (partLibrary.thickness.toString() === filterByThickness) return partLibrary
        else return {}
    }
    const checkLastQuotePeriodValueRangeExist = (partLibrary) => {
        if (typeof partLibrary.lastQuote.lastModifiedDate !== 'undefined') {
            const min = filterByLastQuotePeriodFrom
            const max = filterByLastQuotePeriodTo
            const lastModifiedDate = dayjs.utc(partLibrary.lastQuote.lastModifiedDate)
            lastModifiedDate.hour(0).minute(0).second(0).millisecond(0).valueOf()
            if (lastModifiedDate.valueOf() >= min.valueOf() && lastModifiedDate.valueOf() <= max.valueOf())
                return partLibrary
            else return {}
        } else return {}
    }
    const _partsFromLibrary = partsFromLibrary?.filter((partLibraryItem) => {
        if (Object.keys(partLibraryItem).length && filterByMaterial !== 'All') {
            partLibraryItem = checkMaterialValueExist(partLibraryItem)
        }
        if (Object.keys(partLibraryItem).length && filterByThickness !== 'All') {
            partLibraryItem = checkThicknessValueExist(partLibraryItem)
        }
        if (
            Object.keys(partLibraryItem).length > 0 &&
            filterByLastQuotePeriodFrom != null &&
            filterByLastQuotePeriodTo != null
        ) {
            partLibraryItem = checkLastQuotePeriodValueRangeExist(partLibraryItem)
        }
        // If the record passed from above filters and not equals to null than
        if (Object.keys(partLibraryItem).length > 0) {
            if (partLibraryIdOrNameSearch.length > 0) {
                return (
                    (partLibraryItem.partId.toLowerCase().includes(partLibraryIdOrNameSearch.toLowerCase()) &&
                        partLibraryItem.isDeleted === false) ||
                    (partLibraryItem.name.toLowerCase().includes(partLibraryIdOrNameSearch.toLowerCase()) &&
                        partLibraryItem.isDeleted === false)
                )
            } else {
                return partLibraryItem.isDeleted === false
            }
        }
    })

    useEffect(() => {
        if (!isLoading) {
            const uniqueMaterials = [...new Set(partsFromLibrary.map((item) => item.material.materialName))]
            uniqueMaterials.unshift('All')
            setMaterials(uniqueMaterials)

            const uniqueThickness = [...new Set(partsFromLibrary.map((item) => item.thickness.toString()))]
            uniqueThickness.sort((a, b) => a - b)
            uniqueThickness.unshift('All')
            setThickness(uniqueThickness)
        }
    }, [isLoading, partsFromLibrary])

    return isLoading ? (
        <PageSpinner />
    ) : (
        <Container
            disableGutters={true}
            maxWidth={false}
            sx={classes.container}
        >
            <Box sx={classes.topBar}>
                <Typography
                    sx={classes.title}
                    variant="h5"
                >
                    {t('Add From Part Library')}
                </Typography>
                <Box sx={classes.buttonContainer}>
                    <Button
                        color="secondary"
                        sx={classes.button}
                        variant="contained"
                        disableElevation
                        onClick={onCancelClicked}
                    >
                        {t('Cancel')}
                    </Button>

                    <Button
                        color="primary"
                        disabled={selectedPartLibraryPartsCount === 0}
                        variant="contained"
                        onClick={handleAddPartsFromPartLibraryToProject}
                    >
                        {t(`Add ${selectedPartLibraryPartsCount} Parts to $t(quote)`)}
                    </Button>
                </Box>
            </Box>
            <Box sx={classes.searchBar}>
                <TextField
                    InputLabelProps={{ shrink: true }}
                    InputProps={{
                        endAdornment: (
                            <InputAdornment position="end">
                                <IconButton
                                    size="large"
                                    sx={classes.iconButton}
                                >
                                    <Search />
                                </IconButton>
                            </InputAdornment>
                        ),
                    }}
                    label={t('Search part ID or name')}
                    size="small"
                    sx={classes.searchInput}
                    value={partLibraryIdOrNameSearch}
                    variant="outlined"
                    onChange={handleSearchPartLibraryIdOrName}
                ></TextField>

                <TextField
                    id="outlined-filter-material"
                    label={t('Material')}
                    name=""
                    size="small"
                    sx={classes.textFieldMaterial}
                    value={filterByMaterial}
                    variant="outlined"
                    select
                    onChange={handleChangeFilterByMaterial}
                >
                    {materials.map((option) => (
                        <MenuItem
                            key={option}
                            value={option}
                        >
                            {option.toLowerCase() === 'all' ? t('All') : option}
                        </MenuItem>
                    ))}
                </TextField>

                <TextField
                    id="outlined-filter-by-thickness"
                    label={t('Thickness')}
                    name=""
                    size="small"
                    sx={classes.textFieldThickness}
                    value={filterByThickness}
                    variant="outlined"
                    select
                    onChange={handleChangeFilterByThickness}
                >
                    {thickness.map((option) => (
                        <MenuItem
                            key={option}
                            value={option}
                        >
                            {option.toLowerCase() === 'all' ? t('All') : option}
                        </MenuItem>
                    ))}
                </TextField>

                <Divider
                    orientation="vertical"
                    flexItem
                />

                <TbxLocalizationProvider>
                    <DesktopDatePicker
                        closeOnSelect={false}
                        disableFuture={true}
                        format="DD-MMM-YYYY"
                        label={t('From')}
                        slotProps={{
                            textField: {
                                size: 'small',
                                variant: 'outlined',
                                InputLabelProps: {
                                    shrink: true,
                                },
                            },
                            field: {
                                readOnly: true,
                            },
                            actionBar: {
                                actions: ['clear', 'cancel', 'accept'],
                                disableSpacing: true,
                            },
                        }}
                        sx={classes.textFieldDate}
                        value={filterByLastQuotePeriodFrom}
                        onAccept={handleChangeFilterByLastQuotePeriodFrom}
                    />

                    <DesktopDatePicker
                        closeOnSelect={false}
                        disabled={filterByLastQuotePeriodFrom == null ? true : false}
                        disableFuture={true}
                        format="DD-MMM-YYYY"
                        label={t('To')}
                        minDate={filterByLastQuotePeriodFrom}
                        slotProps={{
                            textField: {
                                size: 'small',
                                variant: 'outlined',
                                InputLabelProps: {
                                    shrink: true,
                                },
                            },
                            field: {
                                readOnly: true,
                            },
                            actionBar: {
                                actions: ['clear', 'cancel', 'accept'],
                                disableSpacing: true,
                            },
                        }}
                        sx={classes.textFieldDate}
                        value={filterByLastQuotePeriodTo}
                        onAccept={handleChangeFilterByLastQuotePeriodTo}
                    />
                </TbxLocalizationProvider>
            </Box>

            <PartLibraryTable
                handleChangeSelectedPartLibraryItem={handleChangeSelectedPartLibraryItem}
                handleDetailSecondaryProcessClick={handleDetailSecondaryProcessClick}
                handleProjectMoreDetailsClick={handleProjectMoreDetailsClick}
                isImperial={organisation.defaultDrawingUnits === 'Imperial'}
                isLoading={isLoading}
                organisation={organisation}
                partsFromLibrary={_partsFromLibrary}
            />

            <Dialog
                aria-labelledby="part-quote-history-dialog"
                open={showDetailSecondaryProcessDialog}
                PaperProps={{ sx: classes.dialogPaper17 }}
                onClose={handleCloseSecondaryProcessDialog}
            >
                <DialogTitle
                    id="SP-dialog"
                    onClose={handleCloseSecondaryProcessDialog}
                >
                    {t('Secondary Process')}
                </DialogTitle>
                <DialogContent dividers>
                    <ul>
                        {secondaryProcesses.map((item) => (
                            <li key={item.secondaryProcess.secondaryProcessId}>{item.secondaryProcess.name}</li>
                        ))}
                    </ul>
                </DialogContent>
            </Dialog>

            <ErrorSnackbar
                open={showErrorAlert}
                onClose={handleCloseAlert}
            >
                {t('$t(An error occurred)')}
            </ErrorSnackbar>
        </Container>
    )
}

PartLibrary.propTypes = {
    customer: PropTypes.object,
    handlePartsLibrarySubmit: PropTypes.func,
    onCancelClicked: PropTypes.func,
}

export default PartLibrary
