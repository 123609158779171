import { useSelector } from 'react-redux'
import { useParams } from 'react-router-dom'
import { Box } from '@mui/material'

import { useGetQuoteQuery } from '@/app/services/quotes'
import { selectOrganisationId } from '@/app/slices/organisationSlice'

import QuoteReviewMiscItemsTable from './QuoteReviewMiscItemsTable'
import QuoteReviewPartsTable from './QuoteReviewPartsTable'

const QuoteReview = () => {
    const organisationId = useSelector(selectOrganisationId)
    const { quoteId } = useParams()

    const { data: quote } = useGetQuoteQuery({
        organisationId,
        quoteId,
    })

    return (
        <Box
            display="flex"
            flexDirection="column"
            gap={2}
        >
            {quote?.numberOfItems ? <QuoteReviewPartsTable /> : null}
            {quote?.numberOfMiscItems ? <QuoteReviewMiscItemsTable /> : null}
        </Box>
    )
}

export default QuoteReview
