import { useCallback, useEffect, useMemo, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useDispatch, useSelector } from 'react-redux'
import DomainAddRoundedIcon from '@mui/icons-material/DomainAddRounded'
import PersonAddRoundedIcon from '@mui/icons-material/PersonAddRounded'
import Autocomplete from '@mui/material/Autocomplete'
import { blueGrey } from '@mui/material/colors'
import TextField from '@mui/material/TextField'
import Typography from '@mui/material/Typography'
import Box from '@mui/system/Box'
import { useSnackbar } from 'notistack'
import PropTypes from 'prop-types'

import { useGetCustomersQuery } from '@/app/services/customers'
import { useUpdateQuoteMutation } from '@/app/services/quotes'
import { selectAllContacts, setSelectedContact } from '@/app/slices/contactsSlice'
import { selectCustomers, setSelectedCustomer } from '@/app/slices/customersSlice'
import { selectDefaultTaxRateId, selectOrganisationId } from '@/app/slices/organisationSlice'
import TbxDrawer from '@/common/components/TbxDrawer/TbxDrawer'
import TbxTooltip from '@/common/components/TbxTooltip/TbxTooltip'
import { QUOTE_STATUS } from '@/common/utils/Constants/Constants'
import AddContactForm from '@/features/customer-central/components/AddContactForm'
import AddCustomerForm from '@/features/customer-central/components/AddCustomerForm'

const classes = {
    disabledInput: {
        '& .Mui-disabled': {
            textFillColor: '#34495E',
        },
        '& .MuiInput-input.Mui-disabled': {
            textFillColor: '#34495E',
            fontWeight: 'bold',
        },
    },
}

const QuoteCustomerContact = ({ hasPartsFromLibrary = false, quote, refetch }) => {
    const { t } = useTranslation()
    const { enqueueSnackbar } = useSnackbar()
    const dispatch = useDispatch()

    const organisationId = useSelector(selectOrganisationId)

    useGetCustomersQuery({ organisationId, params: { includeWebStore: true } })
    const [updateQuote] = useUpdateQuoteMutation()

    const allCustomers = useSelector(selectCustomers)
    const allContacts = useSelector(selectAllContacts)
    const defaultTaxRateId = useSelector(selectDefaultTaxRateId)

    const [customers, setCustomers] = useState([])
    const [contacts, setContacts] = useState([])
    const [localSelectedCustomer, setLocalSelectedCustomer] = useState()
    const [localSelectedContact, setLocalSelectedContact] = useState()

    const [showAddCustomerDialog, setShowAddCustomerDialog] = useState(false)
    const [showAddContactDialog, setShowAddContactDialog] = useState(false)

    const isInputDisabled = useMemo(() => {
        return ![QUOTE_STATUS.NotCalculated, QUOTE_STATUS.Calculated, QUOTE_STATUS.Draft].includes(quote?.status)
    }, [quote?.status])

    const updateQuoteCustomerContact = useCallback(
        async (contactId, customerId, customerName, taxRateId) => {
            try {
                const { billingAddress, deliveryAddress, ...quoteWithoutAddresses } = quote
                await updateQuote({
                    organisationId,
                    quoteId: quote.id,
                    quote: {
                        ...quoteWithoutAddresses,
                        customerContactId: contactId ?? '',
                        customerId: customerId ?? '',
                        customerName: customerName ?? '',
                        taxRateId: taxRateId ?? defaultTaxRateId,
                    },
                }).unwrap()
            } catch (error) {
                enqueueSnackbar(t('An error occurred updating the $t(quote) contact'), {
                    variant: 'error',
                })
            }
        },
        [organisationId, quote]
    )

    const handleCustomerChange = (_event, customerOption) => {
        if (!customerOption || customerOption?.id === localSelectedCustomer?.id) return

        if (customerOption.id === 'newCustomer') {
            updateQuoteCustomerContact(null, null, null, null)
            setShowAddCustomerDialog(true)
            return
        }

        const selectedCustomer = customers.find(({ customerId }) => customerId === customerOption.id)
        const selectedContacts = selectedCustomer?.contacts?.filter((contact) => !contact.isDeleted) ?? []
        const defaultContact = selectedContacts?.find((contact) => contact.isDefault === true) || selectedContacts[0]

        setLocalSelectedCustomer(customerOption)
        dispatch(setSelectedCustomer(selectedCustomer))
        dispatch(setSelectedContact(defaultContact))

        setContacts(selectedContacts)
        setLocalSelectedContact({
            id: defaultContact?.contactId ?? '',
            label: defaultContact?.name ?? '',
            customerId: selectedCustomer?.customerId ?? '',
        })

        if (defaultContact) {
            updateQuoteCustomerContact(
                defaultContact.contactId,
                selectedCustomer.customerId,
                selectedCustomer.companyName,
                selectedCustomer.taxRateId
            )
        }
    }

    const handleContactChange = (_event, contactOption) => {
        if (!contactOption || contactOption.id === localSelectedContact?.id) return

        if (contactOption.id === 'newContact') {
            setShowAddContactDialog(true)
            return
        }

        const customer = customers.find(({ customerId }) => customerId === contactOption?.customerId)
        const selectedContacts = customer?.contacts ?? []
        const selectedContact = selectedContacts?.find((contact) => contact.contactId === contactOption.id)

        if (!localSelectedCustomer?.id) {
            setLocalSelectedCustomer({
                id: customer.customerId,
                label: customer.companyName,
            })

            dispatch(setSelectedCustomer(contactOption))
        }
        setLocalSelectedContact(contactOption)
        dispatch(setSelectedContact(selectedContact))

        updateQuoteCustomerContact(contactOption.id, contactOption.customerId, customer.companyName, customer.taxRateId)
    }

    const handleAddCustomer = async (newCustomer) => {
        const customerOption = {
            id: newCustomer.customerId,
            label: newCustomer.companyName,
        }

        setLocalSelectedCustomer(customerOption)
        setShowAddCustomerDialog(false)
        setShowAddContactDialog(true)
    }

    const handleAddContact = async (newContact) => {
        const contactOption = {
            id: newContact.contactId,
            label: newContact.name,
            customerId: newContact.customerId,
        }
        handleContactChange(null, contactOption)
        setShowAddContactDialog(false)
    }

    const handleCloseAddCustomerDialog = () => {
        refetch()
        setShowAddCustomerDialog(false)
    }

    const handleCloseAddContactDialog = () => {
        handleContactChange(null, null)
        setShowAddContactDialog(false)
    }

    const getOptionLabelCustomer = (option) => option?.label ?? ''

    const getOptionLabelContact = (option) => option?.label ?? ''

    const getOptionSelectedCustomer = (option, value) => option.id === value.id

    const getOptionSelectedContact = (option, value) => option.id === value.id

    const customersOptions = useMemo(() => {
        const customersList = customers.map((customer) => {
            return {
                id: customer.customerId,
                label: customer.companyName,
            }
        })
        customersList.unshift({
            id: 'newCustomer',
            label: t('Add Customer'),
        })
        return customersList
    }, [customers])

    const contactsOptions = useMemo(() => {
        const contactsList = contacts
            .filter((contact) => !contact.isDeleted)
            .map((contact) => {
                return {
                    id: contact.contactId,
                    label: contact.name,
                    customerId: contact.customerId,
                }
            })
            .sort((a, b) => a.label.localeCompare(b.label))

        contactsList.unshift({
            id: 'newContact',
            label: t('Add Contact'),
            customerId: localSelectedCustomer?.id ?? '',
        })

        return contactsList
    }, [contacts])

    const savedCustomerContact = useMemo(() => {
        if (quote?.customerContactId) {
            const selectedContact = allContacts.find(({ contactId }) => contactId === quote.customerContactId)
            const selectedCustomer = allCustomers.find(({ customerId }) => customerId === selectedContact?.customerId)

            const savedCustomer = {
                id: selectedCustomer?.customerId ?? '',
                label: selectedCustomer?.companyName ?? '',
            }
            const savedContact = {
                id: selectedContact?.contactId ?? '',
                label: selectedContact?.name ?? '',
                customerId: selectedCustomer?.customerId ?? '',
            }

            return { savedCustomer, savedContact, selectedCustomer, selectedContact }
        }
    }, [quote, allContacts, allCustomers])

    const renderOption = (props, option) => {
        const { key, ...rest } = props

        return (
            <Box
                component="li"
                key={key}
                {...rest}
                sx={[
                    (option.id === 'newCustomer' || option.id === 'newContact') && {
                        borderBottom: `1px solid ${blueGrey[100]}`,
                    },
                ]}
            >
                <Box
                    alignItems="center"
                    display="flex"
                    justifyContent="space-between"
                    width={1}
                >
                    <Typography>{option.label}</Typography>
                    {(option.id === 'newCustomer' && <DomainAddRoundedIcon fontSize="small" />) ||
                        (option.id === 'newContact' && <PersonAddRoundedIcon fontSize="small" />)}
                </Box>
            </Box>
        )
    }

    useEffect(() => {
        setCustomers(allCustomers)
    }, [allCustomers])

    useEffect(() => {
        if (!localSelectedCustomer?.id) {
            setContacts(allContacts)
        } else {
            const selectedCustomer = allCustomers.find(({ customerId }) => customerId === localSelectedCustomer.id)
            const selectedContacts = selectedCustomer?.contacts ?? []
            setContacts(selectedContacts)
        }
    }, [allContacts, localSelectedCustomer])

    useEffect(() => {
        setLocalSelectedCustomer({
            id: savedCustomerContact?.savedCustomer?.id ?? '',
            label: savedCustomerContact?.savedCustomer?.label ?? '',
        })
        setLocalSelectedContact({
            id: savedCustomerContact?.savedContact?.id ?? '',
            label: savedCustomerContact?.savedContact?.label ?? '',
            customerId: savedCustomerContact?.savedCustomer?.customerId ?? '',
        })

        dispatch(setSelectedCustomer(savedCustomerContact?.selectedCustomer))
        dispatch(setSelectedContact(savedCustomerContact?.selectedContact))
    }, [savedCustomerContact])

    return (
        <>
            <Box
                alignItems="flex-start"
                alignSelf="stretch"
                display="flex"
                flexDirection="column"
                flexShrink={0}
                gap={2}
                justifyContent="space-between"
                width={240}
            >
                <TbxTooltip
                    key="customer-tooltip"
                    title={
                        hasPartsFromLibrary
                            ? t(
                                  "This quote has parts from this customer's Part Library, so the customer cannot be changed until those parts are removed from the quote"
                              )
                            : ''
                    }
                    arrow
                >
                    <Autocomplete
                        disabled={isInputDisabled || hasPartsFromLibrary}
                        forcePopupIcon={!isInputDisabled}
                        getOptionKey={(option) => option.id}
                        getOptionLabel={getOptionLabelCustomer}
                        isOptionEqualToValue={getOptionSelectedCustomer}
                        options={customersOptions}
                        renderInput={(params) => (
                            <TextField
                                {...params}
                                color="secondary"
                                InputLabelProps={{
                                    ...params.InputLabelProps,
                                    shrink: true,
                                }}
                                InputProps={{
                                    ...params.InputProps,
                                    disableUnderline: isInputDisabled,
                                }}
                                inputProps={{
                                    ...params.inputProps,
                                    'data-testid': 'quote-customer-input',
                                }}
                                label={!localSelectedCustomer?.id ? t('Select customer') : t('Customer')}
                            />
                        )}
                        renderOption={renderOption}
                        sx={classes.disabledInput}
                        value={localSelectedCustomer ?? null}
                        disableClearable
                        fullWidth
                        handleHomeEndKeys
                        selectOnFocus
                        onChange={handleCustomerChange}
                    />
                </TbxTooltip>

                <Autocomplete
                    disabled={isInputDisabled}
                    forcePopupIcon={!isInputDisabled}
                    getOptionDisabled={(option) => !option.customerId}
                    getOptionKey={(option) => option.id}
                    getOptionLabel={getOptionLabelContact}
                    isOptionEqualToValue={getOptionSelectedContact}
                    options={contactsOptions}
                    renderInput={(params) => (
                        <TextField
                            {...params}
                            color="secondary"
                            InputLabelProps={{
                                ...params.InputLabelProps,
                                shrink: true,
                            }}
                            InputProps={{
                                ...params.InputProps,
                                disableUnderline: isInputDisabled,
                            }}
                            inputProps={{
                                ...params.inputProps,
                                'data-testid': 'quote-contact-input',
                            }}
                            label={!localSelectedContact?.id ? t('Select contact') : t('Contact')}
                        />
                    )}
                    renderOption={renderOption}
                    sx={classes.disabledInput}
                    value={localSelectedContact ?? savedCustomerContact?.savedContact ?? null}
                    disableClearable
                    fullWidth
                    handleHomeEndKeys
                    selectOnFocus
                    onChange={handleContactChange}
                />
            </Box>

            <TbxDrawer
                showDrawer={showAddCustomerDialog}
                onClose={handleCloseAddCustomerDialog}
            >
                <AddCustomerForm
                    onCancel={handleCloseAddCustomerDialog}
                    onCreate={handleAddCustomer}
                />
            </TbxDrawer>

            <TbxDrawer
                showDrawer={showAddContactDialog}
                onClose={handleCloseAddContactDialog}
            >
                <AddContactForm
                    onCancel={handleCloseAddContactDialog}
                    onCreate={handleAddContact}
                />
            </TbxDrawer>
        </>
    )
}

QuoteCustomerContact.propTypes = {
    quote: PropTypes.object.isRequired,
    refetch: PropTypes.func.isRequired,
    hasPartsFromLibrary: PropTypes.bool,
}

export default QuoteCustomerContact
