import { api } from './api'

const apiVersion = 'v2'

export const sheetsApi = api
    .enhanceEndpoints({
        addTagTypes: ['Sheets'],
    })
    .injectEndpoints({
        endpoints: (builder) => ({
            getSheets: builder.query({
                query: ({ organisationId }) => ({
                    url: `/${apiVersion}/organisation/${organisationId}/sheets`,
                }),
                transformResponse: (response) => {
                    delete response.$type

                    return response
                },
                providesTags: (result, _error, _arg) =>
                    result
                        ? [
                              { type: 'Sheets', id: 'LIST' },

                              ...(Object.keys(result)?.map((sheetId) => ({
                                  type: 'Sheets',
                                  id: sheetId,
                              })) ?? []),
                          ]
                        : [{ type: 'Sheets', id: 'LIST' }],
            }),

            updateSheet: builder.mutation({
                query: ({ organisationId, sheet }) => ({
                    url: `/organisations/${organisationId}/sheets`,
                    method: 'PUT',
                    body: sheet,
                }),
                invalidatesTags: (_result, _error, arg) => [
                    { type: 'Sheets', id: 'LIST' },
                    { type: 'Sheets', id: arg.sheet.sheetId },
                    { type: 'Materials', id: arg.sheet.materialId },
                    { type: 'QuoteItems' },
                    { type: 'Quotes' },
                    { type: 'MaterialThicknesses' },
                    { type: 'ThicknessSheets' },
                ],
            }),

            sheetsBulkUpdate: builder.mutation({
                query: ({ organisationId, updatedSheets }) => ({
                    url: `/organisations/${organisationId}/sheets/bulk-update`,
                    method: 'PUT',
                    body: { sheets: updatedSheets },
                }),
                invalidatesTags: (_result, _error, arg) =>
                    arg.updatedSheets.map((sheet) => ({ type: 'Sheets', id: sheet.sheetId })),
            }),

            updateSheetCuttingTechnology: builder.mutation({
                query: ({ organisationId, sheetCuttingTechnology }) => ({
                    url: `/organisations/${organisationId}/sheets/sheet-cutting-technology`,
                    method: 'PUT',
                    body: sheetCuttingTechnology,
                }),
                invalidatesTags: (_result, _error, arg) => [
                    { type: 'Sheets', id: arg.sheetCuttingTechnology.sheetId },
                    { type: 'CuttingTechnologies', id: arg.sheetCuttingTechnology.cuttingTechnologyId },
                ],
            }),

            deleteSheet: builder.mutation({
                query: ({ organisationId, sheetId }) => ({
                    url: `/organisations/${organisationId}/sheets/${sheetId}`,
                    method: 'DELETE',
                }),
                invalidatesTags: (_result, _error, arg) => [
                    { type: 'Sheets', id: 'LIST' },
                    { type: 'Sheets', id: arg.sheetId },
                    { type: 'QuoteItems' },
                    { type: 'Quotes' },
                ],
            }),

            deleteSheetCuttingTechnology: builder.mutation({
                query: ({ organisationId, sheetCuttingTechnologyId }) => ({
                    url: `/organisations/${organisationId}/sheets/sheet-cutting-technology/${sheetCuttingTechnologyId}`,
                    method: 'DELETE',
                }),
                invalidatesTags: (_result, _error, arg) => [
                    { type: 'Sheets', id: 'LIST' },
                    { type: 'Sheets', id: arg.sheetCuttingTechnology.sheetId },
                    { type: 'CuttingTechnologies', id: 'LIST' },
                    { type: 'CuttingTechnologies', id: arg.sheetCuttingTechnology.cuttingTechnologyId },
                ],
            }),

            setSheetAsDefault: builder.mutation({
                query: ({ organisationId, sheetId }) => ({
                    url: `/organisations/${organisationId}/sheets/${sheetId}/default`,
                    method: 'PUT',
                }),
                invalidatesTags: (_result, _error, arg) => [{ type: 'Sheets', id: arg.sheetId }],
            }),

            setSheetAsWebStoreEnabled: builder.mutation({
                query: ({ isWebStoreEnabled, organisationId, sheetId }) => ({
                    url: `/organisations/${organisationId}/sheets/${sheetId}/store/${
                        isWebStoreEnabled ? 'enable' : 'disable'
                    }`,
                    method: 'PUT',
                }),
                invalidatesTags: (_result, _error, arg) => [{ type: 'Sheets', id: arg.sheetId }],
            }),

            importSheets: builder.mutation({
                query: ({ emptyFieldsTreatment, file, materialId, organisationId }) => {
                    const formData = new FormData()
                    formData.append('file', file, file.name)
                    return {
                        url: `/organisations/${organisationId}/sheets/${materialId}/import?emptyFieldsTreatment=${emptyFieldsTreatment}`,
                        method: 'POST',
                        body: formData,
                    }
                },
                invalidatesTags: (_result, _error, arg) => [
                    { type: 'Sheets', id: 'LIST' },
                    { type: 'Materials', id: arg.materialId },
                ],
            }),

            exportSheets: builder.query({
                query: ({ materialId, organisationId }) => ({
                    url: `/organisations/${organisationId}/sheets/${materialId}/export`,
                    responseHandler: (response) => {
                        return response.text()
                    },
                }),
            }),

            downloadSheetTemplate: builder.query({
                query: ({ materialId, organisationId }) => ({
                    url: `/organisations/${organisationId}/sheets/${materialId}/export-template`,
                    responseHandler: (response) => {
                        return response.text()
                    },
                }),
            }),
        }),
    })

export const {
    useDeleteSheetCuttingTechnologyMutation,
    useDeleteSheetMutation,
    useGetSheetsQuery,
    useImportSheetsMutation,
    useLazyDownloadSheetTemplateQuery,
    useLazyExportSheetsQuery,
    useSetSheetAsDefaultMutation,
    useSetSheetAsWebStoreEnabledMutation,
    useSheetsBulkUpdateMutation,
    useUpdateSheetCuttingTechnologyMutation,
    useUpdateSheetMutation,
} = sheetsApi
