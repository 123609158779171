import MuiLinearProgress from '@mui/material/LinearProgress'
import PropTypes from 'prop-types'

const LinearProgress = ({ className, classes, color, value, valueBuffer, variant }) => {
    return (
        <MuiLinearProgress
            classes={classes}
            color={color}
            sx={className}
            value={value}
            valueBuffer={valueBuffer}
            variant={variant}
        />
    )
}

LinearProgress.propTypes = {
    classes: PropTypes.object,
    className: PropTypes.object,
    color: PropTypes.oneOf(['primary', 'secondary']),
    value: PropTypes.number,
    valueBuffer: PropTypes.number,
    variant: PropTypes.oneOf(['buffer', 'determinate', 'indeterminate', 'query']),
}

export default LinearProgress
