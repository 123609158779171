import { useState } from 'react'
import { HexColorPicker } from 'react-colorful'
import { useTranslation } from 'react-i18next'
import { Box, Button, Grid, Paper, Popper, TextField } from '@mui/material'
import PropTypes from 'prop-types'

const classes = {
    pickerContainer: {
        border: '1px solid #E0E0E0',
        boxSizing: 'border-box',
        borderRadius: 1,
        marginTop: 2,
        marginBottom: 4,
        padding: 3,
        background: (theme) => theme.palette.background.default,
    },
    actions: {
        marginTop: 2,
    },
    colourPreview: {
        width: '30px',
        height: '30px',
        cursor: 'pointer',
    },
    inputContainer: {
        flexGrow: 1,
        marginRight: 1,
    },
}

const ColourPicker = ({ error, helperText, label, onBlur, onChange, value }) => {
    const { t } = useTranslation()

    const [anchorEl, setAnchorEl] = useState(null)
    const [newValue, setNewValue] = useState(value)

    const open = Boolean(anchorEl)
    const id = open ? 'colour-picker-popover' : undefined

    const showPicker = (event) => setAnchorEl(event.currentTarget)
    const hidePicker = () => setAnchorEl(null)
    const handlePickerChange = (value) => setNewValue(value)

    const handleCancelClick = () => {
        setNewValue(value)
        hidePicker()
    }

    const handleOkClick = () => {
        onChange(newValue)
        hidePicker()
    }

    return (
        <>
            <Grid
                alignItems="flex-end"
                justifyContent="space-between"
                container
            >
                <Grid sx={classes.inputContainer}>
                    <TextField
                        error={error}
                        helperText={helperText}
                        label={label}
                        value={value}
                        fullWidth
                        onBlur={onBlur}
                        onChange={onChange}
                        onFocus={showPicker}
                    />
                </Grid>
                <Grid>
                    <Paper
                        style={{ backgroundColor: value || 'white' }}
                        sx={classes.colourPreview}
                        variant="outlined"
                        onClick={showPicker}
                    />
                </Grid>
            </Grid>

            <Popper
                anchorEl={anchorEl}
                id={id}
                open={open}
                onClose={hidePicker}
            >
                <Box sx={classes.pickerContainer}>
                    <HexColorPicker
                        color={value}
                        onChange={handlePickerChange}
                    />
                    <Grid
                        justifyContent="space-between"
                        sx={classes.actions}
                        container
                    >
                        <Button
                            color="secondary"
                            variant="outlined"
                            onClick={handleCancelClick}
                        >
                            {t('Cancel')}
                        </Button>
                        <Button
                            color="primary"
                            variant="contained"
                            onClick={handleOkClick}
                        >
                            {t('OK')}
                        </Button>
                    </Grid>
                </Box>
            </Popper>
        </>
    )
}

ColourPicker.propTypes = {
    error: PropTypes.bool,
    fieldState: PropTypes.object,
    helperText: PropTypes.string,
    label: PropTypes.string,
    value: PropTypes.string,
    onBlur: PropTypes.func,
    onChange: PropTypes.func,
}

export default ColourPicker
