import { memo, useEffect, useMemo, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useParams } from 'react-router-dom'
import { useSortable } from '@dnd-kit/sortable'
import { CSS } from '@dnd-kit/utilities'
import { Accordion } from '@mui/material'
import PropTypes from 'prop-types'

import { useGetQuoteItemsQuery } from '@/app/services/quoteItems'
import { useCalculateQuoteMutation, useGetQuoteQuery } from '@/app/services/quotes'
import { selectOrganisationId } from '@/app/slices/organisationSlice'
import {
    addCollapsedItem,
    removeCollapsedItem,
    selectCollapsedItems,
    selectFilterOptions,
} from '@/app/slices/quoteItemsSlice'
import { quoteItemErrors, quoteItemHasIssues, quoteItemUnset, quoteItemWarnings } from '@/common/utils/quoteUtilities'

import ItemDetails from './ItemDetails'
import ItemSummary from './ItemSummary'

const classes = {
    AccordionRoot: {
        overflow: 'hidden',
        borderRadius: 2,
        border: (theme) => `1px solid ${theme.palette.grey[400]}`,
        backgroundColor: 'background.default',
        '&:before': {
            display: 'none',
        },
    },

    AccordionDetailsRoot: {
        backgroundColor: 'background.paper',
    },
}

const QuoteItem = ({ id }) => {
    const dispatch = useDispatch()
    const { quoteId } = useParams()
    const organisationId = useSelector(selectOrganisationId)
    const collapsedItemsArray = useSelector(selectCollapsedItems)
    const filterOptions = useSelector(selectFilterOptions)

    const [, { isLoading: isCalculating }] = useCalculateQuoteMutation({
        fixedCacheKey: 'shared-calculate-quote',
    })
    const { isFetching: isFetchingQuote } = useGetQuoteQuery({
        organisationId,
        quoteId,
    })

    const { attributes, isDragging, listeners, setActivatorNodeRef, setNodeRef, transform, transition } = useSortable({
        id: id,
        disabled: isCalculating || isFetchingQuote || filterOptions !== 'showAllItems',
    })

    const [isCollapsed, setIsCollapsed] = useState(collapsedItemsArray.includes(id))

    const { quoteItem } = useGetQuoteItemsQuery(
        {
            organisationId,
            quoteId,
        },
        {
            selectFromResult: ({ data }) => ({
                quoteItem: data?.find((item) => item.id === id),
            }),
        }
    )

    const itemHasIssues = useMemo(() => quoteItemHasIssues(quoteItem), [quoteItem])
    const itemWarnings = useMemo(() => quoteItemWarnings(quoteItem), [quoteItem])
    const itemErrors = useMemo(() => quoteItemErrors(quoteItem), [quoteItem])
    const itemIsUnset = useMemo(() => quoteItemUnset(quoteItem), [quoteItem])

    const style = {
        transform: CSS.Transform.toString(transform),
        transition,
        opacity: isDragging ? 0.5 : undefined,
        zIndex: isDragging ? 0 : undefined,
    }

    const handleCollapsedState = () => {
        setIsCollapsed(!isCollapsed)
        if (isCollapsed) {
            dispatch(removeCollapsedItem(id))
        } else {
            dispatch(addCollapsedItem(id))
        }
    }

    const isRotary = useMemo(() => {
        if (!quoteItem) return false
        return 'profile' in quoteItem && quoteItem?.profile !== ''
    }, [quoteItem])

    useEffect(() => {
        setIsCollapsed(collapsedItemsArray.includes(id))
    }, [collapsedItemsArray, id])

    if (quoteItem)
        return (
            <Accordion
                expanded={!isCollapsed}
                ref={setNodeRef}
                style={style}
                sx={classes.AccordionRoot}
                variant="outlined"
                square
                {...attributes}
                onChange={() => handleCollapsedState()}
            >
                <ItemSummary
                    hasErrors={itemErrors}
                    hasIssues={itemHasIssues}
                    hasWarnings={itemWarnings}
                    isUnset={itemIsUnset}
                    key={`quote-item-${id}-summary`}
                    listeners={listeners}
                    quoteItemId={id}
                    setActivatorNodeRef={setActivatorNodeRef}
                />

                <ItemDetails
                    isRotary={isRotary}
                    key={`quote-item-${id}-details`}
                    quoteItemId={id}
                />
            </Accordion>
        )
}

QuoteItem.propTypes = {
    id: PropTypes.string,
}

export default memo(QuoteItem)
