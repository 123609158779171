import 'dayjs/locale/it'
import 'dayjs/locale/fr'
import 'dayjs/locale/de'
import 'dayjs/locale/en-gb'
import 'dayjs/locale/en-au'
import 'dayjs/locale/es'
import 'dayjs/locale/pt'

import { useMemo } from 'react'
import dayjs from 'dayjs'
import timezone from 'dayjs/plugin/timezone'
import utc from 'dayjs/plugin/utc'
import PropTypes from 'prop-types'

import { getSupportedLocale } from '@/common/utils'

dayjs.extend(utc)
dayjs.extend(timezone)

const DateTime = ({ children, format = 'YYYY-MM-DD HH:mm:ss', locale = 'en-US', ...props }) => {
    const userTimeZone = dayjs.tz.guess()
    const supportedLocale = useMemo(() => getSupportedLocale(locale), [locale])

    const dateTime = dayjs.utc(children).locale(supportedLocale).tz(userTimeZone).format()
    const date = dayjs.utc(children).locale(supportedLocale).tz(userTimeZone).format(format)

    return (
        <time
            dateTime={dateTime}
            {...props}
        >
            {date}
        </time>
    )
}

DateTime.propTypes = {
    children: PropTypes.node,
    format: PropTypes.string,
    locale: PropTypes.string,
}

export default DateTime
