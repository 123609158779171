import { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useDispatch, useSelector } from 'react-redux'
import { Archive, Unarchive } from '@mui/icons-material'
import { Box, Card, CardContent, IconButton, Typography } from '@mui/material'
import { alpha } from '@mui/material/styles'
import PropTypes from 'prop-types'

import { useGetCuttingTechnologiesQuery } from '@/app/services/cuttingTechnologies'
import { useArchiveRateTableMutation, useUnarchiveRateTableMutation } from '@/app/services/rateTables'
import { selectOrganisation, selectOrganisationId } from '@/app/slices/organisationSlice'
import { selectSelectedRateTableId, setSelectedRateTableId } from '@/app/slices/rateTablesSlice'
import { TbxTooltip } from '@/common/components'
import { getFormattedPrice } from '@/common/utils'

import ArchiveRateTableDialog from './ArchiveRateTableDialog'

const classes = {
    root: (isSelected) => ({
        position: 'relative',
        marginBottom: '12px',
        backgroundColor: (theme) => (isSelected ? theme.palette.primary.main : 'background.default'),
        cursor: 'pointer',
        '&:hover': {
            backgroundColor: (theme) =>
                isSelected ? theme.palette.primary.main : alpha(theme.palette.primary.main, 0.12),
        },
        '&:focus': {
            borderColor: (theme) => theme.palette.primary.main,
        },
        '&:focus-visible': {
            outline: (theme) => `1px solid ${theme.palette.primary.main}`,
        },
    }),
    cardContent: {
        display: 'flex',
        flexDirection: 'column',
        gap: '6px',
        padding: '12px',
        '&:last-child': {
            paddingBottom: '12px',
        },
    },
    contentGroup: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'space-between',
    },

    contentGroupColumns: {
        display: 'flex',
        flexDirection: 'column',
        gap: '4px',
    },

    contentTitle: (isSelected) => ({
        color: isSelected ? 'common.white' : 'text.primary',
    }),

    contentSubtitle: (isSelected) => ({
        color: isSelected ? 'common.white' : 'text.secondary',
    }),

    contentIcon: (isSelected) => ({
        color: (theme) => (isSelected ? 'common.white' : theme.palette.primary.main),
    }),

    contentArchived: {
        color: 'text.disabled',
    },
}

const RateTablesListItem = ({ onRateTableSelected, rateTable }) => {
    const { t } = useTranslation()
    const dispatch = useDispatch()

    const organisation = useSelector(selectOrganisation)
    const organisationId = useSelector(selectOrganisationId)

    const { data: cuttingTechnologies, isLoading } = useGetCuttingTechnologiesQuery({ organisationId })

    const [archiveRateTable] = useArchiveRateTableMutation()
    const [unarchiveRateTable] = useUnarchiveRateTableMutation()

    const selectedRateTableId = useSelector(selectSelectedRateTableId)

    const [isSelected, setIsSelected] = useState(false)
    const [showDeleteDialog, setShowDeleteDialog] = useState(false)

    useEffect(() => {
        selectedRateTableId && setIsSelected(rateTable.rateTableId === selectedRateTableId)
    }, [rateTable, selectedRateTableId])

    const handleArchiveRateTableClick = () => {
        ;(rateTable.isDeleted
            ? unarchiveRateTable({ organisationId, rateTableId: rateTable.rateTableId })
            : archiveRateTable({ organisationId, rateTableId: rateTable.rateTableId })
        ).then(() => {
            hideArchiveDialog()
        })
    }

    const handleRateTableItemClick = () => {
        dispatch(setSelectedRateTableId(rateTable.rateTableId))
        typeof onRateTableSelected === 'function' && onRateTableSelected()
    }

    const hideArchiveDialog = () => setShowDeleteDialog(false)
    const showArchiveDialog = (e) => {
        e.stopPropagation()
        setShowDeleteDialog(true)
    }

    return !isLoading ? (
        <>
            <Card
                sx={classes.root(isSelected)}
                tabIndex={0}
                variant="outlined"
                onClick={handleRateTableItemClick}
            >
                <CardContent sx={classes.cardContent}>
                    <Box sx={classes.contentGroup}>
                        <Typography
                            component="h2"
                            sx={rateTable.isDeleted ? classes.contentArchived : classes.contentTitle(isSelected)}
                            variant="subtitle2"
                        >
                            {rateTable.name}
                        </Typography>

                        <TbxTooltip
                            title={rateTable.isDeleted ? t('Unarchive rate table') : t('Archive rate table')}
                            arrow
                        >
                            <IconButton
                                disableRipple={true}
                                size="small"
                                sx={classes.contentIcon(isSelected)}
                                onClick={(event) => showArchiveDialog(event)}
                            >
                                {rateTable.isDeleted ? <Unarchive /> : <Archive />}
                            </IconButton>
                        </TbxTooltip>
                    </Box>
                    <Box sx={classes.contentGroup}>
                        <Box sx={classes.contentGroupColumns}>
                            <Typography
                                sx={rateTable.isDeleted ? classes.contentArchived : classes.contentSubtitle(isSelected)}
                                variant="body2"
                            >
                                {t('Cutting technology')}
                                <Typography
                                    component="span"
                                    variant="subtitle2"
                                >
                                    : {cuttingTechnologies[rateTable.cuttingTechnologyId].name}
                                </Typography>
                            </Typography>

                            <Typography
                                sx={rateTable.isDeleted ? classes.contentArchived : classes.contentSubtitle(isSelected)}
                                variant="body2"
                            >
                                {t('Base hourly rate')}
                                <Typography
                                    component="span"
                                    variant="subtitle2"
                                >
                                    :{' '}
                                    {getFormattedPrice(
                                        rateTable.baseHourlyRatePrice,
                                        organisation.currencyCode,
                                        organisation.locale
                                    )}
                                </Typography>
                            </Typography>
                        </Box>

                        <Box sx={classes.contentGroupColumns}>
                            <Typography
                                align="right"
                                sx={rateTable.isDeleted ? classes.contentArchived : classes.contentSubtitle(isSelected)}
                                variant="h5"
                            >
                                {rateTable.materialCount}
                            </Typography>
                            <Typography
                                sx={rateTable.isDeleted ? classes.contentArchived : classes.contentMaterialCount}
                                variant="body2"
                            >
                                {t('Materials')}
                            </Typography>
                        </Box>
                    </Box>
                </CardContent>
            </Card>

            {showDeleteDialog ? (
                <ArchiveRateTableDialog
                    mode={rateTable.isDeleted ? 'unarchive' : 'archive'}
                    rateTableName={rateTable.name}
                    onCancelClose={hideArchiveDialog}
                    onConfirmClose={handleArchiveRateTableClick}
                />
            ) : null}
        </>
    ) : null
}

RateTablesListItem.propTypes = {
    isSelected: PropTypes.bool,
    rateTable: PropTypes.object,
    onRateTableSelected: PropTypes.func,
}

export default RateTablesListItem
