import { useEffect, useMemo, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useDispatch, useSelector } from 'react-redux'
import { useNavigate } from 'react-router-dom'
import { Add } from '@mui/icons-material'
import { Box, Button, Typography } from '@mui/material'

import { useGetCuttingTechnologiesQuery } from '@/app/services/cuttingTechnologies'
import { useGetMaterialsQuery } from '@/app/services/materials'
import { useGetRateTablesQuery } from '@/app/services/rateTables'
import { setShowDrawerMenu, setShowNestedDrawerMenu } from '@/app/slices/appSlice'
import { selectOrganisationId } from '@/app/slices/organisationSlice'
import {
    selectSelectedRateTableId,
    selectShowAddRateTableForm,
    setSelectedRateTableId,
    setShowAddRateTableForm,
} from '@/app/slices/rateTablesSlice'
import { TbxDrawer } from '@/common/components'
import { RateTablesToolbar } from '@/common/components/MainAppBar'
import DuplicateRateTableDialog from '@/features/rate-tables/components/DuplicateRateTableDialog'

import AddRateForm from '../components/AddRateForm'
import AddRateTableForm from '../components/AddRateTableForm'
import RatesTable from '../components/RatesTable'
import RateTableDetails from '../components/RateTableDetails'
import RateTableHeader from '../components/RateTableHeader'

const classes = {
    root: {
        display: 'flex',
        flexDirection: 'column',
        width: '100%',
        minHeight: 'calc(100vh - 64px)',
        padding: '24px 30px',
        backgroundColor: '#FAFAFA',
    },
    header: {
        display: 'flex',
        flexDirection: 'row',
        justifyContent: 'space-between',
        marginBottom: '12px',
    },
    centeredContent: {
        width: '100%',
        height: '100%',
        boxSizing: 'border-box',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
    },
    container: {
        width: '100%',
        height: '100%',
        display: 'flex',
        flexDirection: 'column',
        padding: 2,
        boxSizing: 'border-box',
    },
    actionBar: {
        display: 'flex',
        justifyContent: 'space-between',
        margin: '12px 0',
    },
    errorLabel: {
        color: 'error.main',
    },
    noRateTableText: {
        fontSize: '34px',
        lineHeight: '123.5%',
        color: 'rgba(52, 73, 94, 0.38)',
    },
    noRateTableContainer: {
        display: 'flex',
        maxWidth: '450px',
        height: 'calc(100vh - 64px)',
        justifyContent: 'center',
        alignItems: 'center',
        textAlign: 'center',
        background: 'url(toolbox-box.png)',
        backgroundRepeat: 'no-repeat',
        backgroundPosition: 'center',
    },
    details: {
        display: 'flex',
        flexDirection: 'row',
        alignItems: 'flex-start',
        margin: '12px 0',
    },
}

const RateTables = () => {
    const { t } = useTranslation()
    const navigate = useNavigate()
    const dispatch = useDispatch()

    const organisationId = useSelector(selectOrganisationId)

    useGetMaterialsQuery({ organisationId })
    useGetCuttingTechnologiesQuery({ organisationId })

    const { data: rateTables, isLoading } = useGetRateTablesQuery({ organisationId })

    const selectedRateTableId = useSelector(selectSelectedRateTableId)
    const selectedRateTable = useMemo(() => {
        return rateTables?.[selectedRateTableId]
    }, [rateTables, selectedRateTableId])

    const showAddRateTableForm = useSelector(selectShowAddRateTableForm)

    const [showAddRateDrawer, setShowAddRateDrawer] = useState(false)

    const handleAddRateTableClick = () => dispatch(setShowAddRateTableForm(true))
    const handleAddRateTableClose = () => dispatch(setShowAddRateTableForm(false))

    const handleMaterialsClick = () => navigate('/materials')

    const handleAddRateClick = () => {
        setShowAddRateDrawer(!showAddRateDrawer)
    }

    const handleAddRateClose = () => setShowAddRateDrawer(false)

    const [showDuplicateRateTableDialog, setShowDuplicateRateTableDialog] = useState(false)

    const handleDuplicateRateTableClick = () => setShowDuplicateRateTableDialog(true)
    const hideDuplicateRateTableDialog = () => setShowDuplicateRateTableDialog(false)

    useEffect(() => {
        if (!selectedRateTableId) {
            dispatch(setShowDrawerMenu(true))
            dispatch(setShowNestedDrawerMenu('rate-tables'))
        }
        return () => {
            dispatch(setSelectedRateTableId(null))
        }
    }, [])

    return !isLoading ? (
        <>
            <RateTablesToolbar
                handleAddRateTableClick={handleAddRateTableClick}
                handleDuplicateRateTableClick={handleDuplicateRateTableClick}
                handleMaterialsClick={handleMaterialsClick}
            />

            {(selectedRateTableId && (
                <Box sx={classes.root}>
                    <Box sx={classes.header}>
                        <RateTableHeader />
                    </Box>
                    <Box sx={classes.details}>
                        <RateTableDetails />
                    </Box>
                    <Box sx={classes.actionBar}>
                        <Button
                            color="primary"
                            data-testid="add-thickness-button"
                            disabled={selectedRateTable?.isDeleted}
                            size="small"
                            startIcon={<Add />}
                            variant="outlined"
                            onClick={handleAddRateClick}
                        >
                            {t('Add thickness')}
                        </Button>
                    </Box>
                    <RatesTable selectedRateTableId={selectedRateTableId} />
                </Box>
            )) || (
                <Box sx={classes.noRateTableContainer}>
                    <Typography sx={classes.noRateTableText}>
                        {t('Select a rate table from the list to view its details.')}
                    </Typography>
                </Box>
            )}

            <TbxDrawer
                showDrawer={showAddRateTableForm}
                onClose={handleAddRateTableClose}
            >
                <AddRateTableForm onClose={handleAddRateTableClose} />
            </TbxDrawer>
            <TbxDrawer
                showDrawer={showAddRateDrawer}
                onClose={handleAddRateClose}
            >
                <AddRateForm
                    selectedRateTableId={selectedRateTableId}
                    onCancel={handleAddRateClose}
                />
            </TbxDrawer>

            <DuplicateRateTableDialog
                rateTable={selectedRateTable}
                showDialog={showDuplicateRateTableDialog}
                onClose={hideDuplicateRateTableDialog}
            />
        </>
    ) : null
}

export default RateTables
