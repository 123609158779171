import { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useSelector } from 'react-redux'
import { Box, Checkbox, FormControlLabel, Typography } from '@mui/material'
import { useSnackbar } from 'notistack'
import PropTypes from 'prop-types'

import { useUpdateCustomerMutation } from '@/app/services/customers'
import { selectOrganisationId } from '@/app/slices/organisationSlice'
import { TbxDrawer } from '@/common/components'
import { useToolBoxTreatments } from '@/common/hooks'
import AddressEditForm from '@/features/customer-central/components/AddressEditForm'

import AddressCard from '../../AddressCard'
import TaxRateCard from '../../TaxRateCard'

const classes = {
    container: {
        display: 'flex',
        flexDirection: 'column',
    },

    cards: {
        display: 'flex',
        flexDirection: 'row',
        marginBlock: 1,
    },

    checkboxLabel: {
        color: '#5E7387',
        fontSize: '14px',
        fontWeight: 400,
        lineHeight: '21px',
        letterSpacing: '0px',
    },

    cardActions: {
        padding: '0px 8px 24px 0',
    },

    cardContent: {
        padding: 3,
    },

    cardTitle: {
        color: 'text.primary',
        fontSize: '1.3rem',
        fontWeight: 500,
        lineHeight: '24px',
        paddingBottom: 3,
    },
}

const CustomerSummaryTab = ({ customer }) => {
    const { t } = useTranslation()
    const { enqueueSnackbar } = useSnackbar()

    const { showCustomerTaxRates } = useToolBoxTreatments()

    const organisationId = useSelector(selectOrganisationId)

    const [updateCustomer] = useUpdateCustomerMutation()

    const [useOneAddress, setUseOneAddresses] = useState(customer?.useOneAddress)
    const [addressType, setAddressType] = useState(null)

    const handleUseSameAddressesChange = async (event) => {
        const newValue = event.target.checked

        setUseOneAddresses(newValue)

        if (!newValue) return

        const updatedCustomer = {
            ...customer,
            useOneAddress: newValue,
            billingAddress: customer.deliveryAddress,
        }

        try {
            await updateCustomer({ organisationId, customer: updatedCustomer }).unwrap()

            enqueueSnackbar(t('Customer has been updated successfully'), {
                variant: 'success',
            })
        } catch (error) {
            enqueueSnackbar(t('Failed to update customer'), {
                variant: 'error',
            })
        }
    }

    const handleEditDeliveryAddressChange = () => {
        setAddressType('deliveryAddress')
    }

    const handleEditBillingAddressChange = () => {
        setAddressType('billingAddress')
    }

    const handleEditDrawerClose = () => {
        setAddressType(null)
    }

    useEffect(() => {
        if (customer?.useOneAddress !== useOneAddress) {
            setUseOneAddresses(customer?.useOneAddress)
        }
    }, [customer])

    return (
        <Box sx={classes.container}>
            <Box mb={3}>
                <Typography variant="h6">{t('Address settings')}</Typography>
                <FormControlLabel
                    control={
                        <Checkbox
                            checked={useOneAddress}
                            color="primary"
                            disabled={customer?.isDeleted}
                            name="checked"
                            size="small"
                            onChange={handleUseSameAddressesChange}
                        />
                    }
                    label={
                        <Typography sx={classes.checkboxLabel}>
                            {t('The Billing address is the same as the Delivery address')}
                        </Typography>
                    }
                />
                <Box sx={classes.cards}>
                    <AddressCard
                        address={customer?.deliveryAddress}
                        title={t('Delivery Address')}
                        onEditButtonClick={handleEditDeliveryAddressChange}
                    />

                    <AddressCard
                        address={customer?.billingAddress}
                        isEditable={!useOneAddress}
                        title={t('Billing Address')}
                        onEditButtonClick={handleEditBillingAddressChange}
                    />
                </Box>
            </Box>

            {showCustomerTaxRates ? (
                <Box width={480}>
                    <Typography
                        mb={1}
                        variant="h6"
                    >
                        {t('Tax settings')}
                    </Typography>
                    <Typography
                        mb={2}
                        sx={classes.checkboxLabel}
                    >
                        {t("Select a custom tax rate for this user or use the organisation's defaults")}
                    </Typography>
                    <TaxRateCard customer={customer} />
                </Box>
            ) : null}

            <TbxDrawer
                showDrawer={!!addressType}
                onClose={handleEditDrawerClose}
            >
                <AddressEditForm
                    addressType={addressType}
                    customer={customer}
                    useOneAddress={useOneAddress}
                    onCancel={handleEditDrawerClose}
                />
            </TbxDrawer>
        </Box>
    )
}

CustomerSummaryTab.propTypes = {
    customer: PropTypes.object.isRequired,
    currentTab: PropTypes.number,
    tabValue: PropTypes.number,
}

export default CustomerSummaryTab
