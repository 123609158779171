import { Trans, useTranslation } from 'react-i18next'
import { useSelector } from 'react-redux'
import { Link } from '@mui/material'

import { selectOrganisation } from '@/app/slices/organisationSlice'
import { useToolBoxTreatments } from '@/common/hooks'
import { LIBELLULA_PRICING_REDIRECT_URL, TOOLBOX_PRICING_REDIRECT_URL } from '@/common/utils'

const UpgradePlanLink = () => {
    const { t } = useTranslation()
    const { showLibellulaWhitelabelling } = useToolBoxTreatments()

    const organisation = useSelector(selectOrganisation)

    const handleUpgradeButtonClicked = () => {
        window.open(
            showLibellulaWhitelabelling
                ? LIBELLULA_PRICING_REDIRECT_URL
                : `${TOOLBOX_PRICING_REDIRECT_URL}${organisation?.paymentGatewayCustomerId}`
        )
    }

    return (
        <Trans t={t}>
            The plan you are subscribed to does not include this feature. Please{' '}
            <Link onClick={handleUpgradeButtonClicked}>upgrade</Link> your plan.
        </Trans>
    )
}

export default UpgradePlanLink
