import { useTranslation } from 'react-i18next'
import { Alert, Snackbar } from '@mui/material'
import PropTypes from 'prop-types'

const ErrorSnackbar = ({ autoHideDuration, children, onClose, open }) => {
    const { t } = useTranslation()

    const defaultAutoHideDuration = 7500
    const alertContent = children ?? t('$t(An error occurred).')
    const showtime = autoHideDuration || autoHideDuration === 0 ? autoHideDuration : defaultAutoHideDuration

    return (
        <Snackbar
            autoHideDuration={showtime}
            open={open}
            onClose={onClose}
        >
            <Alert
                severity="error"
                onClose={onClose}
            >
                {alertContent}
            </Alert>
        </Snackbar>
    )
}

ErrorSnackbar.propTypes = {
    open: PropTypes.bool.isRequired,
    onClose: PropTypes.func.isRequired,
    autoHideDuration: PropTypes.number,
    children: PropTypes.node,
}

export default ErrorSnackbar
