import { useCallback, useMemo, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { CreditCardRounded } from '@mui/icons-material'
import { Box, Button, TableCell, TableRow } from '@mui/material'
import { useSnackbar } from 'notistack'
import PropTypes from 'prop-types'

import { useCreateStripeCheckoutSessionMutation } from '@/app/services/integrations'
import { useNumberFormatter } from '@/common/hooks'
import PaymentMethodsIcon from '@/common/icons/PaymentMethodsIcon/paymentMethodsIcon'
import { getFormattedPrice, Paths, QUOTE_PAYMENT_STATUS, QUOTE_STATUS } from '@/common/utils'

const TotalTableRow = ({
    colspan = [3, 6],
    currencyCode,
    deliveryOption,
    deliveryPrice,
    deliveryPricingMethod,
    document,
    linePrice,
    locale,
    minimumQuoteChargePrice,
    organisationId,
    paymentStatus = 'Unpaid',
    paymentsEnabled = false,
    quoteId,
    quoteStatus,
    taxRate,
    totalAmount,
}) => {
    const { t } = useTranslation()
    const { enqueueSnackbar } = useSnackbar()

    const [isInitiatingPayment, setIsInitiatingPayment] = useState(false)

    const [createStripeCheckoutSession] = useCreateStripeCheckoutSessionMutation()

    const totalTax = totalAmount - (minimumQuoteChargePrice || 0) - linePrice

    const totalBeforeTax = linePrice + (minimumQuoteChargePrice || 0)

    const quoteTaxRate = useMemo(
        () => (taxRate?.percentage ? taxRate?.percentage : totalTax / totalBeforeTax),
        [taxRate, totalTax, totalBeforeTax]
    )

    const showDeliveryPrice = deliveryOption === 1 && deliveryPricingMethod === 'ChargeToOrder'

    const decimalPlaces = taxRate?.percentage ? 4 : 2

    const { n: percentageFormatter } = useNumberFormatter({
        numberOfDecimalPlaces: decimalPlaces,
        style: 'percent',
    })

    const orderAndPay = [
        QUOTE_STATUS.Draft,
        QUOTE_STATUS.Issued,
        QUOTE_STATUS.PendingOrderConfirmation,
        QUOTE_STATUS.Lost,
    ].includes(quoteStatus)

    const isPaid =
        paymentStatus === QUOTE_PAYMENT_STATUS.ManualPaid || paymentStatus === QUOTE_PAYMENT_STATUS.GatewayPaid

    const handlePaymentButton = useCallback(async () => {
        setIsInitiatingPayment(true)
        try {
            const stripeCheckoutSessionUrl = await createStripeCheckoutSession({
                organisationId: organisationId,
                quoteId: quoteId,
                successUrl: `${import.meta.env.VITE_AUTH_REDIRECT_URI}${Paths.PAYMENTS_PATHNAME}/${quoteId}/${Paths.PAYMENTS_SUCCESS_PATHNAME}`,
                cancelUrl: `${import.meta.env.VITE_AUTH_REDIRECT_URI}${Paths.SHARED_PATHNAME}/v2/${quoteId}/${document}`,
            }).unwrap()

            enqueueSnackbar('Redirecting to payment gateway...', {
                variant: 'info',
                onExited: () => {
                    window.open(stripeCheckoutSessionUrl, '_self')
                    setIsInitiatingPayment(false)
                },
            })
        } catch (error) {
            console.error('Documents', 'Payment Button Error', error)
            enqueueSnackbar('Error initiating payment', {
                variant: 'error',
            })
        }
    }, [createStripeCheckoutSession, document, enqueueSnackbar, organisationId, quoteId])

    return (
        <>
            {minimumQuoteChargePrice ? (
                <TableRow sx={{ td: { border: 0 } }}>
                    <TableCell
                        align="right"
                        colSpan={colspan[1]}
                    >
                        {t('Minimum order charge')}
                    </TableCell>
                    <TableCell
                        align="right"
                        colSpan={2}
                        sx={{ typography: 'strong2' }}
                    >
                        {getFormattedPrice(minimumQuoteChargePrice, currencyCode, locale)}
                    </TableCell>
                </TableRow>
            ) : null}

            {showDeliveryPrice ? (
                <TableRow sx={{ td: { border: 0 } }}>
                    <TableCell
                        align="right"
                        colSpan={colspan[1]}
                    >
                        {t('Delivery')}
                    </TableCell>
                    <TableCell
                        align="right"
                        colSpan={2}
                        sx={{ typography: 'strong2' }}
                    >
                        {getFormattedPrice(deliveryPrice, currencyCode, locale)}
                    </TableCell>
                </TableRow>
            ) : null}
            <TableRow sx={{ td: { border: 0 } }}>
                <TableCell
                    align="right"
                    colSpan={colspan[1]}
                >
                    {t('Subtotal')}
                </TableCell>
                <TableCell
                    align="right"
                    colSpan={2}
                    sx={{ typography: 'strong2' }}
                >
                    {getFormattedPrice(linePrice + (minimumQuoteChargePrice || 0), currencyCode, locale)}
                </TableCell>
            </TableRow>

            <TableRow>
                <TableCell
                    align="right"
                    colSpan={colspan[1]}
                >
                    {`${t('Taxes')} (${quoteTaxRate ? percentageFormatter(quoteTaxRate) : '0%'})`}
                </TableCell>
                <TableCell
                    align="right"
                    colSpan={2}
                    sx={{ typography: 'strong2' }}
                >
                    {getFormattedPrice(totalTax, currencyCode, locale, {
                        useGrouping: true,
                    })}
                </TableCell>
            </TableRow>
            <TableRow sx={{ verticalAlign: 'top', td: { border: 0 } }}>
                {paymentsEnabled && !isPaid ? (
                    <TableCell colSpan={3}>
                        <Box width={300}>
                            <Button
                                color="secondary"
                                data-testid="order-pay-button"
                                disabled={isInitiatingPayment}
                                endIcon={<CreditCardRounded />}
                                href={`${import.meta.env.VITE_AUTH_REDIRECT_URI}${Paths.SHARED_PATHNAME}/v2/${quoteId}/${document}`}
                                variant="contained"
                                onClick={(e) => {
                                    e.preventDefault()
                                    handlePaymentButton()
                                }}
                            >
                                {isInitiatingPayment
                                    ? t('Initiating payment...')
                                    : orderAndPay
                                      ? t('Order & Pay')
                                      : t('Pay Now')}
                            </Button>
                            <PaymentMethodsIcon
                                sx={{ mt: 1, width: 300, height: 50 }}
                                titleAccess={t('Available payment methods')}
                                inheritViewBox
                            />
                        </Box>
                    </TableCell>
                ) : null}
                <TableCell
                    align="right"
                    colSpan={paymentsEnabled && !isPaid ? colspan[0] : colspan[1]}
                    sx={{ typography: 'strong1' }}
                >
                    {t('Total')}
                </TableCell>
                <TableCell
                    align="right"
                    colSpan={2}
                    sx={{ typography: 'strong1' }}
                >
                    {getFormattedPrice(totalAmount, currencyCode, locale)}
                </TableCell>
            </TableRow>
        </>
    )
}

TotalTableRow.propTypes = {
    currencyCode: PropTypes.string.isRequired,
    locale: PropTypes.string.isRequired,
    totalAmount: PropTypes.number.isRequired,
    colspan: PropTypes.arrayOf(PropTypes.number),
    deliveryOption: PropTypes.number,
    deliveryPrice: PropTypes.number,
    deliveryPricingMethod: PropTypes.string,
    document: PropTypes.string,
    linePrice: PropTypes.number,
    minimumQuoteChargePrice: PropTypes.number,
    organisationId: PropTypes.string,
    paymentsEnabled: PropTypes.bool,
    paymentStatus: PropTypes.string,
    quoteId: PropTypes.string,
    quoteStatus: PropTypes.string,
    taxRate: PropTypes.object,
}

export default TotalTableRow
