import { memo, useMemo, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useDispatch, useSelector } from 'react-redux'
import { useParams } from 'react-router-dom'
import { Add, Create } from '@mui/icons-material'
import { Box, IconButton, Typography } from '@mui/material'
import { useSnackbar } from 'notistack'
import PropTypes from 'prop-types'

import { useGetQuoteItemsQuery, useUpdateQuoteItemMutation } from '@/app/services/quoteItems'
import { useGetQuoteQuery, useUpdateQuoteMutation } from '@/app/services/quotes'
import { useGetSecondaryProcessesQuery } from '@/app/services/secondaryProcesses'
import { selectOrganisationId } from '@/app/slices/organisationSlice'
import { setSelectedQuoteStatus } from '@/app/slices/quoteItemsSlice'
import TbxTooltip from '@/common/components/TbxTooltip/TbxTooltip'
import { QUOTE_STATUS } from '@/common/utils'

import QuantitySecProcess from './QuantitySecProcess'
import SecondaryProcessesModal from './SecondaryProcessesModal'

const classes = {
    secProcessesSection: {
        width: '100%',
        gap: '12px',
    },
    bold: {
        fontWeight: 'bold',
    },
    formControl_small: {
        width: '80px',
    },
}

const ItemSecondaryProcesses = ({ quoteItemId }) => {
    const { t } = useTranslation()
    const { enqueueSnackbar } = useSnackbar()
    const { quoteId } = useParams()
    const dispatch = useDispatch()

    const organisationId = useSelector(selectOrganisationId)

    const { data: selectedQuote } = useGetQuoteQuery({ organisationId, quoteId })
    const { quoteItem } = useGetQuoteItemsQuery(
        {
            organisationId,
            quoteId,
        },
        {
            selectFromResult: ({ data }) => ({
                quoteItem: data?.find((item) => item.id === quoteItemId),
            }),
        }
    )

    const [isEditDialogOpen, setIsEditDialogOpen] = useState(false)

    const [itemSecondaryProcesses, setItemSecondaryProcesses] = useState(quoteItem?.secondaryProcesses)

    const { data: organisationSecondaryProcesses } = useGetSecondaryProcessesQuery({ organisationId })

    const { isFetching: isFetchingQuote } = useGetQuoteQuery({
        organisationId,
        quoteId,
    })

    const [updateQuote] = useUpdateQuoteMutation({ fixedCacheKey: 'shared-update-quote' })
    const [updateQuoteItem] = useUpdateQuoteItemMutation()

    const isPartFromLibrary = Boolean(quoteItem?.partLibraryEntryId)

    const handleUpdateQuote = async () => {
        try {
            await updateQuote({
                organisationId,
                quoteId,
                quote: {
                    ...selectedQuote,
                    status: QUOTE_STATUS.NotCalculated,
                },
            }).unwrap()
        } catch (_e) {
            const errorMessage = t('$t(An error occurred) updating the $t(quote).')
            enqueueSnackbar(errorMessage, { variant: 'error' })
        }
    }

    const handleUpdateQuoteItem = async (quoteItem) => {
        try {
            await updateQuoteItem({
                organisationId,
                quoteId,
                quoteItemId: quoteItem?.id,
                quoteItem,
            }).unwrap()

            if (selectedQuote.status !== QUOTE_STATUS.NotCalculated) {
                handleUpdateQuote()
            }
        } catch (error) {
            enqueueSnackbar(t('Failed to update the $t(quote) item'), {
                variant: 'error',
            })
        }
    }

    const ItemRow = ({ children }) => (
        <Box
            alignItems="center"
            display="flex"
            flexDirection="row"
            justifyContent="space-between"
            width="100%"
        >
            {children}
        </Box>
    )

    const handleEditButtonClick = (event) => {
        event.stopPropagation()
        setIsEditDialogOpen(true)
    }

    const onCloseEditDialog = async (processes) => {
        setIsEditDialogOpen(false)
        if (processes) {
            setItemSecondaryProcesses(processes)
            await handleUpdateQuoteItem({ ...quoteItem, secondaryProcesses: processes })

            if (selectedQuote.status !== QUOTE_STATUS.NotCalculated) {
                dispatch(setSelectedQuoteStatus(QUOTE_STATUS.NotCalculated))
            }
        }
    }

    const onQuantityInputChange = async (process) => {
        if (selectedQuote.status !== QUOTE_STATUS.NotCalculated) {
            dispatch(setSelectedQuoteStatus(QUOTE_STATUS.NotCalculated))
        }

        const processIds = itemSecondaryProcesses?.map((sp) => sp.secondaryProcessId)
        const processIndex = processIds.indexOf(process.secondaryProcessId)
        const newProcessList = [...itemSecondaryProcesses]
        newProcessList[processIndex] = process
        setItemSecondaryProcesses(newProcessList)

        await handleUpdateQuoteItem({ ...quoteItem, secondaryProcesses: newProcessList })
    }

    const getProcessDetails = (processId) =>
        organisationSecondaryProcesses?.find((osp) => osp.secondaryProcessId === processId)

    const sortedProcesses = useMemo(() => {
        return itemSecondaryProcesses
            ?.map((process) => ({
                ...process,
                index: getProcessDetails(process.secondaryProcessId)?.index,
            }))
            .sort((a, b) => (a.index < b.index ? -1 : 1))
    }, [itemSecondaryProcesses])

    return (
        <Box
            alignItems="flex-start"
            display="flex"
            flex="1"
            flexDirection="column"
            sx={classes.secProcessesSection}
        >
            <ItemRow>
                <Typography
                    data-testid="nqp-secondary-processes-section-title"
                    sx={classes.bold}
                >
                    {t('Secondary processes')}
                </Typography>
                <TbxTooltip
                    title={isPartFromLibrary ? t('Cannot edit parts from library') : ''}
                    arrow
                >
                    <span>
                        <IconButton
                            aria-label="edit"
                            color="primary"
                            data-testid="nqp-secondary-processes-add-button"
                            disabled={isFetchingQuote || isPartFromLibrary}
                            size="small"
                            onClick={(event) => handleEditButtonClick(event)}
                            onFocus={(event) => event.stopPropagation()}
                        >
                            {itemSecondaryProcesses?.length > 0 ? <Create /> : <Add />}
                        </IconButton>
                    </span>
                </TbxTooltip>
            </ItemRow>
            {sortedProcesses?.map((process) => (
                <ItemRow key={process.secondaryProcessId}>
                    <Typography
                        data-testid="nqp-quote-item-secondary-process-label"
                        variant="body2"
                    >
                        {getProcessDetails(process.secondaryProcessId)?.name}
                    </Typography>
                    {getProcessDetails(process.secondaryProcessId)?.measureType === 'Quantity' ? (
                        <QuantitySecProcess
                            isPartFromLibrary={isPartFromLibrary}
                            process={process}
                            onInputChange={onQuantityInputChange}
                        />
                    ) : null}
                </ItemRow>
            ))}
            {isEditDialogOpen ? (
                <SecondaryProcessesModal
                    open={isEditDialogOpen}
                    secondaryProcesses={quoteItem?.secondaryProcesses}
                    onClose={onCloseEditDialog}
                />
            ) : null}
        </Box>
    )
}

ItemSecondaryProcesses.propTypes = {
    quoteItemId: PropTypes.string,
}

export default memo(ItemSecondaryProcesses)
