import { useTranslation } from 'react-i18next'
import { Box, Typography } from '@mui/material'

const classes = {
    legendContainer: {
        color: 'white',
        backgroundColor: 'hsla(0, 0%, 15%, .95)',
        height: 'auto',
        display: 'block',
        position: 'absolute',
        left: 20,
        top: 20,
        maxWidth: '400px',
        zIndex: 1010,
        padding: 3,
        border: '2px solid white',
        borderRadius: 2,
        pointerEvents: 'none',
    },
    legendListContainer: {
        display: 'flex',
        flexDirection: 'column',
        gap: 2,
    },
    legendItem: {
        display: 'flex',
        flexDirection: 'row',
        alignItems: 'center',
        justifyContent: 'space-between',
        gap: 4,
    },
}

const ShortcutsLegend = () => {
    const { t } = useTranslation()

    const platform = window.navigator.platform.toLowerCase()

    const isMacOS = platform.indexOf('mac') !== -1

    const options = [
        {
            label: t('Select all'),
            shortcut: `${isMacOS ? '\u2318' : 'Ctrl +'}  A`,
        },
        {
            label: t('Select path'),
            shortcut: t('Left click'),
        },
        {
            label: t('Add path to selection'),
            shortcut: t('Left click'),
        },
        {
            label: t('Remove path from selection'),
            shortcut: t('Left click'),
        },
        {
            label: t('Selection box'),
            shortcut: t('Left click and drag'),
        },
        {
            label: t('Deselect all'),
            shortcut: 'ESC',
        },
        {
            label: t('Delete selected line'),
            shortcut: '\u232B',
        },
        {
            label: t('Set line to cutting'),
            shortcut: `${isMacOS ? '\u2325' : 'Alt +'}  C`,
        },
        {
            label: t('Set line to etching'),
            shortcut: `${isMacOS ? '\u2325' : 'Alt +'}  E`,
        },
        {
            label: t('Set line to information'),
            shortcut: `${isMacOS ? '\u2325' : 'Alt +'}  I`,
        },
        {
            label: t('Set line to folding'),
            shortcut: `${isMacOS ? '\u2325' : 'Alt +'}  F`,
        },
        {
            label: t('Zoom in'),
            shortcut: `${isMacOS ? '\u2318' : 'Ctrl +'} +`,
        },
        {
            label: t('Zoom out'),
            shortcut: `${isMacOS ? '\u2318' : 'Ctrl +'} \u2013`,
        },
        {
            label: t('Reset zoom'),
            shortcut: `${isMacOS ? '\u2318' : 'Ctrl +'} 0`,
        },
        {
            label: t('Zoom in/out'),
            shortcut: t('Mouse wheel'),
        },
        {
            label: t('Zoom to fit'),
            shortcut: t('Double middle click'),
        },
        {
            label: t('Panning'),
            shortcut: t('Middle click and drag'),
        },
        {
            label: t('Show/Hide shortcuts'),
            shortcut: `${isMacOS ? '\u2325' : 'Ctrl +'} S`,
        },
        {
            label: t('Show/Hide path legend'),
            shortcut: `${isMacOS ? '\u2325' : 'Ctrl +'} P`,
        },
    ]

    return (
        <Box sx={classes.legendContainer}>
            <Box sx={classes.legendListContainer}>
                <Typography
                    textTransform="uppercase"
                    variant="strong2"
                >
                    {t('Shortcut list')}
                </Typography>
                {options.map((option) => {
                    return (
                        <Box
                            key={option.label}
                            sx={classes.legendItem}
                        >
                            <Box sx={classes.legendLabel}>
                                <Typography
                                    color="text.secondary"
                                    variant="body2"
                                >
                                    {option.label}
                                </Typography>
                            </Box>
                            <Box sx={classes.leyendShortcut}>
                                <Typography
                                    color="text.secondary"
                                    variant="body2"
                                >
                                    {option.shortcut}
                                </Typography>
                            </Box>
                        </Box>
                    )
                })}
            </Box>
        </Box>
    )
}

export default ShortcutsLegend
