import { useCallback, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useSelector } from 'react-redux'
import { Card, CardContent, FormControl, FormHelperText, InputLabel, MenuItem, Select, Typography } from '@mui/material'
import allCountries from 'country-region-data'
import { useSnackbar } from 'notistack'
import PropTypes from 'prop-types'

import { useUpdateCustomerMutation } from '@/app/services/customers'
import { useGetTaxRatesQuery } from '@/app/services/taxRates'
import { selectOrganisationId } from '@/app/slices/organisationSlice'
import { fractionToPercentage } from '@/common/utils'

const classes = {
    root: {
        marginRight: 3,
    },
    cardContent: {
        padding: 3,
    },
    disabledCard: {
        opacity: 0.5,
        PointerEvent: 'none',
    },
    cardHeader: {
        display: 'flex',
        flexDirection: 'row',
        marginBottom: 2,
        justifyContent: 'space-between',
        alignItems: 'center',
    },
}

const TaxRateCard = ({ customer }) => {
    const { t } = useTranslation()
    const { enqueueSnackbar } = useSnackbar()

    const organisationId = useSelector(selectOrganisationId)

    const [updateCustomer] = useUpdateCustomerMutation()
    const { data: taxRates } = useGetTaxRatesQuery({ organisationId })

    const [taxRateId, setTaxRateId] = useState(customer?.taxRateId)

    const countryName = useCallback(
        (countryCode) => {
            const country = allCountries.find((country) => country.countryShortCode === countryCode?.toUpperCase())
            return country?.countryName ?? null
        },
        [allCountries]
    )

    const stateName = useCallback(
        (countryCode, stateCode) => {
            const country = allCountries.find((country) => country.countryShortCode === countryCode?.toUpperCase())
            const state = country?.regions.find((region) => region.shortCode === stateCode)
            return state?.name ?? null
        },
        [allCountries]
    )

    const label = (taxRate) => {
        const { country, displayName, percentage, state } = taxRate
        const stateLabel = state ? `${stateName(country, state)} - ` : ''
        const countryLabel = country ? `${countryName(country)} - ` : ''
        const percentageLabel = fractionToPercentage(percentage)
        return `${displayName} - ${stateLabel}${countryLabel} (${percentageLabel}%)`
    }

    const handleTaxRateChange = async (event) => {
        const newTaxRateId = event.target.value

        setTaxRateId(newTaxRateId)

        const updatedCustomer = {
            ...customer,
            taxRateId: newTaxRateId,
        }

        try {
            await updateCustomer({ organisationId, customer: updatedCustomer }).unwrap()

            enqueueSnackbar(t('Customer has been updated successfully'), {
                variant: 'success',
            })
        } catch (error) {
            console.error(error)

            enqueueSnackbar(t('Failed to update customer'), {
                variant: 'error',
            })
        }
    }

    return (
        <Card
            sx={classes.card}
            variant="outlined"
        >
            <CardContent sx={classes.cardContent}>
                <Typography
                    mb={1}
                    variant="strong1"
                >
                    {t('Customer default tax rate')}
                </Typography>
                <FormControl fullWidth>
                    <InputLabel
                        data-testid="default-tax-rate-label"
                        htmlFor="default-tax-rate"
                        id="default-tax-rate-label"
                    >
                        {!taxRateId ? t("Use organisation's default tax rate") : t('Tax rate')}
                    </InputLabel>
                    <Select
                        id="default-tax-rate"
                        labelId="default-tax-rate-label"
                        value={taxRateId || ''}
                        onChange={handleTaxRateChange}
                    >
                        <MenuItem value="">
                            <em>{t("Use organisation's default tax rate")}</em>
                        </MenuItem>
                        {taxRates?.map((taxRate) => (
                            <MenuItem
                                key={taxRate.id}
                                value={taxRate.id}
                            >
                                {label(taxRate)}
                            </MenuItem>
                        ))}
                    </Select>

                    <FormHelperText>{t('Select from organisation tax rates.')}</FormHelperText>
                </FormControl>
            </CardContent>
        </Card>
    )
}

TaxRateCard.propTypes = {
    customer: PropTypes.object.isRequired,
}

export default TaxRateCard
