import { useCallback, useState } from 'react'
import { Controller, useForm } from 'react-hook-form'
import { useTranslation } from 'react-i18next'
import { useDispatch, useSelector } from 'react-redux'
import { Box, Button, InputAdornment, InputLabel, MenuItem, Select, TextField, Typography } from '@mui/material'
import PropTypes from 'prop-types'
import { v4 as uuidv4 } from 'uuid'

import { useGetCuttingTechnologiesQuery } from '@/app/services/cuttingTechnologies'
import { useCreateRateTableMutation, useGetRateTablesQuery } from '@/app/services/rateTables'
import { selectOrganisation, selectOrganisationId, selectPaidFeatures } from '@/app/slices/organisationSlice'
import { setSelectedRateTableId } from '@/app/slices/rateTablesSlice'

const classes = {
    title: {
        fontWeight: 500,
        fontSize: '24px',
        lineHeight: '28px',
        color: 'text.primary',
        marginBottom: '24px',
    },
    buttonsContainer: {
        display: 'flex',
        justifyContent: 'space-evenly',
        marginTop: '24px',
        paddingBottom: '36px', // leave room at the bottom of the form for any hovering action buttons being injected
    },
    input: {
        marginBottom: '16px',
    },
    button: {
        fontWeight: 500,
        minWidth: '142px',
        height: '36px',
    },
    addressTitle: {
        fontWeight: 500,
        fontSize: '18px',
        lineHeight: '28px',
        color: 'text.primary',
        margin: '16px 0',
    },
    checkboxLabel: {
        color: '#5E7387',
        fontSize: '14px',
        fontWeight: 400,
        lineHeight: '21px',
        letterSpacing: '0px',
    },
}

const AddRateTableForm = ({ cuttingTechnologyId, onClose, onSave }) => {
    const { t } = useTranslation()

    const dispatch = useDispatch()

    const organisation = useSelector(selectOrganisation)
    const organisationId = useSelector(selectOrganisationId)
    const paidFeatures = useSelector(selectPaidFeatures)

    const { data: cuttingTechnologies } = useGetCuttingTechnologiesQuery({ organisationId })
    const { data: rateTables } = useGetRateTablesQuery({ organisationId })

    const [createRateTable] = useCreateRateTableMutation()

    const foundSimilarRateTable = (rateTableName) =>
        rateTables && Object.values(rateTables).find((rt) => rt.name.toLowerCase() === rateTableName.toLowerCase())

    const [isSaving, setIsSaving] = useState(false)

    const getDefaultCuttingTech = useCallback(() => {
        const defaultCuttingTech = Object.values(cuttingTechnologies).find((ct) => ct.isDefault === true)
        return defaultCuttingTech?.cuttingTechnologyId
    }, [cuttingTechnologies])

    const { control, formState, getValues, handleSubmit } = useForm({
        mode: 'all',
        defaultValues: {
            name: '',
            cuttingTechnologyId: cuttingTechnologyId ? cuttingTechnologyId : getDefaultCuttingTech(),
            baseHourlyRatePrice: '',
            etchingFeedRate: '',
        },
    })

    const handleAddRateTableButtonClick = async (data, _) => {
        setIsSaving(true)
        data['rateTableId'] = uuidv4()
        await createRateTable({ organisationId, rateTable: data })
        dispatch(setSelectedRateTableId(data.rateTableId))
        setIsSaving(false)
        if (typeof onClose === 'function') {
            if (typeof onSave === 'function') {
                onSave(data.rateTableId)
            }
            onClose()
        }
    }

    const canAddRateTable = (rateTableName) => {
        const similarRateTable = foundSimilarRateTable(rateTableName)
        const { cuttingTechnologyId } = getValues()

        if (similarRateTable && similarRateTable.cuttingTechnologyId === cuttingTechnologyId) {
            return t('Rate table already exists for selected cutting technology')
        }

        return true
    }

    return (
        <>
            <Typography sx={classes.title}>{t('Add rate table')}</Typography>
            <Controller
                control={control}
                name="name"
                render={({ field: { onBlur, onChange, value }, fieldState }) => (
                    <TextField
                        error={fieldState.invalid}
                        helperText={fieldState.error?.message || t('Required')}
                        label={t('Name')}
                        sx={classes.input}
                        value={value}
                        fullWidth
                        required
                        onBlur={onBlur}
                        onChange={onChange}
                    />
                )}
                rules={{ required: true, validate: (value) => canAddRateTable(value) }}
            />
            <Controller
                control={control}
                name="cuttingTechnologyId"
                render={({ field: { onBlur, onChange, value }, fieldState }) => (
                    <>
                        <InputLabel id="cutting-technology-select-label">{`${t('Cutting technology')} *`}</InputLabel>
                        <Select
                            disabled={cuttingTechnologyId || !paidFeatures.hasAdvancedMaterials}
                            error={fieldState.invalid}
                            labelId="cutting-technology-select-label"
                            sx={classes.input}
                            value={value}
                            variant="standard"
                            fullWidth
                            required
                            onBlur={onBlur}
                            onChange={onChange}
                        >
                            {cuttingTechnologies
                                ? Object.values(cuttingTechnologies)
                                      .filter((ct) => !ct.isDeleted)
                                      .map((ct) => (
                                          <MenuItem
                                              key={ct.cuttingTechnologyId}
                                              value={ct.cuttingTechnologyId}
                                          >
                                              {t(ct.name)} ({t(ct.type)})
                                          </MenuItem>
                                      ))
                                : null}
                        </Select>
                    </>
                )}
                rules={{ required: true }}
            />
            <Controller
                control={control}
                name="baseHourlyRatePrice"
                render={({ field: { onBlur, onChange, value }, fieldState }) => (
                    <TextField
                        error={fieldState.invalid}
                        helperText={fieldState.error?.message || t('Required')}
                        InputProps={{
                            endAdornment: <InputAdornment position="end">{organisation.currencyCode}</InputAdornment>,
                        }}
                        label={t('Base hourly rate') + ' *'}
                        sx={classes.input}
                        type="number"
                        value={value}
                        fullWidth
                        onBlur={onBlur}
                        onChange={onChange}
                    />
                )}
                rules={{ required: true, min: 1 }}
            />

            <Controller
                control={control}
                name="etchingFeedRate"
                render={({ field: { onBlur, onChange, value }, fieldState }) => (
                    <TextField
                        error={fieldState.invalid}
                        helperText={fieldState.error?.message || ''}
                        InputProps={{
                            endAdornment: (
                                <InputAdornment position="end">
                                    {organisation.defaultDrawingUnits === 'Imperial' ? 'in/min' : 'mm/min'}
                                </InputAdornment>
                            ),
                        }}
                        label={t('Etching feed rate')}
                        sx={classes.input}
                        type="number"
                        value={value}
                        fullWidth
                        onBlur={onBlur}
                        onChange={onChange}
                    />
                )}
                rules={{ min: 0 }}
            />

            <Box sx={classes.buttonsContainer}>
                <Button
                    color="primary"
                    disabled={isSaving}
                    key="cancel"
                    sx={classes.button}
                    variant="outlined"
                    onClick={onClose}
                >
                    {t('Cancel')}
                </Button>
                <Button
                    color="primary"
                    disabled={!formState.isValid || isSaving}
                    key="update"
                    sx={classes.button}
                    variant="contained"
                    onClick={handleSubmit(handleAddRateTableButtonClick)}
                >
                    {isSaving ? t('Saving...') : t('Add')}
                </Button>
            </Box>
        </>
    )
}

AddRateTableForm.propTypes = {
    cuttingTechnologyId: PropTypes.string,
    onClose: PropTypes.func,
    onSave: PropTypes.func,
}

export default AddRateTableForm
