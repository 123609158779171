import { api } from './api'

export const adminApi = api
    .enhanceEndpoints({
        addTagTypes: ['AdminOrganisations', 'AdminUsers'],
    })
    .injectEndpoints({
        endpoints: (builder) => ({
            getOrganisations: builder.query({
                query: () => '/admin/organisations',
                providesTags(result) {
                    return [
                        ...result.map(({ organisationId }) => ({ type: 'AdminOrganisations', id: organisationId })),
                        { type: 'AdminOrganisations', id: 'LIST' },
                    ]
                },
            }),

            getUsers: builder.query({
                query: () => '/admin/users',
                providesTags(result) {
                    return [
                        ...result.map(({ userId }) => ({ type: 'AdminUsers', id: userId })),
                        { type: 'AdminUsers', id: 'LIST' },
                    ]
                },
            }),

            deleteOrganisationUser: builder.mutation({
                query: ({ organisationId, userId }) => ({
                    url: `/admin/organisations/${organisationId}/${userId}`,
                    method: 'DELETE',
                }),
                invalidatesTags(result, error, { organisationId, userId }) {
                    return [
                        { type: 'AdminOrganisations', id: organisationId },
                        { type: 'AdminUsers', id: userId },
                    ]
                },
            }),

            unDeleteOrganisationUser: builder.mutation({
                query: ({ organisationId, userId }) => ({
                    url: `/admin/organisations/${organisationId}/${userId}`,
                    method: 'POST',
                }),
                invalidatesTags(result, error, { organisationId, userId }) {
                    return [
                        { type: 'AdminOrganisations', id: organisationId },
                        { type: 'AdminUsers', id: userId },
                    ]
                },
            }),

            setUserRole: builder.mutation({
                query: ({ userId, userRole }) => ({
                    url: `/admin/users/${userId}/user-role`,
                    method: 'POST',
                    params: { userRole },
                }),
                invalidatesTags(result, error, { userId }) {
                    return [{ type: 'AdminUsers', id: userId }]
                },
            }),

            setUserLastAccessedOrganisationAsync: builder.mutation({
                query: ({ organisationId, userId }) => ({
                    url: `/admin/users/${userId}/last-accessed-organisation`,
                    method: 'POST',
                    params: { organisationId },
                }),
                invalidatesTags(result, error, { userId }) {
                    return [{ type: 'AdminUsers', id: userId }]
                },
            }),
        }),
    })

export const {
    useDeleteOrganisationUserMutation,
    useGetOrganisationsQuery,
    useGetUsersQuery,
    useLazyGetOrganisationsQuery,
    useLazyGetUsersQuery,
    useSetUserLastAccessedOrganisationAsyncMutation,
    useSetUserRoleMutation,
    useUnDeleteOrganisationUserMutation,
} = adminApi
