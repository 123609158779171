import { useTranslation } from 'react-i18next'
import { Add } from '@mui/icons-material'
import { Button, CircularProgress } from '@mui/material'
import PropTypes from 'prop-types'

import TbxToolbar from '@/common/components/TbxToolbar/TbxToolbar'
import TbxToolbarActions from '@/common/components/TbxToolbar/TbxToolbarActions'
import TbxToolbarTitle from '@/common/components/TbxToolbar/TbxToolbarTitle'

import TbxToolbarMessages from '../../TbxToolbar/TbxToolbarMessages'

const QuotesDashboardToolbar = ({ handleCreateQuoteClick, isLoading }) => {
    const { t } = useTranslation()

    return (
        <TbxToolbar>
            <TbxToolbarTitle>{t('Quotes')} </TbxToolbarTitle>

            <TbxToolbarMessages />

            <TbxToolbarActions>
                <Button
                    color="primary"
                    data-testid="quote-add-new-button"
                    startIcon={
                        isLoading ? (
                            <CircularProgress
                                color="background"
                                size={20}
                            />
                        ) : (
                            <Add />
                        )
                    }
                    variant="contained"
                    onClick={isLoading ? null : handleCreateQuoteClick}
                >
                    {t(isLoading ? 'Creating $t(quote)...' : 'Create $t(quote)')}
                </Button>
            </TbxToolbarActions>
        </TbxToolbar>
    )
}

QuotesDashboardToolbar.propTypes = {
    handleCreateQuoteClick: PropTypes.func,
    isLoading: PropTypes.bool,
}

export default QuotesDashboardToolbar
