import { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { Box, TextField } from '@mui/material'
import PropTypes from 'prop-types'

import CountrySelectInput from '../CountrySelectInput/CountrySelectInput'
import LocationSearchInput from '../LocationSearchInput/LocationSearchInput'
import RegionSelectInput from '../RegionSelectInput/RegionSelectInput'

const AddressInput = ({
    address,
    autoFocus,
    autocompletePlaceholder,
    fullWidth = true,
    labelText,
    onChange,
    showAddressInputsOnValidation = false,
    sx,
    validateInput = true,
}) => {
    const { t } = useTranslation()

    const [addressState, setAddressState] = useState({
        line1: '',
        line2: '',
        city: '',
        state: '',
        country: '',
        postcode: '',
    })

    const getFormattedAddress = (address) => {
        if (!address) {
            return ''
        }

        let addressStr = address.line1 ? address.line1 + ' ' : ''
        addressStr += address.line2 ? address.line2 + ' ' : ''
        addressStr += address.city ? address.city + ' ' : ''
        addressStr += address.state ? address.state + ' ' : ''
        addressStr += address.postcode ? address.postcode + ' ' : ''
        addressStr += address.country ? address.country + ' ' : ''

        return addressStr
    }

    const handleAddressChange = (newAddress) => {
        setAddressState({
            line1: newAddress.line1 ?? '',
            line2: newAddress.line2 ?? '',
            city: newAddress.city ?? '',
            state: newAddress.state ?? '',
            country: newAddress.country ?? '',
            postcode: newAddress.postcode ?? '',
        })

        if (typeof onChange === 'function') {
            onChange(newAddress)
        }
    }

    const handleChange = (propertyName, newValue) => {
        const newAddress = { ...addressState }

        newAddress[propertyName] = newValue

        handleAddressChange(newAddress)
    }

    const handleSearchLocationDidUpdate = (address) => {
        handleAddressChange(address)
    }

    useEffect(() => {
        if (address) {
            setAddressState(address)
        }
    }, [address])

    return (
        <Box
            display={'flex'}
            flexDirection={'column'}
            gap={2}
            sx={[...(Array.isArray(sx) ? sx : [sx])]}
        >
            {validateInput ? (
                <LocationSearchInput
                    autoFocus={autoFocus}
                    fullWidth={fullWidth}
                    label={labelText}
                    placeholder={autocompletePlaceholder}
                    value={getFormattedAddress(address)}
                    onChange={handleSearchLocationDidUpdate}
                />
            ) : null}
            {!validateInput || showAddressInputsOnValidation ? (
                <Box
                    display={'flex'}
                    gap={4}
                    justifyContent="space-between"
                >
                    <Box
                        display="flex"
                        flexDirection={'column'}
                        flexGrow={1}
                        gap={2}
                    >
                        <TextField
                            autoFocus={autoFocus ? !validateInput : null}
                            disabled={validateInput}
                            label={t('Street address 1')}
                            placeholder={t('Street address 1')}
                            required={true}
                            value={addressState.line1}
                            fullWidth
                            onChange={(event) => handleChange('line1', event.target.value)}
                        />
                        <TextField
                            disabled={validateInput}
                            label={t('Street address 2')}
                            placeholder={t('Street address 2')}
                            required={true}
                            value={addressState.line2}
                            fullWidth
                            onChange={(event) => handleChange('line2', event.target.value)}
                        />
                        <TextField
                            disabled={validateInput}
                            label={t('Postcode')}
                            placeholder={t('Postcode')}
                            required={true}
                            value={addressState.postcode}
                            fullWidth
                            onChange={(event) => handleChange('postcode', event.target.value)}
                        />
                    </Box>
                    <Box
                        display="flex"
                        flexDirection={'column'}
                        flexGrow={1}
                        gap={2}
                    >
                        <TextField
                            disabled={validateInput}
                            label={t('City')}
                            placeholder={t('City')}
                            required={true}
                            value={addressState.city}
                            fullWidth
                            onChange={(event) => handleChange('city', event.target.value)}
                        />
                        <CountrySelectInput
                            disabled={validateInput}
                            required={true}
                            value={addressState.country}
                            onChange={(country) => handleChange('country', country)}
                        />
                        <RegionSelectInput
                            country={addressState.country}
                            disabled={validateInput}
                            required={true}
                            value={addressState.state}
                            onChange={(region) => handleChange('state', region)}
                        />
                    </Box>
                </Box>
            ) : null}
        </Box>
    )
}

AddressInput.propTypes = {
    address: PropTypes.shape({
        city: PropTypes.string,
        country: PropTypes.string,
        line1: PropTypes.string,
        line2: PropTypes.string,
        postcode: PropTypes.string,
        state: PropTypes.string,
    }),
    autocompletePlaceholder: PropTypes.string,
    autoFocus: PropTypes.bool,
    fullWidth: PropTypes.bool,
    labelPosition: PropTypes.oneOf(['start', 'end', 'bottom', 'top']),
    labelText: PropTypes.string,
    ref: PropTypes.func,
    showAddressInputsOnValidation: PropTypes.bool,
    sx: PropTypes.oneOfType([
        PropTypes.arrayOf(PropTypes.oneOfType([PropTypes.func, PropTypes.object, PropTypes.bool])),
        PropTypes.func,
        PropTypes.object,
    ]),
    validateInput: PropTypes.bool,
    onChange: PropTypes.func,
    onClick: PropTypes.func,
    onSubmit: PropTypes.func,
}

export default AddressInput
