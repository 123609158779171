import { useEffect, useMemo, useState } from 'react'
import { useTranslation } from 'react-i18next'
import PropTypes from 'prop-types'

import { Input, SelectOption } from '@/common/components'

const sheetChangeModeOptions = [
    { name: 'Line Item', value: 'ByLine' },
    { name: 'Material', value: 'ByGroup' },
]

const SheetChangeModeSelect = ({
    classes,
    disabled = false,
    fullWidth = true,
    hideLabel = false,
    includeEmptyOption = false,
    includeNoneOption = true,
    inputProps,
    label,
    labelPosition,
    labelText = 'Modes',
    onChange,
    sx,
    value = 'ByLine',
}) => {
    const { t } = useTranslation()

    const options = useMemo(() => {
        const options = sheetChangeModeOptions.map((o) => ({
            name: t(o.name),
            value: o.value,
        }))

        if (includeNoneOption) {
            options.unshift({ name: t('None'), value: 'None' })
        }

        if (includeEmptyOption) {
            options.unshift({ name: '-', value: '' })
        }

        return options
    }, [includeEmptyOption, includeNoneOption])

    const [selectedValue, setSelectedValue] = useState(value ?? '')

    useEffect(() => {
        setSelectedValue(value)
    }, [value])

    const handleChange = (event) => {
        const newSelectedValue = event.target.value.toString()

        setSelectedValue(newSelectedValue)

        if (typeof onChange === 'function') {
            onChange(newSelectedValue)
        }
    }

    return (
        <Input
            classes={classes}
            className={sx}
            disabled={disabled}
            fullWidth={fullWidth}
            hideLabel={hideLabel}
            inputProps={inputProps}
            label={label}
            labelPosition={labelPosition}
            labelText={t(labelText)}
            type="select"
            value={selectedValue}
            onChange={handleChange}
        >
            {options.map((mode) => (
                <SelectOption
                    key={mode.value}
                    value={mode.value}
                >
                    {mode.name}
                </SelectOption>
            ))}
        </Input>
    )
}

SheetChangeModeSelect.propTypes = {
    classes: PropTypes.object,
    disabled: PropTypes.bool,
    fullWidth: PropTypes.bool,
    hideLabel: PropTypes.bool,
    includeEmptyOption: PropTypes.bool,
    includeNoneOption: PropTypes.bool,
    inputProps: PropTypes.object,
    label: PropTypes.node,
    labelPosition: PropTypes.oneOf(['start', 'end', 'bottom', 'top']),
    labelText: PropTypes.string,
    sx: PropTypes.oneOfType([
        PropTypes.arrayOf(PropTypes.oneOfType([PropTypes.func, PropTypes.object, PropTypes.bool])),
        PropTypes.func,
        PropTypes.object,
    ]),
    value: PropTypes.string,
    onChange: PropTypes.func,
}

export default SheetChangeModeSelect
