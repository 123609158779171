import { api } from './api'

const apiVersion = 'v2'

export const rateTablesApi = api
    .enhanceEndpoints({
        addTagTypes: ['RateTables'],
    })
    .injectEndpoints({
        endpoints: (builder) => ({
            getRateTables: builder.query({
                query: ({ organisationId }) => ({
                    url: `/${apiVersion}/organisation/${organisationId}/rate-tables`,
                }),
                providesTags: (result, _error, _arg) =>
                    result
                        ? [
                              { type: 'RateTables', id: 'LIST' },
                              ...(Object.values(result).map(({ rateTableId }) => ({
                                  type: 'RateTables',
                                  id: rateTableId,
                              })) ?? []),
                          ]
                        : [{ type: 'RateTables', id: 'LIST' }],
            }),

            getRateTable: builder.query({
                query: ({ organisationId, rateTableId }) => ({
                    url: `/${apiVersion}/organisation/${organisationId}/rate-tables/${rateTableId}`,
                }),
                providesTags: (result, _error, arg) => [
                    { type: 'RateTables', id: arg.rateTableId },
                    { type: 'Rates', id: 'LIST' },
                    ...(result.rates.map(({ rateId }) => ({ type: 'Rates', id: rateId })) ?? []),
                ],
            }),

            createRateTable: builder.mutation({
                query: ({ organisationId, rateTable }) => ({
                    url: `/organisations/${organisationId}/rate-table`,
                    method: 'PUT',
                    body: rateTable,
                }),
                invalidatesTags: [{ type: 'RateTables', id: 'LIST' }],
            }),

            updateRateTable: builder.mutation({
                query: ({ organisationId, rateTable }) => ({
                    url: `/organisations/${organisationId}/rate-table`,
                    method: 'PUT',
                    body: rateTable,
                }),
                invalidatesTags: (_result, _error, arg) => [{ type: 'RateTables', id: arg.rateTable.rateTableId }],
            }),

            archiveRateTable: builder.mutation({
                query: ({ organisationId, rateTableId }) => ({
                    url: `/organisations/${organisationId}/rate-table/${rateTableId}`,
                    method: 'DELETE',
                }),
                invalidatesTags: (_result, _error, arg) => [
                    { type: 'RateTables', id: 'LIST' },
                    { type: 'RateTables', id: arg.rateTableId },
                ],
            }),

            unarchiveRateTable: builder.mutation({
                query: ({ organisationId, rateTableId }) => ({
                    url: `/organisations/${organisationId}/rate-table/${rateTableId}/unarchive`,
                }),
                invalidatesTags: (result, _error, arg) => [{ type: 'RateTables', id: arg.rateTableId }],
            }),

            duplicateRateTable: builder.mutation({
                query: ({ newRateTableName, organisationId, rateTableId }) => ({
                    url: `/organisations/${organisationId}/rate-table/${rateTableId}/duplicate/${newRateTableName}`,
                    method: 'POST',
                }),
                invalidatesTags: (_result, _error, arg) => [
                    { type: 'RateTables', id: 'LIST' },
                    { type: 'RateTables', id: arg.rateTableId },
                ],
            }),

            validateRateTableName: builder.mutation({
                query: ({ organisationId, requestBody }) => ({
                    url: `/organisations/${organisationId}/rate-table/validate-name`,
                    method: 'POST',
                    body: requestBody,
                }),
            }),
        }),
    })

export const {
    useArchiveRateTableMutation,
    useCreateRateTableMutation,
    useDuplicateRateTableMutation,
    useGetRateTableQuery,
    useGetRateTablesQuery,
    useLazyGetRateTableQuery,
    useLazyGetRateTablesQuery,
    useUnarchiveRateTableMutation,
    useUpdateRateTableMutation,
    useValidateRateTableNameMutation,
} = rateTablesApi
