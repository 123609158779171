import { useRef } from 'react'
import { Controller, useForm } from 'react-hook-form'
import { Trans, useTranslation } from 'react-i18next'
import { useDispatch, useSelector } from 'react-redux'
import { CloudUploadOutlined } from '@mui/icons-material'
import {
    Alert,
    AlertTitle,
    Box,
    Button,
    FormControlLabel,
    IconButton,
    Link,
    Switch,
    TextField,
    Typography,
} from '@mui/material'
import { useTheme } from '@mui/material/styles'
import Grid from '@mui/material/Unstable_Grid2'
import { useSnackbar } from 'notistack'

import {
    useUpdateOrganisationMutation,
    useUploadStoreFaviconMutation,
    useUploadStoreLogoMutation,
} from '@/app/services/organisation'
import { selectCurrentUser } from '@/app/slices/appSlice'
import {
    selectOrganisation,
    selectOrganisationId,
    selectPaidFeatures,
    setOrganisation,
} from '@/app/slices/organisationSlice'
import { DashedBorderContainer, FileUploader, OrganisationLogo, TbxTooltip } from '@/common/components'
import ColourPicker from '@/common/components/ColourPicker/ColourPicker'
import SettingsToolbar from '@/common/components/MainAppBar/SettingsToolbar/SettingsToolbar'
import UpgradePlanLink from '@/common/components/UpgradePlanLink/UpgradePlanLink'

const classes = {
    root: {
        gap: 4,
        display: 'flex',
        flexDirection: 'column',
        marginBottom: 3,
        px: 4,
        py: 3,
    },
    gridContainer: {
        gap: 3,
        paddingBottom: 6,
    },

    gridContentGap: {
        gap: 3,
    },

    gridItemContent: {
        gap: 1.5,
    },

    uploadActionContainer: {
        flexDirection: 'column',
        borderRadius: '8px',
        border: 'dashed 1px',
        borderColor: 'primary.main',
        padding: '16px 8px',
        textAlign: 'center',
        height: '210px',
    },
    dropzoneLabel: {
        color: 'text.primary',
        marginLeft: '6px',
    },
    logoUploadInstructions: {
        color: 'text.secondary',
        marginTop: 1,
        textAlign: 'center',
    },
    logoGridItem: {
        flex: '1 0 auto',
        alignItems: 'stretch',
        width: '170px',
    },
    logoPreviewContainer: {
        width: '100%',
        flexDirection: 'column',
        borderRadius: '8px',
        border: 'dashed 1px',
        borderColor: (theme) => theme.palette.grey[300],
        padding: '16px 8px',
        textAlign: 'center',
    },
    logoPreview: {
        maxWidth: '100%',
        flexShrink: 0,
    },

    shippingOptionSwitch: {
        marginLeft: 0,
        marginBottom: 2,
        gap: 1,
    },
}

const defaultHeadingParagraph = 'Welcome to the store'
const defaultSubheadingParagraph = 'Upload your parts, select the material and get a quote'
const defaultOrderSuccessEmailMessage = `Thank you for your order. It is now pending our review and confirmation.

If we have any questions about your order, we will contact you on your contact number or email address provided.

In the meantime, please contact us if you have any questions.`

const StoreSettings = () => {
    const { t } = useTranslation()
    const { enqueueSnackbar } = useSnackbar()

    const dispatch = useDispatch()
    const theme = useTheme()

    const organisation = useSelector(selectOrganisation)
    const organisationId = useSelector(selectOrganisationId)
    const currentUser = useSelector(selectCurrentUser)

    const paidFeatures = useSelector(selectPaidFeatures)
    const [updateOrganisation] = useUpdateOrganisationMutation()
    const [uploadStoreLogo] = useUploadStoreLogoMutation()
    const [uploadStoreFavicon] = useUploadStoreFaviconMutation()

    const ownerEmail = currentUser?.organisationUsers.find(
        (ou) => ou.organisationId === organisation.organisationId && ou.role === 'Owner'
    )?.emailAddress

    //  TODO: Change these parameters when the backend integrations are ready
    const isStripeSetupCompleted = true

    const webStoreURL = `${window.location.protocol}//${window.location.host}/store/${organisation.organisationId}`

    const {
        control,
        formState: { dirtyFields, isDirty, isSubmitting, isValid },
        getValues,
        handleSubmit,
        reset,
        setValue,
    } = useForm({
        mode: 'all',
        defaultValues: {
            webStoreIsPublic: organisation?.webStoreIsPublic,
            webStoreDisplayName: organisation?.webStoreDisplayName || '',
            webStoreRedirectLink:
                organisation?.webStoreRedirectLink || `${window.location.protocol}//${window.location.host}`,
            webStorePrimaryColour: organisation?.webStorePrimaryColour || theme?.palette?.primary?.main || '',
            webStoreSecondaryColour: organisation?.webStoreSecondaryColour || theme?.palette?.secondary?.main || '',
            webStoreLogo: null,
            webStoreFavicon: null,
            webStoreSalesEmail: organisation?.webStoreSalesEmail || '',
            webStoreSupportEmail: organisation?.webStoreSupportEmail || '',
            webStoreContactNumber: organisation?.webStoreContactNumber || '',
            webStoreOrderSuccessMessage: organisation?.webStoreOrderSuccessMessage || '',
            webStoreHeadingParagraph: organisation?.webStoreHeadingParagraph || '',
            webStoreSubheadingParagraph: organisation?.webStoreSubheadingParagraph || '',
        },
    })

    const onSubmit = async (data) => {
        try {
            const requests = []
            const requestMapping = {
                settings: null,
                logo: null,
                favicon: null,
            }

            requests.push(
                updateOrganisation({
                    organisationDto: {
                        ...organisation,
                        ...data,
                    },
                })
            )

            requestMapping.settings = 0

            if (dirtyFields['webStoreLogo']) {
                requests.push(uploadStoreLogo({ organisationId, file: data.webStoreLogo }))
                requestMapping.logo = 1
            }

            if (dirtyFields['webStoreFavicon']) {
                requests.push(uploadStoreFavicon({ organisationId, file: data.webStoreFavicon }))
                requestMapping.favicon = requestMapping.logo ? 2 : 1
            }

            const responses = await Promise.allSettled(requests)

            dispatch(setOrganisation(responses[requestMapping.settings].value.data))

            reset(data)

            enqueueSnackbar(t('Store settings updated successfully'), { variant: 'success' })
        } catch (error) {
            enqueueSnackbar(t('Failed to update Store settings'), { variant: 'error' })
        }
    }

    const urlPattern =
        /((([A-Za-z]{3,9}:(?:\/\/)?)(?:[-;:&=+$,\w]+@)?[A-Za-z0-9.-]+|(?:www.|[-;:&=+$,\w]+@)[A-Za-z0-9.-]+)((?:\/[+~%/.\w-_]*)?\??(?:[-+=&;%@.\w_]*)#?(?:[\w]*))?)/
    const emailPattern = /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i
    const hexPattern = /^#[0-9A-F]{6}$/i
    const invalidUrlMessage = 'Please enter a valid URL.'
    const invalidEmailMessage = 'Please enter a valid email'
    const invalidColourMessage = 'Please enter a valid HEX code.'

    const handlePreviewWebStoreClick = () => {
        window.open(webStoreURL, '_blank')
    }
    const uploaderRefs = useRef([])

    /* Logo handling section */
    const handleLogoDropzoneAction = (files) => {
        if (files.length !== 1) {
            return
        }
        setValue('webStoreLogo', files[0], { shouldDirty: true })
    }

    const handleSelectLogoButton = () => {
        if (uploaderRefs.current[0]) {
            uploaderRefs.current[0].value = null
            uploaderRefs.current[0].click()
        }
    }

    const handleFaviconDropzoneAction = (files) => {
        if (files.length !== 1) {
            return
        }
        setValue('webStoreFavicon', files[0], { shouldDirty: true })
    }

    const handleSelectFaviconButton = () => {
        if (uploaderRefs.current[1]) {
            uploaderRefs.current[1].value = null
            uploaderRefs.current[1].click()
        }
    }

    return (
        <>
            <SettingsToolbar
                enableCancelButton={isDirty ? !isSubmitting : null}
                enableSaveButton={isDirty && isValid ? !isSubmitting : null}
                handleCancelClick={() => reset()}
                handleSaveClick={handleSubmit(onSubmit)}
                title={t('Settings / Web Store')}
            />
            <Grid
                sx={classes.root}
                container
            >
                <Grid
                    justifyContent="space-between"
                    container
                >
                    <Grid>
                        <Typography variant="h4">{t('Web Store')}</Typography>
                    </Grid>
                    <Grid>
                        <TbxTooltip
                            leaveDelay={1000}
                            title={paidFeatures.hasWebStore ? '' : <UpgradePlanLink />}
                            arrow
                            disableFocusListener
                            disableTouchListener
                        >
                            <span>
                                <Button
                                    color="primary"
                                    data-testid="open-web-store"
                                    disabled={!paidFeatures?.hasWebStore}
                                    variant="contained"
                                    onClick={handlePreviewWebStoreClick}
                                >
                                    {t('Visit Store')}
                                </Button>
                            </span>
                        </TbxTooltip>
                    </Grid>
                </Grid>

                <Grid
                    sx={classes.gridContainer}
                    container
                >
                    <Grid
                        className="urlSection"
                        xs={12}
                    >
                        <Box
                            display="flex"
                            flexDirection="column"
                            sx={classes.gridItemContent}
                        >
                            <Box>
                                <Typography data-testid="url-heading">
                                    <strong>URL</strong>
                                </Typography>
                                <Typography variant="body2">
                                    {t(
                                        'Clicking on this link will take customers to your organisation\'s store. Enable "Allow access to store without login" to allow anyone access to your store.'
                                    )}
                                </Typography>
                            </Box>
                            {paidFeatures?.hasWebStore ? (
                                <Typography>
                                    {t('Unique store URL')}:{' '}
                                    <Link href={webStoreURL}>
                                        <strong>{webStoreURL}</strong>
                                    </Link>
                                </Typography>
                            ) : (
                                <Typography>
                                    <UpgradePlanLink />
                                </Typography>
                            )}

                            {!isStripeSetupCompleted ? (
                                <Box style={{ width: 320 }}>
                                    <Alert severity="info">
                                        <AlertTitle>{t('Payment setup')}</AlertTitle>
                                        <Trans t={t}>
                                            Your <Link href="#">Stripe setup</Link> needs to be completed before your
                                            store can be made public.
                                        </Trans>
                                    </Alert>
                                </Box>
                            ) : null}

                            <Box>
                                <FormControlLabel
                                    control={
                                        <Controller
                                            control={control}
                                            name="webStoreIsPublic"
                                            render={({ field: { onChange, value } }) => (
                                                <Switch
                                                    checked={value}
                                                    color="primary"
                                                    disabled={!isStripeSetupCompleted}
                                                    name="publicURL"
                                                    onChange={onChange}
                                                />
                                            )}
                                        />
                                    }
                                    label={t('Allow access to store without login')}
                                    labelPlacement="start"
                                    sx={classes.shippingOptionSwitch}
                                />
                            </Box>
                        </Box>
                    </Grid>

                    <Grid
                        className="brandingSection"
                        xs={12}
                    >
                        <Box
                            display="flex"
                            flexDirection="column"
                            sx={classes.gridItemContent}
                        >
                            <Typography data-testid="branding-heading">
                                <strong>{t('Branding')}</strong>
                            </Typography>

                            <Grid
                                sx={classes.gridContentGap}
                                container
                            >
                                <Grid xs={12}>
                                    <Controller
                                        control={control}
                                        name="webStoreDisplayName"
                                        render={({ field: { onBlur, onChange, value } }) => (
                                            <TextField
                                                helperText={t(
                                                    'How your business name will appear to customers of your Store.'
                                                )}
                                                InputLabelProps={{ shrink: true }}
                                                label={t('Display name')}
                                                placeholder={organisation.name}
                                                value={value}
                                                fullWidth
                                                onBlur={onBlur}
                                                onChange={onChange}
                                            />
                                        )}
                                    />
                                </Grid>

                                <Grid xs={12}>
                                    <Controller
                                        control={control}
                                        name="webStoreRedirectLink"
                                        render={({ field: { onBlur, onChange, value }, fieldState }) => (
                                            <TextField
                                                error={fieldState.invalid}
                                                helperText={fieldState.error?.message}
                                                label={t('Website redirect link')}
                                                value={value}
                                                fullWidth
                                                required
                                                onBlur={onBlur}
                                                onChange={onChange}
                                            />
                                        )}
                                        rules={{
                                            required: t('Required'),
                                            pattern: {
                                                value: urlPattern,
                                                message: invalidUrlMessage,
                                            },
                                        }}
                                    />
                                </Grid>

                                <Grid xs={5.5}>
                                    <Controller
                                        control={control}
                                        name="webStorePrimaryColour"
                                        render={({ field: { onBlur, onChange, value }, fieldState }) => (
                                            <ColourPicker
                                                error={fieldState.invalid}
                                                helperText={fieldState.error?.message}
                                                label={t('Primary theme colour')}
                                                value={value}
                                                onBlur={onBlur}
                                                onChange={onChange}
                                            />
                                        )}
                                        rules={{
                                            pattern: {
                                                value: hexPattern,
                                                message: invalidColourMessage,
                                            },
                                        }}
                                    />
                                </Grid>

                                <Grid xs={5.5}>
                                    <Controller
                                        control={control}
                                        name="webStoreSecondaryColour"
                                        render={({ field: { onBlur, onChange, value }, fieldState }) => (
                                            <ColourPicker
                                                error={fieldState.invalid}
                                                helperText={fieldState.error?.message}
                                                label={t('Secondary theme colour')}
                                                value={value}
                                                onBlur={onBlur}
                                                onChange={onChange}
                                            />
                                        )}
                                        rules={{
                                            pattern: {
                                                value: hexPattern,
                                                message: invalidColourMessage,
                                            },
                                        }}
                                    />
                                </Grid>

                                <Grid xs={12}>
                                    <Controller
                                        control={control}
                                        name="webStoreHeadingParagraph"
                                        render={({ field: { onBlur, onChange, value }, fieldState }) => (
                                            <TextField
                                                error={fieldState.invalid}
                                                helperText={fieldState.error?.message}
                                                InputLabelProps={{ shrink: true }}
                                                label={t('Home heading paragraph')}
                                                minRows={4}
                                                placeholder={t(defaultHeadingParagraph)}
                                                value={value}
                                                fullWidth
                                                multiline
                                                onBlur={onBlur}
                                                onChange={onChange}
                                            />
                                        )}
                                    />
                                </Grid>

                                <Grid xs={12}>
                                    <Controller
                                        control={control}
                                        name="webStoreSubheadingParagraph"
                                        render={({ field: { onBlur, onChange, value }, fieldState }) => (
                                            <TextField
                                                error={fieldState.invalid}
                                                helperText={fieldState.error?.message}
                                                InputLabelProps={{ shrink: true }}
                                                label={t('Home subheading paragraph')}
                                                minRows={4}
                                                placeholder={t(defaultSubheadingParagraph)}
                                                value={value}
                                                fullWidth
                                                multiline
                                                onBlur={onBlur}
                                                onChange={onChange}
                                            />
                                        )}
                                    />
                                </Grid>
                            </Grid>
                        </Box>
                    </Grid>

                    <Grid
                        className="logoSection"
                        xs={12}
                    >
                        <Box
                            display="flex"
                            flexDirection="column"
                            sx={classes.gridItemContent}
                        >
                            <Typography data-testid="logo-heading">
                                <strong>{t('Logo')}</strong>
                            </Typography>

                            <Box
                                alignItems="stretch"
                                display="flex"
                                justifyContent="space-between"
                                sx={classes.gridItemContent}
                            >
                                <FileUploader
                                    acceptedFileTypes={{
                                        'image/png': ['.png'],
                                        'image/jpeg': ['.jpg', '.jpeg'],
                                    }}
                                    dragActiveText={t('Drag and drop your image here or browse.')}
                                    ref={(el) => (uploaderRefs.current[0] = el)}
                                    singleFileUpload={true}
                                    onFilesAccepted={handleLogoDropzoneAction}
                                >
                                    <DashedBorderContainer sx={classes.uploadActionContainer}>
                                        <IconButton
                                            color="primary"
                                            disableRipple
                                            onClick={handleSelectLogoButton}
                                        >
                                            <CloudUploadOutlined style={{ fontSize: 64 }} />
                                        </IconButton>
                                        <div>
                                            <Typography
                                                sx={classes.dropzoneLabel}
                                                variant="body2"
                                            >
                                                <Trans t={t}>
                                                    Drag and drop your image <strong>here</strong> or browse.
                                                </Trans>
                                            </Typography>
                                        </div>
                                        <Typography
                                            align="center"
                                            sx={classes.logoUploadInstructions}
                                            variant="caption"
                                        >
                                            {t('Support for PNG, JPG and JPEG. Max file size: 1MB')}
                                        </Typography>
                                    </DashedBorderContainer>
                                </FileUploader>

                                <Box sx={classes.logoGridItem}>
                                    <DashedBorderContainer sx={classes.logoPreviewContainer}>
                                        {getValues('webStoreLogo') ? (
                                            <OrganisationLogo
                                                organisationId={organisation.organisationId}
                                                organisationLogoUri={URL.createObjectURL(getValues('webStoreLogo'))}
                                                wrapperHeight={176}
                                                wrapperWidth={1}
                                            />
                                        ) : organisation.webStoreLogoUri ? (
                                            <OrganisationLogo
                                                organisationId={organisation.organisationId}
                                                organisationLogoUri={organisation.webStoreLogoUri}
                                                wrapperHeight={176}
                                                wrapperWidth={1}
                                            />
                                        ) : (
                                            'LOGO'
                                        )}
                                    </DashedBorderContainer>
                                </Box>
                            </Box>
                        </Box>
                    </Grid>

                    <Grid
                        className="logoSection"
                        xs={12}
                    >
                        <Box
                            display="flex"
                            flexDirection="column"
                            sx={classes.gridItemContent}
                        >
                            <Typography data-testid="favicon-heading">
                                <strong>{t('Favicon')}</strong>
                            </Typography>
                            <Typography variant="body2">
                                <Trans t={t}>
                                    Don’t have a favicon? Websites like <Link href="https://favicon.io/">this one</Link>{' '}
                                    can help you generate one.
                                </Trans>
                            </Typography>

                            <Box
                                alignItems="stretch"
                                display="flex"
                                justifyContent="space-between"
                                sx={classes.gridItemContent}
                            >
                                <FileUploader
                                    acceptedFileTypes={{
                                        'image/png': ['.png'],
                                        'image/jpeg': ['.jpg', '.jpeg'],
                                    }}
                                    dragActiveText={t('Drag and drop your image here or browse.')}
                                    ref={(el) => (uploaderRefs.current[1] = el)}
                                    singleFileUpload={true}
                                    onFilesAccepted={handleFaviconDropzoneAction}
                                >
                                    <DashedBorderContainer sx={classes.uploadActionContainer}>
                                        <IconButton
                                            color="primary"
                                            disableRipple
                                            onClick={handleSelectFaviconButton}
                                        >
                                            <CloudUploadOutlined style={{ fontSize: 64 }} />
                                        </IconButton>
                                        <div>
                                            <Typography
                                                sx={classes.dropzoneLabel}
                                                variant="body2"
                                            >
                                                <Trans t={t}>
                                                    Drag and drop your image <strong>here</strong> or browse.
                                                </Trans>
                                            </Typography>
                                        </div>
                                        <Typography
                                            align="center"
                                            sx={classes.logoUploadInstructions}
                                            variant="caption"
                                        >
                                            <Trans t={t}>Upload a square favicon. Support for ICO, PNG.</Trans>
                                        </Typography>
                                    </DashedBorderContainer>
                                </FileUploader>

                                <Box sx={classes.logoGridItem}>
                                    <DashedBorderContainer sx={classes.logoPreviewContainer}>
                                        {getValues('webStoreFavicon') ? (
                                            <OrganisationLogo
                                                organisationId={organisation.organisationId}
                                                organisationLogoUri={URL.createObjectURL(getValues('webStoreFavicon'))}
                                                wrapperHeight={176}
                                                wrapperWidth={1}
                                            />
                                        ) : organisation.webStoreLogoUri ? (
                                            <OrganisationLogo
                                                organisationId={organisation.organisationId}
                                                organisationLogoUri={organisation.webStoreFaviconUri}
                                                wrapperHeight={176}
                                                wrapperWidth={1}
                                            />
                                        ) : (
                                            'LOGO'
                                        )}
                                    </DashedBorderContainer>
                                </Box>
                            </Box>
                        </Box>
                    </Grid>

                    <Grid
                        className="communicationSection"
                        xs={12}
                    >
                        <Box
                            display="flex"
                            flexDirection="column"
                            sx={classes.gridItemContent}
                        >
                            <Typography data-testid="communication-heading">
                                <strong>{t('Communication')}</strong>
                            </Typography>

                            <Grid
                                sx={classes.gridContentGap}
                                container
                            >
                                <Grid xs={12}>
                                    <Controller
                                        control={control}
                                        name="webStoreSalesEmail"
                                        render={({ field: { onBlur, onChange, value }, fieldState }) => (
                                            <TextField
                                                error={fieldState.invalid}
                                                helperText={fieldState.error?.message}
                                                InputLabelProps={{ shrink: true }}
                                                label={t('Sales notification email')}
                                                placeholder={ownerEmail}
                                                value={value}
                                                fullWidth
                                                onBlur={onBlur}
                                                onChange={onChange}
                                            />
                                        )}
                                        rules={{
                                            pattern: {
                                                value: emailPattern,
                                                message: t(invalidEmailMessage),
                                            },
                                        }}
                                    />
                                </Grid>
                                <Grid xs={12}>
                                    <Controller
                                        control={control}
                                        name="webStoreSupportEmail"
                                        render={({ field: { onBlur, onChange, value }, fieldState }) => (
                                            <TextField
                                                error={fieldState.invalid}
                                                helperText={fieldState.error?.message}
                                                InputLabelProps={{ shrink: true }}
                                                label={t('Support email')}
                                                placeholder={ownerEmail}
                                                value={value}
                                                fullWidth
                                                required
                                                onBlur={onBlur}
                                                onChange={onChange}
                                            />
                                        )}
                                        rules={{
                                            required: t('Required'),
                                            pattern: {
                                                value: emailPattern,
                                                message: t(invalidEmailMessage),
                                            },
                                        }}
                                    />
                                </Grid>
                                <Grid xs={12}>
                                    <Controller
                                        control={control}
                                        name="webStoreContactNumber"
                                        render={({ field: { onBlur, onChange, value }, fieldState }) => (
                                            <TextField
                                                error={fieldState.invalid}
                                                helperText={fieldState.error?.message}
                                                label={t('Support contact number')}
                                                value={value}
                                                fullWidth
                                                onBlur={onBlur}
                                                onChange={onChange}
                                            />
                                        )}
                                    />
                                </Grid>
                                <Grid xs={12}>
                                    <Controller
                                        control={control}
                                        name="webStoreOrderSuccessMessage"
                                        render={({ field: { onBlur, onChange, value }, fieldState }) => (
                                            <TextField
                                                error={fieldState.invalid}
                                                helperText={fieldState.error?.message}
                                                InputLabelProps={{ shrink: true }}
                                                label={t('Order receipt message')}
                                                minRows={4}
                                                placeholder={t(defaultOrderSuccessEmailMessage)}
                                                value={value}
                                                fullWidth
                                                multiline
                                                onBlur={onBlur}
                                                onChange={onChange}
                                            />
                                        )}
                                    />
                                </Grid>
                            </Grid>
                        </Box>
                    </Grid>
                </Grid>
            </Grid>
        </>
    )
}

export default StoreSettings
