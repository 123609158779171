import { useEffect, useMemo } from 'react'
import { useForm } from 'react-hook-form'
import { useTranslation } from 'react-i18next'
import { useDispatch, useSelector } from 'react-redux'
import { Box, Typography } from '@mui/material'
import Grid from '@mui/material/Unstable_Grid2'
import { useSnackbar } from 'notistack'

import { useUpdateOrganisationMutation } from '@/app/services/organisation'
import { selectOrganisation, setOrganisation } from '@/app/slices/organisationSlice'
import SettingsToolbar from '@/common/components/MainAppBar/SettingsToolbar/SettingsToolbar'
import { useToolBoxTreatments } from '@/common/hooks'

import DeliveryPricingModel from './DeliveryPricingModel/DeliveryPricingModel'
import ShippingDefaults from './ShippingDefaults'
import WebStoreOptions from './WebStoreOptions'

const classes = {
    container: {
        width: '100%',
        height: '100%',
        display: 'flex',
        flexDirection: 'column',
        padding: 2,
        boxSizing: 'border-box',
    },
    section: {
        my: 3,
    },
    centeredContent: {
        height: '100%',
        alignItems: 'center',
        display: 'flex',
        justifyContent: 'center',
    },
    shippingOptionSwitch: {
        marginLeft: 0,
        marginBottom: 2,
        gap: 1,
    },
}

const ShippingSettings = () => {
    const { t } = useTranslation()
    const { enqueueSnackbar } = useSnackbar()

    const dispatch = useDispatch()

    const organisation = useSelector(selectOrganisation)

    const { showShipping, showWebStore } = useToolBoxTreatments()

    const [updateOrganisation, { isError: isUpdatingOrgError, isLoading }] = useUpdateOrganisationMutation()

    const getDefaultValues = useMemo(
        () => ({
            pickupAddress: organisation?.pickupAddress || '',
            deliveryPricingOption: organisation?.deliveryPricingOption || 'Flat',
            internalDefaultDeliveryMethod: organisation?.internalDefaultDeliveryMethod || 'PayUponDelivery',
            internalPreferredDeliveryProvider: organisation?.internalPreferredDeliveryProvider || '',
            webStorePickupEnabled: organisation?.webStorePickupEnabled ?? true,
            webStoreDeliveryEnabled: organisation?.webStoreDeliveryEnabled || '',
            webStoreDeliveryRegions: organisation?.webStoreDeliveryRegions,
            webStoreDefaultDeliveryMethod: organisation?.webStoreDefaultDeliveryMethod || 'SeparateCharge',
            webStorePreferredDeliveryProvider: organisation?.webStorePreferredDeliveryProvider || '',
            webStoreCustomerDeliveryProviderOption: organisation?.webStoreCustomerDeliveryProviderOption || false,
            minimumDeliveryCharge: organisation?.minimumDeliveryCharge,
        }),
        [organisation]
    )

    const {
        control,
        formState: { isDirty, isSubmitting, isValid },
        handleSubmit,
        reset,
        watch,
    } = useForm({
        mode: 'all',
        defaultValues: getDefaultValues,
    })

    const updateOrganisationSettings = async (organisationData) => {
        const pickupAddress =
            typeof organisationData.pickupAddress === 'string'
                ? organisationData.pickupAddress
                : organisationData.pickupAddress?.description || ''

        const webStoreDeliveryRegions = organisationData.webStoreDeliveryEnabled
            ? organisationData.webStoreDeliveryRegions.map((item) => {
                  if ('region' in item) return item
                  return { region: item.description, placeId: item.place_id }
              })
            : []
        try {
            const updatedOrganisation = await updateOrganisation({
                organisationDto: {
                    ...organisation,
                    ...organisationData,
                    pickupAddress: pickupAddress,
                    webStoreDeliveryRegions,
                },
            }).unwrap()

            dispatch(setOrganisation(updatedOrganisation))

            enqueueSnackbar(t('Shipping settings updated'), { variant: 'success' })
        } catch (error) {
            if (isUpdatingOrgError) {
                reset(getDefaultValues)
                dispatch(setOrganisation(organisation))
            }
            enqueueSnackbar(t('An error occurred updating your shipping settings'), { variant: 'error' })
        }
    }

    useEffect(() => {
        reset(getDefaultValues)
    }, [organisation])

    return (
        <>
            <SettingsToolbar
                enableCancelButton={isDirty ? !isSubmitting : null}
                enableSaveButton={isDirty && !isSubmitting ? isValid : null}
                handleCancelClick={() => reset()}
                handleSaveClick={handleSubmit(updateOrganisationSettings)}
                isLoading={isLoading}
                title={t('Settings / Shipping')}
            />
            <Box sx={classes.container}>
                <Box sx={classes.section}>
                    <Grid
                        paddingInline={2}
                        spacing={4}
                        container
                    >
                        <Grid xs={12}>
                            <Typography
                                data-testid="shipping-settings-title"
                                sx={classes.title}
                                variant="h4"
                            >
                                {t('Shipping')}
                            </Typography>
                        </Grid>

                        {/* DEFAULTS */}
                        <ShippingDefaults form={{ control, watch }} />
                        {/* END_DEFAULTS */}

                        {/* WEB_STORE_OPTIONS */}
                        {showWebStore ? <WebStoreOptions form={{ control, watch }} /> : null}
                        {/* END_WEB_STORE_OPTIONS */}

                        {/* DELIVERY_PRICING_MODEL */}
                        {showShipping ? <DeliveryPricingModel form={{ control }} /> : null}
                        {/* END_DELIVERY_PRICING_MODEL */}
                    </Grid>
                </Box>
            </Box>
        </>
    )
}

export default ShippingSettings
