import { useDispatch } from 'react-redux'
import { Navigate, Route, Routes } from 'react-router-dom'

import { setLanguageList } from '@/app/slices/appSlice'
import { GenericErrorPage, NotFoundPage, SignOut } from '@/common/components'
import UserRequiredContainer from '@/common/components/UserRequiredContainer/UserRequiredContainer'
import { Paths } from '@/common/utils'
import { Register } from '@/features/account/views'
import { Administration } from '@/features/administration/views'
import CustomerCentral from '@/features/customer-central/views/CustomerCentral'
import Documents from '@/features/documents/views/Documents'
import RedirectToDocument from '@/features/documents/views/RedirectToDocument'
import Materials from '@/features/materials/views/Materials'
import PaymentSuccess from '@/features/payments/PaymentSuccess'
import Process from '@/features/processes/views/Process'
import Quote from '@/features/quotes/views/Quote'
import QuotesDashboard from '@/features/quotes/views/QuotesDashboard'
import RedirectToQuote from '@/features/quotes/views/RedirectToQuote'
import RateTables from '@/features/rate-tables/views/RateTables'
import AccountSettings from '@/features/settings/components/AccountSettings/AccountSettings'
import OrganisationSettings from '@/features/settings/components/OrganisationSettings/OrganisationSettings'
import ShippingSettings from '@/features/settings/components/ShippingSettings/ShippingSettings'
import StoreSettings from '@/features/settings/components/StoreSettings/StoreSettings'
import { RedirectToPricingRequest } from '@/features/settings/components/SubscriptionSettings/components/RedirectToPricingRequest'
import NoActiveSubscription from '@/features/settings/components/SubscriptionSettings/views/NoActiveSubscription'
import SubscriptionSettings from '@/features/settings/components/SubscriptionSettings/views/SubscriptionSettings'
import Settings from '@/features/settings/views/Settings'
import UserWelcome from '@/features/user-welcome/UserWelcome'
import WebStore from '@/features/web-store'
import i18n from '@/i18n'

import './App.css'

const App = () => {
    const dispatch = useDispatch()
    i18n.services.backendConnector.backend.getLanguages((err, result) => {
        if (!err) {
            dispatch(setLanguageList(result))
        }
    })

    return (
        <Routes>
            <Route
                element={<SignOut />}
                path={Paths.SIGNOUT_PATHNAME}
            />
            <Route
                element={<GenericErrorPage />}
                path={Paths.ERROR_PATHNAME}
            />
            <Route
                element={<Register />}
                path={Paths.REGISTER_PATHNAME}
            />
            <Route
                element={<Documents isPublicDocument={true} />}
                path={`${Paths.SHARED_V2_PATHNAME}/:quoteId/:documentType/:action?`}
            />
            <Route
                element={<NotFoundPage />}
                path={Paths.NOT_FOUND}
            />
            <Route
                element={<WebStore />}
                path={Paths.WEB_STORE}
            />

            <Route
                element={<PaymentSuccess />}
                path={`${Paths.PAYMENTS_PATHNAME}/:quoteId/${Paths.PAYMENTS_SUCCESS_PATHNAME}`}
            />

            {/* Protected routes */}
            <Route
                element={<UserRequiredContainer />}
                path="/"
            >
                <Route
                    element={<UserWelcome />}
                    path={Paths.WELCOME_PATHNAME}
                />
                <Route path="/activate">
                    <Route
                        element={<RedirectToPricingRequest />}
                        index
                    />
                    <Route
                        element={<RedirectToPricingRequest />}
                        path="billing-details"
                    />
                    <Route
                        element={<RedirectToPricingRequest />}
                        path="payment-details"
                    />
                </Route>

                <Route
                    element={
                        <Navigate
                            to="/settings/subscription"
                            replace
                        />
                    }
                    path="/subscription"
                />

                <Route
                    element={<NoActiveSubscription />}
                    path="/no-subscription"
                />

                <Route
                    element={<Process />}
                    path="/process-configuration"
                />
                <Route
                    element={<RateTables />}
                    path={Paths.RATE_TABLES}
                />
                <Route
                    element={<CustomerCentral />}
                    path={Paths.CUSTOMER_CENTRAL_PATHNAME}
                />
                <Route
                    element={<Materials />}
                    path={Paths.MATERIALS_MANAGEMENT}
                />
                <Route
                    element={<Quote />}
                    path={Paths.QUOTE_PATHNAME}
                />
                <Route
                    element={<RedirectToQuote />}
                    path="/projects/:quoteId"
                />

                <Route
                    element={<QuotesDashboard />}
                    path={Paths.DASHBOARD_PATHNAME}
                />

                <Route
                    element={
                        <Navigate
                            to={Paths.DASHBOARD_PATHNAME}
                            replace
                        />
                    }
                    path="/projects"
                />

                <Route
                    element={<Documents />}
                    path={Paths.DOCUMENT_PATHNAME}
                />

                <Route
                    element={<RedirectToDocument />}
                    path="/projects/:quoteId/documents/:documentType/:action?"
                />

                <Route
                    element={<Settings />}
                    path="/settings"
                >
                    <Route
                        element={
                            <Navigate
                                to="/settings/account"
                                replace
                            />
                        }
                        index
                    />
                    <Route
                        element={<AccountSettings />}
                        path="account"
                    />
                    <Route
                        element={<OrganisationSettings />}
                        path="organisation"
                    />
                    <Route
                        element={<ShippingSettings />}
                        path="shipping"
                    />
                    <Route
                        element={<StoreSettings />}
                        path="store"
                    />
                    <Route
                        element={<SubscriptionSettings />}
                        path="subscription"
                    />
                </Route>

                <Route
                    element={<Administration />}
                    path="administration"
                />
            </Route>

            <Route
                element={<NotFoundPage />}
                path="*"
            />
        </Routes>
    )
}

export default App
