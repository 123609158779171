import _ from 'lodash'

import CustomError from '../Utilities/CustomError'

import * as EntityConstants from './EntityConstants'
import Layer from './Layer'

export default class Line {
    constructor(json) {
        this['$type'] = json['$type']
        this.startPoint = undefined
        this.endPoint = undefined
        this.layer = undefined
        this.isLineTypeSolid = undefined
        this.type = undefined
        this.toSvg = undefined

        const jsonValid =
            json &&
            Object.prototype.hasOwnProperty.call(json, 'layer') &&
            Object.prototype.hasOwnProperty.call(json, 'isLineTypeSolid') &&
            Object.prototype.hasOwnProperty.call(json, 'type') &&
            Object.prototype.hasOwnProperty.call(json, 'startPoint') &&
            Object.prototype.hasOwnProperty.call(json, 'endPoint')

        if (jsonValid) {
            _.extend(this, json)
            this.layer = new Layer(json.layer)
        } else {
            throw new CustomError('Invalid json supplied', 'InvalidJSONError')
        }
    }

    getSvgPathStr(lastPt) {
        let pathStr = ''
        const lastPtMeetsStartPoint =
            lastPt &&
            Math.abs(lastPt.x - this.startPoint.x) < EntityConstants.POINT_COMPARISON_PRECISION &&
            Math.abs(lastPt.y - this.startPoint.y) < EntityConstants.POINT_COMPARISON_PRECISION

        if (!lastPt || !lastPtMeetsStartPoint) {
            pathStr += !lastPtMeetsStartPoint ? ' ' : ''
            pathStr += 'M' + this.startPoint.x + ' ' + this.startPoint.y
        }
        pathStr += ' L' + this.endPoint.x + ' ' + this.endPoint.y
        return pathStr
    }
}

Line.ToolpathType = 'DtoLine'
