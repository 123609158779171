import { useEffect } from 'react'
import { useTranslation } from 'react-i18next'
import { useSelector } from 'react-redux'
import { Box, Button, Typography } from '@mui/material'
import { padding, width } from '@mui/system'

import { useCreateCustomerPortalSessionMutation } from '@/app/services/integrations'
import { selectHasActiveSubscription, selectIsOnFreePlan, selectOrganisation } from '@/app/slices/organisationSlice'
import { SubscriptionToolbar } from '@/common/components/MainAppBar'
import RoleRequiredContainer from '@/common/components/RoleRequiredContainer/RoleRequiredContainer'

import FreePlanCard from '../components/FreePlanCard'
import NoActiveSubscriptionCard from '../components/NoActiveSubscriptionCard'

const classes = {
    container: {
        width: '100%',
        display: 'flex',
        flexDirection: 'column',
        padding: 2,
        boxSizing: 'border-box',
    },
    section: {
        my: 3,
    },
    centeredContent: {
        alignItems: 'center',
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'center',
        gap: 3,
        paddingBlock: 4,
        paddingInline: 10,
    },
}

const SubscriptionSettings = () => {
    const { t } = useTranslation()

    const organisation = useSelector(selectOrganisation)
    const isOnFreePlan = useSelector(selectIsOnFreePlan)
    const hasActiveSubscription = useSelector(selectHasActiveSubscription)

    const [createCustomerPortalSessionRequest] = useCreateCustomerPortalSessionMutation()

    const redirectToStripe = async () => {
        try {
            const portalSession = await createCustomerPortalSessionRequest({
                organisationId: organisation.organisationId,
                requestModel: {
                    returnUrl: `${window.location.origin}/settings`,
                },
            }).unwrap()

            portalSession.url && window.location.replace(portalSession.url)
        } catch (error) {
            console.error('Error creating customer portal session', error)
        }
    }

    return (
        <>
            <SubscriptionToolbar />
            <RoleRequiredContainer role="owner">
                <Box sx={classes.container}>
                    {hasActiveSubscription && !isOnFreePlan ? (
                        <Box sx={classes.centeredContent}>
                            <Typography
                                textAlign="center"
                                variant="body1"
                            >
                                {t(
                                    'You have an active subscription. Manage your subscription in the Stripe customer portal.'
                                )}
                            </Typography>
                            <Button
                                color="primary"
                                sx={{ ml: 2 }}
                                variant="contained"
                                onClick={redirectToStripe}
                            >
                                {t('Open Stripe Customer Portal')}
                            </Button>
                        </Box>
                    ) : null}

                    {!hasActiveSubscription && isOnFreePlan ? (
                        <Box sx={classes.section}>
                            <FreePlanCard />
                        </Box>
                    ) : null}

                    {!hasActiveSubscription && !isOnFreePlan ? <NoActiveSubscriptionCard /> : null}
                </Box>
            </RoleRequiredContainer>
        </>
    )
}

export default SubscriptionSettings
