const DEFAULT_CURRENCY = 'USD'
const DEFAULT_CURRENCY_DISPLAY = 'narrowSymbol'
const DEFAULT_LOCALE = 'en-US'
const DEFAULT_DECIMAL_PLACES = 2

export function inchesToMm(value) {
    return value * 25.4
}

export function mmToInches(value) {
    return value / 25.4
}

export function percentageToFraction(percentage, decimalPlaces = 4) {
    return percentage != null ? parseFloat((percentage / 100).toFixed(decimalPlaces)) : undefined
}

export function fractionToPercentage(fraction, decimalPlaces = 4) {
    return fraction != null ? parseFloat((fraction * 100).toFixed(decimalPlaces)) : undefined
}

export function formatNumber(value, locale = DEFAULT_LOCALE, useImperialUnits = null) {
    let decimalPlaces = DEFAULT_DECIMAL_PLACES

    if (useImperialUnits != null) {
        decimalPlaces = useImperialUnits ? 4 : 2
    }

    const formatter = new Intl.NumberFormat(locale, {
        style: 'decimal',
        unitDisplay: 'long',
        signDisplay: 'auto',
        useGrouping: true,
        minimumFractionDigits: decimalPlaces,
        maximumFractionDigits: decimalPlaces,
    })
    return formatter.format(value)
}

export function formatCurrency(
    value,
    currency = DEFAULT_CURRENCY,
    locale = DEFAULT_LOCALE,
    decimalPlaces = DEFAULT_DECIMAL_PLACES,
    currencyDisplay = DEFAULT_CURRENCY_DISPLAY
) {
    const formatter = new Intl.NumberFormat(locale, {
        style: 'currency',
        currency: currency,
        currencyDisplay: currencyDisplay,
        unitDisplay: 'long',
        signDisplay: 'auto',
        useGrouping: true,
        minimumFractionDigits: decimalPlaces,
        maximumFractionDigits: decimalPlaces,
    })
    return formatter.format(value)
}
