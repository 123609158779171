import { api } from './api'

//const apiVersion = 'v2'

export const secondaryProcessesApi = api
    .enhanceEndpoints({
        addTagTypes: ['SecondaryProcesses'],
    })
    .injectEndpoints({
        endpoints: (builder) => ({
            getSecondaryProcesses: builder.query({
                query: ({ organisationId, requestParams }) => ({
                    url: `/organisations/${organisationId}/secondary-processes`,
                    params: requestParams,
                }),
                providesTags: (result, _error, _arg) =>
                    result
                        ? [
                              { type: 'SecondaryProcesses', id: 'LIST' },
                              ...result.map(({ secondaryProcessId }) => ({
                                  type: 'SecondaryProcesses',
                                  id: secondaryProcessId,
                              })),
                          ]
                        : [{ type: 'SecondaryProcesses', id: 'LIST' }],
                transformResponse: (response) => {
                    const sorted = response?.sort((a, b) => {
                        if (a.index === b.index) {
                            return a.isDeleted ? -1 : 1
                        }
                        return a.index - b.index
                    })
                    return sorted
                },
            }),

            getSecondaryProcess: builder.query({
                query: ({ organisationId, requestParams, secondaryProcessId }) => ({
                    url: `/organisations/${organisationId}/secondary-processes/${secondaryProcessId}`,
                    params: requestParams,
                }),
                providesTags: (_result, _error, arg) => [{ type: 'SecondaryProcesses', id: arg.secondaryProcessId }],
            }),

            createSecondaryProcess: builder.mutation({
                query: ({ organisationId, secondaryProcess }) => ({
                    url: `/organisations/${organisationId}/secondary-processes`,
                    method: 'POST',
                    body: secondaryProcess,
                }),
                invalidatesTags: [{ type: 'SecondaryProcesses', id: 'LIST' }],
            }),

            updateSecondaryProcess: builder.mutation({
                query: ({ organisationId, secondaryProcess }) => ({
                    url: `/organisations/${organisationId}/secondary-processes`,
                    method: 'PUT',
                    body: secondaryProcess,
                }),
                invalidatesTags: (_result, _error, arg) => [
                    { type: 'SecondaryProcesses', id: 'LIST' },
                    { type: 'SecondaryProcesses', id: arg.secondaryProcess.secondaryProcessId },
                ],
            }),

            deleteSecondaryProcess: builder.mutation({
                query: ({ organisationId, secondaryProcessId }) => ({
                    url: `/organisations/${organisationId}/secondary-processes/${secondaryProcessId}`,
                    method: 'DELETE',
                }),
                invalidatesTags: (_result, _error, arg) => [
                    { type: 'SecondaryProcesses', id: 'LIST' },
                    { type: 'SecondaryProcesses', id: arg.secondaryProcessId },
                ],
            }),

            sortSecondaryProcesses: builder.mutation({
                query: ({ items, organisationId, showArchived, sortParams }) => ({
                    url: `/organisations/${organisationId}/secondary-processes/sort`,
                    method: 'POST',
                    body: sortParams,
                }),
                async onQueryStarted({ items, organisationId, showArchived }, { dispatch, queryFulfilled }) {
                    const patchResult = dispatch(
                        secondaryProcessesApi.util.updateQueryData(
                            'getSecondaryProcesses',
                            { organisationId, requestParams: { includeDeleted: showArchived } },
                            (draft) => {
                                Object.assign(draft, items)
                            }
                        )
                    )
                    try {
                        await queryFulfilled
                    } catch {
                        patchResult.undo()
                    }
                },
            }),
        }),
    })

export const {
    useCreateSecondaryProcessMutation,
    useDeleteSecondaryProcessMutation,
    useGetSecondaryProcessQuery,
    useGetSecondaryProcessesQuery,
    useLazyGetSecondaryProcessesQuery,
    useSortSecondaryProcessesMutation,
    useUpdateSecondaryProcessMutation,
} = secondaryProcessesApi
