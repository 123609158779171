import { forwardRef, useCallback } from 'react'
import { useDropzone } from 'react-dropzone'
import { useTranslation } from 'react-i18next'
import { Box } from '@mui/material'
import Typography from '@mui/material/Typography'
import PropTypes from 'prop-types'

const classes = {
    dragContainer: {
        position: 'fixed',
        top: 0,
        left: 0,
        right: 0,
        bottom: 0,
        backgroundColor: 'hsla(0, 0%, 0%, 0.5)',
        width: '100%',
        height: '100%',
        zIndex: 20,
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        justifyContent: 'center',
    },
    dragInstruction: {
        color: 'background.paper',
    },
    uploaderContainer: {
        width: '100%',
        height: '100%',
    },
}

const FileUploader = forwardRef(
    (
        {
            acceptedFileTypes,
            children,
            dragActiveText,
            maxSize,
            onFilesAccepted,
            onFilesRejected,
            singleFileUpload = false,
        },
        ref
    ) => {
        const { t } = useTranslation()

        // Dropzone configuration
        const onDropAccepted = useCallback(
            (acceptedFiles) => {
                if (typeof onFilesAccepted === 'function') {
                    onFilesAccepted(acceptedFiles)
                }
            },
            [onFilesAccepted]
        )

        const onDropRejected = useCallback(
            (acceptedFiles) => {
                if (typeof onFilesRejected === 'function') {
                    onFilesRejected(acceptedFiles)
                }
            },
            [onFilesRejected]
        )

        const { getInputProps, getRootProps, isDragActive } = useDropzone({
            onDropAccepted,
            onDropRejected,
            accept: acceptedFileTypes,
            noDragEventsBubbling: true,
            noClick: true,
            noKeyboard: true,
            multiple: !singleFileUpload,
            maxSize,
        })

        return (
            <Box
                sx={classes.uploaderContainer}
                {...getRootProps()}
            >
                <input
                    {...getInputProps()}
                    ref={ref}
                />
                {isDragActive ? (
                    <Box sx={classes.dragContainer}>
                        <Typography
                            sx={classes.dragInstruction}
                            variant="body1"
                        >
                            {dragActiveText ?? t('Drop files here')}
                        </Typography>
                        {maxSize ? (
                            <Typography
                                sx={classes.dragInstruction}
                                variant="body1"
                            >
                                {t('Maximum file size {{maxSize}} MB', {
                                    maxSize: Math.floor(maxSize / 1e6),
                                })}
                            </Typography>
                        ) : null}
                    </Box>
                ) : null}
                {children}
            </Box>
        )
    }
)

FileUploader.displayName = 'FileUploader'

FileUploader.propTypes = {
    acceptedFileTypes: PropTypes.oneOfType([PropTypes.array, PropTypes.object]),
    children: PropTypes.node,
    dragActiveText: PropTypes.string,
    maxSize: PropTypes.number,
    singleFileUpload: PropTypes.bool,
    onFilesAccepted: PropTypes.func,
    onFilesRejected: PropTypes.func,
}

export default FileUploader
