import { useCallback, useEffect, useMemo, useRef, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useSelector } from 'react-redux'
import {
    Box,
    Button,
    FormControl,
    InputLabel,
    MenuItem,
    Select,
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableHead,
    TablePagination,
    TableRow,
} from '@mui/material'
import Grid from '@mui/material/Unstable_Grid2'
import { useSnackbar } from 'notistack'
import useLocalStorage from 'use-local-storage'

import { useGetDeliveryContainersQuery } from '@/app/services/deliveryContainers'
import { useGetDeliveryZonesQuery, useUpdateDeliveryZoneMutation } from '@/app/services/deliveryZones'
import { selectOrganisation, selectOrganisationId, selectUseImperialUnits } from '@/app/slices/organisationSlice'
import { AlertDialog, TbxShowToggle } from '@/common/components'
import { AddIcon } from '@/common/icons'
import {
    ZONE_CONTAINERS_PER_PAGE_DEFAULT_VALUE,
    ZONE_CONTAINERS_PER_PAGE_OPTIONS,
    ZONE_CONTAINERS_PER_PAGE_VARIABLE,
} from '@/common/utils'

import AssignContainerDialog from './AssignContainerDialog'
import DeliveryZoneContainerRow from './DeliveryZoneContainerRow'

const classes = {
    tableContainer: {
        background: (theme) => theme.palette.background.paper,
        border: (theme) => `1px solid ${theme.palette.grey[400]}`,
        boxSizing: 'border-box',
        borderRadius: 2,
    },
    table: {
        tableLayout: 'fixed',
        boxSizing: 'border-box',
        borderCollapse: 'separate',
    },
    headerTableCell: {
        paddingBlock: 2,
        paddingInline: 2,
        verticalAlign: 'bottom',
    },
    tableRow: {
        '&:hover': {
            background: (theme) => theme.palette.action.hover,
        },
    },
    tableCell: {
        paddingBlock: 2,
        paddingInline: 2,
    },
    tableCellWithEllipsis: {
        overflow: 'hidden',
        whiteSpace: 'nowrap',
        textOverflow: 'ellipsis',
    },
    tableFooter: {
        display: 'flex',
        alignItems: 'center',
        flexDirection: 'row-reverse',
    },
    iconColor: (active = true) => ({
        color: active ? 'secondary.main' : 'text.disabled',
    }),
    activeRate: (active = true) => ({
        color: active ? 'text.primary' : 'text.disabled',
    }),

    zoneTitle: {
        fontWeight: 500,
        color: 'text.primary',
        textTransform: 'uppercase',
    },
    zoneBorderLeft: {
        borderLeft: '2px dashed #0078A4;',
    },
    zoneBorderRight: {
        borderRight: '2px dashed #0078A4;',
    },
}

const DeliveryPrices = () => {
    const { t } = useTranslation()
    const { enqueueSnackbar } = useSnackbar()

    const organisation = useSelector(selectOrganisation)
    const organisationId = useSelector(selectOrganisationId)
    const isImperial = useSelector(selectUseImperialUnits)

    const { data: deliveryZones, isLoading: isLoadingZones } = useGetDeliveryZonesQuery({
        organisationId,
    })
    const { data: deliveryContainers, isLoading: isLoadingContainers } = useGetDeliveryContainersQuery({
        organisationId,
    })

    const [updateZone, { isLoading }] = useUpdateDeliveryZoneMutation()

    const [showAssignContainerDialog, setShowAssignContainerDialog] = useState(false)
    const [showArchivedZoneContainers, setShowArchivedZoneContainers] = useState(false)
    const [showArchiveZoneContainerDialog, setShowArchiveZoneContainerDialog] = useState(false)
    const [showUnarchiveZoneContainerDialog, setShowUnarchiveZoneContainerDialog] = useState(false)

    const [selectedZoneId, setSelectedZoneId] = useState('')
    const [selectedZone, setSelectedZone] = useState(null)

    const [currentPage, setCurrentPage] = useState(0)

    const [currentRowsPerPage, setCurrentRowsPerPage] = useLocalStorage(
        ZONE_CONTAINERS_PER_PAGE_VARIABLE,
        ZONE_CONTAINERS_PER_PAGE_DEFAULT_VALUE
    )

    const zoneContainerRef = useRef(null)
    const containerRef = useRef(null)

    const deliveryPricingOption = organisation?.deliveryPricingOption

    const handleChangePage = (_, newPage) => {
        setCurrentPage(newPage)
    }

    const handleChangeRowsPerPage = (event) => {
        const newRowsPerPage = parseInt(event.target.value, 10)
        setCurrentRowsPerPage(newRowsPerPage)
        setCurrentPage(0)
    }

    const zoneContainers = useMemo(() => {
        return deliveryZones
            ?.find((zone) => zone.zoneId === selectedZoneId)
            ?.zoneContainers.filter((zc) => {
                const container = deliveryContainers?.find((c) => c.containerId === zc.containerId) ?? null

                if (!container) {
                    return false
                }

                return showArchivedZoneContainers ? true : zc.isEnabled
            })

            ?.sort((a, b) => {
                if (a.flatPrice === b.flatPrice) {
                    if (deliveryPricingOption === 'ByDistance') {
                        return a.pricePerDistance - b.pricePerDistance
                    }
                    if (deliveryPricingOption === 'ByWeight') {
                        return a.pricePerWeight - b.pricePerWeight
                    }
                }
                return a.flatPrice - b.flatPrice
            })
    }, [deliveryZones, selectedZoneId, showArchivedZoneContainers, deliveryPricingOption, deliveryContainers])

    const handleZoneChange = useCallback(
        (event) => {
            const zone = deliveryZones.find((zone) => zone.zoneId === event.target.value)
            setSelectedZoneId(zone.zoneId)
            setSelectedZone(zone)
        },
        [deliveryZones]
    )

    const handleEditZoneContainerButtonClick = (zoneContainer) => {
        zoneContainerRef.current = zoneContainer
        setShowAssignContainerDialog(true)
    }

    const handleShowArchivedZonesContainersToggle = () => {
        setShowArchivedZoneContainers((prev) => !prev)
    }

    const handleCloseAssignContainerDialog = () => {
        zoneContainerRef.current = null
        setShowAssignContainerDialog(false)
    }

    const handleArchiveContainerButtonClick = (container) => {
        zoneContainerRef.current = container
        containerRef.current = deliveryContainers.find((c) => c.containerId === container.containerId)
        setShowArchiveZoneContainerDialog(true)
    }

    const handleUnarchiveContainerButtonClick = (container) => {
        zoneContainerRef.current = container
        containerRef.current = deliveryContainers.find((c) => c.containerId === container.containerId)
        setShowUnarchiveZoneContainerDialog(true)
    }

    const handleCancelDialog = () => {
        zoneContainerRef.current = null
        containerRef.current = null
        setShowArchiveZoneContainerDialog(false)
        setShowUnarchiveZoneContainerDialog(false)
    }

    const handleDialogConfirmation = useCallback(
        async (action) => {
            let zoneContainer
            let message

            if (action === 'enable') {
                zoneContainer = { ...zoneContainerRef.current, isEnabled: true }
                message = t('Container has been enabled')
            } else {
                zoneContainer = { ...zoneContainerRef.current, isEnabled: false }
                message = t('Container has been disabled')
            }
            try {
                await updateZone({
                    organisationId,
                    zone: { ...selectedZone, zoneContainers: [...zoneContainers, zoneContainer] },
                }).unwrap()
                enqueueSnackbar(t(message), { variant: 'success' })
            } catch (error) {
                console.error(error)
                enqueueSnackbar(t(message), { variant: 'error' })
            } finally {
                handleCancelDialog()
            }
        },
        [enqueueSnackbar, organisationId, selectedZone, zoneContainers, updateZone]
    )

    useEffect(() => {
        if (!selectedZoneId && !isLoadingZones && deliveryZones?.length) {
            setSelectedZoneId(deliveryZones[0].zoneId)
            setSelectedZone(deliveryZones[0])
        }
    }, [selectedZoneId, isLoadingZones, deliveryZones])

    return (
        <>
            <Grid xs={12}>
                <Box
                    alignItems="center"
                    display="flex"
                    justifyContent="space-between"
                >
                    <FormControl
                        color="primary"
                        size="small"
                        sx={{ minWidth: 250 }}
                        variant="outlined"
                    >
                        <InputLabel
                            id="select-zone-label"
                            shrink
                        >
                            {t('Zone')}
                        </InputLabel>
                        <Select
                            id="select-zone"
                            label={t('Zone')}
                            labelId="select-zone-label"
                            value={selectedZoneId}
                            displayEmpty
                            onBlur={handleZoneChange}
                            onChange={handleZoneChange}
                        >
                            <MenuItem
                                value=""
                                disabled
                            >
                                <em>{t('Select a zone')}</em>
                            </MenuItem>
                            {deliveryZones?.map((zone) => (
                                <MenuItem
                                    key={zone.zoneId}
                                    value={zone.zoneId}
                                >
                                    {zone.name}
                                </MenuItem>
                            ))}
                        </Select>
                    </FormControl>

                    <Box
                        mr={3}
                        width="fit-content"
                    >
                        <TbxShowToggle
                            checked={showArchivedZoneContainers}
                            data-testid="show-archived-rates-toggle"
                            label={t('Show disabled containers')}
                            onChange={() => handleShowArchivedZonesContainersToggle()}
                        />
                    </Box>

                    <Button
                        color="secondary"
                        data-testid="add-delivery-zone-button"
                        disabled={!selectedZoneId}
                        size="small"
                        startIcon={
                            <AddIcon
                                size={18}
                                type="sharp"
                                variant="solid"
                            />
                        }
                        variant="outlined"
                        onClick={() => setShowAssignContainerDialog(true)}
                    >
                        {t('Assign container')}
                    </Button>
                </Box>
            </Grid>
            {!isLoadingZones ? (
                <Grid xs={12}>
                    <TableContainer sx={classes.tableContainer}>
                        <Table sx={classes.table}>
                            <colgroup>
                                <col style={{ width: '150px' }} />
                                <col style={{ width: '150px' }} />
                                {deliveryPricingOption !== 'Flat' ? <col style={{ width: '150px' }} /> : null}
                                <col style={{ width: '100px' }} />
                            </colgroup>

                            <TableHead>
                                <TableRow>
                                    <TableCell sx={classes.headerTableCell}>{t('Container')}</TableCell>
                                    {deliveryPricingOption === 'Flat' ? (
                                        <TableCell
                                            align="right"
                                            sx={classes.headerTableCell}
                                        >
                                            {t("Zone's flat price")}
                                        </TableCell>
                                    ) : (
                                        <>
                                            <TableCell
                                                align="right"
                                                sx={classes.headerTableCell}
                                            >
                                                {t("Zone's flat price")}
                                            </TableCell>
                                            {deliveryPricingOption === 'ByDistance' ? (
                                                <TableCell
                                                    align="right"
                                                    sx={classes.headerTableCell}
                                                >
                                                    {isImperial
                                                        ? t("Zone's per mile rate")
                                                        : t("Zone's per kilometer rate")}
                                                </TableCell>
                                            ) : (
                                                <TableCell
                                                    align="right"
                                                    sx={classes.headerTableCell}
                                                >
                                                    {isImperial
                                                        ? t("Zone's per pound rate")
                                                        : t("Zone's per kilogram rate")}
                                                </TableCell>
                                            )}
                                        </>
                                    )}

                                    <TableCell></TableCell>
                                </TableRow>
                            </TableHead>
                            <TableBody>
                                {zoneContainers?.length ? (
                                    zoneContainers
                                        ?.slice(
                                            currentPage * currentRowsPerPage,
                                            currentPage * currentRowsPerPage + currentRowsPerPage
                                        )
                                        ?.map((zoneContainer) => {
                                            return (
                                                <DeliveryZoneContainerRow
                                                    handleArchiveButtonClick={handleArchiveContainerButtonClick}
                                                    handleEditButtonClick={handleEditZoneContainerButtonClick}
                                                    handleUnarchiveButtonClick={handleUnarchiveContainerButtonClick}
                                                    key={zoneContainer.zoneContainerId}
                                                    zoneContainer={zoneContainer}
                                                />
                                            )
                                        })
                                ) : (
                                    <TableRow>
                                        <TableCell
                                            align="center"
                                            colSpan={deliveryPricingOption === 'Flat' ? 3 : 4}
                                            sx={classes.tableCell}
                                        >
                                            {t('Please assign containers to this zone')}
                                        </TableCell>
                                    </TableRow>
                                )}
                            </TableBody>
                        </Table>

                        <TablePagination
                            component="div"
                            count={zoneContainers?.length ?? 0}
                            labelDisplayedRows={({ count, from, to }) => `${from}-${to} of ${count}`}
                            labelRowsPerPage={t('Containers per page')}
                            page={currentPage}
                            rowsPerPage={currentRowsPerPage}
                            rowsPerPageOptions={ZONE_CONTAINERS_PER_PAGE_OPTIONS}
                            sx={classes.tableFooter}
                            onPageChange={handleChangePage}
                            onRowsPerPageChange={handleChangeRowsPerPage}
                        />
                    </TableContainer>
                </Grid>
            ) : null}

            {showAssignContainerDialog ? (
                <AssignContainerDialog
                    open={showAssignContainerDialog}
                    zone={selectedZone}
                    zoneContainer={zoneContainerRef.current}
                    zoneContainers={zoneContainers}
                    onClose={handleCloseAssignContainerDialog}
                />
            ) : null}

            {showArchiveZoneContainerDialog ? (
                <AlertDialog
                    content={t('Are you sure want to disable {{containerName}} container for {{zoneName}} zone?', {
                        zoneName: selectedZone.name,
                        containerName: containerRef.current?.name,
                    })}
                    okButtonText={t('Yes, disable')}
                    open={showArchiveZoneContainerDialog}
                    title={t('Disable container for zone')}
                    onCancelClose={() => handleCancelDialog()}
                    onOkClose={() => handleDialogConfirmation('disable')}
                />
            ) : null}

            {showUnarchiveZoneContainerDialog ? (
                <AlertDialog
                    content={t('Are you sure want to enable {{containerName}} container for {{zoneName}} zone?', {
                        zoneName: selectedZone.name,
                        containerName: containerRef.current?.name,
                    })}
                    okButtonText={t('Yes, enable')}
                    open={showUnarchiveZoneContainerDialog}
                    title={t('Enable container for zone')}
                    onCancelClose={() => handleCancelDialog()}
                    onOkClose={() => handleDialogConfirmation('enable')}
                />
            ) : null}
        </>
    )
}

DeliveryPrices.propTypes = {}

export default DeliveryPrices
