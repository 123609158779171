import { Box } from '@mui/material'
import PropTypes from 'prop-types'

import { EXPORTABLE_CONTENT, PRIVATE_DOCUMENTS } from '@/common/utils'

import DeliveryDocket from './Quote/DeliveryDocket'
import ProductionLabels from './Quote/ProductionLabels'
import WorkOrder from './Quote/WorkOrder'
import WorkOrderSummary from './Quote/WorkOrderSummary'

const PrivateDocuments = ({
    customer,
    customerContact,
    documentType,
    issueSeverityDictionary,
    organisation,
    quote,
    showShipping,
}) => {
    return (
        <Box id={EXPORTABLE_CONTENT}>
            {documentType === PRIVATE_DOCUMENTS.ProductionLabels ? (
                <ProductionLabels
                    customer={customer}
                    issueSeverityDictionary={issueSeverityDictionary}
                    organisation={organisation}
                    quote={quote}
                    showShipping={showShipping}
                />
            ) : null}

            {documentType === PRIVATE_DOCUMENTS.DeliveryDocket ? (
                <DeliveryDocket
                    customer={customer}
                    customerContact={customerContact}
                    issueSeverityDictionary={issueSeverityDictionary}
                    organisation={organisation}
                    quote={quote}
                    showShipping={showShipping}
                />
            ) : null}

            {documentType === PRIVATE_DOCUMENTS.WorkOrder ? (
                <WorkOrder
                    customer={customer}
                    customerContact={customerContact}
                    issueSeverityDictionary={issueSeverityDictionary}
                    organisation={organisation}
                    quote={quote}
                    showShipping={showShipping}
                />
            ) : null}

            {documentType === PRIVATE_DOCUMENTS.WorkOrderSummary ? (
                <WorkOrderSummary
                    customer={customer}
                    customerContact={customerContact}
                    organisation={organisation}
                    quote={quote}
                    showShipping={showShipping}
                />
            ) : null}
        </Box>
    )
}

PrivateDocuments.propTypes = {
    customer: PropTypes.object,
    customerContact: PropTypes.object,
    documentType: PropTypes.string,
    issueSeverityDictionary: PropTypes.object,
    organisation: PropTypes.object,
    quote: PropTypes.object,
    showshipping: PropTypes.bool
}

export default PrivateDocuments
