import { useEffect, useMemo, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useSelector } from 'react-redux'
import { useParams } from 'react-router-dom'
import { Add, Create, Gradient } from '@mui/icons-material'
import { Box, Checkbox, IconButton, Typography } from '@mui/material'
import PropTypes from 'prop-types'

import { selectQuoteItems } from '@/app/services/quoteItems'
import { useGetSecondaryProcessesQuery } from '@/app/services/secondaryProcesses'
import { selectOrganisationId } from '@/app/slices/organisationSlice'
import { selectSelectedItems } from '@/app/slices/quoteItemsSlice'

import QuantitySecProcess from '../ItemContent/SecondaryProcesses/QuantitySecProcess'

import SecondaryProcessesBulkEditModal from './SecondaryProcessesBulkEditModal'

const classes = {
    secProcessesSection: {
        width: '100%',
        gap: '12px',
    },
    bold: {
        fontWeight: 'bold',
    },
    formControl_small: {
        width: '80px',
    },
}

const ItemRow = ({ children }) => (
    <Box
        alignItems="center"
        display="flex"
        flexDirection="row"
        justifyContent="space-between"
        width="100%"
    >
        {children}
    </Box>
)

const SecondaryProcessesBulkEdit = ({
    itemSecondaryProcesses,
    setItemSecondaryProcesses,
    setUserMadeSecProcessesChanges,
}) => {
    const { t } = useTranslation()
    const { quoteId } = useParams()

    const organisationId = useSelector(selectOrganisationId)
    const quoteItems = useSelector((state) => selectQuoteItems(state, { organisationId, quoteId }))
    const selectedItemsArray = useSelector(selectSelectedItems)

    const quoteItemsFromSelection = useMemo(
        () => quoteItems.filter((item) => selectedItemsArray.includes(item.id)),
        [quoteItems, selectedItemsArray]
    )

    const partSecProcs = useMemo(
        () => quoteItemsFromSelection.map((part) => part.secondaryProcesses).reduce((a, b) => [...a, ...b], []),
        [quoteItemsFromSelection]
    )

    const { data: organisationSecondaryProcesses } = useGetSecondaryProcessesQuery({ organisationId })

    const [isEditDialogOpen, setIsEditDialogOpen] = useState(false)

    const getProcessDetails = (processId) =>
        organisationSecondaryProcesses?.find((osp) => osp.secondaryProcessId === processId)

    const sortedProcesses = useMemo(() => {
        return itemSecondaryProcesses
            ?.map((process) => ({
                ...process,
                index: getProcessDetails(process.secondaryProcessId)?.index,
            }))
            .sort((a, b) => (a.index < b.index ? -1 : 1))
    }, [itemSecondaryProcesses])

    const handleEditButtonClick = (event) => {
        event.stopPropagation()
        setIsEditDialogOpen(true)
    }

    const onCloseEditDialog = (processes) => {
        setIsEditDialogOpen(false)
        if (processes) {
            setUserMadeSecProcessesChanges(true)
            setItemSecondaryProcesses(processes)
        }
    }

    const onQuantityInputChange = (process) => {
        const processIds = itemSecondaryProcesses?.map((sp) => sp.secondaryProcessId)
        const processIndex = processIds.indexOf(process.secondaryProcessId)
        const newProcessList = [...itemSecondaryProcesses]
        newProcessList[processIndex] = process
        setItemSecondaryProcesses(newProcessList)
    }

    useEffect(() => {
        const partSecProcsIds = [
            // @ts-ignore
            ...new Set(
                quoteItemsFromSelection
                    .map((part) => part.secondaryProcesses)
                    .reduce((a, b) => [...a, ...b], [])
                    .map((proc) => proc.secondaryProcessId)
            ),
        ]

        const newSecondaryProcessList = organisationSecondaryProcesses.filter((sp) =>
            partSecProcsIds.includes(sp.secondaryProcessId)
        )

        const secondaryProcs = newSecondaryProcessList?.map((secProc) => {
            const { measureType, name, secondaryProcessId } = secProc
            let input = ''
            let enabled = false

            if (partSecProcs && partSecProcs.length > 0) {
                // Evaluate how many quoteItems have this process enabled
                const partsWithThisProcess = partSecProcs.filter(
                    (proc) => proc.secondaryProcessId === secProc.secondaryProcessId
                )

                const inAllParts = partsWithThisProcess.length === quoteItemsFromSelection.length
                const notInParts = partsWithThisProcess.length === 0
                const inSomeParts = !inAllParts && partsWithThisProcess.length > 0

                if (inAllParts) {
                    enabled = true
                } else if (inSomeParts) {
                    enabled = null
                } else if (notInParts) {
                    enabled = false
                }

                if (measureType === 'Quantity') {
                    const allInputsEqual = partsWithThisProcess.every(
                        (proc) => proc.input === partsWithThisProcess[0].input
                    )
                    if (inAllParts && allInputsEqual) {
                        input = partsWithThisProcess[0]?.input
                    }
                }
            }
            return {
                secondaryProcessId,
                input,
                isEnabled: enabled,
                name,
                measureType,
            }
        })

        setItemSecondaryProcesses(secondaryProcs)
    }, [quoteItemsFromSelection, organisationSecondaryProcesses])

    return (
        <Box
            alignItems="flex-start"
            display="flex"
            flex="1"
            flexDirection="column"
            sx={classes.secProcessesSection}
        >
            <ItemRow>
                <Box
                    alignItems="center"
                    display="flex"
                    flexDirection="row"
                    gap={1}
                >
                    <Gradient fontSize="small" />
                    <Typography
                        data-testid="bulk-edit-secondary-processes-section-title"
                        sx={classes.bold}
                    >
                        {t('Secondary processes')}
                    </Typography>
                </Box>
                <IconButton
                    aria-label="edit"
                    color="primary"
                    data-testid="nqp-secondary-processes-add-button"
                    size="small"
                    onClick={(event) => handleEditButtonClick(event)}
                    onFocus={(event) => event.stopPropagation()}
                >
                    {itemSecondaryProcesses?.length > 0 ? <Create /> : <Add />}
                </IconButton>
            </ItemRow>
            {sortedProcesses?.map((process) => (
                <ItemRow key={process.secondaryProcessId}>
                    <Box
                        alignItems="center"
                        display="flex"
                        flexDirection="row"
                    >
                        <Checkbox
                            checked={process.isEnabled}
                            color="secondary"
                            data-testid="bulk-edit-secondary-process-option-checkbox"
                            indeterminate={process.isEnabled === null}
                            size="small"
                            sx={{ p: 0, mr: 1 }}
                            value={process.secondaryProcessId}
                        />
                        <Typography
                            data-testid="bulk-edit-secondary-process-label"
                            variant="body2"
                        >
                            {getProcessDetails(process.secondaryProcessId)?.name}
                        </Typography>
                    </Box>
                    {getProcessDetails(process.secondaryProcessId)?.measureType === 'Quantity' ? (
                        <QuantitySecProcess
                            process={process}
                            onInputChange={onQuantityInputChange}
                        />
                    ) : null}
                </ItemRow>
            ))}
            {isEditDialogOpen ? (
                <SecondaryProcessesBulkEditModal
                    open={isEditDialogOpen}
                    secondaryProcesses={itemSecondaryProcesses}
                    onClose={onCloseEditDialog}
                />
            ) : null}
        </Box>
    )
}

SecondaryProcessesBulkEdit.propTypes = {
    itemSecondaryProcesses: PropTypes.array,
    setItemSecondaryProcesses: PropTypes.func,
    setUserMadeSecProcessesChanges: PropTypes.func,
}

export default SecondaryProcessesBulkEdit
