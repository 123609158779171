export default class NetworkError {
    constructor(type, message, response) {
        this.type = type
        this.message = message
        this.response = response
    }

    isCancelledRequest = () => {
        return this.type === NetworkErrorType.cancel
    }

    isNotFound = () => {
        if (!this.response) {
            return false
        }

        return this.response.status === 404
    }

    isUnauthorized = () => {
        if (!this.response) {
            return false
        }

        return this.response.status === 401
    }
}

export const NetworkErrorType = {
    general: 'General Network Error',
    timeout: 'Network Timeout',
    cancel: 'Request cancelled',
}
