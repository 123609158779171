import { Navigate, useParams } from 'react-router-dom'

const RedirectToDocument = () => {
    const { action, documentType, quoteId } = useParams()
    return (
        <Navigate
            to={`/quotes/${quoteId}/documents/${documentType}/${action ? action : ''}`}
            replace
        />
    )
}

export default RedirectToDocument
