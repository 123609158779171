import { useCallback, useEffect, useMemo, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useSelector } from 'react-redux'
import { useNavigate } from 'react-router-dom'
import { Box, FormControl, InputLabel, MenuItem, Select, Typography } from '@mui/material'
import { DesktopDatePicker } from '@mui/x-date-pickers'
import dayjs from 'dayjs'
import { useSnackbar } from 'notistack'
import useLocalStorage from 'use-local-storage'

import { useLazyGetOrganisationQuery } from '@/app/services/organisation'
import {
    useCalculateQuoteMutation,
    useCreateQuoteMutation,
    useDeleteQuoteMutation,
    useDuplicateQuoteMutation,
    useGetQuotesQuery,
    useUnarchiveQuoteMutation,
} from '@/app/services/quotes'
import { selectOrganisationId } from '@/app/slices/organisationSlice'
import { SearchTextField, TbxShowToggle } from '@/common/components'
import { QuotesDashboardToolbar } from '@/common/components/MainAppBar'
import TbxLocalizationProvider from '@/common/components/TbxLocalizationProvider/TbxLocalizationProvider'
import {
    PARTS_PER_PAGE_DEFAULT_VALUE,
    PARTS_PER_PAGE_VARIABLE,
    QUOTE_PAGE_STATUS_LABEL,
    QUOTE_STATUS_ORDER,
} from '@/common/utils'

import NoProjectsPanel from '../components/NoProjectsPanel/NoProjectsPanel'
import QuotesDashboardTable from '../components/QuotesDashboard/QuotesDashboardTable'

const classes = {
    errorMessage: {
        color: 'error.main',
        marginTop: '24px',
    },
    formControl: {
        minWidth: 200,
    },
}

const QuotesDashboard = () => {
    const { t } = useTranslation()
    const { enqueueSnackbar } = useSnackbar()
    const navigate = useNavigate()

    const organisationId = useSelector(selectOrganisationId)

    const [getOrganisation] = useLazyGetOrganisationQuery()

    const [quoteStatusFilter, setQuoteStatusFilter] = useState('All')
    const [quoteArchivedFilter, setQuoteArchivedFilter] = useState(false)
    const [lastModifiedByFilter, setLastModifiedByFilter] = useState('All')
    const [lastModifiedFromFilter, setLastModifiedFromFilter] = useState(undefined)
    const [lastModifiedToFilter, setLastModifiedToFilter] = useState(undefined)
    const [sourceFilter, setSourceFilter] = useState('All')

    const userTimeZone = dayjs.tz.guess()

    const [searchTerm, setSearchTerm] = useState('')

    const [currentPage, setCurrentPage] = useState(0)
    const [currentRowsPerPage, setCurrentRowsPerPage] = useLocalStorage(
        PARTS_PER_PAGE_VARIABLE,
        PARTS_PER_PAGE_DEFAULT_VALUE
    )
    const [orderBy, setOrderBy] = useState('lastModifiedDateUtc')
    const [order, setOrder] = useState('desc')

    const { data, isError, isLoading, refetch } = useGetQuotesQuery({
        organisationId,
        params: {
            pageNumber: currentPage,
            pageSize: currentRowsPerPage,
            searchTerm: searchTerm,
            orderedByProperty: orderBy,
            orderedBy: order,
            includeArchived: quoteArchivedFilter,
            status: quoteStatusFilter,
            lastModifiedBy: lastModifiedByFilter,
            lastModifiedFrom: lastModifiedFromFilter ? dayjs.utc(lastModifiedFromFilter).format() : undefined,
            lastModifiedTo: lastModifiedToFilter ? dayjs.utc(lastModifiedToFilter).format() : undefined,
            source: sourceFilter,
        },
    })

    const [deleteQuote] = useDeleteQuoteMutation()
    const [unarchiveQuote] = useUnarchiveQuoteMutation()
    const [duplicateQuote] = useDuplicateQuoteMutation()
    const [calculateQuote] = useCalculateQuoteMutation()
    const [createQuote, { isLoading: isCreatingQuote }] = useCreateQuoteMutation()

    const quotes = data?.quotes
    const totalQuoteCount = data?.count ?? 0
    const availableStatuses = data?.availableStatuses ?? []
    const availableLastModifiedByUsers = data?.availableLastModifiedByUsers ?? []
    const availableSources = useMemo(
        () => [
            { key: 'Internal', value: 'Internal' },
            { key: 'Web Store', value: 'WebStore' },
        ],
        []
    )

    const cleanupStatuses = useMemo(() => {
        const mappedStatuses = availableStatuses
            .map((status) => ({
                label: QUOTE_PAGE_STATUS_LABEL[status],
                value: status === 'NotCalculated' || status === 'Calculated' ? 'Editing' : status,
                index: QUOTE_STATUS_ORDER.find((sorted) => sorted.status === QUOTE_PAGE_STATUS_LABEL[status]).index,
            }))
            .sort((a, b) => a.index - b.index)

        const removeDuplicates = (array, key) => {
            const seen = new Set()
            return array.filter((item) => {
                const keyValue = item[key]
                if (seen.has(keyValue)) {
                    return false
                } else {
                    seen.add(keyValue)
                    return true
                }
            })
        }

        return removeDuplicates(mappedStatuses, 'value')
    }, [availableStatuses])

    const handleQuoteArchiveClicked = async (id) => {
        try {
            await deleteQuote({ organisationId, quoteId: id }).unwrap()
        } catch (error) {
            enqueueSnackbar(t('An error occurred archiving your quote'), { variant: 'error' })
        }
    }

    const handleQuoteUnarchiveClicked = async (id) => {
        try {
            await unarchiveQuote({ organisationId, quoteId: id }).unwrap()
        } catch (error) {
            enqueueSnackbar(t('An error occurred unarchiving your quote'), { variant: 'error' })
        }
    }

    const handleQuoteDuplicateClicked = async (id, newName) => {
        try {
            const response = await duplicateQuote({ organisationId, quoteId: id, newName: newName }).unwrap()
            await calculateQuote({ organisationId, quoteId: response.id })
        } catch (error) {
            enqueueSnackbar(t('An error occurred duplicating your quote'), { variant: 'error' })
        }
    }

    const handleCreateQuoteClicked = async () => {
        try {
            const createdQuote = await createQuote({ organisationId }).unwrap()

            navigate(`/quotes/${createdQuote.id}`)
        } catch (error) {
            enqueueSnackbar(t('An error occurred creating your quote'), { variant: 'error' })
        }
    }

    const handleSearchChange = (value) => {
        setSearchTerm(value)
        setCurrentPage(0)
        refetch()
    }

    const handleQuoteStatusFilter = (event) => {
        setQuoteStatusFilter(event.target.value)
        setCurrentPage(0)
        refetch()
    }

    const handleLastModifiedByFilter = (event) => {
        setLastModifiedByFilter(event.target.value)
        setCurrentPage(0)
        refetch()
    }

    const handleQuoteArchivedFilter = (event) => {
        setQuoteArchivedFilter(event.target.checked)
        setCurrentPage(0)
        refetch()
    }

    const handleLastModifiedFromFilter = (date) => {
        setLastModifiedFromFilter(date?.utc()?.format() || null)
        setCurrentPage(0)
        refetch()
    }

    const handleLastModifiedToFilter = (date) => {
        setLastModifiedToFilter(date?.utc()?.format() || null)
        setCurrentPage(0)
        refetch()
    }

    const handleSourceFilter = (event) => {
        setSourceFilter(event.target.value)
        setCurrentPage(0)
        refetch()
    }

    const userHasNoQuotes = !isLoading && (!quotes || quotes.length === 0)

    const handlePageOrderChanged = (currentPage, currentRowsPerPage, order, orderBy) => {
        setCurrentPage(currentPage)
        setCurrentRowsPerPage(currentRowsPerPage)
        setOrderBy(orderBy)
        setOrder(order)
        refetch()
    }

    useEffect(() => {
        refetch()
        getOrganisation({ organisationId })
    }, [])

    return (
        <>
            <QuotesDashboardToolbar
                handleCreateQuoteClick={handleCreateQuoteClicked}
                isLoading={isCreatingQuote}
            />

            {isError ? (
                <Typography
                    sx={classes.errorMessage}
                    variant="body1"
                >
                    {t('$t(An error occurred) loading your $t(quotes)')}
                </Typography>
            ) : (
                <Box
                    display="flex"
                    flexDirection="column"
                    id="page-container"
                    maxWidth={1720}
                    p={3}
                    width={1}
                >
                    <Box
                        alignItems="center"
                        display="flex"
                        justifyContent="space-between"
                        mb={2}
                    >
                        <Box
                            display="flex"
                            gap={2}
                        >
                            <SearchTextField
                                debounceInput={true}
                                label={t('Search')}
                                value={searchTerm}
                                onChange={handleSearchChange}
                            />

                            <FormControl
                                sx={classes.formControl}
                                variant="outlined"
                            >
                                <InputLabel id="filter-by-status-label">{t('Filter by status')}</InputLabel>
                                <Select
                                    id="filter-by-status"
                                    label={t('Filter by status')}
                                    labelId="filter-by-status-label"
                                    size="small"
                                    value={quoteStatusFilter}
                                    onChange={handleQuoteStatusFilter}
                                >
                                    <MenuItem value="All">{t('All')}</MenuItem>
                                    {cleanupStatuses.map(({ label, value }) => (
                                        <MenuItem
                                            key={value}
                                            value={value}
                                        >
                                            {t(label)}
                                        </MenuItem>
                                    ))}
                                </Select>
                            </FormControl>

                            <FormControl
                                sx={classes.formControl}
                                variant="outlined"
                            >
                                <InputLabel id="filter-by-last-modified-by-label">{t('Last modified by')}</InputLabel>
                                <Select
                                    id="filter-by-last-modified-by"
                                    label={t('Last modified by')}
                                    labelId="filter-by-last-modified-by-label"
                                    size="small"
                                    value={lastModifiedByFilter}
                                    onChange={handleLastModifiedByFilter}
                                >
                                    <MenuItem value="All">{t('All')}</MenuItem>
                                    {Object.keys(availableLastModifiedByUsers).map((key) => (
                                        <MenuItem
                                            key={availableLastModifiedByUsers[key]}
                                            value={availableLastModifiedByUsers[key]}
                                        >
                                            {t(availableLastModifiedByUsers[key])}
                                        </MenuItem>
                                    ))}
                                </Select>
                            </FormControl>
                            <FormControl
                                sx={classes.formControl}
                                variant="outlined"
                            >
                                <InputLabel id="filter-by-source-label">{t('Source')}</InputLabel>
                                <Select
                                    id="filter-by-source"
                                    label={t('Filter by source')}
                                    labelId="filter-by-source-label"
                                    size="small"
                                    value={sourceFilter}
                                    onChange={handleSourceFilter}
                                >
                                    <MenuItem value="All">{t('All')}</MenuItem>
                                    {availableSources.map((source) => (
                                        <MenuItem
                                            key={source.key}
                                            value={source.value}
                                        >
                                            {t(source.key)}
                                        </MenuItem>
                                    ))}
                                </Select>
                            </FormControl>
                            <TbxLocalizationProvider>
                                <DesktopDatePicker
                                    closeOnSelect={false}
                                    disableFuture={true}
                                    format="DD-MMM-YYYY"
                                    label={t('Last modified from')}
                                    slotProps={{
                                        textField: {
                                            size: 'small',
                                            variant: 'outlined',
                                            InputLabelProps: {
                                                shrink: true,
                                            },
                                        },
                                        field: {
                                            readOnly: true,
                                        },
                                        actionBar: {
                                            actions: ['clear', 'cancel', 'accept'],
                                            disableSpacing: true,
                                        },
                                    }}
                                    sx={classes.textFieldDate}
                                    timezone={userTimeZone}
                                    value={lastModifiedFromFilter ? dayjs(lastModifiedFromFilter) : null}
                                    onAccept={handleLastModifiedFromFilter}
                                />

                                <DesktopDatePicker
                                    closeOnSelect={false}
                                    disableFuture={true}
                                    format="DD-MMM-YYYY"
                                    label={t('Last modified until')}
                                    minDate={lastModifiedFromFilter ? dayjs(lastModifiedFromFilter) : null}
                                    slotProps={{
                                        textField: {
                                            size: 'small',
                                            variant: 'outlined',
                                            InputLabelProps: {
                                                shrink: true,
                                            },
                                        },
                                        field: {
                                            readOnly: true,
                                        },
                                        actionBar: {
                                            actions: ['clear', 'cancel', 'accept'],
                                            disableSpacing: true,
                                        },
                                    }}
                                    sx={classes.textFieldDate}
                                    timezone={userTimeZone}
                                    value={lastModifiedToFilter ? dayjs(lastModifiedToFilter) : null}
                                    onAccept={handleLastModifiedToFilter}
                                />
                            </TbxLocalizationProvider>
                        </Box>

                        <TbxShowToggle
                            checked={quoteArchivedFilter}
                            label={t('Show archived $t(quotes)')}
                            onChange={handleQuoteArchivedFilter}
                        />
                    </Box>

                    {userHasNoQuotes ? (
                        <NoProjectsPanel />
                    ) : (
                        <QuotesDashboardTable
                            currentPage={currentPage}
                            filteredQuotes={quotes}
                            handlePageOrderChanged={handlePageOrderChanged}
                            handleQuoteDuplicateClicked={handleQuoteDuplicateClicked}
                            isLoading={isLoading}
                            order={order}
                            orderBy={orderBy}
                            totalQuoteCount={totalQuoteCount}
                            onArchiveQuoteClick={handleQuoteArchiveClicked}
                            onUnarchiveQuoteClick={handleQuoteUnarchiveClicked}
                        />
                    )}
                </Box>
            )}
        </>
    )
}

export default QuotesDashboard
