import { useTranslation } from 'react-i18next'
import { useSelector } from 'react-redux'
import { useSortable } from '@dnd-kit/sortable'
import { CSS } from '@dnd-kit/utilities'
import { Archive, Create, DragHandle, Unarchive } from '@mui/icons-material'
import { Box, IconButton, Switch, TableCell, TableRow } from '@mui/material'
import { useSnackbar } from 'notistack'
import PropTypes from 'prop-types'

import { useUpdateSecondaryProcessMutation } from '@/app/services/secondaryProcesses'
import { selectOrganisation, selectOrganisationId } from '@/app/slices/organisationSlice'
import { TbxTooltip } from '@/common/components'
import { useCurrencyFormatter } from '@/common/hooks'

const classes = {
    cell: {
        padding: '12px',
    },

    archived: {
        color: 'text.disabled',
    },
    button: {
        margin: '0px 4px',
        color: 'text.secondary',
        '&:hover': {
            color: 'text.primary',
        },
    },
    selectionCheckbox: {
        paddingTop: 0,
        paddingBottom: 0,
    },
}

const SecondaryProcessesRow = ({
    archiveProcessClick,
    component,
    editProcessClick,
    id,
    isImperial,
    isOnFreePlan,
    process,
    secondaryProcessCount,
}) => {
    const { t } = useTranslation()
    const { enqueueSnackbar } = useSnackbar()
    const organisation = useSelector(selectOrganisation)
    const organisationId = useSelector(selectOrganisationId)

    const [updateSecondaryProcess] = useUpdateSecondaryProcessMutation()

    const { attributes, isDragging, listeners, setActivatorNodeRef, setNodeRef, transform, transition } = useSortable({
        id: id,
    })

    const style = {
        transform: CSS.Transform.toString(transform),
        transition,
        opacity: isDragging ? 0.5 : undefined,
        zIndex: isDragging ? 0 : undefined,
    }

    const areaUnits = isImperial ? 'ft2' : 'm2'

    const weightUnits = isImperial ? 'lb' : 'kg'

    const measureTypes = [
        {
            name: t('Rectangular area - 1 side no edges ({{areaUnits}})', {
                areaUnits: areaUnits,
            }),
            value: 'RectangularArea1Side',
        },
        {
            name: t('Surface area - 1 side no edges ({{areaUnits}})', {
                areaUnits: areaUnits,
            }),
            value: 'SurfaceArea1Side',
        },
        {
            name: t('Surface area - 2 sides plus edges ({{areaUnits}})', {
                areaUnits: areaUnits,
            }),
            value: 'SurfaceArea2Sides',
        },
        {
            name: t('Net part weight ({{weightUnits}})', {
                weightUnits: weightUnits,
            }),
            value: 'Weight',
        },
        { name: t('Quantity'), value: 'Quantity' },
    ]

    const setupMode = [
        { name: t('Per Line'), value: 'ByLine' },
        { name: t('Per Process'), value: 'ByProcess' },
    ]

    const { formatCurrency } = useCurrencyFormatter({
        currencyCode: organisation.currencyCode,
    })

    const handleEditClick = () => editProcessClick(process)

    const handleArchiveClick = () => archiveProcessClick(process)

    const updateProcessVisibility = async () => {
        process.customerVisible = !process.customerVisible
        try {
            await updateSecondaryProcess({ organisationId, secondaryProcess: process })

            enqueueSnackbar(t('Secondary process updated'), { variant: 'success' })
        } catch (error) {
            enqueueSnackbar(t(error.response?.data?.title || 'An error occurred'), { variant: 'error' })
            process.customerVisible = !process.customerVisible
        }
    }

    return (
        <TableRow
            component={component}
            hover={!process?.isDeleted}
            ref={setNodeRef}
            style={style}
            {...attributes}
        >
            <TableCell
                align="left"
                sx={[classes.cell, process?.isDeleted && classes.archived]}
            >
                <Box
                    alignItems="center"
                    display="flex"
                    gap={1.5}
                >
                    <IconButton
                        {...listeners}
                        ref={setActivatorNodeRef}
                        sx={{ cursor: 'grab' }}
                    >
                        <DragHandle />
                    </IconButton>

                    {process.name}
                </Box>
            </TableCell>

            <TableCell
                align="right"
                sx={[classes.cell, process?.isDeleted && classes.archived]}
            >
                {formatCurrency(process.hourlyRate)}
            </TableCell>

            <TableCell
                align="right"
                sx={[classes.cell, process?.isDeleted && classes.archived]}
            >
                {process.setupTimeSeconds} {t('s')}
            </TableCell>

            <TableCell
                align="left"
                sx={[classes.cell, process?.isDeleted && classes.archived]}
            >
                {setupMode.find((mode) => mode.value === process.setupMode).name}
            </TableCell>

            <TableCell
                align="left"
                sx={[classes.cell, process?.isDeleted && classes.archived]}
            >
                {measureTypes.find((type) => type.value === process.measureType)?.name}
            </TableCell>

            <TableCell
                align="right"
                sx={[classes.cell, process?.isDeleted && classes.archived]}
            >
                {formatCurrency(process.pricePerMeasure)}
            </TableCell>

            <TableCell
                align="right"
                sx={[classes.cell, process?.isDeleted && classes.archived]}
            >
                {process.timePerPartSeconds} {t('s')}
            </TableCell>

            <TableCell
                align="right"
                sx={[classes.cell, process?.isDeleted && classes.archived]}
            >
                {process.timePerMeasureSeconds} {t('s')}
            </TableCell>

            <TableCell
                align="left"
                sx={[classes.cell, process?.isDeleted && classes.archived]}
            >
                <Switch
                    checked={process.customerVisible}
                    color="primary"
                    disabled={process?.isDeleted}
                    size="small"
                    onChange={updateProcessVisibility}
                />
            </TableCell>

            <TableCell
                align="right"
                sx={[classes.cell, process?.isDeleted && classes.archived]}
            >
                <IconButton
                    disabled={process?.isDeleted}
                    size="small"
                    sx={classes.button}
                    onClick={handleEditClick}
                >
                    <Create fontSize="small" />
                </IconButton>
                <TbxTooltip
                    title={
                        process?.isDeleted
                            ? isOnFreePlan && secondaryProcessCount > 0
                                ? t(
                                      'You are on a Free Plan which only allows 1 custom secondary process.  Please archive your existing process in order to unarchive one.'
                                  )
                                : t('Unarchive secondary process')
                            : t('Archive secondary process')
                    }
                    arrow
                >
                    <span>
                        <IconButton
                            disabled={process?.isDeleted && isOnFreePlan ? secondaryProcessCount > 0 : null}
                            size="small"
                            sx={classes.button}
                            onClick={handleArchiveClick}
                        >
                            {process?.isDeleted ? <Unarchive fontSize="small" /> : <Archive fontSize="small" />}
                        </IconButton>
                    </span>
                </TbxTooltip>
            </TableCell>
        </TableRow>
    )
}

SecondaryProcessesRow.propTypes = {
    archiveProcessClick: PropTypes.func,
    component: PropTypes.any,
    editProcessClick: PropTypes.func,
    id: PropTypes.string,
    index: PropTypes.number,
    isImperial: PropTypes.bool,
    isOnFreePlan: PropTypes.bool,
    process: PropTypes.object,
    secondaryProcessCount: PropTypes.number,
}

export default SecondaryProcessesRow
