import { useEffect, useState } from 'react'
import ReactDOM from 'react-dom'
import { Box } from '@mui/material'

const classes = {
    appBar: {
        display: 'flex',
        width: '100%',
        justifyContent: 'space-between',
        alignItems: 'center',
    },
}

const AppBarPortal = ({ children }) => {
    const [domReady, setDomReady] = useState(false)

    useEffect(() => {
        setDomReady(true)
    }, [])

    const appBarElement = document.getElementById('store-app-bar')
    return domReady && document.getElementById('store-app-bar')
        ? ReactDOM.createPortal(<Box sx={classes.appBar}>{children}</Box>, appBarElement)
        : null
}

export default AppBarPortal
