import { useEffect, useRef, useState } from 'react'

export const useOnAllImagesLoaded = (ref) => {
    const [isImageLoading, setIsImageLoading] = useState(true)
    const imageElements = useRef([])

    const updateStatus = () => {
        setIsImageLoading(imageElements?.current?.some((image) => !image.complete))
    }

    const cleanUpEventHandlers = () => {
        imageElements?.current?.forEach((image) => {
            image.removeEventListener('load', updateStatus)
            image.removeEventListener('error', updateStatus)
        })
    }

    useEffect(() => {
        imageElements.current =
            typeof ref?.current?.querySelectorAll === 'function' ? Array.from(ref.current.querySelectorAll('img')) : []

        cleanUpEventHandlers()

        if (!imageElements?.current?.length) {
            setIsImageLoading(true)
            return
        }

        imageElements?.current?.forEach((image) => {
            image.addEventListener('load', updateStatus)
            image.addEventListener('error', updateStatus)
        })

        updateStatus()

        return cleanUpEventHandlers
    })

    return {
        isImageLoading,
    }
}
