import { api } from './api'

const apiVersion = 'v2'

export const materialsApi = api
    .enhanceEndpoints({
        addTagTypes: ['Materials'],
    })
    .injectEndpoints({
        endpoints: (builder) => ({
            getMaterials: builder.query({
                query: ({ organisationId }) => `/${apiVersion}/organisation/${organisationId}/materials`,
                transformResponse: (response) => {
                    delete response.$type

                    return response
                },
                providesTags: (result, _error, _arg) =>
                    result
                        ? [
                              { type: 'Materials', id: 'LIST' },

                              ...(Object.values(result)?.map(({ materialId }) => ({
                                  type: 'Materials',
                                  id: materialId,
                              })) ?? []),
                          ]
                        : [{ type: 'Materials', id: 'LIST' }],
            }),

            getMaterial: builder.query({
                query: ({ materialId, organisationId }) => ({
                    url: `/${apiVersion}/organisation/${organisationId}/materials/${materialId}`,
                }),
                providesTags: (result, _error, arg) => [
                    { type: 'Materials', id: arg.materialId },
                    { type: 'Sheets', id: 'LIST' },
                    ...(result.sheets.map(({ sheetId }) => ({ type: 'Sheets', id: sheetId })) ?? []),
                ],
            }),

            createMaterial: builder.mutation({
                query: ({ material, organisationId }) => ({
                    url: `/organisations/${organisationId}/materials/create`,
                    method: 'POST',
                    body: { ...material, organisationId },
                }),
                invalidatesTags: [{ type: 'Materials', id: 'LIST' }],
            }),

            checkNameAvailability: builder.query({
                query: ({ materialName, materialType, organisationId }) => ({
                    url: `/organisations/${organisationId}/materials/check-name-availability/${materialName}/${materialType}`,
                    method: 'GET',
                }),
            }),

            updateMaterial: builder.mutation({
                query: ({ material, organisationId }) => ({
                    url: `/organisations/${organisationId}/materials/${material.materialId}`,
                    method: 'PUT',
                    body: material,
                }),
                invalidatesTags: (_result, _error, arg) => [{ type: 'Materials', id: arg.material.materialId }],
            }),

            archiveMaterial: builder.mutation({
                query: ({ materialId, organisationId }) => ({
                    url: `/organisations/${organisationId}/materials/${materialId}`,
                    method: 'DELETE',
                }),
                invalidatesTags: (_result, _error, arg) => [
                    { type: 'Materials', id: arg.materialId },
                    { type: 'QuoteItems' },
                    { type: 'Quotes' }
                ],
            }),

            unarchiveMaterial: builder.mutation({
                query: ({ materialId, organisationId }) => ({
                    url: `/organisations/${organisationId}/materials/${materialId}/unarchive`,
                    method: 'PUT',
                }),
                invalidatesTags: (_result, _error, arg) => [
                    { type: 'Materials', id: arg.materialId },
                    { type: 'QuoteItems' },
                    { type: 'Quotes' }
                ],
            }),

            updateMaterialRateTable: builder.mutation({
                query: ({ materialRateTable, organisationId }) => ({
                    url: `/organisations/${organisationId}/materials/material-rate-table/${materialRateTable.materialRateTableId}`,
                    method: 'PUT',
                    body: materialRateTable,
                }),
                invalidatesTags: (_result, _error, arg) => [
                    { type: 'Materials', id: arg.materialRateTable.materialId },
                ],
            }),

            deleteMaterialRateTable: builder.mutation({
                query: ({ materialRateTableId, organisationId }) => ({
                    url: `/organisations/${organisationId}/materials/material-rate-table/${materialRateTableId}`,
                    method: 'DELETE',
                }),
                invalidatesTags: (_result, _error, arg) => [
                    { type: 'Materials', id: arg.materialRateTableId.materialId },
                ],
            }),

            createMaterialList: builder.mutation({
                query: ({ organisationId }) => ({
                    url: `/organisations/${organisationId}/materials`,
                    method: 'POST',
                }),
            }),

            createQuoteMaterialList: builder.mutation({
                query: ({ location, organisationId }) => {
                    let url = `/organisations/${organisationId}/quote-materials`
                    if (location && location.length > 0) {
                        url += `?location=${location}`
                    }
                    return {
                        url,
                        method: 'POST',
                    }
                },
            }),
        }),
    })

export const {
    useArchiveMaterialMutation,
    useCheckNameAvailabilityQuery,
    useCreateMaterialListMutation,
    useCreateMaterialMutation,
    useCreateQuoteMaterialListMutation,
    useDeleteMaterialRateTableMutation,
    useGetMaterialQuery,
    useGetMaterialsQuery,
    useLazyGetMaterialQuery,
    useLazyGetMaterialsQuery,
    useUnarchiveMaterialMutation,
    useUpdateMaterialMutation,
    useUpdateMaterialRateTableMutation,
} = materialsApi
