import { useTranslation } from 'react-i18next'
import Chip from '@mui/material/Chip'
import PropTypes from 'prop-types'

import { GET_INTEGRATION_STATUS_LABEL } from '@/common/utils'

const QuoteIntegrationChip = ({ integrationExportFailureMessage, integrationExportSucceeded }) => {
    const { t } = useTranslation()

    return (
        <Chip
            data-testid="quote-integration-status-chip"
            label={t(GET_INTEGRATION_STATUS_LABEL(integrationExportSucceeded, integrationExportFailureMessage))}
            size="small"
            sx={{ textTransform: 'uppercase', fontSize: '11px', userSelect: 'none' }}
            title={t(GET_INTEGRATION_STATUS_LABEL(integrationExportSucceeded, integrationExportFailureMessage))}
        />
    )
}

QuoteIntegrationChip.propTypes = {
    integrationExportFailureMessage: PropTypes.string,
    integrationExportSucceeded: PropTypes.bool,
}

export default QuoteIntegrationChip
