import { useTranslation } from 'react-i18next'
import { Button, Typography } from '@mui/material'
import PropTypes from 'prop-types'

import { Dialog } from '@/common/components'

const ArchiveSecondaryProcessDialog = ({ mode = 'archive', name = undefined, onCancelClose, onConfirmClose }) => {
    const { t } = useTranslation()

    const handleDeleteButtonClick = () => {
        if (typeof onConfirmClose === 'function') {
            onConfirmClose()
        }
    }

    return (
        <Dialog
            actions={[
                <Button
                    color="primary"
                    key="add"
                    variant="contained"
                    onClick={handleDeleteButtonClick}
                >
                    {t(mode)}
                </Button>,
                <Button
                    color="primary"
                    key="cancel"
                    variant="contained"
                    onClick={onCancelClose}
                >
                    {t('Cancel')}
                </Button>,
            ]}
            className={{ width: 500 }}
            open={true}
            title={t(`Confirm ${mode} secondary process`)}
            onClose={onCancelClose}
        >
            <Typography variant="body1">
                {t(`Are you sure you wish to ${mode} secondary process`)}
                <span style={{ fontWeight: 500 }}> {name}</span>?
            </Typography>
        </Dialog>
    )
}

ArchiveSecondaryProcessDialog.propTypes = {
    mode: PropTypes.oneOf(['archive', 'unarchive']),
    name: PropTypes.string,
    onCancelClose: PropTypes.func,
    onConfirmClose: PropTypes.func,
}

export default ArchiveSecondaryProcessDialog
