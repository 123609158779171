import { useMemo, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useSelector } from 'react-redux'
import { Link } from 'react-router-dom'
import { Archive, FileCopy, Unarchive } from '@mui/icons-material'
import { Box, IconButton, TableCell, TableRow, Typography, useTheme } from '@mui/material'
import PropTypes from 'prop-types'

import { selectLocale } from '@/app/slices/appSlice'
import { selectCurrencyCode } from '@/app/slices/organisationSlice'
import { DateTime, TbxTooltip } from '@/common/components'
import { useToolBoxTreatments } from '@/common/hooks'
import { Constants, getFormattedPrice, QUOTE_SOURCE_TYPES, QUOTE_STATUS } from '@/common/utils'

import QuoteIntegrationChip from '../common/QuoteIntegrationChip'
import QuotePaidStatusChip from '../common/QuotePaidStatusChip'
import QuoteSourceChip from '../common/QuoteSourceChip'
import QuoteStatusChip from '../common/QuoteStatusChip'
import DownloadDocuments from '../DownloadDocuments/DownloadDocuments'

import ArchiveQuoteDialog from './ArchiveQuoteDialog'

const classes = {
    cell: (isDeleted) => ({
        padding: '16px',
        color: isDeleted ? 'text.disabled' : 'text.primary',
    }),
    row: {
        '&:hover': {
            background: (theme) => theme.palette.action.hover,
        },
    },
    icon: {
        color: 'text.primary',
    },
    disabledIcon: (isDeleted) => ({
        color: isDeleted ? 'text.disabled' : 'text.primary',
    }),
    button: {
        margin: '0px 8px',
    },
}

const QuotesDashboardRow = ({
    onArchiveQuoteClick,
    onQuoteDuplicateClicked,
    onUnarchiveQuoteClick,
    quote,
    showIntegrationStatusColumn,
}) => {
    const { t } = useTranslation()
    const theme = useTheme()

    const { showDownloadPdfs, showPayments } = useToolBoxTreatments()

    const currencyCode = useSelector(selectCurrencyCode)
    const locale = useSelector(selectLocale)

    const [showDeleteDialog, setShowDeleteDialog] = useState(false)

    const showPaymentStatus = useMemo(() => {
        return [QUOTE_STATUS.Ordered, QUOTE_STATUS.Invoiced, QUOTE_STATUS.Cancelled, QUOTE_STATUS.Voided].includes(
            quote?.status
        )
    }, [quote?.status])

    const handleArchiveQuoteClick = () => setShowDeleteDialog(true)

    const hideArchiveDialog = () => setShowDeleteDialog(false)

    const confirmArchiveQuote = () => {
        onArchiveQuoteClick(quote.id)
        hideArchiveDialog()
    }

    const confirmUnarchiveQuote = () => {
        onUnarchiveQuoteClick(quote.id)
        hideArchiveDialog()
    }

    const handleDuplicateClick = (event) => {
        event.stopPropagation()

        if (typeof onQuoteDuplicateClicked === 'function') {
            const newName = `${t('Copy of')} ${quote.name}`
            onQuoteDuplicateClicked(quote?.id, newName)
        }
    }

    return (
        <TableRow sx={classes.row}>
            <TableCell
                align="left"
                sx={classes.cell(quote.isDeleted)}
            >
                <Link
                    style={{ color: theme.palette.secondary.main }}
                    to={`/quotes/${quote.id}`}
                >
                    {quote?.name ?? ''}
                </Link>
            </TableCell>

            <TableCell
                align="left"
                sx={classes.cell(quote.isDeleted)}
            >
                {quote.status === QUOTE_STATUS.Ordered || quote.status === QUOTE_STATUS.Invoiced ? (
                    <Link
                        style={{ color: theme.palette.secondary.main }}
                        target="_blank"
                        to={`/quotes/${quote.id}/documents/${Constants.PRIVATE_DOCUMENTS.WorkOrder}/`}
                    >
                        {quote.workOrderNumberText}
                    </Link>
                ) : null}
            </TableCell>

            <TableCell
                align="left"
                sx={classes.cell(quote.isDeleted)}
            >
                {quote.status === QUOTE_STATUS.Invoiced ? (
                    <Link
                        style={{ color: theme.palette.secondary.main }}
                        target="_blank"
                        to={`/quotes/${quote.id}/documents/${Constants.PUBLIC_DOCUMENTS.TaxInvoice}/`}
                    >
                        {quote.invoiceNumberText}
                    </Link>
                ) : null}
            </TableCell>

            <TableCell
                align="left"
                sx={classes.cell(quote.isDeleted)}
            >
                {quote.customerName ?? t('[No customer]')}
            </TableCell>

            <TableCell
                align="right"
                sx={classes.cell(quote.isDeleted)}
            >
                {quote?.numberOfItems ?? ''}
            </TableCell>

            <TableCell
                align="right"
                sx={classes.cell(quote.isDeleted)}
            >
                <Typography variant="strong2">
                    {getFormattedPrice(quote.lineTaxedPrice || 0, currencyCode, locale)}
                </Typography>
            </TableCell>

            <TableCell
                align="left"
                sx={[
                    classes.cell(quote.isDeleted),
                    { whiteSpace: 'nowrap', overflow: 'hidden', textOverflow: 'ellipsis' },
                ]}
            >
                {quote.lastModifiedByUserName}
            </TableCell>

            <TableCell
                align="right"
                sx={classes.cell(quote.isDeleted)}
            >
                <DateTime
                    format="DD-MMM-YYYY"
                    locale={locale}
                >
                    {quote.lastModifiedDateUtc}
                </DateTime>
            </TableCell>

            <TableCell
                align="left"
                sx={classes.cell(quote.isDeleted)}
            >
                <QuoteSourceChip quoteSource={quote.quoteSourceType} />
            </TableCell>

            <TableCell
                align="left"
                sx={classes.cell(quote.isDeleted)}
            >
                <QuoteStatusChip quoteStatus={quote.status} />
            </TableCell>

            {showIntegrationStatusColumn ? (
                <TableCell
                    align="left"
                    sx={classes.cell(quote.isDeleted)}
                >
                    <TbxTooltip
                        title={quote?.integrationExportFailureMessage}
                        arrow
                    >
                        <span>
                            <QuoteIntegrationChip
                                integrationExportFailureMessage={quote.integrationExportFailureMessage}
                                integrationExportSucceeded={quote.integrationExportSucceeded}
                            />
                        </span>
                    </TbxTooltip>
                </TableCell>
            ) : null}

            {showPayments ? (
                <TableCell
                    align="left"
                    sx={classes.cell(quote.isDeleted)}
                >
                    {showPaymentStatus ? <QuotePaidStatusChip paymentStatus={quote.paymentStatus} /> : null}
                </TableCell>
            ) : null}

            <TableCell
                align="right"
                sx={classes.cell(quote.isDeleted)}
            >
                <Box
                    display="flex"
                    gap={1}
                    justifyContent="flex-end"
                >
                    {quote.quoteSourceType !== QUOTE_SOURCE_TYPES.WebStore ? (
                        <TbxTooltip
                            title={t('Duplicate $t(quote)')}
                            arrow
                        >
                            <IconButton
                                size="small"
                                onClick={handleDuplicateClick}
                            >
                                <FileCopy
                                    fontSize="small"
                                    sx={quote.isDeleted ? classes.disabledIcon(quote.isDeleted) : classes.icon}
                                />
                            </IconButton>
                        </TbxTooltip>
                    ) : null}

                    {showDownloadPdfs ? (
                        <DownloadDocuments
                            quote={quote}
                            autoExport
                            useIconButton
                        />
                    ) : null}

                    <TbxTooltip
                        title={t(quote.isDeleted ? 'Unarchive $t(quote)' : 'Archive $t(quote)')}
                        arrow
                    >
                        <IconButton
                            size="small"
                            onClick={handleArchiveQuoteClick}
                        >
                            {quote.isDeleted ? (
                                <Unarchive
                                    fontSize="small"
                                    sx={classes.icon}
                                />
                            ) : (
                                <Archive
                                    fontSize="small"
                                    sx={classes.disabledIcon(quote.isDeleted)}
                                />
                            )}
                        </IconButton>
                    </TbxTooltip>
                </Box>
            </TableCell>

            {showDeleteDialog ? (
                <ArchiveQuoteDialog
                    mode={quote.isDeleted ? 'unarchive' : 'archive'}
                    name={quote?.name}
                    onCancelClose={hideArchiveDialog}
                    onConfirmClose={quote.isDeleted ? confirmUnarchiveQuote : confirmArchiveQuote}
                />
            ) : null}
        </TableRow>
    )
}

QuotesDashboardRow.propTypes = {
    quote: PropTypes.object,
    showIntegrationStatusColumn: PropTypes.bool,
    onArchiveQuoteClick: PropTypes.func,
    onQuoteDuplicateClicked: PropTypes.func,
    onUnarchiveQuoteClick: PropTypes.func,
}

export default QuotesDashboardRow
