import { useTranslation } from 'react-i18next'
import { Box, Typography } from '@mui/material'
import { blue, green, purple, red, yellow } from '@mui/material/colors'

import LayerColorAdornment from '../LayerColorAdornment/LayerColorAdornment'

const classes = {
    legendContainer: {
        color: 'white',
        backgroundColor: 'hsla(0, 0%, 15%, .95)',
        height: 'auto',
        display: 'block',
        position: 'absolute',
        right: 30,
        top: 30,
        maxWidth: '320px',
        zIndex: 1000,
        padding: 3,
        border: '2px solid white',
        borderRadius: 2,
        pointerEvents: 'none',
    },
    legendListContainer: {
        display: 'flex',
        flexDirection: 'column',
        gap: 2,
    },
    legendItem: {
        display: 'flex',
        flexDirection: 'row',
        alignItems: 'center',
        gap: 1,
    },
}

const PathTypeLegend = () => {
    const { t } = useTranslation()

    const options = [
        {
            label: t('Cutting'),
            color: 'white',
        },
        {
            label: t('Etching'),
            color: green[500],
        },
        {
            label: t('Information'),
            color: yellow[400],
        },
        {
            label: t('Folding'),
            color: blue[500],
        },
        {
            label: t('Open outer cut'),
            color: red[600],
        },
        {
            label: t('Open inner cut'),
            color: purple[500],
        },
    ]

    return (
        <Box sx={classes.legendContainer}>
            <Box sx={classes.legendListContainer}>
                <Typography
                    textTransform="uppercase"
                    variant="strong2"
                >
                    {t('Path type')}
                </Typography>
                {options.map((option) => {
                    return (
                        <Box
                            key={option.label}
                            sx={classes.legendItem}
                        >
                            <LayerColorAdornment color={option.color} />
                            <Box sx={classes.legendLabel}>
                                <Typography
                                    textTransform="uppercase"
                                    variant="body2"
                                >
                                    {option.label}
                                </Typography>
                            </Box>
                        </Box>
                    )
                })}
            </Box>
        </Box>
    )
}

export default PathTypeLegend
