import { useTranslation } from 'react-i18next'
import { Search } from '@mui/icons-material'
import { InputAdornment, TextField } from '@mui/material'
import PropTypes from 'prop-types'

const TbxToolbarSearch = ({
    dataTestId = 'toolbar-search',
    handleChange,
    handleClick,
    placeholder = 'Search...',
    searchValue,
    sx,
}) => {
    const { t } = useTranslation()

    const handleInputChange = (event) => {
        handleChange(event.target.value)
    }

    return (
        <TextField
            autoFocus={false}
            color="primary"
            data-testid={dataTestId}
            InputProps={{
                startAdornment: (
                    <InputAdornment position="start">
                        <Search color="primary" />
                    </InputAdornment>
                ),
            }}
            placeholder={t(placeholder)}
            size="small"
            style={{ width: '100%' }}
            sx={sx}
            value={searchValue}
            variant="outlined"
            onChange={handleInputChange}
            onClick={handleClick}
        ></TextField>
    )
}

TbxToolbarSearch.propTypes = {
    dataTestId: PropTypes.string,
    handleChange: PropTypes.func,
    handleClick: PropTypes.func,
    placeholder: PropTypes.string,
    searchValue: PropTypes.string,
    sx: PropTypes.oneOfType([
        PropTypes.arrayOf(PropTypes.oneOfType([PropTypes.func, PropTypes.object, PropTypes.bool])),
        PropTypes.func,
        PropTypes.object,
    ]),
}

export default TbxToolbarSearch
