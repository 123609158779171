import { useState } from 'react'
import { useTranslation } from 'react-i18next'
import { AddRounded } from '@mui/icons-material'
import Button from '@mui/material/Button'

import AppBarPortal from '../shared/AppBar/AppBarPortal'

import AddQuoteItemsDialog from './AddQuoteItemsDialog'

const QuoteActions = () => {
    const { t } = useTranslation()

    const [showAddPartDialog, setShowAddPartDialog] = useState(false)

    const handleAddPartClick = () => setShowAddPartDialog(true)

    const handleAddPartDialogClose = () => setShowAddPartDialog(false)

    return (
        <AppBarPortal>
            <Button
                color="primary"
                data-testid="add-part-button"
                startIcon={<AddRounded />}
                variant="contained"
                onClick={handleAddPartClick}
            >
                {t('Add part')}
            </Button>

            <AddQuoteItemsDialog
                open={showAddPartDialog}
                onClose={handleAddPartDialogClose}
            />
        </AppBarPortal>
    )
}

export default QuoteActions
