import { createSlice } from '@reduxjs/toolkit'

import { userApi } from '../services/user'

const initialState = {
    userRole: 'user',
    currentUser: undefined,
    appIsLoading: true,
    appBarState: {
        hidden: false,
        menuHidden: false,
        myAccountHidden: false,
        title: '',
        titlePosition: 'center',
        toolbarItems: [],
        justifyItems: 'space-around',
        showSubscriptionBar: false,
        showTrialWithSubscriptionBar: false,
        showUpdatePaymentBar: false,
        freePlan: false,
    },
    activationState: {
        selectedPrice: {},
        billingAddress: {},
        taxIdentifier: '',
    },
    language: 'en-US',
    locale: 'en-US',
    languageList: {
        'en-US': {
            nativeName: 'English',
            region: 'US',
        },
    },
    displayQuotePricing: true,
    subscription: {},
    showDrawerMenu: false,
    showNestedDrawerMenu: null,
}

export const appSlice = createSlice({
    name: 'appSlice',
    initialState,
    reducers: {
        setUserRole: (state, action) => {
            state.userRole = action.payload
        },

        setCurrentUser: (state, action) => {
            state.currentUser = { ...state.currentUser, ...action.payload }
        },

        setAppIsLoading: (state, action) => {
            state.appIsLoading = action.payload
        },

        setAppBarState: (state, action) => {
            state.appBarState = { ...state.appBarState, ...action.payload }
        },

        resetAppBarState: (state) => {
            state.appBarState = initialState.appBarState
        },

        setActivationState: (state, action) => {
            state.activationState = { ...state.activationState, ...action.payload }
        },

        setLanguage: (state, action) => {
            state.language = action.payload
        },

        setLocale: (state, action) => {
            state.locale = action.payload
        },

        setLanguageList: (state, action) => {
            state.languageList = action.payload
        },

        setDisplayQuotePricing: (state, action) => {
            state.displayQuotePricing = action.payload
        },

        setSubscription: (state, action) => {
            state.subscription = action.payload
        },

        setShowDrawerMenu: (state, action) => {
            state.showDrawerMenu = action.payload
        },

        setShowNestedDrawerMenu: (state, action) => {
            state.showNestedDrawerMenu = action.payload
        },
    },
    extraReducers: (builder) => {
        builder
            .addMatcher(userApi.endpoints.getOrCreateUserContext.matchFulfilled, (state, { payload }) => {
                state.currentUser = { ...state.currentUser, ...payload.user }
            })
            .addMatcher(userApi.endpoints.updateUser.matchFulfilled, (state, { payload }) => {
                state.currentUser = { ...state.currentUser, ...payload.user }
            })
    },
})

export const selectUserRole = (state) => state.appSlice.userRole
export const selectCurrentUser = (state) => state.appSlice.currentUser
export const selectCurrentUserId = (state) => state.appSlice.currentUser?.userId
export const selectAppIsLoading = (state) => state.appSlice.appIsLoading
export const selectAppBarState = (state) => state.appSlice.appBarState
export const selectActivationState = (state) => state.appSlice.activationState
export const selectLanguage = (state) => state.appSlice.language
export const selectLocale = (state) => state.appSlice.locale
export const selectLanguageList = (state) => state.appSlice.languageList
export const selectDisplayQuotePricing = (state) => state.appSlice.displayQuotePricing
export const selectSubscription = (state) => state.appSlice.subscription
export const selectShowDrawerMenu = (state) => state.appSlice.showDrawerMenu
export const selectShowNestedDrawerMenu = (state) => state.appSlice.showNestedDrawerMenu
export const selectIsSubscriptionSet = (state) => {
    const subscriptionExists = state?.appSlice?.subscription
    return Boolean(subscriptionExists && Object.keys(subscriptionExists).length)
}
export const selectIsCurrentUserAdmin = (state) => state.appSlice.userRole === 'administrator'
export const selectIsCurrentUserOwner = (state) => state.appSlice.userRole === 'owner'

export const {
    resetAppBarState,
    setActivationState,
    setAppBarState,
    setAppIsLoading,
    setCurrentUser,
    setDisplayQuotePricing,
    setLanguage,
    setLanguageList,
    setLocale,
    setShowDrawerMenu,
    setShowNestedDrawerMenu,
    setSubscription,
    setUserRole,
} = appSlice.actions

export default appSlice.reducer
