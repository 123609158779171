import { Box } from '@mui/system'
import _ from 'lodash'
import PropTypes from 'prop-types'

import PartCategoryPanel from './PartCategoryPanel/PartCategoryPanel'

const PartsList = ({ nqp, onPartSelected, parts, selectedPart }) => {
    return parts ? (
        <Box sx={{ height: 'calc(100% - 64px)', overflowY: 'auto' }}>
            {_(parts)
                .groupBy('category')
                .map((parts, category) => {
                    return (
                        <PartCategoryPanel
                            categoryName={category}
                            expanded={selectedPart ? selectedPart.category === category : false}
                            key={category}
                            nqp={nqp}
                            parts={parts}
                            selectedPart={selectedPart}
                            onPartSelected={onPartSelected}
                        />
                    )
                })
                .value()}
        </Box>
    ) : null
}

PartsList.propTypes = {
    nqp: PropTypes.bool,
    parts: PropTypes.array,
    selectedPart: PropTypes.object,
    onPartSelected: PropTypes.func,
}

export default PartsList
