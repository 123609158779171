import { memo, useCallback, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useDispatch, useSelector } from 'react-redux'
import { useParams } from 'react-router-dom'
import { Box, Button, FormControl, MenuItem, Select, Typography } from '@mui/material'
import { useSnackbar } from 'notistack'
import PropTypes from 'prop-types'

import {
    useGetQuoteItemsQuery,
    useLazyGetQuoteItemDrawingQuery,
    useUpdateQuoteItemDrawingMutation,
    useUpdateQuoteItemMutation,
} from '@/app/services/quoteItems'
import { useCalculateQuoteMutation, useGetQuoteQuery, useUpdateQuoteMutation } from '@/app/services/quotes'
import { selectOrganisationId } from '@/app/slices/organisationSlice'
import {
    setOpenDrawingDoctorModal,
    setSelectedQuoteItemDrawingId,
    setSelectedQuoteStatus,
} from '@/app/slices/quoteItemsSlice'
import TbxTooltip from '@/common/components/TbxTooltip/TbxTooltip'
import ThumbnailUri from '@/common/components/ThumbnailUri/ThumbnailUri'
import useNumberFormatter from '@/common/hooks/useNumberFormatter'
import { QUOTE_STATUS } from '@/common/utils'

const classes = {
    ItemDetails_Image: {
        width: '220px',
        gap: '8px',
    },

    ItemDetails_Options_Size: {
        gap: '12px',
    },
    ItemDetails_TextButton: {
        textTransform: 'none',
        fontWeight: 'normal',
        color: 'text.primary',
        fontSize: '14px',
        textDecoration: 'underline',
        textDecorationThickness: '1px',
    },
}

const ItemDrawing = ({ quoteItemId }) => {
    const { t } = useTranslation()
    const dispatch = useDispatch()
    const { enqueueSnackbar } = useSnackbar()
    const { quoteId } = useParams()

    const organisationId = useSelector((state) => selectOrganisationId(state))

    const { data: selectedQuote, isFetching: isFetchingQuote } = useGetQuoteQuery({
        organisationId,
        quoteId,
    })

    const { quoteItem } = useGetQuoteItemsQuery(
        {
            organisationId,
            quoteId,
        },
        {
            selectFromResult: ({ data }) => ({
                quoteItem: data?.find((item) => item.id === quoteItemId),
            }),
        }
    )

    const [getQuoteItemDrawing] = useLazyGetQuoteItemDrawingQuery()
    const [updateQuote] = useUpdateQuoteMutation()
    const [updateQuoteItem, { isLoading: isUpdating }] = useUpdateQuoteItemMutation()

    const [updateQuoteItemDrawing] = useUpdateQuoteItemDrawingMutation()

    const [, { isLoading: isCalculating }] = useCalculateQuoteMutation({
        fixedCacheKey: 'shared-calculate-quote',
    })

    const [itemUnit, setItemUnit] = useState(quoteItem?.isImperial ? 'in' : 'mm')

    const partUnits = (part) => (part.isImperial ? 'in' : 'mm')

    const isPartFromLibrary = Boolean(quoteItem?.partLibraryEntryId)

    const { n: metricNumberFormatter } = useNumberFormatter({
        numberOfDecimalPlaces: 2,
    })

    const { n: imperialNumberFormatter } = useNumberFormatter({
        numberOfDecimalPlaces: 4,
    })

    const handleUpdateQuoteItemDrawing = async (quoteItemDrawing) => {
        quoteItemDrawing.paths = Object.values(quoteItemDrawing.paths)
        const drawing = { ...quoteItemDrawing }

        const result = await updateQuoteItemDrawing({
            organisationId,
            quoteId,
            quoteItemId,
            drawing,
        }).unwrap()
        return result
    }

    const renderPartSize = (part) => {
        if (part.displayBoundBoxWidth && part.displayBoundBoxHeight) {
            const n = part.isImperial ? imperialNumberFormatter : metricNumberFormatter
            const bounds = part.profile
                ? part.profile.toLowerCase() === 'circle'
                    ? `${n(part.diameter)} x ${n(part.rotaryPartLength)}`
                    : `${n(part.rotaryProfileWidth)} x ${n(part.rotaryProfileLength)} x ${n(part.rotaryPartLength)}`
                : `${n(part.displayBoundBoxWidth)} x ${n(part.displayBoundBoxHeight)}`
            return (
                <Typography
                    sx={{ whiteSpace: 'break-spaces' }}
                    variant="strong2"
                >{`${bounds} ${partUnits(part)}`}</Typography>
            )
        }
    }

    const handleUpdateQuoteItem = async (quoteItem) => {
        try {
            await updateQuoteItem({
                organisationId,
                quoteId: quoteItem?.quoteId,
                quoteItemId: quoteItem?.id,
                quoteItem,
            }).unwrap()
        } catch (error) {
            enqueueSnackbar(t('Failed to update the $t(quote) item'), {
                variant: 'error',
            })
        }
    }

    const handleUpdateQuote = async (updatedFields) => {
        try {
            await updateQuote({
                organisationId,
                quoteId,
                quote: {
                    ...selectedQuote,
                    ...updatedFields,
                },
            }).unwrap()
        } catch (_e) {
            const errorMessage = t('$t(An error occurred) updating the $t(quote).')
            enqueueSnackbar(errorMessage, { variant: 'error' })
        }
    }

    const handleThumbnailClick = useCallback(() => {
        dispatch(setSelectedQuoteItemDrawingId(quoteItemId))
        dispatch(setOpenDrawingDoctorModal(true))
    }, [dispatch, quoteItemId])

    const handleUnitChange = async (event) => {
        if (selectedQuote.status !== QUOTE_STATUS.NotCalculated) {
            dispatch(setSelectedQuoteStatus(QUOTE_STATUS.NotCalculated))
            handleUpdateQuote({ status: QUOTE_STATUS.NotCalculated })
        }
        const value = event?.target?.value
        if (Boolean(value) && itemUnit !== value) {
            setItemUnit(value)
            const drawing = await getQuoteItemDrawing(
                {
                    organisationId,
                    quoteId,
                    quoteItemId,
                },
                true
            ).unwrap()

            await handleUpdateQuoteItem({
                ...quoteItem,
                isImperial: value === 'in',
            })
            await handleUpdateQuoteItemDrawing({
                ...drawing,
                isImperial: value === 'in',
            })
        }
    }

    return (
        <Box
            alignItems="center"
            display="flex"
            flex="0 0 auto"
            flexDirection="column"
            justifyContent="space-around"
            sx={classes.ItemDetails_Image}
        >
            {quoteItem?.partId ? (
                <Typography>
                    {t('Part ID')}: {quoteItem?.partId}
                </Typography>
            ) : null}
            <ThumbnailUri
                uri={quoteItem?.thumbnailUri}
                onClick={handleThumbnailClick}
            />
            <Button
                data-testid="nqp-quote-item-open-drawing-doctor"
                sx={classes.ItemDetails_TextButton}
                variant="text"
                onClick={handleThumbnailClick}
            >
                {t('Open Drawing Doctor')}
            </Button>

            {'profile' in quoteItem ? (
                <Typography variant="strong1">
                    {t('Profile')}: {t(quoteItem?.profile)}
                </Typography>
            ) : null}

            <Box
                alignItems="center"
                display="flex"
                flexDirection="column"
                sx={classes.ItemDetails_Options_Size}
            >
                <Typography>{renderPartSize(quoteItem)}</Typography>
                <TbxTooltip
                    title={isPartFromLibrary ? t('Cannot edit parts from library') : ''}
                    arrow
                >
                    <FormControl
                        color="primary"
                        disabled={isUpdating}
                        margin="none"
                        size="small"
                        sx={classes.formControl_small}
                        variant="outlined"
                    >
                        <Select
                            data-testid="nqp-quote-item-select-unit"
                            disabled={isUpdating || isCalculating || isFetchingQuote || isPartFromLibrary}
                            id="select-itemUnit"
                            labelId="select-itemUnit-label"
                            value={itemUnit}
                            onChange={handleUnitChange}
                        >
                            <MenuItem value="mm">{t('Millimetres')}</MenuItem>
                            <MenuItem value="in">{t('Inches')}</MenuItem>
                        </Select>
                    </FormControl>
                </TbxTooltip>
            </Box>
        </Box>
    )
}

ItemDrawing.propTypes = {
    quoteItemId: PropTypes.string,
}

export default memo(ItemDrawing)
