import { createTheme } from '@mui/material/styles'
import { deepmerge } from '@mui/utils'

export const WhitelabelThemeOverride = (outerTheme) =>
    createTheme(
        deepmerge(outerTheme, {
            palette: {
                ...outerTheme.palette,
                primary: {
                    main: '#808080',
                },
                secondary: {
                    main: '#A9A9A9',
                },
            },
            components: {
                MuiButton: {
                    styleOverrides: {
                        root: {
                            '&:hover': { backgroundColor: 'rgb(89, 89, 89)' },
                        },
                    },
                },
                MuiButtonGroup: {
                    styleOverrides: {
                        root: {
                            '.MuiButtonGroup-grouped:not(:last-of-type)': { borderColor: 'rgb(89, 89, 89)' },
                        },
                    },
                },
            },
        })
    )
