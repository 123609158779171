import { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { TextField } from '@mui/material'
import PropTypes from 'prop-types'

import { SelectOption } from '@/common/components'

const ProcessSetupModeSelect = ({
    InputProps,
    className,
    disabled = false,
    error,
    fullWidth = true,
    helperText,
    hideLabel = false,
    inputProps,
    label,
    labelText,
    onChange,
    value = 'ByLine',
}) => {
    const { t } = useTranslation()

    const units = [
        { name: t('Per Line'), value: 'ByLine' },
        { name: t('Per Process'), value: 'ByProcess' },
    ]

    const [selectedValue, setSelectedValue] = useState('')

    useEffect(() => {
        setSelectedValue(value)
    }, [value])

    const handleChange = (event) => {
        const newSelectedValue = event.target.value

        setSelectedValue(newSelectedValue)

        if (typeof onChange === 'function') {
            onChange(newSelectedValue)
        }
    }

    return (
        <TextField
            disabled={disabled}
            error={error}
            fullWidth={fullWidth}
            helperText={helperText}
            inputProps={inputProps}
            InputProps={InputProps}
            label={label}
            placeholder={!hideLabel ? labelText || t('Select mode') : ''}
            sx={className}
            value={selectedValue}
            select
            onChange={handleChange}
        >
            {units.map((unit) => {
                return (
                    <SelectOption
                        key={unit.value}
                        value={unit.value}
                    >
                        {unit.name}
                    </SelectOption>
                )
            })}
        </TextField>
    )
}

ProcessSetupModeSelect.propTypes = {
    disabled: PropTypes.bool,
    error: PropTypes.bool,
    fullWidth: PropTypes.bool,
    helperText: PropTypes.string,
    hideLabel: PropTypes.bool,
    inputProps: PropTypes.object,
    InputProps: PropTypes.object,
    label: PropTypes.node,
    labelText: PropTypes.string,
    value: PropTypes.string,
    onChange: PropTypes.func,
}

export default ProcessSetupModeSelect
