import { useTranslation } from 'react-i18next'
import Typography from '@mui/material/Typography'

import { DashedBorderContainer } from '@/common/components'

const NoProjectsPanel = () => {
    const { t } = useTranslation()

    return (
        <DashedBorderContainer>
            <Typography variant="h4">{t('Your $t(quotes) will live here once you’ve created one')}</Typography>
        </DashedBorderContainer>
    )
}

export default NoProjectsPanel
