import { useEffect, useRef, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { TransformComponent, TransformWrapper } from 'react-zoom-pan-pinch'
import { ClassNames } from '@emotion/react'
import { Box, Checkbox, ToggleButton, ToggleButtonGroup, useTheme } from '@mui/material'
import PropTypes from 'prop-types'

import { Button, HorizontalSpacer, LoadingOverlay, Menu, MenuItem, Popover, Toolbar } from '@/common/components'
import PathTypeLegend from '@/common/components/PathTypeLegend/PathTypeLegend'
import { useKeyboardShortcut } from '@/common/hooks'

import DrawingCleanerFooter from './Components/DrawingCleanerFooter'
import ExtractPartDialog from './Components/ExtractPartDialog'
import Drawing from './Entities/Drawing'
import DrawingRenderer from './Renderers/DrawingRenderer'
import ZoomControls from './ZoomControls'

const classes = {
    container: {
        height: '100%',
        width: '100%',
        display: 'flex',
        flexDirection: 'column',
    },
    loadingOverlay: {
        height: '100%',
    },
    toolbar: {
        borderBottom: '1px solid #FFFFFF',
    },
    drawingContainer: {
        flex: '1 1 0',
        minHeight: 380,
        boxSizing: 'border-box',
    },
    selectionDetailsContainer: {
        borderTop: '1px solid #FFFFFF',
        boxSizing: 'border-box',
    },
    checkboxLabel: {
        color: 'white',
        fontFamily: 'fontFamily',
        fontSize: '0.7rem',
        textTransform: 'uppercase',
    },
    selectArea: (theme) => ({
        borderWidth: '2px',
        borderStyle: 'dashed',
        borderColor: theme.palette.primary.main,
        backgroundColor: theme.palette.action.focus,
    }),
    icon: {
        margin: '12px',
        width: 24,
        height: 24,
        color: '#858589',
    },
}

const DrawingCleaner = ({
    allowUndoRedo = false,
    disabled,
    drawing,
    footerDecimalPlaces = 2,
    onAllDrawingPartsExtracted,
    onDrawingPartsExtracted,
    onDrawingUpdated,
    onRedoClick,
    onUndoClick,
    originalCadSvg,
    part,
    showCadView = false,
    showCadViewToggle,
    showLegendProp = false,
    showLoadingOverlay,
    webStoreMode = false,
}) => {
    const { t } = useTranslation()

    const maxZoomFactor = 200000
    const wheelZoomFactor = 1.1
    const clickZoomFactor = 2

    const [stateDrawing, setStateDrawing] = useState({})
    const [userMousePosition, setUserMousePosition] = useState(null)
    const [totalZoomFactor, setTotalZoomFactor] = useState(1)
    const [selectedPaths, setSelectedPaths] = useState({})
    const [showExtractPartDialog, setShowExtractPartDialog] = useState(false)
    const [showExtractAllPartsDialog, setShowExtractAllPartsDialog] = useState(false)
    const [originalViewBox, setOriginalViewBox] = useState(null)
    const [contextMenuAnchorPosition, setContextMenuAnchorPosition] = useState(null)
    const [showLegend, setShowLegend] = useState(true)
    const [showOpenPathHighlight, setShowOpenPathHighlight] = useState(true)
    const [showOpenPathCheckbox, setShowOpenPathCheckbox] = useState(false)
    const [showCad, setShowCad] = useState(false)
    const [isViewBoxDirty, setIsViewBoxDirty] = useState(false)

    const [selectedItemDimensions, setSelectedItemDimensions] = useState({
        width: 0,
        height: 0,
        diameter: 0,
        length: 0,
    })

    const [viewBox, setViewBox] = useState({
        minX: 0,
        minY: 0,
        width: 0,
        height: 0,
    })

    const containerRef = useRef(null)

    const numberOfSelectedPaths = Object.keys(selectedPaths).length

    const theme = useTheme()

    useEffect(() => {
        // Avoid scrolling on the container ref from scrolling the page
        containerRef.current.addEventListener('wheel', (event) => event.preventDefault())
        const currentContainerRef = containerRef.current

        return () => {
            currentContainerRef.removeEventListener('wheel', (event) => event.preventDefault())
        }
    }, [])

    useEffect(() => {
        const drawingObj = new Drawing(drawing)

        if (!drawingObj) {
            return
        }

        setStateDrawing(drawingObj)

        if (webStoreMode && isViewBoxDirty) {
            return
        }

        setSelectedPaths({})
        setSelectedItemDimensions({
            width: drawingObj.width,
            height: drawingObj.height,
            diameter: 0,
            length: 0,
        })

        const newViewBox = {
            minX: drawing.viewBoxMinX !== 0 ? drawing.viewBoxMinX ?? drawing.minX : drawing.minX,
            minY: drawing.viewBoxMinY !== 0 ? drawing.viewBoxMinY ?? drawing.minY : drawing.minY,
            width: drawing.viewBoxWidth !== 0 ? drawing.viewBoxWidth ?? drawing.width : drawing.width,
            height: drawing.viewBoxHeight !== 0 ? drawing.viewBoxHeight ?? drawing.height : drawing.height,
        }

        // Apply a margin
        const viewBoxMargin = 0.02
        newViewBox.minX -= viewBoxMargin * newViewBox.width
        newViewBox.minY -= viewBoxMargin * newViewBox.height
        newViewBox.width *= 1 + 2 * viewBoxMargin
        newViewBox.height *= 1 + 2 * viewBoxMargin

        const drawingHasOpenPath = !!drawing.issues.find(
            (issue) => issue.issueType === 'OpenInner' || issue.issueType === 'OpenOuter'
        )
        setOriginalViewBox(newViewBox)
        setViewBox(newViewBox)
        setIsViewBoxDirty(true)
        setShowOpenPathCheckbox(drawingHasOpenPath)
    }, [drawing])

    const handleDrawingUpdate = (updatedDrawing) => {
        if (typeof onDrawingUpdated === 'function') {
            onDrawingUpdated(updatedDrawing.toDto())
        }
    }

    const handlePathsSelected = (pathIds, added) => {
        if (pathIds.length === 1) {
            const selectedPath = stateDrawing.paths[pathIds[0]]
            setSelectedItemDimensions({
                width: selectedPath.width,
                height: selectedPath.height,
                diameter: 0,
                length: 0,
            })
        } else {
            setSelectedItemDimensions({
                width: stateDrawing.width,
                height: stateDrawing.height,
                diameter: 0,
                length: 0,
            })
        }

        if (added) {
            const result = pathIds.reduce(function (res, item) {
                if (!res[item]) {
                    res[item] = stateDrawing.paths[item]
                } else {
                    // if we are adding paths, if the path already exists it gets deselected
                    delete res[item]
                }

                return res
            }, selectedPaths)
            setSelectedPaths(result)
        } else {
            setSelectedPaths(
                Object.fromEntries(Object.entries(stateDrawing.paths).filter(([k, _]) => pathIds.includes(k)))
            )
        }
    }

    const zoom = (zf, centerPoint) => {
        const totalZoom = totalZoomFactor * zf
        if (totalZoom > maxZoomFactor) {
            setTotalZoomFactor(maxZoomFactor)
            return
        }

        const newViewBox = {
            minX: centerPoint.x - (centerPoint.x - viewBox.minX) / zf,
            minY: -1 * ((-1 * viewBox.minY + centerPoint.y) / zf - centerPoint.y),
            width: viewBox.width / zf,
            height: viewBox.height / zf,
        }

        setTotalZoomFactor(totalZoom)
        setViewBox(newViewBox)
    }

    const handleDrawingWheelScroll = (event, userCoordinates) => {
        const zf = -1 * event.deltaY < 0 ? 1 / wheelZoomFactor : wheelZoomFactor

        zoom(zf, userCoordinates)
    }

    const handleDrawingMouseMove = (event, userCoordinates) => {
        setUserMousePosition(userCoordinates)
    }

    const getViewBoxCenterPoint = () => {
        if (!viewBox) {
            return {
                x: 0,
                y: 0,
            }
        }

        return {
            x: viewBox.minX + viewBox.width / 2,
            y: viewBox.minY + viewBox.height / 2,
        }
    }

    const handleZoomInClick = () => {
        const viewBoxCenter = getViewBoxCenterPoint()

        zoom(clickZoomFactor, viewBoxCenter)
    }

    const handleZoomOutClick = () => {
        const viewBoxCenter = getViewBoxCenterPoint()

        zoom(1 / clickZoomFactor, viewBoxCenter)
    }

    const handleZoomFitClick = () => {
        setTotalZoomFactor(1)
        setViewBox(originalViewBox)
    }

    const handleRemoveNonSolidLinesClick = () => {
        const updatedDrawing = new Drawing(stateDrawing)

        for (const k in updatedDrawing.paths) {
            if (!updatedDrawing.paths[k].isSolid) {
                updatedDrawing.deletePath(k)
            }
        }

        setStateDrawing(updatedDrawing)
        handleDrawingUpdate(updatedDrawing)
    }

    const handleRemoveOpenInnersClick = () => {
        const updatedDrawing = new Drawing(stateDrawing)

        for (const k in updatedDrawing.paths) {
            if (!updatedDrawing.paths[k].isClosed) {
                updatedDrawing.deletePath(k)
            }
        }

        setStateDrawing(updatedDrawing)
        handleDrawingUpdate(updatedDrawing)
    }

    const extractPathToDrawingDto = (path, name) => {
        const internalPaths = []
        for (let i = 0; i < path.enclosesIds.length; i++) {
            const p = stateDrawing.paths[path.enclosesIds[i]]
            internalPaths.push(p)
        }

        const newDrawing = Drawing.drawingFromOuterPath(name, path, internalPaths)

        return newDrawing.toDto()
    }

    const changeSelectedEntitiesToLayer = (layerName) => {
        const selectedPathKeys = Object.keys(selectedPaths)

        const updatedDrawing = new Drawing(stateDrawing)

        selectedPathKeys.forEach((key) => {
            updatedDrawing.paths[key].layer.type = layerName
            updatedDrawing.paths[key].entities.forEach((entity) => {
                entity.layer.type = layerName
            })
        })

        setStateDrawing(updatedDrawing)
        handleDrawingUpdate(updatedDrawing)
    }

    const changeSelectedEntitiesToCutting = () => {
        changeSelectedEntitiesToLayer('Cutting')
    }

    const changeSelectedEntitiesToEtching = () => {
        changeSelectedEntitiesToLayer('Etching')
    }

    const changeSelectedEntitiesToFolding = () => {
        changeSelectedEntitiesToLayer('Folding')
    }

    const changeSelectedEntitiesToInformation = () => {
        changeSelectedEntitiesToLayer('Information')
    }

    const hasRemainingPaths = (extractedPathIds, outerPathIds) => {
        // extracted paths must be at least equal to outer paths if all paths are to be removed
        if (extractedPathIds.length >= outerPathIds.length) {
            for (const pathId of outerPathIds) {
                //if we can't find an outer path in our list of extracted parts there will be paths remaining in drawing
                if (!extractedPathIds.find((id) => id === pathId)) {
                    return true
                }
            }
            // if all outer paths exist in the extracted paths then we know no parts will remain
            return false
        }
        return true
    }

    const handleExtractSelectedPaths = (name) => {
        if (numberOfSelectedPaths > 0) {
            let count = 1

            const pathKeys = Object.keys(selectedPaths)
            const pathsToExtract = []

            pathKeys.forEach((key) => {
                const path = stateDrawing.paths[key]

                const partName = name + ` ${count}`
                count += 1

                const drawingDto = extractPathToDrawingDto(path, partName)
                pathsToExtract.push(drawingDto)
            })
            const deleteOriginalPart = !hasRemainingPaths(pathKeys, stateDrawing.partOuterPathIds)
            if (pathsToExtract.length > 0 && typeof onDrawingPartsExtracted === 'function') {
                onDrawingPartsExtracted(pathsToExtract, part, deleteOriginalPart, () =>
                    handleDeleteSelectedAndInsideClick()
                )
            }
        }
    }

    const handleExtractAllPaths = (namePrefix) => {
        let count = 1
        const extractedDrawings = []

        for (const k in stateDrawing.paths) {
            const path = stateDrawing.paths[k]
            if (!path.isText() && !path.layer.isInfo() && path.isOuter()) {
                const partName = namePrefix + ` ${count}`
                count += 1

                const drawingDto = extractPathToDrawingDto(path, partName)
                extractedDrawings.push(drawingDto)
            }
        }

        if (extractedDrawings.length > 0 && typeof onAllDrawingPartsExtracted === 'function') {
            onAllDrawingPartsExtracted(extractedDrawings)
        }
    }

    const handleSelectedPathExtractionSubmit = (name) => {
        handleExtractSelectedPaths(name)
        setShowExtractPartDialog(false)
    }

    const handleAllPathExtractionSubmit = (name) => {
        handleExtractAllPaths(name)
        setShowExtractAllPartsDialog(false)
    }

    const handleExtractSelectedClick = () => {
        setShowExtractPartDialog(true)
    }

    const handleExtractAllPartsClick = () => {
        setShowExtractAllPartsDialog(true)
    }

    const handleDeleteSelectedClick = () => {
        const updatedDrawing = new Drawing(stateDrawing)

        for (const k in selectedPaths) {
            updatedDrawing.deletePath(k)
        }

        setSelectedPaths({})
        setStateDrawing(updatedDrawing)
        handleDrawingUpdate(updatedDrawing)
    }

    const handleDeleteSelectedAndInsideClick = () => {
        const updatedDrawing = new Drawing(stateDrawing)

        for (const k in selectedPaths) {
            const pathId = updatedDrawing.paths[k].pathId

            updatedDrawing.deleteInsidePath(pathId)
            updatedDrawing.deletePath(pathId)
        }

        setSelectedPaths({})
        setStateDrawing(updatedDrawing)
        handleDrawingUpdate(updatedDrawing)
    }

    const handleDeleteInsideSelectedClick = () => {
        if (numberOfSelectedPaths === 1) {
            const updatedDrawing = new Drawing(stateDrawing)
            const k = Object.keys(selectedPaths)[0]
            const pathId = updatedDrawing.paths[k].pathId

            updatedDrawing.deleteInsidePath(pathId)

            setSelectedPaths({})
            setStateDrawing(updatedDrawing)
            handleDrawingUpdate(updatedDrawing)
        }
    }

    const handleDeleteOutsideSelectedClick = () => {
        if (numberOfSelectedPaths === 1) {
            const updatedDrawing = new Drawing(stateDrawing)
            const k = Object.keys(selectedPaths)[0]
            const pathId = updatedDrawing.paths[k].pathId

            updatedDrawing.deleteOutsidePath(pathId)

            setSelectedPaths({})
            setStateDrawing(updatedDrawing)
            handleDrawingUpdate(updatedDrawing)
        }
    }

    const handleDrawingRightClick = (event) => {
        event.preventDefault()

        if (numberOfSelectedPaths > 0) {
            setContextMenuAnchorPosition({ left: event.clientX, top: event.clientY })
        }
    }

    const handleContextMenuClose = () => {
        setContextMenuAnchorPosition(null)
    }

    const hideShowExtractPartDialog = () => {
        setShowExtractPartDialog(false)
    }

    const hideShowExtractAllPartDialog = () => {
        setShowExtractAllPartsDialog(false)
    }

    const handleShowOpenPathHighlight = () => {
        setShowOpenPathHighlight(!showOpenPathHighlight)
    }

    const handleShowLegendClick = () => {
        setShowLegend(!showLegend)
    }

    // Keyboard shortcuts
    useKeyboardShortcut(['control', 'z'], onUndoClick)
    useKeyboardShortcut(['meta', 'z'], onUndoClick)
    useKeyboardShortcut(['control', 'y'], onRedoClick)
    useKeyboardShortcut(['meta', 'shift', 'z'], onRedoClick)

    const handleViewChange = (event, newView) => {
        setShowCad(newView)
    }

    const isCadViewVisible = (showCad && showCadViewToggle) || showCadView

    const isPartFlat = part?.profile === undefined || part?.profile === null || part?.profile === ''

    return (
        <LoadingOverlay
            active={showLoadingOverlay}
            className={classes.loadingOverlay}
            showLoadingLabel={false}
        >
            <TransformWrapper>
                {({ resetTransform, zoomIn, zoomOut, ...rest }) => (
                    <Box
                        ref={containerRef}
                        sx={classes.container}
                    >
                        {!webStoreMode ? (
                            <Toolbar sx={classes.toolbar}>
                                {showCadViewToggle ? (
                                    <ToggleButtonGroup
                                        data-testid="part-toolbar-button-toggle-cad-view"
                                        value={showCad}
                                        exclusive
                                        onChange={handleViewChange}
                                    >
                                        <ToggleButton value={false}>{t('Cut Path')}</ToggleButton>
                                        <ToggleButton value={true}>
                                            {isPartFlat ? t('Original CAD') : t('3D Model')}
                                        </ToggleButton>
                                    </ToggleButtonGroup>
                                ) : null}
                                <Menu title={t('Zoom')}>
                                    <MenuItem onClick={isCadViewVisible && !isPartFlat ? zoomIn : handleZoomInClick}>
                                        {t('Zoom in')}
                                    </MenuItem>
                                    <MenuItem onClick={isCadViewVisible && !isPartFlat ? zoomOut : handleZoomOutClick}>
                                        {t('Zoom out')}
                                    </MenuItem>
                                    <MenuItem
                                        onClick={isCadViewVisible && !isPartFlat ? resetTransform : handleZoomFitClick}
                                    >
                                        {t('Zoom fit')}
                                    </MenuItem>
                                </Menu>
                                {!isCadViewVisible && isPartFlat ? (
                                    <Menu
                                        disabled={disabled}
                                        title={t('Remove')}
                                    >
                                        <MenuItem onClick={handleRemoveNonSolidLinesClick}>
                                            {t('Non-solid lines')}
                                        </MenuItem>
                                        <MenuItem onClick={handleRemoveOpenInnersClick}>{t('Open inners')}</MenuItem>
                                    </Menu>
                                ) : null}
                                {!isCadViewVisible && isPartFlat ? (
                                    <Menu
                                        className="drawing-cleaner-toolbar-extract"
                                        disabled={disabled}
                                        title={t('Extract')}
                                    >
                                        <MenuItem onClick={handleExtractSelectedClick}>
                                            {t('Extract selected')}
                                        </MenuItem>
                                        <MenuItem
                                            className="drawing-cleaner-menu-extract-all"
                                            onClick={handleExtractAllPartsClick}
                                        >
                                            {t('Extract all parts in drawing')}
                                        </MenuItem>
                                    </Menu>
                                ) : null}
                                {!isCadViewVisible && isPartFlat ? (
                                    <Menu
                                        disabled={disabled}
                                        title={t('Delete')}
                                    >
                                        <MenuItem onClick={handleDeleteSelectedClick}>{t('Delete selected')}</MenuItem>
                                        <MenuItem onClick={handleDeleteSelectedAndInsideClick}>
                                            {t('Delete selected and paths inside')}
                                        </MenuItem>
                                        <MenuItem onClick={handleDeleteInsideSelectedClick}>
                                            {t('Delete paths inside selected')}
                                        </MenuItem>
                                        <MenuItem onClick={handleDeleteOutsideSelectedClick}>
                                            {t('Delete paths outside selected')}
                                        </MenuItem>
                                    </Menu>
                                ) : null}
                                {!isCadViewVisible && isPartFlat ? (
                                    <Menu
                                        disabled={disabled}
                                        title={t('Line Entity')}
                                    >
                                        <MenuItem onClick={changeSelectedEntitiesToCutting}>
                                            {t('Set to cutting')}
                                        </MenuItem>
                                        <MenuItem onClick={changeSelectedEntitiesToEtching}>
                                            {t('Set to etching')}
                                        </MenuItem>
                                        <MenuItem onClick={changeSelectedEntitiesToInformation}>
                                            {t('Set to information')}
                                        </MenuItem>
                                        <MenuItem onClick={changeSelectedEntitiesToFolding}>
                                            {t('Set to folding')}
                                        </MenuItem>
                                    </Menu>
                                ) : null}
                                {allowUndoRedo && isPartFlat ? (
                                    <>
                                        <Button onClick={onUndoClick}>{t('Undo')}</Button>
                                        <Button onClick={onRedoClick}>{t('Redo')}</Button>
                                    </>
                                ) : null}
                                <HorizontalSpacer />
                                {showOpenPathCheckbox ? (
                                    <>
                                        <Checkbox
                                            checked={showOpenPathHighlight}
                                            onChange={handleShowOpenPathHighlight}
                                        />
                                        <Box sx={classes.checkboxLabel}>{t('Show Endpoints')}</Box>
                                    </>
                                ) : null}
                                <Checkbox
                                    checked={showLegend}
                                    onChange={handleShowLegendClick}
                                />
                                <Box sx={classes.checkboxLabel}>{t('Show legend')}</Box>
                            </Toolbar>
                        ) : null}
                        <Box
                            sx={classes.drawingContainer}
                            onContextMenu={!webStoreMode ? handleDrawingRightClick : undefined}
                        >
                            {showLegendProp || (showLegend && !webStoreMode) ? <PathTypeLegend /> : null}
                            {webStoreMode ? (
                                <ZoomControls
                                    onZoomIn={isCadViewVisible && !isPartFlat ? zoomIn : handleZoomInClick}
                                    onZoomOut={isCadViewVisible && !isPartFlat ? zoomOut : handleZoomOutClick}
                                    onZoomReset={isCadViewVisible && !isPartFlat ? resetTransform : handleZoomFitClick}
                                />
                            ) : null}
                            {isCadViewVisible && part?.modelViewUri ? (
                                <TransformComponent>
                                    <Box
                                        component="img"
                                        src={part?.modelViewUri}
                                        style={{
                                            width: '100%',
                                        }}
                                    />
                                </TransformComponent>
                            ) : (
                                <>
                                    <TransformComponent></TransformComponent>
                                    <ClassNames>
                                        {({ css }) => (
                                            <DrawingRenderer
                                                drawing={stateDrawing}
                                                originalCadSvg={originalCadSvg}
                                                selectAreaClassName={css(classes.selectArea(theme))}
                                                selectedPaths={selectedPaths}
                                                showCad={isCadViewVisible}
                                                showOpenPathHighlight={
                                                    showOpenPathCheckbox ? showOpenPathHighlight : null
                                                }
                                                viewBox={viewBox}
                                                onMouseMove={handleDrawingMouseMove}
                                                onPathsSelected={handlePathsSelected}
                                                onWheel={handleDrawingWheelScroll}
                                            />
                                        )}
                                    </ClassNames>
                                </>
                            )}
                        </Box>
                        {!webStoreMode ? (
                            <DrawingCleanerFooter
                                className={classes.selectionDetailsContainer}
                                diameter={part?.diameter}
                                heightValue={selectedItemDimensions.height}
                                isPartFlat={isPartFlat}
                                isRotary={!!part?.profile}
                                numberDecimalPlaces={footerDecimalPlaces}
                                partLength={part?.rotaryPartLength}
                                pointerPosition={userMousePosition}
                                profileLength={part?.rotaryProfileLength}
                                profileWidth={part?.rotaryProfileWidth}
                                widthValue={selectedItemDimensions.width}
                            />
                        ) : null}
                        <ExtractPartDialog
                            initialName={stateDrawing.name}
                            multiple={false}
                            open={showExtractPartDialog}
                            onClose={hideShowExtractPartDialog}
                            onSubmit={handleSelectedPathExtractionSubmit}
                        />
                        <ExtractPartDialog
                            initialName={stateDrawing.name}
                            multiple={true}
                            open={showExtractAllPartsDialog}
                            onClose={hideShowExtractAllPartDialog}
                            onSubmit={handleAllPathExtractionSubmit}
                        />
                        {isPartFlat ? (
                            <Popover
                                anchorPosition={contextMenuAnchorPosition}
                                anchorReference="anchorPosition"
                                open={Boolean(contextMenuAnchorPosition)}
                                onClick={handleContextMenuClose}
                                onClose={handleContextMenuClose}
                                onContextMenu={handleDrawingRightClick}
                            >
                                {numberOfSelectedPaths >= 1 ? (
                                    <MenuItem onClick={handleExtractSelectedClick}>{t('Extract selected')}</MenuItem>
                                ) : null}
                                <MenuItem onClick={handleDeleteSelectedClick}>{t('Delete selected')}</MenuItem>
                                <MenuItem onClick={handleDeleteSelectedAndInsideClick}>
                                    {t('Delete selected and paths inside')}
                                </MenuItem>
                                {numberOfSelectedPaths === 1 ? (
                                    <MenuItem onClick={handleDeleteInsideSelectedClick}>
                                        {t('Delete paths inside selected')}
                                    </MenuItem>
                                ) : null}
                                {numberOfSelectedPaths === 1 ? (
                                    <MenuItem onClick={handleDeleteOutsideSelectedClick}>
                                        {t('Delete paths outside selected')}
                                    </MenuItem>
                                ) : null}
                                {numberOfSelectedPaths > 0 ? (
                                    <MenuItem onClick={changeSelectedEntitiesToCutting}>{t('Set to cutting')}</MenuItem>
                                ) : null}
                                {numberOfSelectedPaths > 0 ? (
                                    <MenuItem onClick={changeSelectedEntitiesToEtching}>{t('Set to etching')}</MenuItem>
                                ) : null}
                                {numberOfSelectedPaths > 0 ? (
                                    <MenuItem onClick={changeSelectedEntitiesToInformation}>
                                        {t('Set to information')}
                                    </MenuItem>
                                ) : null}
                                {numberOfSelectedPaths > 0 ? (
                                    <MenuItem onClick={changeSelectedEntitiesToFolding}>{t('Set to folding')}</MenuItem>
                                ) : null}
                            </Popover>
                        ) : null}
                    </Box>
                )}
            </TransformWrapper>
        </LoadingOverlay>
    )
}

DrawingCleaner.propTypes = {
    allowUndoRedo: PropTypes.bool,
    disabled: PropTypes.bool,
    drawing: PropTypes.object,
    footerDecimalPlaces: PropTypes.number,
    nqp: PropTypes.bool,
    organisationId: PropTypes.string,
    originalCadSvg: PropTypes.oneOfType([PropTypes.string, PropTypes.object]),
    part: PropTypes.object,
    showCadView: PropTypes.bool,
    showCadViewToggle: PropTypes.bool,
    showLegendProp: PropTypes.bool,
    showLoadingOverlay: PropTypes.bool,
    theme: PropTypes.bool,
    webStoreMode: PropTypes.bool,
    onAllDrawingPartsExtracted: PropTypes.func,
    onDrawingPartsExtracted: PropTypes.func,
    onDrawingUpdated: PropTypes.func,
    onRedoClick: PropTypes.func,
    onUndoClick: PropTypes.func,
}

export default DrawingCleaner
