import { useTranslation } from 'react-i18next'
import PropTypes from 'prop-types'

import { AlertDialog } from '@/common/components'

const DeleteLayerAlertDialog = ({ layerName, onCancelClose, onOkClose, open = false }) => {
    const { t } = useTranslation()

    const titleText = t('Delete {{layerName}} layer', { layerName: layerName })
    const alertContent = t('Are you sure you want to delete this layer? This will remove all paths on the layer.')

    return (
        <AlertDialog
            content={alertContent}
            open={open}
            title={titleText}
            onCancelClose={onCancelClose}
            onOkClose={onOkClose}
        />
    )
}

DeleteLayerAlertDialog.propTypes = {
    layerName: PropTypes.string.isRequired,
    open: PropTypes.bool,
    onCancelClose: PropTypes.func,
    onOkClose: PropTypes.func,
}

export default DeleteLayerAlertDialog
