import { createTheme } from '@mui/material/styles'

const storeThemeOverride = (outerTheme, storeSettings) =>
    createTheme({
        ...outerTheme,
        palette: {
            ...outerTheme.palette,
            primary: {
                main: storeSettings?.webStorePrimaryColour ?? outerTheme.palette.primary.main,
            },
            secondary: {
                main: storeSettings?.webStoreSecondaryColour ?? outerTheme.palette.secondary.main,
            },
        },
    })

export { storeThemeOverride }
