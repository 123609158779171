import { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useSelector } from 'react-redux'
import { useParams } from 'react-router-dom'
import { Edit, Save, Undo } from '@mui/icons-material'
import { Box, IconButton, TextField, Typography } from '@mui/material'
import { useSnackbar } from 'notistack'
import PropTypes from 'prop-types'

import { useUpdateQuoteItemsAttributesMutation } from '@/app/services/quoteItems'
import { selectOrganisationId } from '@/app/slices/organisationSlice'

const ItemName = ({ id, isPartFromLibrary, name }) => {
    const [isEditing, setIsEditing] = useState(false)
    const [itemName, setItemName] = useState(name)
    const { t } = useTranslation()
    const { enqueueSnackbar } = useSnackbar()
    const { quoteId } = useParams()

    const [updateQuoteItems, { isLoading: isUpdating }] = useUpdateQuoteItemsAttributesMutation()
    const organisationId = useSelector((state) => selectOrganisationId(state))

    useEffect(() => {
        setItemName(name)
    }, [name])

    const handleUpdateQuoteItem = async (attributes) => {
        const quoteItems = {
            quoteItemIds: [id],
            attributes,
        }
        try {
            const result = await updateQuoteItems({
                organisationId,
                quoteId,
                quoteItems,
            }).unwrap()
            enqueueSnackbar(t('$t(Quote) item updated'), { variant: 'success' })
            return result
        } catch (error) {
            enqueueSnackbar(t('Failed to update the $t(quote) item'), {
                variant: 'error',
            })
        }
    }

    const handleItemNameChange = (event) => {
        const value = event.target.value
        setItemName(value)
    }

    const enableEditing = (event) => {
        event.stopPropagation()
        setIsEditing(true)
    }
    const saveEditing = async (event) => {
        event.stopPropagation()
        if (itemName !== name) {
            await handleUpdateQuoteItem({ name: itemName })
        }
        setIsEditing(false)
    }

    const reset = (event) => {
        event.stopPropagation()
        setItemName(name)
        setIsEditing(false)
    }

    const onKeyDown = (event) => {
        if (event?.key === 'Enter') saveEditing(event)
    }

    return (
        <Box>
            {isEditing ? (
                <>
                    <TextField
                        key={`name-input-${id}`}
                        value={itemName}
                        onChange={handleItemNameChange}
                        onClick={(event) => event.stopPropagation()}
                        onKeyDown={onKeyDown}
                    />

                    <IconButton
                        color="secondary"
                        data-testid="save-quote-item-name"
                        disabled={isUpdating || !itemName}
                        onClick={saveEditing}
                    >
                        <Save />
                    </IconButton>
                    <IconButton
                        color="secondary"
                        data-testid="undo-quote-item-name"
                        disabled={isUpdating}
                        onClick={reset}
                    >
                        <Undo />
                    </IconButton>
                </>
            ) : (
                <Box
                    alignItems="center"
                    display="flex"
                >
                    <Typography variant="body1">{itemName}</Typography>
                    {!isPartFromLibrary ? (
                        <IconButton
                            color="secondary"
                            data-testid="edit-quote-item-name"
                            onClick={enableEditing}
                        >
                            <Edit />
                        </IconButton>
                    ) : null}
                </Box>
            )}
        </Box>
    )
}

ItemName.propTypes = {
    id: PropTypes.string,
    isPartFromLibrary: PropTypes.bool,
    name: PropTypes.string,
}

export default ItemName
