import { useEffect, useMemo } from 'react'
import { useTranslation } from 'react-i18next'
import { NumericFormat } from 'react-number-format'
import { useSelector } from 'react-redux'
import { useParams } from 'react-router-dom'
import { Box, FormControl, MenuItem, OutlinedInput, Select, TextField, Typography } from '@mui/material'
import PropTypes from 'prop-types'

import { selectQuoteItems } from '@/app/services/quoteItems'
import { selectOrganisationId } from '@/app/slices/organisationSlice'
import { selectSelectedItems } from '@/app/slices/quoteItemsSlice'
import ProcessesIcon from '@/common/icons/ProcessesIcon/ProcessesIcon'

const classes = {
    foldingSection: {
        width: '100%',
        gap: '12px',
    },
    bold: {
        fontWeight: 'bold',
    },
    itemColumn: {
        display: 'flex',
        flex: 1,
        fontSize: '14px',
    },
    formControl_medium: {
        width: '120px',
    },
    formControl_small: {
        width: '80px',
    },
}

const ItemRow = ({ children }) => (
    <Box
        alignItems="center"
        display="flex"
        flexDirection="row"
        justifyContent="space-between"
        width="100%"
    >
        {children}
    </Box>
)

const FoldingBulkEdit = ({
    itemMaxLength,
    itemNumberOfFolds,
    itemSource,
    itemUnit,
    setItemMaxLength,
    setItemNumberOfFolds,
    setItemSource,
    setUserMadeFoldingChanges,
}) => {
    const { t } = useTranslation()
    const { quoteId } = useParams()

    const organisationId = useSelector(selectOrganisationId)
    const quoteItems = useSelector((state) => selectQuoteItems(state, { organisationId, quoteId }))
    const selectedItemsArray = useSelector(selectSelectedItems)

    const quoteItemsFromSelection = useMemo(
        () => quoteItems.filter((item) => selectedItemsArray.includes(item.id)),
        [quoteItems, selectedItemsArray]
    )

    const handleSourceChange = async (event) => {
        const value = event?.target?.value
        if (Boolean(value) && itemSource !== value) {
            setItemSource(value)
            setUserMadeFoldingChanges(true)
        }
    }

    const handleMaxLengthChange = (values) => {
        const { floatValue } = values
        setItemMaxLength(floatValue)
        setUserMadeFoldingChanges(true)
    }

    const handleNumberOfFoldsChange = (values) => {
        const { floatValue } = values
        setItemNumberOfFolds(floatValue)
        setUserMadeFoldingChanges(true)
    }

    const allItemsHaveSameProperty = (array, property) => {
        return array.every((element) => element[property] === array[0][property])
    }

    useEffect(() => {
        if (!quoteItemsFromSelection.length) return

        if (allItemsHaveSameProperty(quoteItemsFromSelection, 'foldingSource')) {
            setItemSource(quoteItemsFromSelection[0].foldingSource)
        }

        if (allItemsHaveSameProperty(quoteItemsFromSelection, 'numberOfFolds')) {
            setItemNumberOfFolds(quoteItemsFromSelection[0].numberOfFolds)
        }

        if (allItemsHaveSameProperty(quoteItemsFromSelection, 'maxFoldLength')) {
            setItemMaxLength(quoteItemsFromSelection[0].maxFoldLength)
        }
    }, [quoteItemsFromSelection])

    return (
        <Box
            alignItems="flex-start"
            display="flex"
            flex="1"
            flexDirection="column"
            sx={classes.foldingSection}
        >
            <ItemRow>
                <Box
                    alignItems="center"
                    display="flex"
                    flexDirection="row"
                    gap={1}
                >
                    <ProcessesIcon fontSize="small" />
                    <Typography
                        data-testid="bulk-edit-folding-section-title"
                        sx={classes.bold}
                    >
                        {t('Folding')}
                    </Typography>
                </Box>
            </ItemRow>
            <ItemRow>
                <Typography
                    data-testid="nqp-quote-item-source-label"
                    sx={classes.itemColumn}
                >
                    {t('Source')}
                </Typography>
                <FormControl
                    color="primary"
                    margin="none"
                    size="small"
                    sx={classes.formControl_medium}
                    variant="outlined"
                >
                    <Select
                        data-testid="nqp-quote-item-source"
                        id="select-itemSource"
                        input={<OutlinedInput sx={{ typography: 'inputTextSmall' }} />}
                        labelId="select-itemSource-label"
                        SelectDisplayProps={{ style: { textAlign: 'right' } }}
                        value={itemSource}
                        onBlur={handleSourceChange}
                        onChange={handleSourceChange}
                    >
                        <MenuItem
                            key={'Drawing'}
                            value={'Drawing'}
                        >
                            {t('Drawing')}
                        </MenuItem>
                        <MenuItem
                            key={'Manual'}
                            value={'Manual'}
                        >
                            {t('Manual')}
                        </MenuItem>
                    </Select>
                </FormControl>
            </ItemRow>
            <ItemRow>
                <Typography
                    data-testid="nqp-quote-item-max-length-label"
                    sx={classes.itemColumn}
                >
                    {t('Max length')}
                </Typography>
                <NumericFormat
                    allowNegative={false}
                    customInput={TextField}
                    disabled={itemSource === 'Drawing' || !itemUnit}
                    inputProps={{
                        style: { textAlign: 'right' },
                        'data-testid': 'nqp-quote-item-max-length-quantity',
                    }}
                    placeholder={`0 ${itemUnit}`}
                    size="small"
                    suffix={` ${itemUnit}`}
                    sx={classes.formControl_medium}
                    value={itemSource === 'Drawing' ? '' : itemMaxLength}
                    variant="outlined"
                    thousandSeparator
                    onValueChange={handleMaxLengthChange}
                />
            </ItemRow>
            <ItemRow>
                <Typography
                    data-testid="nqp-quote-item-number-of-folds-label"
                    sx={classes.itemColumn}
                >
                    {t('Number of folds')}
                </Typography>
                <NumericFormat
                    allowNegative={false}
                    customInput={TextField}
                    disabled={itemSource === 'Drawing'}
                    inputProps={{
                        style: { textAlign: 'right' },
                        'data-testid': 'nqp-quote-item-number-of-folds-quantity',
                    }}
                    placeholder="0"
                    size="small"
                    sx={classes.formControl_small}
                    value={itemSource === 'Drawing' ? '' : itemNumberOfFolds}
                    variant="outlined"
                    fullWidth
                    thousandSeparator
                    onValueChange={handleNumberOfFoldsChange}
                />
            </ItemRow>
        </Box>
    )
}

FoldingBulkEdit.propTypes = {
    itemMaxLength: PropTypes.number,
    itemNumberOfFolds: PropTypes.number,
    itemSource: PropTypes.string,
    itemUnit: PropTypes.string,
    setItemMaxLength: PropTypes.func,
    setItemNumberOfFolds: PropTypes.func,
    setItemSource: PropTypes.func,
    setUserMadeFoldingChanges: PropTypes.func,
}

export default FoldingBulkEdit
