import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react'

import { getAccessToken } from '@/common/helpers/auth/getAccessToken'

const baseQuery = fetchBaseQuery({
    baseUrl: `${import.meta.env.VITE_QUICKPART_API_URL}`,
    prepareHeaders: async (headers) => {
        const { token } = await getAccessToken()

        if (token) {
            headers.set('authorization', `Bearer ${token}`)
        }
        return headers
    },
})

export const quickPartsApi = createApi({
    reducerPath: 'quickPartsApi',
    baseQuery,
    tagTypes: ['QuickParts'],
    endpoints: (builder) => ({
        getQuickParts: builder.query({
            query: (params) => ({
                url: '/parts',
                params,
            }),
            providesTags: ['QuickParts'],
        }),

        getQuickPartsDrawingDto: builder.query({
            query: ({ name, part }) => ({
                url: '/parts/drawing',
                method: 'POST',
                body: { part, name },
            }),
        }),

        getQuickPartsSvg: builder.query({
            query: (part) => ({
                url: '/parts/svg',
                method: 'POST',
                body: part,
                responseHandler: (response) => {
                    const svg = response.text()
                    return svg
                },
            }),
        }),
    }),
})

export const { useGetQuickPartsQuery, useGetQuickPartsSvgQuery, useLazyGetQuickPartsDrawingDtoQuery } = quickPartsApi
