import { useTranslation } from 'react-i18next'
import { useSelector } from 'react-redux'
import { Button } from '@mui/material'
import PropTypes from 'prop-types'

import { useSetIntegrationFlagsMutation } from '@/app/services/integrations'
import { selectOrganisation } from '@/app/slices/organisationSlice'
import { TbxTooltip } from '@/common/components'
import UpgradePlanLink from '@/common/components/UpgradePlanLink/UpgradePlanLink'
import * as ParagonService from '@/common/services/ParagonService'

const ParagonConnectIntegration = ({ disabled, type }) => {
    const { t } = useTranslation()

    const [setIntegrationFlags] = useSetIntegrationFlagsMutation()
    const organisation = useSelector(selectOrganisation)

    const successCallback = async () => {
        const organisationDto =
            type === 'xero'
                ? {
                      ...organisation,
                      hasXeroIntegration: true,
                  }
                : {
                      ...organisation,
                      hasQuickBooksIntegration: true,
                  }
        console.log({ organisationDto })
        await setIntegrationFlags({ organisationDto: organisationDto })
    }

    const handleConnectionClicked = () => {
        ParagonService.connect(type, {
            onSuccess: successCallback,
            onError: () => {},
        })
    }

    const paragonUser = ParagonService.getUser()

    const button = (
        <Button
            color="primary"
            disabled={disabled}
            variant="outlined"
            onClick={handleConnectionClicked}
        >
            {t(paragonUser?.integrations?.[type]?.enabled ? `Manage ${type}` : `Connect ${type}`)}
        </Button>
    )

    return disabled ? (
        <TbxTooltip title={<UpgradePlanLink />}>
            <span>{button}</span>
        </TbxTooltip>
    ) : (
        button
    )
}

ParagonConnectIntegration.propTypes = {
    disabled: PropTypes.bool,
    type: PropTypes.oneOf(['xero', 'quickbooks']),
}

export default ParagonConnectIntegration
