const geocode = async (params) => {
    const geocoder = new window.google.maps.Geocoder()
    return new Promise((resolve, _) => {
        geocoder.geocode(params, (results, _) => {
            resolve(results[0])
        })
    })
}

export const hasDeliveryForAddress = async (deliveryRegions, addressPlaceId) => {
    const placeIds = deliveryRegions.map((region) => region.placeId)
    for (const placeId of placeIds) {
        const hasDelivery = await regionContainsAddress(placeId, addressPlaceId)
        if (hasDelivery) return true
    }
    return false
}

export const regionContainsAddress = async (regionPlaceId, addressPlaceId) => {
    const regionDetails = await geocode({ placeId: regionPlaceId })
    const addressDetails = await geocode({ placeId: addressPlaceId })
    return regionDetails.geometry.bounds.contains(addressDetails.geometry.location)
}

export const getPlaceIdFromDetails = async (address, city, state, country, postCode) => {
    const fullDetails = `${address}, ${city}, ${state}, ${country}, ${postCode}`
    const place = await geocode({ address: fullDetails })
    return place?.place_id
}

export const getAddressDetails = (address) => {
    if (address && typeof address === 'string') return address

    if (address && typeof address === 'object') {
        const addressAttributes = [
            address.line1,
            address.line2,
            address.city,
            address.state,
            address.country,
            address.postcode,
        ]
        const nonEmptyKeys = addressAttributes.filter((attribute) => attribute !== undefined && attribute !== '')
        return nonEmptyKeys.join(', ')
    }
    return ''
}

export const getAddressDetailsFromAddressComponents = (addressDetails) => {
    const address = addressDetails?.address_components
    const streetNumber = address.find((component) => component.types.includes('street_number'))?.long_name
    const street = address.find((component) => component.types.includes('route'))?.long_name
    const neighborhood = address.find((component) => component.types.includes('neighborhood'))?.long_name
    const locality = address.find((component) => component.types.includes('locality'))?.long_name
    const sublocality = address.find((component) => component.types.includes('sublocality_level_1'))?.long_name
    const postalTown = address.find((component) => component.types.includes('postal_town'))?.long_name
    const city = address.find((component) => component.types.includes('administrative_area_level_2'))?.long_name
    const state = address.find((component) => component.types.includes('administrative_area_level_1'))?.long_name
    const country = address.find((component) => component.types.includes('country'))?.long_name
    const postCode = address.find((component) => component.types.includes('postal_code'))?.long_name

    const getAddress = () => {
        if (street && streetNumber && sublocality) return `${streetNumber} ${street}, ${sublocality}`
        if (street && streetNumber) return `${streetNumber} ${street}`
        if (street) return street
        return ''
    }

    const getCity = () => {
        if (['Chile'].some((c) => country.includes(c))) return city || locality
        if (locality) return locality
        if (postalTown) return postalTown
        if (neighborhood) return neighborhood
        return ''
    }

    const getState = () => {
        if (['United Kingdom'].some((c) => country.includes(c))) return postalTown || state
        if (state) return state
        return ''
    }

    return {
        addressLine1: getAddress(),
        city: getCity(),
        state: getState(),
        country,
        postCode,
    }
}
