import { useEffect, useMemo, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useSelector } from 'react-redux'
import { useParams } from 'react-router-dom'
import { Box, Typography } from '@mui/material'
import { useSnackbar } from 'notistack'
import PropTypes from 'prop-types'
import { useDebouncedCallback } from 'use-debounce'

import { useGetQuoteItemsQuery, useUpdateQuoteItemMutation } from '@/app/services/quoteItems'
import { useGetSecondaryProcessesQuery } from '@/app/services/secondaryProcesses'
import { selectOrganisationId } from '@/app/slices/organisationSlice'

import QuantitySecProcess from '../../../QuoteItemList/ItemContent/SecondaryProcesses/QuantitySecProcess'

const SecondaryProcesses = ({ quoteItemId }) => {
    const { t } = useTranslation()
    const { enqueueSnackbar } = useSnackbar()
    const { quoteId } = useParams()

    const organisationId = useSelector(selectOrganisationId)

    const { quoteItem } = useGetQuoteItemsQuery(
        {
            organisationId,
            quoteId,
        },
        {
            selectFromResult: ({ data }) => ({
                quoteItem: data?.find((item) => item.id === quoteItemId),
            }),
        }
    )

    const [itemSecondaryProcesses, setItemSecondaryProcesses] = useState([])

    const { data: organisationSecondaryProcesses } = useGetSecondaryProcessesQuery({ organisationId })

    const [updateQuoteItem] = useUpdateQuoteItemMutation()

    const isPartFromLibrary = Boolean(quoteItem?.partLibraryEntryId)

    const handleUpdateQuoteItem = async (quoteItem) => {
        try {
            await updateQuoteItem({
                organisationId,
                quoteId,
                quoteItemId: quoteItem?.id,
                quoteItem,
            }).unwrap()
        } catch (error) {
            enqueueSnackbar(t('Failed to update the $t(quote) item'), {
                variant: 'error',
            })
        }
    }

    const debounceHandleUpdateQuoteItem = useDebouncedCallback(handleUpdateQuoteItem, 1000)

    const ItemRow = ({ children }) => (
        <Box
            alignItems="center"
            display="flex"
            flexDirection="row"
            justifyContent="space-between"
            width="100%"
        >
            {children}
        </Box>
    )

    const onQuantityInputChange = async (process) => {
        debounceHandleUpdateQuoteItem.cancel()
        const processIds = itemSecondaryProcesses?.map((sp) => sp.secondaryProcessId)
        const processIndex = processIds.indexOf(process.secondaryProcessId)
        const newProcessList = [...itemSecondaryProcesses]
        newProcessList[processIndex] = process
        setItemSecondaryProcesses(newProcessList)

        await debounceHandleUpdateQuoteItem({ ...quoteItem, secondaryProcesses: newProcessList })
    }

    const getProcessDetails = (processId) =>
        organisationSecondaryProcesses?.find((osp) => osp.secondaryProcessId === processId)

    const sortedProcesses = useMemo(() => {
        return itemSecondaryProcesses
            ?.map((process) => ({
                ...process,
                index: getProcessDetails(process.secondaryProcessId)?.index,
            }))
            .sort((a, b) => (a.index < b.index ? -1 : 1))
    }, [itemSecondaryProcesses])

    useEffect(() => {
        setItemSecondaryProcesses(quoteItem?.secondaryProcesses)
    }, [quoteItem?.secondaryProcesses])

    return (
        <Box
            display="flex"
            flexDirection="column"
            gap={2}
        >
            {sortedProcesses?.map((process) => (
                <ItemRow key={process.secondaryProcessId}>
                    <Typography
                        data-testid="nqp-quote-item-secondary-process-label"
                        variant="body1"
                    >
                        {getProcessDetails(process.secondaryProcessId)?.name}
                    </Typography>
                    {getProcessDetails(process.secondaryProcessId)?.measureType === 'Quantity' ? (
                        <QuantitySecProcess
                            isPartFromLibrary={isPartFromLibrary}
                            process={process}
                            onInputChange={onQuantityInputChange}
                        />
                    ) : null}
                </ItemRow>
            ))}
        </Box>
    )
}

SecondaryProcesses.propTypes = {
    quoteItemId: PropTypes.string,
}

export default SecondaryProcesses
