import { createSlice, isAnyOf } from '@reduxjs/toolkit'

import { getCurrencySymbol } from '@/common/utils'

import { organisationApi } from '../services/organisation'

const getPlanMetadataTypeReducer = (type) => {
    switch (type) {
        case 'number':
            return (acc, curr) => Math.max(parseInt(acc) || 0, parseInt(curr) || 0)
        default:
            return (acc, curr) => acc || curr === 'true'
    }
}

const PLAN_METADATA_TYPES = {
    '3d': 'boolean',
    customer_pricing: 'boolean',
    customer_supplied: 'boolean',
    fixed_price_parts: 'boolean',
    is_enterprise_plan: 'boolean',
    material_consumption: 'boolean',
    materials_revamp: 'boolean',
    max_users: 'number',
    miscellaneous_items: 'boolean',
    part_library: 'boolean',
    pdf: 'boolean',
    plan: 'free',
    quote_history: 'boolean',
    sells_drawing_credits: 'boolean',
    tube: 'boolean',
    webstore: 'boolean',
    showViewer3D: 'boolean',
    showViewerTube: 'boolean',
}

const initialState = {
    organisation: {},
    organisationId: undefined,
    lastPaymentStatus: undefined,
    paidFeatures: undefined,
    useImperialUnits: undefined,
    currencySymbol: undefined,
    currencyCode: undefined,
    isOnTrial: false,
    reduceTrialAccess: false,
    planMetadata: undefined,
    resolvedPlanMetadata: undefined,
    isOnFreePlan: false,
    showAccelerationSettings: false,
    defaultTaxRateId: undefined,
    hasActiveSubscription: false,
}

export const organisationSlice = createSlice({
    name: 'organisationSlice',
    initialState,
    reducers: {
        setOrganisation: (state, { payload }) => {
            const isOnTrialValue = (payload?.trial && !payload?.trial.hasExpired) ?? false
            const isOnFreePlan = Boolean(payload?.freePlan)
            const reduceTrialAccessValue =
                ((payload?.trial &&
                    !payload?.trial?.hasExpired &&
                    !payload.hasActiveSubscription &&
                    !import.meta.env.VITE_RESELLER) ||
                    (payload?.freePlan && !payload.hasActiveSubscription && !import.meta.env.VITE_RESELLER)) ??
                false

            const metadata = payload?.subscriptions
                ?.map((s) => s.subscriptionItems)
                ?.reduce((array, current) => {
                    current.forEach((si) => array.push(si.price))
                    return array
                }, [])
                ?.reduce((map, curr) => {
                    if (curr && curr.metadata) {
                        const { $type, ...metadataDto } = curr.metadata
                        map[curr.priceId] = metadataDto
                    }
                    return map
                }, {})

            const resolvedMetadata = metadata
                ? Object.values(metadata).reduce((acc, currPlanMetadata) => {
                      return Object.keys(currPlanMetadata).reduce((innerAcc, metadataKey) => {
                          innerAcc[metadataKey] = getPlanMetadataTypeReducer(PLAN_METADATA_TYPES[metadataKey])(
                              innerAcc[metadataKey],
                              currPlanMetadata[metadataKey]
                          )
                          return innerAcc
                      }, acc)
                  }, {})
                : {}
            const paidFeatures = {
                isFreePlan: resolvedMetadata?.plan?.toString().toLowerCase() === 'free',
                hasMaterialConsumptionModeOptions:
                    isOnTrialValue || (payload?.hasActiveSubscription && resolvedMetadata?.material_consumption),
                hasCustomerPricing:
                    isOnTrialValue || (payload?.hasActiveSubscription && resolvedMetadata?.customer_pricing),
                hasAdvancedMaterials:
                    isOnTrialValue || (payload?.hasActiveSubscription && resolvedMetadata?.materials_revamp),
                hasCustomerSuppliedMaterial:
                    isOnTrialValue || (payload?.hasActiveSubscription && resolvedMetadata?.customer_supplied),
                hasCreditConsumption: true,
                hasUnfoldTube: isOnTrialValue || (payload?.hasActiveSubscription && resolvedMetadata?.tube),
                hasPdfUpload: isOnTrialValue || (payload?.hasActiveSubscription && resolvedMetadata?.pdf),
                hasPartLibrary: isOnTrialValue || (payload?.hasActiveSubscription && resolvedMetadata?.part_library),
                hasWebStore: isOnTrialValue || (payload?.hasActiveSubscription && resolvedMetadata?.webstore),
                has3d:
                    isOnTrialValue ||
                    (payload?.hasActiveSubscription && resolvedMetadata ? resolvedMetadata['3d'] : false),
                hasMiscellaneousItems:
                    isOnTrialValue || (payload?.hasActiveSubscription && resolvedMetadata?.miscellaneous_items),
                hasFixedPriceParts:
                    isOnTrialValue || (payload?.hasActiveSubscription && resolvedMetadata?.fixed_price_parts),
                hasShowViewer3D: payload?.hasActiveSubscription && resolvedMetadata?.ShowViewer3D,
                hasShowViewerTube: payload?.hasActiveSubscription && resolvedMetadata?.ShowViewerTube,
            }

            const useImperialUnits =
                payload?.defaultDrawingUnits && payload?.defaultDrawingUnits?.toLowerCase() === 'imperial'

            const currencySymbol = getCurrencySymbol(payload?.currencyCode, payload?.locale)

            state.resolvedPlanMetadata = resolvedMetadata
            state.planMetadata = metadata
            state.paidFeatures = paidFeatures
            state.useImperialUnits = useImperialUnits
            state.currencySymbol = currencySymbol
            state.currencyCode = payload.currencyCode
            state.reduceTrialAccess = reduceTrialAccessValue
            state.isOnTrial = isOnTrialValue
            state.isOnFreePlan = isOnFreePlan
            state.organisationId = payload.organisationId
            state.organisation = payload
            state.hasActiveSubscription = payload.hasActiveSubscription
            state.showAccelerationSettings = payload.showAccelerationSettings
        },
        setLastPaymentStatus: (state, action) => {
            state.lastPaymentStatus = action.payload
        },
        setPaidFeatures: (state, action) => {
            state.paidFeatures = action.payload
        },
        setUseImperialUnits: (state, action) => {
            state.useImperialUnits = action.payload
        },
        setCurrencySymbol: (state, action) => {
            state.currencySymbol = action.payload
        },
        setcurrencyCode: (state, action) => {
            state.currencyCode = action.payload
        },
        setIsOnTrial: (state, action) => {
            state.isOnTrial = action.payload
        },
        setDefaultTaxRateId: (state, action) => {
            state.defaultTaxRateId = action.payload
        },
    },
    extraReducers: (builder) => {
        builder.addMatcher(isAnyOf(organisationApi.endpoints.getOrganisation.matchFulfilled), (state, action) => {
            organisationSlice.caseReducers.setOrganisation(state, action)
        })
    },
})

export const selectOrganisation = (state) => state.organisationSlice.organisation
export const selectOrganisationId = (state) => state.organisationSlice.organisationId
export const selectPlanMetadata = (state) => state.organisationSlice.planMetadata
export const selectLastPaymentStatus = (state) => state.organisationSlice.lastPaymentStatus
export const selectUseImperialUnits = (state) => state.organisationSlice.useImperialUnits
export const selectCurrencySymbol = (state) => state.organisationSlice.currencySymbol
export const selectCurrencyCode = (state) => state.organisationSlice.currencyCode
export const selectPaidFeatures = (state) => state.organisationSlice.paidFeatures
export const selectIsOnTrial = (state) => state.organisationSlice.isOnTrial
export const selectReduceTrialAccess = (state) => state.organisationSlice.reduceTrialAccess
export const selectResolvedPlanMetadata = (state) => state.organisationSlice.resolvedPlanMetadata
export const selectIsOnFreePlan = (state) => state.organisationSlice.isOnFreePlan
export const selectShowAccelerationSettings = (state) => state.organisationSlice.showAccelerationSettings
export const selectDefaultTaxRateId = (state) => state.organisationSlice.defaultTaxRateId
export const selectMaxUsers = (state) => state.organisationSlice.resolvedPlanMetadata?.max_users ?? 0
export const selectHasActiveSubscription = (state) => state.organisationSlice.hasActiveSubscription

export const {
    setCurrencySymbol,
    setDefaultTaxRateId,
    setIsOnTrial,
    setLastPaymentStatus,
    setOrganisation,
    setPaidFeatures,
    setUseImperialUnits,
} = organisationSlice.actions

export default organisationSlice.reducer
