import { configureStore } from '@reduxjs/toolkit'
import { setupListeners } from '@reduxjs/toolkit/query'
import { FLUSH, PAUSE, PERSIST, persistReducer, persistStore, PURGE, REGISTER, REHYDRATE } from 'redux-persist'
import storage from 'redux-persist/lib/storage'

import { api } from './services/api'
import { quickPartsApi } from './services/quickPartsApi'
import { webStoreApi } from './services/web-store/webStoreApi'
import appReducer from './slices/appSlice'
import contactsReducer from './slices/contactsSlice'
import customersReducer from './slices/customersSlice'
import cuttingTechnologiesReducer from './slices/cuttingTechnologiesSlice'
import materialsReducer from './slices/materialsSlice'
import miscItemsReducer from './slices/miscItemsSlice'
import organisationReducer from './slices/organisationSlice'
import quoteItemsReducer from './slices/quoteItemsSlice'
import ratesReducer from './slices/ratesSlice'
import rateTablesReducer from './slices/rateTablesSlice'
import sheetsReducer from './slices/sheetsSlice'
import webStoreAuthSlice from './slices/web-store/webStoreAuthSlice'
import webStoreQuoteItemsReducer from './slices/web-store/webStoreQuoteItemsSlice'

const persistConfig = {
    key: 'qt',
    version: 3,
    storage,
    whitelist: ['displayQuotePricing'],
}

const persistedAppReducer = persistReducer(persistConfig, appReducer)

export const store = configureStore({
    reducer: {
        [api.reducerPath]: api.reducer,
        [quickPartsApi.reducerPath]: quickPartsApi.reducer,
        [webStoreApi.reducerPath]: webStoreApi.reducer,
        appSlice: persistedAppReducer,
        contactsSlice: contactsReducer,
        customersSlice: customersReducer,
        cuttingTechnologiesSlice: cuttingTechnologiesReducer,
        materialsSlice: materialsReducer,
        miscItemsSlice: miscItemsReducer,
        organisationSlice: organisationReducer,
        quoteItemsSlice: quoteItemsReducer,
        ratesSlice: ratesReducer,
        rateTablesSlice: rateTablesReducer,
        sheetsSlice: sheetsReducer,
        webStoreAuth: webStoreAuthSlice,
        webStoreQuoteItems: webStoreQuoteItemsReducer,
    },
    middleware: (getDefaultMiddleware) =>
        getDefaultMiddleware({
            serializableCheck: {
                ignoredActions: [FLUSH, REHYDRATE, PAUSE, PERSIST, PURGE, REGISTER],
            },
        })
            .concat(api.middleware)
            .concat(quickPartsApi.middleware)
            .concat(webStoreApi.middleware),
    devTools: process.env.NODE_ENV === 'development',
})

setupListeners(store.dispatch)

export const persistor = persistStore(store)
