import { useState } from 'react'
import { Controller, useForm } from 'react-hook-form'
import { useTranslation } from 'react-i18next'
import { useDispatch, useSelector } from 'react-redux'
import { TextField } from '@mui/material'

import { useRatesBulkUpdateMutation } from '@/app/services/rates'
import { selectOrganisationId } from '@/app/slices/organisationSlice'
import { clearSelectedRates, selectSelectedRates } from '@/app/slices/ratesSlice'
import { TbxBulkEditModal } from '@/common/components'

import ArchiveRateDialog from './ArchiveRateDialog'

const RatesBulkEdit = () => {
    const { t } = useTranslation()
    const dispatch = useDispatch()

    const organisationId = useSelector(selectOrganisationId)
    const selectedRates = useSelector(selectSelectedRates)

    const [ratesBulkUpdate] = useRatesBulkUpdateMutation()

    const { control, formState, handleSubmit } = useForm({
        mode: 'all',
        defaultValues: {
            hourlyRateMultiplier: '',
        },
    })

    const [isSaving, setIsSaving] = useState(false)
    const [showDialog, setShowDialog] = useState(false)

    const saveRates = async (data) => {
        setIsSaving(true)

        const updatedRates = selectedRates.map((r) => ({
            ...r,
            ...data,
        }))

        await ratesBulkUpdate({ organisationId, updatedRates })
        setIsSaving(false)
    }

    const archiveRates = async () => {
        setIsSaving(true)

        const updatedRates = selectedRates.map((r) => ({
            ...r,
            isDeleted: true,
        }))

        await ratesBulkUpdate({ organisationId, updatedRates })
        setIsSaving(false)
    }

    const handleCloseClick = () => {
        dispatch(clearSelectedRates())
    }

    const handleSaveClick = async (data, _) => {
        await saveRates(data)
        dispatch(clearSelectedRates())
    }

    const confirmArchive = async () => {
        await archiveRates()
        dispatch(clearSelectedRates())
        setShowDialog(false)
    }

    const hideArchiveDialog = () => {
        setShowDialog(false)
    }

    const showArchiveDialog = () => {
        setShowDialog(true)
    }

    return (
        <TbxBulkEditModal
            disableSave={!formState.isValid || isSaving}
            handleArchive={showArchiveDialog}
            handleClose={handleCloseClick}
            handleSave={handleSubmit(handleSaveClick)}
            items={selectedRates}
            open={selectedRates?.length > 0}
            showDeleteIcon
        >
            <Controller
                control={control}
                name="hourlyRateMultiplier"
                render={({ field: { onBlur, onChange, value }, fieldState }) => (
                    <TextField
                        error={fieldState.invalid}
                        helperText={fieldState.error?.message || ''}
                        InputLabelProps={{ shrink: true }}
                        label={t('Set hourly rate multiplier')}
                        value={value}
                        autoFocus
                        onBlur={onBlur}
                        onChange={onChange}
                    />
                )}
                rules={{ required: 'Required' }}
            />
            {showDialog ? <ArchiveRateDialog
                    name={': ' + selectedRates.map((r) => r.thickness).join(', ')}
                    onCancelClose={hideArchiveDialog}
                    onConfirmClose={confirmArchive}
                          /> : null}
        </TbxBulkEditModal>
    )
}

export default RatesBulkEdit
