import { forwardRef } from 'react'
import { CheckBoxOutlineBlank, Delete, DragHandle, ExpandMore } from '@mui/icons-material'
import IconButton from '@mui/material/IconButton'
import Typography from '@mui/material/Typography'
import { Box } from '@mui/system'
import PropTypes from 'prop-types'

import QuoteItemStatus from './QuoteItemStatus'

const classes = {
    Overlay: {
        borderRadius: 2,
        overflow: 'hidden',
        backgroundColor: 'primary.main',

        boxShadow: 15,
        zIndex: 10,
    },

    OverlayContent: {
        px: 2,
        py: 1.5,
        display: 'flex',
        justifyContent: 'space-between',
    },

    OverlayContentLeft: {
        gap: 1.5,
    },

    OverlayContentRight: {
        gap: 6,
    },

    OverlayContentColor: {
        color: 'primary.contrastText',
    },
}

const QuoteItemOverlay = forwardRef(({ id, name, ...props }, ref) => {
    return (
        <Box sx={classes.Overlay}>
            <Box sx={classes.OverlayContent}>
                <Box
                    alignItems="center"
                    display="flex"
                    sx={classes.OverlayContentLeft}
                >
                    <IconButton
                        size="small"
                        sx={classes.OverlayContentColor}
                    >
                        <DragHandle />
                    </IconButton>

                    <QuoteItemStatus status="overlay" />

                    <CheckBoxOutlineBlank
                        fontSize="small"
                        sx={classes.OverlayContentColor}
                    />

                    <Typography sx={classes.OverlayContentColor}>{name}</Typography>
                </Box>

                <Box
                    alignItems="center"
                    display="flex"
                    sx={classes.OverlayContentRight}
                >
                    <Box
                        alignItems="center"
                        display="flex"
                    >
                        <IconButton sx={classes.OverlayContentColor}>
                            <Delete />
                        </IconButton>

                        <ExpandMore sx={classes.OverlayContentColor} />
                    </Box>
                </Box>
            </Box>
        </Box>
    )
})

QuoteItemOverlay.displayName = 'QuoteItemOverlay'

QuoteItemOverlay.propTypes = {
    id: PropTypes.string.isRequired,
    name: PropTypes.string.isRequired,
}

export default QuoteItemOverlay
