import { api } from './api'

const apiVersion = 'v2'

export const quoteItemsApi = api
    .enhanceEndpoints({
        addTagTypes: ['QuoteItems', 'QuoteItemDrawing'],
    })
    .injectEndpoints({
        endpoints: (builder) => ({
            getQuoteItems: builder.query({
                query: ({ organisationId, quoteId }) => `/${apiVersion}/quote/${organisationId}/${quoteId}/quote-items`,
                providesTags: (result, _error, _arg) =>
                    result
                        ? [{ type: 'QuoteItems', id: 'LIST' }, ...result.map(({ id }) => ({ type: 'QuoteItems', id }))]
                        : [{ type: 'QuoteItems', id: 'LIST' }],
                transformResponse: (responseData) => {
                    if (responseData?.length) {
                        responseData?.sort((a, b) => a.index - b.index)
                    }
                    return responseData
                },
            }),

            getQuoteItem: builder.query({
                query: ({ organisationId, quoteId, quoteItemId }) =>
                    `/${apiVersion}/quote/${organisationId}/${quoteId}/quote-items/${quoteItemId}`,

                providesTags: (_result, _error, arg) => [{ type: 'QuoteItems', id: arg.quoteItemId }],
            }),

            addQuoteItemsFromFiles: builder.mutation({
                query: ({ drawingFiles, organisationId, quoteId }) => ({
                    url: `/${apiVersion}/quote/${organisationId}/${quoteId}/quote-items/drawing-files`,
                    method: 'POST',
                    body: drawingFiles,
                }),
                invalidatesTags: [{ type: 'QuoteItems', id: 'LIST' }],
            }),

            addQuoteItemsFromParts: builder.mutation({
                query: ({ organisationId, parts, quoteId }) => ({
                    url: `/${apiVersion}/quote/${organisationId}/${quoteId}/quote-items`,
                    method: 'POST',
                    body: parts,
                }),
                invalidatesTags: [{ type: 'QuoteItems', id: 'LIST' }],
            }),

            addQuoteItemsFrom3D: builder.mutation({
                query: ({ organisationId, quoteId }) => ({
                    url: `/${apiVersion}/quote/${organisationId}/${quoteId}/quote-items/3dUnfolding`,
                    method: 'POST',
                }),
                invalidatesTags: [{ type: 'QuoteItems', id: 'LIST' }],
            }),

            addQuoteItemsFromPDF: builder.mutation({
                query: ({ organisationId, quoteId }) => ({
                    url: `/${apiVersion}/quote/${organisationId}/${quoteId}/quote-items/pdf`,
                    method: 'POST',
                }),
                invalidatesTags: [{ type: 'QuoteItems', id: 'LIST' }],
            }),

            addQuoteItemsFromPartLibrary: builder.mutation({
                query: ({ organisationId, partsToAdd, quoteId }) => ({
                    url: `/${apiVersion}/quote/${organisationId}/${quoteId}/quote-items/part-library`,
                    method: 'PUT',
                    body: partsToAdd,
                }),
                invalidatesTags: [{ type: 'QuoteItems', id: 'LIST' }],
            }),

            updateQuoteItem: builder.mutation({
                query: ({ organisationId, quoteId, quoteItem, quoteItemId }) => ({
                    url: `/${apiVersion}/quote/${organisationId}/${quoteId}/quote-items/${quoteItemId}`,
                    method: 'PUT',
                    body: quoteItem,
                }),
                async onQueryStarted(
                    { organisationId, quoteId, quoteItem, quoteItemId },
                    { dispatch, queryFulfilled }
                ) {
                    dispatch(
                        quoteItemsApi.util.updateQueryData('getQuoteItems', { organisationId, quoteId }, (draft) => {
                            const index = draft.findIndex((item) => item.id === quoteItemId)
                            draft[index] = quoteItem
                        })
                    )

                    try {
                        await queryFulfilled
                    } catch {
                        //patchResult.undo()
                        dispatch(quoteItemsApi.util.invalidateTags([{ type: 'QuoteItems', id: quoteItemId }]))
                    }
                },
            }),

            updateQuoteItems: builder.mutation({
                query: ({ organisationId, quoteId, quoteItems }) => ({
                    url: `/${apiVersion}/quote/${organisationId}/${quoteId}/quote-items`,
                    method: 'PUT',
                    body: quoteItems,
                }),
                async onQueryStarted({ organisationId, quoteId, quoteItems }, { dispatch, queryFulfilled }) {
                    dispatch(
                        quoteItemsApi.util.updateQueryData('getQuoteItems', { organisationId, quoteId }, (draft) => {
                            Object.assign(draft, quoteItems)
                        })
                    )
                    try {
                        await queryFulfilled
                    } catch {
                        //patchResult.undo()
                        dispatch(quoteItemsApi.util.invalidateTags([{ type: 'QuoteItems', id: 'LIST' }]))
                    }
                },
            }),

            updateQuoteItemsAttributes: builder.mutation({
                query: ({ organisationId, quoteId, quoteItems }) => ({
                    url: `/${apiVersion}/quote/${organisationId}/${quoteId}/quote-items/attribute`,
                    method: 'PUT',
                    body: quoteItems,
                }),
                invalidatesTags(result, error, arg) {
                    return arg.quoteItems.quoteItemIds.map((quoteItemId) => ({
                        type: 'QuoteItems',
                        id: quoteItemId,
                    }))
                },
            }),

            updateQuoteItemDrawing: builder.mutation({
                query: ({ drawing, organisationId, quoteId, quoteItemId }) => ({
                    url: `/${apiVersion}/quote/${organisationId}/${quoteId}/quote-items/${quoteItemId}/drawing`,
                    method: 'PUT',
                    body: drawing,
                }),
                invalidatesTags: (result, error, arg) => [
                    { type: 'QuoteItems', id: arg.quoteItemId },
                    { type: 'QuoteItemDrawing', id: 'DRAWING' },
                ],
            }),

            resetQuoteItemDrawing: builder.mutation({
                query: ({ organisationId, quoteId, quoteItemId }) => ({
                    url: `/${apiVersion}/quote/${organisationId}/${quoteId}/quote-items/${quoteItemId}/reset-toolpath`,
                    method: 'PUT',
                }),
                invalidatesTags: (result, error, arg) => [
                    { type: 'QuoteItems', id: arg.quoteItemId },
                    { type: 'QuoteItemDrawing', id: 'DRAWING' },
                ],
            }),

            scaleQuoteItemDrawing: builder.mutation({
                query: ({ organisationId, quoteId, quoteItemId, scaleFactor }) => ({
                    url: `/${apiVersion}/quote/${organisationId}/${quoteId}/quote-items/${quoteItemId}/scale`,
                    method: 'PUT',
                    params: { scaleFactor },
                }),
                invalidatesTags: (result, error, arg) => [
                    { type: 'QuoteItems', id: arg.quoteItemId },
                    { type: 'QuoteItemDrawing', id: 'DRAWING' },
                ],
            }),

            deleteQuoteItem: builder.mutation({
                query: ({ organisationId, quoteId, quoteItemId }) => ({
                    url: `/${apiVersion}/quote/${organisationId}/${quoteId}/quote-items/${quoteItemId}`,
                    method: 'DELETE',
                }),
                invalidatesTags: (result, error, arg) => [
                    { type: 'QuoteItems', id: 'LIST' },
                    { type: 'QuoteItems', id: arg.quoteItemId },
                    { type: 'PartLibraryEntries', id: 'LIST' },
                ],
            }),

            deleteQuoteItems: builder.mutation({
                query: ({ organisationId, quoteId, quoteItemsIds }) => ({
                    url: `/${apiVersion}/quote/${organisationId}/${quoteId}/quote-items`,
                    method: 'DELETE',
                    body: quoteItemsIds,
                }),
                invalidatesTags: (result, error, arg) => [
                    { type: 'QuoteItems', id: 'LIST' },
                    { type: 'PartLibraryEntries', id: 'LIST' },
                    ...arg.quoteItemsIds.map((quoteItemId) => ({ type: 'QuoteItems', id: quoteItemId })),
                ],
            }),

            getQuoteItemDrawing: builder.query({
                query: ({ organisationId, quoteId, quoteItemId }) =>
                    `/${apiVersion}/quote/${organisationId}/${quoteId}/quote-items/${quoteItemId}/drawing`,
                providesTags: (_result, _error, _arg) => [{ type: 'QuoteItemDrawing', id: 'DRAWING' }],
            }),

            getQuoteItemCadView: builder.query({
                query: ({ organisationId, quoteId, quoteItemId }) =>
                    `/${apiVersion}/quote/${organisationId}/${quoteId}/quote-items/${quoteItemId}/cad-view`,
            }),
        }),
    })

export const selectQuoteItems = (state, query) => {
    return quoteItemsApi.endpoints.getQuoteItems.select(query)(state)?.data ?? []
}

export const selectQuoteItemsForQuote = (state, query) => {
    const quoteItems = selectQuoteItems(state, query)
    return quoteItems.map(({ id, index, name, quoteId }) => ({ id, name, index, quoteId }))
}

export const {
    useAddQuoteItemsFrom3DMutation,
    useAddQuoteItemsFromFilesMutation,
    useAddQuoteItemsFromPDFMutation,
    useAddQuoteItemsFromPartLibraryMutation,
    useAddQuoteItemsFromPartsMutation,
    useDeleteQuoteItemMutation,
    useDeleteQuoteItemsMutation,
    useGetQuoteItemCadViewQuery,
    useGetQuoteItemDrawingQuery,
    useGetQuoteItemQuery,
    useGetQuoteItemsQuery,
    useLazyGetQuoteItemDrawingQuery,
    useLazyGetQuoteItemQuery,
    useLazyGetQuoteItemsQuery,
    useResetQuoteItemDrawingMutation,
    useScaleQuoteItemDrawingMutation,
    useUpdateQuoteItemDrawingMutation,
    useUpdateQuoteItemMutation,
    useUpdateQuoteItemsAttributesMutation,
    useUpdateQuoteItemsMutation,
} = quoteItemsApi
