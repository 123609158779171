import { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { Close } from '@mui/icons-material'
import { Box, Button, Dialog, DialogTitle, IconButton, TextField, Typography } from '@mui/material'
import DialogContent from '@mui/material/DialogContent'
import PropTypes from 'prop-types'

const classes = {
    root: {
        margin: 0,
        padding: 2,
    },
    closeButton: {
        position: 'absolute',
        right: 1,
        top: 1,
        color: 'grey[500]',
    },
    title: {
        fontWeight: 700,
        fontSize: '16px',
        lineHeight: '150%',
        letterSpacing: '0.15px',
        color: 'text.primary',
    },
    settingRow: {
        width: 400,
        display: 'flex',
        flexDirection: 'row',
        justifyContent: 'space-between',
        marginTop: '12px',
    },
    settingLabel: {
        width: 200,
        fontWeight: 400,
        fontSize: '14px',
        lineHeight: '143%',
        display: 'flex',
        alignItems: 'center',
        letterSpacing: '0.17px',
        color: 'text.primary',
        maxWidth: '200px',
    },
    buttonsContainer: {
        display: 'flex',
        justifyContent: 'space-evenly',
        marginTop: '24px',
    },
    button: {
        fontWeight: 500,
        minWidth: '142px',
        height: '36px',
    },
}

const DuplicateMaterialDialog = ({ onClose, onSave, placeholderName = '', showDialog = false }) => {
    const { t } = useTranslation()
    const [materialName, setMaterialName] = useState('')

    const onNameChange = (event) => {
        setMaterialName(event.target.value)
    }

    useEffect(() => {
        setMaterialName(placeholderName)
    }, [placeholderName])

    return (
        <Dialog
            aria-labelledby="nest-dialog"
            maxWidth="md"
            open={showDialog}
            onClose={onClose}
        >
            <DialogTitle sx={classes.root}>
                <Typography variant="h6">{t('Duplicate Material')}</Typography>
                <IconButton
                    aria-label="close"
                    size="large"
                    sx={classes.closeButton}
                    onClick={onClose}
                >
                    <Close />
                </IconButton>
            </DialogTitle>

            <DialogContent dividers>
                <Box sx={classes.settingRow}>
                    <Typography sx={classes.settingLabel}>{t('New material name')}</Typography>
                    <TextField
                        error={!materialName}
                        helperText={
                            materialName ? t('Material name cannot be changed later') : t('Material name required')
                        }
                        inputProps={{
                            'data-testid': 'add-material-density',
                        }}
                        sx={classes.textInput}
                        value={materialName}
                        fullWidth
                        required
                        onChange={onNameChange}
                    />
                </Box>
                <Box sx={classes.buttonsContainer}>
                    <Button
                        color="primary"
                        key="cancel"
                        sx={classes.button}
                        variant="outlined"
                        onClick={onClose}
                    >
                        {t('Cancel')}
                    </Button>
                    <Button
                        color="primary"
                        disabled={!materialName}
                        key="update"
                        sx={classes.button}
                        variant="contained"
                        onClick={() => onSave(materialName)}
                    >
                        {t('Duplicate')}
                    </Button>
                </Box>
            </DialogContent>
        </Dialog>
    )
}

DuplicateMaterialDialog.propTypes = {
    placeholderName: PropTypes.string,
    showDialog: PropTypes.bool,
    onClose: PropTypes.func,
    onSave: PropTypes.func,
}

export default DuplicateMaterialDialog
