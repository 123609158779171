import { useState } from 'react'
import { Controller, useForm } from 'react-hook-form'
import { useTranslation } from 'react-i18next'
import { useSelector } from 'react-redux'
import { useMsal } from '@azure/msal-react'
import { Button, TextField, Typography } from '@mui/material'
import Grid from '@mui/system/Unstable_Grid'
import { useSnackbar } from 'notistack'

import { useUpdateUserMutation } from '@/app/services/user'
import { selectCurrentUser } from '@/app/slices/appSlice'
import { resetPasswordRequest } from '@/authConfig'
import { AlertDialog } from '@/common/components'
import LanguageSelect from '@/common/components/LanguageSelect/LanguageSelect'
import SettingsToolbar from '@/common/components/MainAppBar/SettingsToolbar/SettingsToolbar'

const classes = {
    root: {
        gap: 2,
        display: 'flex',
        flexDirection: 'column',
        boxShadow: 'none',
        mb: 3,
        px: 4,
        py: 3,
    },
}

const AccountSettings = () => {
    const { t } = useTranslation()

    const { instance: msalInstance } = useMsal()
    const { enqueueSnackbar } = useSnackbar()

    const currentUser = useSelector(selectCurrentUser)
    const [updateUser] = useUpdateUserMutation()

    const [showLanguageChangeAlert, setShowLanguageChangeAlert] = useState(false)

    const {
        control,
        formState: { isDirty, isSubmitting, isValid },
        handleSubmit,
        reset,
    } = useForm({
        mode: 'all',
        defaultValues: {
            firstName: currentUser?.firstName,
            lastName: currentUser?.lastName,
            emailAddress: currentUser?.emailAddress,
            displayLanguage: currentUser?.language,
        },
    })

    const handlePageReload = () => {
        setShowLanguageChangeAlert(false)
        window.location.reload()
    }

    const onSubmit = async (data) => {
        const user = {
            firstName: data.firstName,
            lastName: data.lastName,
            language: data.displayLanguage != 'none' ? data.displayLanguage : '',
            emailAddress: data.emailAddress,
            showUserflowTooltips: currentUser.showUserflowTooltips,
        }
        try {
            await updateUser({ userId: currentUser.userId, user }).unwrap()

            reset(data)

            enqueueSnackbar(t('Account settings updated successfully'), {
                variant: 'success',
            })

            if (data.displayLanguage != currentUser.language) {
                setShowLanguageChangeAlert(true)
            }
        } catch (error) {
            enqueueSnackbar(t('Failed to update account settings'), {
                variant: 'error',
            })
        }
    }

    const emailPattern = /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i
    const invalidEmailMessage = 'Please enter a valid email'

    const handleResetPasswordClicked = () => {
        msalInstance.loginRedirect(resetPasswordRequest)
    }

    return (
        <>
            <SettingsToolbar
                enableCancelButton={isDirty ? !isSubmitting : null}
                enableSaveButton={isDirty && !isSubmitting ? isValid : null}
                handleCancelClick={() => reset()}
                handleSaveClick={handleSubmit(onSubmit)}
                title="Settings / Account"
            />
            <Grid
                sx={classes.root}
                container
            >
                <Grid xs={12}>
                    <Typography variant="h4">{t('Account')}</Typography>
                </Grid>
                <Grid xs={12}>
                    <Typography variant="h6">{t('Profile')}</Typography>
                </Grid>
                <Grid
                    spacing={2}
                    sx={classes.fieldContainer}
                    container
                >
                    <Grid xs={6}>
                        <Controller
                            control={control}
                            name="firstName"
                            render={({ field: { onBlur, onChange, value }, fieldState }) => (
                                <TextField
                                    error={Boolean(fieldState.error)}
                                    helperText={t(fieldState.error?.message)}
                                    label={t('First name')}
                                    value={value}
                                    fullWidth
                                    required
                                    onBlur={onBlur}
                                    onChange={onChange}
                                />
                            )}
                            rules={{ required: t('Required') }}
                        />
                    </Grid>
                    <Grid xs={6}>
                        <Controller
                            control={control}
                            name="lastName"
                            render={({ field: { onBlur, onChange, value }, fieldState }) => (
                                <TextField
                                    error={Boolean(fieldState.error)}
                                    helperText={t(fieldState.error?.message)}
                                    label={t('Last name')}
                                    value={value}
                                    fullWidth
                                    required
                                    onBlur={onBlur}
                                    onChange={onChange}
                                />
                            )}
                            rules={{ required: t('Required') }}
                        />
                    </Grid>
                    <Grid xs={6}>
                        <Controller
                            control={control}
                            name="emailAddress"
                            render={({ field: { onBlur, onChange, value }, fieldState }) => (
                                <TextField
                                    error={Boolean(fieldState.error)}
                                    helperText={t(fieldState.error?.message)}
                                    InputProps={{
                                        readOnly: true,
                                    }}
                                    label={t('Email address')}
                                    value={value}
                                    fullWidth
                                    required
                                    onBlur={onBlur}
                                    onChange={onChange}
                                />
                            )}
                            rules={{
                                required: t('Required'),
                                pattern: {
                                    value: emailPattern,
                                    message: invalidEmailMessage,
                                },
                            }}
                        />
                    </Grid>
                    <Grid xs={6}>
                        <Controller
                            control={control}
                            name="displayLanguage"
                            render={({ field: { onChange, value } }) => (
                                <LanguageSelect
                                    disabled={isSubmitting}
                                    nullValueOptionLabelText={t('Inherit organisation language')}
                                    showNullValueOption={true}
                                    value={value || 'none'}
                                    fullWidth
                                    onChange={(value) => onChange(value || 'none')}
                                />
                            )}
                        />
                    </Grid>
                    <Grid xs={12}>
                        <Button
                            color="primary"
                            variant="text"
                            onClick={handleResetPasswordClicked}
                        >
                            {t('Reset password')}
                        </Button>
                    </Grid>
                </Grid>
            </Grid>

            <AlertDialog
                content={t('For language change to take effect, the page needs to be reloaded.')}
                hideCancelButton={true}
                okButtonText={t('Reload page')}
                open={showLanguageChangeAlert}
                title={t('Language change')}
                onOkClose={handlePageReload}
            />
        </>
    )
}

export default AccountSettings
