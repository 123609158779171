import { useTranslation } from 'react-i18next'
import Chip from '@mui/material/Chip'
import PropTypes from 'prop-types'

import { QUOTE_SOURCE_TYPES } from '@/common/utils'

const QuoteSourceChip = ({ quoteSource }) => {
    const { t } = useTranslation()

    return (
        <Chip
            label={t(quoteSource === QUOTE_SOURCE_TYPES.Internal ? 'Internal' : 'Web Store')}
            size="small"
            sx={{ textTransform: 'uppercase', fontSize: '11px', userSelect: 'none' }}
            title={t(quoteSource === QUOTE_SOURCE_TYPES.Internal ? 'Internal' : 'Web Store')}
        />
    )
}

QuoteSourceChip.propTypes = {
    quoteSource: PropTypes.number,
}

export default QuoteSourceChip
