import { useCallback, useEffect, useState } from 'react'
import { Search } from '@mui/icons-material'
import { TextField } from '@mui/material'
import InputAdornment from '@mui/material/InputAdornment'
import { debounce } from 'lodash'
import PropTypes from 'prop-types'

const SearchTextField = ({
    debounceDelay = 800,
    debounceInput = true,
    disabled = false,
    label,
    onChange,
    placeholder,
    value,
}) => {
    const [searchTerm, setSearchTerm] = useState(value)

    useEffect(() => {
        setSearchTerm(value)
    }, [value])

    const searchTermChangedHandler = useCallback(
        debounce(
            (newValue) => {
                if (typeof onChange === 'function') {
                    onChange(newValue)
                }
            },
            !debounceInput ? 0 : debounceDelay
        ),
        []
    )

    const handleSearchTermChange = (event) => {
        setSearchTerm(event.target.value)
        searchTermChangedHandler(event.target.value)
    }

    return (
        <TextField
            disabled={disabled}
            InputProps={{
                startAdornment: (
                    <InputAdornment position="start">
                        <Search
                            sx={{
                                color: 'text.primary',
                            }}
                        />
                    </InputAdornment>
                ),
            }}
            label={label}
            placeholder={placeholder}
            size="small"
            value={searchTerm}
            variant="outlined"
            onChange={handleSearchTermChange}
        />
    )
}

SearchTextField.propTypes = {
    debounceDelay: PropTypes.number,
    debounceInput: PropTypes.bool,
    disabled: PropTypes.bool,
    label: PropTypes.string,
    placeholder: PropTypes.string,
    value: PropTypes.string,
    onChange: PropTypes.func,
}

export default SearchTextField
