import { useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useSelector } from 'react-redux'
import { Box, Tab, Tabs } from '@mui/material'
import PropTypes from 'prop-types'

import { selectOrganisation, selectPaidFeatures } from '@/app/slices/organisationSlice'
import { useToolBoxTreatments } from '@/common/hooks'

import CustomerContactsTab from './CustomerContactsTab/CustomerContactsTable'
import CustomerPricingTab from './CustomerPricingTab/CustomerPricingTab'
import CustomerSummaryTab from './CustomerSummaryTab/CustomerSummaryTab'
import PartLibraryTab from './PartLibraryTab/PartLibraryTab'
import QuoteHistoryTab from './QuoteHistoryTab/QuoteHistoryTab'

const classes = {
    tabContainer: {
        display: 'flex',
        margin: '24px 0',
    },
    tabBar: {
        '& .MuiTabs-indicator': {
            display: 'none',
        },
        '& .Mui-selected': {
            borderLeft: '1px solid',
            borderRight: '1px solid',
            borderTop: '1px solid',
            fontWeight: 500,
            borderBottom: 0,
            borderRadius: '5px 5px 0 0',
        },
    },
    tabTitle: {
        fontSize: '14px',
        color: 'text.primary',
        textTransform: 'none',
        padding: '12px 16px',
        minWidth: 0,
        borderBottom: '1px solid',
        borderBottomColor: 'primary.main',
    },
    afterTab: {
        flexGrow: 1,
        borderBottom: '1px solid',
        borderBottomColor: 'primary.main',
    },
}

const SUMMARY_TAB = 0
const CONTACTS_TAB = 1
const PRICING_TAB = 2
const ESTIMATE_HISTORY_TAB = 3
const PART_LIBRARY_TAB = 4

const TabPanel = ({ children, currentTab, value, ...other }) => {
    return (
        <Box
            aria-labelledby={`simple-tab-${currentTab}`}
            hidden={value !== currentTab}
            id={`simple-tabpanel-${currentTab}`}
            role="tabpanel"
            {...other}
        >
            {value === currentTab ? <div>{children}</div> : null}
        </Box>
    )
}

const CustomerDetails = ({ customer }) => {
    const { t } = useTranslation()

    const [currentTab, setCurrentTab] = useState(0)

    const handleTabChange = (event, newValue) => {
        setCurrentTab(newValue)
    }

    const organisation = useSelector(selectOrganisation)
    const paidFeatures = useSelector(selectPaidFeatures)
    const { showPartLibrary } = useToolBoxTreatments()
    const isCustomerPricingEnabled = customer && paidFeatures?.hasCustomerPricing
    const isPartLibraryEnabled = showPartLibrary && paidFeatures?.hasPartLibrary

    return (
        <Box sx={classes.root}>
            <Box sx={classes.tabContainer}>
                <Tabs
                    indicatorColor="primary"
                    sx={classes.tabBar}
                    textColor="primary"
                    value={currentTab}
                    onChange={handleTabChange}
                >
                    <Tab
                        data-testid="summary-tab"
                        id="customer-summary-tab"
                        label={t('Summary')}
                        sx={classes.tabTitle}
                        value={SUMMARY_TAB}
                    />
                    <Tab
                        data-testid="contacts-tab"
                        id="customer-contacts-tab"
                        label={t('Contacts')}
                        sx={classes.tabTitle}
                        value={CONTACTS_TAB}
                    />
                    <Tab
                        data-testid="estimate-history-tab"
                        id="customer-quote-history-tab"
                        label={t('$t(Quote) History')}
                        sx={classes.tabTitle}
                        value={ESTIMATE_HISTORY_TAB}
                    />
                    <Tab
                        data-testid="pricing-tab"
                        disabled={!isCustomerPricingEnabled}
                        id="customer-pricing-tab"
                        label={t('Pricing')}
                        sx={classes.tabTitle}
                        value={PRICING_TAB}
                    />
                    <Tab
                        data-testid="part-library-tab"
                        disabled={!isPartLibraryEnabled}
                        id="customer-part-library-tab"
                        label={t('Part Library')}
                        sx={classes.tabTitle}
                        value={PART_LIBRARY_TAB}
                    />
                </Tabs>
                <Box sx={classes.afterTab}></Box>
            </Box>

            <TabPanel
                aria-labelledby="customer-summary-tab"
                currentTab={currentTab}
                data-testid="summary-tab-panel"
                id="customer-summary-tab-panel"
                value={SUMMARY_TAB}
            >
                <CustomerSummaryTab customer={customer} />
            </TabPanel>

            <TabPanel
                aria-labelledby="customer-contacts-tab"
                currentTab={currentTab}
                data-testid="contacts-tab-panel"
                id="customer-contacts-tab-panel"
                value={CONTACTS_TAB}
            >
                <CustomerContactsTab
                    customer={customer}
                    organisation={organisation}
                />
            </TabPanel>
            <TabPanel
                aria-labelledby="customer-quote-history-tab"
                currentTab={currentTab}
                data-testid="estimate-history-tab-panel"
                id="customer-quote-history-tab-panel"
                value={ESTIMATE_HISTORY_TAB}
            >
                <QuoteHistoryTab customerId={customer?.customerId} />
            </TabPanel>
            <TabPanel
                aria-labelledby="customer-pricing-tab"
                currentTab={currentTab}
                data-testid="pricing-tab-panel"
                id="customer-pricing-tab-panel"
                value={PRICING_TAB}
            >
                <CustomerPricingTab customer={customer} />
            </TabPanel>
            <TabPanel
                aria-labelledby="customer-part-library-tab"
                currentTab={currentTab}
                data-testid="part-library-tab-panel"
                id="customer-part-library-tab-panel"
                value={PART_LIBRARY_TAB}
            >
                <PartLibraryTab customerId={customer?.customerId} />
            </TabPanel>
        </Box>
    )
}

TabPanel.propTypes = {
    currentTab: PropTypes.number,
    value: PropTypes.number,
}

CustomerDetails.propTypes = {
    customer: PropTypes.object,
}

export default CustomerDetails
