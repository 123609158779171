import { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { FormControl, InputLabel, MenuItem, Select } from '@mui/material'
import PropTypes from 'prop-types'

const DrawingFileTypeSelect = ({ disabled = false, onBlur, onChange, value = 'dxf' }) => {
    const { t } = useTranslation()

    const fileTypes = [
        { name: t('DXF'), value: 'dxf' },
        { name: t('DWG'), value: 'dwg' },
    ]

    const [selectedValue, setSelectedValue] = useState('')

    useEffect(() => {
        let lowerCaseValue = value
        if (typeof value == 'string') {
            lowerCaseValue = value.toLowerCase()
        }
        setSelectedValue(lowerCaseValue)
    }, [value])

    const handleChange = (event) => {
        const newSelectedValue = event.target.value

        setSelectedValue(newSelectedValue)

        if (typeof onChange === 'function') {
            onChange(newSelectedValue)
        }
    }

    const handleOnBlur = (event) => {
        const newSelectedValue = event.target.value

        setSelectedValue(newSelectedValue)

        if (typeof onBlur === 'function') {
            onBlur(newSelectedValue)
        }
    }

    return (
        <FormControl
            disabled={disabled}
            fullWidth
        >
            <InputLabel
                data-testid="organisation-cad-file-type-label"
                id="cad-file-select-label"
            >
                {t('CAD File Type')}
            </InputLabel>
            <Select
                id="cad-file-select"
                inputProps={{
                    'data-testid': 'organisation-cad-file-type-input',
                }}
                label={t('CAD File Type')}
                labelId="cad-file-select-label"
                value={selectedValue}
                onBlur={handleOnBlur}
                onChange={handleChange}
            >
                {fileTypes.map((fileType) => {
                    return (
                        <MenuItem
                            key={fileType.value}
                            value={fileType.value}
                        >
                            {fileType.name}
                        </MenuItem>
                    )
                })}
            </Select>
        </FormControl>
    )
}

DrawingFileTypeSelect.propTypes = {
    disabled: PropTypes.bool,
    value: PropTypes.string,
    onBlur: PropTypes.func,
    onChange: PropTypes.func,
}

export default DrawingFileTypeSelect
