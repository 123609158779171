import { useTranslation } from 'react-i18next'
import { useSelector } from 'react-redux'
import Typography from '@mui/material/Typography'
import Box from '@mui/system/Box'

import { useGetMaterialsQuery } from '@/app/services/materials'
import { selectSelectedMaterialId } from '@/app/slices/materialsSlice'
import { selectOrganisationId } from '@/app/slices/organisationSlice'

const classes = {
    nameContainer: {
        display: 'flex',
        flexDirection: 'row',
        alignItems: 'center',
    },
    materialName: {
        fontWeight: 400,
        fontSize: '34px',
        lineHeight: '123.5%',
        letterSpacing: '0.25px',
        color: 'text.primary',
    },
}

const MaterialHeader = () => {
    const { t } = useTranslation()
    const organisationId = useSelector(selectOrganisationId)
    const { data: materials } = useGetMaterialsQuery({ organisationId })

    const selectedMaterialId = useSelector(selectSelectedMaterialId)
    const selectedMaterial = materials[selectedMaterialId]

    return (
        <Box sx={classes.nameContainer}>
            <Typography sx={classes.materialName}>
                {`${t(selectedMaterial?.type)}: ${selectedMaterial?.materialName} ${
                    selectedMaterial?.isDeleted ? t('(archived)') : ''
                }`}
            </Typography>
        </Box>
    )
}

export default MaterialHeader
