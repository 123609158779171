import { useTranslation } from 'react-i18next'
import { Typography } from '@mui/material'
import PropTypes from 'prop-types'

const FoldingDetailsDisplay = ({ part }) => {
    const { t } = useTranslation()
    return part.numberOfFolds > 0 ? <Typography variant="body2">{t('Folding')}</Typography> : undefined
}
FoldingDetailsDisplay.propTypes = {
    part: PropTypes.object,
}

export default FoldingDetailsDisplay
