import { useEffect, useState } from 'react'
import { Box } from '@mui/material'
import PropTypes from 'prop-types'

import { useToolBoxTreatments } from '@/common/hooks'
import { getProductDetails } from '@/common/utils/GeneralUtils/GeneralUtils'

const classes = {
    wrapper: ({ wrapperHeight, wrapperWidth }) => ({
        width: wrapperWidth,
        height: wrapperHeight,
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
    }),
    img: {
        height: '100%',
        width: '100%',
        objectFit: 'contain',
        objectPosition: 'center',
    },
}

const OrganisationLogo = ({ organisationId, organisationLogoUri, sx, wrapperHeight = 100, wrapperWidth = 100 }) => {
    const { previewResellerName } = useToolBoxTreatments()

    const productDetails = getProductDetails(previewResellerName)
    const defaultLogoUrl = `/${productDetails.logo}`

    const [logo, setLogo] = useState(null)

    useEffect(() => {
        let isMounted = true

        const loadDefaultLogo = async () => {
            const logoResponse = await fetch(defaultLogoUrl)
            const blobResponse = logoResponse && (await logoResponse.blob())
            if (blobResponse?.size > 0) {
                isMounted && setLogo(URL.createObjectURL(blobResponse))
            }
        }

        if (organisationId && organisationLogoUri) {
            setLogo(organisationLogoUri)
        } else {
            loadDefaultLogo()
        }

        return () => {
            isMounted = false
        }
    }, [organisationId, organisationLogoUri])

    return logo ? (
        <Box sx={[classes.wrapper({ wrapperWidth, wrapperHeight }), ...(Array.isArray(sx) ? sx : [sx])]}>
            <Box
                alt="[Organisation Logo]"
                component="img"
                data-testid="organisation-logo"
                src={logo}
                sx={classes.img}
            />
        </Box>
    ) : (
        <Box sx={classes.table}>
            <Box sx={[classes.wrapper({ wrapperWidth, wrapperHeight }), ...(Array.isArray(sx) ? sx : [sx])]}>
                <Box
                    alt="[Organisation Logo]"
                    component="img"
                    data-testid="organisation-logo"
                    src={defaultLogoUrl}
                    sx={classes.img}
                />
            </Box>
        </Box>
    )
}

OrganisationLogo.propTypes = {
    organisationId: PropTypes.string.isRequired,
    asBase64: PropTypes.bool,
    organisationLogoUri: PropTypes.string,
    sx: PropTypes.oneOfType([
        PropTypes.arrayOf(PropTypes.oneOfType([PropTypes.func, PropTypes.object, PropTypes.bool])),
        PropTypes.func,
        PropTypes.object,
    ]),
    wrapperHeight: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
    wrapperWidth: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
}

export default OrganisationLogo
