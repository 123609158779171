import { createTheme } from '@mui/material/styles'
import { deepmerge } from '@mui/utils'

export const StrikerThemeOverride = (outerTheme) =>
    createTheme(
        deepmerge(outerTheme, {
            palette: {
                ...outerTheme.palette,
                primary: {
                    main: '#3168B0',
                },
                secondary: {
                    main: '#ff0000',
                },
            },
        })
    )
