import { useState } from 'react'

import { useCalculateQuoteMutation } from '@/app/services/web-store/webStoreQuote'

const useCalculateMutation = (timeout = 3000) => {
    const [calculateQuote, { isLoading: isCalculating }] = useCalculateQuoteMutation({
        fixedCacheKey: 'shared-calculate-webstore-quote',
    })

    const [timeoutId, setTimeoutId] = useState(null)

    const triggerCalculateQuote = (args) => {
        return new Promise((resolve, reject) => {
            if (timeoutId) {
                clearTimeout(timeoutId)
            }
            const id = setTimeout(() => {
                calculateQuote(args)
                    .unwrap()
                    .then((result) => {
                        resolve(result)
                    })
                    .catch((error) => {
                        reject(error)
                    })
            }, timeout)

            setTimeoutId(id)
        })
    }

    return [triggerCalculateQuote, isCalculating]
}

export default useCalculateMutation
