import { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import Script from 'react-load-script'
import { TextField } from '@mui/material'
import PropTypes from 'prop-types'

let autocomplete

const LocationSearchInput = ({ autoFocus, fullWidth, label, onChange, placeholder, required, value }) => {
    const { t } = useTranslation()

    const [query, setQuery] = useState('')

    const handleGeolocation = () => {
        if (navigator.geolocation) {
            navigator.geolocation.getCurrentPosition((position) => {
                const geolocation = {
                    lat: position.coords.latitude,
                    lng: position.coords.longitude,
                }
                const circle = new window.google.maps.Circle({
                    center: geolocation,
                    radius: position.coords.accuracy,
                })
                autocomplete.setBounds(circle.getBounds())
            })
        }
    }

    const handleScriptLoad = () => {
        // Declare Options For Autocomplete
        const options = {
            types: ['geocode', 'establishment'],
        }

        // Initialize Google Autocomplete
        autocomplete = new window.google.maps.places.Autocomplete(document.getElementById('autocomplete'), options)

        // Avoid paying for data that you don't need by restricting the set of
        // place fields that are returned to just the address components and formatted
        // address.
        autocomplete.setFields(['address_components', 'formatted_address'])

        // Fire Event when a suggested name is selected
        autocomplete.addListener('place_changed', handlePlaceSelect)

        handleGeolocation()
    }

    const getValueFromAddressComponent = (address, typeName, attributeName) => {
        attributeName = attributeName ?? 'long_name'

        for (let i = 0; i < address.length; i++) {
            const component = address[i]
            const typesIndex = component.types.findIndex((t) => t === typeName)

            if (typesIndex > -1) {
                return component[attributeName] ?? ''
            }
        }

        return ''
    }

    const getUnitAddressFromAddress = (address) => {
        return getValueFromAddressComponent(address, 'subpremise', 'short_name')
    }

    const getStreetAddressFromAddress = (address) => {
        let streetAddress = ''

        streetAddress += getUnitAddressFromAddress(address)

        streetAddress += streetAddress ? '/' : ''

        streetAddress += getValueFromAddressComponent(address, 'street_number', 'long_name')

        streetAddress += streetAddress ? ' ' : ''

        streetAddress += getValueFromAddressComponent(address, 'route', 'long_name')

        return streetAddress
    }

    const getCityFromAddress = (address) => {
        return getValueFromAddressComponent(address, 'locality', 'short_name')
    }

    const getCountryFromAddress = (address) => {
        return getValueFromAddressComponent(address, 'country', 'short_name')
    }

    const getPostcodeFromAddress = (address) => {
        return getValueFromAddressComponent(address, 'postal_code', 'long_name')
    }

    const getStateFromAddress = (address) => {
        return getValueFromAddressComponent(address, 'administrative_area_level_1', 'short_name')
    }

    const handlePlaceSelect = () => {
        const addressObject = autocomplete.getPlace()
        const address = addressObject.address_components
        setQuery(addressObject.formatted_address)

        if (address && typeof onChange === 'function') {
            const processedAddress = {
                line1: getStreetAddressFromAddress(address),
                line2: '',
                city: getCityFromAddress(address),
                country: getCountryFromAddress(address),
                postcode: getPostcodeFromAddress(address),
                state: getStateFromAddress(address),
            }

            onChange(processedAddress)
        }
    }

    const handleInputFocus = (event) => {
        event.target.select()
    }

    const handleInputBlur = () => {
        const addressObject = autocomplete ? autocomplete.getPlace() : null
        if (addressObject) {
            setQuery(addressObject.formatted_address)
        } else {
            setQuery(value)
        }
    }

    useEffect(() => {
        setQuery(value)
    }, [value])

    return (
        <>
            <Script
                url={`https://maps.googleapis.com/maps/api/js?key=${
                    import.meta.env.VITE_GOOGLE_API_KEY
                }&libraries=places`}
                onLoad={handleScriptLoad}
            />
            <TextField
                autoFocus={autoFocus}
                fullWidth={fullWidth}
                inputProps={{ id: 'autocomplete' }}
                label={label}
                placeholder={placeholder ?? t('Enter your address')}
                required={required}
                value={query}
                onBlur={handleInputBlur}
                onChange={(event) => setQuery(event.target.value)}
                onClick={handleGeolocation}
                onFocus={handleInputFocus}
            />
        </>
    )
}

LocationSearchInput.propTypes = {
    autoFocus: PropTypes.bool,
    fullWidth: PropTypes.bool,
    label: PropTypes.string,
    placeholder: PropTypes.string,
    required: PropTypes.bool,
    value: PropTypes.string,
    onChange: PropTypes.func,
}

export default LocationSearchInput
