import { useRef, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { ArrowDropDown as ArrowDropDownIcon } from '@mui/icons-material'
import {
    Box,
    Button,
    ButtonGroup as MuiButtonGroup,
    ClickAwayListener,
    Grow,
    MenuItem,
    MenuList,
    Paper,
    Popper,
} from '@mui/material'
import PropTypes from 'prop-types'

import TbxTooltip from '../TbxTooltip/TbxTooltip'

const classes = {
    popper: {
        zIndex: 1001,
        position: 'absolute',
        top: 0,
        left: 0,
        transform: 'translate3d(140px, 50px, 0px)',
        willChange: 'transform',
    },
    dropdownArrow: {
        marginRight: 0.5,
    },
}

const ButtonGroup = ({ className, color, defaultSelectedIndex, disabled, options, splitButton, variant }) => {
    const { t } = useTranslation()

    const quoteButtonGroupAnchorRef = useRef()
    const [selectedIndex, setSelectedIndex] = useState(defaultSelectedIndex ? defaultSelectedIndex : 0)

    const [menuOpen, setMenuOpen] = useState(false)

    const handleToggleMenu = () => {
        setMenuOpen((prevOpen) => !prevOpen)
    }

    const handleButtonEvent = async (index) => {
        if (!splitButton && index === 0) {
            return handleToggleMenu()
        }

        if (options[index]) {
            options[index].handler()
        }
    }

    const handleButtonClick = () => {
        handleButtonEvent(selectedIndex)
    }

    const handleMenuItemClick = (event, index) => {
        if (splitButton) {
            setSelectedIndex(index)
        }

        setMenuOpen(false)
        handleButtonEvent(index)
    }

    const handleCloseButton = (event) => {
        if (quoteButtonGroupAnchorRef.current && quoteButtonGroupAnchorRef.current.contains(event.target)) {
            return
        }
        setMenuOpen(false)
    }

    return options.length > 1 ? (
        <div>
            <MuiButtonGroup
                aria-label="split primary button group"
                color={color}
                data-testid="sharedAddPartsButton"
                disabled={disabled}
                ref={quoteButtonGroupAnchorRef}
                sx={className}
                variant={variant}
            >
                <Button
                    disabled={disabled}
                    onClick={handleButtonClick}
                    {...options[selectedIndex].tagAttrs}
                >
                    <Box sx={!splitButton ? classes.dropdownArrow : undefined}>{options[selectedIndex].label}</Box>
                    {!splitButton ? <ArrowDropDownIcon /> : null}
                </Button>
                {splitButton ? (
                    <Button
                        aria-controls={menuOpen ? 'split-button-menu' : undefined}
                        aria-expanded={menuOpen ? 'true' : undefined}
                        aria-haspopup="menu"
                        aria-label="select merge strategy"
                        disabled={disabled}
                        size="small"
                        onClick={handleToggleMenu}
                    >
                        <ArrowDropDownIcon />
                    </Button>
                ) : null}
            </MuiButtonGroup>
            <Popper
                anchorEl={quoteButtonGroupAnchorRef.current}
                data-testid="sharedQuotePopper"
                open={menuOpen}
                role={undefined}
                sx={classes.popper}
                disablePortal
                transition
            >
                {({ TransitionProps, placement }) => (
                    <Grow
                        {...TransitionProps}
                        style={{
                            transformOrigin: placement === 'bottom' ? 'center top' : 'center bottom',
                        }}
                    >
                        <Paper>
                            <ClickAwayListener onClickAway={handleCloseButton}>
                                <MenuList id="split-button-menu">
                                    {splitButton
                                        ? options.map((option, index) => {
                                              const { id, tagAttrs } = option
                                              const title = !tagAttrs.disabled
                                                  ? ''
                                                  : id === 'addFromPartLibrary'
                                                    ? t('Select a customer to enable Part Library')
                                                    : id === 'sendToPartLibrary'
                                                      ? t('Disabled until the order is confirmed')
                                                      : ''

                                              return (
                                                  <TbxTooltip
                                                      key={option.id}
                                                      title={title}
                                                      arrow
                                                  >
                                                      <span>
                                                          <MenuItem
                                                              key={option.id}
                                                              selected={index === selectedIndex}
                                                              onClick={(event) => {
                                                                  handleMenuItemClick(event, index)
                                                                  if (option.id === 'addFromPartLibrary') {
                                                                      setSelectedIndex(0)
                                                                  }
                                                              }}
                                                              {...option.tagAttrs}
                                                          >
                                                              {option.label}
                                                          </MenuItem>
                                                      </span>
                                                  </TbxTooltip>
                                              )
                                          })
                                        : options.slice(1).map((option, index) => {
                                              const { id, label, tagAttrs } = option
                                              const title = !tagAttrs.disabled
                                                  ? ''
                                                  : id === 'sendToPartLibrary'
                                                    ? t('Disabled until the order is confirmed')
                                                    : ''
                                              return (
                                                  <TbxTooltip
                                                      key={id}
                                                      title={title}
                                                      arrow
                                                  >
                                                      <span>
                                                          <MenuItem
                                                              key={id}
                                                              selected={index === selectedIndex}
                                                              onClick={(event) => handleMenuItemClick(event, index + 1)}
                                                              {...tagAttrs}
                                                          >
                                                              {label}
                                                          </MenuItem>
                                                      </span>
                                                  </TbxTooltip>
                                              )
                                          })}
                                </MenuList>
                            </ClickAwayListener>
                        </Paper>
                    </Grow>
                )}
            </Popper>
        </div>
    ) : (
        <Button
            onClick={options[0]?.handler}
            {...options[0].tagAttrs}
        >
            {options[0]?.label}
        </Button>
    )
}

ButtonGroup.propTypes = {
    className: PropTypes.string,
    color: PropTypes.string,
    defaultSelectedIndex: PropTypes.number,
    disabled: PropTypes.bool,
    hasPartLibraryEntry: PropTypes.bool,
    options: PropTypes.arrayOf(
        PropTypes.shape({
            handler: PropTypes.func,
            label: PropTypes.string,
            tagAttrs: PropTypes.object,
        })
    ),
    quoteCustomer: PropTypes.object,
    splitButton: PropTypes.bool,
    variant: PropTypes.string,
}

export default ButtonGroup
