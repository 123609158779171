import { useTranslation } from 'react-i18next'
import { useNavigate, useParams } from 'react-router-dom'
import Button from '@mui/material/Button'

import AppBarPortal from '../../components/shared/AppBar/AppBarPortal'

const OrderedActions = () => {
    const { t } = useTranslation()
    const { organisationId } = useParams()
    const navigate = useNavigate()

    const handlePlaceOrderClick = () => {
        navigate(`/store/${organisationId}`)
    }

    return (
        <AppBarPortal>
            <Button
                color="primary"
                data-testid="place-new-order-button"
                variant="contained"
                onClick={handlePlaceOrderClick}
            >
                {t('Place new order')}
            </Button>
        </AppBarPortal>
    )
}

export default OrderedActions
