import { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { FormControl, InputLabel, MenuItem, Select } from '@mui/material'
import allCountries from 'country-region-data'
import PropTypes from 'prop-types'

const RegionSelectInput = ({ country, disabled = false, onChange, required = false, value = '' }) => {
    const { t } = useTranslation()

    const [selectedValue, setSelectedValue] = useState('')
    const [regions, setRegions] = useState([])

    useEffect(() => {
        setSelectedValue(value)
    }, [value])

    useEffect(() => {
        if (!country) {
            setRegions([])
        } else {
            const countryIndex = allCountries.findIndex((c) => c.countryShortCode === country)
            if (countryIndex > -1) {
                const selectedCountry = allCountries[countryIndex]

                setRegions(selectedCountry.regions ?? [])
            } else {
                setRegions([])
            }
        }
    }, [country])

    const handleChange = (event) => {
        const newRegionCode = event.target.value
        const newRegionIndex = regions.findIndex((region) => region.shortCode === newRegionCode)

        if (newRegionIndex > -1) {
            const newSelectedRegion = regions[newRegionIndex].shortCode

            setSelectedValue(newSelectedRegion)

            if (typeof onChange === 'function') {
                onChange(newSelectedRegion)
            }
        }
    }

    return (
        <FormControl
            disabled={disabled}
            fullWidth
        >
            <InputLabel
                data-testid="organisation-cad-file-version-label"
                id="cad-file-version-select-label"
                required={required}
            >
                {t('Select region')}
            </InputLabel>
            <Select
                id="cad-file-select"
                inputProps={{
                    'data-testid': 'organisation-cad-file-version',
                }}
                label={t('Select region')}
                labelId="cad-file-version-select-label"
                value={selectedValue}
                onChange={handleChange}
            >
                {regions.map((region) => {
                    return (
                        <MenuItem
                            key={region.shortCode}
                            value={region.shortCode}
                        >
                            {region.name}
                        </MenuItem>
                    )
                })}
            </Select>
        </FormControl>
    )
}

RegionSelectInput.propTypes = {
    country: PropTypes.string,
    disabled: PropTypes.bool,
    required: PropTypes.bool,
    value: PropTypes.string,
    onChange: PropTypes.func,
}

export default RegionSelectInput
