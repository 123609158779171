import { Box } from '@mui/material'
import Checkbox from '@mui/material/Checkbox'
import MuiTableHead from '@mui/material/TableHead'
import MuiTableSortLabel from '@mui/material/TableSortLabel'
import PropTypes from 'prop-types'

import { getCellAlignmentForColumn } from '../Table'
import TableCell from '../TableCell/TableCell'
import TableRow from '../TableRow/TableRow'

const classes = {
    visuallyHidden: {
        border: 0,
        clip: 'rect(0 0 0 0)',
        height: 1,
        margin: -1,
        overflow: 'hidden',
        padding: 0,
        position: 'absolute',
        top: 20,
        width: 1,
    },
    tableCellColumnBorders: {
        border: 'solid 1px',
        borderTop: 'none',
    },
    tableCellFullBorders: {
        border: 'solid 1px',
    },
    tableCellNoBorder: {
        border: 'none',
    },
    tableCell: {
        zIndex: 900,
        backgroundColor: 'background.default',
        fontSize: '12px',
        fontWeight: 500,
    },
    headerContainer: {
        display: 'flex',
        flexDirection: 'row',
        alignItems: 'flex-start',
        justifyContent: 'flex-start',
    },
    invertedHeaderContainer: {
        display: 'flex',
        flexDirection: 'row-reverse',
        alignItems: 'flex-start',
        justifyContent: 'flex-start',
    },
}

const TableHead = ({
    actionColumnPosition = 'end',
    allowMultiRowSelect = true,
    borderStyle = 'rows',
    cellStyle,
    columns = [],
    numberOfRowsSelected,
    onRequestSort,
    onSelectAllClick,
    order,
    orderBy,
    rowCount,
    showActionsColumn = false,
}) => {
    const tableCellStyle = Object.assign(
        {},
        classes.tableCell,
        borderStyle === 'none' ? classes.tableCellNoBorder : {},
        borderStyle === 'columns' ? classes.tableCellColumnBorders : {},
        borderStyle === 'cells' ? classes.tableCellFullBorders : {}
    )

    const createSortHandler = (property) => (event) => {
        onRequestSort(event, property)
    }

    const renderActionsColumn = () => {
        return (
            showActionsColumn && (
                <TableCell
                    classes={{ root: tableCellStyle }}
                    colSpan={1}
                    style={cellStyle}
                ></TableCell>
            )
        )
    }

    const columnIsSortable = (column) => typeof column.sortable === 'undefined' || column.sortable

    return (
        <MuiTableHead>
            <TableRow style={{ verticalAlign: 'bottom' }}>
                {allowMultiRowSelect && columns.length > 0 ? <TableCell
                        classes={{ root: tableCellStyle }}
                        padding="none"
                        style={{ ...cellStyle, width: 40 }}
                                                             >
                        <Checkbox
                            checked={rowCount > 0 ? numberOfRowsSelected === rowCount : null}
                            indeterminate={numberOfRowsSelected > 0 ? numberOfRowsSelected < rowCount : null}
                            onChange={onSelectAllClick}
                        />
                    </TableCell> : null}
                {actionColumnPosition === 'start' ? renderActionsColumn() : null}
                {columns.map(
                    (column, index) =>
                        !column.hidden && (
                            <TableCell
                                align={getCellAlignmentForColumn(column)}
                                key={index}
                                sortDirection={columnIsSortable(column) && orderBy === column.id ? order : false}
                                style={{ ...column.cellStyle }}
                                sx={{ root: tableCellStyle }}
                            >
                                <MuiTableSortLabel
                                    active={columnIsSortable(column) ? orderBy === column.id : null}
                                    direction={columnIsSortable(column) && orderBy === column.id ? order : 'asc'}
                                    hideSortIcon={!columnIsSortable(column)}
                                    sx={
                                        column.align === 'left'
                                            ? classes.headerContainer
                                            : classes.invertedHeaderContainer
                                    }
                                    onClick={columnIsSortable(column) ? createSortHandler(column.id) : undefined}
                                >
                                    {column.label}
                                    {columnIsSortable(column) && orderBy === column.id ? (
                                        <Box sx={classes.visuallyHidden}>
                                            {order === 'desc' ? 'sorted descending' : 'sorted ascending'}
                                        </Box>
                                    ) : null}
                                </MuiTableSortLabel>
                            </TableCell>
                        )
                )}
                {actionColumnPosition === 'end' ? renderActionsColumn() : null}
            </TableRow>
        </MuiTableHead>
    )
}

TableHead.propTypes = {
    numberOfRowsSelected: PropTypes.number.isRequired,
    order: PropTypes.oneOf(['asc', 'desc']).isRequired,
    orderBy: PropTypes.string.isRequired,
    rowCount: PropTypes.number.isRequired,
    onRequestSort: PropTypes.func.isRequired,
    onSelectAllClick: PropTypes.func.isRequired,
    actionColumnPosition: PropTypes.oneOf(['end', 'start']),
    allowMultiRowSelect: PropTypes.bool,
    borderStyle: PropTypes.oneOf(['none', 'columns', 'rows', 'cells']),
    cellStyle: PropTypes.object,
    columns: PropTypes.arrayOf(PropTypes.object),
    showActionsColumn: PropTypes.bool,
}

export default TableHead
