import { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { NumericFormat } from 'react-number-format'
import { useSelector } from 'react-redux'
import { useParams } from 'react-router-dom'
import { Box, MenuItem, Select, TextField, Typography } from '@mui/material'
import { useSnackbar } from 'notistack'
import PropTypes from 'prop-types'
import { useDebouncedCallback } from 'use-debounce'

import { useGetQuoteItemsQuery, useLazyGetQuoteItemsQuery, useUpdateQuoteItemMutation } from '@/app/services/quoteItems'
import { selectLocale } from '@/app/slices/appSlice'
import { selectCurrencyCode, selectOrganisationId } from '@/app/slices/organisationSlice'
import TbxTooltip from '@/common/components/TbxTooltip/TbxTooltip'
import { getCurrencyFormat } from '@/common/utils'

const Folding = ({ quoteItemId }) => {
    const { t } = useTranslation()
    const { enqueueSnackbar } = useSnackbar()
    const { quoteId } = useParams()

    const organisationId = useSelector(selectOrganisationId)

    const currencyCode = useSelector(selectCurrencyCode)
    const locale = useSelector(selectLocale)

    const { quoteItem } = useGetQuoteItemsQuery(
        {
            organisationId,
            quoteId,
        },
        {
            selectFromResult: ({ data }) => ({
                quoteItem: data?.find((item) => item.id === quoteItemId),
            }),
        }
    )

    const [getQuoteItems] = useLazyGetQuoteItemsQuery()
    const [updateQuoteItem] = useUpdateQuoteItemMutation()

    const [itemSource, setItemSource] = useState(quoteItem?.foldingSource || '')
    const [itemMaxLength, setItemMaxLength] = useState(quoteItem?.maxFoldLength || '')
    const [itemNumberOfFolds, setItemNumberOfFolds] = useState(quoteItem?.numberOfFolds || '')

    const units = quoteItem?.isImperial ? 'in' : 'mm'

    const isPartFromLibrary = Boolean(quoteItem?.partLibraryEntryId)
    const currencyFormat = getCurrencyFormat(currencyCode, locale)

    const handleUpdateQuoteItem = async (quoteItem) => {
        try {
            await updateQuoteItem({
                organisationId,
                quoteId,
                quoteItemId: quoteItem?.id,
                quoteItem,
            }).unwrap()
        } catch (error) {
            enqueueSnackbar(t('Failed to update the $t(quote) item'), {
                variant: 'error',
            })
        }
    }

    const debounceHandleUpdateQuoteItem = useDebouncedCallback(handleUpdateQuoteItem, 800)

    const handleSourceChange = async (event) => {
        const value = event?.target?.value
        if (Boolean(value) && itemSource !== value) {
            setItemSource(value)

            await handleUpdateQuoteItem({
                ...quoteItem,
                foldingSource: value,
            })

            if (value === 'Drawing') {
                await getQuoteItems({ organisationId, quoteId })
            }
        }
    }

    const handleMaxLengthChange = async (values) => {
        debounceHandleUpdateQuoteItem.cancel()
        const { floatValue } = values
        setItemMaxLength(floatValue)
        debounceHandleUpdateQuoteItem({
            ...quoteItem,
            maxFoldLength: floatValue,
            numberOfFolds: itemNumberOfFolds,
        })
    }

    const handleNumberOfFoldsChange = async (values) => {
        debounceHandleUpdateQuoteItem.cancel()
        const { floatValue } = values
        setItemNumberOfFolds(floatValue)
        debounceHandleUpdateQuoteItem({
            ...quoteItem,
            numberOfFolds: floatValue,
            maxFoldLength: itemMaxLength,
        })
    }

    useEffect(() => {
        if (!quoteItem) return
        setItemMaxLength(quoteItem?.maxFoldLength)
        setItemSource(quoteItem?.foldingSource)
        setItemNumberOfFolds(quoteItem?.numberOfFolds)
    }, [quoteItem, quoteItem?.maxFoldLength, quoteItem?.foldingSource, quoteItem?.numberOfFolds])

    return (
        <Box>
            <Box
                alignItems={'center'}
                display={'flex'}
                flexDirection={'row'}
                gap={1}
                justifyContent={'space-between'}
                margin={1}
            >
                <Box flex={1}>
                    <Typography>{t('Source')}</Typography>
                </Box>
                <TbxTooltip
                    title={isPartFromLibrary ? t('Cannot edit parts from library') : ''}
                    arrow
                >
                    <Box flex={1}>
                        <Select
                            data-testid="nqp-quote-item-source"
                            disabled={isPartFromLibrary}
                            id="select-itemSource"
                            labelId="select-itemSource-label"
                            size="small"
                            value={itemSource}
                            fullWidth
                            onChange={handleSourceChange}
                        >
                            <MenuItem
                                key="drawing"
                                value="Drawing"
                            >
                                {t('Drawing')}
                            </MenuItem>
                            <MenuItem
                                key="manual"
                                value="Manual"
                            >
                                {t('Manual')}
                            </MenuItem>
                        </Select>
                    </Box>
                </TbxTooltip>
            </Box>
            <Box
                alignItems={'center'}
                display={'flex'}
                flexDirection={'row'}
                gap={1}
                justifyContent={'space-between'}
                margin={1}
            >
                <Box flex={2}>
                    <Typography>{t('Max fold length')}</Typography>
                </Box>
                <TbxTooltip
                    title={isPartFromLibrary ? t('Cannot edit parts from library') : ''}
                    arrow
                >
                    <Box flex={2}>
                        <NumericFormat
                            allowNegative={false}
                            customInput={TextField}
                            decimalSeparator={currencyFormat.decimal}
                            disabled={itemSource === 'Drawing' || isPartFromLibrary}
                            inputProps={{
                                style: { textAlign: 'right' },
                                'data-testid': 'nqp-quote-item-max-length-quantity',
                            }}
                            placeholder={`0 ${units}`}
                            size="small"
                            suffix={` ${units}`}
                            thousandSeparator={currencyFormat.group}
                            value={itemMaxLength}
                            variant="outlined"
                            onValueChange={handleMaxLengthChange}
                        />
                    </Box>
                </TbxTooltip>
            </Box>
            <Box
                alignItems={'center'}
                display={'flex'}
                flexDirection={'row'}
                gap={1}
                justifyContent={'space-between'}
                margin={1}
            >
                <Box flex={2}>
                    <Typography>{t('Number of folds')}</Typography>
                </Box>
                <TbxTooltip
                    title={isPartFromLibrary ? t('Cannot edit parts from library') : ''}
                    arrow
                >
                    <Box flex={2}>
                        <NumericFormat
                            allowNegative={false}
                            customInput={TextField}
                            decimalSeparator={currencyFormat.decimal}
                            disabled={itemSource === 'Drawing' || isPartFromLibrary}
                            inputProps={{
                                style: { textAlign: 'right' },
                                'data-testid': 'nqp-quote-item-number-of-folds-quantity',
                            }}
                            placeholder="0"
                            size="small"
                            thousandSeparator={currencyFormat.group}
                            value={itemNumberOfFolds}
                            variant="outlined"
                            onValueChange={handleNumberOfFoldsChange}
                        />
                    </Box>
                </TbxTooltip>
            </Box>
        </Box>
    )
}

Folding.propTypes = {
    quoteItemId: PropTypes.string,
}

export default Folding
