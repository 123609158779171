import _ from 'lodash'

import CustomError from '../Utilities/CustomError'

import Arc from './Arc'
import Circle from './Circle'
import Layer from './Layer'
import Line from './Line'
import Text from './Text'

export default class Path {
    constructor(json) {
        const jsonValid =
            json &&
            Object.prototype.hasOwnProperty.call(json, 'pathId') &&
            Object.prototype.hasOwnProperty.call(json, 'layer') &&
            Object.prototype.hasOwnProperty.call(json, 'entities') &&
            Object.prototype.hasOwnProperty.call(json, 'enclosesIds') &&
            Object.prototype.hasOwnProperty.call(json, 'enclosedByIds') &&
            Object.prototype.hasOwnProperty.call(json, 'minX') &&
            Object.prototype.hasOwnProperty.call(json, 'minY') &&
            Object.prototype.hasOwnProperty.call(json, 'width') &&
            Object.prototype.hasOwnProperty.call(json, 'height') &&
            Object.prototype.hasOwnProperty.call(json, 'isClosed') &&
            Object.prototype.hasOwnProperty.call(json, 'isSolid')

        if (jsonValid) {
            this.pathId = undefined
            this.enclosedByIds = undefined
            this.isClosed = undefined
            this.isSolid = undefined
            this.enclosesIds = undefined
            this.minX = undefined
            this.minY = undefined
            this.width = undefined
            this.height = undefined

            _.extend(this, json)
            this.entities = []
            this.layer = new Layer(json.layer)
            this.svg = null
            this.svgId = Path.className + '_' + this.pathId
            for (let i = 0; i < json.entities.length; i++) {
                const e = json.entities[i]
                let newEnt
                switch (e.type) {
                    case Line.ToolpathType:
                        newEnt = new Line(e)
                        break
                    case Arc.ToolpathType:
                        newEnt = new Arc(e)
                        break
                    case Circle.ToolpathType:
                        newEnt = new Circle(e)
                        break
                    case Text.ToolpathType:
                        newEnt = new Text(e)
                        break
                    default:
                        throw new CustomError('Unsupported type: ' + e.type, 'UnsupportedEntityError')
                }
                this.entities.push(newEnt)
            }
        } else {
            throw new CustomError('Invalid json supplied', 'InvalidJSONError')
        }
    }

    toSvg() {
        if (!this.entities || this.entities.length === 0) {
            return ''
        }

        let pathStr = '<path d="'
        let lastPathPt = null

        for (let i = 0; i < this.entities.length; i++) {
            const e = this.entities[i]
            if (e.type === Circle.ToolpathType || e.type === Text.ToolpathType) {
                pathStr = typeof e.toSvg === 'function' ? e.toSvg() : null
                return pathStr
            } else {
                pathStr += e.getSvgPathStr(lastPathPt)
                lastPathPt = e.endPoint
            }
        }

        pathStr += '" />'

        return pathStr
    }

    isOuter() {
        return this.enclosedByIds.length === 0
    }

    isText() {
        return this.entities.length === 1 && this.entities[0].type === Text.ToolpathType
    }

    getClassName() {
        let cn = Path.className + ' '
        cn += this.isOuter() ? 'outer ' : 'inner '
        cn += this.isClosed ? 'closed ' : 'open '
        cn += this.isSolid ? '' : 'notsolid '
        cn += this.layer.isCutting() ? 'cutting ' : ''
        cn += this.layer.isEtching() ? 'etching ' : ''
        cn += this.layer.isInfo() ? 'info ' : ''
        cn += this.layer.isFolding() ? 'folding ' : ''
        return cn
    }

    isEnclosedBy(id) {
        return this.enclosedByIds.findIndex((e) => e === id) !== -1
    }

    encloses(id) {
        return this.enclosesIds.findIndex((e) => e === id) !== -1
    }

    enclosedWithin(minX, minY, width, height) {
        return (
            minX < this.minX &&
            minY < this.minY &&
            minX + width > this.minX + this.width &&
            minY + height > this.minY + this.height
        )
    }
}

Path.className = 'path'
