import { memo, useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useSelector } from 'react-redux'
import { useParams } from 'react-router-dom'
import { Add, Create, DoubleArrowRounded } from '@mui/icons-material'
import GradientIcon from '@mui/icons-material/Gradient'
import InfoIcon from '@mui/icons-material/Info'
import { Box, IconButton } from '@mui/material'
import Drawer from '@mui/material/Drawer'
import { enqueueSnackbar } from 'notistack'
import PropTypes from 'prop-types'

import { useGetQuoteItemsQuery, useUpdateQuoteItemMutation } from '@/app/services/quoteItems'
import { selectOrganisationId } from '@/app/slices/organisationSlice'
import { LayersIcon, PartDetailsIcon, ProcessesIcon } from '@/common/icons'
import SecondaryProcessesModal from '@/features/quotes/components/QuoteItemList/ItemContent/SecondaryProcesses/SecondaryProcessesModal'

import DrawerItem from './DrawerItem'
import Folding from './Folding'
import Layers from './Layers'
import PartDetails from './PartDetails'
import PartProperties from './PartProperties'
import SecondaryProcesses from './SecondaryProcesses'

const classes = {
    drawerRoot: (drawerOpen) => ({
        width: drawerOpen ? 400 : 50,
        flexShrink: 0,

        '& .MuiDrawer-paper': {
            width: drawerOpen ? 400 : 50,
            height: 'calc(100% - 72px)',
            top: 72,
            boxSizing: 'border-box',
            borderRight: '1px solid hsl(0, 0%, 15%)',
            backgroundColor: 'hsl(0, 0%, 15%)',
        },
    }),
    icon: {
        color: 'action.active',
    },
}

const DrawingDoctorDrawer = ({ drawing, quoteItemId }) => {
    const { t } = useTranslation()
    const { quoteId } = useParams()

    const organisationId = useSelector(selectOrganisationId)
    const { quoteItem } = useGetQuoteItemsQuery(
        {
            organisationId,
            quoteId,
        },
        {
            selectFromResult: ({ data }) => ({
                quoteItem: data?.find((item) => item.id === quoteItemId),
            }),
        }
    )

    const [drawerOpen, setDrawerOpen] = useState(true)
    const [isEditDialogOpen, setIsEditDialogOpen] = useState(false)
    const [itemSecondaryProcesses, setItemSecondaryProcesses] = useState([])

    const [updateQuoteItem] = useUpdateQuoteItemMutation()

    const isRotary = 'profile' in quoteItem && quoteItem.profile !== ''

    const isPartFromLibrary = Boolean(quoteItem?.partLibraryEntryId)

    const handleUpdateQuoteItem = async (quoteItem) => {
        try {
            const quoteId = quoteItem.quoteId
            await updateQuoteItem({
                organisationId,
                quoteId,
                quoteItemId: quoteItem?.id,
                quoteItem,
            }).unwrap()
        } catch (error) {
            enqueueSnackbar(t('Failed to update the $t(quote) item'), {
                variant: 'error',
            })
        }
    }

    const handleEditButtonClick = (event) => {
        event.stopPropagation()
        setIsEditDialogOpen(true)
    }

    const onCloseEditDialog = async (processes) => {
        setIsEditDialogOpen(false)
        if (processes) {
            setItemSecondaryProcesses(processes)
            await handleUpdateQuoteItem({
                ...quoteItem,
                secondaryProcesses: processes,
            })
        }
    }

    const getEditIcon = () => {
        return (
            <IconButton
                aria-label="edit"
                color="primary"
                data-testid="nqp-secondary-processes-add-button"
                size="small"
                sx={{ p: 0, ml: 2 }}
                onClick={(event) => handleEditButtonClick(event)}
                onFocus={(event) => event.stopPropagation()}
            >
                {itemSecondaryProcesses?.length > 0 ? <Create fontSize="small" /> : <Add fontSize="small" />}
            </IconButton>
        )
    }

    useEffect(() => {
        setItemSecondaryProcesses(quoteItem.secondaryProcesses)
    }, [quoteItem.secondaryProcesses])

    return (
        <>
            <Drawer
                anchor="left"
                disableAutoFocus={true}
                disableEnforceFocus={true}
                hideBackdrop={false}
                open={drawerOpen}
                sx={classes.drawerRoot(drawerOpen)}
                variant="permanent"
                onClose={() => setDrawerOpen(false)}
            >
                <Box
                    display="flex"
                    justifyContent={drawerOpen ? 'right' : 'center'}
                >
                    {drawerOpen ? (
                        <DoubleArrowRounded
                            fontSize="small"
                            sx={{
                                color: 'action.active',
                                transform: 'rotate(180deg)',
                                margin: '4px 16px',
                                cursor: 'pointer',
                            }}
                            onClick={() => setDrawerOpen(false)}
                        />
                    ) : (
                        <DoubleArrowRounded
                            fontSize="small"
                            sx={{
                                color: 'action.active',
                                margin: '4px 16px',
                                cursor: 'pointer',
                            }}
                            onClick={() => setDrawerOpen(true)}
                        />
                    )}
                </Box>
                <DrawerItem
                    drawerOpen={drawerOpen}
                    handleOpenDrawer={() => setDrawerOpen(true)}
                    icon={
                        <LayersIcon
                            fontSize="small"
                            sx={classes.icon}
                        />
                    }
                    title={t('Layers')}
                >
                    <Layers
                        drawing={drawing}
                        quoteItemId={quoteItemId}
                    />
                </DrawerItem>
                <DrawerItem
                    drawerOpen={drawerOpen}
                    handleOpenDrawer={() => setDrawerOpen(true)}
                    icon={
                        <InfoIcon
                            fontSize="small"
                            sx={classes.icon}
                        />
                    }
                    title={t('Part details')}
                >
                    <PartDetails
                        drawing={drawing}
                        quoteItemId={quoteItemId}
                    />
                </DrawerItem>
                <DrawerItem
                    drawerOpen={drawerOpen}
                    handleOpenDrawer={() => setDrawerOpen(true)}
                    icon={
                        <PartDetailsIcon
                            fontSize="small"
                            sx={classes.icon}
                        />
                    }
                    title={t('Properties')}
                >
                    <PartProperties quoteItemId={quoteItemId} />
                </DrawerItem>
                {!isRotary ? (
                    <DrawerItem
                        drawerOpen={drawerOpen}
                        handleOpenDrawer={() => setDrawerOpen(true)}
                        icon={
                            <ProcessesIcon
                                fontSize="small"
                                sx={classes.icon}
                            />
                        }
                        title={t('Folding')}
                    >
                        <Folding quoteItemId={quoteItemId} />
                    </DrawerItem>
                ) : null}
                <DrawerItem
                    drawerOpen={drawerOpen}
                    editButton={!isPartFromLibrary ? getEditIcon() : null}
                    handleOpenDrawer={() => setDrawerOpen(true)}
                    icon={
                        <GradientIcon
                            fontSize="small"
                            sx={classes.icon}
                        />
                    }
                    title={t('Secondary processes')}
                >
                    <SecondaryProcesses quoteItemId={quoteItemId} />
                </DrawerItem>
            </Drawer>

            {isEditDialogOpen ? (
                <SecondaryProcessesModal
                    accentColor="primary"
                    open={isEditDialogOpen}
                    secondaryProcesses={quoteItem?.secondaryProcesses}
                    onClose={onCloseEditDialog}
                />
            ) : null}
        </>
    )
}

DrawingDoctorDrawer.propTypes = {
    drawing: PropTypes.object,
    quoteItemId: PropTypes.string,
}

export default memo(DrawingDoctorDrawer)
