import { useMemo } from 'react'
import { useTranslation } from 'react-i18next'
import { useDispatch, useSelector } from 'react-redux'
import { useParams } from 'react-router'
import { ToggleButton, ToggleButtonGroup } from '@mui/material'

import { selectQuoteItems } from '@/app/services/quoteItems'
import { selectOrganisationId } from '@/app/slices/organisationSlice'
import { selectFilterOptions, setFilterOptions } from '@/app/slices/quoteItemsSlice'
import { quoteIssuesCount } from '@/common/utils/quoteUtilities'

import QuoteItemStatus from '../common/QuoteItemStatus'

const QuoteItemListFilter = () => {
    const dispatch = useDispatch()
    const { t } = useTranslation()
    const { quoteId } = useParams()

    const filterOptions = useSelector(selectFilterOptions)

    const organisationId = useSelector(selectOrganisationId)
    const quoteItems = useSelector((state) => selectQuoteItems(state, { organisationId, quoteId: quoteId }))
    const issuesCount = useMemo(() => quoteIssuesCount(quoteItems), [quoteItems])

    const handleFilterOptionsChange = (event, newFilterOptions) => {
        if (newFilterOptions !== null) {
            dispatch(setFilterOptions(newFilterOptions))
        }
    }

    return (
        <ToggleButtonGroup
            aria-label="filter options"
            color="secondary"
            data-testid="filter-options"
            value={filterOptions}
            exclusive
            onChange={handleFilterOptionsChange}
        >
            <ToggleButton
                aria-label="show all items"
                data-testid="show-all-parts-button"
                sx={{ py: '7px', color: 'text.secondary' }}
                value="showAllItems"
            >
                {t('All')}
            </ToggleButton>

            {issuesCount.unset > 0 ? (
                <ToggleButton
                    aria-label="show unset items"
                    data-testid="show-unset-parts-button"
                    disabled={issuesCount.unset === 0}
                    sx={{ py: '7px' }}
                    value="showUnsetItems"
                >
                    <QuoteItemStatus
                        count={issuesCount.unset}
                        marginLeft={0}
                        messages="Show unset parts"
                        status="unset"
                        useBackground={false}
                    />
                </ToggleButton>
            ) : null}

            {issuesCount.errors > 0 ? (
                <ToggleButton
                    aria-label="show error items"
                    data-testid="show-errors-parts-button"
                    disabled={issuesCount.errors === 0}
                    sx={{ py: '7px' }}
                    value="showErrorItems"
                >
                    <QuoteItemStatus
                        count={issuesCount.errors}
                        marginLeft={0}
                        messages="Show parts with errors"
                        status="error"
                        useBackground={false}
                    />
                </ToggleButton>
            ) : null}

            {issuesCount.warnings > 0 ? (
                <ToggleButton
                    aria-label="show warning items"
                    data-testid="show-warnings-parts-button"
                    disabled={issuesCount.warnings === 0}
                    sx={{ py: '7px' }}
                    value="showWarningItems"
                >
                    <QuoteItemStatus
                        count={issuesCount.warnings}
                        marginLeft={0}
                        messages="Show parts with warnings"
                        status="warning"
                        useBackground={false}
                    />
                </ToggleButton>
            ) : null}

            {issuesCount.success > 0 ? (
                <ToggleButton
                    aria-label="show success items"
                    data-testid="show-no-issues-parts-button"
                    disabled={issuesCount.success === 0}
                    sx={{ py: '7px' }}
                    value="showSuccessItems"
                >
                    <QuoteItemStatus
                        count={issuesCount.success}
                        marginLeft={0}
                        messages="Show parts without any issues"
                        status="success"
                        useBackground={false}
                    />
                </ToggleButton>
            ) : null}
        </ToggleButtonGroup>
    )
}

export default QuoteItemListFilter
