import { useState } from 'react'
import { Controller, useForm } from 'react-hook-form'
import { useTranslation } from 'react-i18next'
import { useSelector } from 'react-redux'
import { Box, Button, InputAdornment, InputLabel, MenuItem, Select, TextField, Typography } from '@mui/material'
import { useSnackbar } from 'notistack'
import PropTypes from 'prop-types'

import { useCreateMaterialMutation } from '@/app/services/materials'
import { selectOrganisationId, selectPaidFeatures, selectUseImperialUnits } from '@/app/slices/organisationSlice'
import { TbxTooltip } from '@/common/components'
import UpgradePlanLink from '@/common/components/UpgradePlanLink/UpgradePlanLink'
import { useToolBoxTreatments } from '@/common/hooks'
import { percentageToFraction } from '@/common/utils'

const classes = {
    title: {
        fontWeight: 500,
        fontSize: '24px',
        lineHeight: '28px',
        color: 'text.primary',
        marginBottom: '24px',
    },
    buttonsContainer: {
        display: 'flex',
        justifyContent: 'space-evenly',
        marginTop: '24px',
        paddingBottom: '36px', // leave room at the bottom of the form for any hovering action buttons being injected
    },
    input: {
        marginBottom: '16px',
    },
    button: {
        fontWeight: 500,
        minWidth: '142px',
        height: '36px',
    },
    addressTitle: {
        fontWeight: 500,
        fontSize: '18px',
        lineHeight: '28px',
        color: 'text.primary',
        margin: '16px 0',
    },
    checkboxLabel: {
        color: '#5E7387',
        fontSize: '14px',
        fontWeight: 400,
        lineHeight: '21px',
        letterSpacing: '0px',
    },
}

const AddMaterialForm = ({ onCancel, onSave }) => {
    const { t } = useTranslation()
    const { enqueueSnackbar } = useSnackbar()

    const [isSaving, setIsSaving] = useState(false)

    const organisationId = useSelector(selectOrganisationId)
    const useImperialUnits = useSelector(selectUseImperialUnits)
    const paidFeatures = useSelector(selectPaidFeatures)

    const [createMaterial] = useCreateMaterialMutation()

    const { showTube } = useToolBoxTreatments()

    const { control, formState, handleSubmit } = useForm({
        mode: 'all',
        defaultValues: {
            materialName: '',
            type: 'Flat',
            density: '',
            standardMarkupPercentage: '',
            customerSuppliedMarkupPercentage: '',
        },
    })

    const handleAddMaterialButtonClick = async (data, _) => {
        setIsSaving(true)
        try {
            const newMaterial = await createMaterial({
                organisationId,
                material: {
                    ...data,
                    standardMarkupPercentage: data.standardMarkupPercentage
                        ? percentageToFraction(data.standardMarkupPercentage)
                        : null,
                    customerSuppliedMarkupPercentage: data.customerSuppliedMarkupPercentage
                        ? percentageToFraction(data.customerSuppliedMarkupPercentage)
                        : null,
                },
            }).unwrap()

            enqueueSnackbar(t('Material added'), { variant: 'success' })
            onSave(newMaterial.materialId)
        } catch (error) {
            enqueueSnackbar(t('An error occurred'), { variant: 'error' })
        } finally {
            setIsSaving(false)
        }
    }

    return (
        <>
            <Typography sx={classes.title}>{t('Add new material')}</Typography>
            <Controller
                control={control}
                name="materialName"
                render={({ field: { onBlur, onChange, value }, fieldState }) => (
                    <TextField
                        error={fieldState.invalid}
                        helperText={`${fieldState.error?.message || t('Required')}. ${t(
                            'This name can not be edited once created'
                        )}`}
                        label={t('Name')}
                        sx={classes.input}
                        value={value}
                        fullWidth
                        required
                        onBlur={onBlur}
                        onChange={onChange}
                    />
                )}
                rules={{ required: true }}
            />
            {showTube ? (
                <Controller
                    control={control}
                    name="type"
                    render={({ field: { onBlur, onChange, value }, fieldState }) => (
                        <>
                            <InputLabel datatest-id="material-type-label">{`${t('Type')} *`}</InputLabel>
                            <Select
                                disabled={!paidFeatures.hasUnfoldTube}
                                error={fieldState.invalid}
                                sx={classes.input}
                                value={value}
                                variant="standard"
                                fullWidth
                                required
                                onBlur={onBlur}
                                onChange={onChange}
                            >
                                <MenuItem
                                    key="Flat"
                                    value="Flat"
                                >
                                    {t('Flat')}
                                </MenuItem>
                                <MenuItem
                                    key="Rotary"
                                    value="Rotary"
                                >
                                    {t('Rotary')}
                                </MenuItem>
                            </Select>
                        </>
                    )}
                    rules={{ required: true }}
                />
            ) : null}

            <Typography sx={classes.addressTitle}>{t('Details')}</Typography>

            <Controller
                control={control}
                name="density"
                render={({ field: { onBlur, onChange, value }, fieldState }) => (
                    <TextField
                        error={fieldState.invalid}
                        helperText={fieldState.error?.message || t('Required')}
                        inputProps={{
                            'data-testid': 'add-material-density',
                        }}
                        InputProps={{
                            endAdornment: (
                                <InputAdornment position="end">{useImperialUnits ? 'lb/ft3' : 'kg/m3'}</InputAdornment>
                            ),
                        }}
                        label={t('Density')}
                        sx={classes.input}
                        type="number"
                        value={value}
                        fullWidth
                        required
                        onBlur={onBlur}
                        onChange={onChange}
                    />
                )}
                rules={{ required: 'Required' }}
            />

            <Controller
                control={control}
                name="standardMarkupPercentage"
                render={({ field: { onBlur, onChange, value }, fieldState }) => (
                    <TextField
                        error={fieldState.invalid}
                        helperText={fieldState.error?.message || ''}
                        InputProps={{
                            endAdornment: <InputAdornment position="start">%</InputAdornment>,
                        }}
                        inputProps={{
                            'data-testid': 'add-material-standard-markup',
                        }}
                        label={t('Standard markup')}
                        sx={classes.input}
                        type="number"
                        value={value}
                        fullWidth
                        onBlur={onBlur}
                        onChange={onChange}
                    />
                )}
            />

            <Controller
                control={control}
                name="customerSuppliedMarkupPercentage"
                render={({ field: { onBlur, onChange, value }, fieldState }) => (
                    <TbxTooltip
                        leaveDelay={500}
                        title={paidFeatures.hasCustomerSuppliedMaterial ? '' : <UpgradePlanLink />}
                        arrow
                        disableFocusListener
                        disableTouchListener
                    >
                        <TextField
                            disabled={!paidFeatures.hasCustomerSuppliedMaterial}
                            error={fieldState.invalid}
                            helperText={`${t('(% material cost)')} ${fieldState.error?.message || ''}`.trim()}
                            InputProps={{
                                endAdornment: <InputAdornment position="start">%</InputAdornment>,
                            }}
                            inputProps={{
                                'data-testid': 'add-material-customer-supplied-handling-fee',
                            }}
                            label={t('Customer-supplied material handling fee')}
                            sx={classes.input}
                            type="number"
                            value={paidFeatures.hasCustomerSuppliedMaterial ? value : ''}
                            fullWidth
                            onBlur={onBlur}
                            onChange={onChange}
                        />
                    </TbxTooltip>
                )}
            />
            <Box sx={classes.buttonsContainer}>
                <Button
                    color="primary"
                    disabled={isSaving}
                    key="cancel"
                    sx={classes.button}
                    variant="outlined"
                    onClick={onCancel}
                >
                    {t('Cancel')}
                </Button>
                <Button
                    color="primary"
                    disabled={!formState.isValid || isSaving}
                    key="update"
                    sx={classes.button}
                    variant="contained"
                    onClick={handleSubmit(handleAddMaterialButtonClick)}
                >
                    {isSaving ? t('Saving...') : t('Add')}
                </Button>
            </Box>
        </>
    )
}

AddMaterialForm.propTypes = {
    onCancel: PropTypes.func,
    onSave: PropTypes.func,
}

export default AddMaterialForm
