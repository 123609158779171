import { useEffect, useMemo, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { NumericFormat } from 'react-number-format'
import { useSelector } from 'react-redux'
import { useParams } from 'react-router-dom'
import { Box, MenuItem, Select, TextField, Typography } from '@mui/material'
import { useSnackbar } from 'notistack'
import PropTypes from 'prop-types'
import { useDebouncedCallback } from 'use-debounce'

import {
    useGetCuttingTechnologiesQuery,
    useLazyGetCuttingTechnologyMaterialsQuery,
    useLazyGetMaterialThicknessesQuery,
    useLazyGetThicknessSheetsQuery,
} from '@/app/services/cuttingTechnologies'
import { useGetQuoteItemsQuery, useUpdateQuoteItemMutation } from '@/app/services/quoteItems'
import { selectLocale } from '@/app/slices/appSlice'
import { selectCurrencyCode, selectOrganisationId, selectUseImperialUnits } from '@/app/slices/organisationSlice'
import TbxTooltip from '@/common/components/TbxTooltip/TbxTooltip'
import { getCurrencyFormat, getPartDimensions } from '@/common/utils'
import { formatNumber } from '@/features/web-store/helpers/utilities'

const PartProperties = ({ quoteItemId }) => {
    const { t } = useTranslation()
    const { enqueueSnackbar } = useSnackbar()
    const { quoteId } = useParams()

    const locale = useSelector(selectLocale)
    const organisationId = useSelector(selectOrganisationId)
    const useImperialUnits = useSelector(selectUseImperialUnits)
    const currencyCode = useSelector(selectCurrencyCode)

    const { quoteItem } = useGetQuoteItemsQuery(
        {
            organisationId,
            quoteId,
        },
        {
            selectFromResult: ({ data }) => ({
                quoteItem: data?.find((item) => item.id === quoteItemId),
            }),
        }
    )

    const [updateQuoteItem] = useUpdateQuoteItemMutation()

    const [itemCuttingTechnology, setItemCuttingTechnology] = useState(quoteItem?.cuttingTechnologyId || '')
    const [itemMaterial, setItemMaterial] = useState(quoteItem?.materialId || '')
    const [itemThickness, setItemThickness] = useState(quoteItem?.thickness || '')
    const [itemSheet, setItemSheet] = useState(quoteItem?.sheetId || '')
    const [itemConsumptionMode, setItemConsumptionMode] = useState(quoteItem?.materialConsumptionMode || '')
    const [itemIsCustomerSuppliedMaterial, setItemIsCustomerSuppliedMaterial] = useState(
        quoteItem?.isCustomerSuppliedMaterial || false
    )
    const [itemQuantity, setItemQuantity] = useState(quoteItem?.quantity || 1)

    const [quantityError, setQuantityError] = useState(false)

    const partDimensions = useMemo(() => getPartDimensions(quoteItem), [quoteItem])
    const units = useMemo(() => (useImperialUnits ? 'in' : 'mm'), [useImperialUnits])
    const mode = useMemo(() => ('profile' in quoteItem ? 'rotary' : 'flat'), [quoteItem])

    const isPartFromLibrary = Boolean(quoteItem?.partLibraryEntryId)

    const currencyFormat = getCurrencyFormat(currencyCode, locale)

    const handleUpdateQuoteItem = async (quoteItem) => {
        try {
            await updateQuoteItem({
                organisationId,
                quoteId,
                quoteItemId: quoteItem?.id,
                quoteItem,
            }).unwrap()
        } catch (error) {
            enqueueSnackbar(t('Failed to update the $t(quote) item'), {
                variant: 'error',
            })
        }
    }

    const debounceHandleUpdateQuoteItem = useDebouncedCallback(handleUpdateQuoteItem, 800)

    const { data: cuttingTechOptionsData, isLoading: isLoadingCuttingTechs } = useGetCuttingTechnologiesQuery({
        organisationId,
        params: {
            mode: mode,
        },
    })

    const cuttingTechOptions = useMemo(() => {
        if (isLoadingCuttingTechs) return []

        return Object.values(cuttingTechOptionsData).filter((tech) => !tech?.isDeleted)
    }, [cuttingTechOptionsData, isLoadingCuttingTechs])

    const [getCuttingTechnologyMaterials, { data: materialOptions, isLoading: isLoadingMaterials }] =
        useLazyGetCuttingTechnologyMaterialsQuery()

    const [getMaterialThicknesses, { data: thicknessOptions, isLoading: isLoadingThicknesses }] =
        useLazyGetMaterialThicknessesQuery()

    const [getThicknessSheets, { data: sheetOptions }] = useLazyGetThicknessSheetsQuery()

    const handleCuttingTechChange = async (event) => {
        const value = event?.target?.value
        if (Boolean(value) && itemCuttingTechnology !== value) {
            setItemCuttingTechnology(value)
            setItemMaterial('')
            setItemThickness('')
            setItemSheet('')
            setItemConsumptionMode('')
            setItemIsCustomerSuppliedMaterial(false)

            await handleUpdateQuoteItem({
                ...quoteItem,
                cuttingTechnologyId: value,
                materialId: '',
                thickness: '',
                sheetId: '',
                materialConsumptionMode: '',
                isCustomerSuppliedMaterial: '',
            })

            getCuttingTechnologyMaterials(
                {
                    organisationId,
                    cuttingTechnologyId: value,
                },
                true
            )
        }
    }

    const handleMaterialChange = async (event) => {
        const value = event?.target?.value
        if (Boolean(value) && itemMaterial !== value) {
            setItemMaterial(value)
            setItemThickness('')
            setItemSheet('')
            setItemConsumptionMode('')
            setItemIsCustomerSuppliedMaterial(false)
            await handleUpdateQuoteItem({
                ...quoteItem,
                materialId: value,
                thickness: '',
                sheetId: '',
                materialConsumptionMode: '',
                isCustomerSuppliedMaterial: '',
            })
            getMaterialThicknesses(
                {
                    organisationId,
                    cuttingTechnologyId: itemCuttingTechnology,
                    materialId: value,
                    params: {
                        mode: mode,
                        rotaryProfile: quoteItem?.profile,
                        rotaryProfileLength: quoteItem?.rotaryProfileLength,
                        rotaryProfileWidth: quoteItem?.rotaryProfileWidth,
                        diameter: quoteItem?.diameter,
                        rotaryPartLength: quoteItem?.rotaryPartLength,
                    },
                },
                true
            )

            getThicknessSheets(
                {
                    organisationId,
                    cuttingTechnologyId: itemCuttingTechnology,
                    materialId: itemMaterial,
                    params: {
                        mode: mode,
                        thickness: 0,
                        rotaryProfile: quoteItem?.profile,
                        rotaryProfileLength: quoteItem?.rotaryProfileLength,
                        rotaryProfileWidth: quoteItem?.rotaryProfileWidth,
                        diameter: quoteItem?.diameter,
                        rotaryPartLength: quoteItem?.rotaryPartLength,
                    },
                },
                true
            )
        }
    }

    const handleThicknessChange = async (event) => {
        const value = event?.target?.value
        if (Boolean(value) && itemThickness !== value) {
            setItemThickness(value)
            setItemConsumptionMode('')
            setItemIsCustomerSuppliedMaterial(false)
            const data = await getThicknessSheets(
                {
                    organisationId,
                    cuttingTechnologyId: itemCuttingTechnology,
                    materialId: itemMaterial,
                    params: {
                        mode: mode,
                        thickness: value,
                        rotaryProfile: quoteItem?.profile,
                        rotaryProfileLength: quoteItem?.rotaryProfileLength,
                        rotaryProfileWidth: quoteItem?.rotaryProfileWidth,
                        diameter: quoteItem?.diameter,
                        rotaryPartLength: quoteItem?.rotaryPartLength,
                    },
                },
                true
            ).unwrap()

            const firstSheet = data[0]

            const { defaultMaterialConsumptionMode, isSoldByOrganisation, sheetId } = firstSheet

            const shouldUpdateSheetProps = !data.map((item) => item.sheetId).includes(itemSheet)

            if (shouldUpdateSheetProps) {
                setItemSheet(sheetId)
                setItemConsumptionMode(defaultMaterialConsumptionMode || '')
                setItemIsCustomerSuppliedMaterial(!isSoldByOrganisation)
            }

            await handleUpdateQuoteItem({
                ...quoteItem,
                thickness: value,
                sheetId: shouldUpdateSheetProps ? sheetId : '',
                materialConsumptionMode: shouldUpdateSheetProps ? defaultMaterialConsumptionMode : '',
                isCustomerSuppliedMaterial: shouldUpdateSheetProps ? !isSoldByOrganisation : false,
            })
        }
    }

    const handleSheetChange = async (event) => {
        const value = event?.target?.value

        if (Boolean(value) && itemSheet !== value) {
            const { defaultMaterialConsumptionMode, isSoldByOrganisation } =
                sheetOptions?.find((option) => option.sheetId === itemSheet) || {}
            setItemSheet(value)
            setItemConsumptionMode(defaultMaterialConsumptionMode)
            setItemIsCustomerSuppliedMaterial(!isSoldByOrganisation)
            await handleUpdateQuoteItem({
                ...quoteItem,
                sheetId: value,
                materialConsumptionMode: defaultMaterialConsumptionMode,
                isCustomerSuppliedMaterial: !isSoldByOrganisation,
            })
        }
    }

    const handleQuantityChange = async (values) => {
        const { floatValue } = values

        setQuantityError(!floatValue || isNaN(floatValue) || floatValue <= 0)

        setItemQuantity(floatValue)

        if (!isNaN(floatValue) || floatValue >= 1) {
            await debounceHandleUpdateQuoteItem({ ...quoteItem, quantity: floatValue })
        }
    }

    useEffect(() => {
        setItemQuantity(quoteItem.quantity)
    }, [quoteItem?.quantity])

    useEffect(() => {
        setItemCuttingTechnology(quoteItem?.cuttingTechnologyId || '')
        if (quoteItem?.cuttingTechnologyId) {
            getCuttingTechnologyMaterials(
                {
                    organisationId,
                    cuttingTechnologyId: quoteItem?.cuttingTechnologyId,
                },
                true
            )
        }
    }, [quoteItem?.cuttingTechnologyId])

    useEffect(() => {
        setItemMaterial(quoteItem?.materialId || '')
        if (quoteItem?.cuttingTechnologyId && quoteItem?.materialId) {
            getMaterialThicknesses(
                {
                    organisationId,
                    cuttingTechnologyId: quoteItem?.cuttingTechnologyId,
                    materialId: quoteItem?.materialId,
                    params: {
                        mode: mode,
                        rotaryProfile: quoteItem?.profile,
                        rotaryProfileLength: quoteItem?.rotaryProfileLength,
                        rotaryProfileWidth: quoteItem?.rotaryProfileWidth,
                        diameter: quoteItem?.diameter,
                        rotaryPartLength: quoteItem?.rotaryPartLength,
                    },
                },
                true
            )
        }
    }, [quoteItem?.materialId])

    useEffect(() => {
        setItemThickness(quoteItem?.thickness || '')

        if (quoteItem?.cuttingTechnologyId && quoteItem?.materialId && quoteItem?.thickness) {
            getThicknessSheets(
                {
                    organisationId,
                    cuttingTechnologyId: quoteItem?.cuttingTechnologyId,
                    materialId: quoteItem?.materialId,
                    params: {
                        mode: mode,
                        thickness: quoteItem?.thickness,
                        rotaryProfile: quoteItem?.profile,
                        rotaryProfileLength: quoteItem?.rotaryProfileLength,
                        rotaryProfileWidth: quoteItem?.rotaryProfileWidth,
                        diameter: quoteItem?.diameter,
                        rotaryPartLength: quoteItem?.rotaryPartLength,
                    },
                },
                true
            )
        }
    }, [quoteItem?.thickness, thicknessOptions])

    useEffect(() => {
        setItemSheet(quoteItem?.sheetId || '')
    }, [quoteItem?.sheetId])

    useEffect(() => {
        setItemConsumptionMode(quoteItem?.materialConsumptionMode || '')
    }, [quoteItem?.materialConsumptionMode])

    useEffect(() => {
        setItemIsCustomerSuppliedMaterial(quoteItem?.isCustomerSuppliedMaterial || '')
    }, [quoteItem?.isCustomerSuppliedMaterial])

    return (
        <Box key={quoteItemId}>
            <Box
                alignItems={'center'}
                display={'flex'}
                flexDirection={'row'}
                gap={1}
                justifyContent={'space-between'}
                margin={1}
            >
                <Box flex={1}>
                    <Typography>{t('Quantity')}</Typography>
                </Box>
                <Box flex={1}>
                    <NumericFormat
                        allowNegative={false}
                        customInput={TextField}
                        decimalSeparator={currencyFormat.decimal}
                        error={quantityError}
                        helperText={quantityError ? t('Quantity cannot be less than 1') : null}
                        inputProps={{
                            style: { textAlign: 'right' },
                            'data-testid': 'nqp-quote-item-quantity',
                        }}
                        placeholder="0"
                        size="small"
                        thousandSeparator={currencyFormat.group}
                        value={itemQuantity}
                        variant="outlined"
                        onValueChange={handleQuantityChange}
                    />
                </Box>
            </Box>
            <Box
                alignItems={'center'}
                display={'flex'}
                flexDirection={'row'}
                gap={1}
                justifyContent={'space-between'}
                margin={1}
            >
                <Box flex={1}>
                    <Typography>{t('Cutting technology')}</Typography>
                </Box>
                <TbxTooltip
                    title={isPartFromLibrary ? t('Cannot edit parts from library') : ''}
                    arrow
                >
                    <Box flex={1}>
                        <Select
                            disabled={isPartFromLibrary}
                            id="select-itemCuttingTechnology"
                            inputProps={{ 'data-testid': 'nqp-quote-item-cutting-technology' }}
                            labelId="select-itemCuttingTechnology-label"
                            SelectDisplayProps={{ style: { textAlign: 'right' } }}
                            size="small"
                            value={itemCuttingTechnology}
                            fullWidth
                            onChange={handleCuttingTechChange}
                        >
                            {!isLoadingCuttingTechs
                                ? cuttingTechOptions?.map((ct) => (
                                      <MenuItem
                                          key={ct.cuttingTechnologyId}
                                          value={ct.cuttingTechnologyId}
                                      >
                                          {ct.name}
                                      </MenuItem>
                                  ))
                                : null}
                        </Select>
                    </Box>
                </TbxTooltip>
            </Box>
            <Box
                alignItems={'center'}
                display={'flex'}
                flexDirection={'row'}
                gap={1}
                justifyContent={'space-between'}
                margin={1}
            >
                <Box flex={1}>
                    <Typography>{t('Material')}</Typography>
                </Box>
                <TbxTooltip
                    title={isPartFromLibrary ? t('Cannot edit parts from library') : ''}
                    arrow
                >
                    <Box flex={3}>
                        <Select
                            disabled={isPartFromLibrary}
                            id="select-itemMaterial"
                            inputProps={{ 'data-testid': 'nqp-quote-item-material' }}
                            labelId="select-itemMaterial-label"
                            SelectDisplayProps={{ style: { textAlign: 'right' } }}
                            size="small"
                            value={itemMaterial}
                            fullWidth
                            onChange={handleMaterialChange}
                        >
                            {!isLoadingMaterials
                                ? materialOptions?.map((material) => (
                                      <MenuItem
                                          key={material.materialId}
                                          value={material.materialId}
                                      >
                                          {material.materialName}
                                      </MenuItem>
                                  ))
                                : null}
                        </Select>
                    </Box>
                </TbxTooltip>
            </Box>
            <Box
                alignItems={'center'}
                display={'flex'}
                flexDirection={'row'}
                gap={1}
                justifyContent={'space-between'}
                margin={1}
            >
                <Box flex={1}>
                    <Typography>{t('Thickness')}</Typography>
                </Box>
                <TbxTooltip
                    title={isPartFromLibrary ? t('Cannot edit parts from library') : ''}
                    arrow
                >
                    <Box flex={1}>
                        <Select
                            disabled={isPartFromLibrary}
                            id="select-itemThickness"
                            inputProps={{ 'data-testid': 'nqp-quote-item-thickness' }}
                            labelId="select-itemThickness-label"
                            SelectDisplayProps={{ style: { textAlign: 'right' } }}
                            size="small"
                            value={itemThickness}
                            fullWidth
                            onChange={handleThicknessChange}
                        >
                            {!isLoadingThicknesses
                                ? thicknessOptions?.map((thickness) => (
                                      <MenuItem
                                          key={thickness}
                                          sx={{ justifyContent: 'flex-end' }}
                                          value={thickness}
                                      >
                                          {formatNumber(thickness, locale, useImperialUnits)} {units}
                                      </MenuItem>
                                  ))
                                : null}
                        </Select>
                    </Box>
                </TbxTooltip>
            </Box>
            <Box
                alignItems={'center'}
                display={'flex'}
                flexDirection={'row'}
                gap={1}
                justifyContent={'space-between'}
                margin={1}
            >
                <Box flex={1}>
                    <Typography>{t('Sheet')}</Typography>
                </Box>
                <Box flex={3}>
                    <Select
                        id="select-itemSheet"
                        inputProps={{ 'data-testid': 'nqp-quote-item-sheet-size' }}
                        labelId="select-itemSheet-label"
                        SelectDisplayProps={{ style: { textAlign: 'right' } }}
                        size="small"
                        value={itemSheet}
                        fullWidth
                        onChange={handleSheetChange}
                    >
                        {!isLoadingThicknesses
                            ? sheetOptions?.map((sheet) => (
                                  <MenuItem
                                      key={sheet.sheetId}
                                      sx={{ justifyContent: 'flex-end' }}
                                      value={sheet.sheetId}
                                  >
                                      {'profile' in quoteItem
                                          ? `${formatNumber(sheet.sheetlength, locale)} ${units}`
                                          : `${formatNumber(sheet.sheetWidth, locale)} x ${formatNumber(
                                                sheet.sheetHeight,
                                                locale
                                            )} ${units}`}
                                  </MenuItem>
                              ))
                            : null}
                    </Select>
                </Box>
            </Box>
        </Box>
    )
}

PartProperties.propTypes = {
    isPartFromLibrary: PropTypes.bool,
    quoteItemId: PropTypes.string,
}

export default PartProperties
