import { useTranslation } from 'react-i18next'
import { Button, Typography } from '@mui/material'
import PropTypes from 'prop-types'

import { Dialog } from '@/common/components'

const ArchiveRateTableDialog = ({ mode = 'archive', onCancelClose, onConfirmClose, rateTableName = undefined }) => {
    const { t } = useTranslation()

    const handleArchiveButtonClick = () => {
        if (typeof onConfirmClose === 'function') {
            onConfirmClose()
        }
    }

    return (
        <Dialog
            actions={[
                <Button
                    color="primary"
                    key="archive"
                    variant="contained"
                    onClick={handleArchiveButtonClick}
                >
                    {t(mode)}
                </Button>,
                <Button
                    color="primary"
                    key="cancel"
                    variant="contained"
                    onClick={onCancelClose}
                >
                    {t('Cancel')}
                </Button>,
            ]}
            className={{ width: 680 }}
            open={true}
            title={t(`Confirm rate table ${mode}`)}
            onClose={onCancelClose}
        >
            <Typography variant="body1">
                {t(`Are you sure you wish to ${mode} the rate table`) + ` ${rateTableName}?`}
            </Typography>
        </Dialog>
    )
}

ArchiveRateTableDialog.propTypes = {
    mode: PropTypes.oneOf(['archive', 'unarchive']),
    rateTableName: PropTypes.string,
    onCancelClose: PropTypes.func,
    onConfirmClose: PropTypes.func,
}

export default ArchiveRateTableDialog
