import { Children, cloneElement, useEffect, useRef, useState } from 'react'
import { Box } from '@mui/material'
import MuiButton from '@mui/material/Button'
import MuiClickAwayListener from '@mui/material/ClickAwayListener'
import MuiGrow from '@mui/material/Grow'
import MuiMenuList from '@mui/material/MenuList'
import MuiPaper from '@mui/material/Paper'
import MuiPopper from '@mui/material/Popper'
import PropTypes from 'prop-types'

const classes = {
    root: {
        display: 'flex',
        zIndex: (theme) => theme.zIndex.appBar - 1,
    },
    paper: {
        marginRight: 2,
    },
}

const Menu = ({ children, disabled, open = false, title }) => {
    const [menuOpen, setMenuOpen] = useState(open)
    const anchorRef = useRef(null)

    useEffect(() => {
        setMenuOpen(open)
    }, [open])

    const handleToggle = () => {
        setMenuOpen((prevOpen) => !prevOpen)
    }

    const handleClose = (event) => {
        if (anchorRef.current && anchorRef.current.contains(event.target)) {
            return
        }

        setMenuOpen(false)
    }

    const handleChildOnClick = (event, childClickHandler) => {
        if (typeof childClickHandler === 'function') {
            childClickHandler(event)
        }

        handleClose(event)
    }

    function handleListKeyDown(event) {
        if (event.key === 'Tab') {
            event.preventDefault()
            setMenuOpen(false)
        }
    }

    // return focus to the button when we transitioned from !menuOpen -> menuOpen
    const prevOpen = useRef(menuOpen)
    useEffect(() => {
        if (prevOpen.current === true && menuOpen === false) {
            anchorRef.current.focus()
        }

        prevOpen.current = menuOpen
    }, [menuOpen])

    return (
        <Box sx={classes.root}>
            <MuiButton
                aria-controls={menuOpen ? 'menu-list-grow' : undefined}
                aria-haspopup="true"
                color="secondary"
                disabled={disabled}
                ref={anchorRef}
                onClick={handleToggle}
            >
                {title}
            </MuiButton>
            <MuiPopper
                anchorEl={anchorRef.current}
                open={menuOpen}
                role={undefined}
                disablePortal
                transition
            >
                {({ TransitionProps }) => (
                    <MuiGrow
                        {...TransitionProps}
                        style={{ transformOrigin: 'bottom left' }}
                    >
                        <MuiPaper>
                            <MuiClickAwayListener onClickAway={handleClose}>
                                <MuiMenuList
                                    autoFocusItem={menuOpen}
                                    id="menu-list-grow"
                                    onKeyDown={handleListKeyDown}
                                >
                                    {/* {children} */}
                                    {Children.map(children, (child) => {
                                        const childClickHandler = child.props.onClick
                                        return cloneElement(child, {
                                            onClick: (event) => handleChildOnClick(event, childClickHandler),
                                        })
                                    })}
                                </MuiMenuList>
                            </MuiClickAwayListener>
                        </MuiPaper>
                    </MuiGrow>
                )}
            </MuiPopper>
        </Box>
    )
}

Menu.propTypes = {
    title: PropTypes.string.isRequired,
    children: PropTypes.node,
    disabled: PropTypes.bool,
    open: PropTypes.bool,
}

export default Menu
