import { Box } from '@mui/material'
import PropTypes from 'prop-types'

const CircleRenderer = ({ circle, className, onClick, path, style }) => {
    return (
        circle && (
            <Box
                component="circle"
                cx={circle.centrePoint.x}
                cy={circle.centrePoint.y}
                id={path.pathId}
                r={circle.radius}
                style={style}
                sx={className}
                onClick={onClick}
            />
        )
    )
}

CircleRenderer.propTypes = {
    circle: PropTypes.object,
    path: PropTypes.object,
    style: PropTypes.object,
    onClick: PropTypes.func,
}

export default CircleRenderer
