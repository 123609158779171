import { useEffect, useRef, useState } from 'react'
import { Box } from '@mui/material'
import PropTypes from 'prop-types'

import { theme } from '@/common/themes/LightTheme'

function newResizeObserver(callback) {
    // Skip this feature for browsers which
    // do not support ResizeObserver.
    if (typeof ResizeObserver === 'undefined') return
    return new ResizeObserver((e) => e.map(callback))
}

const classes = {
    scrollWrapper: {
        position: 'relative',
        overflow: 'hidden',
    },
    scrollContainer: {
        overflow: 'auto',
        '&::-webkit-scrollbar': {
            display: 'none',
        },
    },
    shadows: {
        position: 'absolute',
        pointerEvents: 'none',
        opacity: 0,
        transition: 'opacity 0.2s ease',
    },
    shadowTopBottom: (styleProps) => ({
        right: 0,
        left: 0,
        height: theme.spacing(styleProps.verticalSize),
        backgroundImage: `radial-gradient(farthest-side at 50% 0, ${styleProps.startColor}, ${styleProps.endColor})`,
    }),
    shadowLeftRight: (styleProps) => ({
        top: 0,
        bottom: 0,
        width: theme.spacing(styleProps.horizontalSize),
        backgroundImage: `radial-gradient(farthest-side at 0 50%, ${styleProps.startColor}, ${styleProps.endColor})`,
    }),
    shadowTop: {
        top: 0,
    },
    shadowRight: {
        right: 0,
        transform: 'rotate(180deg)',
    },
    shadowBottom: {
        bottom: 0,
        transform: 'rotate(180deg)',
    },
    shadowLeft: {
        left: 0,
    },
    isActive: {
        opacity: 1,
    },
}

const TbxShadowScroll = ({
    children,
    endColor = 'rgba(0,0,0,0)',
    horizontalSize = 2,
    scrollContainerSx,
    startColor = 'rgba(108,108,108, 0.4)',
    verticalSize = 2,
    wrapperSx,
}) => {
    const scrollContainer = useRef(null)
    const wrapper = useRef(null)

    const [width, setWidth] = useState(undefined)
    const [height, setHeight] = useState(undefined)
    const [shadowTop, setShadowTop] = useState(false)
    const [shadowBottom, setShadowBottom] = useState(false)
    const [shadowLeft, setShadowLeft] = useState(false)
    const [shadowRight, setShadowRight] = useState(false)

    const styleProps = {
        verticalSize,
        horizontalSize,
        startColor,
        endColor,
    }

    const calcDimensions = async () => {
        // Reset dimensions for correctly recalculating parent dimensions.
        setWidth(`${wrapper.current.clientWidth}px`)
        setHeight(`${wrapper.current.clientHeight}px`)
    }

    // Check if shadows are needed.
    const toggleShadow = () => {
        const hasHorizontalScrollbar = scrollContainer.current.clientWidth < scrollContainer.current.scrollWidth
        const hasVerticalScrollbar = scrollContainer.current.clientHeight < scrollContainer.current.scrollHeight

        const scrolledFromLeft = scrollContainer.current.offsetWidth + scrollContainer.current.scrollLeft
        const scrolledFromTop = scrollContainer.current.offsetHeight + scrollContainer.current.scrollTop

        const scrolledToTop = scrollContainer.current.scrollTop === 0
        const scrolledToRight = scrolledFromLeft >= scrollContainer.current.scrollWidth
        const scrolledToBottom = scrolledFromTop >= scrollContainer.current.scrollHeight
        const scrolledToLeft = scrollContainer.current.scrollLeft === 0

        setShadowTop(hasVerticalScrollbar && !scrolledToTop)
        setShadowRight(hasHorizontalScrollbar && !scrolledToRight)
        setShadowBottom(hasVerticalScrollbar && !scrolledToBottom)
        setShadowLeft(hasHorizontalScrollbar && !scrolledToLeft)
    }

    useEffect(() => {
        // Check if shadows are necessary after the element is resized.
        const scrollContainerObserver = newResizeObserver(toggleShadow)
        if (scrollContainerObserver) {
            scrollContainerObserver.observe(scrollContainer.current)
        }

        // Recalculate the container dimensions when the wrapper is resized.
        const wrapObserver = newResizeObserver(calcDimensions)
        if (wrapObserver) {
            wrapObserver.observe(wrapper.current)
        }

        return () => {
            // Cleanup when the component is destroyed.
            if (scrollContainerObserver) {
                scrollContainerObserver.disconnect()
            }
            if (wrapObserver) {
                wrapObserver.disconnect()
            }
        }
    }, [])

    return (
        <Box
            component="div"
            ref={wrapper}
            sx={[classes.scrollWrapper, ...(Array.isArray(wrapperSx) ? wrapperSx : [wrapperSx])]}
        >
            <Box
                component="div"
                ref={scrollContainer}
                style={{ width, height }}
                sx={[
                    classes.scrollContainer,
                    ...(Array.isArray(scrollContainerSx) ? scrollContainerSx : [scrollContainerSx]),
                ]}
                onScroll={toggleShadow}
            >
                {children}

                <Box
                    component="span"
                    sx={[
                        classes.shadows,
                        classes.shadowTopBottom(styleProps),
                        classes.shadowTop,
                        shadowTop && classes.isActive,
                    ]}
                />
                <Box
                    component="span"
                    sx={[
                        classes.shadows,
                        classes.shadowLeftRight(styleProps),
                        classes.shadowRight,
                        shadowRight && classes.isActive,
                    ]}
                />
                <Box
                    component="span"
                    sx={[
                        classes.shadows,
                        classes.shadowTopBottom(styleProps),
                        classes.shadowBottom,
                        shadowBottom && classes.isActive,
                    ]}
                />
                <Box
                    component="span"
                    sx={[
                        classes.shadows,
                        classes.shadowLeftRight(styleProps),
                        classes.shadowLeft,
                        shadowLeft && classes.isActive,
                    ]}
                />
            </Box>
        </Box>
    )
}

TbxShadowScroll.propTypes = {
    children: PropTypes.node.isRequired,
    endColor: PropTypes.string,
    horizontalSize: PropTypes.number,
    scrollContainerSx: PropTypes.oneOfType([
        PropTypes.arrayOf(PropTypes.oneOfType([PropTypes.func, PropTypes.object, PropTypes.bool])),
        PropTypes.func,
        PropTypes.object,
    ]),
    startColor: PropTypes.string,
    verticalSize: PropTypes.number,
    wrapperSx: PropTypes.oneOfType([
        PropTypes.arrayOf(PropTypes.oneOfType([PropTypes.func, PropTypes.object, PropTypes.bool])),
        PropTypes.func,
        PropTypes.object,
    ]),
}

export default TbxShadowScroll
