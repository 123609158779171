import { Trans, useTranslation } from 'react-i18next'
import { useSelector } from 'react-redux'
import { Add, ArrowForward, FileCopy } from '@mui/icons-material'
import { Button, Link } from '@mui/material'
import PropTypes from 'prop-types'

import { selectIsOnFreePlan, selectReduceTrialAccess } from '@/app/slices/organisationSlice'
import { selectSelectedRateTableId } from '@/app/slices/rateTablesSlice'
import { TbxTooltip } from '@/common/components'
import TbxToolbar from '@/common/components/TbxToolbar/TbxToolbar'
import TbxToolbarActions from '@/common/components/TbxToolbar/TbxToolbarActions'
import TbxToolbarDivider from '@/common/components/TbxToolbar/TbxToolbarDivider'
import TbxToolbarTitle from '@/common/components/TbxToolbar/TbxToolbarTitle'

import TbxToolbarMessages from '../../TbxToolbar/TbxToolbarMessages'

const RateTablesToolbar = ({ handleAddRateTableClick, handleDuplicateRateTableClick, handleMaterialsClick }) => {
    const { t } = useTranslation()

    const selectedRateTableId = useSelector(selectSelectedRateTableId)

    const reduceTrialAccess = useSelector(selectReduceTrialAccess)
    const isOnFreePlan = useSelector(selectIsOnFreePlan)

    return (
        <TbxToolbar>
            <TbxToolbarTitle>{t('Rate tables')}</TbxToolbarTitle>

            <TbxToolbarMessages />

            <TbxToolbarActions>
                <TbxTooltip
                    title={
                        isOnFreePlan ? (
                            <Trans t={t}>
                                Not available in free mode. <Link href="/activate">Subscribe here.</Link>
                            </Trans>
                        ) : reduceTrialAccess ? (
                            <Trans t={t}>
                                Not available in trial mode. <Link href="/activate">Subscribe here.</Link>
                            </Trans>
                        ) : (
                            ''
                        )
                    }
                    arrow
                >
                    <span>
                        <Button
                            color="secondary"
                            data-testid="duplicate-rate-table-button"
                            disabled={reduceTrialAccess || !selectedRateTableId}
                            startIcon={<FileCopy />}
                            variant="outlined"
                            onClick={handleDuplicateRateTableClick}
                        >
                            {t('Duplicate')}
                        </Button>
                    </span>
                </TbxTooltip>

                <Button
                    color="secondary"
                    data-testid="go-to-materials-button"
                    endIcon={<ArrowForward />}
                    variant="outlined"
                    onClick={handleMaterialsClick}
                >
                    {t('Materials')}
                </Button>

                <TbxToolbarDivider />

                <TbxTooltip
                    title={
                        isOnFreePlan ? (
                            <Trans t={t}>
                                Not available in free mode. <Link href="/activate">Subscribe here.</Link>
                            </Trans>
                        ) : reduceTrialAccess ? (
                            <Trans t={t}>
                                Not available in trial mode. <Link href="/activate">Subscribe here.</Link>
                            </Trans>
                        ) : (
                            ''
                        )
                    }
                    arrow
                >
                    <span>
                        <Button
                            color="primary"
                            data-testid="add-rate-table-button"
                            disabled={reduceTrialAccess}
                            startIcon={<Add />}
                            variant="contained"
                            onClick={handleAddRateTableClick}
                        >
                            {t('Add rate table')}
                        </Button>
                    </span>
                </TbxTooltip>
            </TbxToolbarActions>
        </TbxToolbar>
    )
}

RateTablesToolbar.propTypes = {
    handleAddRateTableClick: PropTypes.func,
    handleDuplicateRateTableClick: PropTypes.func,
    handleMaterialsClick: PropTypes.func,
}

export default RateTablesToolbar
