import { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { NumericFormat } from 'react-number-format'
import { useSelector } from 'react-redux'
import { useParams } from 'react-router-dom'
import { CheckRounded } from '@mui/icons-material'
import {
    AccordionDetails,
    Box,
    Button,
    CircularProgress,
    FormControl,
    List,
    ListItem,
    ListItemIcon,
    ListItemText,
    MenuItem,
    Select,
    TextField,
    Typography,
} from '@mui/material'
import { useSnackbar } from 'notistack'
import PropTypes from 'prop-types'
import { useDebounce } from 'use-debounce'

import { selectStoreSettings } from '@/app/services/organisation'
import { useGetQuoteQuery } from '@/app/services/web-store/webStoreQuote'
import { useUpdateQuoteItemsAttributesMutation } from '@/app/services/web-store/webStoreQuoteItems'

import { formatCurrency, formatNumber } from '../../../helpers/utilities'
import { useQuoteItemPropertyOptions } from '../../../hooks/useQuoteItemPropertyOptions'
import DrawingCleanerModal from '../../shared/DrawingCleanerModal'

const classes = {
    AccordionDetailsRoot: {
        display: 'flex',
        backgroundColor: 'background.paper',
        padding: 3,
        gap: '24px',
        flexWrap: 'wrap',
    },
    ItemDetails_Image: {
        width: 120,
        gap: '8px',
    },
    ItemDetails_Options: {
        width: 320,
        gap: '12px',
    },

    ItemDetails_Options_Size: {
        gap: '12px',
    },
    ItemDetails_Processes: {
        width: 200,
        gap: '6px',
        py: 0,
        px: 1.5,
        borderWidth: '0px 1px',
        borderStyle: 'solid',
        borderColor: '#E0E0E0',
    },
    ItemDetails_Summary: {
        flex: 1,
    },
    ItemDetails_Calculating: {
        gap: '12px',
    },
    formControl_size: {
        width: '85px',
    },
    formControl_material: {
        width: '200px',
    },
    formControl_thickness: {
        width: '120px',
    },
    formControl_quantity: {
        width: '120px',
    },

    ListItemIconRoot: {
        minWidth: 'initial',
        paddingRight: 1,
    },
}

const ItemDetails = ({ calculateQuote, isCalculating, quoteItem }) => {
    const { t } = useTranslation()
    const { organisationId, quoteId } = useParams()
    const { enqueueSnackbar } = useSnackbar()

    const storeSettings = useSelector((state) => selectStoreSettings(state, { organisationId }))

    const units = storeSettings.defaultDrawingUnits.toLowerCase() === 'metric' ? 'mm' : 'in'

    const size = `${formatNumber(quoteItem.displayBoundBoxWidth, storeSettings.locale)} x ${formatNumber(
        quoteItem.displayBoundBoxHeight,
        storeSettings.locale
    )}`

    const [itemUnit, setItemUnit] = useState(quoteItem.isImperial ? 'in' : 'mm')
    const [itemMaterial, setItemMaterial] = useState(quoteItem.materialId || '')
    const [itemThickness, setItemThickness] = useState(quoteItem.thickness || '')
    const [itemQuantity, setItemQuantity] = useState(quoteItem.quantity || 1)
    const [itemNotes, setItemNotes] = useState(quoteItem.customerNotes || '')
    const [quantityError, setQuantityError] = useState(false)

    const [debouncedItemQuantity] = useDebounce(itemQuantity, 500)
    const [debouncedItemNotes] = useDebounce(itemNotes, 800)

    const { materialOptions, thicknessOptions } = useQuoteItemPropertyOptions({
        organisationId,
        materialId: itemMaterial,
        mode: 'flat',
    })

    const { isFetching: isFetchingQuote } = useGetQuoteQuery({
        organisationId,
        quoteId,
    })

    const [updateQuoteItems, { isLoading: isUpdating }] = useUpdateQuoteItemsAttributesMutation()

    const handleUpdateQuoteItem = async (attributes) => {
        const quoteItems = {
            quoteItemIds: [quoteItem.id],
            attributes,
        }
        try {
            const result = await updateQuoteItems({
                organisationId,
                quoteId,
                quoteItems,
            }).unwrap()
            enqueueSnackbar(t('$t(Quote) item updated'), { variant: 'success' })
            return result
        } catch (error) {
            enqueueSnackbar(t('Failed to update the $t(quote) item'), {
                variant: 'error',
            })
        }
    }

    const calculateQuoteItem = async () => {
        try {
            await calculateQuote({ organisationId, quoteId })
            enqueueSnackbar(t('$t(Quote) calculated'), { variant: 'success' })
        } catch (error) {
            enqueueSnackbar(t('Failed to calculate the $t(quote)'), {
                variant: 'error',
            })
        }
    }

    const [showDrawingCleaner, setShowDrawingCleaner] = useState(false)

    const handleThumbnailClick = () => {
        setShowDrawingCleaner(true)
    }

    const handleDrawingCleanerClose = () => {
        setShowDrawingCleaner(false)
    }

    const handleUnitChange = async (event) => {
        const value = event?.target?.value
        if (Boolean(value) && itemUnit !== value) {
            setItemUnit(value)
            await handleUpdateQuoteItem({ isImperial: value === 'in' })
            if (itemMaterial && itemThickness) {
                await calculateQuoteItem()
            }
        }
    }

    const handleMaterialChange = async (event) => {
        const value = event?.target?.value
        if (Boolean(value) && itemMaterial !== value) {
            setItemMaterial(value)
            setItemThickness('')
            await handleUpdateQuoteItem({
                materialId: value,
                thickness: '',
                cuttingTechnologyId: '',
                sheetId: '',
            })
        }
    }

    const handleThicknessChange = async (event) => {
        const value = event?.target?.value
        if (Boolean(value) && itemThickness !== value) {
            setItemThickness(value)
            await handleUpdateQuoteItem({ thickness: value })
            await calculateQuoteItem()
        }
    }

    const handleQuantityChange = async (event) => {
        const value = event?.target?.value
        setQuantityError(!value || isNaN(value) || value <= 0)
        setItemQuantity(value)
    }

    const handleNotesChange = async (event) => {
        const value = event?.target?.value
        setItemNotes(value)
    }

    useEffect(() => {
        async function updateAndCalculateItem(attributes) {
            await handleUpdateQuoteItem(attributes)
            await calculateQuoteItem()
        }
        async function updateItem(attributes) {
            await handleUpdateQuoteItem(attributes)
        }

        if (Number(debouncedItemQuantity) !== Number(quoteItem.quantity)) {
            updateAndCalculateItem({ quantity: debouncedItemQuantity })
        }
        if (debouncedItemNotes !== quoteItem.customerNotes) {
            updateItem({ customerNotes: debouncedItemNotes })
        }
    }, [debouncedItemQuantity, debouncedItemNotes])

    // Re-render child components if any of quoteItem property changes
    useEffect(() => {
        setItemUnit(quoteItem.isImperial ? 'in' : 'mm')
        setItemMaterial(quoteItem.materialId || '')
        setItemThickness(quoteItem.thickness || '')
        setItemQuantity(quoteItem.quantity || '')
        setItemNotes(quoteItem.customerNotes || '')
    }, [JSON.stringify(quoteItem)])

    return (
        <AccordionDetails sx={classes.AccordionDetailsRoot}>
            <Box
                alignItems="center"
                display="flex"
                flexDirection="column"
                justifyContent="flex-start"
                sx={classes.ItemDetails_Image}
            >
                <img
                    alt="[Quote Item Thumbnail]"
                    data-testid="quote-item-thumbnail"
                    src={quoteItem.thumbnailUri}
                    onClick={handleThumbnailClick}
                />
                <Button
                    variant="text"
                    onClick={handleThumbnailClick}
                >
                    {t('View part')}
                </Button>
            </Box>

            <Box
                alignItems="flex-start"
                display="flex"
                flex="0 0 auto"
                flexDirection="column"
                sx={classes.ItemDetails_Options}
            >
                <Box
                    alignItems="center"
                    display="flex"
                    flexDirection="row"
                    justifyContent="space-between"
                    width="100%"
                >
                    <Typography>{t('Size')}</Typography>
                    <Box
                        alignItems="center"
                        display="flex"
                        flexDirection="row"
                        sx={classes.ItemDetails_Options_Size}
                    >
                        <Typography>{size}</Typography>
                        <FormControl
                            color="primary"
                            disabled={isUpdating}
                            margin="none"
                            size="small"
                            sx={classes.formControl_size}
                            variant="outlined"
                        >
                            <Select
                                id="select-itemUnit"
                                labelId="select-itemUnit-label"
                                value={itemUnit}
                                onBlur={handleUnitChange}
                                onChange={handleUnitChange}
                            >
                                <MenuItem value="mm">mm</MenuItem>
                                <MenuItem value="in">in</MenuItem>
                            </Select>
                        </FormControl>
                    </Box>
                </Box>
                <Box
                    alignItems="center"
                    display="flex"
                    flexDirection="row"
                    justifyContent="space-between"
                    width="100%"
                >
                    <Typography>{t('Material')}</Typography>
                    <FormControl
                        color="primary"
                        disabled={isUpdating}
                        margin="none"
                        size="small"
                        sx={classes.formControl_material}
                        variant="outlined"
                    >
                        <Select
                            id="select-itemMaterial"
                            labelId="select-itemMaterial-label"
                            value={itemMaterial}
                            onBlur={handleMaterialChange}
                            onChange={handleMaterialChange}
                        >
                            {materialOptions.map((material) => (
                                <MenuItem
                                    key={material.materialId}
                                    value={material.materialId}
                                >
                                    {material.materialName}
                                </MenuItem>
                            ))}
                        </Select>
                    </FormControl>
                </Box>
                <Box
                    alignItems="center"
                    display="flex"
                    flexDirection="row"
                    justifyContent="space-between"
                    width="100%"
                >
                    <Typography>
                        {t('Thickness')} ({units})
                    </Typography>
                    <FormControl
                        color="primary"
                        disabled={!thicknessOptions.length || isUpdating}
                        margin="none"
                        size="small"
                        sx={classes.formControl_thickness}
                        variant="outlined"
                    >
                        <Select
                            id="select-itemThickness"
                            labelId="select-itemThickness-label"
                            value={itemThickness}
                            onBlur={handleThicknessChange}
                            onChange={handleThicknessChange}
                        >
                            {thicknessOptions.map((thickness) => (
                                <MenuItem
                                    key={thickness}
                                    value={thickness}
                                >
                                    {thickness}
                                </MenuItem>
                            ))}
                        </Select>
                    </FormControl>
                </Box>
                <Box
                    alignItems="center"
                    display="flex"
                    flexDirection="row"
                    justifyContent="space-between"
                    width="100%"
                >
                    <Typography>{t('Quantity')}</Typography>
                    <TextField
                        color="primary"
                        error={quantityError}
                        helperText={quantityError ? t('Quantity cannot be less than 1') : null}
                        inputProps={{ style: { textAlign: 'right' }, min: 1 }}
                        placeholder="0"
                        size="small"
                        sx={classes.formControl_quantity}
                        type="number"
                        value={itemQuantity}
                        variant="outlined"
                        fullWidth
                        onBlur={handleQuantityChange}
                        onChange={handleQuantityChange}
                    />
                </Box>
            </Box>

            <Box
                alignItems="flex-start"
                display="flex"
                flex="0 0 auto"
                flexDirection="column"
                sx={classes.ItemDetails_Processes}
            >
                <Typography
                    style={{ fontWeight: 700 }}
                    variant="body1"
                >
                    {t('Processes')}
                </Typography>

                <List disablePadding>
                    <ListItem
                        key="cutting"
                        disableGutters
                    >
                        <ListItemIcon classes={{ root: classes.ListItemIconRoot }}>
                            <CheckRounded
                                color="primary"
                                fontSize="small"
                            />
                        </ListItemIcon>
                        <ListItemText
                            id="cutting"
                            primary={t('Cutting')}
                        />
                    </ListItem>
                </List>
            </Box>

            <Box
                alignItems="flex-start"
                display="flex"
                flex="1 0 auto"
                flexDirection="column"
                justifyContent="space-between"
                sx={classes.ItemDetails_Summary}
            >
                <Box
                    className="ItemDetails_Notes"
                    width="100%"
                >
                    <Typography
                        style={{ fontWeight: 700 }}
                        variant="body1"
                    >
                        {t('Notes')}
                    </Typography>
                    <TextField
                        color="primary"
                        maxRows={4}
                        minRows={4}
                        value={itemNotes}
                        variant="outlined"
                        fullWidth
                        multiline
                        onBlur={handleNotesChange}
                        onChange={handleNotesChange}
                    />
                </Box>
                {isCalculating || isFetchingQuote ? (
                    <Box
                        alignItems="center"
                        display="flex"
                        flexDirection="row"
                        justifyContent="flex-start"
                        sx={classes.ItemDetails_Calculating}
                        width="100%"
                    >
                        <CircularProgress size={24} />
                        <Typography>{t('Calculating...')}</Typography>
                    </Box>
                ) : null}

                {!isCalculating && !isFetchingQuote ? (
                    <Box
                        alignItems="center"
                        className="ItemDetails_Prices"
                        display="flex"
                        flexDirection="row"
                        justifyContent="space-between"
                        width="100%"
                    >
                        <Box>
                            <Typography>{t('Unit price')}</Typography>
                            <Typography>
                                {quoteItem.itemPrice
                                    ? formatCurrency(
                                          quoteItem.itemPrice,
                                          storeSettings.currencyCode,
                                          storeSettings.locale
                                      )
                                    : String.fromCharCode(8212)}
                            </Typography>
                        </Box>
                        <Box textAlign="right">
                            <Typography style={{ fontWeight: 700 }}>{t('Line price')}</Typography>
                            <Typography style={{ fontWeight: 700 }}>
                                {quoteItem.linePrice
                                    ? formatCurrency(
                                          quoteItem.linePrice,
                                          storeSettings.currencyCode,
                                          storeSettings.locale
                                      )
                                    : String.fromCharCode(8212)}
                            </Typography>
                        </Box>
                    </Box>
                ) : null}
            </Box>

            <DrawingCleanerModal
                open={showDrawingCleaner}
                quoteItem={quoteItem}
                onClose={handleDrawingCleanerClose}
            />
        </AccordionDetails>
    )
}

ItemDetails.propTypes = {
    calculateQuote: PropTypes.func,
    isCalculating: PropTypes.bool,
    quoteItem: PropTypes.object,
}

export default ItemDetails
