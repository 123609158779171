import { Controller } from 'react-hook-form'
import { useTranslation } from 'react-i18next'
import { Box, FormControl, FormControlLabel, Radio, RadioGroup, Typography } from '@mui/material'
import { alpha } from '@mui/material/styles'
import PropTypes from 'prop-types'

const classes = {
    customRadioButton: {
        marginLeft: 0,

        '& [class*="MuiButtonBase-root"]': {
            clip: 'rect(0 0 0 0)',
            clipPath: 'inset(50%)',
            height: '1px',
            overflow: 'hidden',
            position: 'absolute',
            whiteSpace: 'nowrap',
            width: '1px',
        },

        '& [class*="MuiFormControlLabel-label"]': {
            cursor: 'pointer',
            px: 3,
            py: 1,
            backgroundColor: (theme) => alpha(theme.palette.primary.main, 0.12),
            border: (theme) => `1px solid ${theme.palette.primary.light}`,
            borderRadius: '8px',
            color: 'primary.main',
            '&:hover': {
                backgroundColor: (theme) => alpha(theme.palette.primary.main, 0.5),
                borderColor: 'primary.main',
                color: 'primary.contrastText',
            },
        },

        '& [class*="MuiRadio-colorPrimary"][class*="Mui-checked"] + [class*="MuiFormControlLabel-label"]': {
            backgroundColor: 'primary.main',
            borderColor: 'primary.dark',
            color: 'primary.contrastText',
            '&:hover': {
                backgroundColor: 'primary.main',
                borderColor: 'primary.dark',
                color: 'primary.contrastText',
            },
        },
    },
}

const FormInputRadio = ({ control, name, options }) => {
    const { t } = useTranslation()

    const generateRadioOptions = () => {
        return options.map((singleOption) => (
            <FormControlLabel
                control={<Radio color="primary" />}
                key={singleOption.value}
                label={
                    <Box
                        display="flex"
                        style={{ gap: '12px' }}
                    >
                        {singleOption.icon}
                        <Typography
                            style={{ textTransform: 'uppercase' }}
                            variant="body1"
                        >
                            {t(singleOption.label)}
                        </Typography>
                    </Box>
                }
                sx={classes.customRadioButton}
                value={singleOption.value}
            />
        ))
    }
    return (
        <FormControl component="fieldset">
            <Controller
                control={control}
                name={name}
                render={({ field: { onChange, value } }) => (
                    <RadioGroup
                        value={value || ''}
                        row
                        onChange={onChange}
                    >
                        {generateRadioOptions()}
                    </RadioGroup>
                )}
            />
        </FormControl>
    )
}

FormInputRadio.propTypes = {
    control: PropTypes.object.isRequired,
    name: PropTypes.string.isRequired,
    options: PropTypes.array.isRequired,
}

export default FormInputRadio
