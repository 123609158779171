import { useTranslation } from 'react-i18next'
import { Box, Skeleton } from '@mui/material'
import PropTypes from 'prop-types'

const classes = {
    wrapper: ({ wrapperHeight, wrapperWidth }) => ({
        width: wrapperWidth,
        height: wrapperHeight,
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
    }),
    img: (isClickable) => ({
        height: '100%',
        width: '100%',
        objectFit: 'contain',
        objectPosition: 'center',
        mixBlendMode: 'multiply',
        cursor: isClickable ? 'pointer' : 'default',
    }),
    partLibraryImg: {
        maxHeight: 70,
        maxWidth: 70,
        objectFit: 'contain',
        objectPosition: 'center',
    },
}

const Thumbnail = ({
    fromPartLibrary = false,
    lazyLoading = true,
    onClick,
    sx,
    uri = '',
    wrapperHeight = 100,
    wrapperWidth = 100,
}) => {
    const { t } = useTranslation()

    return (
        <Box sx={[classes.wrapper({ wrapperWidth, wrapperHeight }), ...(Array.isArray(sx) ? sx : [sx])]}>
            {uri ? (
                <Box
                    alt={fromPartLibrary ? t('[PartLibrary Item Thumbnail]') : t('[Quote Item Thumbnail]')}
                    component="img"
                    data-testid={fromPartLibrary ? 'partLibrary-item-thumbnail' : 'quote-item-thumbnail'}
                    loading={lazyLoading ? 'lazy' : 'eager'}
                    src={uri}
                    sx={fromPartLibrary ? classes.partLibraryImg : classes.img(onClick ? true : false)}
                    onClick={fromPartLibrary ? undefined : onClick}
                />
            ) : (
                <Skeleton
                    height={fromPartLibrary ? 70 : null}
                    sx={fromPartLibrary ? classes.partLibraryImg : classes.img}
                    variant="rectangular"
                    width={fromPartLibrary ? 70 : null}
                />
            )}
        </Box>
    )
}

Thumbnail.propTypes = {
    fromPartLibrary: PropTypes.bool,
    lazyLoading: PropTypes.bool,
    sx: PropTypes.oneOfType([
        PropTypes.arrayOf(PropTypes.oneOfType([PropTypes.func, PropTypes.object, PropTypes.bool])),
        PropTypes.func,
        PropTypes.object,
    ]),
    uri: PropTypes.string,
    wrapperHeight: PropTypes.number,
    wrapperWidth: PropTypes.number,
    onClick: PropTypes.func,
}

export default Thumbnail
