import { api } from './api'

const apiVersion = 'v2'

export const miscItemsApi = api
    .enhanceEndpoints({
        addTagTypes: ['MiscItems'],
    })
    .injectEndpoints({
        endpoints: (builder) => ({
            getMiscItems: builder.query({
                query: ({ organisationId, quoteId }) =>
                    `/${apiVersion}/quote/${organisationId}/${quoteId}/miscellaneous-items`,
                providesTags: (result, _error, _arg) =>
                    result
                        ? [{ type: 'MiscItems', id: 'LIST' }, ...result.map(({ id }) => ({ type: 'MiscItems', id }))]
                        : [{ type: 'MiscItems', id: 'LIST' }],
                transformResponse: (responseData) => {
                    if (responseData.length) {
                        responseData.sort((a, b) => a.index - b.index)
                    }
                    return responseData
                },
            }),

            addMiscItem: builder.mutation({
                query: ({ miscItem, organisationId, quoteId }) => ({
                    url: `/${apiVersion}/quote/${organisationId}/${quoteId}/miscellaneous-items`,
                    method: 'POST',
                    body: miscItem,
                }),
                invalidatesTags: [{ type: 'MiscItems', id: 'LIST' }],
            }),

            updateMiscItem: builder.mutation({
                query: ({ miscItem, miscItemId, organisationId, quoteId }) => ({
                    url: `/${apiVersion}/quote/${organisationId}/${quoteId}/miscellaneous-items/${miscItemId}`,
                    method: 'PUT',
                    body: miscItem,
                }),
                async onQueryStarted({ miscItem, miscItemId, organisationId, quoteId }, { dispatch, queryFulfilled }) {
                    dispatch(
                        miscItemsApi.util.updateQueryData('getMiscItems', { organisationId, quoteId }, (draft) => {
                            const index = draft.findIndex((item) => item.id === miscItemId)
                            draft[index] = miscItem
                        })
                    )

                    try {
                        await queryFulfilled
                    } catch {
                        //patchResult.undo()
                        dispatch(miscItemsApi.util.invalidateTags([{ type: 'MiscItems', id: miscItemId }]))
                    }
                },
            }),

            updateMiscItems: builder.mutation({
                query: ({ miscItems, organisationId, quoteId }) => ({
                    url: `/${apiVersion}/quote/${organisationId}/${quoteId}/miscellaneous-items`,
                    method: 'PUT',
                    body: miscItems,
                }),
                async onQueryStarted({ miscItems, organisationId, quoteId }, { dispatch, queryFulfilled }) {
                    dispatch(
                        miscItemsApi.util.updateQueryData('getMiscItems', { organisationId, quoteId }, (draft) => {
                            Object.assign(draft, miscItems)
                        })
                    )
                    try {
                        await queryFulfilled
                    } catch {
                        //patchResult.undo()
                        dispatch(miscItemsApi.util.invalidateTags([{ type: 'MiscItems', id: 'LIST' }]))
                    }
                },
            }),

            deleteMiscItem: builder.mutation({
                query: ({ miscItemId, organisationId, quoteId }) => ({
                    url: `/${apiVersion}/quote/${organisationId}/${quoteId}/miscellaneous-items/${miscItemId}`,
                    method: 'DELETE',
                }),
                invalidatesTags: (_result, _error, arg) => [
                    { type: 'MiscItems', id: 'LIST' },
                    { type: 'MiscItems', id: arg.miscItemId },
                ],
            }),

            deleteMiscItems: builder.mutation({
                query: ({ miscItemsIds, organisationId, quoteId }) => ({
                    url: `/${apiVersion}/quote/${organisationId}/${quoteId}/miscellaneous-items`,
                    method: 'DELETE',
                    body: miscItemsIds,
                }),
                invalidatesTags: (_result, _error, arg) => [
                    { type: 'MiscItems', id: 'LIST' },
                    ...arg.miscItemsIds.map((miscItemId) => ({
                        type: 'MiscItems',
                        id: miscItemId,
                    })),
                ],
            }),
        }),
    })

export const selectMiscItems = (state, query) => {
    return miscItemsApi.endpoints.getMiscItems.select(query)(state)?.data ?? []
}

export const {
    useAddMiscItemMutation,
    useDeleteMiscItemMutation,
    useDeleteMiscItemsMutation,
    useGetMiscItemsQuery,
    useUpdateMiscItemMutation,
    useUpdateMiscItemsMutation,
} = miscItemsApi
