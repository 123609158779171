import { useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useSelector } from 'react-redux'
import { useNavigate } from 'react-router-dom'
import { Info } from '@mui/icons-material'
import { Box, Typography } from '@mui/material'
import Table from '@mui/material/Table'
import TableBody from '@mui/material/TableBody'
import TableCell from '@mui/material/TableCell'
import TableContainer from '@mui/material/TableContainer'
import TableHead from '@mui/material/TableHead'
import TablePagination from '@mui/material/TablePagination'
import TableRow from '@mui/material/TableRow'
import TableSortLabel from '@mui/material/TableSortLabel'
import PropTypes from 'prop-types'
import useLocalStorage from 'use-local-storage'

import { selectLocale } from '@/app/slices/appSlice'
import { selectOrganisation } from '@/app/slices/organisationSlice'
import { DateTime, TbxTooltip } from '@/common/components'
import { useNumberFormatter } from '@/common/hooks'
import {
    fractionToPercentage,
    getFormattedPrice,
    PARTS_PER_PAGE_DEFAULT_VALUE,
    PARTS_PER_PAGE_OPTIONS,
    PARTS_PER_PAGE_VARIABLE,
} from '@/common/utils'

function descendingComparator(a, b, orderBy) {
    if (b[orderBy] < a[orderBy]) {
        return -1
    }
    if (b[orderBy] > a[orderBy]) {
        return 1
    }
    return 0
}

function getComparator(order, orderBy) {
    return order === 'desc'
        ? (a, b) => descendingComparator(a, b, orderBy)
        : (a, b) => -descendingComparator(a, b, orderBy)
}

function stableSort(array, comparator) {
    const stabilizedThis = array.map((el, index) => [el, index])
    stabilizedThis.sort((a, b) => {
        const order = comparator(a[0], b[0])
        if (order !== 0) return order
        return a[1] - b[1]
    })
    return stabilizedThis.map((el) => el[0])
}
const EnhancedTableHead = ({ classes, columns, onRequestSort, order, orderBy }) => {
    const createSortHandler = (property) => (event) => {
        onRequestSort(event, property)
    }
    return (
        <TableHead>
            <TableRow style={{ height: 70 }}>
                {columns.map((headCell) => (
                    <TableCell
                        align={headCell.numeric ? 'right' : 'left'}
                        key={headCell.id}
                        padding={headCell.disablePadding ? 'none' : 'normal'}
                        sortDirection={orderBy === headCell.id ? order : false}
                        sx={classes.headerTableCell}
                    >
                        <TableSortLabel
                            active={orderBy === headCell.id}
                            direction={orderBy === headCell.id ? order : 'asc'}
                            sx={classes.sortLabel}
                            onClick={createSortHandler(headCell.id)}
                        >
                            {headCell.label}
                            {orderBy === headCell.id ? (
                                <Box sx={classes.visuallyHidden}>
                                    {order === 'desc' ? 'sorted descending' : 'sorted ascending'}
                                </Box>
                            ) : null}
                        </TableSortLabel>
                    </TableCell>
                ))}
            </TableRow>
        </TableHead>
    )
}
EnhancedTableHead.propTypes = {
    classes: PropTypes.object.isRequired,
    order: PropTypes.oneOf(['asc', 'desc']).isRequired,
    orderBy: PropTypes.string.isRequired,
    onRequestSort: PropTypes.func.isRequired,
    columns: PropTypes.arrayOf(PropTypes.object),
}
const classes = {
    tableContainer: {
        display: 'flex',
        flexDirection: 'column',
        height: '100%',
        overflowX: 'scroll',
        background: (theme) => theme.palette.background.paper,
        border: (theme) => `1px solid ${theme.palette.grey[400]}`,
        boxSizing: 'border-box',
        borderRadius: 2,
    },
    table: {
        minWidth: 750,
        boxSizing: 'border-box',
        borderCollapse: 'separate',
    },
    titleTableCell: {
        '& p': {
            paddingInline: '12px',
            paddingBlock: '12px',
        },
    },
    headerTableCell: {
        padding: '8px',
        verticalAlign: 'bottom',
    },
    tableFooter: {
        flexShrink: 0,
        display: 'flex',
        alignItems: 'center',
        flexDirection: 'row-reverse',
    },

    sortLabel: {
        justifyContent: 'space-between',
        width: '100%',
    },
    visuallyHidden: {
        border: 0,
        clip: 'rect(0 0 0 0)',
        height: 1,
        margin: -1,
        overflow: 'hidden',
        padding: 0,
        position: 'absolute',
        top: 20,
        width: 1,
    },
    customWidth: {
        maxWidth: 200,
    },
    row: {
        height: '70px',
        '&:hover': {
            background: (theme) => theme.palette.action.hover,
        },
    },
    quoteName: {
        fontSize: '14px',
        lineHeight: '20px',
        letterSpacing: '0.17px',
        color: 'text.primary',
        textDecoration: 'underline',
        cursor: 'pointer',
    },
}

const PartLibraryQuoteDetailsTable = ({ columns, rows }) => {
    const { t } = useTranslation()

    const navigate = useNavigate()

    const organisation = useSelector(selectOrganisation)
    const locale = useSelector(selectLocale)

    const [order, setOrder] = useState('asc')
    const [orderBy, setOrderBy] = useState('quoteName')
    const [page, setPage] = useState(0)
    const [rowsPerPage, setRowsPerPage] = useLocalStorage(PARTS_PER_PAGE_VARIABLE, PARTS_PER_PAGE_DEFAULT_VALUE)

    const { n: timeNumberFormatter } = useNumberFormatter({
        numberOfDecimalPlaces: 4,
    })

    const handleRequestSort = (event, property) => {
        const isAsc = orderBy === property && order === 'asc'
        setOrder(isAsc ? 'desc' : 'asc')
        setOrderBy(property)
    }

    const handleChangePage = (event, newPage) => {
        setPage(newPage)
    }

    const handleChangeRowsPerPage = (event) => {
        setRowsPerPage(parseInt(event.target.value, 10))
        setPage(0)
    }
    const handleQuoteNameClick = (quoteId) => {
        navigate(`/quotes/${quoteId}`)
    }

    const secProcessesTooltipContent = (row) => {
        const secProcesses = []
        if (row.numberOfFolds) {
            secProcesses.push(t('Folding'))
        }
        for (let i = 0; i < row.secondaryProcesses.length; i++) {
            const element = row.secondaryProcesses[i]
            secProcesses.push(element.secondaryProcess.name + '\n')
        }
        return (
            <>
                {row.numberOfFolds > 0 ? (
                    <>
                        {t('Folding')} <br />
                    </>
                ) : undefined}
                {row.secondaryProcesses?.map((secProc) => (
                    <>
                        {t(secProc.secondaryProcess?.name)}
                        <br />
                    </>
                ))}
            </>
        )
    }

    const getProcessesTotalPrice = (row) => {
        return (row.secondaryProcessTotalPricePerItem || 0) + (row.foldingTotalPricePerItem || 0)
    }

    const renderSecondaryProcess = (row) => {
        return (
            <>
                <TbxTooltip
                    title={secProcessesTooltipContent(row)}
                    arrow
                >
                    <Info
                        color="action"
                        style={{ fontSize: 15 }}
                    />
                </TbxTooltip>
                {getFormattedPrice(getProcessesTotalPrice(row), organisation.currencyCode, organisation.locale)}
            </>
        )
    }
    const renderCuttingTime = (row) => {
        return `${timeNumberFormatter(row.runtime)} ${t('s')}`
    }
    const renderMaterialMarkup = (row) => {
        const materialMarkup = fractionToPercentage(row.materialMarkup) + ' %'
        return materialMarkup
    }

    return (
        <TableContainer sx={classes.tableContainer}>
            <Table
                aria-label="PartQuoteHistory"
                sx={classes.table}
                stickyHeader
            >
                <EnhancedTableHead
                    classes={classes}
                    columns={columns}
                    order={order}
                    orderBy={orderBy}
                    onRequestSort={handleRequestSort}
                />
                <TableBody>
                    {stableSort(rows, getComparator(order, orderBy))
                        .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                        .map((row) => {
                            return (
                                <TableRow
                                    key={row.id}
                                    sx={classes.row}
                                    tabIndex={-1}
                                >
                                    <TableCell
                                        align="left"
                                        onClick={() => handleQuoteNameClick(row.quote.id)}
                                    >
                                        <Typography sx={classes.quoteName}>{row.quote.name}</Typography>
                                    </TableCell>
                                    <TableCell align="left">{`${t(row.quote.status)}`}</TableCell>
                                    <TableCell align="left">
                                        <DateTime
                                            format="DD-MMM-YYYY"
                                            locale={locale}
                                        >
                                            {row.quote.lastModifiedDateUtc}
                                        </DateTime>
                                    </TableCell>
                                    <TableCell align="left">{row.cuttingTechnology.name}</TableCell>
                                    <TableCell align="right">{renderCuttingTime(row)}</TableCell>
                                    <TableCell align="right">
                                        {row.cutPrice
                                            ? getFormattedPrice(
                                                  row.processingTotalPrice,
                                                  organisation.currencyCode,
                                                  organisation.locale
                                              )
                                            : ' '}
                                    </TableCell>
                                    <TableCell align="right">{renderMaterialMarkup(row)}</TableCell>
                                    <TableCell align="right">
                                        {row.materialPrice
                                            ? getFormattedPrice(
                                                  row.materialPrice,
                                                  organisation.currencyCode,
                                                  organisation.locale
                                              )
                                            : ''}
                                    </TableCell>
                                    <TableCell align="right">{renderSecondaryProcess(row)}</TableCell>
                                    <TableCell align="right">
                                        {row.otherChargePrice
                                            ? getFormattedPrice(
                                                  row.otherChargePrice,
                                                  organisation.currencyCode,
                                                  organisation.locale
                                              )
                                            : ''}
                                    </TableCell>
                                    <TableCell align="right">
                                        {row.itemPrice
                                            ? getFormattedPrice(
                                                  row.itemPrice,
                                                  organisation.currencyCode,
                                                  organisation.locale
                                              )
                                            : ''}
                                    </TableCell>
                                    <TableCell align="right">{row.quantity}</TableCell>
                                </TableRow>
                            )
                        })}
                </TableBody>
            </Table>
            <TablePagination
                component="div"
                count={rows.length}
                page={page}
                rowsPerPage={rowsPerPage}
                rowsPerPageOptions={PARTS_PER_PAGE_OPTIONS}
                sx={classes.tableFooter}
                onPageChange={handleChangePage}
                onRowsPerPageChange={handleChangeRowsPerPage}
            />
        </TableContainer>
    )
}
PartLibraryQuoteDetailsTable.propTypes = {
    columns: PropTypes.arrayOf(PropTypes.object),
    rows: PropTypes.arrayOf(PropTypes.object),
}
export default PartLibraryQuoteDetailsTable
