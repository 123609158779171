import { useEffect, useMemo } from 'react'
import { useTranslation } from 'react-i18next'
import { useParams } from 'react-router-dom'
import { Box, Grid, Link, ThemeProvider, Typography } from '@mui/material'

import { useGetStoreSettingsQuery } from '@/app/services/organisation'
import { useGetMaterialsQuery } from '@/app/services/web-store/webStoreMaterial'
import { theme as lightTheme } from '@/common/themes/LightTheme'
import { getLanguageToUse, getProductDetails } from '@/common/utils'

import AppBar from '../../components/shared/AppBar/AppBar'
import TbxLoadingOverlay from '../../components/shared/TbxLoadingOverlay'
import { loadWebStoreFavicon } from '../../helpers/utilities'
import { storeThemeOverride } from '../theme/storeThemeOverride'

const classes = {
    layoutContainer: {
        display: 'flex',
        flex: 1,
        padding: 3,
    },

    contentContainer: {
        flexGrow: 1,
        backgroundColor: 'background.default',
    },
    footer: {
        textAlign: 'center',
        paddingBottom: 1.5,
    },
}

const Layout = ({ children }) => {
    const { t } = useTranslation()

    const { organisationId } = useParams()
    const { i18n } = useTranslation()

    const { data: storeSettings, isLoading: isLoadingSettings } = useGetStoreSettingsQuery({
        organisationId,
    })
    const { isLoading: isLoadingMaterials } = useGetMaterialsQuery({
        organisationId,
    })

    useEffect(() => {
        const languageToUse = getLanguageToUse(undefined, storeSettings)
        i18n.changeLanguage(languageToUse)
    }, [organisationId, storeSettings, getLanguageToUse, i18n])

    const storeTheme = useMemo(
        () => (isLoadingSettings ? lightTheme : storeThemeOverride(lightTheme, storeSettings)),
        [storeSettings, isLoadingSettings]
    )

    useEffect(() => {
        if (storeSettings) {
            if (!storeSettings.webStoreIsEnabled) {
                window.location.replace('https://tempustools.com/')
            }
        }

        document.title =
            storeSettings?.webStoreDisplayName || storeSettings?.organisationName || getProductDetails().title

        if (storeSettings?.webStoreFaviconUri) {
            loadWebStoreFavicon(storeSettings.webStoreFaviconUri)
        }
    }, [storeSettings])

    if (isLoadingSettings || isLoadingMaterials) {
        return (
            <TbxLoadingOverlay
                labelText={t('Loading store settings and materials...')}
                progressColor="secondary"
            />
        )
    }

    return (
        <ThemeProvider theme={storeTheme}>
            <AppBar />

            <Box sx={classes.layoutContainer}>
                <Grid
                    component="main"
                    spacing={3}
                    sx={classes.contentContainer}
                    container
                >
                    {children}
                </Grid>
            </Box>

            <Box
                component="footer"
                sx={classes.footer}
            >
                <Typography variant="body2">
                    {t('Powered by') + ' '}
                    <Link
                        color="primary"
                        href={`https://tempustools.com?org_id=${organisationId}&utm_source=quotemate&utm_medium=virality&utm_campaign=web-store`}
                        target="_blank"
                        underline="always"
                    >
                        Tempus Tools
                    </Link>
                </Typography>
            </Box>
        </ThemeProvider>
    )
}

export default Layout
