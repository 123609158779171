import { useEffect, useState } from 'react'
import { Box } from '@mui/material'
import PropTypes from 'prop-types'

import Circle from '../Entities/Circle'
import Path from '../Entities/Path'
import Text from '../Entities/Text'

import CircleRenderer from './CircleRenderer'
import TextRenderer from './TextRenderer'

const classes = {
    openOuter: {
        stroke: 'red',
        fill: 'none',
    },
    closedOuter: {
        stroke: 'white',
        fill: '#858589',
    },
    inner: {
        strokeLinecap: 'square',
        strokeWidth: '0.5px',
        strokeDasharray: 'none',
        strokeOpacity: 1,
        fillOpacity: 0.65,
        fill: 'none',
    },
    openInner: {
        stroke: 'purple',
        fill: 'none',
    },
    closedInner: {
        stroke: 'white',
        fill: '#303030',
    },
    notSolid: {
        strokeDasharray: '2px 2px',
    },
    etching: {
        stroke: 'green',
        fill: 'none',
    },
    info: {
        stroke: 'yellow',
        fill: 'none',
    },
    folding: {
        stroke: 'blue',
        fill: 'none',
    },
    text: {
        fillOpacity: 1,
        fill: 'white',
        strokeWidth: 0,
        userSelect: 'none',
    },
}

const PathRenderer = ({ drawingPath, showOpenPathHighlight, style, usingCartesianCoordinates = true }) => {
    const [path, setPath] = useState(null)

    useEffect(() => {
        if (drawingPath) {
            // Need to create a Path object to use render methods
            setPath(new Path(drawingPath))
        }
    }, [drawingPath])

    const getPathComponent = () => {
        if (!path || !path.entities || path.entities.length === 0) {
            return null
        }

        const isOuter = path.isOuter()
        const isClosed = path.isClosed
        const isSolid = path.isSolid
        const isCutting = path.layer.isCutting()
        const isEtching = path.layer.isEtching()
        const isInfo = path.layer.isInfo()
        const isText = path.isText()
        const isFolding = path.layer.isFolding()

        const pathStyle = Object.assign(
            {},
            isOuter && !isClosed ? classes.openOuter : {},
            isOuter && isClosed ? classes.closedOuter : {},
            !isOuter ? classes.inner : {},
            !isOuter && !isClosed ? classes.openInner : {},
            !isOuter && isClosed && isCutting ? classes.closedInner : {},
            !isSolid ? classes.notSolid : {},
            isCutting ? classes.cutting : {},
            isEtching ? classes.etching : {},
            isInfo ? classes.info : {},
            isText ? classes.text : {},
            isFolding ? classes.folding : {}
        )

        if (path.entities.length === 1 && path.entities[0].type === Circle.ToolpathType) {
            return (
                <CircleRenderer
                    circle={path.entities[0]}
                    className={pathStyle}
                    path={path}
                    style={style}
                />
            )
        } else if (path.entities.length === 1 && path.entities[0].type === Text.ToolpathType) {
            return (
                <TextRenderer
                    className={pathStyle}
                    hidden={true}
                    path={path}
                    style={style}
                    textEntity={path.entities[0]}
                    usingCartesianCoordinates={usingCartesianCoordinates}
                />
            )
        }

        let pathStr = ''
        let lastPathPt = null

        for (let i = 0; i < path.entities.length; i++) {
            const e = path.entities[i]
            if (e.type === Circle.ToolpathType || e.type === Text.ToolpathType) {
                throw new Error('Unexpected entity in path - ' + e.type)
            } else {
                pathStr += e.getSvgPathStr(lastPathPt)
                lastPathPt = e.endPoint
            }
        }

        return (
            <Box
                component="path"
                d={pathStr}
                id={path.pathId}
                markerEnd={!path.isClosed && showOpenPathHighlight ? 'url(#open-path-highlight)' : null}
                markerStart={!path.isClosed && showOpenPathHighlight ? 'url(#open-path-highlight)' : null}
                style={style}
                sx={pathStyle}
            />
        )
    }

    return getPathComponent()
}

PathRenderer.propTypes = {
    drawingPath: PropTypes.object,
    showOpenPathHighlight: PropTypes.bool,
    style: PropTypes.object,
    usingCartesianCoordinates: PropTypes.bool,
}

export default PathRenderer
