/* global paragon */
import * as jose from 'jose'

async function getUserToken(userId, timeStampDateTime, tokenLifetimeSeconds) {
    const alg = 'RS256'
    const secret = import.meta.env.VITE_PARAGON_SIGNING_KEY
    const privateKey = await jose.importPKCS8(secret, alg)

    return await new jose.SignJWT({})
        .setProtectedHeader({ alg })
        .setSubject(userId)
        .setIssuedAt(timeStampDateTime.valueOf())
        .setExpirationTime(`${tokenLifetimeSeconds}s`)
        .sign(privateKey)
}

async function authenticate(projectId, userId, timeStampDateTime, tokenLifetimeSeconds) {
    return paragon.authenticate(projectId, await getUserToken(userId, timeStampDateTime, tokenLifetimeSeconds))
}

function connect(integrationName, handler) {
    paragon.connect(integrationName, handler)
}

function getUser() {
    return paragon.getUser()
}

export { authenticate, connect, getUser }
