import { api } from './api'

const apiVersion = 'v2'

export const taxRatesApi = api
    .enhanceEndpoints({
        addTagTypes: ['TaxRates'],
    })
    .injectEndpoints({
        endpoints: (builder) => ({
            getTaxRates: builder.query({
                query: ({ organisationId, params }) => ({
                    url: `/${apiVersion}/tax-rates/${organisationId}`,
                    params,
                }),
                providesTags: (result) =>
                    result
                        ? [{ type: 'TaxRates', id: 'LIST' }, ...result.map(({ id }) => ({ type: 'TaxRates', id }))]
                        : [{ type: 'TaxRates', id: 'LIST' }],
                transformResponse: (response) => {
                    if (response && response.length > 0) {
                        return response?.sort((a, b) => {
                            if (a.isDefault > b.isDefault) return -1
                            if (a.isDefault < b.isDefault) return 1
                            if (a.active > b.active) return -1
                            if (a.active < b.active) return 1
                            if (a.displayName < b.displayName) return -1
                            if (a.displayName > b.displayName) return 1
                            return 0
                        })
                    }
                    return response
                },
            }),

            createTaxRate: builder.mutation({
                query: ({ organisationId, taxRate }) => ({
                    url: `/${apiVersion}/tax-rates/${organisationId}`,
                    method: 'POST',
                    body: taxRate,
                }),
                invalidatesTags: [{ type: 'TaxRates', id: 'LIST' }],
            }),

            updateTaxRate: builder.mutation({
                query: ({ organisationId, taxRate }) => ({
                    url: `/${apiVersion}/tax-rates/${organisationId}`,
                    method: 'PUT',
                    body: taxRate,
                }),
                invalidatesTags: (result, error, { _organisationId, taxRate }) => [
                    { type: 'TaxRates', id: 'LIST' },
                    { type: 'TaxRates', id: taxRate.id },
                ],
            }),

            deleteTaxRate: builder.mutation({
                query: ({ organisationId, taxRateId }) => ({
                    url: `/${apiVersion}/tax-rates/${organisationId}/${taxRateId}`,
                    method: 'DELETE',
                }),
                invalidatesTags: (result, error, { _organisationId, taxRateId }) => [
                    { type: 'TaxRates', id: 'LIST' },
                    { type: 'TaxRates', id: taxRateId },
                ],
            }),
        }),
    })

export const {
    useCreateTaxRateMutation,
    useDeleteTaxRateMutation,
    useGetTaxRatesQuery,
    useLazyGetTaxRatesQuery,
    useUpdateTaxRateMutation,
} = taxRatesApi
