import { createSlice } from '@reduxjs/toolkit'

const initialState = {
    selectedItemsIds: [],
    expandedItemsIds: [],
    collapsedItemsIds: [],
    filterOptions: 'showAllItems',
    isCalculatingQuote: false,
    selectedQuoteItemDrawingId: null,
    openDrawingDoctorModal: false,
    openPartQuoteHistoryModal: false,
    quoteStatus: null,
    quotePaymentStatus: null,
    selectedPartFromLibrary: null,
}

export const quoteItemsSlice = createSlice({
    name: 'quoteItemsSlice',
    initialState,
    reducers: {
        setSelectedItemsIds: (state, action) => {
            state.selectedItemsIds = action.payload
        },
        addSelectedItem: (state, action) => {
            state.selectedItemsIds.push(action.payload)
        },
        removeSelectedItem: (state, action) => {
            state.selectedItemsIds = state.selectedItemsIds.filter((item) => item !== action.payload)
        },
        clearSelectedItems: (state) => {
            state.selectedItemsIds = []
        },
        setExpandedItemsIds: (state, action) => {
            state.expandedItemsIds = action.payload
        },
        addExpandedItem: (state, action) => {
            state.expandedItemsIds.push(action.payload)
        },
        removeExpandedItem: (state, action) => {
            state.expandedItemsIds = state.expandedItemsIds.filter((item) => item !== action.payload)
        },
        clearExpandedItems: (state) => {
            state.expandedItemsIds = []
        },
        setCollapsedItemsIds: (state, action) => {
            state.collapsedItemsIds = action.payload
        },
        addCollapsedItem: (state, action) => {
            state.collapsedItemsIds.push(action.payload)
        },
        removeCollapsedItem: (state, action) => {
            state.collapsedItemsIds = state.collapsedItemsIds.filter((item) => item !== action.payload)
        },
        clearCollapsedItems: (state) => {
            state.collapsedItemsIds = []
        },
        setFilterOptions: (state, action) => {
            state.filterOptions = action.payload
        },
        setIsCalculatingQuote: (state, action) => {
            state.isCalculatingQuote = action.payload
        },

        setSelectedQuoteItemDrawingId: (state, action) => {
            state.selectedQuoteItemDrawingId = action.payload
        },
        setOpenDrawingDoctorModal: (state, action) => {
            state.openDrawingDoctorModal = action.payload
        },
        setOpenPartQuoteHistoryModal: (state, action) => {
            state.openPartQuoteHistoryModal = action.payload
        },
        setSelectedQuoteStatus: (state, action) => {
            state.quoteStatus = action.payload
        },
        setSelectedQuotePaymentStatus: (state, action) => {
            state.quotePaymentStatus = action.payload
        },
        setSelectedPartFromLibrary: (state, action) => {
            state.selectedPartFromLibrary = action.payload
        },
    },
})

export const selectSelectedItems = (state) => state.quoteItemsSlice.selectedItemsIds
export const selectExpandedItems = (state) => state.quoteItemsSlice.expandedItemsIds
export const selectCollapsedItems = (state) => state.quoteItemsSlice.collapsedItemsIds
export const selectFilterOptions = (state) => state.quoteItemsSlice.filterOptions
export const selectIsCalculatingQuote = (state) => state.quoteItemsSlice.isCalculatingQuote
export const selectQuoteItemDrawingId = (state) => state.quoteItemsSlice.selectedQuoteItemDrawingId
export const selectOpenDrawingDoctorModal = (state) => state.quoteItemsSlice.openDrawingDoctorModal
export const selectOpenPartQuoteHistoryModal = (state) => state.quoteItemsSlice.openPartQuoteHistoryModal
export const selectedQuoteStatus = (state) => state.quoteItemsSlice.quoteStatus
export const selectQuotePaymentStatus = (state) => state.quoteItemsSlice.quotePaymentStatus
export const selectSelectedPartFromLibrary = (state) => state.quoteItemsSlice.selectedPartFromLibrary

export const {
    addCollapsedItem,
    addExpandedItem,
    addSelectedItem,
    clearCollapsedItems,
    clearExpandedItems,
    clearSelectedItems,
    removeCollapsedItem,
    removeExpandedItem,
    removeSelectedItem,
    setCollapsedItemsIds,
    setExpandedItemsIds,
    setFilterOptions,
    setIsCalculatingQuote,
    setOpenDrawingDoctorModal,
    setOpenPartQuoteHistoryModal,
    setSelectedItemsIds,
    setSelectedPartFromLibrary,
    setSelectedQuoteItemDrawingId,
    setSelectedQuotePaymentStatus,
    setSelectedQuoteStatus,
} = quoteItemsSlice.actions

export default quoteItemsSlice.reducer
