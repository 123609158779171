import { useTranslation } from 'react-i18next'
import { useSelector } from 'react-redux'
import { Alert, AlertTitle, Button, Typography } from '@mui/material'
import Grid from '@mui/material/Unstable_Grid2'

import { selectOrganisation } from '@/app/slices/organisationSlice'
import { useToolBoxTreatments } from '@/common/hooks'
import { LIBELLULA_PRICING_REDIRECT_URL, TOOLBOX_PRICING_REDIRECT_URL } from '@/common/utils'

const FreePlanCard = () => {
    const { t } = useTranslation()

    const organisation = useSelector(selectOrganisation)
    const { showLibellulaWhitelabelling } = useToolBoxTreatments()

    const handleActivateButtonClicked = () => {
        window.open(
            showLibellulaWhitelabelling
                ? LIBELLULA_PRICING_REDIRECT_URL
                : `${TOOLBOX_PRICING_REDIRECT_URL}${organisation?.paymentGatewayCustomerId}`
        )
    }

    const handlePlanInfoButtonClicked = () => {
        window.open('https://tempustools.com/pricing/')
    }

    return (
        <Grid
            px={2}
            spacing={4}
            container
        >
            <Grid xs={12}>
                <Typography
                    data-testid="subscription-settings-title"
                    variant="h4"
                >
                    {t('Subscription')}
                </Typography>
                <Typography variant="body2">
                    {t('Update your current plan, billing details or payment method')}
                </Typography>
            </Grid>

            <Grid
                columnSpacing={3}
                rowSpacing={3}
                xs={12}
                container
            >
                <Grid xs={12}>
                    <Alert
                        severity="warning"
                        variant="filled"
                    >
                        <AlertTitle>{t('You are on a free plan')}</AlertTitle>
                        {t('Purchase one of our popular plans to take your quoting experience to the next level!')}
                    </Alert>
                </Grid>

                <Grid xs={6}>
                    <Button
                        color="secondary"
                        size="large"
                        variant="contained"
                        fullWidth
                        onClick={handlePlanInfoButtonClicked}
                    >
                        {t('Plans and Features')}
                    </Button>
                </Grid>

                <Grid xs={6}>
                    <Button
                        color="warning"
                        size="large"
                        variant="contained"
                        fullWidth
                        onClick={handleActivateButtonClicked}
                    >
                        {t('Purchase now')}
                    </Button>
                </Grid>
            </Grid>
        </Grid>
    )
}

export default FreePlanCard
