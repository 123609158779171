import { useEffect, useMemo, useState } from 'react'
import { Trans, useTranslation } from 'react-i18next'
import { useDispatch, useSelector } from 'react-redux'
import { Add } from '@mui/icons-material'
import { Box, Button, FormControl, InputLabel, Link, MenuItem, Select, Typography } from '@mui/material'
import PropTypes from 'prop-types'

import { useGetMaterialsQuery } from '@/app/services/materials'
import { selectSelectedMaterialId, setSelectedMaterialId, setShowAddMaterialForm } from '@/app/slices/materialsSlice'
import {
    selectIsOnFreePlan,
    selectOrganisationId,
    selectPaidFeatures,
    selectReduceTrialAccess,
} from '@/app/slices/organisationSlice'
import { TbxShowToggle, TbxTooltip } from '@/common/components'
import TbxToolbarSearch from '@/common/components/TbxToolbar/TbxToolbarSearch'

import MaterialsListItem from './MaterialsListItem'

const classes = {
    searchInput: {
        width: '100%',
        '& input::placeholder': {
            fontSize: '16px',
            lineHeight: '24px',
            color: 'black',
        },
        mb: 2,
    },
    toggleContainer: {
        mb: 2,
    },
}

const compareMaterials = (a, b) => {
    if (a.materialName.toLocaleLowerCase() < b.materialName.toLocaleLowerCase()) {
        return -1
    }
    if (a.materialName.toLocaleLowerCase() > b.materialName.toLocaleLowerCase()) {
        return 1
    }
    return 0
}

const filterOptions = [
    { label: 'All', value: 'All' },
    { label: 'Flat', value: 'Flat' },
    { label: 'Rotary', value: 'Rotary' },
]

const MaterialsList = ({ onClose }) => {
    const { t } = useTranslation()
    const dispatch = useDispatch()
    const organisationId = useSelector(selectOrganisationId)
    const paidFeatures = useSelector(selectPaidFeatures)
    const isOnFreePlan = useSelector(selectIsOnFreePlan)
    const reduceTrialAccess = useSelector(selectReduceTrialAccess)

    const { data: materials, isLoading } = useGetMaterialsQuery({ organisationId })
    const availableMaterialCount = materials ? Object.values(materials).filter((mat) => !mat.isDeleted).length : 0

    const selectedMaterialId = useSelector(selectSelectedMaterialId)
    const selectedMaterial = materials && !isLoading ? materials[selectedMaterialId] : null

    const [showArchivedMaterials, setShowArchivedMaterials] = useState(false)
    const [materialsSearchValue, setMaterialsSearchValue] = useState('')
    const [materialsFilterOption, setMaterialsFilterOption] = useState('All')

    const filteredMaterials = useMemo(() => {
        if (!materials) return []
        const materialsArray = Object.values(materials)

        const filteredMaterials =
            materialsFilterOption && materialsFilterOption !== 'All'
                ? materialsArray.filter((m) => m.type === materialsFilterOption)
                : materialsArray

        let searchedMaterials = materialsSearchValue
            ? filteredMaterials.filter((material) =>
                  material.materialName.toLocaleLowerCase().includes(materialsSearchValue.toLocaleLowerCase())
              )
            : filteredMaterials

        searchedMaterials = searchedMaterials?.slice()?.sort(compareMaterials)

        if (!paidFeatures.hasUnfoldTube) {
            searchedMaterials = searchedMaterials.filter((m) => m.type === 'Flat')
        }

        return showArchivedMaterials ? searchedMaterials : searchedMaterials.filter((material) => !material.isDeleted)
    }, [materialsSearchValue, materialsFilterOption, materials, showArchivedMaterials, paidFeatures.hasUnfoldTube])

    useEffect(() => {
        if (selectedMaterial) {
            const selectedIsFiltered = filteredMaterials.find((c) => c.materialId === selectedMaterial.materialId)
            if (selectedIsFiltered === undefined) dispatch(setSelectedMaterialId(null))
        }
    }, [filteredMaterials, selectedMaterial, dispatch])

    const handleShowArchivedMaterialsChange = () => {
        setShowArchivedMaterials(!showArchivedMaterials)
    }

    const handleSearchValueChange = (value) => {
        setMaterialsSearchValue(value)
    }

    const handleFilterOptionChange = (event) => {
        setMaterialsFilterOption(event.target.value)
    }

    const handleAddMaterialClick = () => {
        dispatch(setShowAddMaterialForm(true))
    }

    return (
        <>
            <FormControl
                size="small"
                sx={{ mt: '72px', mb: 2 }}
                variant="outlined"
                fullWidth
            >
                <InputLabel id="material-filter-label">Profile Type</InputLabel>
                <Select
                    data-testid="materials-filter-select"
                    label="Profile Type"
                    labelId="material-filter-label"
                    value={materialsFilterOption}
                    onChange={handleFilterOptionChange}
                >
                    {filterOptions.map((option) => (
                        <MenuItem
                            key={option.value}
                            value={option.value}
                        >
                            {option.label}
                        </MenuItem>
                    ))}
                </Select>
            </FormControl>

            <TbxToolbarSearch
                dataTestId="material-toolbar-search"
                handleChange={handleSearchValueChange}
                placeholder="Search material..."
                searchValue={materialsSearchValue}
                sx={classes.searchInput}
            />

            <TbxShowToggle
                checked={showArchivedMaterials}
                label={t('Show archived materials')}
                sx={classes.toggleContainer}
                onChange={handleShowArchivedMaterialsChange}
            />

            <TbxTooltip
                title={
                    isOnFreePlan && availableMaterialCount > 2 ? (
                        <Trans t={t}>
                            Maximum of 3 materials available on your Free plan. You must first archive a material before
                            adding another active material.
                        </Trans>
                    ) : reduceTrialAccess && !isOnFreePlan ? (
                        <Trans t={t}>
                            Not available in trial mode. <Link href="/activate">Subscribe here.</Link>
                        </Trans>
                    ) : (
                        ''
                    )
                }
                arrow
            >
                <span>
                    {isOnFreePlan ? (
                        <Button
                            color="secondary"
                            data-testid="add-material-button"
                            disabled={availableMaterialCount > 2}
                            startIcon={<Add />}
                            variant="contained"
                            fullWidth
                            onClick={handleAddMaterialClick}
                        >
                            {t('Add new material')}
                        </Button>
                    ) : (
                        <Button
                            color="secondary"
                            data-testid="add-material-button"
                            startIcon={<Add />}
                            variant="contained"
                            fullWidth
                            onClick={handleAddMaterialClick}
                        >
                            {t('Add new material')}
                        </Button>
                    )}
                </span>
            </TbxTooltip>

            {isLoading ? (
                <Typography
                    my={3}
                    textAlign="center"
                    variant="body2"
                >
                    {t('Loading materials...')}
                </Typography>
            ) : (
                <Box my={2}>
                    {filteredMaterials?.map((material) => (
                        <MaterialsListItem
                            availableMaterialCount={availableMaterialCount}
                            key={material.materialId}
                            material={material}
                            selectedMaterial={selectedMaterial}
                            onMaterialSelected={onClose}
                        />
                    ))}
                </Box>
            )}
        </>
    )
}

MaterialsList.propTypes = {
    onClose: PropTypes.func,
}

export default MaterialsList
