import { Typography } from '@mui/material'
import PropTypes from 'prop-types'

const SecondaryProcessDetailsDisplay = ({ part }) => {
    return part?.secondaryProcesses
        ?.filter((secProc) => secProc.secondaryProcess?.customerVisible)
        .map((secProc) => (
            <Typography
                key={secProc.secondaryProcess.name}
                variant="body2"
            >
                {secProc.secondaryProcess?.name}
            </Typography>
        ))
}

SecondaryProcessDetailsDisplay.propTypes = {
    part: PropTypes.object,
}

export default SecondaryProcessDetailsDisplay
