import { useRef, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useSelector } from 'react-redux'
import {
    Box,
    Button,
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableHead,
    TablePagination,
    TableRow,
    Typography,
} from '@mui/material'
import Grid from '@mui/material/Unstable_Grid2'
import { useSnackbar } from 'notistack'
import useLocalStorage from 'use-local-storage'

import {
    useArchiveDeliveryContainerMutation,
    useGetDeliveryContainersQuery,
    useUnarchiveDeliveryContainerMutation,
} from '@/app/services/deliveryContainers'
import { selectOrganisationId, selectUseImperialUnits } from '@/app/slices/organisationSlice'
import { AlertDialog, TbxShowToggle } from '@/common/components'
import { AddIcon } from '@/common/icons'
import {
    CONTAINERS_PER_PAGE_DEFAULT_VALUE,
    CONTAINERS_PER_PAGE_OPTIONS,
    CONTAINERS_PER_PAGE_VARIABLE,
} from '@/common/utils'

import AddDeliveryContainerDialog from './AddDeliveryContainerDialog'
import DeliveryContainerRow from './DeliveryContainerRow'

const classes = {
    tableContainer: {
        background: (theme) => theme.palette.background.paper,
        border: (theme) => `1px solid ${theme.palette.grey[400]}`,
        boxSizing: 'border-box',
        borderRadius: 2,
    },
    table: {
        tableLayout: 'fixed',
        boxSizing: 'border-box',
        borderCollapse: 'separate',
    },
    headerTableCell: {
        paddingBlock: 2,
        paddingInline: 2,
        verticalAlign: 'bottom',
    },
    tableRow: {
        '&:hover': {
            background: (theme) => theme.palette.action.hover,
        },
    },
    tableCell: {
        paddingBlock: 2,
        paddingInline: 2,
    },
    tableCellWithEllipsis: {
        overflow: 'hidden',
        whiteSpace: 'nowrap',
        textOverflow: 'ellipsis',
    },
    tableFooter: {
        display: 'flex',
        alignItems: 'center',
        flexDirection: 'row-reverse',
    },
    iconColor: (active = true) => ({
        color: active ? 'secondary.main' : 'text.disabled',
    }),
    activeRate: (active = true) => ({
        color: active ? 'text.primary' : 'text.disabled',
    }),

    zoneTitle: {
        fontWeight: 500,
        color: 'text.primary',
        textTransform: 'uppercase',
    },
    zoneBorderLeft: {
        borderLeft: '2px dashed #0078A4;',
    },
    zoneBorderRight: {
        borderRight: '2px dashed #0078A4;',
    },
}

const DeliveryContainers = () => {
    const { t } = useTranslation()
    const { enqueueSnackbar } = useSnackbar()

    const organisationId = useSelector(selectOrganisationId)
    const isImperial = useSelector(selectUseImperialUnits)

    const [showArchivedContainers, setShowArchivedContainers] = useState(false)
    const [showAddContainerDialog, setShowAddContainerDialog] = useState(false)
    const [showArchiveContainerDialog, setShowArchiveContainerDialog] = useState(false)
    const [showUnarchiveContainerDialog, setShowUnarchiveContainerDialog] = useState(false)
    const [currentPage, setCurrentPage] = useState(0)

    const {
        data: deliveryContainers,
        isLoading: isLoadingContainers,
        refetch,
    } = useGetDeliveryContainersQuery({
        organisationId,
        params: { includeDeleted: showArchivedContainers },
    })

    const [archiveContainer] = useArchiveDeliveryContainerMutation()
    const [unarchiveContainer] = useUnarchiveDeliveryContainerMutation()

    const [currentRowsPerPage, setCurrentRowsPerPage] = useLocalStorage(
        CONTAINERS_PER_PAGE_VARIABLE,
        CONTAINERS_PER_PAGE_DEFAULT_VALUE
    )

    const containerRef = useRef(null)

    const weightUnit = isImperial ? 'lb' : 'kg'
    const lengthUnit = isImperial ? 'in' : 'mm'

    const handleChangePage = (_, newPage) => {
        setCurrentPage(newPage)
    }

    const handleChangeRowsPerPage = (event) => {
        const newRowsPerPage = parseInt(event.target.value, 10)
        setCurrentRowsPerPage(newRowsPerPage)
        setCurrentPage(0)
    }

    const handleAddContainerButtonClick = () => {
        setShowAddContainerDialog(true)
    }

    const handleEditContainerButtonClick = (container) => {
        containerRef.current = container
        setShowAddContainerDialog(true)
    }

    const handleCloseAddContainerDialog = () => {
        containerRef.current = null
        setShowAddContainerDialog(false)
    }

    const handleArchiveContainerButtonClick = (container) => {
        containerRef.current = container
        setShowArchiveContainerDialog(true)
    }

    const handleUnarchiveContainerButtonClick = (container) => {
        containerRef.current = container
        setShowUnarchiveContainerDialog(true)
    }

    const handleCancelDialog = () => {
        containerRef.current = null
        setShowArchiveContainerDialog(false)
    }

    const handleShowArchivedContainersToggle = () => {
        setShowArchivedContainers((prev) => !prev)
        refetch()
    }

    const handleArchiveConfirmation = async () => {
        try {
            await archiveContainer({
                organisationId,
                containerId: containerRef.current.containerId,
                showArchived: showArchivedContainers,
            }).unwrap()
            enqueueSnackbar(t('Delivery container archived successfully'), { variant: 'success' })
        } catch (error) {
            console.error(error)
            enqueueSnackbar(t('Failed to archive delivery container'), { variant: 'error' })
        } finally {
            containerRef.current = null
            setShowArchiveContainerDialog(false)
        }
    }

    const handleUnarchiveConfirmation = async () => {
        try {
            await unarchiveContainer({
                organisationId,
                containerId: containerRef.current.containerId,
                showArchived: showArchivedContainers,
            }).unwrap()
            enqueueSnackbar(t('Delivery container archived successfully'), { variant: 'success' })
        } catch (error) {
            console.error(error)
            enqueueSnackbar(t('Failed to archive delivery container'), { variant: 'error' })
        } finally {
            containerRef.current = null
            setShowUnarchiveContainerDialog(false)
        }
    }

    return (
        <>
            <Grid xs={12}>
                <Typography
                    data-testid="delivery-containers"
                    mb={1}
                    variant="strong1"
                >
                    {t('Delivery containers')}
                </Typography>
                <Typography
                    color="text.secondary"
                    mb={1}
                    variant="body2"
                >
                    {t(
                        'Goods are transported to zones inside containers or vehicles. Define the weight and size constraints of the containers you might use. Each container can be used in one or more zones, and can be assigned its own delivery price for that zone.'
                    )}
                </Typography>

                <Box
                    alignItems="center"
                    display="flex"
                    justifyContent="flex-end"
                >
                    <Box
                        mr={3}
                        width="fit-content"
                    >
                        <TbxShowToggle
                            checked={showArchivedContainers}
                            data-testid="show-archived-rates-toggle"
                            label={t('Show archived containers')}
                            onChange={handleShowArchivedContainersToggle}
                        />
                    </Box>
                    <Button
                        color="secondary"
                        data-testid="add-delivery-zone-button"
                        size="small"
                        startIcon={
                            <AddIcon
                                size={18}
                                type="sharp"
                                variant="solid"
                            />
                        }
                        variant="outlined"
                        onClick={handleAddContainerButtonClick}
                    >
                        {t('Add new container')}
                    </Button>
                </Box>
            </Grid>
            {!isLoadingContainers ? (
                <Grid xs={12}>
                    <TableContainer sx={classes.tableContainer}>
                        <Table sx={classes.table}>
                            <colgroup>
                                <col style={{ width: '120px' }} />
                                <col style={{ width: '120px' }} />
                                <col style={{ width: '120px' }} />
                                <col style={{ width: '120px' }} />
                                <col style={{ width: '120px' }} />
                                <col style={{ width: '90px' }} />
                            </colgroup>

                            <TableHead>
                                <TableRow>
                                    <TableCell sx={classes.headerTableCell}>{t('Container')}</TableCell>
                                    <TableCell
                                        align="right"
                                        sx={classes.headerTableCell}
                                    >
                                        {t('Maximum weight')} ({weightUnit})
                                    </TableCell>
                                    <TableCell
                                        align="right"
                                        sx={classes.headerTableCell}
                                    >
                                        {t('Maximum length')} ({lengthUnit})
                                    </TableCell>

                                    <TableCell
                                        align="right"
                                        sx={classes.headerTableCell}
                                    >
                                        {t('Maximum width')} ({lengthUnit})
                                    </TableCell>

                                    <TableCell
                                        align="right"
                                        sx={classes.headerTableCell}
                                    >
                                        {t('Maximum height')} ({lengthUnit})
                                    </TableCell>
                                    <TableCell></TableCell>
                                </TableRow>
                            </TableHead>
                            <TableBody>
                                {deliveryContainers?.length ? (
                                    deliveryContainers
                                        ?.slice(
                                            currentPage * currentRowsPerPage,
                                            currentPage * currentRowsPerPage + currentRowsPerPage
                                        )
                                        ?.map((container) => {
                                            return (
                                                <DeliveryContainerRow
                                                    container={container}
                                                    handleArchiveButtonClick={handleArchiveContainerButtonClick}
                                                    handleEditButtonClick={handleEditContainerButtonClick}
                                                    handleUnarchiveButtonClick={handleUnarchiveContainerButtonClick}
                                                    key={container.containerId}
                                                />
                                            )
                                        })
                                ) : (
                                    <TableRow>
                                        <TableCell
                                            align="center"
                                            colSpan={9}
                                            sx={classes.tableCell}
                                        >
                                            {t('Please add a container to your organisation')}
                                        </TableCell>
                                    </TableRow>
                                )}
                            </TableBody>
                        </Table>

                        <TablePagination
                            component="div"
                            count={deliveryContainers?.length ?? 0}
                            labelDisplayedRows={({ count, from, to }) => `${from}-${to} of ${count}`}
                            labelRowsPerPage={t('Containers per page')}
                            page={currentPage}
                            rowsPerPage={currentRowsPerPage}
                            rowsPerPageOptions={CONTAINERS_PER_PAGE_OPTIONS}
                            sx={classes.tableFooter}
                            onPageChange={handleChangePage}
                            onRowsPerPageChange={handleChangeRowsPerPage}
                        />
                    </TableContainer>
                </Grid>
            ) : null}

            {showAddContainerDialog ? (
                <AddDeliveryContainerDialog
                    container={containerRef.current}
                    open={showAddContainerDialog}
                    onClose={handleCloseAddContainerDialog}
                />
            ) : null}

            {showArchiveContainerDialog ? (
                <AlertDialog
                    content={t(
                        'Are you sure you want to archive {{containerName}} from your organisation? Archiving the container will disable it for all zones.',
                        {
                            containerName: containerRef.current?.name,
                        }
                    )}
                    okButtonText={t('Yes, archive')}
                    open={showArchiveContainerDialog}
                    title={t('Archive container')}
                    onCancelClose={handleCancelDialog}
                    onOkClose={handleArchiveConfirmation}
                />
            ) : null}

            {showUnarchiveContainerDialog ? (
                <AlertDialog
                    content={t(
                        'Are you sure you want to unarchive {{containerName}} from your organisation? Unarchiving the container will re-enable it for all assigned zones.',
                        {
                            containerName: containerRef.current?.name,
                        }
                    )}
                    okButtonText={t('Yes, unarchive')}
                    open={showUnarchiveContainerDialog}
                    title={t('Unarchive container')}
                    onCancelClose={handleCancelDialog}
                    onOkClose={handleUnarchiveConfirmation}
                />
            ) : null}
        </>
    )
}

DeliveryContainers.propTypes = {}

export default DeliveryContainers
