export function getUserDtoFromMsalAccountInfo({
    idTokenClaims: { family_name: lastName, given_name: firstName } = {},
    localAccountId: userId,
    name,
    username: emailAddress,
} = {}) {
    return {
        userId,
        emailAddress,
        name,
        firstName,
        lastName,
    }
}
