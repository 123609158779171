import { createTheme } from '@mui/material/styles'
import { deepmerge } from '@mui/utils'

export const PrintDocumentsOverrides = (parentTheme) =>
    createTheme(
        deepmerge(parentTheme, {
            components: {
                MuiCssBaseline: {
                    styleOverrides: {
                        html: {
                            '@media print': {
                                width: 'initial',
                                height: 'initial',
                            },
                        },
                        body: {
                            '@media print': {
                                backgroundColor: '#FFFFFF',
                                color: '#2b2b2b',
                                width: 'initial',
                                height: 'initial',
                            },
                        },
                    },
                },
                MuiTypography: {
                    styleOverrides: {
                        root: {
                            '@media print': {
                                color: '#2b2b2b',
                            },
                        },
                    },
                },
                MuiTableCell: {
                    styleOverrides: {
                        root: {
                            '@media print': {
                                borderColor: '#d7d7d7;',
                                color: '#2b2b2b',
                            },
                        },
                    },
                },
                MuiLink: {
                    styleOverrides: {
                        root: {
                            '@media print': {
                                color: parentTheme.palette.primary.main,
                            },
                        },
                    },
                },
                MuiCheckbox: {
                    styleOverrides: {
                        root: {
                            '@media print': {
                                color: '#2b2b2b',
                            },
                        },
                    },
                },
                MuiInputLabel: {
                    styleOverrides: {
                        root: {
                            '@media print': {
                                color: '#2b2b2b',
                            },
                        },
                    },
                },
            },
        })
    )
