import { useMemo } from 'react'
import { useTranslation } from 'react-i18next'
import { useSelector } from 'react-redux'
import { ChromeReaderMode, ChromeReaderModeOutlined, HowToRegRounded, Receipt } from '@mui/icons-material'
import { Box, Button, TableCell, TableRow, Typography } from '@mui/material'
import PropTypes from 'prop-types'

import { useGetCuttingTechnologiesQuery } from '@/app/services/cuttingTechnologies'
import { useGetMaterialsQuery } from '@/app/services/materials'
import { useGetSecondaryProcessesQuery } from '@/app/services/secondaryProcesses'
import { useGetSheetsQuery } from '@/app/services/sheets'
import { selectLocale } from '@/app/slices/appSlice'
import { selectCurrencyCode, selectOrganisationId, selectUseImperialUnits } from '@/app/slices/organisationSlice'
import ThumbnailUri from '@/common/components/ThumbnailUri/ThumbnailUri'
import { formatCurrency } from '@/common/helpers/formatUtilities'
import { useNumberFormatter } from '@/common/hooks'
import { quoteItemErrors, quoteItemHasIssues, quoteItemWarnings } from '@/common/utils/quoteUtilities'

import QuoteItemStatus from '../common/QuoteItemStatus'

const classes = {
    tableCell: {
        py: 1.5,
    },
    tableCellBorder: {
        borderRight: (theme) => `1px dashed ${theme.palette.text.disabled}`,
    },
}

const MaterialConsumptionModes = (materialType) => {
    if (materialType === 'Flat') {
        return [
            { label: 'Whole Sheet', value: 'WholeSheet' },
            { label: 'Nest Bounds', value: 'NestBounds' },
        ]
    }
    return [
        { label: 'Whole Length', value: 'WholeSheet' },
        { label: 'Nest Bounds', value: 'NestBounds' },
    ]
}

const QuoteReviewTableRow = ({ handleOpenNotesModal, handleOpenPriceDetailsModal, quoteItem }) => {
    const { t } = useTranslation()

    const organisationId = useSelector(selectOrganisationId)
    const useImperialUnits = useSelector(selectUseImperialUnits)
    const locale = useSelector(selectLocale)
    const currencyCode = useSelector(selectCurrencyCode)

    const { data: materials, isLoading: isLoadingMaterials } = useGetMaterialsQuery({ organisationId })
    const { data: cuttingTechnologies, isLoading: isLoadingCT } = useGetCuttingTechnologiesQuery({ organisationId })
    const { data: sheets, isLoading: isLoadingSheets } = useGetSheetsQuery({ organisationId })

    const material = useMemo(() => {
        if (isLoadingMaterials) return null
        return materials[quoteItem.materialId]
    }, [quoteItem, materials, isLoadingMaterials])

    const sheet = useMemo(() => {
        if (isLoadingSheets) return null
        return sheets[quoteItem?.sheetId]
    }, [quoteItem, sheets, isLoadingSheets])

    const cuttingTechnology = useMemo(() => {
        if (isLoadingCT) return null
        return cuttingTechnologies[quoteItem?.cuttingTechnologyId]
    }, [quoteItem, cuttingTechnologies, isLoadingCT])

    const { data: organisationSecondaryProcesses } = useGetSecondaryProcessesQuery({ organisationId })

    const { n: metricNumberFormatter } = useNumberFormatter({
        numberOfDecimalPlaces: 2,
    })

    const { n: imperialNumberFormatter } = useNumberFormatter({
        numberOfDecimalPlaces: 4,
    })

    const partHasIssues = quoteItemHasIssues(quoteItem)
    const partWarnings = quoteItemWarnings(quoteItem)
    const partErrors = quoteItemErrors(quoteItem)

    const isPartFromLibrary = Boolean(quoteItem?.partLibraryEntryId)
    const hasFixedPrice = Boolean(quoteItem?.partLibraryEntry?.fixedPrice)
    const isFixedPriceExpired = useMemo(() => {
        if (!quoteItem?.partLibraryEntry?.fixedPriceEndTime) return false
        const currentDate = new Date().setHours(0, 0, 0, 0)
        const fixedPriceEndTime = new Date(quoteItem?.partLibraryEntry?.fixedPriceEndTime).setHours(0, 0, 0, 0)
        return currentDate > fixedPriceEndTime
    }, [quoteItem])

    const partHasNotes = useMemo(() => {
        const { customerNotes, privateNotes, vendorNotes } = quoteItem
        return Boolean(customerNotes) || Boolean(vendorNotes) || Boolean(privateNotes)
    }, [quoteItem])

    const units = useImperialUnits ? 'in' : 'mm'
    const partUnits = (part) => (part?.isImperial ? 'in' : 'mm')

    const getSheetSize = () => {
        const n = useImperialUnits ? imperialNumberFormatter : metricNumberFormatter

        if (sheet?.profile) {
            if (sheet?.profile === 'Circle') {
                return `${n(sheet?.diameter)} x ${n(sheet?.materialLength)}`
            } else {
                return `${n(sheet?.sheetWidth)} x ${n(sheet?.sheetHeight)} x ${n(sheet?.materialLength)}`
            }
        } else {
            return `${n(sheet?.sheetWidth)} x ${n(sheet?.sheetHeight)}`
        }
    }

    const getProcessDetails = (processId) =>
        organisationSecondaryProcesses?.find((osp) => osp?.secondaryProcessId === processId)

    const sortedProcesses = useMemo(() => {
        return quoteItem.secondaryProcesses
            ?.map((process) => ({
                ...process,
                index: getProcessDetails(process.secondaryProcessId)?.index,
            }))
            .sort((a, b) => (a.index < b.index ? -1 : 1))
    }, [quoteItem])

    const renderPartSize = (part) => {
        if (part?.displayBoundBoxWidth && part?.displayBoundBoxHeight) {
            const n = part?.isImperial ? imperialNumberFormatter : metricNumberFormatter
            const bounds = part?.profile
                ? part?.profile.toLowerCase() === 'circle'
                    ? `${n(part?.diameter)} x ${n(part?.rotaryPartLength)}`
                    : `${n(part?.rotaryProfileWidth)} x ${n(part?.rotaryProfileLength)} x ${n(part?.rotaryPartLength)}`
                : `${n(part?.displayBoundBoxWidth)} x ${n(part?.displayBoundBoxHeight)}`
            return (
                <Typography
                    sx={{ whiteSpace: 'break-spaces' }}
                    variant="strong2"
                >{`${bounds} ${partUnits(part)}`}</Typography>
            )
        }
    }

    const renderPartDetails = (part) => {
        return (
            <Box
                display="flex"
                flexDirection="column"
                gap={0.5}
            >
                {!partHasIssues ? <QuoteItemStatus marginLeft={0} /> : null}
                {partHasIssues && partWarnings ? (
                    <QuoteItemStatus
                        marginLeft={0}
                        messages={partWarnings}
                        status="warning"
                    />
                ) : null}
                {partHasIssues && partErrors ? (
                    <QuoteItemStatus
                        marginLeft={0}
                        messages={partErrors}
                        status="error"
                    />
                ) : null}

                <Box>
                    <Typography
                        sx={{ whiteSpace: 'break-space' }}
                        variant="body2"
                    >
                        {part?.name}
                    </Typography>
                    {isPartFromLibrary ? (
                        <Typography variant="body2">
                            {t('ID: ')} {part?.partId}
                        </Typography>
                    ) : null}

                    {'profile' in quoteItem ? (
                        <Typography variant="body2">
                            {t('Profile')}:{' '}
                            <Typography
                                component="span"
                                variant="strong2"
                            >
                                {t(quoteItem.profile)}
                            </Typography>
                        </Typography>
                    ) : null}
                    {renderPartSize(part)}
                </Box>

                <Button
                    color="secondary"
                    size="small"
                    startIcon={partHasNotes ? <ChromeReaderMode /> : <ChromeReaderModeOutlined />}
                    sx={{ px: 0, width: 'fit-content' }}
                    variant="text"
                    disableRipple
                    onClick={() => handleOpenNotesModal(quoteItem)}
                >
                    {t('Part Notes')}
                </Button>
            </Box>
        )
    }

    const renderMaterialDetails = (part) => {
        const n = useImperialUnits ? imperialNumberFormatter : metricNumberFormatter
        return (
            <Box
                display="flex"
                flexDirection="column"
                gap={1}
            >
                <Box>
                    <Typography
                        sx={{ whiteSpace: 'break-space' }}
                        variant="body2"
                    >
                        {material?.materialName}
                    </Typography>

                    <Typography
                        sx={{ whiteSpace: 'break-space' }}
                        variant="body2"
                    >
                        {`${n(part?.thickness)} ${units}`}
                    </Typography>
                    <Typography variant="body2">{`${getSheetSize()} ${units}`}</Typography>
                </Box>

                {part?.isCustomerSuppliedMaterial ? (
                    <Box
                        alignItems="center"
                        display="flex"
                        justifyContent="space-between"
                        width={1}
                    >
                        <Typography variant="body2">{t('Customer supplied')}</Typography>
                        <HowToRegRounded
                            color="secondary"
                            fontSize="small"
                        />
                    </Box>
                ) : null}

                <Typography variant="body2">
                    {t(
                        MaterialConsumptionModes(material?.type).find(
                            (option) => option.value === part?.materialConsumptionMode
                        )?.label ?? ''
                    )}
                </Typography>
            </Box>
        )
    }

    const renderSecondayProcesses = (part) => {
        return (
            <Box
                alignItems="flex-start"
                display="flex"
                flex={1}
                flexDirection="column"
                gap={0.5}
                justifyContent="flex-start"
            >
                {part?.numberOfFolds > 0 ? <Typography variant="body2">{t('Folding')}</Typography> : null}

                {sortedProcesses?.map((secProc, index) => (
                    <Box
                        alignItems="center"
                        display="flex"
                        gap={0.5}
                        key={index}
                    >
                        <Typography variant="body2">{getProcessDetails(secProc?.secondaryProcessId)?.name}</Typography>
                    </Box>
                ))}
            </Box>
        )
    }

    return !isLoadingMaterials && !isLoadingSheets && !isLoadingCT ? (
        <TableRow key={quoteItem.id}>
            <TableCell
                align="center"
                sx={classes.tableCell}
            >
                <ThumbnailUri
                    uri={quoteItem.thumbnailUri}
                    wrapperWidth={1}
                />
            </TableCell>

            <TableCell
                align="left"
                sx={classes.tableCell}
            >
                {renderPartDetails(quoteItem)}
            </TableCell>

            <TableCell
                align="left"
                sx={classes.tableCell}
            >
                {cuttingTechnology?.name}
            </TableCell>

            <TableCell
                align="left"
                sx={classes.tableCell}
            >
                {renderMaterialDetails(quoteItem)}
            </TableCell>

            <TableCell
                align="left"
                sx={classes.tableCell}
            >
                {renderSecondayProcesses(quoteItem)}
            </TableCell>

            <TableCell
                align="right"
                sx={[classes.tableCell, classes.tableCellBorder]}
            >
                {quoteItem?.quantity}
            </TableCell>

            <TableCell
                align="right"
                sx={classes.tableCell}
            >
                {formatCurrency(quoteItem?.materialPrice, currencyCode, locale)}
            </TableCell>

            <TableCell
                align="right"
                sx={classes.tableCell}
            >
                {formatCurrency(quoteItem?.processingTotalPrice, currencyCode, locale)}
            </TableCell>

            <TableCell
                align="right"
                sx={classes.tableCell}
            >
                {formatCurrency(quoteItem?.foldingTotalPricePerItem, currencyCode, locale)}
            </TableCell>

            <TableCell
                align="right"
                sx={classes.tableCell}
            >
                {formatCurrency(quoteItem?.secondaryProcessTotalPricePerItem, currencyCode, locale)}
            </TableCell>

            <TableCell
                align="right"
                sx={classes.tableCell}
            >
                {formatCurrency(quoteItem?.otherChargePrice, currencyCode, locale)}
            </TableCell>

            <TableCell
                align="right"
                sx={classes.tableCell}
            >
                <Typography variant="strong2">{formatCurrency(quoteItem?.itemPrice, currencyCode, locale)}</Typography>
            </TableCell>

            <TableCell
                align="right"
                sx={classes.tableCell}
            >
                <Box position="relative">
                    <Typography variant="strong2">
                        {formatCurrency(quoteItem?.linePrice, currencyCode, locale)}
                    </Typography>
                    <Box
                        position="absolute"
                        right={0}
                    >
                        <Button
                            data-testid="nqp-quote-item-unit-times-details"
                            endIcon={<Receipt />}
                            size="small"
                            sx={{ px: 0, width: 'fit-content' }}
                            variant="text"
                            disableRipple
                            onClick={() => handleOpenPriceDetailsModal(quoteItem)}
                        >
                            {t('Details')}
                        </Button>
                    </Box>
                </Box>
            </TableCell>
        </TableRow>
    ) : null
}

QuoteReviewTableRow.propTypes = {
    handleOpenNotesModal: PropTypes.func,
    handleOpenPriceDetailsModal: PropTypes.func,
    quoteItem: PropTypes.object,
}

export default QuoteReviewTableRow
