import { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { SvgLoader, SvgProxy } from 'react-svgmt'
import { Box, CircularProgress, Container, Typography } from '@mui/material'
import PropTypes from 'prop-types'

import { useGetQuickPartsSvgQuery } from '@/app/services/quickPartsApi'

import AnimatedDimension from './AnimatedDimension/AnimatedDimension'

import './PartViewer.css'

const classes = {
    viewerContainer: {
        height: '100%',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
    },
    centeredContentContainer: {
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
    },
    partViewerText: {
        color: '#FFFFFF',
    },
}

const PartViewer = ({ nqp, onDimensionSelected, part, selectedDimension }) => {
    const { t } = useTranslation()

    const { data: partSvg, isError, isLoading } = useGetQuickPartsSvgQuery(part, { skip: !part })

    const [selectedDimensionId, setSelectedDimensionId] = useState(null)

    const defaultDimStrokeWidth = 0.25
    const animatedDimMaxStrokeWidth = 0.35

    useEffect(() => {
        if (!selectedDimension) {
            setSelectedDimensionId(null)
            return
        }

        setSelectedDimensionId('dim_' + selectedDimension)
    }, [selectedDimension])

    const handleDimensionSelected = (event, dimensionId) => {
        event.preventDefault()

        if (typeof onDimensionSelected === 'function') {
            const dimension = dimensionId ? dimensionId.replace('dim_', '') : null
            onDimensionSelected(dimension)
        }
    }

    return (
        <Container sx={classes.viewerContainer}>
            {isLoading || !part ? (
                <Box sx={classes.centeredContentContainer}>
                    <CircularProgress />
                    <Typography
                        sx={classes.partViewerText}
                        variant="h4"
                    >
                        {t('Rendering')}...
                    </Typography>
                </Box>
            ) : null}
            {isError ? (
                <Box sx={classes.centeredContentContainer}>
                    <Typography
                        sx={classes.partViewerText}
                        variant="h3"
                    >
                        {t('There was an error rendering the part')}
                    </Typography>
                </Box>
            ) : null}

            {part ? (
                <SvgLoader
                    className={nqp ? 'partViewerSvgNqp' : 'partViewerSvg'}
                    svgXML={partSvg}
                >
                    {
                        // This fragment below is to avoid the appending of an integer (e.g. "-0") to the selected arrows id names
                        <>
                            <SvgProxy
                                id="SelectedArrowStart"
                                selector='marker[id*="SelectedArrowStart"]'
                            />
                            <SvgProxy
                                id="SelectedArrowEnd"
                                selector='marker[id*="SelectedArrowEnd"]'
                            />
                        </>
                    }
                    <SvgProxy
                        fill=""
                        selector="g.Dim"
                        stroke=""
                        {...{ 'stroke-width': defaultDimStrokeWidth + '%' }}
                        class="Dim"
                        onMouseDown={(e) => {
                            handleDimensionSelected(e, e.target.parentNode.id)
                        }}
                    />
                    {selectedDimensionId ? (
                        <AnimatedDimension
                            maxStrokeWidth={animatedDimMaxStrokeWidth}
                            minStrokeWidth={defaultDimStrokeWidth}
                            selectedDimensionId={selectedDimensionId}
                        />
                    ) : null}
                </SvgLoader>
            ) : null}
        </Container>
    )
}

PartViewer.propTypes = {
    nqp: PropTypes.bool,
    part: PropTypes.object,
    selectedDimension: PropTypes.string,
    onDimensionSelected: PropTypes.func,
}

export default PartViewer
