import MuiTableRow from '@mui/material/TableRow'
import PropTypes from 'prop-types'

const TableRow = ({ children, hover = false, selected = false, ...other }) => {
    return (
        <MuiTableRow
            hover={hover}
            selected={selected}
            {...other}
        >
            {children}
        </MuiTableRow>
    )
}

TableRow.propTypes = {
    children: PropTypes.node,
    hover: PropTypes.bool,
    selected: PropTypes.bool,
}

export default TableRow
