import _ from 'lodash'

import CustomError from '../Utilities/CustomError'

import Path from './Path'
import Point from './Point'

const kSvgMargin = 0.02

export default class Drawing {
    constructor() {
        this.drawingId = -1
        this.issues = []
        this.height = 0
        this.width = 0
        this.minX = 0
        this.minY = 0

        if (arguments.length === 1) {
            this.constructFromJson(arguments[0])
        } else {
            this.constructNormally(arguments[0], arguments[1])
        }
    }

    constructNormally(name, paths) {
        this.name = name
        this.setPaths(paths)
    }

    constructFromJson(json) {
        json.isValid = !json.issues || json.issues.length === 0
        const jsonValid =
            json &&
            Object.prototype.hasOwnProperty.call(json, 'drawingId') &&
            Object.prototype.hasOwnProperty.call(json, 'name') &&
            Object.prototype.hasOwnProperty.call(json, 'isValid') &&
            Object.prototype.hasOwnProperty.call(json, 'width') &&
            Object.prototype.hasOwnProperty.call(json, 'height') &&
            Object.prototype.hasOwnProperty.call(json, 'minX') &&
            Object.prototype.hasOwnProperty.call(json, 'minY') &&
            Object.prototype.hasOwnProperty.call(json, 'paths') &&
            Object.prototype.hasOwnProperty.call(json, 'issues')

        if (jsonValid) {
            _.extend(this, json)
            this.setPaths(json.paths)
        } else {
            throw new CustomError('Invalid json supplied', 'InvalidJSONError')
        }
    }

    setPaths(paths) {
        this.paths = {}
        if (Array.isArray(paths)) {
            for (let i = 0; i < paths.length; i++) {
                const p = new Path(paths[i])
                this.paths[p.pathId] = p
            }
        } else {
            for (const k in paths) {
                this.paths[k] = paths[k]
            }
        }
    }

    toDto() {
        const dtoDrawing = new Drawing(this)

        dtoDrawing.paths = []

        for (const k in this.paths) {
            dtoDrawing.paths.push(this.paths[k])
        }

        return dtoDrawing
    }

    getLayers() {
        const layers = []

        for (const k in this.paths) {
            const layer = this.paths[k].layer
            let found = false
            for (let l = 0; l < layers.length; l++) {
                const compLayer = layers[l]
                if (
                    compLayer.name === layer.name &&
                    compLayer.colour === layer.colour &&
                    compLayer.type === layer.type
                ) {
                    found = true
                    break
                }
            }
            if (!found) {
                layers.push(layer)
            }
        }

        this.setLayers(layers)

        return layers
    }

    setLayers(layers) {
        const applyLayers = {}

        layers.forEach((val) => {
            applyLayers[val.name] = val
        })

        this.layers = applyLayers
    }

    applyLayers(layers) {
        layers.forEach(function (val) {
            if (this.layers[val.name]) {
                this.layers[val.name] = val
            }
        }, this)

        const resultPaths = []
        // apply changes to all paths
        for (const k in this.paths) {
            const appliedLayer = this.layers[this.paths[k].layer.name]
            if (!appliedLayer.isIgnored) {
                this.paths[k].layer = appliedLayer
                resultPaths.push(this.paths[k])
            }
        }

        this.setPaths(resultPaths)

        return this.getLayers()
    }

    movePathsToLayer(selectedPathIds, layerType) {
        // accumulate all layers of all selected paths before applying
        const matchedLayers = {}
        for (let i = 0; i < selectedPathIds.length; i++) {
            const match = this.paths[selectedPathIds[i]].layer
            matchedLayers[match.name] = match
        }

        // apply the layer type to the matched layers
        const resultArray = []
        for (const l in matchedLayers) {
            matchedLayers[l].type = layerType
            resultArray.push(matchedLayers[l])
        }

        this.applyLayers(resultArray)
        this.getLayers()
    }

    toSvg(paper, viewwidth, viewheight) {
        // Setup viewbox
        const viewBox = this.getViewBox(viewwidth, viewheight, 1.0)
        const viewBoxStr = viewBox ? `${viewBox.minX} ${viewBox.minY} ${viewBox.width} ${viewBox.height}` : '0 0 0 0'
        paper.attr('viewBox', viewBoxStr)
        //Create open outer marker
        const c = paper.circle(10, 10, 6)
        c.addClass('openOuterMarker')
        const m = c.marker(0, 0, 20, 20, 10, 10)
        m.node.id = 'openOuterHoverCircle'
        // Create drawing group and change coordinate system
        const g = paper.g()
        g.addClass(Drawing.classname)
        // Add paths to drawing group
        this.drawPaths(g)
    }

    drawPaths(svgGroup) {
        //Draw most enclosed paths last so that they are rendered on top.
        let maxPathDepth = 0

        for (const k in this.paths) {
            if (this.paths[k].enclosedByIds.length > maxPathDepth) {
                maxPathDepth = this.paths[k].enclosedByIds.length
            }
        }

        for (let i = 0; i <= maxPathDepth; i++) {
            for (const j in this.paths) {
                const path = this.paths[j]
                if (path.enclosedByIds.length === i && path.isClosed) {
                    path.toSvg(svgGroup)
                }
            }
        }

        //Draw open outers last so that they are rendered on top.
        for (let i = 0; i <= maxPathDepth; i++) {
            for (const k in this.paths) {
                const path = this.paths[k]
                if (path.enclosedByIds.length === i && !path.isClosed) {
                    path.toSvg(svgGroup)
                }
            }
        }
    }

    getViewBox(viewwidth, viewheight, zoom) {
        if (!zoom) {
            zoom = 1.0
        }

        const scaledBl = new Point(this.minX / zoom, this.minY / zoom)
        const scaledTr = new Point((this.minX + this.width) / zoom, (this.minY + this.height) / zoom)
        const scaledwidth = scaledTr?.x - scaledBl?.x
        const scaledheight = scaledTr?.y - scaledBl?.y

        const viewBox = {
            minX: scaledBl?.x ?? 0,
            minY: scaledBl?.y ?? 0,
            width: scaledwidth ?? 0,
            height: scaledheight ?? 0,
        }

        // Apply margin
        viewBox.minX -= kSvgMargin * viewBox.width
        viewBox.minY -= kSvgMargin * viewBox.height
        viewBox.width *= 1 + 2 * kSvgMargin
        viewBox.height *= 1 + 2 * kSvgMargin

        return viewBox
    }

    getPath(pathId) {
        return this.paths[pathId]
    }

    deletePath(pathId) {
        delete this.paths[pathId]
        this.removeIdsFromAllPaths([pathId])
    }

    deleteOutsidePath(pathId) {
        const pathToKeep = this.paths[pathId]
        if (!pathToKeep) {
            return
        }

        const pathIds = []
        for (const k in this.paths) {
            const path = this.paths[k]

            if (path.pathId === pathId) {
                continue
            }
            if (!pathToKeep.encloses(path.pathId)) {
                this.deletePath(path.pathId)
                pathIds.push(path.pathId)
            }
        }

        this.removeIdsFromAllPaths(pathIds)
    }

    deleteInsidePath(pathId) {
        const enclosedIds = Array.from(this.paths[pathId].enclosesIds)
        const pathIds = []
        for (let i = 0; i < enclosedIds.length; i++) {
            this.deletePath(enclosedIds[i])
            pathIds.push(this.paths[pathId].enclosesIds[i])
        }
        this.removeIdsFromAllPaths(pathIds)
    }

    removeIdsFromAllPaths(pathIds) {
        for (const i in this.paths) {
            for (const idKey in pathIds) {
                let idx = this.paths[i].enclosesIds.indexOf(pathIds[idKey])
                if (idx > -1) {
                    this.paths[i].enclosesIds.splice(idx, 1)
                }
                idx = this.paths[i].enclosedByIds.indexOf(pathIds[idKey])
                if (idx > -1) {
                    this.paths[i].enclosedByIds.splice(idx, 1)
                }
            }
        }
    }

    getMultiparts() {
        const outers = []
        let noOfOuters = 0

        for (const k in this.paths) {
            //Get outers that aren't text
            if (this.paths[k].isOuter() && !this.paths[k].isText()) {
                outers.push(this.paths[k])
                noOfOuters += 1
            }
        }
        if (noOfOuters > 1) {
            return outers
        }

        return []
    }

    getMultilevels() {
        const multilevelPaths = []
        for (const k in this.paths) {
            const p = this.paths[k]
            for (let i = 0; i < p.enclosesIds.length; i++) {
                const innerPath = this.paths[p.enclosesIds[i]]
                if (innerPath && innerPath.enclosesIds.length > 0) {
                    for (let j = 0; j < innerPath.enclosesIds.length; j++) {
                        //Get path that isnt text nested in 2 other paths
                        const id = innerPath.enclosesIds[j]
                        const multilevelPath = this.getPath(id)
                        if (!multilevelPath.isText()) {
                            multilevelPaths.push(multilevelPath)
                        }
                    }
                }
            }
        }
        return multilevelPaths
    }

    getOpenOuters() {
        const openOuterPaths = []
        for (const k in this.paths) {
            //Get paths that are outers, not closed and not text
            if (this.paths[k].isOuter() && !this.paths[k].isClosed && !this.paths[k].isText()) {
                openOuterPaths.push(this.paths[k])
            }
        }
        return openOuterPaths
    }

    static drawingFromOuterPath(name, outer, internalPaths) {
        if (!name) {
            throw new CustomError('Cannot create Drawing. Need to supply a name.')
        }
        if (!outer) {
            throw new CustomError('Cannot create Drawing. Need to supply an outer path.')
        }

        const outerClone = _.extend({}, outer)
        outerClone.enclosedByIds = []

        const allPaths = [outerClone]
        allPaths.push.apply(allPaths, internalPaths)

        const newDwg = new Drawing(name, allPaths)
        newDwg.minX = outer.minX
        newDwg.minY = outer.minY
        newDwg.height = outer.height
        newDwg.width = outer.width

        return newDwg
    }
}

Drawing.classname = 'drawing'
