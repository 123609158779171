import { useMemo, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useDispatch, useSelector } from 'react-redux'
import { Delete, Edit } from '@mui/icons-material'
import { Checkbox, IconButton, TableCell, TableRow } from '@mui/material'
import PropTypes from 'prop-types'

import { useArchiveRateMutation } from '@/app/services/rates'
import { selectOrganisation, selectOrganisationId } from '@/app/slices/organisationSlice'
import { addToSelectedRates, removeFromSelectedRates, selectSelectedRates } from '@/app/slices/ratesSlice'
import { TbxTooltip } from '@/common/components'
import { useCurrencyFormatter, useNumberFormatter } from '@/common/hooks'
import { organisationLengthPrecision } from '@/common/utils'

import ArchiveRateDialog from './ArchiveRateDialog'

const classes = {
    cell: (isDeleted) => ({
        padding: '8px 10px',
        color: isDeleted ? 'text.disabled' : 'text.primary',
    }),
    row: {
        '&:hover': {
            background: (theme) => theme.palette.action.hover,
        },
    },
    icon: {
        fontSize: '16px',
    },
    button: {
        color: 'text.primary',
        margin: '0px 4px',
        '&:disabled': {
            color: 'text.disabled',
        },
    },
    fineCuttingBorderLeft: {
        borderLeft: '2px dashed #0078A4;',
    },
    fineCuttingBorderRight: {
        borderRight: '2px dashed #0078A4;',
    },
}

const RatesTableRow = ({ disabled, onEditClick, rate, rateTableBaseHourlyRate }) => {
    const { t } = useTranslation()

    const dispatch = useDispatch()

    const organisation = useSelector(selectOrganisation)
    const organisationId = useSelector(selectOrganisationId)

    const selectedRates = useSelector(selectSelectedRates)

    const [archiveRate] = useArchiveRateMutation()

    const [showDeleteDialog, setShowDeleteDialog] = useState(false)

    const isDisabledOrDeleted = useMemo(() => disabled || rate?.isDeleted, [disabled, rate])

    const { n } = useNumberFormatter()

    const { n: dimensionFormat } = useNumberFormatter({
        numberOfDecimalPlaces: organisation ? organisationLengthPrecision(organisation) : 2,
    })

    const { formatCurrency } = useCurrencyFormatter({
        currencyCode: organisation.currencyCode,
    })

    const handleEditRateClick = () => {
        typeof onEditClick === 'function' && onEditClick(rate)
    }

    const hideArchiveDialog = () => {
        setShowDeleteDialog(false)
    }

    const handleArchiveRateClick = () => {
        setShowDeleteDialog(true)
    }

    const confirmArchiveRate = async () => {
        if (rate) {
            await archiveRate({ organisationId, rateId: rate.rateId })
        }
        hideArchiveDialog()
    }

    const toggleRateSelection = (event) => {
        if (!rate) return
        const { checked } = event.target
        checked ? dispatch(addToSelectedRates(rate)) : dispatch(removeFromSelectedRates(rate))
    }

    const isSelected = useMemo(() => {
        if (!rate || !selectedRates || !selectedRates.length) return false
        return selectedRates.some((r) => r.rateId === rate.rateId)
    }, [rate, selectedRates])

    const fineCuttingLeftStyle = Object.assign({}, classes.cell, classes.fineCuttingBorderLeft)
    const fineCuttingRightStyle = Object.assign({}, classes.cell, classes.fineCuttingBorderRight)

    return (
        <TableRow>
            <TableCell style={{ width: '30px' }}>
                <Checkbox
                    checked={isSelected}
                    onChange={toggleRateSelection}
                />
            </TableCell>
            <TableCell
                align="right"
                style={{ width: '80px' }}
                sx={classes.cell(isDisabledOrDeleted)}
            >
                {dimensionFormat(rate?.thickness) ?? ''}
            </TableCell>
            <TableCell
                align="right"
                style={{ width: '80px' }}
                sx={classes.cell(isDisabledOrDeleted)}
            >
                {dimensionFormat(rate?.web) ?? ''}
            </TableCell>
            <TableCell
                align="right"
                style={{ width: '120px' }}
                sx={classes.cell(isDisabledOrDeleted)}
            >
                {n(rate?.cuttingFeedRate) ?? ''}
            </TableCell>
            <TableCell
                align="right"
                style={{ width: '100px' }}
                sx={classes.cell(isDisabledOrDeleted)}
            >
                {n(rate?.pierceTime) ?? ''}
            </TableCell>
            <TableCell
                align="right"
                style={{ width: '128px' }}
                sx={fineCuttingLeftStyle}
            >
                {dimensionFormat(rate?.fineSpeedThreshold) ?? ''}
            </TableCell>
            <TableCell
                align="right"
                style={{ width: '120px' }}
                sx={classes.cell(isDisabledOrDeleted)}
            >
                {n(rate?.fineCuttingRate) ?? ''}
            </TableCell>
            <TableCell
                align="right"
                style={{ width: '120px' }}
                sx={fineCuttingRightStyle}
            >
                {n(rate?.finePierceTime) ?? ''}
            </TableCell>
            <TableCell
                align="right"
                style={{ width: '120px' }}
                sx={classes.cell(isDisabledOrDeleted)}
            >
                {dimensionFormat(rate?.smallHolesLimit) ?? ''}
            </TableCell>
            <TableCell
                align="right"
                style={{ width: '120px' }}
                sx={classes.cell(isDisabledOrDeleted)}
            >
                {n(rate?.hourlyRateMultiplier) ?? ''}
            </TableCell>
            <TableCell
                align="right"
                style={{ width: '120px' }}
                sx={classes.cell(isDisabledOrDeleted)}
            >
                {formatCurrency(rateTableBaseHourlyRate * rate?.hourlyRateMultiplier) ?? ''}
            </TableCell>
            <TableCell
                align="right"
                sx={classes.cell(isDisabledOrDeleted)}
            >
                <IconButton
                    disabled={isDisabledOrDeleted}
                    size="small"
                    sx={classes.button}
                    onClick={handleEditRateClick}
                >
                    <Edit sx={classes.icon} />
                </IconButton>
                <TbxTooltip
                    title={t('Delete thickness')}
                    arrow
                >
                    <IconButton
                        disabled={disabled}
                        size="small"
                        sx={classes.button}
                        onClick={handleArchiveRateClick}
                    >
                        <Delete
                            color={isDisabledOrDeleted ? 'disabled' : undefined}
                            sx={classes.icon}
                        />
                    </IconButton>
                </TbxTooltip>
            </TableCell>
            {showDeleteDialog ? (
                <ArchiveRateDialog
                    name={' with thickness: ' + rate.thickness}
                    onCancelClose={hideArchiveDialog}
                    onConfirmClose={confirmArchiveRate}
                />
            ) : null}
        </TableRow>
    )
}

RatesTableRow.propTypes = {
    disabled: PropTypes.bool,
    rate: PropTypes.object,
    rateTableBaseHourlyRate: PropTypes.number,
    onEditClick: PropTypes.func,
}

export default RatesTableRow
