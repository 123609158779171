import { useDispatch, useSelector } from 'react-redux'
import { useNavigate } from 'react-router-dom'

import { useLazyGetMaterialsQuery } from '@/app/services/materials'
import { useGetOrCreateUserContextMutation } from '@/app/services/user'
import { selectCurrentUser, setAppIsLoading, setCurrentUser, setUserRole } from '@/app/slices/appSlice'
import { setOrganisation } from '@/app/slices/organisationSlice'
import { Paths } from '@/common/utils'

import { useBroadcastChannel } from './useBroadcastChannel'

export const useUserContextSwitcher = () => {
    const navigate = useNavigate()
    const dispatch = useDispatch()

    const currentUser = useSelector(selectCurrentUser)

    const [getOrCreateUserContext] = useGetOrCreateUserContextMutation()
    const [getMaterials] = useLazyGetMaterialsQuery()

    const { postMessage } = useBroadcastChannel('orgChangeChannel', (message) => {
        try {
            if (message === 'ORG_CHANGED') {
                navigate(Paths.DASHBOARD_PATHNAME)
                window.location.reload()
            }
        } catch (error) {
            console.error('Failed to process message:', error)
        }
    })

    const setSelectedOrganisation = async (newSelectedOrganisationId) => {
        dispatch(setAppIsLoading(true))

        try {
            const userContext = await getOrCreateUserContext({
                user: { ...currentUser, lastAccessedOrganisationId: newSelectedOrganisationId },
            }).unwrap()

            const appUser = userContext.user
            const selectedOrganisationId = userContext.selectedOrganisationId
            const organisationUser =
                appUser &&
                selectedOrganisationId &&
                Array.isArray(appUser.organisationUsers) &&
                appUser.organisationUsers.length &&
                appUser.organisationUsers.find((ou) => ou.organisationId === selectedOrganisationId)

            const selectedOrganisation =
                organisationUser && appUser.organisations.find((o) => o.organisationId === selectedOrganisationId)
            const role = organisationUser && organisationUser.role

            await getMaterials({ organisationId: selectedOrganisationId })

            dispatch(setUserRole(role.toLowerCase()))
            dispatch(setCurrentUser(appUser))

            dispatch(setOrganisation(selectedOrganisation))

            dispatch(setAppIsLoading(false))

            navigate(Paths.DASHBOARD_PATHNAME)

            postMessage('ORG_CHANGED')
            return selectedOrganisation
        } catch (e) {
            dispatch(setAppIsLoading(false))
            navigate(Paths.ERROR_PATHNAME)
        }
    }

    return {
        setSelectedOrganisation,
    }
}
