import { useCallback, useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useDispatch, useSelector } from 'react-redux'
import { ArrowDropDown } from '@mui/icons-material'
import { Input } from '@mui/material'
import { blueGrey } from '@mui/material/colors'
import Divider from '@mui/material/Divider'
import FormControl from '@mui/material/FormControl'
import InputLabel from '@mui/material/InputLabel'
import MenuItem from '@mui/material/MenuItem'
import Select from '@mui/material/Select'
import TextField from '@mui/material/TextField'
import { Box } from '@mui/system'
import { DatePicker } from '@mui/x-date-pickers'
import allCountries from 'country-region-data'
import dayjs from 'dayjs'
import { useSnackbar } from 'notistack'
import PropTypes from 'prop-types'
import { useDebouncedCallback } from 'use-debounce'

import { useUpdateQuoteMutation } from '@/app/services/quotes'
import { useGetTaxRatesQuery } from '@/app/services/taxRates'
import { selectOrganisation, selectOrganisationId } from '@/app/slices/organisationSlice'
import { setSelectedQuoteStatus } from '@/app/slices/quoteItemsSlice'
import TbxLocalizationProvider from '@/common/components/TbxLocalizationProvider/TbxLocalizationProvider'
import { fractionToPercentage } from '@/common/helpers/formatUtilities'
import { useToolBoxTreatments } from '@/common/hooks'
import { QUOTE_STATUS } from '@/common/utils/Constants/Constants'

const classes = {
    disabledInput: {
        '& .Mui-disabled': {
            textFillColor: '#34495E',
        },
        '& .MuiInput-input.Mui-disabled': {
            fontWeight: 'bold',
        },
    },
    divider: {
        borderStyle: 'dashed',
        borderColor: blueGrey[200],
    },
}

const QuoteDetailsOptions = ({ quote }) => {
    const { t } = useTranslation()
    const dispatch = useDispatch()

    const { enqueueSnackbar } = useSnackbar()

    const { showMultipleTaxRates } = useToolBoxTreatments()

    const organisation = useSelector(selectOrganisation)
    const organisationId = useSelector(selectOrganisationId)

    const [updateQuote] = useUpdateQuoteMutation()
    const { data: taxRates } = useGetTaxRatesQuery({ organisationId })

    const [quoteNumber, setQuoteNumber] = useState(quote?.name ?? '')
    const [quoteRFQ, setQuoteRFQ] = useState(quote?.rfqNumber ?? '')
    const [quoteExpiryDate, setQuoteExpiryDate] = useState(quote?.expiryDate ?? null)
    const [purchaseOrder, setPurchaseOrder] = useState(quote?.poNumber ?? null)
    const [invoiceDueDate, setInvoiceDueDate] = useState(quote?.invoiceDueDateUtc ?? null)

    const [taxRateOption, setTaxRateOption] = useState(quote?.taxRateId ?? '')

    const userTimeZone = dayjs.tz.guess()

    const isEditable = [QUOTE_STATUS.NotCalculated, QUOTE_STATUS.Calculated].includes(quote?.status)

    const isDraft = [QUOTE_STATUS.NotCalculated, QUOTE_STATUS.Calculated, QUOTE_STATUS.Draft].includes(quote?.status)

    const isIssued = [QUOTE_STATUS.Issued].includes(quote?.status)

    const isOrdered = [QUOTE_STATUS.Ordered].includes(quote?.status)

    const isInvoiced = [QUOTE_STATUS.Invoiced].includes(quote?.status)

    const isDisabled = [QUOTE_STATUS.Lost, QUOTE_STATUS.Cancelled, QUOTE_STATUS.Rejected, QUOTE_STATUS.Voided].includes(
        quote?.status
    )

    const countryName = useCallback(
        (countryCode) => {
            const country = allCountries.find((country) => country.countryShortCode === countryCode?.toUpperCase())
            return country?.countryName ?? null
        },
        [allCountries]
    )

    const stateName = useCallback(
        (countryCode, stateCode) => {
            const country = allCountries.find((country) => country.countryShortCode === countryCode?.toUpperCase())
            const state = country?.regions.find((region) => region.shortCode === stateCode)
            return state?.name ?? null
        },
        [allCountries]
    )

    const updateQuoteHandler = async (attribute, value) => {
        if (value === '' && !['poNumber'].includes(attribute)) return

        try {
            await updateQuote({
                organisationId: organisation.organisationId,
                quoteId: quote?.id,
                quote: {
                    ...quote,
                    ...(['taxRateId'].includes(attribute) &&
                        quote.status !== QUOTE_STATUS.NotCalculated && { status: QUOTE_STATUS.NotCalculated }),
                    [attribute]: value,
                },
            }).unwrap()
        } catch (error) {
            enqueueSnackbar(t('Failed to update the quote'), {
                variant: 'error',
            })
        }
    }

    const debouncedUpdateQuoteHandler = useDebouncedCallback(updateQuoteHandler, 1000)

    const handleQuoteNumberChange = (event) => {
        setQuoteNumber(event.target.value)
        debouncedUpdateQuoteHandler('name', event.target.value)
    }

    const handleQuoteNumberBlur = (event) => {
        if (event.target.value === '') {
            setQuoteNumber(quote?.name ?? '')
        }
    }

    const handleRFQChange = (event) => {
        setQuoteRFQ(event.target.value)
        debouncedUpdateQuoteHandler('rfqNumber', event.target.value)
    }

    const handleRFQBlur = (event) => {
        if (event.target.value === '') {
            setQuoteRFQ(quote?.rfqNumber ?? '')
        }
    }

    const handleQuoteExpiryDateChange = (date) => {
        setQuoteExpiryDate(date)
        updateQuoteHandler('expiryDate', date?.utc().format() || null)
    }

    const handlePurchaseOrderChange = (event) => {
        setPurchaseOrder(event.target.value)
        debouncedUpdateQuoteHandler('poNumber', event.target.value)
    }

    const handleInvoiceDueDateChange = (date) => {
        setInvoiceDueDate(date)
        updateQuoteHandler('invoiceDueDateUtc', date?.utc().format() || null)
    }

    const handleTaxRateOptionChange = (event) => {
        if (quote.status !== QUOTE_STATUS.NotCalculated) {
            dispatch(setSelectedQuoteStatus(QUOTE_STATUS.NotCalculated))
        }
        setTaxRateOption(event.target.value)
        updateQuoteHandler('taxRateId', event.target.value)
    }

    useEffect(() => {
        setTaxRateOption(quote?.taxRateId ?? '')
    }, [quote?.taxRateId])

    return (
        <TbxLocalizationProvider>
            <Box
                alignItems="flex-start"
                alignSelf="stretch"
                display="flex"
                gap={3}
                justifyContent="space-between"
                key={`${quote?.id}-details-options`}
            >
                <Box
                    alignItems="flex-start"
                    alignSelf="stretch"
                    display="flex"
                    flexDirection="column"
                    gap={2}
                    justifyContent="space-between"
                    width={140}
                >
                    <TextField
                        color="secondary"
                        disabled={!isDraft}
                        InputLabelProps={{
                            shrink: true,
                        }}
                        inputProps={{
                            'data-testid': 'quote-number-input',
                        }}
                        InputProps={{
                            disableUnderline: !isDraft,
                        }}
                        label={t('Quote #')}
                        sx={classes.disabledInput}
                        value={quoteNumber}
                        onBlur={handleQuoteNumberBlur}
                        onChange={handleQuoteNumberChange}
                    />
                    {isIssued || isOrdered || isInvoiced || isDisabled ? (
                        <DatePicker
                            disabled={!isDraft}
                            disableOpenPicker={!isDraft}
                            format="DD-MMM-YYYY"
                            label={t('Quote issue date')}
                            slotProps={{
                                textField: {
                                    sx: classes.disabledInput,
                                    color: 'secondary',
                                    inputProps: {
                                        'data-testid': 'quote-issue-date-input',
                                    },
                                    InputProps: {
                                        disableUnderline: !isDraft,
                                    },
                                    InputLabelProps: {
                                        shrink: true,
                                    },
                                },
                            }}
                            timezone={userTimeZone}
                            value={dayjs(quote?.issuedDateUtc)}
                            disablePast
                        />
                    ) : null}
                </Box>
                <Box
                    alignItems="flex-start"
                    alignSelf="stretch"
                    display="flex"
                    flexDirection="column"
                    gap={2}
                    justifyContent="space-between"
                    width={140}
                >
                    <TextField
                        color="secondary"
                        disabled={!isDraft}
                        InputLabelProps={{
                            shrink: true,
                        }}
                        inputProps={{
                            'data-testid': 'quote-rfq-input',
                        }}
                        InputProps={{
                            disableUnderline: !isDraft,
                        }}
                        label={t('RFQ #')}
                        sx={classes.disabledInput}
                        value={quoteRFQ}
                        onBlur={handleRFQBlur}
                        onChange={handleRFQChange}
                    />
                    {isDraft || isIssued ? (
                        <DatePicker
                            closeOnSelect={false}
                            disabled={!isDraft}
                            disableOpenPicker={!isDraft}
                            format="DD-MMM-YYYY"
                            label={t('Quote expiry date')}
                            slotProps={{
                                textField: {
                                    sx: classes.disabledInput,
                                    color: 'secondary',
                                    placeholder: t('Select a date'),
                                    inputProps: {
                                        'data-testid': 'quote-expiry-date-input',
                                    },
                                    InputProps: {
                                        disableUnderline: !isDraft,
                                    },
                                    InputLabelProps: {
                                        shrink: true,
                                    },
                                },
                                field: {
                                    readOnly: true,
                                },
                                actionBar: {
                                    actions: ['clear', 'cancel', 'accept'],
                                    disableSpacing: true,
                                },
                            }}
                            timezone={userTimeZone}
                            value={quoteExpiryDate ? dayjs(quoteExpiryDate) : null}
                            disablePast
                            onAccept={handleQuoteExpiryDateChange}
                        />
                    ) : null}
                </Box>
            </Box>

            {isIssued || isOrdered || isInvoiced || isDisabled ? (
                <Divider
                    orientation="vertical"
                    sx={classes.divider}
                    flexItem
                />
            ) : null}

            {isIssued || isOrdered || isInvoiced || isDisabled ? (
                <Box
                    alignItems="flex-start"
                    alignSelf="stretch"
                    display="flex"
                    gap={3}
                    justifyContent="space-between"
                >
                    <Box
                        alignItems="flex-start"
                        alignSelf="stretch"
                        display="flex"
                        flexDirection="column"
                        gap={2}
                        justifyContent="space-between"
                        width={140}
                    >
                        <TextField
                            color="secondary"
                            disabled={isDisabled}
                            InputLabelProps={{
                                shrink: true,
                            }}
                            inputProps={{
                                'data-testid': 'quote-purchase-order-number-input',
                            }}
                            InputProps={{
                                disableUnderline: isDisabled,
                            }}
                            label={t('Purchase order #')}
                            sx={classes.disabledInput}
                            value={purchaseOrder}
                            onChange={handlePurchaseOrderChange}
                        />
                        {isOrdered || isInvoiced || isDisabled ? (
                            <TextField
                                color="secondary"
                                InputLabelProps={{
                                    shrink: true,
                                }}
                                inputProps={{
                                    'data-testid': 'quote-work-order-number-input',
                                }}
                                InputProps={{
                                    disableUnderline: true,
                                }}
                                label={t('Work order #')}
                                sx={classes.disabledInput}
                                value={quote?.workOrderNumberText ?? ''}
                                disabled
                            />
                        ) : null}
                    </Box>
                </Box>
            ) : null}

            {isOrdered || isInvoiced || isDisabled ? (
                <Divider
                    orientation="vertical"
                    sx={classes.divider}
                    flexItem
                />
            ) : null}

            {isOrdered || isInvoiced || isDisabled ? (
                <Box
                    alignItems="flex-start"
                    alignSelf="stretch"
                    display="flex"
                    gap={3}
                    justifyContent="space-between"
                >
                    {isInvoiced || isDisabled ? (
                        <Box
                            alignItems="flex-start"
                            alignSelf="flex-end"
                            display="flex"
                            flexDirection="column"
                            gap={2}
                            justifyContent="space-between"
                            width={140}
                        >
                            <TextField
                                color="secondary"
                                disabled={isInvoiced || isDisabled}
                                InputLabelProps={{
                                    shrink: true,
                                }}
                                inputProps={{
                                    'data-testid': 'quote-invoice-number-input',
                                }}
                                InputProps={{
                                    disableUnderline: isInvoiced || isDisabled,
                                }}
                                label={t('Invoice #')}
                                sx={classes.disabledInput}
                                value={quote?.invoiceNumberText ?? null}
                            />
                            <DatePicker
                                disabled={isInvoiced || isDisabled}
                                disableOpenPicker={isInvoiced || isDisabled}
                                format="DD-MMM-YYYY"
                                label={t('Invoice issue date')}
                                slotProps={{
                                    textField: {
                                        sx: classes.disabledInput,
                                        color: 'secondary',
                                        inputProps: {
                                            'data-testid': 'quote-invoice-issue-date-input',
                                        },
                                        InputProps: {
                                            disableUnderline: isInvoiced || isDisabled,
                                        },
                                        InputLabelProps: {
                                            shrink: true,
                                        },
                                    },
                                }}
                                timezone={userTimeZone}
                                value={quote?.invoiceIssueDateUtc ? dayjs(quote?.invoiceIssueDateUtc) : null}
                            />
                        </Box>
                    ) : null}
                    <Box
                        alignItems="flex-start"
                        alignSelf="flex-end"
                        display="flex"
                        flexDirection="column"
                        gap={2}
                        justifyContent="space-between"
                        width={140}
                    >
                        <DatePicker
                            closeOnSelect={false}
                            disabled={isDisabled}
                            disableOpenPicker={isDisabled}
                            format="DD-MMM-YYYY"
                            label={t('Invoice due date')}
                            slotProps={{
                                textField: {
                                    sx: classes.disabledInput,
                                    color: 'secondary',
                                    placeholder: t('Select a date'),
                                    inputProps: {
                                        'data-testid': 'quote-invoice-due-date-input',
                                    },
                                    InputProps: {
                                        disableUnderline: isDisabled,
                                    },
                                    InputLabelProps: {
                                        shrink: true,
                                    },
                                },
                                field: {
                                    readOnly: true,
                                },
                                actionBar: {
                                    actions: ['clear', 'cancel', 'accept'],
                                    disableSpacing: true,
                                },
                            }}
                            timezone={userTimeZone}
                            value={invoiceDueDate ? dayjs(invoiceDueDate) : null}
                            onAccept={handleInvoiceDueDateChange}
                        />
                    </Box>
                </Box>
            ) : null}

            {showMultipleTaxRates && isEditable ? (
                <>
                    <Divider
                        orientation="vertical"
                        sx={classes.divider}
                        flexItem
                    />
                    <Box
                        alignItems="flex-start"
                        alignSelf="stretch"
                        display="flex"
                        flexDirection="column"
                        gap={2}
                        justifyContent="space-between"
                    >
                        <FormControl fullWidth>
                            <InputLabel
                                color="secondary"
                                id="select-tax-rate-option-label"
                                shrink={true}
                            >
                                {t('Tax rate')}
                            </InputLabel>
                            <Select
                                color="secondary"
                                disabled={!isEditable || isDisabled}
                                disableUnderline={!isEditable || isDisabled}
                                IconComponent={!isEditable || isDisabled ? null : ArrowDropDown}
                                id="select-tax-rate-option"
                                input={<Input sx={classes.disabledInput} />}
                                inputProps={{
                                    'data-testid': 'quote-tax-rate-option-input',
                                }}
                                label={t('Tax rate')}
                                labelId="select-tax-rate-option-label"
                                value={taxRateOption ?? ''}
                                variant="standard"
                                displayEmpty
                                onChange={handleTaxRateOptionChange}
                            >
                                <MenuItem
                                    value=""
                                    disabled
                                >
                                    <em>{t('Select a tax rate')}</em>
                                </MenuItem>

                                {taxRates?.map((option) => (
                                    <MenuItem
                                        key={option.id}
                                        value={option.id}
                                    >
                                        {option.displayName} -{' '}
                                        {option.state ? `${stateName(option.country, option.state)} - ` : null}{' '}
                                        {countryName(option.country)} ({fractionToPercentage(option.percentage)}%)
                                    </MenuItem>
                                ))}
                            </Select>
                        </FormControl>
                    </Box>
                </>
            ) : null}
        </TbxLocalizationProvider>
    )
}

QuoteDetailsOptions.propTypes = {
    quote: PropTypes.object,
}

export default QuoteDetailsOptions
