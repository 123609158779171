import { useEffect, useState } from 'react'
import { Box } from '@mui/material'
import MuiTab from '@mui/material/Tab'
import MuiTabs from '@mui/material/Tabs'
import PropTypes from 'prop-types'

const classes = {
    root: ({ fullHeight, fullWidth, orientation }) => ({
        flexGrow: 1,
        backgroundColor: 'background.default',
        display: 'flex',
        flexDirection: orientation === 'horizontal' ? 'column' : 'row',
        height: fullHeight ? '100%' : 'auto',
        width: fullWidth ? '100%' : 'auto',
    }),
    tabs: ({ orientation, tabHeight, tabWidth }) => ({
        backgroundColor: 'background.default',
        minHeight: orientation === 'horizontal' ? `${tabHeight}px` : 'auto',
        minWidth: orientation !== 'horizontal' ? `${tabWidth}px` : 'auto',
    }),
    tab: ({ tabHeight, tabWidth }) => ({
        minWidth: `${tabWidth}px`,
        minHeight: `${tabHeight}px`,
        '&.Mui-selected': {
            color: 'text.primary',
        },
    }),
    indicator: ({ indicatorPosition, orientation }) => ({
        backgroundColor: 'primary.main',
        width: '0.4rem',
        position: 'absolute',
        left:
            indicatorPosition === 'left' || (indicatorPosition === 'default' && orientation === 'vertical')
                ? 0
                : 'auto',
        bottom:
            indicatorPosition === 'bottom' || (indicatorPosition === 'default' && orientation === 'horizontal')
                ? 0
                : 'auto',
        top: indicatorPosition === 'top' ? 0 : 'auto',
        right: indicatorPosition === 'right' ? 0 : 'auto',
    }),
}

const TabPanel = ({ children, hidden, index, value }) => {
    return value === index && !hidden ? children : null
}

TabPanel.propTypes = {
    hidden: PropTypes.bool,
    index: PropTypes.number,
    value: PropTypes.number,
}

const Tabs = ({
    fullHeight = false,
    fullWidth = false,
    indicatorPosition = 'default',
    onPanelChange,
    orientation = 'horizontal',
    panels = [],
    selectedPanelIndex,
    tabHeight,
    tabWidth,
}) => {
    const [value, setValue] = useState(0)

    useEffect(() => {
        window.dispatchEvent(new CustomEvent('resize'))
    }, [])

    useEffect(() => {
        setValue(selectedPanelIndex ?? 0)
    }, [selectedPanelIndex])

    const handleChange = (_, newValue) => {
        setValue(newValue)

        if (typeof onPanelChange === 'function') {
            onPanelChange(newValue)
        }
    }

    return (
        <Box sx={classes.root({ orientation, fullHeight, fullWidth })}>
            <MuiTabs
                orientation={orientation}
                sx={classes.tabs({ orientation, tabHeight, tabWidth })}
                TabIndicatorProps={{ sx: classes.indicator({ orientation, indicatorPosition }) }}
                value={Math.min(value, panels.length - 1)}
                variant="fullWidth"
                onChange={handleChange}
            >
                {panels.map((panel, index) => {
                    return (
                        <MuiTab
                            data-testid={`${panel.label.toLowerCase()}-tab`}
                            disabled={panel.hidden}
                            icon={panel.icon}
                            key={index}
                            label={panel.label}
                            sx={classes.tab({ tabHeight, tabWidth })}
                        />
                    )
                })}
            </MuiTabs>
            {panels.map((panel, index) => {
                return (
                    <TabPanel
                        data-testid={`${panel.label.toLowerCase()}-panel`}
                        hidden={panel.hidden}
                        index={index}
                        key={index}
                        value={Math.min(value, panels.length - 1)}
                    >
                        {panel.content}
                    </TabPanel>
                )
            })}
        </Box>
    )
}

Tabs.propTypes = {
    fullHeight: PropTypes.bool,
    fullWidth: PropTypes.bool,
    indicatorPosition: PropTypes.oneOf(['default', 'top', 'left', 'bottom', 'right']),
    orientation: PropTypes.oneOf(['horizontal', 'vertical']),
    panels: PropTypes.arrayOf(PropTypes.object),
    selectedPanelIndex: PropTypes.number,
    tabHeight: PropTypes.number,
    tabWidth: PropTypes.number,
    onPanelChange: PropTypes.func,
}

export default Tabs
