import { useTranslation } from 'react-i18next'
import { useMsal } from '@azure/msal-react'
import { Box, Card, CardContent, Link, Typography } from '@mui/material'

import { AppLogo } from '@/common/components'

import { loginRequest } from '../../../authConfig'

const classes = {
    cardContainer: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        flexDirection: 'column',
        height: '100vh',
    },
    root: {
        borderRadius: 4,
        width: 860,
        height: 400,
        display: 'flex',
        flexDirection: 'column',
    },
    logoImgContainer: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        flexDirection: 'column',
        width: '100%',
        marginBottom: 2,
    },
    logoImg: {
        height: 128,
    },
    contentContainer: {
        flexGrow: 1,
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        justifyContent: 'center',
    },
    link: {
        cursor: 'pointer',
    },
    title: {
        marginBottom: 2,
    },
}

const SignOut = () => {
    const { t } = useTranslation()
    const { instance: msalInstance } = useMsal()
    const handleSignInClicked = () => {
        msalInstance.loginRedirect({
            ...loginRequest,
            redirectStartPage: '/',
        })
    }

    return (
        <Box sx={classes.cardContainer}>
            <Card sx={classes.root}>
                <CardContent sx={classes.contentContainer}>
                    <Box sx={classes.logoImgContainer}>
                        <AppLogo className={classes.logoImg} />
                    </Box>
                    <Typography
                        align="center"
                        sx={classes.title}
                        variant="h3"
                    >
                        {t('Successfully signed out')}
                    </Typography>
                    <Typography
                        align="center"
                        variant="h5"
                    >
                        {t('To sign back in') + ' '}
                        <Link
                            color="primary"
                            sx={classes.link}
                            onClick={handleSignInClicked}
                        >
                            {t('click here')}
                        </Link>
                    </Typography>
                </CardContent>
            </Card>
        </Box>
    )
}

export default SignOut
