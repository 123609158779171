import { useRef, useState } from 'react'
import { ArrowDropDown } from '@mui/icons-material'
import Button from '@mui/material/Button'
import ButtonGroup from '@mui/material/ButtonGroup'
import ClickAwayListener from '@mui/material/ClickAwayListener'
import Grow from '@mui/material/Grow'
import MenuItem from '@mui/material/MenuItem'
import MenuList from '@mui/material/MenuList'
import Paper from '@mui/material/Paper'
import Popper from '@mui/material/Popper'
import PropTypes from 'prop-types'

const classes = {
    popper: {
        zIndex: (theme) => theme.zIndex.appBar + 1,
    },
}

const SelectButton = ({
    disabled = false,
    hideSelectedOptionFromMenu = false,
    options = [],
    switchButtonToSelected = true,
}) => {
    const anchorRef = useRef(null)
    const [open, setOpen] = useState(false)
    const [selectedIndex, setSelectedIndex] = useState(0)

    const handleClick = (event) => {
        const selectedOption = options[selectedIndex]

        if (selectedOption && typeof selectedOption.onClick === 'function') {
            selectedOption.onClick(event)
        }
    }

    const handleMenuItemClick = (event, index) => {
        if (switchButtonToSelected) {
            setSelectedIndex(index)
        }

        const selectedOption = options[index]
        if (typeof selectedOption.onClick === 'function') {
            selectedOption.onClick(event)
        }

        setOpen(false)
    }

    const handleToggle = () => {
        setOpen((prevOpen) => !prevOpen)
    }

    const handleClose = (event) => {
        if (anchorRef.current && anchorRef.current.contains(event.target)) {
            return
        }

        setOpen(false)
    }

    return (
        <>
            <ButtonGroup
                aria-label="split button"
                color="primary"
                variant="contained"
            >
                <Button
                    disabled={disabled}
                    onClick={handleClick}
                >
                    {options[selectedIndex] ? options[selectedIndex].labelText : null}
                </Button>
                <Button
                    aria-controls={open ? 'split-button-menu' : undefined}
                    aria-expanded={open ? 'true' : undefined}
                    aria-haspopup="menu"
                    aria-label="select option"
                    color="primary"
                    disabled={disabled}
                    ref={anchorRef}
                    size="small"
                    onClick={handleToggle}
                >
                    <ArrowDropDown />
                </Button>
            </ButtonGroup>
            <Popper
                anchorEl={anchorRef.current}
                open={open}
                role={undefined}
                sx={classes.popper}
                disablePortal
                transition
            >
                {({ TransitionProps, placement }) => (
                    <Grow
                        {...TransitionProps}
                        style={{
                            transformOrigin: placement === 'bottom' ? 'right top' : 'right bottom',
                        }}
                    >
                        <Paper>
                            <ClickAwayListener onClickAway={handleClose}>
                                <MenuList id="split-button-menu">
                                    {options.map(
                                        (option, index) =>
                                            !(index === selectedIndex && hideSelectedOptionFromMenu) && (
                                                <MenuItem
                                                    disabled={option.disabled}
                                                    key={index}
                                                    selected={index === selectedIndex}
                                                    onClick={(event) => handleMenuItemClick(event, index)}
                                                >
                                                    {option.labelText}
                                                </MenuItem>
                                            )
                                    )}
                                </MenuList>
                            </ClickAwayListener>
                        </Paper>
                    </Grow>
                )}
            </Popper>
        </>
    )
}

SelectButton.propTypes = {
    disabled: PropTypes.bool,
    hideSelectedOptionFromMenu: PropTypes.bool,
    options: PropTypes.arrayOf(
        PropTypes.shape({
            labelText: PropTypes.string.isRequired,
            disabled: PropTypes.bool,
            onClick: PropTypes.func,
        })
    ),
    switchButtonToSelected: PropTypes.bool,
}

export default SelectButton
