import { Box, Divider, Typography } from '@mui/material'
import PropTypes from 'prop-types'

import { AppBar, AppLogo } from '@/common/components'

const classes = {
    appBar: {
        backgroundColor: 'white',
        color: 'white',
        displayPrint: 'none',
    },
    appLogo: {
        height: 32,
        px: 1,
        py: 1,
        cursor: 'pointer',
        boxSizing: 'content-box',
    },
    toolbarItems: {
        display: 'flex',
        alignItems: 'center',
        flex: 1,
        justifyContent: 'flex-end',
    },
    menuDivider: {
        backgroundColor: 'divider',
        margin: '10px 16px',
    },
    appBarItems: {
        display: 'flex',
        width: '100%',
        justifyContent: 'space-between',
        alignItems: 'center',
        flex: 1,
    },
    appBarTitle: {
        fontWeight: 500,
        fontSize: '18px',
        lineHeight: '21px',
        textTransform: 'uppercase',
        color: 'text.primary',
    },
}

const PublicAppBar = ({ organisationLogo, organisationName }) => {
    return (
        <AppBar
            className={classes.appBar}
            position="sticky"
        >
            <AppLogo
                className={classes.appLogo}
                logoUrl={organisationLogo}
            />

            <Divider
                orientation="vertical"
                sx={classes.menuDivider}
                flexItem
            />

            <Box sx={classes.appBarItems}>
                <Typography sx={classes.appBarTitle}>{organisationName}</Typography>
            </Box>
        </AppBar>
    )
}

PublicAppBar.propTypes = {
    organisationLogo: PropTypes.string,
    organisationName: PropTypes.string,
}

export default PublicAppBar
