import { initReactI18next } from 'react-i18next'
import i18n from 'i18next'
import LanguageDetector from 'i18next-browser-languagedetector'
import Backend from 'i18next-locize-backend'

i18n
    // i18next-locize-backend
    // loads translations from your project, saves new keys to it (saveMissing: true)
    // https://github.com/locize/i18next-locize-backend
    .use(Backend)
    // detect user language
    // learn more: https://github.com/i18next/i18next-browser-languageDetector
    .use(LanguageDetector)
    // pass the i18n instance to react-i18next.
    .use(initReactI18next)
    // init i18next
    // for all options read: https://www.i18next.com/overview/configuration-options
    .init({
        fallbackLng: 'en-GB',
        saveMissing: true,
        interpolation: {
            escapeValue: false, // not needed for react as it escapes by default
            prefix: '{{',
            suffix: '}}',
        },
        keySeparator: false,
        nsSeparator: '::',
        backend: {
            // the id of your locize project
            projectId: import.meta.env.VITE_LOCIZE_PROJECT_ID,
            // add an api key if you want to send missing keys
            apiKey: import.meta.env.VITE_LOCIZE_API_KEY,
            private: true,
            referenceLng: import.meta.env.VITE_LOCIZE_REFERENCE_LNG,
        },
    })

export default i18n
