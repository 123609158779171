import { memo } from 'react'
import { useTranslation } from 'react-i18next'
import { useDispatch, useSelector } from 'react-redux'
import { useParams } from 'react-router-dom'
import { Box, Button, Typography } from '@mui/material'
import { useSnackbar } from 'notistack'
import PropTypes from 'prop-types'

import { useGetMiscItemsQuery } from '@/app/services/miscItems'
import { selectOrganisationId } from '@/app/slices/organisationSlice'
import ThumbnailUri from '@/common/components/ThumbnailUri/ThumbnailUri'
import { MiscItemIcon } from '@/common/icons'

const classes = {
    ItemDetails_Image: {
        width: '220px',
        gap: '8px',
        fontSize: '64px',
    },

    ItemDetails_Options_Size: {
        gap: '12px',
    },
    ItemDetails_TextButton: {
        textTransform: 'none',
        fontWeight: 'normal',
        color: 'text.primary',
        fontSize: '14px',
        textDecoration: 'underline',
        textDecorationThickness: '1px',
    },

    iconColor: { color: 'text.secondary' },
}

const MiscItemDrawing = ({ miscItemId }) => {
    const { t } = useTranslation()

    const { quoteId } = useParams()

    const organisationId = useSelector((state) => selectOrganisationId(state))

    const { miscItem } = useGetMiscItemsQuery(
        {
            organisationId,
            quoteId,
        },
        {
            selectFromResult: ({ data }) => ({
                miscItem: data?.find((item) => item.id === miscItemId),
            }),
        }
    )

    return (
        <Box
            alignItems="center"
            display="flex"
            flex="0 0 auto"
            flexDirection="column"
            justifyContent="space-around"
            sx={classes.ItemDetails_Image}
        >
            {/* <ThumbnailUri
                    wrapperHeight={64}
                    wrapperWidth={64}
                /> */}

            <MiscItemIcon
                fontSize="inherit"
                titleAccess={t('Miscellaneous item icon')}
            />

            {/* <Button
                    data-testid="nqp-quote-item-open-drawing-doctor"
                    sx={classes.ItemDetails_TextButton}
                    variant="text"
                >
                    {t('Change icon')}
                </Button> */}
        </Box>
    )
}

MiscItemDrawing.propTypes = {
    miscItemId: PropTypes.string,
}

export default memo(MiscItemDrawing)
