import { createSlice } from '@reduxjs/toolkit'

const initialState = {
    selectedRate: null,
    selectedRates: [],
}

export const ratesSlice = createSlice({
    name: 'rateSlice',
    initialState,
    reducers: {
        setSelectedRate: (state, action) => {
            state.selectedRate = action.payload
        },

        setSelectedRates: (state, action) => {
            state.selectedRates = action.payload
        },

        addToSelectedRates: (state, action) => {
            state.selectedRates.push(action.payload)
        },

        removeFromSelectedRates: (state, action) => {
            state.selectedRates = state.selectedRates.filter((rate) => rate.rateId !== action.payload.rateId)
        },

        clearSelectedRates: (state) => {
            state.selectedRates = []
        },
    },
})

export const selectSelectedRate = (state) => state.ratesSlice.selectedRate
export const selectSelectedRates = (state) => state.ratesSlice.selectedRates

export const { addToSelectedRates, clearSelectedRates, removeFromSelectedRates, setSelectedRate, setSelectedRates } =
    ratesSlice.actions

export default ratesSlice.reducer
