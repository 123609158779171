import { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { Close, Edit, Save } from '@mui/icons-material'
import { Box, Dialog, DialogContent, DialogTitle, IconButton, TextField, Typography } from '@mui/material'
import PropTypes from 'prop-types'

import SaveNotesDialog from './SaveNotesDialog'

const classes = {
    icon: {
        fontSize: '16px',
    },
}

const ItemNotesModal = ({ handleUpdate, miscItem, onClose, open, quoteItem }) => {
    const { t } = useTranslation()

    const [isEditingVendorNotes, setIsEditingVendorNotes] = useState(false)
    const [isEditingPrivateNotes, setIsEditingPrivateNotes] = useState(false)

    const [itemVendorNotes, setItemVendorNotes] = useState(quoteItem?.vendorNotes || miscItem?.vendorNotes)
    const [itemPrivateNotes, setItemPrivateNotes] = useState(quoteItem?.privateNotes || miscItem?.privateNotes)
    const [showConfirmationDialog, setShowConfirmationDialog] = useState(false)

    const name = quoteItem?.name || miscItem?.name

    const toggleEditVendorNotes = () => {
        const baseObj = quoteItem || miscItem

        if (isEditingVendorNotes) {
            handleUpdate({ ...baseObj, vendorNotes: itemVendorNotes })
        }
        setIsEditingVendorNotes((v) => !v)
    }
    const toggleEditPrivateNotes = () => {
        const baseObj = quoteItem || miscItem

        if (isEditingPrivateNotes) {
            handleUpdate({ ...baseObj, privateNotes: itemPrivateNotes })
        }
        setIsEditingPrivateNotes((v) => !v)
    }

    const handleVendorNotesChange = (event) => {
        const { value } = event.target
        if (value != itemVendorNotes) {
            setItemVendorNotes(value)
        }
    }

    const handlePrivateNotesChange = (event) => {
        const { value } = event.target
        if (value != itemPrivateNotes) {
            setItemPrivateNotes(value)
        }
    }

    const handleClose = (_, reason) => {
        if (reason !== 'backdropClick') {
            if (isEditingPrivateNotes || isEditingVendorNotes) {
                setShowConfirmationDialog(true)
            } else {
                onClose()
            }
        }
    }
    const onSaveNotesDialogCancel = () => {
        setShowConfirmationDialog(false)
        onClose()
    }

    const onSaveNotesDialogConfirm = () => {
        const baseObj = quoteItem || miscItem
        setShowConfirmationDialog(false)
        handleUpdate({ ...baseObj, privateNotes: itemPrivateNotes, vendorNotes: itemVendorNotes })
        onClose()
    }

    useEffect(() => {
        setItemVendorNotes(quoteItem?.vendorNotes)
        setItemPrivateNotes(quoteItem?.privateNotes)

        return () => {
            setItemVendorNotes('')
            setItemPrivateNotes('')
        }
    }, [quoteItem])

    useEffect(() => {
        setItemVendorNotes(miscItem?.vendorNotes)
        setItemPrivateNotes(miscItem?.privateNotes)

        return () => {
            setItemVendorNotes('')
            setItemPrivateNotes('')
        }
    }, [miscItem])

    return (
        <Dialog
            open={open}
            PaperProps={{ sx: { width: 400 } }}
            disableEscapeKeyDown
            onClose={handleClose}
        >
            <DialogTitle
                alignItems="center"
                data-testid="quote-item-notes-modal"
                display="flex"
                justifyContent="space-between"
            >
                <Typography
                    textTransform="uppercase"
                    variant="strong1"
                >
                    {t('NOTES FOR')} {name}
                </Typography>
                <IconButton
                    color="secondary"
                    size="small"
                    onClick={handleClose}
                >
                    <Close />
                </IconButton>
            </DialogTitle>
            <DialogContent>
                <Box
                    display="flex"
                    flexDirection="column"
                    gap={3}
                >
                    {quoteItem ? <Box>
                            <Typography
                                data-testid="customer-notes-label"
                                variant="strong2"
                            >
                                {t('Customer notes')}
                            </Typography>
                            <TextField
                                color="secondary"
                                data-testid="customer-notes"
                                helperText={t('These notes are provided by your customers using the Web Store.')}
                                InputProps={{
                                    readOnly: true,
                                    style: { fontSize: 14 },
                                }}
                                value={quoteItem?.customerNotes}
                                fullWidth
                                multiline
                            />
                        </Box> : null}
                    <Box>
                        <Box
                            alignItems="center"
                            display="flex"
                            flexDirection="row"
                            justifyContent="space-between"
                        >
                            <Typography
                                data-testid="vendor-notes-label"
                                variant="strong2"
                            >
                                {t('Vendor notes')}
                            </Typography>
                            <IconButton
                                color="secondary"
                                size="small"
                                onClick={toggleEditVendorNotes}
                            >
                                {isEditingVendorNotes ? <Save sx={classes.icon} /> : <Edit sx={classes.icon} />}
                            </IconButton>
                        </Box>
                        <TextField
                            color="secondary"
                            data-testid="vendor-notes"
                            helperText={t(
                                'These notes will be visible on the Quote, Order Confirmation, Work Order and Delivery Docket.'
                            )}
                            InputProps={{
                                readOnly: !isEditingVendorNotes,
                                style: { fontSize: 14 },
                            }}
                            value={itemVendorNotes}
                            fullWidth
                            multiline
                            onChange={handleVendorNotesChange}
                        />
                    </Box>
                    <Box
                        display="flex"
                        flexDirection="column"
                        gap={1}
                    >
                        <Box>
                            <Box
                                alignItems="center"
                                display="flex"
                                flexDirection="row"
                                justifyContent="space-between"
                            >
                                <Typography
                                    data-testid="private-notes-label"
                                    variant="strong2"
                                >
                                    {t('Private notes')}
                                </Typography>
                                <IconButton
                                    color="secondary"
                                    size="small"
                                    onClick={toggleEditPrivateNotes}
                                >
                                    {isEditingPrivateNotes ? <Save sx={classes.icon} /> : <Edit sx={classes.icon} />}
                                </IconButton>
                            </Box>
                        </Box>
                        <TextField
                            color="secondary"
                            data-testid="private-notes"
                            helperText={t('These notes will be visible on the Work Order only.')}
                            InputProps={{
                                readOnly: !isEditingPrivateNotes,
                                style: { fontSize: 14 },
                            }}
                            value={itemPrivateNotes}
                            fullWidth
                            multiline
                            onChange={handlePrivateNotesChange}
                        />
                    </Box>
                </Box>
            </DialogContent>
            <SaveNotesDialog
                open={showConfirmationDialog}
                onCancel={onSaveNotesDialogCancel}
                onConfirm={onSaveNotesDialogConfirm}
            />
        </Dialog>
    )
}

ItemNotesModal.propTypes = {
    handleUpdate: PropTypes.func,
    miscItem: PropTypes.object,
    open: PropTypes.bool,
    quoteItem: PropTypes.object,
    onClose: PropTypes.func,
}

export default ItemNotesModal
