import { memo, useMemo, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { NumericFormat } from 'react-number-format'
import { useSelector } from 'react-redux'
import { useParams } from 'react-router-dom'
import { Receipt } from '@mui/icons-material'
import { Box, Button, Divider, TextField, Typography } from '@mui/material'
import { useSnackbar } from 'notistack'
import PropTypes from 'prop-types'
import { useDebouncedCallback } from 'use-debounce'

import { useGetQuoteItemsQuery, useUpdateQuoteItemMutation } from '@/app/services/quoteItems'
import { useCalculateQuoteMutation, useGetQuoteQuery } from '@/app/services/quotes'
import { useGetTaxRatesQuery } from '@/app/services/taxRates'
import { selectLocale } from '@/app/slices/appSlice'
import { selectCurrencyCode, selectCurrencySymbol, selectOrganisationId } from '@/app/slices/organisationSlice'
import { selectedQuoteStatus } from '@/app/slices/quoteItemsSlice'
import TbxTooltip from '@/common/components/TbxTooltip/TbxTooltip'
import { fractionToPercentage, getCurrencyFormat, getFormattedPrice, QUOTE_STATUS } from '@/common/utils'
import PriceDetailsDialog from '@/features/quotes/components/PriceDetailsDialog/PriceDetailsDialog'
import { formatCurrency } from '@/features/web-store/helpers/utilities'

const classes = {
    pricesSection: {
        width: '250px',
        gap: '12px',
    },
    pricePercentage: {
        fontSize: '14px',
        color: 'text.secondary',
    },
    bold: {
        fontWeight: 'bold',
    },
    itemColumn: {
        display: 'flex',
        flex: 1,
    },
    alignRight: {
        justifyContent: 'flex-end',
    },
    inputColumn: {
        pl: 2,
    },
    fixedPriceInput: {
        '& .MuiOutlinedInput-root.Mui-disabled': {
            '& .MuiInputBase-input.Mui-disabled': {
                textFillColor: (theme) => theme.palette.text.primary,
            },
            '& fieldset': {
                borderColor: 'primary.main',
                borderWidth: '2px',
            },
        },
    },
}

const ItemRow = ({ children }) => (
    <Box
        alignItems="center"
        display="flex"
        flexDirection="row"
        justifyContent="space-between"
        width="100%"
    >
        {children}
    </Box>
)

const ItemPrices = ({ quoteItemId }) => {
    const { t } = useTranslation()
    const { enqueueSnackbar } = useSnackbar()
    const { quoteId } = useParams()

    const organisationId = useSelector(selectOrganisationId)
    const currencySymbol = useSelector(selectCurrencySymbol)
    const locale = useSelector(selectLocale)
    const currencyCode = useSelector(selectCurrencyCode)

    const { data: selectedQuote, isFetching: isFetchingQuote } = useGetQuoteQuery({ organisationId, quoteId })
    const quoteNotCalculated = useSelector(selectedQuoteStatus) !== QUOTE_STATUS.Calculated

    const { quoteItem } = useGetQuoteItemsQuery(
        {
            organisationId,
            quoteId,
        },
        {
            selectFromResult: ({ data }) => ({
                quoteItem: data?.find((item) => item.id === quoteItemId),
            }),
        }
    )

    const [calculateQuote, { isLoading: isCalculating }] = useCalculateQuoteMutation({
        fixedCacheKey: 'shared-calculate-quote',
    })

    const { data: taxRates } = useGetTaxRatesQuery({ organisationId, params: { includeDeleted: true } })

    const [updateQuoteItem] = useUpdateQuoteItemMutation()

    const {
        foldingTotalPricePerItem,
        itemPrice,
        linePrice,
        materialPrice,
        otherChargePrice,
        processingTotalPrice,
        secondaryProcessTotalPricePerItem,
    } = quoteItem

    const isPartFromLibrary = Boolean(quoteItem?.partLibraryEntryId)
    const hasFixedPrice = Boolean(quoteItem?.partLibraryEntry?.fixedPrice)
    const isFixedPriceExpired = useMemo(() => {
        if (!quoteItem?.partLibraryEntry?.fixedPriceEndTime) return false
        const currentDate = new Date().setHours(0, 0, 0, 0)
        const fixedPriceEndTime = new Date(quoteItem?.partLibraryEntry?.fixedPriceEndTime).setHours(0, 0, 0, 0)
        return currentDate > fixedPriceEndTime
    }, [quoteItem?.partLibraryEntry?.fixedPriceEndTime])

    const currencyFormat = getCurrencyFormat(currencyCode, locale)

    const placeholder = getFormattedPrice(0, currencyCode, locale, true)

    const subtotal = useMemo(
        () =>
            (materialPrice || 0) +
            (secondaryProcessTotalPricePerItem || 0) +
            (processingTotalPrice || 0) +
            (foldingTotalPricePerItem || 0),
        [materialPrice, secondaryProcessTotalPricePerItem, processingTotalPrice, foldingTotalPricePerItem]
    )

    const [percentageAdjustment, setPercentageAdjustment] = useState(
        fractionToPercentage(otherChargePrice / subtotal, 0)
    )
    const [amountAdjustment, setAmountAdjustment] = useState(otherChargePrice)

    const handleUpdateQuoteItem = async (quoteItem) => {
        try {
            await updateQuoteItem({
                organisationId,
                quoteId,
                quoteItemId: quoteItem?.id,
                quoteItem,
            }).unwrap()
            await calculateQuote({ organisationId, quoteId })
        } catch (error) {
            enqueueSnackbar(t('Failed to update the $t(quote) item'), {
                variant: 'error',
            })
        }
    }

    const debounceHandleUpdateQuoteItem = useDebouncedCallback(handleUpdateQuoteItem, 1500)

    const handlePercentageAdjustmentChange = async (values, sourceInfo) => {
        if (sourceInfo.source == 'event') {
            debounceHandleUpdateQuoteItem.cancel()
            const { floatValue } = values
            setPercentageAdjustment(floatValue)
            const amount = (floatValue * subtotal) / 100
            setAmountAdjustment(amount)
            debounceHandleUpdateQuoteItem({ ...quoteItem, otherChargePrice: amount })
        }
    }

    const handleAmountAdjustmentChange = async (values, sourceInfo) => {
        if (sourceInfo.source == 'event') {
            debounceHandleUpdateQuoteItem.cancel()
            const { floatValue } = values
            setAmountAdjustment(floatValue)
            const percentage = fractionToPercentage(floatValue / subtotal, 0)
            setPercentageAdjustment(percentage)
            debounceHandleUpdateQuoteItem({ ...quoteItem, otherChargePrice: floatValue })
        }
    }

    const [priceDetailsData, setPriceDetailsData] = useState(null)

    const displayPriceDetails = (quoteItem) => {
        setPriceDetailsData(quoteItem)
    }

    const hidePriceDetails = () => {
        setPriceDetailsData(null)
    }

    const quoteTaxRate = selectedQuote?.taxRateId
        ? taxRates?.find((taxRate) => taxRate.id === selectedQuote?.taxRateId)?.percentage
        : selectedQuote?.taxRate

    return (
        <>
            <Box
                alignItems="flex-start"
                display="flex"
                flex="1 0 auto"
                flexDirection="column"
                justifyContent="space-between"
                sx={classes.pricesSection}
            >
                <ItemRow>
                    <Typography
                        data-testid="quote-item-prices-section-title"
                        sx={classes.bold}
                    >
                        {t('Prices')}
                    </Typography>
                    <Button
                        data-testid="nqp-quote-item-unit-times-details"
                        endIcon={<Receipt />}
                        size="small"
                        variant="text"
                        onClick={() => displayPriceDetails(quoteItem)}
                    >
                        {t('Details')}
                    </Button>
                </ItemRow>

                <ItemRow>
                    <Typography
                        data-testid="nqp-quote-item-material-price-label"
                        sx={classes.itemColumn}
                    >
                        {t('Material')}
                    </Typography>
                    <Typography sx={[classes.itemColumn, classes.pricePercentage, classes.alignRight]}>
                        {quoteNotCalculated || isCalculating || isFetchingQuote || !materialPrice
                            ? ''
                            : fractionToPercentage(materialPrice / subtotal, 0)}
                        %
                    </Typography>
                    <Typography sx={[classes.itemColumn, classes.alignRight]}>
                        {quoteNotCalculated || isCalculating || isFetchingQuote || !materialPrice
                            ? String.fromCharCode(8212)
                            : formatCurrency(materialPrice, currencyCode, locale)}
                    </Typography>
                </ItemRow>
                <ItemRow>
                    <Typography
                        data-testid="nqp-quote-item-cutting-price-label"
                        sx={classes.itemColumn}
                    >
                        {t('Cutting')}
                    </Typography>
                    <Typography sx={[classes.itemColumn, classes.pricePercentage, classes.alignRight]}>
                        {quoteNotCalculated || isCalculating || isFetchingQuote || !processingTotalPrice
                            ? ''
                            : fractionToPercentage(processingTotalPrice / subtotal, 0)}
                        %
                    </Typography>
                    <Typography sx={[classes.itemColumn, classes.alignRight]}>
                        {quoteNotCalculated || isCalculating || isFetchingQuote || !processingTotalPrice
                            ? String.fromCharCode(8212)
                            : formatCurrency(processingTotalPrice, currencyCode, locale)}
                    </Typography>
                </ItemRow>
                <ItemRow>
                    <Typography
                        data-testid="nqp-quote-item-folding-price-label"
                        sx={classes.itemColumn}
                    >
                        {t('Folding')}
                    </Typography>
                    <Typography sx={[classes.itemColumn, classes.pricePercentage, classes.alignRight]}>
                        {quoteNotCalculated || isCalculating || isFetchingQuote || !foldingTotalPricePerItem
                            ? ''
                            : fractionToPercentage(foldingTotalPricePerItem / subtotal, 0)}
                        %
                    </Typography>
                    <Typography sx={[classes.itemColumn, classes.alignRight]}>
                        {quoteNotCalculated || isCalculating || isFetchingQuote || !foldingTotalPricePerItem
                            ? String.fromCharCode(8212)
                            : formatCurrency(foldingTotalPricePerItem, currencyCode, locale)}
                    </Typography>
                </ItemRow>
                <ItemRow>
                    <Typography
                        data-testid="nqp-quote-item-secondary-process-price-label"
                        sx={classes.itemColumn}
                    >
                        {t('Secondary processes')}
                    </Typography>
                    <Typography sx={[classes.itemColumn, classes.pricePercentage, classes.alignRight]}>
                        {quoteNotCalculated || isCalculating || isFetchingQuote || !secondaryProcessTotalPricePerItem
                            ? ''
                            : fractionToPercentage(secondaryProcessTotalPricePerItem / subtotal, 0)}
                        %
                    </Typography>
                    <Typography sx={[classes.itemColumn, classes.alignRight]}>
                        {quoteNotCalculated || isCalculating || isFetchingQuote || !secondaryProcessTotalPricePerItem
                            ? String.fromCharCode(8212)
                            : formatCurrency(secondaryProcessTotalPricePerItem, currencyCode, locale)}
                    </Typography>
                </ItemRow>
                <Divider
                    orientation="horizontal"
                    flexItem
                />
                <ItemRow>
                    <Typography
                        data-testid="nqp-quote-item-subtotal-label"
                        sx={[classes.itemColumn, classes.bold]}
                    >
                        {t('Subtotal')}
                    </Typography>
                    <Typography sx={[classes.itemColumn, classes.pricePercentage, classes.alignRight, classes.bold]}>
                        100%
                    </Typography>
                    <Typography sx={[classes.itemColumn, classes.bold, classes.alignRight]}>
                        {quoteNotCalculated || isCalculating || isFetchingQuote || !subtotal
                            ? String.fromCharCode(8212)
                            : formatCurrency(subtotal, currencyCode, locale)}
                    </Typography>
                </ItemRow>
                <ItemRow>
                    <Typography data-testid="nqp-quote-item-adjustment-label">{t('Adjust')}</Typography>
                    <TbxTooltip
                        title={
                            isPartFromLibrary && hasFixedPrice && !isFixedPriceExpired
                                ? t(
                                      'The adjustment is the difference between the fixed part price and the current calculated price'
                                  )
                                : ''
                        }
                        arrow
                    >
                        <span>
                            <NumericFormat
                                allowNegative={true}
                                customInput={TextField}
                                decimalScale={2}
                                decimalSeparator={currencyFormat.decimal || ','}
                                disabled={
                                    quoteNotCalculated || (isPartFromLibrary && hasFixedPrice && !isFixedPriceExpired)
                                }
                                inputProps={{
                                    style: { textAlign: 'right' },
                                    'data-testid': 'nqp-quote-item-adustment-text',
                                }}
                                placeholder="0"
                                size="small"
                                suffix="%"
                                sx={[
                                    classes.itemColumn,
                                    classes.alignRight,
                                    classes.inputColumn,
                                    hasFixedPrice && !isFixedPriceExpired ? classes.fixedPriceInput : null,
                                ]}
                                thousandSeparator={currencyFormat.group}
                                value={quoteNotCalculated ? 0 : percentageAdjustment}
                                variant="outlined"
                                onValueChange={handlePercentageAdjustmentChange}
                            />
                        </span>
                    </TbxTooltip>

                    <TbxTooltip
                        title={
                            isPartFromLibrary && hasFixedPrice && !isFixedPriceExpired
                                ? t(
                                      'The adjustment is the difference between the fixed part price and the current calculated price'
                                  )
                                : ''
                        }
                        arrow
                    >
                        <span>
                            <NumericFormat
                                allowNegative={true}
                                customInput={TextField}
                                decimalScale={currencyFormat.decimalPlaces}
                                decimalSeparator={currencyFormat.decimal}
                                disabled={
                                    quoteNotCalculated || (isPartFromLibrary && hasFixedPrice && !isFixedPriceExpired)
                                }
                                fixedDecimalScale={true}
                                inputProps={{
                                    style: { textAlign: 'right' },
                                }}
                                placeholder={placeholder}
                                prefix={currencyFormat.currencySymbolPosition === 'before' ? currencySymbol : undefined}
                                size="small"
                                suffix={currencyFormat.currencySymbolPosition === 'after' ? currencySymbol : undefined}
                                sx={[
                                    classes.itemColumn,
                                    classes.alignRight,
                                    classes.inputColumn,
                                    hasFixedPrice && !isFixedPriceExpired ? classes.fixedPriceInput : null,
                                ]}
                                thousandSeparator={currencyFormat.group}
                                value={amountAdjustment ?? null}
                                variant="outlined"
                                onValueChange={handleAmountAdjustmentChange}
                            />
                        </span>
                    </TbxTooltip>
                </ItemRow>
                <Divider
                    orientation="horizontal"
                    flexItem
                />
                <ItemRow>
                    <Typography
                        data-testid="nqp-quote-item-unit-price-label"
                        sx={[classes.itemColumn, classes.bold]}
                    >
                        {t('Unit price')}
                    </Typography>
                    <Typography sx={[classes.itemColumn, classes.pricePercentage, classes.alignRight, classes.bold]}>
                        {quoteNotCalculated || isCalculating || isFetchingQuote || !processingTotalPrice
                            ? ''
                            : fractionToPercentage(itemPrice / subtotal, 0)}
                        %
                    </Typography>
                    <Typography sx={[classes.itemColumn, classes.alignRight, classes.bold]}>
                        {quoteNotCalculated || isCalculating || isFetchingQuote || !itemPrice
                            ? String.fromCharCode(8212)
                            : formatCurrency(itemPrice || 0, currencyCode, locale)}
                    </Typography>
                </ItemRow>
                <ItemRow>
                    <Typography
                        data-testid="nqp-quote-item-line-price-label"
                        sx={classes.bold}
                    >
                        {t('Line price')}
                    </Typography>
                    <Typography sx={classes.bold}>
                        {quoteNotCalculated || isCalculating || isFetchingQuote || !linePrice
                            ? String.fromCharCode(8212)
                            : formatCurrency(linePrice || 0, currencyCode, locale)}
                    </Typography>
                </ItemRow>
            </Box>
            {priceDetailsData ? (
                <PriceDetailsDialog
                    open={!!priceDetailsData}
                    quoteItem={priceDetailsData}
                    quoteOverrides={selectedQuote?.quoteOverrides}
                    taxRate={quoteTaxRate}
                    onClose={hidePriceDetails}
                />
            ) : null}
        </>
    )
}

ItemPrices.propTypes = {
    quoteItemId: PropTypes.string,
}

export default memo(ItemPrices)
