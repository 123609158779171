import { useEffect } from 'react'
import { useTranslation } from 'react-i18next'
import { Add, Remove } from '@mui/icons-material'
import { Box, Button } from '@mui/material'
import PropTypes from 'prop-types'

const classes = {
    root: {
        height: 'auto',
        display: 'block',
        position: 'absolute',
        left: 24,
        bottom: 24,
        maxWidth: 320,
        zIndex: 1000,
        padding: 2,
    },
    controlsContainer: {
        display: 'flex',
        flexDirection: 'column',
        gap: 1,
        alignItems: 'center',
    },
    iconButton: {
        padding: '4px',
        minWidth: 0,
        width: '28px',
        height: '28px',
        fontSize: '13px',
    },
    resetButton: {
        height: '28px',
        fontSize: '13px',
    },
}

const ZoomControls = ({ onZoomIn, onZoomOut, onZoomReset }) => {
    const { t } = useTranslation()

    const platform = window.navigator.platform.toLowerCase()

    const isMacOS = platform.indexOf('mac') !== -1

    const keyModifierEvent = isMacOS ? 'metaKey' : 'ctrlKey'

    useEffect(() => {
        const keyDownHandler = (event) => {
            if (event.key === '+' && event[keyModifierEvent]) {
                event.preventDefault()
                event.stopPropagation()
                onZoomIn(0.2)
            }
            if (event.key === '-' && event[keyModifierEvent]) {
                event.preventDefault()
                event.stopPropagation()
                onZoomOut(0.2)
            }
            if (event.key === '0' && event[keyModifierEvent]) {
                event.preventDefault()
                event.stopPropagation()
                onZoomReset()
            }
        }

        document.addEventListener('keydown', keyDownHandler)

        return () => {
            document.removeEventListener('keydown', keyDownHandler)
        }
    }, [])

    return (
        <Box sx={classes.root}>
            <Box sx={classes.controlsContainer}>
                <Button
                    color="secondary"
                    size="small"
                    sx={classes.iconButton}
                    variant="contained"
                    disableRipple
                    onClick={() => onZoomIn(0.2)}
                >
                    <Add />
                </Button>
                <Button
                    color="secondary"
                    sx={classes.resetButton}
                    variant="contained"
                    disableRipple
                    onClick={() => onZoomReset()}
                >
                    {t('Zoom to fit')}
                </Button>
                <Button
                    color="secondary"
                    size="small"
                    sx={classes.iconButton}
                    variant="contained"
                    disableRipple
                    onClick={() => onZoomOut(0.2)}
                >
                    <Remove />
                </Button>
            </Box>
        </Box>
    )
}

ZoomControls.propTypes = {
    onZoomIn: PropTypes.func,
    onZoomOut: PropTypes.func,
    onZoomReset: PropTypes.func,
}

export default ZoomControls
